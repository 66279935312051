import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { useCallback, useEffect, useState } from 'react';
import { NoItems } from '../components-molecules/NoItems';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { GetIndustryEventsList, JoinIndustryEventRequest } from '../service/serviceService';
import { IndustryEventsServiceList } from './IndustryEventsServiceList';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { LIGHT_THEME } from '../constants/theme';

export function IndustryEventsService(): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState<IndustryEvent[]>([]);
  const pageSize = 10;
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState(0);
  const [watchEventModal, setWatchEventModal] = useState<IndustryEvent>();

  const loadMore = () => {
    getIndustryEvents(page + 1, pageSize, events);
    setPage(page + 1);
  };

  const getIndustryEvents = useCallback((page = 0, size = pageSize, prevData: IndustryEvent[] = []) => {
    if (!token) return;

    GetIndustryEventsList(
      token,
      { Size: size, Page: page },
      (response) => {
        setTotal(response.Result.Count);
        setEvents(prevData.concat(response.Result.Result));
        setTimeout(() => setLoading(false), 100);
      },
      () => setLoading(false)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getIndustryEvents();
  }, [token, getIndustryEvents]);

  const onJoinEvent = useCallback((eventId: GUID | string) => {
    const event = events.find(x => x.Id === eventId);
    if (token && event) {
      JoinIndustryEventRequest(token, event.Id, () => {/** */ }, () => {/** */ });
      window.open(event.JoinUrl, '_blank', 'rel=noopener noreferrer');
    }
  }, [events, token]);

  const onWatchEventModalOpen = useCallback((eventId: GUID | string) => {
    const event = events.find(x => x.Id === eventId);
    if (event) setWatchEventModal(event);
  }, [events]);

  const onWatchEventModalClose = useCallback(() => {
    setWatchEventModal(undefined);
  }, []);

  return (
    <>
      {loading
        ? <Grid container justifyContent='center'>
          <CircularProgress
            color='primary'
            size={50}
            sx={{ margin: '48px 0' }}
          />
        </Grid>
        : events.length > 0
          ? <IndustryEventsServiceList
            total={total}
            events={events}
            watchEventModal={watchEventModal}
            loadMore={loadMore}
            onJoinEvent={onJoinEvent}
            onWatchEventModalOpen={onWatchEventModalOpen}
            onWatchEventModalClose={onWatchEventModalClose}
          />
          : <NoItems
            icon={<VideoLibraryIcon sx={{ fontSize: 88, fill: LIGHT_THEME.palette.primary.main, }} />}
            title='No events yet'
            description='There are no events published yet.'
          />
      }
    </>
  );
}
