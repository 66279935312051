const INITIAL: MerchantRegistrationState = {
  tab: null,
};
    
export const merchantRegistrationReducer = (state: MerchantRegistrationState = INITIAL, action: MerchantRegistrationAction): MerchantRegistrationState => {
  switch (action.type) {
    case 'SET_TAB':
      return { ...state, tab: action.payload.tab };
    default:
      return state;
  }
};
  
