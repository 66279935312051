import { useEffect, useState } from 'react';

import { AdminDivider } from '../utils/arrayDividers';
import { TerritoryManagerListCard } from './cards/TerritoryManagerListCard';
import { ListDivider } from '../components-molecules/Divider';
import { CircularProgress, Container, debounce, useMediaQuery } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LIGHT_THEME } from '../constants/theme';

interface IProps {
  territoryManagers: TerritoryManager[];
  loadMore: () => void;
  total: number;
  onDelete: () => void;
}

export function TerritoryManagersList({ territoryManagers, loadMore, total, onDelete }: IProps): JSX.Element {
  const [sectionedlist, setSectionedList] = useState<DividedList<TerritoryManager>>({});
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  
  useEffect(() => {
    setSectionedList({ ...AdminDivider<TerritoryManager>(territoryManagers) });
  }, [territoryManagers]);

  return (
    <div style={{ paddingBottom: '48px', width: '100%' }}>
      <InfiniteScroll
        style={{ overflowY: 'hidden' }}
        dataLength={territoryManagers.length} //This is important field to render the next data
        next={() => debounce(() => loadMore(), 300)()}
        scrollThreshold={smallDown ? '550px' : '50px'}
        hasMore={territoryManagers.length < total}
        loader={
          <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
            <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
          </Container>
        }
      >
        {Object.keys(sectionedlist).sort().map((section, sectionIndex) => {
          return (
            <ListDivider
              key={sectionIndex}
              division={{ name: section, length: sectionedlist[section].length }}
              caption={{ singular: 'Territory Manager', plural: 'Territory Managers' }}
            >
              {sectionedlist[section].map(item =>
                <TerritoryManagerListCard
                  onDelete={onDelete}
                  key={`${item.AdminProperties.Id}${new Date()}`}
                  territorymanager={item}
                />
              )}
            </ListDivider>
          );
        })}
      </InfiniteScroll>
    </div>
  );
}
