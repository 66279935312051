const INITIAL: IndustryEventState = {
  currentData: {
    Id: '',
    Title: '',
    Description: '',
    EditUrl: '',
    ThumbnailUrl:'',
    StartDate:'',
    IsPublished: false,
    JoinUrl: '',
    ShareUrl: '',
    RecordingPassword: '',
    IsDeleted: false,
    TargetUsers: '',
    NumberOfRegistrations: 0,
    NumberOfViews: 0,
    HasRegistered: false,
    State: 'Past',
  }
};
    
export const industryEventReducer = (state: IndustryEventState = INITIAL, action: IndustryEventAction): IndustryEventState => {
  switch (action.type) {
    case 'SET_CURRENT_INDUSTRY_EVENT':
      return { ...state, currentData: action.payload.currentData };
    default:
      return state;
  }
};
  
