import { Grid } from '@mui/material';
import { BodyTwoSecondary, HeaderSix, OverlineSecondary, SubtitleOnePrimary } from '../components-atoms/TypographyComponents';
import '../scss/components/_recent-items.scss';
import '../scss/components/_thumbnail.scss';

interface IProps {
  products: Array<{
    img: string;
    link: string;
    subTitle: string;
    title: string;
  }>;
}

export function LearnMore({ products }: IProps): JSX.Element {
  return (
    <>
      <section className="recent-items">
        <div className="recent-items__title">
          <HeaderSix>Learn More About Our Products</HeaderSix>
        </div>
        <Grid container item columnSpacing={20 / 8} alignItems="center">
          {products.map(product => (
            <Grid key={product.title} className="recent-items__content" item lg={4} xl={4} xs={12} md={6} sm={6} >
              <a
                onClick={() => window.open(`/detail/product/${product.link}`, '_self')}
                className="recent-item recent-item--link"
              >
                <img
                  src={product.img}
                  className="
                    recent-item__media
                    thumb thumb--large thumb--picture-icon
                  "
                  style={{ padding: 8 }}
                />
                <div className="recent-item__content">
                  <OverlineSecondary>
                    Knowledge Base
                  </OverlineSecondary>
                  <SubtitleOnePrimary className="u-text-truncate-multi">
                    {product.title}
                  </SubtitleOnePrimary>
                  <BodyTwoSecondary>
                    {product.subTitle}
                  </BodyTwoSecondary>
                </div>
              </a>
            </Grid>
          ))}
        </Grid>
      </section>
    </>
  );
}
