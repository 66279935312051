import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { NoItems } from '../components-molecules/NoItems';
import { Dashboard } from '../components-organisms/Dashboard';
import MailIcon from '@mui/icons-material/Mail';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { LIGHT_THEME } from '../constants/theme';
import { GetAudiencesRequest, GetMerchantManagerOptionsRequest, GetMerchantOptionsRequest, GetRolesRequest, ListNewslettersRequest, NewsletterResult } from '../service/serviceService';
import { CircularProgress, Collapse, Container, Grid, useMediaQuery } from '@mui/material';
import { BodyOnePrimary, BodyTwoPrimary, BodyTwoSecondary, CaptionSecondary, HeaderSix, OverlineSecondary, SubtitleTwoPrimary } from '../components-atoms/TypographyComponents';
import parse from 'html-react-parser';
import { debounce } from 'lodash';
import { IFilterOption, IFilterOptions, ISelectedOptions } from '../components-molecules/FilterMenu';
import { FormatDate } from '../utils/dateUtils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { SearchbarWithDeboubce } from '../components-molecules/SearchBarWithDebounce';

interface NewsletterFilter extends IFilterOptions {
  Audiences: IFilterOption[];
  'User Roles': IFilterOption[];
}

export function Newsletters(): JSX.Element {
  const timeoutDuration = 300;
  const pageSize = 20;
  const token = useTypedSelector(state => state.userReducer.token);
  const [newsletters, setNewsletters] = useState<NewsletterResult[]>([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [filter, setFilter] = useState<ISelectedOptions>({ Audiences: [], 'User Roles': [], Merchants: [], 'Merchant Managers': [] });
  const [audiences, setAudiences] = useState<IFilterOption[]>([]);
  const [roles, setRoles] = useState<IFilterOption[]>([]);
  const [merchants, setMerchants] = useState<IFilterOption[]>([]);
  const [merchantManagers, setMerchantManagers] = useState<IFilterOption[]>([]);
  const [filterOptions, setFilterOptions] = useState<NewsletterFilter>({ Audiences: [], 'User Roles': [], Merchants: [], 'Merchant Managers': [] });
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  const [page, setPage] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleChange = () => {
    setExpanded((prev) => !prev);
  };

  const getNewsletters = useCallback((t: string, page = 0, size = pageSize, prevData: NewsletterResult[] = []): void => {
    ListNewslettersRequest(
      t,
      {
        Page: page,
        Size: size,
        Query: query,
        AudienceIds: filter.Audiences,
        UserRoleIds: filter['User Roles'],
        MerchantIds: filter.Merchants,
        MerchantManagerIds: filter['Merchant Managers']
      },
      (response) => {
        if (response) {
          setCount(response.Result.Count);
          let temp = [...prevData];
          response.Result.Result.map(m => {
            const tempMatch = temp.find((f) => f.GroupName === m.GroupName);
            if (tempMatch) {
              temp[temp.indexOf(tempMatch)].Data = [...temp[temp.indexOf(tempMatch)].Data, ...m.Data];
            } else {
              temp = [...temp, m];
            }
          });
          setNewsletters(temp);
          setLoading(false);
        }
      },
      (error: any) => { if (error.response) { console.error(error.response.data.Error); } setLoading(false); }
    );
  }, [query, filter]);
  useEffect(() => {
    if (token) {
      setLoading(true);
      getNewsletters(token);
    }
  }, [token, getNewsletters]);
  useEffect(() => {
    if (token) {
      GetRolesRequest(
        token,
        (response) => {
          setRoles([...response.Result.map(role => ({ title: role.Name, value: role.Id }))]);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
      GetAudiencesRequest(
        token,
        (response) => {
          setAudiences([...response.Result.map(audience => ({ title: audience.Name, value: audience.Id }))]);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
      GetMerchantOptionsRequest(
        token,
        (response) => {
          setMerchants([...response.Result.map(merchant => ({ title: merchant.Name, value: merchant.Id }))]);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
      GetMerchantManagerOptionsRequest(
        token,
        (response) => {
          setMerchantManagers([...response.Result.map(merchantManager => ({ title: merchantManager.Name, value: merchantManager.Id }))]);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  }, [token]);

  useEffect(() => {
    setFilterOptions(prev => ({ ...prev, 'User Roles': roles, Audiences: audiences, Merchants: merchants, 'Merchant Managers': merchantManagers }));
  }, [roles, audiences, merchants, merchantManagers]);

  const loadMore = () => {
    if (token) {
      getNewsletters(token, page + 1, pageSize, newsletters);
      setPage(prev => prev + 1);
    }
  };

  const searchOnChange=(text:string)=>{
    setQuery(text);
  };

  return (
    <Dashboard justifyContent="flex-start">
      <PageHeader
        title={'Email Newsletters'}
        subtitle={
          (count > 0)
            ? `${count} ${(count > 1) ? 'Email Newsletters' : 'Email Newsletter'}`
            : loading
              ? ''
              : 'No Email Newsletters Yet'
        }
        buttonTitle="New Email"
        buttonIconMail
        activeButtons
        hasFilter
        noSort
        filterOptions={filterOptions}
        onFilterSubmit={(selected) => setFilter(selected)}
        onClickSearch={() => setSearchOpen(true)}
        modalCallback={() => navigate('/admin/new-email')}
        isAdmin={true}
      />
      <SearchbarWithDeboubce
        isOpen={searchOpen}
        query={query}
        placeholder="Search Email Newsletters"
        onCancel={() => { setSearchOpen(false); setQuery(''); }}
        onChange={searchOnChange}
      />
      {loading
        ? <CircularProgress
          color='primary'
          size={50}
          style={{ zIndex: 999, position: 'absolute', top: '50vh', right: '40vw' }} />
        : count > 0
          ? <div style={{ paddingBottom: '48px' }}>
            <InfiniteScroll
              style={{ overflowY: 'hidden' }}
              dataLength={newsletters.map(m => m.Data).flat().length} //This is important field to render the next data
              next={() => debounce(() => loadMore(), timeoutDuration)()}
              scrollThreshold={smallDown ? '550px' : '50px'}
              hasMore={newsletters.map(m => m.Data).flat().length < count}
              loader={
                <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
                  <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
                </Container>
              }
            >
              {
                newsletters.map(newsletter =>
                  <Grid container key={newsletter.GroupName} sx={{ marginTop: '48px', '& > .MuiContainer-root:last-child': { margin: '24px 0 0 ' } }}>
                    <Grid container alignItems="baseline">
                      <SubtitleTwoPrimary style={{ paddingLeft: '24px', marginRight: '8px' }}>{newsletter.GroupName}</SubtitleTwoPrimary>
                      <CaptionSecondary>{`Displaying ${newsletter.Count} ${(newsletter.Count > 1) ? 'Email Newsletters' : 'Email Newsletter'}`}</CaptionSecondary>
                    </Grid>
                    {newsletter.Data.map((detail) => (
                      <Container key={detail.Newsletter.Id} sx={{ width: '840px', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '20px', margin: '24px 0px', padding: '24px 0px' }}>
                        <HeaderSix>{detail.Newsletter.Subject}</HeaderSix>
                        <BodyTwoSecondary style={{ marginBottom: '32px' }}>
                          {FormatDate(new Date(detail.Newsletter.SentDate), { day: 'numeric', month: 'long', format: 'dd MM YYYY' })}
                        </BodyTwoSecondary>
                        <Grid sx={{ width: '100%', marginBottom: '44px' }}>
                          {parse(detail.Newsletter.Body || '')}
                        </Grid>
                        {detail.Attachments.length > 0 && (
                          <Grid container direction="row" alignItems="center" sx={{ marginBottom: '35px' }}>
                            <Grid item sm={3}>
                              <OverlineSecondary>FILE ATTACHMENTS</OverlineSecondary>
                            </Grid>
                            <Grid xs={12} sm={9} item>
                              <Grid maxWidth="md" sx={{ display: 'flex', flexDirection: 'column' }}>
                                {detail.Attachments.map((attachment: any) =>
                                  <BodyTwoSecondary
                                    key={attachment.Id}
                                    style={{ color: '#012169', cursor: 'pointer', textDecoration: 'underline' }}
                                    onClick={() => window.open(attachment.FileUrl)}
                                  >
                                    {attachment.FileName}
                                  </BodyTwoSecondary>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {detail.AudienceUserRoles.length > 0 && (
                          <Grid container direction="row" alignItems="center">
                            <Grid item sm={3}>
                              <OverlineSecondary>RECIPIENT GROUPS</OverlineSecondary>
                            </Grid>
                            <Grid xs={12} sm={9} item>
                              <Grid maxWidth="md" sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {detail.AudienceUserRoles.map((audienceUserRole: any, idx: number, arr: any) =>
                                  <Fragment key={idx}>
                                    <BodyTwoPrimary>{`${audienceUserRole.AudienceName} - ${audienceUserRole.UserRoleName}`}</BodyTwoPrimary>
                                    {idx !== arr.length - 1  && (
                                      <BodyTwoPrimary style={{ marginRight: '4px' }}>,</BodyTwoPrimary>
                                    )}
                                  </Fragment>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {detail.MerchantUsers.length > 0 && (
                          <Grid container direction="row" alignItems="center">
                            <Grid item sm={3}>
                              <OverlineSecondary>RECIPIENT MERCHANTS</OverlineSecondary>
                            </Grid>
                            <Grid xs={12} sm={9} item>
                              <Grid maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                                {detail.MerchantUsers?.map((item, idx) =>
                                  <Fragment key={idx}>
                                    <BodyOnePrimary style={{ marginBottom: '4px' }}>{item.Name}</BodyOnePrimary>
                                    <BodyTwoSecondary>{item.Emails.join(', ')}</BodyTwoSecondary>
                                  </Fragment>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {detail.MerchantManagers.length > 0 && detail.MerchantManagers.map((merchantManager: any, idx: number) => idx < 3 ? (
                          <Grid key={idx} container direction="row" alignItems="center" sx={{ padding: '16px 0' }}>
                            <Grid item sm={3}>
                              <OverlineSecondary>MERCHANT MANAGER</OverlineSecondary>
                            </Grid>
                            <Grid xs={12} sm={9} item>
                              <Grid maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                                <BodyTwoPrimary>{merchantManager.FirstName} {merchantManager.LastName}</BodyTwoPrimary>
                                <BodyTwoSecondary>{merchantManager.Merchant}, {merchantManager.Email}</BodyTwoSecondary>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <Collapse in={expanded} key={idx}>
                            <Grid key={idx} container direction="row" alignItems="center" sx={{ padding: '16px 0' }}>
                              <Grid item sm={3}>
                                <OverlineSecondary>MERCHANT MANAGER</OverlineSecondary>
                              </Grid>
                              <Grid xs={12} sm={9} item>
                                <Grid maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                                  <BodyTwoPrimary>{merchantManager.FirstName} {merchantManager.LastName}</BodyTwoPrimary>
                                  <BodyTwoSecondary>{merchantManager.Merchant}, {merchantManager.Email}</BodyTwoSecondary>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Collapse>
                        ))}
                        {detail.MerchantManagers.length > 3
                          ? (
                            <Grid container alignItems="center" justifyContent='flex-end' sx={{ paddingRight: '8px' }}>
                              {expanded ? (
                                <ExpandLessIcon onClick={handleChange} sx={{ cursor: 'pointer' }} />
                              ) : (
                                <Fragment>
                                  <BodyTwoSecondary>+{detail.MerchantManagers.length - 3} More</BodyTwoSecondary>
                                  <ExpandMoreIcon onClick={handleChange} sx={{ marginLeft: '8px', cursor: 'pointer' }} />
                                </Fragment>
                              )}
                            </Grid>
                          ) : null}
                      </Container>
                    ))}
                  </Grid>
                )}
            </InfiniteScroll>
          </div>
          : query || !Object.values(filter).every(option => Array.isArray(option) && option.length === 0)
            ? <Grid sx={{ width: '100%', paddingLeft: '24px', marginTop: '16px' }}>
              <BodyOnePrimary>0 results found.</BodyOnePrimary>
            </Grid>
            : <NoItems
              icon={<MailIcon sx={{ fontSize: 88, fill: LIGHT_THEME.palette.primary.main }} />}
              title="No sent newsletters yet"
              description="You haven’t sent any email newsletters yet. Start by sending the first newsletter."
            />
      }
    </Dashboard>
  );
}
