import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Checkbox, IconButton, Typography } from '@mui/material';
import { CaptionSecondary } from '../components-atoms/TypographyComponents';
import { LIGHT_THEME } from '../constants/theme';
import imageIcon from '../assets/img/merchant_placeholder.jpg';

export interface IMerchantAssignCardItem {
  merchant: MerchantListModel;
  onChange: (c: boolean, merchant: MerchantListModel) => void;
  onSelect: (m: MerchantListModel) => void;
  disabled: boolean;
  selected: boolean;
  storeSelected?: number;
  totalStore: number;
}

export function MerchantAssignCardItem (props: IMerchantAssignCardItem):JSX.Element {
  const {merchant, disabled, selected, storeSelected, totalStore, onSelect, onChange} =props;
  return(
    <Box
      borderRadius={1}
      mt={1}
      ml={1}
      mr={1}
      pl={'7px'}
      bgcolor={selected || storeSelected ? LIGHT_THEME.palette.action.selected : LIGHT_THEME.palette.background.default}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        transition: '0.2s background-color',
        cursor: disabled ? 'not-allowed' : 'pointer',
        '&:hover': {
          bgcolor: LIGHT_THEME.palette.action.selected
        }
      }}
    >
      <Checkbox
        checked={Boolean(selected)}
        indeterminate={selected ? false : Boolean(storeSelected)}
        disabled={disabled}
        onChange={(e,c)=> onChange(c, merchant)}
        sx={{ mr: 1 }}
      />

      <Box
        display="flex"
        flex={1}
        alignItems="center"
        height={68}
        onClick={()=> disabled ? undefined : onSelect(merchant)}
      >
        <img
          src={merchant.ThumbnailUrl || merchant.Logo || imageIcon}
          alt={merchant.Name}
          data-testid={merchant.Name}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = imageIcon;
          }}
          style={{
            width: 28,
            height: 28,
            marginRight: '12px',
            border: '1px solid rgba(0, 0, 0, 0.08)',
            borderRadius: '4px',
            objectFit: 'contain'
          }}
        />

        <Box flex={1}>
          <Typography sx={{
            flex: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 340,
          }}>
            {merchant.Name}
          </Typography>

          <CaptionSecondary>
            {merchant.PayerCode}
          </CaptionSecondary>
        </Box>

        <Box display="flex" alignItems="center" mr={1}>
          <CaptionSecondary variant="caption" mr={1}>
            {selected ? 'All Stores' : storeSelected ? `${storeSelected} / ${totalStore}` : ''}
          </CaptionSecondary>
          
          <IconButton>
            <ChevronRightIcon/>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}
