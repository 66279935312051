import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';

import { CenteredDialogComponent } from '../components-molecules/dialogs/CenteredDialog';
import { ImageComponent } from '../components-atoms/ImageComponents';
import { Dashboard } from '../components-organisms/Dashboard';
import { BodyOnePrimary, BodyOneSecondary } from '../components-atoms/TypographyComponents';
import { ButtonRouteContainedPrimary } from '../components-atoms/ButtonComponents';

import DuluxMerchantConnect from '../assets/img/DuluxMerchantConnect-logo.png';
import { useTypedSelector, useTypedDispatch } from '../hooks/TypedReduxHooks';
import { useEffect } from 'react';
import { GetAllAdminServicesRequest, GetAllServicesNoPublicRequest, GetAvailableServices } from '../service/serviceService';

interface UnauthorizedProps {
  noDrawer?: boolean;
  noAppBar?: boolean;
}

export function Unauthorized({ noDrawer, noAppBar }: UnauthorizedProps): JSX.Element {
  const location = useLocation();

  const isAdminPanel = location.pathname.startsWith('/admin');
  const [user, token] = useTypedSelector(state => [state.userReducer.data, state.userReducer.token]);
  const dispatch = useTypedDispatch();
  useEffect(()=>{
    if(token){
      if (user?.IsAdmin && !user.IsTerritoryManager && !user?.IsSalesManager){
        Promise.all([
          new Promise(res => GetAllServicesNoPublicRequest(
            token,
            (resp) => {
              res(0);
              dispatch({ type: 'DRAWER_SET_SERVICE_LIST', payload: { services: resp.Result } });
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {}
          )),
          new Promise(res => GetAllAdminServicesRequest(
            token,
            (resp) => {
              res(0);
              dispatch({ type: 'DRAWER_SET_ADMIN_SERVICE_LIST', payload: { adminServices: resp.Result } });
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {}
          ))
        ]);
      }else{
        GetAvailableServices(
          token,
          (res) => {
            dispatch({ type: 'USER_SET_SERVICES', payload: { services: res.Result } });
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => {}
        );
      }
    }
    
  },[isAdminPanel, token, dispatch, user]);
  return (
    <Dashboard noDrawer={noDrawer} noAppBar={noAppBar}>
      <CenteredDialogComponent sx={{ height: '78vh', alignItems: 'start', marginTop: isAdminPanel ? 0 : '56px' }}>
        {isAdminPanel ? null
          : <ImageComponent src={DuluxMerchantConnect} alt="AkzoNobel" loading="lazy" height="56" sx={{ margin: '12px' }} />
        }

        <Box sx={{ margin: '12px 12px 4px' }}>
          <BodyOnePrimary component="span" sx={{ fontWeight: 'fontWeightMedium' }}>403. </BodyOnePrimary>
          <BodyOnePrimary component="span">That&apos;s an error.</BodyOnePrimary>
        </Box>

        <Box sx={{ margin: '4px 12px 12px' }}>
          <BodyOnePrimary component="span"> You have no permission to view this content. </BodyOnePrimary>
          <BodyOneSecondary component="span">That’s all we know.</BodyOneSecondary>
        </Box>
          

        <ButtonRouteContainedPrimary fullWidth sx={{ margin: '12px' }} to={(isAdminPanel || (user && user.IsAdmin)) ? '/admin' : '/'}>
          Back to Home Page
        </ButtonRouteContainedPrimary>
      </CenteredDialogComponent>
    </Dashboard>
  );
}
