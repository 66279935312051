/* eslint-disable @typescript-eslint/naming-convention */

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';

const BLINK = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const BlinkBox = styled(Box)({
  animation: `${BLINK} 1s ease-out infinite`,
  animationDirection: 'alternate'
});
