import { AxiosError } from 'axios';
import { HttpRequestGet, HttpRequestPost } from './genericService';

interface AssetDownloaderRequestModel {
  DigitalAssetIds: string[];
}

interface DetailAssetDownloaderRequestModel {
  DigitalAssetFileIds: string[];
}

export function GetAssetsFromListRequest(
  token: string,
  body: AssetDownloaderRequestModel,
  success: (response: ApiResponse<string>) => void,
  failure?: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, AssetDownloaderRequestModel>(
    '/digitalasset/getDownloadUrl',
    body,
    (response) => success(response.data),
    (error) => failure && failure(error),
    token,
  );
}

export function GetAssetsFromDetailRequest(token: string, assetId: string, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<string>(
    `/digitalasset/${assetId}/getDownloadUrl`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface DigitalAssetDownloadLog {
  id:string;
}

export function SingleFileDownloadLogRequest(token: string, body: DigitalAssetDownloadLog): void {
  const {id,...rest}=body;
  HttpRequestPost<string, Omit<DigitalAssetDownloadLog, 'id'>>(
    `/digitalasset/singleFileDownloadLog/${id}`,
    rest,
    () => undefined,
    () => undefined,
    token
  );
}

export function GetSelectedAssetsFromDetailRequest(
  token: string,
  body: DetailAssetDownloaderRequestModel,
  success: (response: ApiResponse<string>) => void,
  failure?: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, DetailAssetDownloaderRequestModel>(
    '/digitalasset/files/getDownloadUrl',
    body,
    (response) => success(response.data),
    (error) => failure && failure(error),
    token,
  );
}
