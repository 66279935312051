import { useEffect, useState } from 'react';

import { DataDivider } from '../utils/arrayDividers';

import { ListDivider } from '../components-molecules/Divider';
import { StoreCard } from '../components-organisms/cards/StoreCard';

export function StoresList({ stores, navigateBackTo }: { stores: Store[]; navigateBackTo: string; }): JSX.Element {
  const [sectionedlist, setSectionedList] = useState<DividedList<Store>>({});

  useEffect(() => {
    if (stores.length > 0) {
      setSectionedList({ ...DataDivider(stores) });
    }
  }, [stores]);

  return (
    <div style={{ paddingBottom: 72 }}>
      {Object.keys(sectionedlist).sort().map((section, sectionIndex) => {
        return (
          <ListDivider
            key={section + sectionIndex.toString()}
            division={{ name: section, length: sectionedlist[section].length }}
            caption={{ singular: 'Store', plural: 'Stores' }}
          >
            {sectionedlist[section].map((item) => <StoreCard key={item.Id} store={item} navigateBackTo={navigateBackTo} />)}
          </ListDivider>
        );
      })}
    </div>
  );
}
