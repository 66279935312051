/* eslint-disable @typescript-eslint/naming-convention */
import { ToolbarConfig } from 'react-rte';

export const TOOLBAR_CONFIG: ToolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['BLOCK_TYPE_DROPDOWN', 'INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS',],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Paragraph', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'Bullet List', style: 'unordered-list-item' },
    { label: 'Numbered List', style: 'ordered-list-item' }
  ]
};

export const EMPTY_HTML = '<p><br></p>';
