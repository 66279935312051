import { useMediaQuery, Container, CircularProgress, Grid } from '@mui/material';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LIGHT_THEME } from '../constants/theme';
import { ProductsListModel } from '../service/serviceService';
import { ListingCard } from './cards/listing/ListingCard';

interface IProps {
  products: ProductsListModel[];
  loadMore: () => void;
  total: number;
}

export function NPDServiceList({ products, loadMore, total }: IProps): JSX.Element {
  const timeoutDuration = 300;
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  return (
    <Grid sx={{ width: '100%' }} px={{ xs: '20px', sm: 4 }}>
      <InfiniteScroll
        style={{ overflowY: 'hidden' }}
        dataLength={products.length} //This is important field to render the next data
        next={() => debounce(() => loadMore(), timeoutDuration)()}
        scrollThreshold={smallDown ? '550px' : '50px'}
        hasMore={products.length !== total}
        loader={
          <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
            <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
          </Container>
        }
      >
        {products.map((item, index) => (
          <ListingCard
            key={item.Id}
            title={item.Title}
            description={item.Description}
            thumbnailUrl={item.ThumbnailUrl}
            url={'/detail/npd/' + item.Id}
            isFirst={index === 0}
            isLast={index === products.length - 1} />
        )
        )}

      </InfiniteScroll>
    </Grid>);
}
