/* eslint-disable @typescript-eslint/naming-convention */
interface IResponse {
  allowedFiles: File[];
  filesBanned: string[];
  errorMessage: string | null;
}

const AllImageTypes = ['.jpg', '.jpeg', '.jpe', '.jif', '.jfif', '.jfi', '.png', '.gif', '.webp', '.tiff', '.tif', '.bmp', '.dib', '.svg', '.svgz'];
const AllVideoTypes = ['.webm', '.mpg', '.mp2', '.mpeg', '.mpe', '.mpv', '.ogg', '.mp4', '.m4p', '.m4v', '.avi', '.wmv', '.mov', '.qt', '.flv', '.swf', '.avchd'];
const AllAudioTypes = ['.mp3', '.m4a', '.aac', '.oga', '.flac', '.wav', '.pcm', '.aiff'];


export default function CheckFileExtensions(files: File[], allowedExtensions: string): IResponse {

  let extensions = allowedExtensions.split(',').map(x => x.trim());
  let extensionsNeedsToAdded: string[] = [];
  extensions = extensions.filter(x => {
    if (x.includes('image/*') || x.includes('video/*') || x.includes('audio/*')) {
        
      if (x.includes('image/*')) {
        extensionsNeedsToAdded = [...extensionsNeedsToAdded, ...AllImageTypes];
      }
      if (x.includes('video/*')) {
        extensionsNeedsToAdded = [...extensionsNeedsToAdded, ...AllVideoTypes];
      }
      if (x.includes('audio/*')) {
        extensionsNeedsToAdded = [...extensionsNeedsToAdded, ...AllAudioTypes];
      }
      return false;
    }
    return true;
  });
  extensions = [...extensions, ...extensionsNeedsToAdded];
  
  const filesAllowed: File[] = [];
  const fileNamesNotAllowed: string[] = [];
  files.forEach(file => {
    let fileAllowed = false;
    extensions.forEach(x => {
      if (file.name.endsWith(x)) {
        fileAllowed = true;
      }
    });
    if (fileAllowed) {
      filesAllowed.push(file);
    } else {
      fileNamesNotAllowed.push(file.name);
    }
  });
  let fileNamesNotAllowedSingleString = '';
  fileNamesNotAllowed.forEach(x => {
    if (fileNamesNotAllowedSingleString === '') {
      fileNamesNotAllowedSingleString = x;
    } else {
      fileNamesNotAllowedSingleString = fileNamesNotAllowedSingleString+ ', ' +x;
    }
  });
  const message = fileNamesNotAllowedSingleString !== '' ? fileNamesNotAllowedSingleString + ' will not be uploaded' : null;
  return {
    allowedFiles: filesAllowed,
    filesBanned: fileNamesNotAllowed,
    errorMessage: message
  };
}
