import { Fragment } from 'react';

import Grid from '@mui/material/Grid';

import { CaptionSecondary, SubtitleTwoPrimary } from '../components-atoms/TypographyComponents';
import { SxProps } from '@mui/material';

interface ListDividerProps {
  children: null | JSX.Element | (JSX.Element[] | JSX.Element | null)[];
  division: { name: string, length: number; };
  caption: { singular: string; plural: string; };
  halfMargin?: boolean;
  leftMargin?: boolean;
  noMargin?: boolean;
  verticalPadding?: boolean;
  noBottomPadding?: boolean;
  morePadding?: boolean;
  noPadding?: boolean;
  sx?: SxProps<any>
}

export function ListDivider({ children, division, caption, halfMargin, leftMargin, noMargin, verticalPadding, noBottomPadding, morePadding, noPadding, sx }: ListDividerProps): JSX.Element {
  return (
    <Fragment>
      <Grid
        container
        alignItems="center"
        sx={{
          margin: noMargin ? '0px' : halfMargin ? '24px 0px 0px' : '48px 0px 0px',
          padding: morePadding ? '0 0 0 40px' : noBottomPadding ? '24px 0 0 24px' : verticalPadding ? '24px 0px' : noPadding?'0px': '0px 24px',
          ...sx
        }}>
        <SubtitleTwoPrimary component="span" sx={{ margin: leftMargin ? '0 8px 0 8px' : '0 8px 0 0' }}>{division.name}</SubtitleTwoPrimary>
        <CaptionSecondary component="span">{division.length} {division.length > 1 ? caption.plural : caption.singular}</CaptionSecondary>
      </Grid>

      {children}
    </Fragment>
  );
}
