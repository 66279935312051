import { useState } from 'react';

import { Grid, IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { Section } from '../../components-molecules/section/Section';
import { BodyTwoSecondary, CaptionPrimary, HeaderSix } from '../../components-atoms/TypographyComponents';

import { LIGHT_THEME } from '../../constants/theme';
import '../../scss/components/_industry-insight.scss';

interface IProps {
  article: Article;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onPublishChange: (id: string) => void;
  moveUp: (ids: number) => void;
  moveDown: (idx: number) => void;
  index: number;
  count: number;
}
export function ArticleListCard({ article, index, onEdit, onDelete, onPublishChange, moveUp, moveDown, count }: IProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);


  return (
    <Section fullPadding>
      <Grid container item direction="row" columnSpacing={3} sx={{ width: '100%' }}>
        <Grid
          container
          justifyContent="center"
          item xs={2}
        >
          <Grid
            container
            justifyContent="center"
            item
            sx={{
              aspectRatio: '1/1',
              height: '96px',
              width: '96px',
              border: '1px solid rgba(0, 0, 0, 0.08)',
              borderRadius: '4px',
              objectFit: 'contain'
            }}>
            <img
              style={{ maxHeight: '100%', maxWidth: '100%', }}
              src={article.ThumbnailUrl || article.ImageUrl}
            />
          </Grid>
        </Grid>
        <Grid container item xs={7} direction="column">
          <BodyTwoSecondary> {article.IsPublished ? `Topic ${index + 1}` : 'Unlisted'}</BodyTwoSecondary>
          <HeaderSix sx={{ marginBottom: 0 }} >{article.Title}</HeaderSix>
        </Grid>
        <Grid xs={1} container item justifyContent="center" alignItems="center">
          <CaptionPrimary fontWeight={700} sx={{ color: article.IsPublished ? LIGHT_THEME.palette.success.main : LIGHT_THEME.palette.error.main }}>
            {article.IsPublished ? 'Published' : 'Unpublished'}
          </CaptionPrimary>
        </Grid>
        <Grid xs={2} container item justifyContent="center" alignItems="center" direction="row">
          <Grid xs={8} container item direction="column" justifyContent="flex-end" alignItems="flex-end" sx={{ width: '100%' }}>
            <IconButton
              data-testid="editors-pick-move-up-button"
              onClick={() => moveUp(index)}
              sx={{ '&:hover': { backgroundColor: 'transparent' } }}
              disabled={index === 0 || !article.IsPublished}
            >
              <KeyboardArrowUpIcon color={
                index === 0 || !article.IsPublished
                  ? 'disabled'
                  : 'primary'
              } />
            </IconButton>
            <IconButton
              data-testid="editors-pick-move-down-button"
              onClick={() => moveDown(index)}
              sx={{ '&:hover': { backgroundColor: 'transparent' } }}
              disabled={index === count - 1 || !article.IsPublished}
            >
              <KeyboardArrowDownIcon color={
                index === count - 1 || !article.IsPublished
                  ? 'disabled'
                  : 'primary'
              } />
            </IconButton>
          </Grid>
          <Grid item container xs={4} justifyContent="flex-end">
            <IconButton sx={{ margin: '0 -18px 0 0', padding: 0, height: '48px', width: '48px' }} onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ '.MuiPaper-root': { width: '160px', marginTop: '8px' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => { onEdit(article.Id); handleClose(); }}>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { onPublishChange(article.Id); handleClose(); }}>
          <ListItemText >{!article.IsPublished ? 'Publish' : 'Unpublish'}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { onDelete(article.Id); handleClose(); }}>
          <ListItemText sx={{ color: 'error.main' }}>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </Section>
  );
}

