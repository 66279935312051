/* eslint-disable @typescript-eslint/naming-convention */
import { Grid, Alert, LinearProgress } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormControlImage, FormControlInput } from '../../components-molecules/FormControl';
import { ModalCardComponent } from '../../components-molecules/Modal';
import RichTextEditor, { EditorValue, ToolbarConfig } from 'react-rte';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { LOGO_VALIDATION_WITH_TYPE, REQUIRED_VALIDATION } from '../../forms/_predefinedValidations';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { EditFocusOfTheMonthArticleRequest } from '../../service/serviceService';
import { AssetStatusButtonGroup } from '../AssetStatusButtonGroup';
import { IStyles } from '../../constants/theme';
import Add from '@mui/icons-material/Add';
import { SectionRow } from '../../components-molecules/section/SectionRow';
import { InfoComponent } from '../../components-atoms/InfoComponent';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { EditAttachmentFile } from '../../components-molecules/EditAttachmentFile';
import { UploadToAzure } from '../../hooks/uploadToAzure';
import { environment } from '../../environment';
import CheckFileExtensions from '../../hooks/checkFileExtensions';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonContainedPrimary } from '../../components-atoms/ButtonComponents';

const TOOLBAR_CONFIG: ToolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['BLOCK_TYPE_DROPDOWN', 'INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS',],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Paragraph', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-five' },
    { label: 'Heading Small', style: 'header-six' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'Bullet List', style: 'unordered-list-item' },
    { label: 'Numbered List', style: 'ordered-list-item' }
  ]
};

interface EditArticleModel{
  id: string;
  attachments: File[];
  body: string;
  image: File | null;
  title: string;
  published: any;
}

interface IOption {
  title: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  value: boolean;
}

interface onProgressResponse {
  fileName: string;
  percentage: number;
}

interface EditAttachments {
  AttachmentName: string;
  AttachmentUrl: string
}

export function  EditArticle(): JSX.Element {
  const navigate = useNavigate();
  const { state } = useLocation();
  const article = state.article;
  const buttonGroupOptions: IOption[] = [
    { title: 'Published', color: 'success', value: true },
    { title: 'Unpublished', color: 'error', value: false }
  ];
  const [errorMessage, ] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [html, setHtml] = useState<EditorValue>(RichTextEditor.createValueFromString(article.Description, 'html'));
  const [attachments, setAttachments] = useState<Array<File | EditAttachments>>([]);
  const [loadingProgress, setLoadingProgress] = useState<onProgressResponse[]>([]);
  const [percentage, setPercentage] = useState(0);
  const [token] = useTypedSelector((state) => [state.userReducer.token]);
  const [defaultValues] = useState<any>({
    body: article.Description,
    id: article.Id,
    published: article.IsPublished,
    title: article.Title,
    image: article.ImageUrl,
    attachments: article.Attachments
  });
  const { control, handleSubmit, formState: { isValid }, setValue } = useForm({ mode: 'all', reValidateMode: 'onChange', defaultValues: defaultValues });
  const [fileAttachmenErrorMessage, setFileAttachmenErrorMessage] = useState<string|undefined>();

  useEffect(() => {
    setValue('published', true, { shouldDirty: true, shouldValidate: true });
    setAttachments([...attachments, ...article.Attachments]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    calculatePercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProgress]);
  
  const submit: SubmitHandler<EditArticleModel> = async (data:EditArticleModel) => {
    setLoading(true);
    setScrollToTop(true);
    let articleImageFileName;
    let imageUrl; 
    if (typeof data.image === 'object') {
      articleImageFileName = await UploadToAzure({
        data: [data.image],
        onProgress: onProgress,
        folderName: environment.articleFolder
      });
      imageUrl = articleImageFileName[0].Url; 
    } else {
      const url:string = data?.image;
      const urlArray = url.split('/');
      imageUrl = urlArray[urlArray.length-1];
    }
    const items = attachments.filter((x:any) => x.AttachmentName === undefined);
    
    let attachmentFileNames;
    if (items.length > 0) {
      attachmentFileNames = await UploadToAzure({
        data: items,
        onProgress: onProgress,
        folderName: environment.articleFolder
        
      });
    }
    
    const requestAttachments:Array<any> = [];
    article.Attachments.forEach((x:any) => {
      requestAttachments.push({
        Name: x.AttachmentName,
        Url: x.AttachmentUrl
      });
    });
    attachmentFileNames?.forEach(async(x) => {
      requestAttachments.push({
        Name: x.Name,
        Size: x.Size,
        Url: x.Url
      });
    });
    
    if (token) {
      EditFocusOfTheMonthArticleRequest(token, {
        Id: data.id,
        Title: data.title,
        Description: data.body,
        ImageUrl: imageUrl,
        Published: data.published,
        Attachments: requestAttachments
      },
      () => {
        setLoading(false);
        navigate(-1);
      },
      () => {
        setLoading(false);
        setScrollToTop(false);
      });
    }
  };

  const onProgress = (data: onProgressResponse) => {
    const result = loadingProgress?.find(x => data.fileName === x.fileName);
    let tempLoadingProcesses = loadingProgress;

    if (result !== undefined) {
      tempLoadingProcesses = tempLoadingProcesses?.map(x => {
        if (x.fileName === data.fileName) {
          return {...x, percentage: data.percentage};
        } else {
          return x;
        }
      });
    } else {
      tempLoadingProcesses?.push(data);
    }
    
    setLoadingProgress(tempLoadingProcesses);
  };

  const calculatePercentage = () => {
    let total = 0;
    
    loadingProgress.forEach(x => {
      total += x.percentage / loadingProgress.length;
    });
    setPercentage(Math.round(total));
  };
  
  const GetAttachments = () => {
    if (attachments.length>0) {
      return (
        <>
          {
            attachments.map((file: any, idx) => {
              if (file?.AttachmentName!==undefined) {
                return (
                  <EditAttachmentFile
                    title={`File ${idx + 1}`}
                    fileName={file.AttachmentName}
                    url={file.AttachmentUrl}
                    removeClicked={()=>{
                      const temp = [...attachments].filter(f => attachments.indexOf(f) !== idx);
                      setAttachments(temp);
                      setValue('attachments', temp, { shouldDirty: true, shouldValidate: true });
                    }}
                  />
                );
              } else {
                return (
                  <SectionRow
                    title={`File ${idx + 1}`}
                    lines={[
                      { data: file.name, type: 'body1' }
                    ]}
                    endIcon={<RemoveCircleIcon color="primary" fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => {
                      const temp = [...attachments].filter(f => attachments.indexOf(f) !== idx);
                      setAttachments(temp);
                      setValue('attachments', temp, { shouldDirty: true, shouldValidate: true });
                    }} />}
                  />
                );
              }
            })
          }
        </>
      );
      
    } else {
      return (
        <InfoComponent
          title="File attachments"
          description="You haven’t set any file attachments yet. Start by selecting files in a dialog. You can add multiple files at once"
        />
      );
    }
  };

  return (
    <FormPageComponent
      hasError={!!errorMessage}
      title="Edit Topic"
      buttonTitle="Submit"
      buttonDisabled={!isValid || loading}
      buttonLoading={loading}
      close={() => navigate(-1)}
      submit={handleSubmit(submit)}
      scrollToTop={scrollToTop}
    >
      <Fragment>
        <Grid item sx={{ flexGrow: 1 }}>
          {!!errorMessage
            && <Alert severity="error">{errorMessage}</Alert>
          }
        </Grid>
        
        {loading && <LinearProgress variant="determinate" color="secondary" value={percentage}  sx={{ zIndex: 2 }} />}
        <ModalCardComponent>
          <SectionHeader title="Topic Image" />
          <Fragment>
            <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
              <Grid item xs={12}>
                <FormControlImage
                  accept="image/*"
                  data-testid="edit-article-image"
                  control={control}
                  initialValue={article.ThumbnailUrl || article.ImageUrl}
                  initialFileType='image'
                  field={{
                    label: 'Image',
                    name: 'image',
                    inputType: 'logo',
                    validation: {
                      required: REQUIRED_VALIDATION,
                      validate: LOGO_VALIDATION_WITH_TYPE('image/*')
                    }
                  }}
                  title="Image"
                  subtitle="Square image with 640px x 640px resolution works best."
                />
              </Grid>
            </Grid>
          </Fragment>
        </ModalCardComponent>
        
        <ModalCardComponent>
          <SectionHeader title="Topic Content" />
          <Grid item container columnSpacing={4} sx={styles.fg1}>
            <Grid item xs={12} sx={styles.mb16}>
              <FormControlInput
                data-testid="add-article-title"
                control={control}
                field={{
                  label: 'Title',
                  name: 'title',
                  inputType: 'text',
                  validation: {
                    required: REQUIRED_VALIDATION,
                  }
                }}
              /> 
            </Grid>
            <Grid item xs={12}>
              <RichTextEditor
                {...control.register('body', { required: REQUIRED_VALIDATION })}
                value={html}
                toolbarConfig={TOOLBAR_CONFIG}
                placeholder="Body"
                onChange={(val: EditorValue) => {
                  setHtml(val);
                  setValue('body', val.toString('html'), { shouldDirty: true, shouldValidate: true });
                }}
              />
            </Grid>
          </Grid>
        </ModalCardComponent>
        <ModalCardComponent>
          <SectionHeader
            title="File attachments"
            subtitle="Select files you would like to attach to this newsletter. Maximum file size 5MB."
            errorMessage={fileAttachmenErrorMessage}
            button={
              <label htmlFor="inputid">
                <input
                  data-testid="attachments-upload"
                  id="inputid"
                  accept=".pdf, .pptx, .ppt"
                  type="file"
                  multiple
                  onClick={event => {
                    event.currentTarget.files = null;
                    event.currentTarget.value = '';
                  }}
                  style={{ display: 'none' }}
                  {...control.register('attachments', { required: { value: false, message: '' } })}
                  onChange={(e) => {
                    setFileAttachmenErrorMessage(undefined);
                    let fileArray: File[] = Array.prototype.slice.call(e.target.files);
                    const items = attachments.filter((x:any) => x.AttachmentName === undefined);
                    fileArray = fileArray.filter(newFile=>{
                      let fileNotExist = true;
                      items.forEach((file:any)=>{
                        if(newFile.size === file.size && file.name === newFile.name){
                          fileNotExist = false;
                        }
                      });
                      return fileNotExist;
                    });
                    const response = CheckFileExtensions(fileArray,'.pdf, .pptx, .ppt');
                    if(response.errorMessage!==null){
                      setFileAttachmenErrorMessage(response.errorMessage);
                    }
                    setAttachments([...attachments, ...response.allowedFiles]);
                    setValue('attachments', [...attachments, ...fileArray], { shouldDirty: true, shouldValidate: true });
                  }}
                />
                <ButtonContainedPrimary
                  data-testid="add-attachment"
                  startIcon={<Add />}
                  sx={{ margin: '0 0 0 8px', width: 'max-content', pointerEvents: 'none' }}
                >
                  Add Attachment
                </ButtonContainedPrimary>
              </label>
            } />
          <Grid xs={12} item container sx={{ flexGrow: 1, width: '100%' }}>
            <GetAttachments/>
          </Grid>
        </ModalCardComponent>
        <ModalCardComponent>
          <SectionHeader title="Topic Status" />
          <Grid item container columnSpacing={4} sx={styles.fg1}>
            <Grid item xs={12} sx={styles.mb16}>
              <AssetStatusButtonGroup
                subtitle="Published topics are visible to customers and unpublished topics are hidden."
                
                data-testid="add-article-published"
                onChange={(value: boolean) => setValue('published', value, { shouldDirty: true, shouldValidate: true })}
                options={buttonGroupOptions}
              />
            </Grid>
          </Grid>
        </ModalCardComponent>
        {loading ? (
          <div style={styles.overlay}>
          </div>
        ) : (
          <></>
        )}
      </Fragment>
    </FormPageComponent>
  );
}

const styles: IStyles = {
  fg1: { flexGrow: 1 },
  mb16: { marginBottom: '16px' },
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  }
};
