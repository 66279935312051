/* eslint-disable @typescript-eslint/naming-convention */
import { Fragment, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import { FormHelperText, LinearProgress } from '@mui/material';

import { MerchantAddFormTypes, MERCHANT_FORM } from '../../forms/merchant';
import { AddMerchantRequest } from '../../service/merchantsService';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { UploadToAzure } from '../../hooks/uploadToAzure';

import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { FormControlImage, FormControlInput, FormControlSelect } from '../../components-molecules/FormControl';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { environment } from '../../environment';
import { IStyles } from '../../constants/theme';
 
interface onProgressResponse {
  fileName: string;
  percentage: number;
}

export function AddMerchant(): JSX.Element {
  const { control, handleSubmit, setError, formState: { isValid, errors }, reset } = useForm({ mode: 'all', reValidateMode: 'onBlur' });
  const token = useTypedSelector((state) => state.userReducer.token);
  const [loading, setLoading] = useState(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [loadingProgress, setLoadingProgress] = useState<onProgressResponse[]>([]);
  const [percentage, setPercentage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    calculatePercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProgress]);
  
  const submit: SubmitHandler<MerchantAddFormTypes> = async (data) => {
    if (token) {
      setLoading(true);
      setScrollToTop(true);
      if (data.logo) {
        const merchantLogo = await UploadToAzure({
          data: [data.logo],
          onProgress: onProgress,
          folderName: environment.merchantFolder
        });

        const imageUrl = merchantLogo[0].Url;

        AddMerchantRequest(
          token,
          {
            MerchantName: data.merchantName,
            Logo: imageUrl,
            PayerCode: data.payerCode,
            Audience: data.audienceName,
            AddressLine1: data.addressLine1,
            AddressLine2: data.addressLine1,
            City: data.city,
            PostCode: data.postCode,
            County: data.county,
            Phone: data.phone,
            Email: data.email,
            Latitude: undefined,
            Longitude: undefined
          },
          (response) => {
            setLoading(false);
            setScrollToTop(false);
            navigate(`/admin/merchants/${response.Result}`, { replace: true });
          },
          (error) => {
            if (error.response?.data.Error) {
              reset(data);
              setLoading(false);
              setScrollToTop(false);
              setError( error.response.data.Details, { message: error.response.data.Error });
            }
          }
        );
      }
    }
  };

  const onProgress = (data: onProgressResponse) => {
    const result = loadingProgress?.find(x => data.fileName === x.fileName);
    let tempLoadingProcesses = loadingProgress;

    if (result !== undefined) {
      tempLoadingProcesses= tempLoadingProcesses?.map(x => {
        if (x.fileName === data.fileName) {
          return {...x, percentage: data.percentage};
        } else {
          return x;
        }
      });
    } else {
      tempLoadingProcesses?.push(data);
    }
    setLoadingProgress(tempLoadingProcesses);
  };

  const calculatePercentage = () => {
    let total = 0;
    loadingProgress.forEach(x=>{
      total += x.percentage / loadingProgress.length;
    });
    setPercentage(Math.round(total));
  };

  return (
    <FormPageComponent
      hasError={!!errors.merchantName}
      title="New Merchant"
      buttonTitle="New Merchant"
      buttonDisabled={!isValid || loading}
      close={() => navigate('/admin/merchants')}
      submit={handleSubmit(submit)}
      buttonLoading={loading}
      scrollToTop={scrollToTop}
    >
      {loading ? (
        <LinearProgress variant="determinate" color="secondary" value={percentage}  sx={{ zIndex: 2 }} />
      ) : (
        <></>
      )}
      <Fragment>
        {MERCHANT_FORM.map(section => {
          return (
            <ModalCardComponent key={section.title}>
              <SectionHeader title={section.title} />
              <Fragment>
                <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
                  {section.fields.map(field =>
                    <Grid key={field.name} item xs={field.name === 'city' || field.name === 'postCode' ? 6 : 12}>
                      {field.inputType === 'select'
                        ? <FormControlSelect control={control} field={field} />
                        : field.inputType === 'logo'
                          ? <FormControlImage accept='image/*' subtitle='Square image with maximum file size up to 2 MB works best.' control={control} field={field} removeHelperTextMarginLeft />
                          : <FormControlInput control={control} field={field} />
                      }
                      {field.helperText 
                        && <FormHelperText sx={{ padding: '0 14px' }}>
                          {field.helperText}
                        </FormHelperText>
                      }
                    </Grid>
                  )}
                </Grid>
              </Fragment>
            </ModalCardComponent>
          );
        })}
        {loading ? (
          <div style={styles.overlay}>
          </div>
        ) : (
          <></>
        )}
      </Fragment>
    </FormPageComponent>
  );
}

const styles: IStyles = {
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  }
};
