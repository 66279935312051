/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState, ChangeEvent, Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import RichTextEditor, { EditorValue, ToolbarConfig } from 'react-rte';

import { Grid, Alert, IconButton, CircularProgress, LinearProgress, Box } from '@mui/material';
import Add from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SearchIcon from '@mui/icons-material/Search';

import { ModalCardComponent } from '../../components-molecules/Modal';
import { SectionRow } from '../../components-molecules/section/SectionRow';
import { FormControlInput } from '../../components-molecules/FormControl';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { MiniCard, MiniDialog } from '../../components-molecules/dialogs/MiniDialog';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { ListDivider } from '../../components-molecules/Divider';
import { SortButton } from '../../components-molecules/SortButton';

import { ButtonContainedPrimary, ButtonTextPrimary } from '../../components-atoms/ButtonComponents';
import { InfoComponent } from '../../components-atoms/InfoComponent';
import { BodyOnePrimary, BodyTwoSecondary, CaptionSecondary } from '../../components-atoms/TypographyComponents';

import { REQUIRED_VALIDATION } from '../../forms/_predefinedValidations';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import CheckFileExtensions from '../../hooks/checkFileExtensions';
import BrandAssetFileTypes from '../../utils/BrandAssetsSupportedFileTypes';
import { CreateThreadAttachment, CreateThreadRequest, GetThreadMerchants, ThreadMerchant, ThreadMerchantEmployee, MerchantGroup, GetThreadMerchantEmployees, GetMerchantsEmployeeResponse, GetThreadTerritoryManagers, ThreadTerritoryManagersResponse, ThreadTerritoryManagers } from '../../service/serviceService';
import { IStyles, LIGHT_THEME } from '../../constants/theme';
import { v4 as uuidv4 } from 'uuid';
import { UploadToAzure } from '../../hooks/uploadToAzure';
import { FilterMenu, IFilterOptions } from '../../components-molecules/FilterMenu';
import { GetSalesManagerRoles } from '../../service/adminService';
import { SearchbarWithDeboubce } from '../../components-molecules/SearchBarWithDebounce';
interface IThread {
  subject: string;
  body: string;
}

interface onProgressResponse {
  fileName: string;
  percentage: number;
  fileSize: number;
  loadedBytes: number;
}

type RecipientGroup = 'merchant' | 'territoryManager';

export function NewThread(): JSX.Element {
  const navigate = useNavigate();
  const { state } = useLocation();

  const token = useTypedSelector(state => state.userReducer.token);

  const [html, setHtml] = useState<EditorValue>(RichTextEditor.createEmptyValue());
  const [dialog, setDialog] = useState(false);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [merchants, setMerchants] = useState<MerchantGroup[]>([]);
  const [merchantEmployees, setMerchantEmployees] = useState<GetMerchantsEmployeeResponse[]>([]);
  const [tempMerchants, setTempMerchants] = useState<ThreadMerchant | null>(null);
  const [tempEmployees, setTempEmployees] = useState<ThreadMerchantEmployee[]>([]);
  const [territoryManagers, setTerritoryManagers] = useState<ThreadTerritoryManagersResponse[]>([]);

  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [sort, setSort] = useState('asc');
  const [recipientLoading, setRecipientLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [fileAttachmenErrorMessage, setFileAttachmenErrorMessage] = useState<string | undefined>();
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingProgress, setLoadingProgress] = useState<onProgressResponse[]>([]);
  const [percentage, setPercentage] = useState(0);
  const [merchantListLoading, setMerchantListLoading] = useState(false);
  const [merchantEmployeesLoading, setMerchantEmployeesLoading] = useState(false);
  const [employeesSelected, setEmployeesSelected] = useState<ThreadMerchantEmployee[]>([]);
  const [merchantSelected, setMerchantSelected] = useState<ThreadMerchant | null>(null);
  const [merchantName, setMerchantName] = useState<string>('');

  const [tempTerritoryManager, setTempTerritoryManager] = useState<ThreadTerritoryManagers[]>([]);
  const [selectedTerritoryManagers, setSelectedTerritoryManagers] = useState<ThreadTerritoryManagers[]>([]);
  const recipientType: RecipientGroup = state.currentTab === 'accessRights' ? 'territoryManager' : 'merchant';
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>({ Roles: [] });
  const [filter, setFilter] = useState<ISelectedOptions>({ Roles: [] });
  const { control, handleSubmit, formState: { isValid }, setValue } = useForm({ mode: 'all', reValidateMode: 'onChange' });

  useEffect(() => {
    calculatePercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProgress]);
  useEffect(() => {
    if (token) {
      GetSalesManagerRoles(token,
        (res) => {
          setFilterOptions({
            Roles: [...res.Result.map(m => ({ title: m.Name, value: m.Id })), { title: 'Territory Manager', value: '00000000-0000-0000-0000-000000000000' }]
          });
        },
        (err) => { console.log(err); });
    }
  }, [token]);
  const calculatePercentage = () => {
    let totalPercentage = 0;

    loadingProgress.forEach(x => {
      totalPercentage += x.percentage / loadingProgress.length;
    });

    if (percentage < totalPercentage) {
      setPercentage(Math.round(totalPercentage));
    }
  };

  const onProgress = (data: onProgressResponse) => {
    const result = loadingProgress?.find(x => data.fileName === x.fileName);
    let tempLoadingProcesses = loadingProgress;

    if (result !== undefined) {
      tempLoadingProcesses = tempLoadingProcesses?.map(x => {
        if (x.fileName === data.fileName) {
          return { ...x, percentage: data.percentage, loadedBytes: data.loadedBytes };
        } else {
          return x;
        }
      });
    } else {
      tempLoadingProcesses?.push(data);
    }
    setLoadingProgress(tempLoadingProcesses);
  };

  const submit: SubmitHandler<IThread> = async (data) => {
    if (token) {
      setScrollToTop(true);
      setLoading(true);
      const uuid = uuidv4() as GUID;
      if (attachments.length > 0) {
        const attachmentFileNames = await UploadToAzure({
          data: attachments,
          onProgress: onProgress,
          folderName: uuid,
          useDocRepoContainer: true,
          isDownloadable: true
        });
        const requestAttachments: CreateThreadAttachment[] = [];
        attachmentFileNames.forEach(async (x) => {
          requestAttachments.push({
            FileName: x.Name,
            FileUrl: uuid + '/' + x.Url,
            FileType: x.FileType
          });
        });
        setPercentage(100);
        CreateThreadRequest(
          token,
          {
            Id: uuid,
            Attachments: requestAttachments,
            Body: data.body,
            Subject: data.subject,
            Recipients: recipientType === 'merchant' ? employeesSelected.map(employee => employee.Id) : selectedTerritoryManagers.map(manager => manager.Id),
          },
          () => { setLoading(false); navigate(state.navigateBackTo, { state: { currentTab: state.currentTab } }); },
          (error) => {
            setErrorMessage(error.response?.data.Error || '');
            setLoading(false);
          }
        );
      } else {
        CreateThreadRequest(
          token,
          {
            Id: uuid,
            Body: data.body,
            Subject: data.subject,
            Recipients: recipientType === 'merchant' ? employeesSelected.map(employee => employee.Id) : selectedTerritoryManagers.map(manager => manager.Id),
          },
          () => { setLoading(false); navigate(state.navigateBackTo, { state: { currentTab: state.currentTab } }); },
          (error) => {
            setErrorMessage(error.response?.data.Error || '');
            setLoading(false);
          }
        );
      }
    }
  };
  const getTerritoryManagerRecipientList = () => {
    if (token) {
      setRecipientLoading(true);
      GetThreadTerritoryManagers(
        token, {
          Query: searchQuery,
          OrderBy: sort,
          RoleIds: filter.Roles
        },
        response => {
          setTerritoryManagers(response.Result),
          setRecipientLoading(false);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );

    }
  };
  useEffect(() => {
    if (token) {
      if (recipientType === 'merchant') {
        if (!merchantSelected) {
          getMerchantListData();
        } else {
          getMerchantEmployeesData();
        }
      } else if (recipientType === 'territoryManager') {
        getTerritoryManagerRecipientList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, searchQuery, sort, filter]);

  const getMerchantListData = () => {
    if (token) {
      setMerchantListLoading(true);
      GetThreadMerchants(token, {
        OrderBy: sort,
        Query: searchQuery,
      }, response => {
        setMerchants(response.Result);
        setMerchantListLoading(false);
      }, // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => { });
    }
  };
  const getMerchantEmployeesData = () => {
    if (token) {
      setMerchantEmployeesLoading(true);
      GetThreadMerchantEmployees(token, {
        OrderBy: sort,
        Query: searchQuery,
        MerchantId: tempMerchants?.Id ? tempMerchants?.Id : '',
      }, response => {
        setMerchantEmployees(response.Result);
        setMerchantEmployeesLoading(false);
      }, () => {
        setMerchantEmployeesLoading(false);
      });
    }
  };




  const getSelectedMerchant = (merchant: ThreadMerchant) => {

    let isSelected = false;
    if (tempMerchants?.Id === merchant.Id) {
      isSelected = true;
    }

    return isSelected;
  };

  const searchOnChange=(text:string) => {
    setSearchQuery(text);
  };

  const attachmentsOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFileAttachmenErrorMessage(undefined);
    let fileArray: File[] = Array.prototype.slice.call(event.target.files);
    const response = CheckFileExtensions(fileArray, BrandAssetFileTypes);
    if (response.errorMessage !== null) {
      setFileAttachmenErrorMessage(response.errorMessage);
    }
    fileArray = response.allowedFiles.filter(newFile => {
      let fileNotExist = true;
      attachments.forEach(file => {
        if (newFile.size === file.size && file.name === newFile.name) {
          fileNotExist = false;
        }
      });
      return fileNotExist;
    });
    setAttachments([...attachments, ...fileArray]);
    setValue('attachments', [...attachments, ...fileArray], { shouldDirty: true, shouldValidate: true });
  };

  const NoAttachmentSelected = () => {
    return (
      <InfoComponent
        title="File attachments"
        description="You haven’t any file attachments yet. Start by selecting files in a dialog. You can add multiple files at once"
      />
    );
  };

  const GetAttachmentList = () => {
    if (attachments.length === 0) {
      return <></>;
    }
    return (
      <>
        {attachments.map((file: File, idx) => (
          <Fragment key={idx}>
            <SectionRow
              title={`File ${idx + 1}`}
              lines={[
                { data: file.name, type: 'body1' }
              ]}
              endIcon={
                <RemoveCircleIcon color="primary" fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => {
                  const temp = [...attachments].filter(f => attachments.indexOf(f) !== idx);
                  setAttachments(temp);
                  setValue('attachments', temp, { shouldDirty: true, shouldValidate: true });
                }} />
              }
            />
          </Fragment>
        ))}
      </>
    );
  };

  const removeMerchantFromList = (id: GUID) => {
    const list = employeesSelected.filter(merchant => merchant.Id !== id);
    if (list.length === 0) {
      setTempMerchants(null);
      setMerchantSelected(null);
    }
    setEmployeesSelected(list);
  };

  const GetMerchantList = () => {
    if (!employeesSelected) {
      return <></>;
    }
    return (
      <Fragment>
        {employeesSelected.map((employee) =>
          <Grid key={employee.Id} container alignItems="center" justifyContent="space-between" sx={{ width: '100%', marginTop: '12px' }}>
            <Grid item sx={{ width: '180px', flexGrow: 1, flexShrink: 1 }}>
              <BodyTwoSecondary>{employee.Role}</BodyTwoSecondary>
              <BodyOnePrimary>{employee.FirstName + ' ' + employee.LastName}</BodyOnePrimary>
              <CaptionSecondary>{employee.Email}</CaptionSecondary>
            </Grid>
            <Grid item sx={{ width: '180px', flexGrow: 1, flexShrink: 1 }}>
              <BodyTwoSecondary>Merchant</BodyTwoSecondary>
              <BodyOnePrimary>{merchantName}</BodyOnePrimary>
            </Grid>
            <Grid item>
              <RemoveCircleIcon
                color="primary"
                fontSize="small"
                sx={{ cursor: 'pointer' }}
                onClick={() => { removeMerchantFromList(employee.Id); }} />
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  };

  const removeTerritoryManagerFromList = (id: GUID) => {
    const list = selectedTerritoryManagers.filter(manager => manager.Id !== id);
    setSelectedTerritoryManagers(list);
  };

  const GetTerritoryManagerList = () => {
    if (!selectedTerritoryManagers) {
      return <></>;
    }

    return (
      <Fragment>
        {selectedTerritoryManagers.map((manager) =>
          <Fragment key={manager.Id}>
            <Grid container alignItems="center" sx={{ margin: '16px 0' }}>
              <Grid item sx={{ width: '180px', flexGrow: 1, flexShrink: 1 }}>
                <BodyTwoSecondary style={{ marginBottom: '4px' }}>Territory Manager</BodyTwoSecondary>
                <BodyOnePrimary>{manager.FirstName + ' ' + manager.LastName}</BodyOnePrimary>
                <CaptionSecondary>{manager.Email}</CaptionSecondary>
              </Grid>
              {manager.Merchants && manager.Merchants.length > 0 && (
                <Grid item sx={{ width: '180px', flexGrow: 1, flexShrink: 1 }}>
                  <BodyTwoSecondary style={{ marginBottom: '4px' }}>Merchants</BodyTwoSecondary>
                  <BodyOnePrimary
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2
                    }}
                  >
                    {manager.Merchants.map(merchant => merchant).join(', ')}
                  </BodyOnePrimary>
                </Grid>
              )}
              <Grid item>
                <RemoveCircleIcon color="primary" fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => removeTerritoryManagerFromList(manager.Id)} />
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Fragment>
    );
  };

  const NoRecipientSelected = () => {
    return (
      <InfoComponent
        title="Thread Recipients"
        description="You haven’t set any recipients for this thread yet. You can select single or multiple users of the same organisation."
      />
    );
  };

  const GetRecipientList = () => {
    switch (recipientType) {
      case 'merchant':
        if (employeesSelected.length > 0) {
          return <GetMerchantList />;
        } else {
          return <NoRecipientSelected />;
        }
      case 'territoryManager':
        if (selectedTerritoryManagers.length > 0) {
          return <GetTerritoryManagerList />;
        } else {
          return <NoRecipientSelected />;
        }
      default:
        return <></>;
    }
  };

  const nextClicked = () => {
    setMerchantSelected(tempMerchants);
    setMerchantName(tempMerchants ? tempMerchants.Name : '');
    setShowSearchInput(false);
    setSort('asc');
    setSearchQuery('');
    getMerchantEmployeesData();
  };

  const closeClicked = () => {
    setDialog(false);
    if (employeesSelected.length === 0) {
      setTempMerchants(null);
      setMerchantSelected(null);
    }
  };

  const submitPressed = () => {
    const temp = tempEmployees.filter(x => employeesSelected.map(y => {
      if (x.Id === y.Id) {
        return true;
      }
    }));
    setEmployeesSelected(temp);
    if (temp.length === 0) {
      setTempMerchants(null);
      setMerchantSelected(null);
    }
    setTempEmployees([]);

    setShowSearchInput(false);
    setSort('asc');
    setSearchQuery('');
    setDialog(false);
  };

  const customBottomButtons = () => {
    return <Box sx={{ height: '32px', margin: '16px', alignSelf: 'end', display: 'flex', justifyContent: 'space-between', width: '99%', paddingLeft: '16px', paddingRight: '8px' }}>
      {
        merchantSelected === null
          ? <>
            <ButtonTextPrimary onClick={closeClicked} data-testid='close-button'>Cancel</ButtonTextPrimary>
            <ButtonTextPrimary onClick={nextClicked} disabled={tempMerchants === null} data-testid='close-button'>Next</ButtonTextPrimary>
          </>
          : <>
            <ButtonTextPrimary onClick={closeClicked} data-testid='close-button'>Cancel</ButtonTextPrimary>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <ButtonTextPrimary onClick={submitPressed} data-testid='close-button'>Submit</ButtonTextPrimary>
            </div>
          </>
      }
    </Box>;
  };
  const saveMerchant = (merchant: ThreadMerchant) => {
    if (tempMerchants?.Id !== merchant.Id) {
      setTempMerchants(merchant);
    } else if (tempMerchants?.Id === merchant.Id) {
      setTempMerchants(null);
    }
  };

  const getMerchantList = () => {
    if (merchantListLoading) {
      return <Grid container item justifyContent="center" alignItems="center">
        <CircularProgress
          color="primary"
          size={50}
          style={{ zIndex: 999, margin: '56px 0' }}
        />
      </Grid>;
    }
    return merchants.map(group => {
      return <>
        <ListDivider
          key={group.Group}
          division={{ name: group.Group, length: group.Merchants.length }}
          caption={{ singular: 'Merchant', plural: 'Merchants' }}
          halfMargin
        >
          {
            group.Merchants.map(merchant => <MiniCard
              key={merchant.Id}
              onClick={() => saveMerchant(merchant)}
              title={`${merchant.Name}`}
              subtitle={merchant.Payercode}
              selected={getSelectedMerchant(merchant)}
              imgUrl={merchant.Logo}
            />)
          }
        </ListDivider>
      </>

      ;
    }
    );
  };
  const saveEmployee = (employee: ThreadMerchantEmployee) => {
    let temp = JSON.parse(JSON.stringify(tempEmployees));
    if (tempEmployees.length === 0) {
      temp.push(employee);
    } else {
      if (getSelectedMerchantEmployee(employee)) {
        temp = temp.filter((x: ThreadMerchantEmployee) => x.Id !== employee.Id);
      } else {
        temp.push(employee);
      }
    }
    setTempEmployees(temp);
  };
  const getSelectedMerchantEmployee = (employee: ThreadMerchantEmployee) => {
    let isSelected = false;
    tempEmployees.forEach(x => {
      if (x.Id === employee.Id) {
        isSelected = true;
      }
    });
    return isSelected;
  };

  const getEmployeeList = () => {
    if (merchantEmployeesLoading) {
      return <Grid container item justifyContent="center" alignItems="center">
        <CircularProgress
          color="primary"
          size={50}
          style={{ zIndex: 999, margin: '56px 0' }}
        />
      </Grid>;
    }
    return <>

      {
        merchantEmployees.length === 0
          ? <Grid sx={{ width: '100%', height: '340px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <BodyOnePrimary style={{ textAlign: 'center' }}>{searchQuery ? '0 results found.' : 'You have no active users for this merchant yet.'}</BodyOnePrimary>
          </Grid>
          : merchantEmployees.map(group => {
            return <>
              <ListDivider
                key={group.Group}
                division={{ name: group.Group, length: group.Users.length }}
                caption={{ singular: 'Merchant', plural: 'Merchants' }}
                halfMargin
              >
                {
                  group.Users.map(employee => <MiniCard
                    key={employee.Id}
                    onClick={() => saveEmployee(employee)}
                    title={`${employee.FirstName} ${employee.LastName}`}
                    subtitle={employee.Role}
                    secondSubtitle={employee.Email}
                    selected={getSelectedMerchantEmployee(employee)}
                  />)
                }
              </ListDivider>
            </>;
          }
          )
      }
    </>;
  };


  const addRecipientsClicked = () => {
    setDialog(true);
    
    switch (recipientType) {
      case 'territoryManager':
        setTempTerritoryManager(selectedTerritoryManagers);
        break;
      case 'merchant':
        setTempEmployees(employeesSelected);
        break;
      default:
        break;
    }
  };

  const handleSelectionAllEmployees = (selection: boolean) => {
    if (selection) {
      const employees: ThreadMerchantEmployee[] = [];
      merchantEmployees.forEach(group => group.Users.forEach(x => employees.push(x)));
      setTempEmployees(employees);
    } else {
      setTempEmployees([]);
    }
  };

  const handleSelectionAllTerritoryManagers = (selection: boolean) => {
    if (selection) {
      const managers: ThreadTerritoryManagers[] = [];
      territoryManagers.forEach(group => group.TerritoryManagers.forEach(x => managers.push(x)));
      setTempTerritoryManager(managers);
    } else {
      setTempTerritoryManager([]);
    }
  };


  const customTerritoryManagerBottomButtons = () => {
    return <Box sx={{ height: '32px', margin: '16px', alignSelf: 'end', display: 'flex', justifyContent: 'space-between', width: '99%', paddingLeft: '16px', paddingRight: '8px' }}>
      <ButtonTextPrimary onClick={closeClicked} data-testid='close-button'>Cancel</ButtonTextPrimary>
      <ButtonTextPrimary onClick={territoryManagerSubmitPressed} disabled={tempTerritoryManager.length === 0} data-testid='close-button'>Submit</ButtonTextPrimary>
    </Box>;
  };

  const territoryManagerSubmitPressed = () => {
    const temp = tempTerritoryManager.filter(x => selectedTerritoryManagers.map(y => {
      if (x.Id === y.Id) {
        return true;
      }
    }));
    setSelectedTerritoryManagers(temp);
    setTempTerritoryManager([]);
    setShowSearchInput(false);
    setSort('asc');
    setSearchQuery('');
    setDialog(false);
  };

  const saveManager = (manager: ThreadTerritoryManagers) => {
    let temp = JSON.parse(JSON.stringify(tempTerritoryManager));
    if (tempEmployees.length === 0) {
      temp.push(manager);
    } else {
      if (getSelectedTerritoryManager(manager)) {
        temp = temp.filter((x: ThreadMerchantEmployee) => x.Id !== manager.Id);
      } else {
        temp.push(manager);
      }
    }
    setTempTerritoryManager(temp);
  };
  const getSelectedTerritoryManager = (manager: ThreadTerritoryManagers) => {
    let isSelected = false;
    tempTerritoryManager.forEach(x => {
      if (x.Id === manager.Id) {
        isSelected = true;
      }
    });
    return isSelected;
  };
  const getTerritoryManagerList = () => {
    if (recipientLoading) {
      return <Grid container item justifyContent="center" alignItems="center">
        <CircularProgress
          color="primary"
          size={50}
          style={{ zIndex: 999, margin: '56px 0' }}
        />
      </Grid>;
    }
    return territoryManagers.map(group => {
      return <>
        <ListDivider
          key={group.Group}
          division={{ name: group.Group, length: group.TerritoryManagers.length }}
          caption={{ singular: 'Manager', plural: 'Managers' }}
          halfMargin
        >
          {
            group.TerritoryManagers.map(manager => <MiniCard
              key={manager.Id}
              onClick={() => saveManager(manager)}
              title={`${manager.FirstName} ${manager.LastName}`}
              subtitle={manager.Merchants.map(merchant => merchant).join(', ')}
              secondSubtitle={manager.Email}
              thirdSubtitle={manager.RoleName}
              selected={getSelectedTerritoryManager(manager)}
            />)
          }
        </ListDivider>
      </>
      ;
    }
    );
  };


  return (
    <>
      <FormPageComponent
        hasError={!!errorMessage}
        title={`New Thread - ${recipientType === 'merchant' ? 'Merchants' : 'Managers'}`}
        buttonTitle="Submit"
        buttonDisabled={!isValid || !(employeesSelected.length > 0 || selectedTerritoryManagers.length > 0) || loading}
        buttonLoading={loading}
        close={() => navigate(state.navigateBackTo, { state: { currentTab: state.currentTab } })}
        submit={handleSubmit(submit)}
        scrollToTop={scrollToTop}
      >
        <Grid item sx={{ flexGrow: 1 }}>
          {!!errorMessage
            && <Alert severity="error">{errorMessage}</Alert>
          }
        </Grid>
        {loading ? <LinearProgress variant="determinate" color="secondary" value={percentage} sx={{ zIndex: 2 }} /> : <></>}
        <ModalCardComponent showOverflowingChild>
          <SectionHeader title="Thread details" />
          <Grid item container rowSpacing={2} sx={{ flexGrow: 1 }}>
            <Grid item xs={12}>
              <FormControlInput
                data-testid="new-thread-subject"
                control={control}
                field={{
                  label: 'Subject',
                  name: 'subject',
                  inputType: 'text',
                  validation: {
                    maxLength: {
                      value: 50,
                      message: 'Subject should be up to 50 characters.',
                    },
                    required: REQUIRED_VALIDATION,
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <RichTextEditor
                {...control.register('body', { required: REQUIRED_VALIDATION, validate: val => val !== EMPTY_HTML })}
                value={html}
                toolbarConfig={TOOLBAR_CONFIG}
                placeholder="Body"
                onChange={(val: EditorValue) => {
                  setHtml(val);
                  setValue('body', val.toString('html'), { shouldDirty: true, shouldValidate: true });
                }}
              />
            </Grid>
          </Grid>
        </ModalCardComponent>
        <ModalCardComponent>
          <SectionHeader
            title="File attachments"
            subtitle="Select files you would like to attach to this thread."
            errorMessage={fileAttachmenErrorMessage}
            button={
              <label htmlFor="inputid">
                <input
                  data-testid="attachments-upload"
                  id="inputid"
                  accept={BrandAssetFileTypes}
                  type="file"
                  multiple
                  onClick={event => {
                    event.currentTarget.files = null;
                    event.currentTarget.value = '';
                  }}
                  {...control.register('attachments', { required: { value: false, message: '' } })}
                  onChange={(e) => attachmentsOnChange(e)}
                  style={{ display: 'none' }}
                />
                <ButtonContainedPrimary
                  data-testid="add-attachment"
                  startIcon={<Add />}
                  sx={{ margin: '0 0 0 8px', width: 'max-content', pointerEvents: 'none' }}
                >
                  Add Attachment
                </ButtonContainedPrimary>
              </label>
            } />
          <Grid xs={12} item container sx={{ flexGrow: 1, width: '100%' }}>
            {attachments.length > 0
              ? <GetAttachmentList />
              : <NoAttachmentSelected />
            }
          </Grid>
        </ModalCardComponent>
        <ModalCardComponent>
          <SectionHeader
            title="Recipients"
            button={
              <ButtonContainedPrimary
                data-testid="add-recipients"
                onClick={addRecipientsClicked}
                startIcon={<Add />}
              >
                Add Recipients
              </ButtonContainedPrimary>
            }
          />
          <Grid item container sx={{ flexGrow: 1 }}>
            <GetRecipientList />
          </Grid>
        </ModalCardComponent>
        {loading ? (
          <div style={styles.overlay}>
          </div>
        ) : (
          <></>
        )}
      </FormPageComponent>
      {/* <SelectTerritoryManagersDialog /> */}
      <MiniDialog
        title="Select Merchant"
        open={dialog && recipientType === 'merchant'}
        close={closeClicked}
        customBottomButtons={customBottomButtons}
        titleMargin
      >
        <>
          <div style={{
            flexDirection: 'column',
          }}>
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: ' 8px 24px',
            }}>
              <IconButton aria-label="search" sx={styles.sortFilterButton} onClick={() => setShowSearchInput(!showSearchInput)}>
                <SearchIcon color={'primary'} />
              </IconButton>
              <SortButton
                menuItems={
                  SORT_TYPES.map((sortItem: { title: string; value: string; }) => ({
                    title: sortItem.title,
                    value: sortItem.value,
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    handleSelect: () => { setSort(sortItem.value); },
                  }))}
                selectedSortType={sort}
              />
              {
                merchantSelected && <>
                  <ButtonTextPrimary onClick={() => handleSelectionAllEmployees(true)}>Select all</ButtonTextPrimary>
                  <ButtonTextPrimary onClick={() => handleSelectionAllEmployees(false)} disabled={tempEmployees.length === 0}>Deselect all</ButtonTextPrimary>
                </>
              }
            </div>
            {showSearchInput && (
              <div style={{ padding: ' 0px 24px' }}>
                <SearchbarWithDeboubce
                  isOpen
                  query={searchQuery}
                  placeholder="Search Merchants"
                  onCancel={() => { setShowSearchInput(false); setSearchQuery(''); }}
                  onChange={searchOnChange}
                />
              </div>
            )}
          </div>
          <div style={{ width: '600px', marginLeft: '13px', maxHeight: '340px', overflow: 'auto' }}>
            {
              !merchantSelected
                ? getMerchantList()
                : getEmployeeList()
            }
          </div>
        </>
      </MiniDialog>
      <MiniDialog
        title="Select Managers"
        open={dialog && recipientType === 'territoryManager'}
        close={closeClicked}
        customBottomButtons={customTerritoryManagerBottomButtons}
        titleMargin
        childrenSx={{ width: '100%' }}
      >
        <>
          <div style={{
            flexDirection: 'column',
          }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center', 
              justifyContent: 'flex-end',
              padding: '8px 24px',
            }}>
              <IconButton aria-label="search" sx={styles.sortFilterButton} onClick={() => setShowSearchInput(!showSearchInput)}>
                <SearchIcon color={'primary'} />
              </IconButton>
              <SortButton
                menuItems={
                  SORT_TYPES.map((sortItem: { title: string; value: string; }) => ({
                    title: sortItem.title,
                    value: sortItem.value,
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    handleSelect: () => { setSort(sortItem.value); },
                  }))}
                selectedSortType={sort}
              />
              <FilterMenu
                filterOptions={filterOptions}
                onSubmit={(selected) => setFilter(selected)}
                activeButtons />

              <ButtonTextPrimary
                data-testid="selectall-button"
                onClick={() => handleSelectionAllTerritoryManagers(true)}
                sx={{ margin: '0 8px 0 0', padding: '8px 11px !important' }}>
                Select All
              </ButtonTextPrimary>

              <ButtonTextPrimary
                data-testid="deselectall-button"
                onClick={() => handleSelectionAllTerritoryManagers(false)}
                disabled={tempTerritoryManager.length === 0}
                sx={{ margin: '0 8px 0 0', padding: '8px 11px !important' }}>
                Deselect All
              </ButtonTextPrimary>
            </div>
            {showSearchInput && (
              <div style={{ padding: ' 0px 24px' }}>
                <SearchbarWithDeboubce
                  isOpen
                  query={searchQuery}
                  placeholder="Search Managers"
                  onCancel={() => { setShowSearchInput(false); setSearchQuery(''); }}
                  onChange={searchOnChange}
                />
              </div>
            )}
          </div>
          <div style={{ paddingLeft: '24px', paddingRight: '24px' }}>
            {getTerritoryManagerList()}
          </div>
        </>
      </MiniDialog>
    </>
  );
}

const styles: IStyles = {
  sortFilterButton: {
    margin: '0 8px 0 0',
    height: '48px',
    width: '48px',
    '&:hover': {
      backgroundColor: LIGHT_THEME.palette.action.selected
    }
  },
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  }
};

const SORT_TYPES = [
  {
    title: 'A \u2014 Z',
    value: 'asc'
  },
  {
    title: 'Z \u2014 A',
    value: 'desc'
  }
];

const TOOLBAR_CONFIG: ToolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['BLOCK_TYPE_DROPDOWN', 'INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS',],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Paragraph', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'Bullet List', style: 'unordered-list-item' },
    { label: 'Numbered List', style: 'ordered-list-item' }
  ]
};

const EMPTY_HTML = '<p><br></p>';
