import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import TabPanel from '@mui/lab/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NoItems } from '../components-molecules/NoItems';
import { SearchbarWithDeboubce } from '../components-molecules/SearchBarWithDebounce';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { IndustryEventsList } from '../components-organisms/IndustryEventsList';
import { LIGHT_THEME } from '../constants/theme';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { GetIndustryEventsList, IndustryEventTogglePublishState, JoinIndustryEventRequest } from '../service/serviceService';
import { Stack } from '@mui/material';

const FILTER_OPTIONS = {
  'Date Range': [],
  'Event State': [{ title: 'Live', value: 'Live' }, { title: 'Upcoming', value: 'Upcoming' }, { title: 'Past', value: 'Past' }],
  'Target Users': [{ title: 'Merchants', value: 'Merchants' }, { title: 'Territory & Sales Managers', value: 'Territory & Sales Managers' }, { title: 'Merchants, Territory & Sales Managers', value: 'Merchants, Territory & Sales Managers' }],
  'Event Status': [{ title: 'Published', value: 'Published' }, { title: 'Unpublished', value: 'Unpublished' }]
};

const TM_SM_FILTER_OPTIONS = {
  'Date Range': [],
  'Event State': [{ title: 'Live', value: 'Live' }, { title: 'Upcoming', value: 'Upcoming' }, { title: 'Past', value: 'Past' }],
};

export function IndustryEvents(): JSX.Element {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const token = useTypedSelector(state => state.userReducer.token);
  const user = useTypedSelector((state) => state.userReducer.data);
  const isTMorSM = user?.IsTerritoryManager || user?.IsSalesManager;

  const [events, setEvents] = useState<IndustryEvent[]>([]);

  const [page, setPage] = useState(0);
  const [searchOpen, setSearchOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const pageSize = 10;
  const [filter, setFilter] = useState<ISelectedOptions>({ 'Date Range': [], 'Event State': [], 'Target Users': [], 'Event Status': [] });
  const [sortType] = useState<string>('desc');
  const [total, setTotal] = useState(0);
  const [watchEventModal, setWatchEventModal] = useState<IndustryEvent>();

  const loadMore = () => {
    if (token) {
      getIndustryEvents(page + 1, pageSize, events);
      setPage(page + 1);
    }
  };

  const getIndustryEvents = (page = 0, size = pageSize, prevData: IndustryEvent[] = []): void => {
    if (!token) return;
    if (loading) return;
    setLoading(true);

    GetIndustryEventsList(
      token,
      {
        Query: query,
        Size: size,
        Page: page,
        OrderBy: sortType,
        Filter:
        {
          DateStart: filter['Date Range']?.[0],
          DateEnd: filter['Date Range']?.[1],
          State: filter['Event State'],
          IsPublished: filter['Event Status']?.length > 0 ? filter['Event Status'].length === 2 ? null : filter['Event Status'].includes('Published') : null,
          Target: filter['Target Users']?.length > 0 ? filter['Target Users'].includes('Merchants, Territory & Sales Managers') ? 'Both' : filter['Target Users'].includes('Territory & Sales Managers') ? 'TMSM' : filter['Target Users'].includes('Merchants') ? 'Merchants' : null : null,
        }
      },
      (response) => {
        setTotal(response.Result.Count);
        setEvents(prevData.concat(response.Result.Result));
        setTimeout(() => setLoading(false), 100);
      },
      () => setLoading(false)
    );
  };

  useEffect(() => {
    getIndustryEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, query, sortType, filter]);

  const onEdit = (id: string) => {
    const selectedEvent = events.find((f: any) => f.Id === id);
    dispatch({ type: 'SET_CURRENT_INDUSTRY_EVENT', payload: { currentData: selectedEvent } });
    navigate('/admin/edit-event');
  };

  const onToggle = (id: string) => {
    if (!token) return;
    IndustryEventTogglePublishState(token, id, () => getIndustryEvents(), (error) => console.log('Error:', error));
  };

  const searchOnChange = (text: string) => {
    setQuery(text);
  };

  const onJoinEvent = useCallback((eventId: GUID | string) => {
    const event = events.find(x => x.Id === eventId);
    if (token && event) {
      JoinIndustryEventRequest(token, event.Id, () => {/** */ }, () => {/** */ });
      window.open(event.JoinUrl, '_blank', 'rel=noopener noreferrer');
    }
  }, [events, token]);

  const onWatchEventModalOpen = useCallback((eventId: GUID | string) => {
    const event = events.find(x => x.Id === eventId);
    if (event) setWatchEventModal(event);
  }, [events]);

  const onWatchEventModalClose = useCallback(() => {
    setWatchEventModal(undefined);
  }, []);

  const renderContent = () => {
    return (
      <Stack>
        <PageHeader
          buttonTitle={isTMorSM ? '' : 'New Event'}
          activeButtons={true}
          modalCallback={() => {
            window.open('https://zoom.us/webinar/schedule', '_blank');
          }}
          onClickSearch={() => setSearchOpen(!searchOpen)}
          hasFilter
          noSort
          filterOptions={isTMorSM ? TM_SM_FILTER_OPTIONS : FILTER_OPTIONS}
          onFilterSubmit={(selected) => {
            console.log(selected);
            setFilter(selected);
          }}
          isAdmin={true}
        />

        <SearchbarWithDeboubce
          isOpen={searchOpen}
          query={query}
          placeholder='Search Insights'
          onCancel={() => { setSearchOpen(false); setQuery(''); }}
          onChange={searchOnChange}
        />

        {loading && page === 0
          ? <CircularProgress
            color='primary'
            size={50}
            style={{ zIndex: 999, position: 'absolute', top: '50vh', right: '40vw' }}
          />
          : !loading && events.length === 0
            ? <NoItems
              title='No events published yet'
              description='You haven’t published any events yet. Start by creating the first event.'
              icon={<VideoLibraryIcon sx={{ fontSize: 88, fill: LIGHT_THEME.palette.primary.main }} />}
            />
            : <IndustryEventsList
              industryEvents={events}
              total={total}
              loadMore={loadMore}
              onEdit={onEdit}
              onUnpublish={onToggle}
              isTMorSM={isTMorSM}
              onJoinEvent={onJoinEvent}
              onWatchEventModalOpen={onWatchEventModalOpen}
              onWatchEventModalClose={onWatchEventModalClose}
              watchEventModal={watchEventModal}
            />
        }
      </Stack>
    );
  };

  if (isTMorSM) {
    return renderContent();
  }

  return (
    <TabPanel value={'content'} sx={{ padding: 0, width: '100%' }}>
      {renderContent()}
    </TabPanel>
  );
}
