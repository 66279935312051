/* eslint-disable @typescript-eslint/naming-convention */
import { ButtonProps } from '@mui/material';
import { ButtonContainedPrimary, ButtonOutlinedPrimary } from '../components-atoms/ButtonComponents';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { ExternalPageRequest } from '../service/externalPageService';

interface IExternalRedirectButtonProps extends ButtonProps {
  children: React.ReactNode;
  url:string;
}
export function ExternalRedirectButton(props: IExternalRedirectButtonProps): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const { children, url, variant='contained', ...rest } = props;
  const Component = variant === 'contained' ? ButtonContainedPrimary : ButtonOutlinedPrimary;

  function onClick(){
    window.open(url);

    if(token){
      ExternalPageRequest(
        token,
        {Url: url}
      );
    }
  }

  return (
    <Component {...rest} onClick={onClick}>
      {children}
    </Component>
  );
}
