export const AUDIENCE_LIST: AudienceName[] = [
  'Retail LSOs',
  'Retail SSOs',
  'Builder Merchant Group',
  'Decorator Merchant Group',
  'Online',
  'Distributor & Independents',
  'DDCs',
  'SSO-MPP',
  'LSO-MPP',
  'DMG-MPP',
  'BMG-MPP',
];

export function GetAudienceOptions(): SelectFieldOption[] {
  return AUDIENCE_LIST.map((v) => ({ name: v, value: v }));
}
