import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { Chip, Grid } from '@mui/material';
import { HeaderSix } from '../components-atoms/TypographyComponents';
import { GetRecentSearchRequest } from '../service/serviceService';
import '../scss/components/_topsearches.scss';

interface IProps {
  categoryId?: string;
  serviceId?: string;
}

export function RecentSearches({ categoryId, serviceId }: IProps): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const [recentSearches, setRecentSearches] = useState<any>([]);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      GetRecentSearchRequest(
        token,
        {
          Page: 0,
          Size: 6,
        },
        (response) => { if (response) setRecentSearches(response.Result); },
        (error: any) => { if (error.response) console.error(error.response.data.Error); }
      );
    }
  }, [token]);

  return (
    <>
      {recentSearches.length > 0 && (
        <>
          <section className="top-searches">
            <div className="top-searches__title">
              <HeaderSix >Recent Searches</HeaderSix>
            </div>
            <Grid item container spacing={1}>
              {recentSearches.map((search: any, idx: number) =>
                <Grid item key={`${search}${idx}`}>
                  <Chip
                    onClick={() => {
                      dispatch({ type: 'SET_SEARCH_QUERY', payload: { currentData: search } });
                      navigate('/searchresults', {
                        state: {
                          categoryId,
                          serviceId
                        }
                      });
                    }}
                    className="top-searches__chip" label={search} />
                </Grid>
              )}
            </Grid>
          </section>
        </>
      )}
    </>
  );
}
