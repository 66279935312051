export const correctUrls = (desc: string): string => {
  const hrefRegex = new RegExp(/href="[^"]*"+/g);
  let temp = desc;

  const matches: string[] = Array.from(desc.matchAll(hrefRegex)).flat();
  const corrected: string[] = [];

  if (matches && matches.length > 0) {
    matches.forEach(m => {
      const url = m.slice(m.indexOf('href="') + 6, m.length);
      if (url.includes('http://') || url.includes('https://')) {
        corrected.push(m);
      } else {
        corrected.push(m.replace(url, `https://${url}`));
      }
    });

    corrected.forEach((c, i) => {
      temp = temp.replace(matches[i], c);
    });
  }

  return temp;
};
