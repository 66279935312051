/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo } from 'react';
import { Stack, useMediaQuery } from '@mui/material';
import { HeaderDownloadButtons } from './HeaderDownloadButtons';
import { useTypedDispatch, useTypedSelector } from '../../hooks/TypedReduxHooks';
import { LIGHT_THEME } from '../../constants/theme';
import { HeaderFilterChips } from './HeaderFilterChips';
import { DIGITAL_ASSET_REDUX_FILTER_INITIAL } from '../../redux/reducers/digitalAssets';
import { useSearchParams } from 'react-router-dom';
import { DownloadButtonSource } from '../../utils/enums';
import { DEFAULT_BRAND_ASSET_SORT } from '../../constants/digitalAssets';

interface IServicePageHeaderThirdRowProps {
  onShare?: any;
  onChipDelete: ({ key, value }: { key: string, value: string; }) => void;
  chips: any;
  alwaysShow?: boolean;
}

export default function ServicePageHeaderThirdRow({ chips, onShare, onChipDelete, alwaysShow }: IServicePageHeaderThirdRowProps): JSX.Element {
  const dispatch = useTypedDispatch();
  const [digitalAssets, focusedContents, selectedAssets, savedQuery]: any = useTypedSelector((state) => [state.digitalAssetReducer.assetListToShow, state.digitalAssetReducer.focusedContents, state.digitalAssetReducer.selectedAssets, state.digitalAssetReducer.search]);
  
  const isMdUp = useMediaQuery(LIGHT_THEME.breakpoints.up('md'));
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  const assetSelected = useMemo(() => selectedAssets?.length > 0, [selectedAssets]);
  const [searchParams, setSearchParams] = useSearchParams();

  function onClear() {
    searchParams.delete('shareToken');
    setSearchParams(searchParams);

    if (!savedQuery || savedQuery?.length === 0) {
      dispatch({ type: 'SET_DIGITAL_ASSET_SORT', payload: { sortType: DEFAULT_BRAND_ASSET_SORT } });
    }

    dispatch({
      type: 'SET_DIGITAL_ASSET_FILTER',
      payload: {
        filter: DIGITAL_ASSET_REDUX_FILTER_INITIAL
      }
    });
  }

  const onSelectAll = () => {
    const allAssets: DigitalAsset[] = [];
    let assetsToSelect: DigitalAsset[] = focusedContents || [];

    digitalAssets.forEach((asset: any) => {
      allAssets.push(...asset.DigitalAssets);
    });

    assetsToSelect = allAssets.reduce((acc: any, arr2El: DigitalAsset) => {
      if (assetsToSelect.findIndex((arr1El) => arr1El.DigitalAssetId === arr2El.DigitalAssetId) === -1) {
        acc.push(arr2El);
      }
      return acc;
    }, [...assetsToSelect]);

    dispatch({
      type: 'SET_DIGITAL_ASSET_SELECT',
      payload: {
        selectedAssets: assetsToSelect.slice(0, 25),
      },
    });
  };

  return (
    <Stack
      data-test-id='filters-and-download'
      display='flex'
      flexDirection='row'
      alignItems='center'
      overflow='auto'
      height={(alwaysShow && isMdUp) || assetSelected || chips.length > 0 ? '64px' : 0}
      pl={{ xs: '20px', sm: 4, md: 4, lg: 4, xl: 4 }}
      pr={{ xs: 1, sm: 1, md: '20px', lg: '20px', xl: '20px' }}>
      <Stack
        flex={1}
        display='flex'
        flexDirection='row'
        alignItems='center'
        sx={{
          marginTop: assetSelected && smallDown ? '10px' : 0,
          overflowX: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
          }
        }}>
        <HeaderFilterChips
          chips={chips}
          onClear={onClear}
          onDelete={onChipDelete}
          sx={{
            width: selectedAssets?.length > 0 ? 'calc(94vw - 380px)' : '50w'
          }} />
      </Stack>
      <Stack
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='flex-end'>
        <HeaderDownloadButtons
          onShare={onShare}
          selectedAssets={selectedAssets}
          onSelectAll={onSelectAll}
          source={DownloadButtonSource.AssetListing} />
      </Stack>
    </Stack>
  );
}
