import { Button } from '@mui/material';
import { BodyTwoPrimary } from '../components-atoms/TypographyComponents';
import { LIGHT_THEME } from '../constants/theme';

type IProps = any;

export function DropdownMenuButton(props: IProps): JSX.Element {
  const { title, selected, value } = props;
  return (
    <Button
      id="basic-button"
      aria-controls="basic-menu"
      aria-haspopup="true"
      sx={{
        backgroundColor: selected ? LIGHT_THEME.palette.action.selected : undefined,
        justifyContent: 'flex-start',
        width: '100%',
        minHeight: '40px',
        marginTop: '4px',
        marginBottom: '4px',
        paddingLeft: '12px',
        paddingRight: '12px',
        borderRadius: '8px',
        '&:hover': {
          backgroundColor: selected ? 'rgba(1, 33, 105, 0.24)' : LIGHT_THEME.palette.action.hover
        }
      }}
      onClick={() => props.handleClick(value)}


    >
      <BodyTwoPrimary style={{ color: selected ? 'inherit' : LIGHT_THEME.palette.text.primary }} >
        {title}
      </BodyTwoPrimary>
    </Button>
  );
}

