/* eslint-disable @typescript-eslint/naming-convention */
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Alert, Grid, FormHelperText, Container, Typography } from '@mui/material';
import { FormPageComponent } from '../components-molecules/FormPageComponent';
import { ModalCardComponent } from '../components-molecules/Modal';
import { SectionHeader } from '../components-molecules/section/SectionHeader';
import { FormControlInput, FormControlSelect } from '../components-molecules/FormControl';
import { InvitationFormTypes, INVITATION_FORM } from '../forms/invitation';
import { IStyles } from '../constants/theme';
import { BodyOneSecondary, HeaderFivePrimary } from '../components-atoms/TypographyComponents';
import { MerchantGetInvited } from '../service/serviceService';

export default function GetInvited():JSX.Element {
  const navigate = useNavigate();
  const { control, formState: { isValid }, /* reset, */ handleSubmit } = useForm({ mode: 'all', reValidateMode: 'onBlur' });
  const [errorMessage, ] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);

  const submit: SubmitHandler<InvitationFormTypes> = async (data:InvitationFormTypes) => {
    
    setLoading(true);
    setScrollToTop(true);
    MerchantGetInvited({
      Email: data.email,
      FirstName: data.firstName,
      LastName: data.lastName,
      MerchantName: data.merchantName,
      Phone: data.phone,
      RoleName: data.role,
      MerchantSAPCode: data.sapCode,
      StoreName: data.storeName,
      StorePostCode: data.storePostCode,
      StoreTerritoryManager: data.territoryManager,
    },
    () => {
      setLoading(false);
      navigate('/get-invited-success');
    },
    () => {
      setScrollToTop(false);
      setLoading(false);
    }
    );
  };

  return (
    <FormPageComponent
      hasError={!!errorMessage}
      title={'Join Connect for Merchants'}
      buttonTitle="Submit"
      buttonDisabled={!isValid || loading}
      buttonLoading={loading}
      scrollToTop={scrollToTop} 
      submit={handleSubmit(submit)}
      close={() => navigate(-1)}
      submitButton
    >
      <Fragment>
        <Grid item sx={{ flexGrow: 1 }}>
          {!!errorMessage
            && <Alert severity="error">{errorMessage}</Alert>
          }
        </Grid>

        <Container maxWidth={false} style={styles.headerTextContainer} sx={{ maxWidth: '840px', paddingLeft: { xs: '16px', md: 0 }, paddingRight: { xs: '16px', md: 0 } }}>
          <HeaderFivePrimary sx={{ marginBottom: '16px' }}>Register your interest and get invited</HeaderFivePrimary>
          <BodyOneSecondary>Fill out your personal information, enter your organisation and store details below to get invited.</BodyOneSecondary>
        </Container>
        <div style={styles.sectionContainer}>
          {INVITATION_FORM.map((section, index) => {
            return (
              <ModalCardComponent key={index}>
                <SectionHeader title={section.title} subtitle={section.subtitle} />
                <Fragment>
                  <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
                    {section.fields.map((field, idx) =>
                      <Grid key={idx} item  xs={field.name === 'firstName' || field.name === 'lastName' ? 6 : 12} >
                        {field.inputType === 'select'
                          ? <FormControlSelect control={control} field={field} />
                          : <FormControlInput control={control} field={field} dontShowOptionalText={field.validation.validate === undefined} />
                        }
                      </Grid>
                    )}
                  </Grid>
                  <div style={styles.helperTextContainer}>
                    {section.title === 'User account details' && (
                      <FormHelperText>
                        Enter a personal phone number for our agents to contact you if any further questions arise about the registration. Example: 07975777666
                      </FormHelperText>
                    )}</div>
                </Fragment>
              </ModalCardComponent>
            );
          })}
          <Container maxWidth={false} style={{...styles.headerTextContainer, maxWidth: '840px'}} sx={{ paddingLeft: { xs: '16px', md: 0 } }}>
            <Typography color="text.secondary">
              Fields marked with * are mandatory
            </Typography>
          </Container>
        </div>
      </Fragment>
    </FormPageComponent>
  );
}
const styles: IStyles = {
  helperTextContainer:{
    padding: '0 14px'
  },
  headerTextContainer:{
    padding: '24px 24px 0 24px'
  },
  sectionContainer:{
    paddingBottom:'32px'
  }
};
