import { useState } from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import { Section } from '../../components-molecules/section/Section';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { SectionRow } from '../../components-molecules/section/SectionRow';
import { useTypedDispatch, useTypedSelector } from '../../hooks/TypedReduxHooks';
import { DeleteUserRequest } from '../../service/userService';
import { MiniDialog } from '../../components-molecules/dialogs/MiniDialog';
import { BodyOneSecondary } from '../../components-atoms/TypographyComponents';
import { useNavigate } from 'react-router-dom';

export function EmployeeCard({ employee, navigateBackTo }: { employee: MerchantUser; navigateBackTo: string; }): JSX.Element {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [token, storesList, userData] = useTypedSelector(state => [state.userReducer.token, state.merchantReducer.stores, state.userReducer.data]);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const onEdit = () => {
    const storeArray: Store[] = [];
    storesList.map(store => {
      if ([...store.StoreEmployees, ...store.StoreManagers, ...store.MerchantManagers].some(emp => employee.Id === emp.UserProperties.Id)) {
        storeArray.push(store);
      }
    });
    dispatch({ type: 'MERCHANT_SET_STORES_OF_EMPLOYEE', payload: { storesOfEmployee: storeArray } });
    navigate(userData && userData.IsAdmin ? '/admin/edit-employee' : '/edit-employee', { state: { navigateBackTo, employee } });
  };

  const onDelete = () => {
    if (token) {
      DeleteUserRequest(
        employee.Id,
        token,
        () => dispatch({ type: 'MERCHANT_REFRESH' }),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  return (
    <Section>
      <SectionHeader
        hasMenuIcon={userData && userData.Role !== 'Store Employee'}
        title={`${employee.FirstName} ${employee.LastName}`}
        label={employee.RegistrationPending ? 'Registration Pending' : ''}
        onEdit={onEdit}
        onDelete={() => setModalOpen(true)}
      />

      <SectionRow
        title="Address"
        lines={[
          { data: employee.Email, type: 'body1' },
          { data: employee.Phone, type: 'body2', color: 'text.secondary' }
        ]}
      />

      <Box><Divider /></Box>

      <SectionRow
        title="Role"
        lines={[
          { data: employee.Role, type: 'body1' }
        ]}
      />

      {modalOpen
        ? <MiniDialog
          title="Delete Employee"
          open={modalOpen}
          close={() => setModalOpen(false)}
          remove={() => {
            onDelete();
            setModalOpen(false);
          }}>
          <Box sx={{ padding: '0 24px' }}>
            <BodyOneSecondary>
              {'Are you sure you want to delete this Employee?'}
            </BodyOneSecondary>
          </Box>
        </MiniDialog>
        : null
      }
    </Section>
  );
}
