import { GetAudienceOptions } from '../constants/audiences';
import { GetRoleOptions } from '../constants/roles';
import { REQUIRED_VALIDATION } from './_predefinedValidations';

export interface AccessFormInputTypes {
  audienceName: AudienceName,
  role: UserRole,
}

export const ACCESS_FORM: [FormSelectField, FormSelectField] = [
  {
    label: 'Select merchant audience',
    name: 'audienceName',
    inputType: 'select',
    options: GetAudienceOptions(),
    validation: {
      required: REQUIRED_VALIDATION
    }
  },
  {
    label: 'Select user role',
    name: 'role',
    inputType: 'select',
    options: GetRoleOptions(),
    validation: {
      required: REQUIRED_VALIDATION
    }
  }
];
