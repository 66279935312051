import { Box, Grid } from '@mui/material';
import { ModalCardComponent } from '../components-molecules/Modal';
import { SectionHeader } from '../components-molecules/section/SectionHeader';
import { useEffect, useState } from 'react';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { IIndustryEventRegistrantsResponse, IndustryEventRegistrants } from '../service/serviceService';
import { BodyOnePrimary, BodyTwoSecondary, CaptionSecondary } from '../components-atoms/TypographyComponents';

export default function IndustryEventsRegisteredUsers({ id }: { id: string }): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const [registeredUsers, setRegisteredUsers] = useState<IIndustryEventRegistrantsResponse[]>([]);

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getUsers() {
    if (!token) return;
    IndustryEventRegistrants(
      token,
      id,
      (res) => {
        setRegisteredUsers(res.Result);
      },
      (error) => {
        console.log('Error:', error);
      }
    );
  }
  return (
    <ModalCardComponent>
      <SectionHeader title='Registered Users' />
      <Box>
        <BodyTwoSecondary>
          {registeredUsers?.length > 0 ? `Showing ${registeredUsers.length} Registered users` : 'No registered users to show'}
        </BodyTwoSecondary>

        <Grid container mt={1}>
          {registeredUsers?.map(u =>
            <Grid key={u.UserId} container item xs={12} py={2} columnGap={12}>
              <Grid width="388px">
                <Grid item xs>
                  <BodyOnePrimary>
                    {u.FirstName} {u.LastName}
                  </BodyOnePrimary>
                  <CaptionSecondary>
                    {u.Email}
                  </CaptionSecondary>
                </Grid>
              </Grid>
              {u.Role === 'TM' || u.Role === 'SM'
                ? <Grid>
                  <BodyTwoSecondary>
                    Role
                  </BodyTwoSecondary>
                  <BodyOnePrimary pt={0.5}>
                    {u.Role === 'TM' ? 'Territory Manager' : 'Sales Manager'}
                  </BodyOnePrimary>
                </Grid>
                : <Grid>
                  <BodyTwoSecondary>
                    Merchant
                  </BodyTwoSecondary>
                  <BodyOnePrimary pt={0.5}>
                    {u.MerchantName}
                  </BodyOnePrimary>
                </Grid>
              }
            </Grid>
          )}
        </Grid>
      </Box>
    </ModalCardComponent>
  );
}
