import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormPageComponent } from '../components-molecules/FormPageComponent';
import { PdfViewer } from './PdfViewer';


export default function PdfPreviewPage():JSX.Element {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <FormPageComponent
      title={''}
      close={() => navigate(state.navigateBackTo, {state: {...state, navigateBackTo: state.prevNavigateBackTo}})}
      submitButton={false}
    >
      <Grid container item direction="row" justifyContent={'center'} sx={{ flex: 1, width: '100vw', height: '100vh' }}>
        <Grid xs={12} sm={7} item container justifyContent="center" alignItems="center" sx={{ padding: '0 24px',height:'100%', }}>
          <PdfViewer url={state.url} />
        </Grid>
      </Grid>

    </FormPageComponent>
  );
}
