/* eslint-disable @typescript-eslint/naming-convention */
import { Fragment, useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import { FormHelperText, LinearProgress } from '@mui/material';

import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { FormControlImage, FormControlInput, FormControlSelect } from '../../components-molecules/FormControl';
import { ORGANISATION_FORM } from '../../forms/organisation';
import { MerchantDefaultTypes, MerchantFormTypes } from '../../forms/merchant';
import { EditMerchantRequest } from '../../service/merchantsService';
import { useTypedDispatch, useTypedSelector } from '../../hooks/TypedReduxHooks';
import { UploadToAzure } from '../../hooks/uploadToAzure';
import { environment } from '../../environment';
import { FormatBytes } from '../../utils/formatBytes';
import { IStyles } from '../../constants/theme';
interface onProgressResponse {
  fileName: string;
  percentage: number;
}

export function EditOrganisation(): JSX.Element {
  const [token, userData] = useTypedSelector(state => [state.userReducer.token, state.userReducer.data]);
  const organisation = useTypedSelector(state => state.merchantReducer.currentData);
  const dispatch = useTypedDispatch();
  
  const [defaultValues] = useState<MerchantDefaultTypes>(
    {
      merchantName: organisation.Name,
      payerCode: organisation.PayerCode,
      logo: organisation.Logo,
      addressLine1: organisation.AddressLine1,
      addressLine2: organisation.AddressLine2,
      city: organisation.City,
      postCode: organisation.PostCode,
      county: organisation.County,
      phone: organisation.Phone,
      email: organisation.Email,
      audienceName: organisation.AudienceName,
    }
  );

  const { control, handleSubmit, setError, formState: { isValid, errors }, reset, getValues, watch,trigger } = useForm<any>({ mode: 'all', reValidateMode: 'onBlur', defaultValues });
  const [loading, setLoading] = useState(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [loadingProgress, setLoadingProgress] = useState<onProgressResponse[]>([]);
  const [percentage, setPercentage] = useState(0);
  const logo = watch('logo', defaultValues.logo);
  const navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    calculatePercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProgress]);

  useEffect(() => {
    if (typeof logo !== 'string') {
      if (FormatBytes((logo as File)?.size, 'MB') > 2) {
        setError('logo', { message: 'Logos must be smaller than 2MB.', type: 'value' });
      }
    }
  }, [logo, getValues, setError]);

  const updateMerchant = (data: MerchantFormTypes, newLogo: string) => {
    if (token) {
      EditMerchantRequest(
        token,
        {
          Id: organisation.Id,
          Name: data.merchantName,
          Logo: newLogo,
          PayerCode: data.payerCode,
          AddressLine1: data.addressLine1,
          AddressLine2: data.addressLine2,
          City: data.city,
          PostCode: data.postCode,
          County: data.county,
          Phone: data.phone,
          Email: data.email,
          Audience: data.audienceName,
        },
        () => {
          dispatch({ type: 'MERCHANT_REFRESH' });
          setLoading(false);
          setScrollToTop(false);
          navigate(state.navigateBackTo);
        },
        (error) => {
          if (error.response) {
            dispatch({ type: 'USER_ANY_FAILURE_NO_SIGNOUT', payload: { error: error.response.data.Error } });
            setError(error.response.data.Details, { message: error.response.data.Error });
            setLoading(false);
            setScrollToTop(false);
          }
        }
      );
    }
  };

  const editOrganisation: SubmitHandler<MerchantFormTypes> = async (data) => {
    if (token) {
      setLoading(true);
      setScrollToTop(true);
      if (typeof data.logo !== 'string') {

        const organisationLogo = await UploadToAzure({
          data: [data.logo],
          onProgress: onProgress,
          folderName: environment.merchantFolder
        });

        const imageUrl = organisationLogo[0].Url;

        updateMerchant(data, imageUrl);
      } else {
        updateMerchant(data, (organisation.Logo as string).split('/').pop() as string);
      }
    }
  };

  const onProgress = (data: onProgressResponse) => {
    const result = loadingProgress?.find(x => data.fileName === x.fileName);
    let tempLoadingProcesses = loadingProgress;

    if (result !== undefined) {
      tempLoadingProcesses= tempLoadingProcesses?.map(x => {
        if (x.fileName === data.fileName) {
          return {...x, percentage: data.percentage};
        } else {
          return x;
        }
      });
    } else {
      tempLoadingProcesses?.push(data);
    }
    setLoadingProgress(tempLoadingProcesses);
  };

  const calculatePercentage = () => {
    let total = 0;
    loadingProgress.forEach(x=>{
      total += x.percentage / loadingProgress.length;
    });
    setPercentage(Math.round(total));
  };

  return (
    <FormPageComponent
      title="Edit organisation profile"
      buttonTitle="Submit"
      buttonDisabled={!isValid}
      hasError={!!errors.merchantName}
      close={() => { navigate(state.navigateBackTo); reset(defaultValues); }}
      submit={handleSubmit(editOrganisation)}
      buttonLoading={loading}
      scrollToTop={scrollToTop}>
      {loading ? (
        <LinearProgress variant="determinate" color="secondary" value={percentage}  sx={{ zIndex: 2 }} />
      ) : (
        <></>
      )}
      <Fragment>
        {ORGANISATION_FORM.map((section, index) => {
          return (
            <ModalCardComponent key={index}>
              <SectionHeader title={section.title} />
              <Fragment>
                <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
                  {section.fields.map(field =>
                    <Grid key={field.name} item xs={field.name === 'city' || field.name === 'postCode' ? 6 : 12}>
                      {field.inputType === 'logo'
                        ? <FormControlImage
                          accept='image/*'
                          subtitle='Square image with maximum file size up to 2 MB works best.'
                          control={control}
                          field={field}
                          initialValue={organisation.ThumbnailUrl || organisation.Logo}
                          initialFileType='image'
                          removeHelperTextMarginLeft
                        />
                        : field.inputType === 'select'
                          ? <FormControlSelect control={control} field={field} />
                          : <FormControlInput disabled={field.name === 'payerCode' && userData && userData.Role !== 'Super Admin'} control={control} field={field} />
                      }
                      {field.helperText 
                        && <FormHelperText sx={{ padding: '0 14px' }}>
                          {field.helperText}
                        </FormHelperText>
                      }
                    </Grid>
                  )}
                </Grid>
              </Fragment>
            </ModalCardComponent>
          );
        })}
        {loading ? (
          <div style={styles.overlay}>
          </div>
        ) : (
          <></>
        )}
      </Fragment>
    </FormPageComponent>
  );
}

const styles: IStyles = {
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  }
};
