/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/display-name */
import { forwardRef, useRef } from 'react';
import { debounce, SxProps, TextField, Theme } from '@mui/material';
import { DEBOUNCE_TIMEOUT } from '../constants/genaralValues';

interface IProps {
  sx?:SxProps<Theme>;
  placeholder?:string;
  onChange:(text:string)=>void;
  autoFocus?:boolean;
  fullWidth?:boolean;
  variant?:'standard' | 'filled' | 'outlined';
  name?:string;
  defaultValue?: string;
  size?: any;
  InputProps?: any;
}

export const DebouncedInput = forwardRef((props: IProps, ref: any) => {
  const {sx, onChange, variant = 'filled', name = '' } = props;
  const debouncedSearchTerm = useRef(
    debounce(async (textValue) => {
      if(onChange !== undefined){
        onChange(textValue);
      }
    }, DEBOUNCE_TIMEOUT)
  ).current;

  return (
    <TextField
      {...props}
      inputRef={ref}
      sx={sx}
      variant={variant}
      name={name}
      onChange={event=> debouncedSearchTerm(event.target.value) }
    ></TextField>
  );
});

export default DebouncedInput;
