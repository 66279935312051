/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeEvent, Fragment, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';
import axios from 'axios';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { CircularProgress, Container, FilledInput, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { LIGHT_THEME } from '../constants/theme';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { GroupedMerchant, ListMerchantsRequest } from '../service/merchantsService';
import { GetMerchantTokenRequest } from '../service/adminService';

import { TutorialDialog } from '../components-organisms/modals/TutorialDialog';
import { FormCard } from '../components-molecules/cards/FormCard';
import { ListDivider } from '../components-molecules/Divider';
import { RoundedImageComponent } from '../components-atoms/ImageComponents';
import { HeaderSix, BodyTwoPrimary, BodyTwoSecondary, CaptionSecondary } from '../components-atoms/TypographyComponents';
import { ButtonContainedPrimary } from '../components-atoms/ButtonComponents';

import imageIcon from '../assets/img/merchant_placeholder.jpg';
import { GetAvailableServices } from '../service/serviceService';
import { gaService, SendTo } from '../service/gaService';

export function Welcome(): JSX.Element {
  const [userData, token] = useTypedSelector((state) => [state.userReducer.data, state.userReducer.token]);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const showTutorial = location.state?.showTutorial ? location.state.showTutorial : false;
  window.history.replaceState({}, document.title);

  const [groupedMerchants, setGroupedMerchants] = useState<GroupedMerchant[]>([]);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState(0);
  const [query, setQuery] = useState<string>('');
  const pageSize = 20;
  const timeoutDuration = 500;
  const cancelTokenSource = axios.CancelToken.source();
  // const showCustomerPanel = useMemo(() => userData?.IsTerritoryManager || userData?.IsSalesManager, [userData]);
  const showCustomerPanel = false;

  useEffect(() => {
    gaService.pageView('Merchant Select', { sendTo: SendTo.admin });
  }, []);

  const getTerritoryManagerMerchants = useCallback((t: string, cancelToken = undefined, page = 0, size = pageSize, prevData: GroupedMerchant[] = []): void => {
    ListMerchantsRequest(
      t,
      {
        Query: query,
        Size: size,
        Page: page
      },
      (response) => {
        if (response) {
          let temp = [...prevData];
          response.Result.Result.map(m => {
            const tempMatch = temp.find((f) => f.GroupName === m.GroupName);
            if (tempMatch) {
              temp[temp.indexOf(tempMatch)].Result = [...temp[temp.indexOf(tempMatch)].Result, ...m.Result];
            } else {
              temp = [...temp, m];
            }
          });
          setGroupedMerchants(temp);
          setTotal(response.Result.Count);
        }
      },
      (error) => { if (error.response) console.error(error.response.data.Error); },
      cancelToken
    );
  }, [query]);

  useEffect(() => {
    if (token) getTerritoryManagerMerchants(token, cancelTokenSource.token);
    return () => {
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, getTerritoryManagerMerchants]);

  const handlePath = (merchant?: MerchantListModel) => {
    if (merchant && token) {
      GetMerchantTokenRequest(
        token,
        merchant.Id as string,
        (response) => {
          if (response.Result) {
            dispatch({ type: 'USER_ADD_SUPERUSE', payload: { merchant, token: response.Result } });
            localStorage.setItem('merchantToken', response.Result);
            GetAvailableServices(
              response.Result,
              (res) => {
                dispatch({ type: 'USER_SET_SERVICES', payload: { services: res.Result } });
                navigate('/');
              },
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              () => { }
            );
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    }
    else {
      dispatch({ type: 'USER_CLEAR_SUPERUSE' });
      navigate('/admin');
    }
  };

  const handleClient = () => {
    navigate('/');
  };

  const loadMore = () => {
    if (token) {
      getTerritoryManagerMerchants(token, undefined, page + 1, pageSize, groupedMerchants);
      setPage(page + 1);
    }
  };

  const onSearch = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setQuery(event.target.value);
    debounce((e) => dispatch({ type: 'SET_SEARCH_QUERY', payload: { currentData: e.target.value } }), timeoutDuration)(event);
  };

  const onClear = () => {
    setQuery('');
    dispatch({ type: 'SET_SEARCH_QUERY', payload: { currentData: '' } });
  };

  return (
    <FormCard>
      <Grid item sx={{ flexGrow: 1, padding: '0px 40px' }}>
        <HeaderSix>Hi {userData?.FirstName}</HeaderSix>
        <BodyTwoSecondary sx={{ margin: '0 0 8px' }}>Select one of the merchants linked to your account, access the admin panel, or preview the customer portal.</BodyTwoSecondary>
      </Grid>
      <Grid item display="flex" flexDirection="row" gap={1} alignItems="center" justifyContent="flex-start" sx={{ margin: showCustomerPanel ? '16px 40px' : '16px auto' }}>
        <ButtonContainedPrimary data-testid='admin-panel-button' sx={{ height: '30px' }} onClick={() => handlePath()}>Admin Panel</ButtonContainedPrimary>
        {showCustomerPanel
          && <ButtonContainedPrimary data-testid='customer-panel-button' sx={{ height: '30px' }} onClick={() => handleClient()}>Customer Portal</ButtonContainedPrimary>
        }
      </Grid>
      {groupedMerchants.length > 0
        ? <>
          <Grid
            container
            alignItems="center"
            sx={{
              height: '47px',
              margin: '8px 0px 9px',
              padding: '0px 40px'
            }}
          >
            <FilledInput
              value={query}
              size="medium"
              fullWidth
              data-testid="search-input"
              placeholder="Search"
              onChange={e => onSearch(e)}
              endAdornment={query && (
                <IconButton data-testid="search-clear-button" onClick={onClear}>
                  <ClearIcon fontSize='small' />
                </IconButton>
              )}
              sx={{
                height: '100%',
                '&.MuiFilledInput-root': {
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px'
                },
                '.MuiFilledInput-input': {
                  paddingTop: '12px',
                  paddingBottom: '12px'
                }
              }}
            />
          </Grid>
          <Fragment>
            <Grid
              id="scrollableDiv"
              sx={{
                overflowY: 'scroll',
                overflowX: 'hidden',
                px: 1,
                height: 250,
                width: '100%',
                marginTop: '9px',
                padding: 0
              }}
            >
              <InfiniteScroll
                style={{ overflowY: 'hidden' }}
                dataLength={groupedMerchants.map(m => m.Result).flat().length} //This is important field to render the next data
                next={() => debounce(() => loadMore(), 300)()}
                scrollableTarget="scrollableDiv"
                hasMore={groupedMerchants.map(m => m.Result).flat().length < total}
                loader={
                  <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '30px', paddingBottom: '80px' }}>
                    <CircularProgress sx={{ position: 'absolute', top: '0', left: '50%' }} size={18} />
                  </Container>
                }
              >
                {groupedMerchants.map((merchants) => {
                  return (
                    <ListDivider
                      key={merchants.GroupName}
                      division={{ name: merchants.GroupName, length: merchants.Result.length }}
                      caption={{ singular: 'Merchant', plural: 'Merchants' }}
                      noMargin
                      morePadding
                    >
                      {merchants.Result.map((merchant, index) =>
                        <Fragment key={index}>
                          {merchants.Result.length > index && index !== 0 ? <Divider sx={{ width: 'calc(100% - 80px)', margin: '0 auto' }} /> : null}
                          <Button
                            disabled={merchant.ManagerStatus !== 'Active'}
                            variant="text"
                            sx={{
                              height: '62px',
                              width: '100%',
                              borderRadius: 0,
                              padding: '0 !important',
                              marginBottom: merchant.ManagerStatus === 'Active' ? 'auto' : '20px'
                            }}
                            onClick={() => handlePath(merchant)}
                          >
                            <Grid
                              container
                              sx={{
                                height: '40px',
                                margin: '11px 40px',
                                borderRadius: '8px'
                              }}
                            >
                              <Grid item sx={{ height: '40px', width: '40px', padding: '6px', marginLeft: '-6px' }}>
                                <RoundedImageComponent
                                  src={merchant.ThumbnailUrl || merchant.Logo || imageIcon}
                                  sx={{
                                    height: '28px',
                                    width: '28px',
                                    opacity: merchant.ManagerStatus !== 'Active' ? '0.4' : '1.0',
                                    filter: merchant.ManagerStatus !== 'Active' ? 'alpha(opacity = 40)' : 'auto',
                                  }} />
                              </Grid>
                              <Grid item sx={{ height: '40px', textAlign: 'left', margin: '0 0 0 6px' }}>
                                <Grid item sx={{ height: '40px', textAlign: 'left', margin: '0 0 0 6px', display: 'flex', flexDirection: 'column' }}>
                                  <BodyTwoPrimary sx={{ color: merchant.ManagerStatus === 'Active' ? LIGHT_THEME.palette.text.primary : LIGHT_THEME.palette.text.disabled }}>{merchant.Name}</BodyTwoPrimary>
                                  <CaptionSecondary sx={{ color: merchant.ManagerStatus === 'Active' ? LIGHT_THEME.palette.text.primary : LIGHT_THEME.palette.text.disabled }}>{merchant.PayerCode}</CaptionSecondary>
                                  {
                                    merchant.ManagerStatus === ''
                                      ? <CaptionSecondary sx={{ color: LIGHT_THEME.palette.warning.main }}>No manager added yet</CaptionSecondary>
                                      : merchant.ManagerStatus === 'Pending'
                                        ? <CaptionSecondary sx={{ color: LIGHT_THEME.palette.warning.main }}>Manager registration pending</CaptionSecondary>
                                        : <></>
                                  }
                                </Grid>
                              </Grid>
                            </Grid>
                          </Button>
                        </Fragment>
                      )}
                    </ListDivider>
                  );
                })}
              </InfiniteScroll>
            </Grid>
          </Fragment>
        </>
        : null}
      <TutorialDialog showDialog={showTutorial} videoSrc="https://hncdev.azureedge.net/merchantconnect-dev/brand_assets_images/p_8445ef08-15ed-49f2-977e-53cf3bf3fb7a_Connect_for_Merchant_Overview.mp4" videoPoster="https://hncdev.azureedge.net/merchantconnect-prod/help_videos/connect_for_merchants_overview.jpg" />
    </FormCard>
  );
}
