/* eslint-disable @typescript-eslint/naming-convention */
import { Chip, Collapse, Grid, IconButton, Stack, useMediaQuery } from '@mui/material';
import {
  BodyTwoPrimary,
  CaptionSecondary,
  SubtitleTwoPrimary,
} from '../components-atoms/TypographyComponents';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import { DATE_OPTIONS } from '../constants/digitalAssets';
import { AssetStatusButtonGroup } from './AssetStatusButtonGroup';
import { LIGHT_THEME } from '../constants/theme';

interface IOption {
  title: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  value: boolean;
}

const assetStatusOptions: IOption[] = [
  { title: 'Published', color: 'success', value: true },
  { title: 'Unpublished', color: 'error', value: false },
];

interface IProps {
  isAdmin: boolean;
  isSide: boolean;
  chips: Array<any>;
  digitalAsset: any;
  onPublishChange: (id: string) => void;
}

export function AssetDetailSidebar(props: IProps): JSX.Element {
  const { isAdmin, isSide, chips, digitalAsset, onPublishChange } = props;
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(isSide);
  const smDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  const xs = isSide ? 12 : smDown ? 6 : 4;

  function renderChips() {
    if (chips.length === 0) {
      return <></>;
    }
    return (
      <Grid item mt={2}>
        <CaptionSecondary>Tags</CaptionSecondary>
        <Grid container mt={0.5} rowGap={'4px'} columnGap={'4px'}>
          {chips?.map((tag: any) => (
            <Chip
              key={tag.TagId}
              label={tag.TagName}
              variant="outlined"
              size="small"
              sx={{ maxWidth: '256px', marginTop: '4px', borderRadius: '8px' }}
            />
          ))}
        </Grid>
      </Grid>
    );
  }

  return (
    <Stack mb={3}>
      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        height="64px"
      >
        <SubtitleTwoPrimary fontWeight="bold" color="rgba(0, 0, 0, 0.87)">
          {digitalAsset.DigitalAssetName}
        </SubtitleTwoPrimary>

        {!isSide && (
          <IconButton
            sx={{ width: 48, height: 48 }}
            onClick={() => setSidebarOpen((o) => !o)}
          >
            <KeyboardArrowUpIcon
              sx={{
                transition: 'all 0.3s',
                transform: `rotate(${sidebarOpen ? '0deg' : '180deg'})`,
              }}
            />
          </IconButton>
        )}
      </Stack>
      <Collapse in={sidebarOpen}>
        {isAdmin ? (
          <Grid
            item
            sx={{
              width: '100%',
              mb: {
                xs: 2,
                sm: 2,
                md: 1,
                lg: 1,
                xl: 1,
              },
            }}
          >
            <AssetStatusButtonGroup
              disabled={!isAdmin}
              value={digitalAsset.Published}
              data-testid="asset-detail-published"
              onChange={() => onPublishChange(digitalAsset.DigitalAssetId)}
              options={assetStatusOptions}
              noText
              buttonContainerStyle={{
                width: '100%',
              }}
              fullWidth
            />
          </Grid>
        ) : (
          <></>
        )}
        <Grid container rowSpacing={2} columnSpacing="20px">
          {digitalAsset.PublicationDate
            ? <Grid item xs={xs}>
              <CaptionSecondary>Date of Creation</CaptionSecondary>
              <BodyTwoPrimary sx={{ mt: 0.5 }}>
                {new Date(digitalAsset.PublicationDate || '').toLocaleDateString(
                  ['en-gb'],
                  DATE_OPTIONS
                )}
              </BodyTwoPrimary>
            </Grid>
            : null}
          {digitalAsset.Created
            ? <Grid item xs={xs}>
              <CaptionSecondary>Date added</CaptionSecondary>
              <BodyTwoPrimary sx={{ mt: 0.5 }}>
                {new Date(digitalAsset.Created || '').toLocaleDateString(
                  ['en-gb'],
                  DATE_OPTIONS
                )}
              </BodyTwoPrimary>
            </Grid>
            : null}
          {digitalAsset.Product
            ? <Grid item xs={xs}>
              <CaptionSecondary>Product</CaptionSecondary>
              <BodyTwoPrimary sx={{ mt: 0.5 }}>{digitalAsset.Product}</BodyTwoPrimary>
            </Grid>
            : null}
          {digitalAsset.Brand
            ? <Grid item xs={xs}>
              <CaptionSecondary>Brand</CaptionSecondary>
              <BodyTwoPrimary sx={{ mt: 0.5 }}>{digitalAsset.Brand}</BodyTwoPrimary>
            </Grid>
            : null}
          {digitalAsset.Colour && (
            <Grid item xs={xs}>
              <CaptionSecondary>Colour</CaptionSecondary>
              <BodyTwoPrimary sx={{ mt: 0.5 }}>{digitalAsset.Colour}</BodyTwoPrimary>
            </Grid>
          )}
          {digitalAsset.AkzoColourId && (
            <Grid item xs={xs}>
              <CaptionSecondary>Colour Id</CaptionSecondary>
              <BodyTwoPrimary sx={{ mt: 0.5 }}>{digitalAsset.AkzoColourId}</BodyTwoPrimary>
            </Grid>
          )}
          {digitalAsset?.Barcode && (
            <Grid item xs={xs}>
              <CaptionSecondary>Barcode</CaptionSecondary>
              <BodyTwoPrimary sx={{ mt: 0.5 }}>{digitalAsset?.Barcode}</BodyTwoPrimary>
            </Grid>
          )}
          {digitalAsset?.Fini && (
            <Grid item xs={xs}>
              <CaptionSecondary>FINI</CaptionSecondary>
              <BodyTwoPrimary sx={{ mt: 0.5 }}>{digitalAsset?.Fini}</BodyTwoPrimary>
            </Grid>
          )}
          {digitalAsset?.FileType && (
            <Grid item>
              <CaptionSecondary>Media type</CaptionSecondary>
              <BodyTwoPrimary sx={{ mt: 0.5 }}>{digitalAsset?.FileType}</BodyTwoPrimary>
            </Grid>
          )}
          {digitalAsset.Orientation
            ? <Grid item xs={xs}>
              <CaptionSecondary>Orientation</CaptionSecondary>
              <BodyTwoPrimary sx={{ mt: 0.5 }}>{digitalAsset.Orientation}</BodyTwoPrimary>
            </Grid>
            : null}
        </Grid>
        {renderChips()}
      </Collapse>
    </Stack>
  );
}
