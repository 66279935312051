import { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';

import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import { CircularProgress } from '@mui/material';

import { Dashboard } from '../components-organisms/Dashboard';
import { AccessRightsManagement } from '../components-organisms/AccessRightsManagement';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { GetServiceForAdminRequest, GetServicePermissions } from '../service/serviceService';
import { IndustryInsights } from './IndustryInsights';
import { NewProductDevelopment } from './NewProductDevelopment';
import { FocusOfTheMonth } from './FocusOfTheMonth';
import { DocumentRepository } from '../components-organisms/DocumentRepository';
import { DigitalAssetService } from './DigitalAssetService';
import { DigitalToolkitsSource } from '../utils/enums';
import { DigitalToolkitsList } from '../components-organisms/lists/DigitalToolkitsList';
import { FocusedContentList } from '../components-organisms/lists/FocusedContentList';
import { IndustryEvents } from './IndustryEvents';

function RenderServiceContent(title: string, isTMorSM?: boolean): JSX.Element | string {

  switch (title) {
    case 'Brand Assets':
      return <DigitalAssetService
        isAdmin
        showPanel={!isTMorSM}
        service={{
          Name: title,
          ImageUrl: 'branded-asset'
        }}
      />;
    case 'Industry Insights':
      return <IndustryInsights />;
    case 'Product Updates':
      return <NewProductDevelopment />;
    case 'Focus of the Month':
      return <FocusOfTheMonth />;
    case 'Industry Events':
      return <IndustryEvents />;
    case 'Digital Toolkits':
      return <DigitalToolkitsList source={DigitalToolkitsSource.Admin} />;
    default:
      return 'Service Content';
  }
}

export function ServiceDetail(): JSX.Element {
  const { id } = useParams();
  const [title, setTitle] = useState<string>('');
  const [hasContent, setHasContent] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { state } = useLocation();
  const [currentTab, setCurrentTab] = useState<'content' | 'content-second' | 'accessRights'>(state?.currentTab ? state?.currentTab : 'content');
  const [permission, setPermission] = useState<ServicePermission>({ HasContentAccess: false, HasAccessManagementAccess: false });
  const serviceId = id as GUID;
  const token = useTypedSelector(state => state.userReducer.token);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const marginLeft = title === 'Brand Assets' ? -6 : 0;
  const user = useTypedSelector((state) => state.userReducer.data);
  const isTMorSM = (user?.IsTerritoryManager || user?.IsSalesManager) && (title === 'Digital Toolkits' || title === 'Brand Assets');

  const getTabLabel = (title: string): JSX.Element | string => {
    switch (title) {
      case 'Brand Assets':
        return 'Assets';
      case 'Industry Insights':
        return 'Insights';
      case 'Industry Events':
        return 'Industry Events';
      case 'Product Updates':
        return 'Products';
      case 'Focus of the Month':
        return 'Topics';
      case 'Document Library':
        return 'Threads';
      case 'Digital Toolkits':
        return 'Digital Toolkits';
      default:
        return title;
    }
  };

  const getService = useCallback((serviceId: GUID, resetTab = true) => {
    if (token) {
      setLoading(true);
      GetServiceForAdminRequest(
        token,
        serviceId,
        (response) => {
          setLoading(false);
          if (response.Result.Service.Name !== 'Document Library') {
            setCurrentTab(prev => resetTab ? 'content' : prev);
          }
          setTitle(response.Result.Service.Name);
          setHasContent(response.Result.Service.HasContent);
          dispatch({ type: 'SET_CURRENT_ACCESS_RIGHTS', payload: { currentData: response.Result.Service.AccessRights } });
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    }
  }, [token, dispatch]);

  const getServicePermissions = useCallback((serviceId: GUID) => {
    if (token) {
      setLoading(true);
      GetServicePermissions(
        serviceId,
        token,
        (response) => {
          setPermission(response.Result);
        },
        () => navigate('/admin/unauthorized')
      );
    }
  }, [token, navigate]);

  useEffect(() => {
    getServicePermissions(serviceId);
    getService(serviceId);

    return () => {
      setTitle('');
    };
  }, [token, getService, serviceId, getServicePermissions]);

  if (loading) {
    return (
      <Dashboard justifyContent='flex-start'>
        <CircularProgress
          color='primary'
          size={50}
          style={{ zIndex: 999, position: 'absolute', top: '50vh', right: '40vw' }}
        />
      </Dashboard>
    );
  }

  return (
    <Dashboard justifyContent='flex-start'>
      <PageHeader title={title} />
      {!hasContent || !permission.HasContentAccess ? (
        <AccessRightsManagement noTab serviceId={serviceId} getService={() => { getService(serviceId); }} />
      ) : permission.HasContentAccess && !permission.HasAccessManagementAccess ? (
        <TabContext value={currentTab}>
          {RenderServiceContent(title, isTMorSM)}
        </TabContext>
      ) : title === 'Document Library' ? (
        <TabContext value={currentTab}>
          <TabList
            onChange={(e: unknown, newValue: 'content' | 'accessRights') => {
              setCurrentTab(newValue);
              navigate('.', { state: { currentTab: newValue }, replace: true });
            }}
            sx={{ margin: '24px 0 0', width: '100%' }}
          >
            <Tab data-testid={`${title}-merchants-tab`} label="Merchants" value="content" sx={{ width: '50%', maxWidth: '50%' }} />
            <Tab data-testid={`${title}-territory-managers-tab`} label="Sales & Territory Managers" value="accessRights" sx={{ width: '50%', maxWidth: '50%' }} />
          </TabList>
          <Box sx={{ minHeight: '80vh', width: '100%' }}>
            <DocumentRepository tabValue={currentTab} currentTab={currentTab} />
          </Box>
        </TabContext>
      ) : title === 'Brand Assets' ? (
        <TabContext value={currentTab}>
          <TabList onChange={(e: unknown, newValue: 'content' | 'content-second' | 'accessRights') => setCurrentTab(newValue)} sx={{ margin: '24px 0 0', width: '100%' }}>
            <Tab data-testid={`${title}-content-tab`} label={getTabLabel(title)} value="content" sx={{ width: '33%', maxWidth: '33%' }} />
            <Tab data-testid={'focused-content-tab'} label='Focused Content' value="content-second" sx={{ width: '33%', maxWidth: '33%' }} />
            <Tab data-testid="acces-rights-tab" label="Access Rights Management" value="accessRights" sx={{ width: '33%', maxWidth: '33%' }} />
          </TabList>
          <Box sx={{ minHeight: '80vh', width: '100%', marginLeft: marginLeft }}>
            {currentTab === 'content' && (
              <>
                {RenderServiceContent(title, isTMorSM)}
              </>
            )}
            {currentTab === 'content-second' && (
              <>
                <FocusedContentList />
              </>
            )}
            {currentTab === 'accessRights' && (
              <>
                <AccessRightsManagement serviceId={serviceId} getService={() => { getService(serviceId, false); }} />
              </>
            )}
          </Box>
        </TabContext>
      ) : (
        <TabContext value={currentTab}>
          <TabList onChange={(e: unknown, newValue: 'content' | 'accessRights') => setCurrentTab(newValue)} sx={{ margin: '24px 0 0', width: '100%' }}>
            <Tab data-testid={`${title}-content-tab`} label={getTabLabel(title)} value="content" sx={{ width: '50%', maxWidth: '50%' }} />
            <Tab data-testid="acces-rights-tab" label="Access Rights Management" value="accessRights" sx={{ width: '50%', maxWidth: '50%' }} />
          </TabList>
          <Box sx={{ minHeight: '80vh', width: '100%', marginLeft: marginLeft }}>
            {RenderServiceContent(title, isTMorSM)}
            <AccessRightsManagement serviceId={serviceId} getService={() => { getService(serviceId, false); }} />
          </Box>
        </TabContext>
      )}
    </Dashboard >
  );
}
