import { Box, Checkbox, SxProps, Typography } from '@mui/material';
import { LIGHT_THEME } from '../constants/theme';

export interface IAssignCardItemProps {
  item: Store;
  onChange: (c: boolean, store: Store) => void;
  selected: boolean;
  title?: string;
  additionalInfo?: any;
  sx?: SxProps<any>
}

export function AssignCardItem (props: IAssignCardItemProps):JSX.Element {
  const {additionalInfo, item, selected, title, sx, onChange} =props;
  return(
    <Box
      borderRadius={1}
      mb={1}
      ml={1}
      mr={1}
      pt={1}
      pb={1}
      pl={'7px'}
      bgcolor={selected ? LIGHT_THEME.palette.action.selected : LIGHT_THEME.palette.background.default}
      display="flex"
      alignItems="center"
      sx={{
        transition: '0.2s background-color',
        cursor: 'pointer',
        '&:hover': {
          bgcolor: LIGHT_THEME.palette.action.selected
        },
        ...sx
      }}
    >
      <Checkbox
        checked={selected}
        onChange={(e,c)=> onChange(c, item)}
      />

      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        ml="12px"
        onClick={()=> onChange(!selected, item)}
      >
        <Typography>
          {item.Name || title}
        </Typography>

        {additionalInfo()}
      </Box>
    </Box>
  );
}
