/* eslint-disable @typescript-eslint/naming-convention */
import { Grid } from '@mui/material';
import React from 'react';
import { ModalComponent } from '../../components-molecules/Modal';
import { PdfViewer } from '../PdfViewer';

interface IProps {
  visible: boolean;
  close: () => void;
  url: string
}

export const PdfPreviewModal = ({visible,close,url}: IProps): JSX.Element => {
  return (
    <ModalComponent
      fullWidth
      submitButton={false}
      title={''}
      isOpen={visible}
        
      close={close}
      assetDetailPage>
      <Grid container item direction="row" justifyContent={'center'} sx={{ flex: 1, width: '100vw', height: '100vh' }}>
        <Grid xs={12} sm={7} item container justifyContent="center" alignItems="center" sx={{ padding: '0 24px',height:'100%', }}>
          <PdfViewer url={url} />
        </Grid>
      </Grid>
    </ModalComponent>
  );
};

