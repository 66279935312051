import { Grid } from '@mui/material';
import { RecentArticles } from './RecentArticles';
import { RecentColours } from './RecentColours';
import { RecentProducts } from './RecentProducts';
import { RecentSearches } from './RecentSearches';
import { ServicePageHeader } from './appbars/ServicePageHeader';

interface IProps {
  categoryId: string;
  serviceId: string;
  service: any;
}

export function KnowledgeBaseService({categoryId, serviceId, service}: IProps): JSX.Element {
  return (
    <>
      <ServicePageHeader
        service={service}
        customSearchbar={'knowledgeBase'}
      />
      <Grid item container sx={{ maxWidth: '1408px', width: '100%', padding: '0 24px' }}>
        <Grid item sx={{ width: '100%', margin: '10px 0 0 0 ' }}>
          <RecentSearches categoryId={categoryId} serviceId={serviceId}/>
        </Grid>
        <Grid item sx={{ width: '100%', margin: '10px 0 0 0 ' }}>
          <RecentArticles categoryId={categoryId} serviceId={serviceId}/>
        </Grid>
        <Grid item sx={{ width: '100%', margin: '10px 0 0 0 ' }}>
          <RecentProducts categoryId={categoryId} serviceId={serviceId}/>
        </Grid>
        <Grid item sx={{ width: '100%', margin: '10px 0 0 0 ' }}>
          <RecentColours categoryId={categoryId} serviceId={serviceId}/>
        </Grid>
      </Grid>
    </>
  );
}
