import { ListDivider } from '../components-molecules/Divider';
import InvitationItem from '../components-molecules/InvitationItem';
import { NoItems } from '../components-molecules/NoItems';
import { InvitationGroup } from '../service/serviceService';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import { LIGHT_THEME } from '../constants/theme';

/* eslint-disable @typescript-eslint/naming-convention */
interface InvitationListProps{
  data:InvitationGroup[];
  tab: 'completed' | 'rejected' | 'pending'
}

export const InvitationsList =(props:InvitationListProps):JSX.Element=>{
  const {data,tab}=props;

  if(data.length === 0){
    return (
      <NoItems
        icon={<DomainDisabledIcon sx={{ fontSize: 88, fill: LIGHT_THEME.palette.primary.main }} />}
        title={noItemsTexts[tab].title}
        description={noItemsTexts[tab].description} />
    );
  }

  return(
    <>
      {
        data.map(group=>
          <ListDivider
            key={group.Group}
            division={{ name: group.Group, length: group.MerchantRegistrations.length }}
            caption={{ singular: 'Merchant', plural: 'Merchants' }}
            halfMargin
          >
            {
              group.MerchantRegistrations.map(item=>
                <InvitationItem key={item.Id} data={item} tab={tab}/>
              )
            }
          </ListDivider>
        )
      }
    </>
  );

};

const noItemsTexts={
  pending:{
    title:'No new merchant registration requests',
    description:'You don’t have any new merchant registration requests yet.'
  },
  completed:{
    title:'No completed registration requests',
    description:'Completed registration requests will appear here.'
  },
  rejected:{
    title:'No rejected registration requests',
    description:'Rejected registration requests will appear here.'
  },
};
