const INITIAL: IndustryInsightState = {
  currentData: {
    Id: '' as GUID,
    Title: '',
    Description: '',
    PdfUrl: '',
    ImageUrl: '',
    ThumbnailUrl: '',
    Published: true,
  },
};

export const industryInsightReducer = (state: IndustryInsightState = INITIAL, action: IndustryInsightAction): IndustryInsightState => {
  switch (action.type) {
    case 'SET_CURRENT_INDUSTRY_INSIGHT':
      return { ...state, currentData: action.payload.currentData };
    default:
      return state;
  }
};
