import { AxiosError, CancelToken } from 'axios';
import {
  HttpRequestDelete,
  HttpRequestGet,
  HttpRequestPost,
} from './genericService';

const SERVICE_NAME = 'digitaltoolkit';

export interface ListRequestModel {
  Size: number;
  Page: number;
  ServiceId?: string;
}

export interface DigitalToolkitsListModel {
  Id: string;
  Order: number;
  Title: string;
  Description: string;
  FileUrl: string;
  ThumbnailUrl: string;
}

interface DigitalToolkitsResponseModel {
  Count: number;
  Result: DigitalToolkitsListModel[];
}

export function ListRequest(
  token: string,
  body: ListRequestModel,
  success: (response: ApiResponse<DigitalToolkitsResponseModel>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void,
  cancelToken?: CancelToken
): void {
  HttpRequestPost<DigitalToolkitsResponseModel, ListRequestModel>(
    `/${SERVICE_NAME}/list`,
    body,
    (response: { data: ApiResponse<DigitalToolkitsResponseModel> }) =>
      success(response.data),
    (error: AxiosError<ErrorResponse, any>) => failure(error),
    token,
    cancelToken
  );
}

export interface SearchRequestModel {
  Size: number;
  Page: number;
  Query: string;
}

export interface DigitalToolkitGroupItem {
  GroupName: string;
  Products: DigitalToolkit[];
}

interface SearchResponseModel {
  Count: number;
  Result: DigitalToolkitGroupItem[];
}

export function SearchRequest(
  token: string,
  body: SearchRequestModel,
  success: (response: ApiResponse<SearchResponseModel>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void,
  cancelToken?: CancelToken
): void {
  HttpRequestPost<SearchResponseModel, SearchRequestModel>(
    `/${SERVICE_NAME}/search`,
    body,
    (response: { data: ApiResponse<SearchResponseModel> }) =>
      success(response.data),
    (error: AxiosError<ErrorResponse, any>) => failure(error),
    token,
    cancelToken
  );
}

interface CreateRequestModel {
  Title: string;
  Description: string;
  FileUrl: string;
  Published: boolean;
}

export function CreateRequest(
  token: string,
  body: CreateRequestModel,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, CreateRequestModel>(
    `/${SERVICE_NAME}`,
    body,
    (response: { data: ApiResponse<string> }) => success(response.data),
    (error: AxiosError<ErrorResponse, any>) => failure(error),
    token
  );
}

interface UpdateRequestModel {
  Id: string;
  Title: string;
  Description: string;
  FileUrl: string;
  Published: boolean;
}

export function UpdateRequest(
  token: string,
  body: UpdateRequestModel,
  success: (data: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, UpdateRequestModel>(
    `/${SERVICE_NAME}/update`,
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface ReOrderRequestModel {
  Id: string;
}

export function ReOrderRequest(
  token: string,
  body: ReOrderRequestModel[],
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, ReOrderRequestModel[]>(
    `/${SERVICE_NAME}/updateOrders`,
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface ChangePublishStateRequestModel {
  Id: string;
}

export function ChangePublishStateRequest(
  token: string,
  body: ChangePublishStateRequestModel,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, ChangePublishStateRequestModel>(
    `/${SERVICE_NAME}/changePublishState`,
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetRequest(
  token: string,
  id: string,
  success: (response: ApiResponse<DigitalToolkit>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestGet<DigitalToolkit>(
    `/${SERVICE_NAME}/${id}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function DeleteRequest(
  token: string,
  id: string,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestDelete<string>(
    `/${SERVICE_NAME}/${id}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export enum LogActionType {
  view = 'View',
  download = 'Download',
}

interface LogActionRequestModel {
  Id: string;
  Action: LogActionType;
  FileUrl: string;
}

function LogActionRequest(
  token: string,
  body: LogActionRequestModel,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, ChangePublishStateRequestModel>(
    `/${SERVICE_NAME}/logAction`,
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function LogAction(
  token: string | undefined,
  id: string,
  action: LogActionType,
  fileUrl: string
) : void {
  if (token) {
    LogActionRequest(
      token,
      {
        Id: id,
        Action: action,
        FileUrl: fileUrl,
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  }
}
