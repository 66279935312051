import { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { AddMerchantDetails } from '../components-organisms/modals/AddMerchantDetails';
import { AddUserDetails } from '../components-organisms/modals/AddUserDetails';
import { GetUserMarketing, UserSetupDetail, UserSetupDetailResponseModel } from '../service/serviceService';
import { useNavigate } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { AuthUserTokenRequest, GetUserInfoRequest } from '../service/userService';

interface IProfileSetupProps {
  userId: GUID;
  isSetUp: boolean;
  merchantId?: GUID;
}

export function ProfileSetup({ userId, isSetUp, merchantId }: IProfileSetupProps): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const [modalOpen, setModalOpen] = useState<string>('');
  const [token, user] = useTypedSelector(state => [state.userReducer.token, state.userReducer.data]);
  const [userDetails, setUserDetails] = useState<UserSetupDetailResponseModel>({
    Id: '' as GUID,
    MerchantId: '' as GUID,
    Email: '',
    IsManager: false
  });

  useLayoutEffect(() => {
    if (userId && !isSetUp) {
      UserSetupDetail(
        userId,
        (response) => {
          setUserDetails(response.Result);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    } else if (user) {
      setUserDetails({
        Id: user.Id,
        MerchantId: user.MerchantId,
        Email: user.Email,
        IsManager: user.IsMerchantManager
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setModalOpen(!isSetUp ? 'user' : 'merchant');
  }, [isSetUp, userDetails]);

  const navigateTo = (userData: UserProperties): void => {
    if (userData.IsAdmin) navigate('/welcome', {state: { showTutorial: !userData.DontShowTutorial }});
    else navigate('/', {state: { showTutorial: !userData.DontShowTutorial }});
  };

  const getUserInfo = (token: string): void => {
    GetUserInfoRequest(
      token,
      (response) => {
        dispatch({ type: 'USER_SIGNIN_SUCCESS', payload: { token: token, data: response.Result } });
        if (!response.Result.IsAdmin) {
          GetUserMarketing(
            token,
            (resp) => dispatch({ type: 'USER_SET_MARKETING', payload: { marketing: resp.Result } }),
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {}
          );
        }
        navigateTo(response.Result);
      },
      (error) => {
        if (error.response) dispatch({ type: 'USER_ANY_FAILURE', payload: { error: error.response.data.Error } });
      }
    );
  };

  const navigateUsers = () => {
    if (token) {
      getUserInfo(token);
    } else {
      AuthUserTokenRequest(
        { Email: userDetails.Email },
        (response) => {
          getUserInfo(response.Result);
          return { type: 'USER_SET_TOKEN', payload: { token: response.Result } };
        },
        (error) => {
          if (error.response) dispatch({ type: 'USER_ANY_FAILURE', payload: { error: error.response.data.Error } });
        },
      );
    }
  };

  return (
    <Fragment>
      {
        modalOpen === 'user'
          ? <AddUserDetails userEmail={userDetails.Email} isManager={userDetails.IsManager} isOpen={modalOpen === 'user'} modalCallback={() => userDetails.IsManager ? setModalOpen('merchant') : navigateUsers()} />
          : null
      }
      {
        modalOpen === 'merchant'
          ? <AddMerchantDetails merchantId={merchantId ? merchantId : userDetails.MerchantId} isOpen={modalOpen === 'merchant'} modalCallback={() => navigateUsers()} />
          : null
      }
    </Fragment>
  );
}
