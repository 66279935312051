import { useNavigate, useLocation } from 'react-router-dom';

import ListItemIcon from '@mui/material/ListItemIcon';

import {
  DrawerListComponent,
  DrawerListSubheaderComponent,
  DrawerListItemButtonComponent,
  DrawerListItemComponent,
  DrawerListOverlineSubheaderComponent
} from '../components-atoms/DrawerComponents';
import { CaptionPrimary, HeaderFivePrimary } from '../components-atoms/TypographyComponents';
import { ListItemText, SxProps } from '@mui/material';
import { useTypedSelector } from '../hooks/TypedReduxHooks';

export function DrawerList({ items, sx }: { items: DrawerItem[]; sx?: SxProps<any> }): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const [invitationCount] = useTypedSelector((state) => [state.drawerReducer.invitationCount]);
  function onClick (item: DrawerItem) {
    if (item.route) {
      item.external ? window.open(item.route):navigate(item.route);
    }
  }

  const displayBadge=(route:string|undefined)=>{
    if(route && route !== '/admin/merchantregistrations'){
      return false;
    }
    return invitationCount && invitationCount > 0 ? true : false;
  };
  
  const getColour=(route:string|undefined)=>{
    return route && location.pathname.startsWith(route) ?'#012169':'#00000099';
  };

  return (
    <DrawerListComponent sx={sx}>
      {items.map((item, index) => {
        if (item.type === 'item') {
          return (
            <DrawerListItemComponent key={index}>
              <DrawerListItemButtonComponent
                selected={Boolean(item.route && location.pathname.startsWith(item.route))}
                onClick={() => onClick(item)}
                sx={{
                  '&.Mui-selected': {
                    background: `${(item.customBackgroundColor) && item.customBackgroundColor} !important`,
                    '& > .MuiListItemText-root > .MuiTypography-root': {
                      color: `${(item.customBackgroundColor) && 'white !important'}`
                    }
                  }
                }}
              >
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText primaryTypographyProps={{
                  fontFamily: '"Open Sans", "Colour Sans", "Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: 600,
                  fontSize: '0.875rem',
                  lineHeight: 1.57,
                  letterSpacing: '0.00714em',
                }} primary={item.primaryText} data-testid="left-menu-text" />
                {
                  displayBadge(item.route)
                  && <div style={{width:'23px',height:'20px',borderRadius:'10px',display:'flex',justifyContent:'center',alignItems:'center', backgroundColor:getColour(item.route)}}>
                    <CaptionPrimary style={{color:'white'}}>{ invitationCount }</CaptionPrimary>
                  </div>
                }
                
              </DrawerListItemButtonComponent>
            </DrawerListItemComponent>
          );
        } else if (item.type === 'head') {
          return item.header ? <HeaderFivePrimary key={index} style={{ padding: '32px 0px 20px 20px' }}>{item.primaryText}</HeaderFivePrimary>
            : <DrawerListSubheaderComponent key={index}>{item.primaryText}</DrawerListSubheaderComponent>;
        } else {
          return (item.header ? <HeaderFivePrimary key={index} style={{ paddingLeft: 20, paddingTop: 16 }}>{item.primaryText}</HeaderFivePrimary>
            : <DrawerListItemComponent key={index} style={{ padding: '16px 0 0 0' }}>
              <DrawerListOverlineSubheaderComponent>{item.primaryText}</DrawerListOverlineSubheaderComponent>
            </DrawerListItemComponent>);
        }
      })}
    </DrawerListComponent>
  );
}
