import { useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, Container, IconButton } from '@mui/material';
import { ButtonContainedPrimary } from '../components-atoms/ButtonComponents';
import { MiniDialog } from '../components-molecules/dialogs/MiniDialog';
import { SortButton } from '../components-molecules/SortButton';
import { LIGHT_THEME } from '../constants/theme';
import { ListDivider } from '../components-molecules/Divider';
import { GetSelectedTerritoryManagers, GroupedTerritoryManagers, ListTerritoryManagersRequest, SubmitAssignTerritoryManagerRequest } from '../service/merchantsService';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { AZ_SORT } from '../utils/sortTypes';
import { AssignCardItem } from '../components-molecules/StoreAssignCardItem';
import { BodyTwoPrimary, CaptionSecondary } from '../components-atoms/TypographyComponents';
import SearchIcon from '@mui/icons-material/Search';
import BusinessIcon from '@mui/icons-material/Business';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SearchbarWithDeboubce } from '../components-molecules/SearchBarWithDebounce';

const SIZE = 20;
export function TerritoryManagerAssignModal({ onSubmit }: { onSubmit?: () => void; }): JSX.Element {
  const [token] = useTypedSelector((state) => [state.userReducer.token]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchOpen, setSearchOpen] = useState(false);

  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState(0);
  const [sort, setSort] = useState<any>(AZ_SORT[0]);
  const [query, setQuery] = useState<string>('');

  // TODO: Type
  const [territoryManagers, setTerritoryManagers] = useState<GroupedTerritoryManagers[]>([]);
  const [selectedManagers, setSelectedManagers] = useState<GUID[]>([]);

  const getSelectedTMList = useCallback(() => {
    if (!token) { return; }

    GetSelectedTerritoryManagers(
      token,
      (response: any) => {
        if (response) {
          setSelectedManagers(response.Result);
        }
      },
      (error) => {
        if (error.response) {
          console.error(error.response.data.Error);
        }
      }
    );
  }, [token]);

  //const handleResponse = (res: ApiResponse<ListTerritoryManagersResponseModel>, prevData: GroupedTerritoryManagers[]) => {
  //  // TODO: any
  //  const response = res.Result;
  //  let temp = [...prevData];
  //  response.Result.map(m => {
  //    const tempMatch = temp.find((f) => f.GroupName === m.GroupName);
  //    if (tempMatch) {
  //      temp[temp.indexOf(tempMatch)].Result = [...temp[temp.indexOf(tempMatch)].Result, ...m.Result];
  //    } else {
  //      temp = [...temp, m];
  //    }
  //  });
  //  setTerritoryManagers(temp);
  //  setCount(res.Result.Count);
  //  setLoading(false);
  //};

  const getTerritoryManagers = useCallback((page = 0, prevData: GroupedTerritoryManagers[] = []): void => {
    if (!token || !open) { return; }
    setLoading(true);

    ListTerritoryManagersRequest(
      token,
      {
        Page: page,
        Size: SIZE,
        Query: query,
        OrderBy: sort.value
      },
      res => {
        setCount(res.Result.Count);
        const response = res.Result;
        let temp = [...prevData];
        response.Result.map(m => {
          const tempMatch = temp.find((f) => f.Group === m.Group);
          if (tempMatch) {
            temp[temp.indexOf(tempMatch)].TerritoryManagers = [...temp[temp.indexOf(tempMatch)].TerritoryManagers, ...m.TerritoryManagers];
          } else {
            temp = [...temp, m];
          }
        });
        setTerritoryManagers(temp);
        setLoading(false);
      },
      (error) => {
        if (error.response) {
          console.error(error.response.data.Error);
          setLoading(false);
        }
      }
    );
  }, [open, query, sort.value, token]);

  useEffect(() => {
    if (token && open) {
      getTerritoryManagers();
    }
  }, [token, sort, query, open, getTerritoryManagers]);

  useEffect(() => {
    getSelectedTMList();
  }, [getSelectedTMList]);

  const loadMore = () => {
    setPage(page + 1);
  };
  useEffect(() => {
    if (page !== 0) {
      getTerritoryManagers(page, territoryManagers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  const getDataLength = useCallback(() => {
    return territoryManagers?.flatMap(f => f.TerritoryManagers).length;
  }, [territoryManagers]);
  const submitDialog = (): void => {
    if (!token) { return; }
    setLoading(true);
    setPage(0);

    SubmitAssignTerritoryManagerRequest(
      token,
      {
        TerritoryManagerIds: selectedManagers,
      },
      (response: any) => {
        if (response) {
          setLoading(false);
          setOpen(false);
          onSubmit?.();
        }
      },
      (error) => {
        if (error.response) {
          console.error(error.response.data.Error);
          setLoading(false);
        }
      }
    );
  };

  const onMerchantCheckboxChange = (checked: boolean, merchant: any) => {
    if (checked) {
      setSelectedManagers((m: Array<string>) => ([
        ...m,
        merchant.Id
      ]));
    } else {
      setSelectedManagers((m: any) => ([
        ...m.filter((f: any) => f !== merchant.Id),
      ]));
    }
  };

  function headerRight() {
    return (
      <Box display="flex" justifyContent="flex-end" mr={2}>
        <IconButton
          aria-label="search"
          sx={{ margin: '0 8px 0 0', height: '48px', width: '48px', '&:hover': { backgroundColor: LIGHT_THEME.palette.action.selected } }}
          onClick={() => setSearchOpen(true)}>
          <SearchIcon color={'primary'} />
        </IconButton>

        <SortButton
          menuItems={AZ_SORT.map((sortItem: { title: string; value: string; }) => ({
            title: sortItem.title,
            value: sortItem.value,
            handleSelect: () => {
              setPage(0);
              setSort(sortItem);
            },
          }))}
          selectedSortType={sort}
        />
      </Box>
    );
  }
  const searchOnChange=(text:string)=>{
    setQuery(text);
  };

  return (
    <>
      <ButtonContainedPrimary
        data-testid={'Assign From List'}
        startIcon={<BusinessIcon />}
        onClick={() => { setPage(0); setOpen(true); }}
        sx={{ margin: '0 0 0 8xx', padding: '8px 11px !important', width: 'max-content' }}>
        Assign From List
      </ButtonContainedPrimary>

      <MiniDialog
        title={'Territory Managers'}
        open={open}
        submit={submitDialog}
        header={headerRight}
        close={() => {
          setOpen(false);
        }}>
        <Box mb={2} ml={2} mr={2}>
          <SearchbarWithDeboubce
            isOpen={searchOpen}
            query={query}
            placeholder="Search Territory Managers"
            onChange={searchOnChange}
            onCancel={() => { setSearchOpen(false); setQuery(''); }}
          />
        </Box>
        {loading && page === 0 ? <Box display="flex" justifyContent="center" mt={4} p={4}>
          <CircularProgress
            color='primary'
            size={50}
          />
        </Box> : territoryManagers.length > 0 ? <div
          id="scrollableDiv"
          style={{ overflow: 'auto', maxHeight: searchOpen ? 300 : 360 }}>
          <InfiniteScroll
            dataLength={getDataLength()}
            next={loadMore}
            hasMore={getDataLength() < count}
            scrollableTarget="scrollableDiv"
            loader={
              <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
                <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
              </Container>
            }
          >
            {territoryManagers.map((section: any, index: number) => {
              return (
                <ListDivider
                  key={`${section.Group}-${index}`}
                  noMargin
                  division={{ name: section.Group, length: section.Count }}
                  caption={{ singular: 'Territory Manager', plural: 'Territory Managers' }}
                  sx={{ mt: 1.5, mb: 0.5 }}
                >
                  {section.TerritoryManagers.map((item: any, index: number) =>
                    <AssignCardItem
                      key={`${item.Id}-${index}`}
                      item={item}
                      title={`${item.FirstName} ${item.LastName}`}
                      selected={Boolean(selectedManagers.find((s: any) => s === item?.Id))}
                      onChange={onMerchantCheckboxChange}
                      additionalInfo={() =>
                        <CaptionSecondary>
                          {item.Email}
                        </CaptionSecondary>
                      }
                    />
                  )}
                </ListDivider>
              );
            })}
          </InfiniteScroll>
        </div> : <BodyTwoPrimary sx={{ padding: '16px 24px 0' }}>0 results found.</BodyTwoPrimary>}
      </MiniDialog>
    </>
  );
}
