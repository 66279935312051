/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Grid, SxProps, Theme } from '@mui/material';
import { BodyOnePrimary, BodyTwoSecondary } from '../components-atoms/TypographyComponents';
import { LIGHT_THEME } from '../constants/theme';

interface IOption {
  title: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  value: boolean;
}
interface IProps {
  options: IOption[];
  disabled?: boolean;
  onChange: (value: boolean) => void;
  noText?: boolean;
  buttonContainerStyle?: SxProps<Theme>;
  value?: boolean;
  fullWidth?: boolean;
  title?: string;
  subtitle?: string;
}
export function AssetStatusButtonGroup({ options, disabled, onChange, noText, buttonContainerStyle, value, fullWidth, title, subtitle }: IProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(value !== undefined ? options.indexOf(options.find(f => f.value === value) as IOption) : 0);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    onChange(options[index].value);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current
      && anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Grid item container sx={{ flexGrow: 1, flexShrink: 1, justifyContent: 'space-between', alignItems: 'center' }}>
        {!noText ? <Grid item xs={9}>
          <BodyOnePrimary sx={{ marginBottom: '4px' }}>{title}</BodyOnePrimary>
          <BodyTwoSecondary variant="body2">{subtitle}</BodyTwoSecondary>
        </Grid> : <></>}

        <Grid item sx={{ ...buttonContainerStyle, maxWidth: 'max-content!important' }} xs={3}>
          <ButtonGroup sx={{ borderRadius: 4, width: '100%' }} color={options[selectedIndex].color} variant="outlined" ref={anchorRef} aria-label="split button" >
            <Button
              disabled
              data-testid="current-publish-status"
              sx={{
                '&:disabled': {
                  backgroundColor: 'white',
                  borderColor: LIGHT_THEME.palette[options[selectedIndex].color].light,
                  color: LIGHT_THEME.palette[options[selectedIndex].color].main,
                }
              }} fullWidth={fullWidth}>{options[selectedIndex].title}</Button>
            <Button
              disabled={disabled}
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select asset status"
              aria-haspopup="menu"
              onClick={handleToggle}
              data-testid="status-arrow-dropdown"
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Popper
        style={{ zIndex: 999, width: fullWidth ? 336.5 : 142.9 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal

      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.title}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

    </>
  );
}
