/* eslint-disable @typescript-eslint/naming-convention */
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BodyOnePrimary, BodyTwoSecondary, HeaderSix } from '../components-atoms/TypographyComponents';
import { IStyles } from '../constants/theme';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { PendingInvitation } from '../service/serviceService';
import { FormatDate } from '../utils/dateUtils';
import Button from '@mui/material/Button';

interface IProps {
  data:PendingInvitation;
  tab: 'completed' | 'rejected' | 'pending'
}

export default function InvitationItem(props:IProps):JSX.Element {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const {data, tab} = props;
  const [userData, superData] = useTypedSelector(state => [state.userReducer.data, state.userReducer.super]);
  const showThreeDotIcon = userData && userData.IsAdmin && (userData.IsTerritoryManager || userData.IsSalesManager) && !superData;

  const onClick =()=>{
    dispatch({ type: 'SET_TAB', payload: { tab: tab } });
    navigate('/admin/merchantregistrations/'+data.Id);
  };

  const displayTimeStamp=()=>{
    return tab === 'completed' || tab === 'rejected' ? true : false;
  };

  const timeStampTitle=()=>{
    switch (tab) {
      case 'completed':
        return 'Completed';
      case 'rejected':
        return 'Rejected';
      default:
        break;
    }
  };
  const procesedByTitle=()=>{
    switch (tab) {
      case 'completed':
        return 'Completed By';
      case 'rejected':
        return 'Rejected By';
      default:
        break;
    }
  };
  const timeStamp=()=>{
    switch (tab) {
      case 'completed':
        return data.Completed;
      case 'rejected':
        return data.Rejected;
      default:
        return'';
    }
  };
  
  return (
    <Button fullWidth variant="text" onClick={onClick} style={styles.container} >
      <Grid container >
        <Grid style={styles.bodyGrid} sx={{ maxWidth: showThreeDotIcon ? 'calc(100% - 190px)' : 'calc(100% - 146px)',display:'flex' }}>
          <HeaderSix style={styles.mb4}>{data.MerchantName}</HeaderSix>
        </Grid>
      </Grid>
      <Grid container style={styles.detailContainer}  >
        <div style={{width:displayTimeStamp()? 'calc(100%/4)': '50%',...styles.textContainer}}>
          <BodyTwoSecondary sx={{marginBottom:'4px'}}>
            {data.RoleName}
          </BodyTwoSecondary>
          <BodyOnePrimary sx={{textAlign:'left'}}>
            {data.FirstName + ' ' + data.LastName}
          </BodyOnePrimary>
        </div>
        <div style={{width:displayTimeStamp()? 'calc(100%/4)': '50%',...styles.textContainer}}>
          <BodyTwoSecondary sx={{marginBottom:'4px'}}>
            Store
          </BodyTwoSecondary>
          <BodyOnePrimary sx={{textAlign:'left'}}>
            {data.StoreName !== '' ? data.StoreName : '/'}
          </BodyOnePrimary>
        </div>
        
        {displayTimeStamp() && <div style={{width: 'calc(100%/4)',...styles.textContainer}}>
          <BodyTwoSecondary sx={{marginBottom:'4px'}}>
            {timeStampTitle()}
          </BodyTwoSecondary>
          <BodyOnePrimary sx={{textAlign:'left'}}>
            {FormatDate(new Date(timeStamp()), { day: '2-digit', month: 'short', format: 'MM dd, YYYY' })}
          </BodyOnePrimary>
        </div>}
        {displayTimeStamp() && <div style={{width: 'calc(100%/4)',...styles.textContainer}}>
          <BodyTwoSecondary sx={{marginBottom:'4px'}}>
            {procesedByTitle()}
          </BodyTwoSecondary>
          <BodyOnePrimary sx={{textAlign:'left'}}>
            {data?.ProcessedByName !== '' ? data.ProcessedByName : '/'}
          </BodyOnePrimary>
        </div>}
      </Grid>
      
    </Button>
  );
}
const styles: IStyles = {
  container: {
    maxWidth: '840px',
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '20px',
    margin: '24px 0px 0px 0px',
    padding: '24px',
    cursor: 'pointer',
    display:'flex',
    flexDirection:'column'
  },
  iconGrid: { width: '20px' },
  bodyGrid: { width: '100%' },
  mt4: { marginTop: '4px' },
  mr4: { marginRight: '4px' },
  mb4: { marginBottom: '4px' },
  detailContainer: { 
    marginTop: '12px' 
  },
  w50:{ width: '50%'},
  textContainer:{
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-start'
  }
};
