import { debounce, FilledInput } from '@mui/material';
import React, { useRef } from 'react';
import { DEBOUNCE_TIMEOUT } from '../constants/genaralValues';

interface IProps {
  placeholder?:string;
  onChangeWithDebounce:(text:string)=>void;
  onChange:(text:string)=>void;
  endAdornment?:  React.ReactNode;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
  value?:string|undefined
}

export default function MainSearchInputWithDebounce(props:IProps):JSX.Element {
  const {placeholder, onChange, onChangeWithDebounce, endAdornment, onBlur, value } = props;
 
  
  const debouncedSearchTerm = useRef(
    debounce(async (textValue) => {
      if(onChangeWithDebounce !== undefined){
        onChangeWithDebounce(textValue);
      }
    }, DEBOUNCE_TIMEOUT)
  ).current;
  
  return (
    <FilledInput
      size="medium"
      value={value}
      fullWidth
      data-testid="search-input"
      id="search-input"
      placeholder={placeholder}
      onChange={event=>{
        onChange(event.target.value);
        debouncedSearchTerm(event.target.value);
      }}
      endAdornment={endAdornment}
      onBlur={onBlur}
    />
  );

}
