import { Fragment, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import { Section } from '../../components-molecules/section/Section';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { SectionRow } from '../../components-molecules/section/SectionRow';
import { BodyOneLink, BodyOneSecondary } from '../../components-atoms/TypographyComponents';
import { DeleteSalesManagerRequest, SearchSalesManagerResult } from '../../service/adminService';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { MiniDialog } from '../../components-molecules/dialogs/MiniDialog';

function NoMerchants(): JSX.Element {
  return (
    <SectionRow
      title="Merchants"
      lines={[{
        data: 'Manager does not have any merchant assigned yet. You can assign Territory Managers to a Merchant on a Merchant page.',
        type: 'body1',
        color: 'text.secondary'
      }]}
    />
  );
}

interface IProps {
  onDelete: () => void;
  salesmanager: SearchSalesManagerResult;
}

export function SalesManagerListCard({ salesmanager, onDelete }: IProps): JSX.Element {
  const navigate = useNavigate();
  const merchantsList = salesmanager.Merchants
    ? salesmanager.Merchants.map((merchant: any, index: number) =>
      <Link key={`${merchant.Id}${index}`} component={RouterLink} to={`/admin/merchants/${merchant.Id}`} sx={{ display: 'inline-block', margin: '0 0 0 4px' }}>
        {salesmanager.Merchants
          ? <BodyOneLink>{merchant.Name}{salesmanager.Merchants.length - 1 > index ? ',' : null}</BodyOneLink>
          : null
        }
      </Link>
    )
    : null;

  const token = useTypedSelector(state => state.userReducer.token);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const deleteSalesManager = () => {
    if (token) {
      DeleteSalesManagerRequest(
        token,
        salesmanager.Id,
        () => onDelete(),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  return (
    <Section>
      <SectionHeader
        hasMenuIcon
        title={`${salesmanager.FirstName} ${salesmanager.LastName}`}
        label={salesmanager.RegistrationPending ? 'Registration Pending' : ''}
        onDelete={() => setModalOpen(true)}
        onEdit={() => navigate('/admin/edit-salesmanager', { state: { salesmanager } })}
      />
      <SectionRow
        title="Role"
        lines={[
          { data: salesmanager.Role, type: 'body1' }
        ]}
      />
      <Box><Divider /></Box>
      <SectionRow
        title="Contact"
        lines={[
          { data: salesmanager.Email, type: 'body1' },
          { data: salesmanager.Phone, type: 'body2', color: 'text.secondary' }
        ]}
      />
      <Box><Divider /></Box>
      {(salesmanager.Merchants && salesmanager.Merchants.length > 0)
        ? <SectionRow
          title="Merchants"
          lines={[{
            element: <Fragment key={salesmanager.Email}><div>{merchantsList}</div></Fragment>
          }]}
        />
        : <NoMerchants />
      }
      {modalOpen
        ? <MiniDialog
          title="Delete Sales Manager"
          open={modalOpen}
          close={() => setModalOpen(false)}
          remove={() => {
            deleteSalesManager();
            setModalOpen(false);
          }}>
          <Box sx={{ padding: '0 24px' }}>
            <BodyOneSecondary>
              Are you sure you want to delete this Sales Manager?
            </BodyOneSecondary>
          </Box>
        </MiniDialog>
        : null
      }
    </Section>
  );
}
