import { AxiosError, CancelToken } from 'axios';
import {
  HttpRequestDelete,
  HttpRequestGet,
  HttpRequestPost,
  HttpRequestPut,
} from './genericService';

const SERVICE_NAME = 'focusedcontent';

export interface FocusedContentsListModel {
  DigitalAssetId: GUID;
  DigitalAssetName: string;
  ThumbnailUrl: string;
  PreviewUrl: string;
  FileUrl: string;
  FileName: string;
  BrandName: string;
  ProductName: string;
  ColourName: string;
  DigitalAssetType: string;
  Orientation: string;
  Published: Date;
  Created: Date;
  DigitalAssetFileType: string;
  ImageCount: number;
  PresentationCount: number;
  VideoCount: number;
  OtherCount: number;
  DownloadCount: number;
  FocusedPriority: number;
}

interface FocusedContentsResponseModel {
  Result: FocusedContentsListModel[];
}

export function ListRequest(
  token: string,
  success: (response: ApiResponse<FocusedContentsResponseModel>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void,
  cancelToken?: CancelToken
): void {
  HttpRequestGet<FocusedContentsResponseModel>(
    `/${SERVICE_NAME}/list`,
    (response: { data: ApiResponse<FocusedContentsResponseModel> }) =>
      success(response.data),
    (error: AxiosError<ErrorResponse, any>) => failure(error),
    token,
    cancelToken
  );
}

interface ReOrderRequestModel {
  Id: string;
}

export function ReOrderRequest(
  token: string,
  body: ReOrderRequestModel[],
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, ReOrderRequestModel[]>(
    `/${SERVICE_NAME}/updateOrders`,
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function AddRequest(
  token: string,
  id: string,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPut<string, undefined>(
    `/${SERVICE_NAME}/${id}`,
    undefined,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function DeleteRequest(
  token: string,
  id: string,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestDelete<string>(
    `/${SERVICE_NAME}/${id}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}
