const INITIAL: MerchantState = {
  refresh: false,
  employees: [],
  stores: [],
  storesOfEmployee: [],
  currentData: {
    Id: '',
    Name: '',
    Logo: '',
    ThumbnailUrl: '',
    PayerCode: '',
    AddressLine1: '',
    AddressLine2: '',
    City: '',
    PostCode: '',
    County: '',
    Phone: '',
    Email: '',
    AudienceName: '',
    Stores: [],
    MerchantManagers: [],
    Active: false,
    IsSetUp: false,
    ManagerStatus: ''
  },
  territoryManagers: [],
};

export const merchantReducer = (state: MerchantState = INITIAL, action: MerchantAction): MerchantState => {
  switch (action.type) {
    case 'MERCHANT_REFRESH':
      return { ...state, refresh: !state.refresh };
    case 'MERCHANT_SET_EMPLOYEES':
      return { ...state, employees: action.payload.employees };
    case 'MERCHANT_SET_STORES':
      return { ...state, stores: action.payload.stores };
    case 'MERCHANT_SET_STORES_OF_EMPLOYEE':
      return { ...state, storesOfEmployee: action.payload.storesOfEmployee };
    case 'MERCHANT_SET_CURRENT_DATA':
      return { ...state, currentData: action.payload.merchant };
    case 'MERCHANT_SET_TERRITORY_MANAGERS':
      return { ...state, territoryManagers: action.payload.territoryManagers };
    default:
      return state;
  }
};
