/* eslint-disable no-useless-escape */

export const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,16})/;

export const STARTS_WITH_NUMBER_PATTERN = /^\d+/;

export const STARTS_WITH_NUMBER_OR_LETTER_PATTERN = /^\w+/;

// TODO: Discuss what type of phone number inputs we will be accepting
export const PHONE_NUMBER_PATTERN = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

export const PHONE_NUMBER_PATTERN_V2 = /^(0) ?\d{4} ?\d{6}$/g;

export const PAYER_CODE_PATTERN = /^\d{4,8}$/;

export const CUSTOMER_CODE_PATTERN = /^\d{4,8}$/;

// Supplied by UK government, apparently
export const UK_POST_CODE_PATTERN = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/g;

export const POST_CODE_PATTERN = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$/i;
