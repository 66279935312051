/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useState } from 'react';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';

import { IStyles, LIGHT_THEME } from '../../constants/theme';
import { AdminDivider, DataDivider, UserDivider } from '../../utils/arrayDividers';

import { ListDivider } from '../Divider';
import { BodyOnePrimary, BodyTwoPrimary, BodyTwoSecondary, HeaderSix } from '../../components-atoms/TypographyComponents';
import { ButtonTextPrimary } from '../../components-atoms/ButtonComponents';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { IconButton, CircularProgress, SxProps } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { SearchbarWithDeboubce } from '../SearchBarWithDebounce';

interface MiniDialogProps {
  children: null | JSX.Element | string | (null | JSX.Element | string)[];
  header?: undefined | null | JSX.Element | string | (null | JSX.Element | string)[] | any;
  backEnabled?: boolean;
  title: string;
  open: boolean;
  disabled?: boolean;
  close: () => void;
  submit?: () => void;
  remove?: () => void;
  confirm?: boolean;
  noCancel?: boolean;
  noMaxHeight?: boolean;
  customWidth?: number | string;
  additionalButtons?: React.ReactNode | React.ReactChildren | any;
  customBottomButtons?: React.ReactNode | React.ReactChildren | any;
  titleMargin?: boolean;
  onBack?: () => void;
  onHelpClick?: () => void;
  childrenSx?: SxProps<any>;
  titleSx?: SxProps<any>;
  bodySx?: SxProps<any>;
}

export function MiniDialog({ backEnabled = false, children, title, open, disabled, close, submit, remove, confirm, header, noCancel, noMaxHeight, customWidth, additionalButtons, customBottomButtons, titleMargin, onBack, onHelpClick, childrenSx, titleSx, bodySx }: MiniDialogProps): JSX.Element {
  return (
    <Modal
      open={open}
      onClose={close}
      disableAutoFocus={true}
    >
      <Paper
        elevation={24}
        sx={{
          width: '100%', maxWidth: customWidth ? customWidth : '600px', maxHeight: noMaxHeight ? 'none' : '560px', borderRadius: '12px',
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
          background: 'background.paper',
          ...bodySx
        }}
      >
        <Grid
          container
          direction='column'
          alignContent='center'
          justifyContent='space-between'
          sx={{ height: '100%', width: '100%' }}
        >
          <Box sx={{display:'flex', alignItems: 'center', justifyContent:'space-between', width: '100%', height: '64px', padding: '16px 24px', margin: titleMargin ? '0 0 0 8px' : '0' }}>
            <Box display="flex" alignItems="center" sx={titleSx}>
              {backEnabled && <IconButton 
                aria-label="back" 
                data-testid="back" 
                onClick={onBack}
                sx={{ ml: -2.5, mt: 0.3, mr: 2 }}>
                <ArrowBackIcon sx={{color: LIGHT_THEME.palette.primary.main }} />
              </IconButton>
              }
              <HeaderSix sx={{ m:0 }}>{title}</HeaderSix>
            </Box>

            {onHelpClick
            &&  <IconButton aria-label="help" data-testid="help" onClick={onHelpClick}>
              <HelpOutlineIcon />
            </IconButton>
            }
          </Box>
          {header
            && <Box sx={{  overflow: 'auto' }}>{header()}</Box>
          }
          <Box sx={{ maxHeight: noMaxHeight ? 'none' : '432px', overflow: 'auto', ...childrenSx }}>
            {children}
          </Box>

          {!customBottomButtons 
            ?<Box sx={{ height: '32px', margin: '16px', alignSelf: 'end' }}>
              {additionalButtons  
                ? additionalButtons()
                : <></>
              }
              {!confirm 
                ? noCancel
                  ? <></>
                  : <ButtonTextPrimary onClick={close} data-testid="cancel-button" sx={{ marginRight: '8px' }}>Cancel</ButtonTextPrimary>
                : <></>
              }
              {submit
                ? <ButtonTextPrimary onClick={submit} disabled={disabled} data-testid='submit-button'>Submit</ButtonTextPrimary>
                : remove
                  ? <ButtonTextPrimary onClick={remove} data-testid='delete-button'>Delete</ButtonTextPrimary>
                  : confirm
                    ? <ButtonTextPrimary onClick={close} data-testid='confirmation-button'>OK</ButtonTextPrimary>
                    : noCancel
                      ? <ButtonTextPrimary onClick={close} data-testid='close-button'>Close</ButtonTextPrimary>
                      : <></>
              }
            </Box>
            :customBottomButtons()}
        </Grid>
      </Paper>
    </Modal>
  );
}

interface MiniCardProps {
  title: string;
  subtitle: string;
  subtitleOverflow?: boolean;
  secondSubtitle?: string;
  thirdSubtitle?: string;
  selected: boolean;
  multiple?: boolean;
  onClick: () => void;
  imgUrl?: string;
}

export function MiniCard({ title, subtitle, subtitleOverflow, secondSubtitle, thirdSubtitle, selected, multiple, onClick, imgUrl }: MiniCardProps): JSX.Element {
  return (
    <Grid
      container
      alignItems="center"
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        margin: '8px 8px 16px',
        padding: '8px 16px',
        borderRadius: '8px',
        width: 'unset',
        background: selected ? LIGHT_THEME.palette.action.selected : 'none',
        ':hover': { background: LIGHT_THEME.palette.action.hover }
      }}
    >
      {(multiple)
        ? <Grid item sx={{ width: '42px' }}>
          <Checkbox checked={selected} sx={{ padding: '0' }} />
        </Grid>
        : null
      }
      {imgUrl
      && (
        <Grid item sx={{ width: '40px' }}>
          <img src={imgUrl} style={{ display: 'flex', alignSelf: 'center', width: '28px', height: '28px', borderRadius: '4px' }} />
        </Grid>
      )}
      <Grid item>
        <BodyOnePrimary>{title}</BodyOnePrimary>
        {subtitleOverflow ? (
          <BodyTwoSecondary style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '520px'
          }}>
            {subtitle}
          </BodyTwoSecondary>
        ) : (
          <BodyTwoSecondary>{subtitle}</BodyTwoSecondary>
        )}
        {secondSubtitle&&<BodyTwoSecondary>{secondSubtitle}</BodyTwoSecondary>}
        {thirdSubtitle&&<BodyTwoSecondary>{thirdSubtitle}</BodyTwoSecondary>}
      </Grid>
    </Grid>
  );
}

interface DataListDialogProps {
  items: TerritoryManager[] | MerchantUser[] | Store[];
  title: string;
  caption: { singular: string; plural: string; };
  open: boolean;
  multiple?: boolean;
  close: () => void;
  submit: (list: Set<string>) => void;
  edit?: boolean;
  hasSearch?: boolean;
  searchTextOnChange?: (text:string) => void;
  searchPlaceHolder?: string;
  loading?: boolean;
}

export function ListDialog({ items, title, caption, open, multiple, close, submit, edit, hasSearch, searchTextOnChange, searchPlaceHolder, loading }: DataListDialogProps): JSX.Element {
  const [adminItems, setAdminItems] = useState<TerritoryManager[]>([]);
  const [userItems, setUserItems] = useState<MerchantUser[]>([]);
  const [dataItems, setDataItems] = useState<Store[]>([]);
  const [sectionedlist, setSectionedList] = useState<DividedList<typeof items[number]>>({});
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentTerritoryManager, currentMerchantManagers, currentEmployees, currentManagers, currentEmployeeStores] = useTypedSelector(state => [
    state.storeReducer.territoryManager,
    state.storeReducer.merchantManagerList,
    state.storeReducer.employeeList,
    state.storeReducer.managerList,
    state.merchantReducer.storesOfEmployee]);
  const inferType = (items: TerritoryManager[] | MerchantUser[] | Store[]) => {
    if ('Role' in items[0]) setUserItems(items as MerchantUser[]);
    else if ('AdminProperties' in items[0]) setAdminItems(items as TerritoryManager[]);
    else setDataItems(items as Store[]);
  };

  const addToList = useCallback((id: string): void => {
    const temp = new Set<string>();
    if (multiple) selectedItems.forEach(v => temp.add(v));

    if (selectedItems.has(id)) temp.delete(id);
    else temp.add(id);

    setSelectedItems(temp);
  }, [multiple, selectedItems]);

  useEffect(() => {
    if (items.length > 0){
      inferType(items);
    } else{
      setUserItems([]);
      setAdminItems([]);
      setDataItems([]);
    }
  }, [items]);

  useEffect(() => {
    if (adminItems.length > 0) {
      setSectionedList({ ...AdminDivider<Admin>(adminItems as Admin[]) });
      if (currentTerritoryManager && edit) {
        setSelectedItems(new Set([currentTerritoryManager.AdminProperties.Id]));
      }
    }
    else if (userItems.length > 0) {
      setSectionedList({ ...UserDivider<MerchantUser>(userItems as MerchantUser[]) });
      const currentUserItems = [...currentEmployees, ...currentManagers, ...currentMerchantManagers];
      
      if (currentUserItems && edit) {
        setSelectedItems(new Set(currentUserItems.map(userItem => userItem.UserProperties.Id)));
      }
    }
    else if (dataItems.length > 0) {
      setSectionedList({ ...DataDivider(dataItems) });
      if (currentEmployeeStores && edit) {
        setSelectedItems(new Set(currentEmployeeStores.map(dataItem => dataItem.Id)));
      }
    }else{
      setSectionedList({});
    }
  }, [adminItems, dataItems, dataItems.length, items, userItems, currentEmployees, currentManagers, currentTerritoryManager, currentMerchantManagers, currentEmployeeStores, edit]);
  
  useEffect(() => {
    if(!open){
      setShowSearchInput(false);
      setSearchQuery('');
      if(searchTextOnChange!==undefined){
        searchTextOnChange('');
      }
    }
  }, [open,searchTextOnChange]);
  
  const textOnChange=(text:string)=>{
    setSearchQuery(text);
    if(searchTextOnChange!==undefined){
      searchTextOnChange(text);
    }
  };
  
  return (
    <MiniDialog
      title={title}
      open={open}
      disabled={title.toLowerCase().includes('select') ? false : selectedItems.size === 0}
      close={close}
      submit={() => submit(selectedItems)}
    >
      <>
        {hasSearch
        && <div style={styles.dialogButtonContainer}>
          <div style={styles.dialogInnerButtonContainer}>
            <IconButton aria-label="search" sx={styles.sortFilterButton} onClick={()=>setShowSearchInput(!showSearchInput)}>
              <SearchIcon color={ 'primary' } />
            </IconButton>
          </div>
        </div>
        }
        <div style={styles.searchContainer}>
          <SearchbarWithDeboubce
            isOpen={showSearchInput}
            query={searchQuery}
            placeholder={searchPlaceHolder? searchPlaceHolder : ''}
            onCancel={() => { 
              setShowSearchInput(false); 
              setSearchQuery(''); 
              if(searchTextOnChange!==undefined){
                searchTextOnChange('');
              }
            }}
            onChange={textOnChange}
          />
        </div>

        {loading ?<Grid container item justifyContent="center" alignItems="center">
          <CircularProgress
            color="primary"
            size={50}
            style={{ zIndex: 999, margin: '56px 0' }}
          />
        </Grid>
          :(
            Object.keys(sectionedlist).length > 0
              ?Object.keys(sectionedlist).sort().map((section, sectionIndex) => {
                return (
                  <ListDivider
                    key={sectionIndex}
                    division={{ name: section, length: sectionedlist[section].length }}
                    caption={caption}
                    halfMargin
                  >
                    {adminItems.length > 0
                      ? sectionedlist[section].map((item, itemIndex) => {
                        const tmpItem = item as Admin;
                        return <MiniCard
                          key={itemIndex}
                          onClick={() => addToList(tmpItem.AdminProperties.Id)}
                          title={`${tmpItem.AdminProperties.FirstName} ${tmpItem.AdminProperties.LastName}`}
                          subtitle={tmpItem.AdminProperties.Email}
                          selected={selectedItems.has(tmpItem.AdminProperties.Id)}
                          multiple={multiple}
                        />;
                      })
                      : null
                    }
                    {dataItems.length > 0
                      ? sectionedlist[section].map((item, itemIndex) => {
                        const tmpItem = item as Store;
                        return <MiniCard
                          key={itemIndex}
                          onClick={() => addToList(tmpItem.Id)}
                          title={tmpItem.Name}
                          subtitle={tmpItem.CustomerCode}
                          selected={selectedItems.has(tmpItem.Id)}
                          multiple={multiple}
                        />;
                      })
                      : null
                    }
    
                    {userItems.length > 0
                      ? sectionedlist[section].map((item, itemIndex) => {
                        const tmpItem = item as MerchantUser;
                        return <MiniCard
                          key={itemIndex}
                          onClick={() => addToList(tmpItem.Id)}
                          title={tmpItem.FirstName && tmpItem.LastName ? `${tmpItem.FirstName} ${tmpItem.LastName}` : `${tmpItem.Email}`}
                          subtitle={tmpItem.Role}
                          selected={selectedItems.has(tmpItem.Id)}
                          multiple={multiple}
                        />;
                      })
                      : null
                    }
                  </ListDivider>
                );
              })
              : <BodyTwoPrimary sx={{ padding: '16px 24px 0' }}>0 results found.</BodyTwoPrimary>
          )
        }
        
      </>
    </MiniDialog>
  );
}
const styles:IStyles = {
  dialogButtonContainer:{
    flexDirection:'column',
  },
  dialogInnerButtonContainer:{
    display: 'flex',
    justifyContent:'flex-end',
    padding:' 8px 24px',
  },
  searchContainer:{
    padding: '0px 24px'
  }
};
