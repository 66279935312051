import { Link as RouterLink } from 'react-router-dom';

import Button, { ButtonProps } from '@mui/material/Button';

import { LIGHT_THEME } from '../constants/theme';
import { Tooltip } from '@mui/material';

const ROUNDED_BUTTON_DEFAULT_PROPS = {
  minHeight: '32px',
  borderRadius: 12,
  boxShadow: 'none',
};

const SMALL_BUTTON_FONT_PROPS = {
  fontSize: 13,
  lineHeight: '22px',
  letterSpacing: '0.46px',
};

// Contained Buttons
export function ButtonContainedPrimary(props: ButtonProps & { isSmall?: boolean; startIcon?: React.ReactNode }): JSX.Element {
  return <Button {...props} variant="contained" color="primary" sx={{
    ...ROUNDED_BUTTON_DEFAULT_PROPS,
    padding: props.isSmall ? props.startIcon ? '4px 16px 4px 14px' : '4px 16px' : '8px 24px',
    backgroundColor: '#465aa4',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#5466ab'
    },
    '.MuiSvgIcon-root': {
      fontSize: props.isSmall ? 18 : 20
    },
    ...(props.isSmall ? SMALL_BUTTON_FONT_PROPS : {}),
    ...props.sx
  }} />;
}

export function ButtonContainedSecondary(props: ButtonProps): JSX.Element {
  return <Button {...props} variant="contained" color="secondary" sx={{ padding: '8px 12px', ...ROUNDED_BUTTON_DEFAULT_PROPS, ...props.sx }} />;
}

export function ButtonContainedError(props: ButtonProps & { isSmall?: boolean; }): JSX.Element {
  return <Button {...props} variant="contained" color="error" sx={{
    padding: props.isSmall ? '4px 8px' : '8px 24px',
    '&:hover': {
      background: '#9d392d',
      boxShadow: 'none'
    },
    ...ROUNDED_BUTTON_DEFAULT_PROPS,
    ...(props.isSmall ? SMALL_BUTTON_FONT_PROPS : {}),
    ...props.sx
  }} />;
}

export function ButtonRouteContainedPrimary(props: ButtonProps & { to?: string; }): JSX.Element {
  return <Button component={RouterLink} variant="contained" color="primary" sx={{ ...ROUNDED_BUTTON_DEFAULT_PROPS, ...props.sx }} to={props.to ? props.to : '/404'}>{props.children}</Button>;
}

// Outlined Buttons
export function ButtonOutlinedPrimary(props: ButtonProps): JSX.Element {
  return <Button {...props} variant="outlined" color="primary" sx={{
    ...ROUNDED_BUTTON_DEFAULT_PROPS,
    '&:hover': {
      backgroundColor: 'rgba(1, 33, 105, 0.08) !important'
    },
    ...props.sx
  }} />;
}

// Text Buttons
export function ButtonTextPrimary(props: ButtonProps & { isSmall?: boolean; }): JSX.Element {
  return <Button {...props} variant="text" color="primary" sx={{
    padding: props.isSmall ? '4px 8px' : '8px 12px',
    ...ROUNDED_BUTTON_DEFAULT_PROPS,
    ...props.sx,
    '.MuiButton-startIcon': { fill: LIGHT_THEME.palette.primary.main }, fontSize: props.isSmall ? undefined : '0.9375rem', lineHeight: '1.73',
    ...(props.isSmall ? SMALL_BUTTON_FONT_PROPS : {}),
  }} />;
}

export function ButtonTextPrimaryTooltip(props: ButtonProps & { tooltipTitle: string }): JSX.Element {
  return <Tooltip disableFocusListener disableTouchListener arrow title={props.tooltipTitle}>
    <span>
      <Button {...props} variant="text" color="primary" sx={{ padding: '8px 12px', ...ROUNDED_BUTTON_DEFAULT_PROPS, ...props.sx, '.MuiButton-startIcon': { fill: LIGHT_THEME.palette.primary.main }, fontSize: '0.9375rem', lineHeight: '1.73' }} />
    </span>
  </Tooltip>;
}

export function ButtonTextSecondary(props: ButtonProps): JSX.Element {
  return <Button {...props} variant="text" color="secondary" sx={{ ...ROUNDED_BUTTON_DEFAULT_PROPS, ...props.sx, '.MuiButton-startIcon': { fill: LIGHT_THEME.palette.secondary.main }, fontSize: '0.9375rem', lineHeight: '1.73' }} />;
}

export function ButtonRouteTextPrimary(props: ButtonProps & { to?: string; }): JSX.Element {
  return <Button component={RouterLink} variant="text" color="primary" sx={{ ...ROUNDED_BUTTON_DEFAULT_PROPS, ...props.sx }} to={props.to ? props.to : '/404'}>{props.children}</Button>;
}

export function ButtonTextError(props: ButtonProps): JSX.Element {
  return <Button {...props} variant="text" color="error" sx={{ ...ROUNDED_BUTTON_DEFAULT_PROPS, ...props.sx, '.MuiButton-startIcon': { fill: LIGHT_THEME.palette.error.main } }} />;
}
