/* eslint-disable @typescript-eslint/naming-convention */
import { CUSTOMER_CODE_PATTERN, EMAIL_PATTERN, PASSWORD_PATTERN, PAYER_CODE_PATTERN, PHONE_NUMBER_PATTERN, PHONE_NUMBER_PATTERN_V2, POST_CODE_PATTERN, UK_POST_CODE_PATTERN } from '../constants/regexpPatterns';
import CheckFileExtensions from '../hooks/checkFileExtensions';
import { FormatBytes } from '../utils/formatBytes';


export const validateWhiteSpace = (value: string): boolean | string => !!value?.trim() || 'This field is required.';
export const REQUIRED_VALIDATION = {
  value: true,
  message: 'This field is required.',
};

export const EMAIL_VALIDATION = {
  value: EMAIL_PATTERN,
  message: 'Enter a valid e-mail address.'
};

export const PASSWORD_VALIDATION = {
  value: PASSWORD_PATTERN,
  message: 'Passwords must be minimum eight characters with at least one lowercase and one uppercase letter and one number.'
};

export const PHONE_VALIDATION = {
  value: PHONE_NUMBER_PATTERN,
  message: 'Enter a valid phone number.'
};

export const PHONE_VALIDATION_V2 = {
  value: PHONE_NUMBER_PATTERN_V2,
  message: 'Enter a valid phone number.'
};

export const PAYER_CODE_VALIDATION = {
  value: PAYER_CODE_PATTERN,
  message: 'Enter a valid payer/parent code.'
};

export const CUSTOMER_CODE_VALIDATION = {
  value: CUSTOMER_CODE_PATTERN,
  message: 'Enter a valid customer code.'
};

export const UK_POST_CODE_VALIDATION = {
  value: UK_POST_CODE_PATTERN, message: 'Enter a valid postcode.'
};
export const LOGO_VALIDATION = {
  sizeConstraint: (value: File): string | boolean => {
    return (FormatBytes(value.size, 'MB') < 2) || 'Logos must be smaller than 2MB.';
  }
};

export const POST_CODE_VALIDATION = {
  value: POST_CODE_PATTERN, message: 'Enter a valid postcode.'
};

export const LOGO_VALIDATION_WITH_TYPE = (types = 'image/*') : any => {
  return {
    sizeConstraint: (value: File): string | boolean => {
      return (FormatBytes(value.size, 'MB') < 2) || 'Logos must be smaller than 2MB.';
    },
    typeConstraint: (value: File): string | boolean => {
      const resp = CheckFileExtensions([value], types);
      if(resp.errorMessage!==null){
        return resp.errorMessage;
      }else {
        return true;
      }
    }
  };
};

export const FILE_VALIDATION = (size: number): { sizeConstraint: (value: File) => string | boolean; } => ({
  sizeConstraint: (value: File): string | boolean => {
    return (FormatBytes(value.size, 'MB') < size) || `This file must be smaller than ${size}MB.`;
  }
});


export const FILE_VALIDATION_WITH_TYPE = (size: number, types:string): any => ({
  sizeConstraint: (value: File): string | boolean => {
    return (FormatBytes(value.size, 'MB') < size) || `This file must be smaller than ${size}MB.`;
  },
  typeConstraint: (value: File): string | boolean => {
    const resp = CheckFileExtensions([value], types);
    if(resp.errorMessage!==null){
      return resp.errorMessage;
    }else {
      return true;
    }
  }
});

export const FILE_TYPE_VALIDATION_MULTIPLE = (types:string): { typeConstraint: (value: File) => string | boolean; } => ({
  typeConstraint: (value: File): string | boolean => {
    const fileArray: File[] = Array.prototype.slice.call(value);
    const resp = CheckFileExtensions(fileArray, types);
    if(resp.errorMessage!==null){
      return resp.errorMessage;
    }else {
      return true;
    }
  }
});
export const FILE_TYPE_VALIDATION = (types:string): { typeConstraint: (value: File) => string | boolean; } => ({
  typeConstraint: (value: File): string | boolean => {
    const resp = CheckFileExtensions([value], types);
    if(resp.errorMessage!==null){
      return resp.errorMessage;
    }else {
      return true;
    }
  }
});



