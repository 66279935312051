import { AxiosError } from 'axios';
import { HttpRequestGet, HttpRequestPut } from './genericService';

type GetGlobalEmailNotificationServicesResponseModel = GlobalEmailNotificationConfig[];

export function GetGlobalEmailNotificationServicesRequest(token: string, success: (response: GlobalEmailNotificationConfig[]) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<GetGlobalEmailNotificationServicesResponseModel>(
    '/service/published-content-email',
    (response) => success(response.data.Result),
    (error) => failure(error),
    token
  );
}

export function ToggleGlobalEmailNotificationServicesRequest(token: string, id: string | number | GUID, success: (response: ApiResponse<unknown>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPut<unknown, unknown>(
    `/service/published-content-email/${id}`,
    {},
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}
