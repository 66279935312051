const INITIAL: DrawerState = {
  // This is used to figure out if the drawer should be open or closed when the
  // application first launches. This is persistent, so the drawer's initial state 
  // with a given device will depend on the user's device viewport width
  didInfer: false,
  // This makes it easy for other components to figure out their state
  inferredDevice: 'desktop',
  // Fairly self-explanatory
  drawerOpen: true,
  services: [],
  adminServices:[],
  invitationCount:0,
};

export const drawerReducer = (state: DrawerState = INITIAL, action: DrawerAction): DrawerState => {
  switch (action.type) {
    case 'DRAWER_CLOSE':
      return { ...state, drawerOpen: false };
    case 'DRAWER_OPEN':
      return { ...state, drawerOpen: true };
    case 'DRAWER_SWITCH':
      return { ...state, drawerOpen: !state.drawerOpen };
    case 'DRAWER_INFER':
      if (action.payload.viewportWidth < 700) {
        return { ...state, inferredDevice: 'mobile', drawerOpen: false, didInfer: true };
      }
      else if (action.payload.viewportWidth < 1000) {
        return { ...state, inferredDevice: 'tablet', drawerOpen: false, didInfer: true };
      }
      return { ...state, inferredDevice: 'desktop', drawerOpen: true, didInfer: true };
    case 'DRAWER_SET_SERVICE_LIST':
      return { ...state, services: action.payload.services };
    case 'DRAWER_SET_ADMIN_SERVICE_LIST':
      return { ...state, adminServices: action.payload.adminServices };
    case 'DRAWER_SET_PENDING_INVITATION_COUNT':
      return { ...state, invitationCount: action.payload.invitationCount };
    default:
      return state;
  }
};
