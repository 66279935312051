import { useState } from 'react';
import { Box, Checkbox, Grid } from '@mui/material';
import { BodyOnePrimary, BodyOneSecondary } from '../../components-atoms/TypographyComponents';
import { MiniDialog } from '../../components-molecules/dialogs/MiniDialog';
import { SetTutorialOptionRequest } from '../../service/userService';
import { LIGHT_THEME } from '../../constants/theme';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';

interface TutorialDialogProps {
  showDialog: boolean;
  videoSrc: string;
  videoPoster: string;
}

export function TutorialDialog({showDialog, videoSrc, videoPoster}: TutorialDialogProps): JSX.Element {
  const token = useTypedSelector((state) => state.userReducer.token);
  const [showTutorial, setShowTutorial] = useState(showDialog);
  const [dontShowTutorial, setDontShowTutorial] = useState(false);

  const onClose = () => {
    if (dontShowTutorial && token) {
      SetTutorialOptionRequest(
        token,
        { DontShowTutorial: dontShowTutorial },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
    setShowTutorial(false);
  };
  
  return (
    <MiniDialog
      title="Welcome to Connect for Merchants"
      open={showTutorial}
      close={() => onClose()}
      noCancel
      noMaxHeight
    >
      <Box sx={{ padding: '0 24px' }}>
        <BodyOneSecondary sx={{ padding: { xs: '16px 0', sm: '0 0 16px' } }}>
            Please click on the video below to find out more about the platform.
        </BodyOneSecondary>
        <video controls playsInline src={videoSrc} poster={videoPoster} style={{ width: '100%' }} />
        <BodyOneSecondary sx={{ paddingTop: '16px' }}>
            Search ‘Connect’ within the Brand Assets section to view more tutorial videos like this.
        </BodyOneSecondary>
        <Grid
          container
          flexWrap="nowrap"
          alignItems="center"
          onClick={() => setDontShowTutorial(prev => !prev)}
          sx={{
            cursor: 'pointer',
            padding: '6px',
            marginTop: '16px',
            borderRadius: '8px',
            width: 'fit-content',
            background: dontShowTutorial ? LIGHT_THEME.palette.action.selected : 'none',
            ':hover': { background: LIGHT_THEME.palette.action.hover }
          }}
        >
          <Grid item sx={{ height: '26px', width: { xs: '32px', sm:'42px' } }}>
            <Checkbox checked={dontShowTutorial} sx={{ padding: '0', margin: '0' }} />
          </Grid>
          <Grid item>
            <BodyOnePrimary>
              Don’t show again
            </BodyOnePrimary>
          </Grid>
        </Grid>
      </Box>
    </MiniDialog>
  );
}
