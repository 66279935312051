import { HttpRequestPost } from './genericService';

interface ExternalPage {
  Url: string;
}
  
export function ExternalPageRequest(token: string, body: ExternalPage): void {
  HttpRequestPost<string, ExternalPage>(
    '/externalPage',
    body,
    () => undefined,
    () => undefined,
    token
  );
}
export function CampaignPageRequest(token: string, body: ExternalPage): void {
  HttpRequestPost<string, ExternalPage>(
    '/externalPage/campaign',
    body,
    () => undefined,
    () => undefined,
    token
  );
}
