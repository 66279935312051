import { Fragment, useEffect } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import Add from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MailIcon from '@mui/icons-material/Mail';

import { BodyTwoSecondary, HeaderFive, HeaderSix } from '../../components-atoms/TypographyComponents';
import { ButtonContainedPrimary, ButtonContainedSecondary, ButtonTextSecondary } from '../../components-atoms/ButtonComponents';
import { FilterMenu, IFilterOptions, ISelectedOptions } from '../FilterMenu';
import { SortButton } from '../SortButton';
import { SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LIGHT_THEME } from '../../constants/theme';
import { Download } from '@mui/icons-material';
import { GetAssetsFromListRequest } from '../../service/assetService';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { DynamicFilterMenu } from '../DynamicFilterMenu';
import { gaService } from '../../service/gaService';

interface PageHeaderProps {
  title?: string;
  subtitle?: string;
  buttonTitle?: string;
  buttonIconMail?: boolean;
  activeButtons?: boolean;
  modalCallback?: () => void;
  onClickSearch?: () => void;
  sortTypes?: {
    title: string;
    value: string;
  }[];
  selectedSortType?: string;
  handleSortSelect?: (val: string) => void;
  hasFilter?: boolean;
  hasDynamicFilter?: boolean;
  onFilterUpdate?: (selected: ISelectedOptions) => void;
  filterOptions?: IFilterOptions;
  onFilterSubmit?: (selected: ISelectedOptions, from?: string, to?: string) => void;
  pageHeaderContainerStyle?: SxProps<Theme>;
  noSort?: boolean;
  noPadding?: boolean;
  isAdmin?: boolean;
  canTouch?: boolean;
  isSelectable?: boolean;
  selectButtonOnClick?: () => void;
  selectedAssets?: DigitalAsset[];
  deselectAllSelectedAssets?: () => void;
  customFilterElement?: JSX.Element;
  filterReset?: boolean;
  noMargin?: boolean;
  setFilterReset?: React.Dispatch<React.SetStateAction<boolean>>;
  titleSectionRow?: boolean;
  defaultFilter?: ISelectedOptions;
  headerRight?: () => JSX.Element;
  smallerTitle?: boolean;
}

export function PageHeader({
  title,
  subtitle,
  buttonTitle,
  buttonIconMail,
  activeButtons,
  modalCallback,
  onClickSearch,
  sortTypes = [],
  handleSortSelect,
  selectedSortType = '',
  hasFilter,
  hasDynamicFilter,
  filterOptions,
  onFilterSubmit,
  pageHeaderContainerStyle,
  noSort,
  noPadding,
  isAdmin,
  canTouch,
  isSelectable,
  selectButtonOnClick,
  selectedAssets,
  deselectAllSelectedAssets,
  customFilterElement,
  filterReset,
  noMargin,
  setFilterReset,
  titleSectionRow,
  defaultFilter,
  onFilterUpdate,
  headerRight,
  smallerTitle
}: PageHeaderProps): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (title && title.length > 0) {
      gaService.pageView(title);
    }
  }, [title]);

  const downloadFiles = () => {
    const assetIds = selectedAssets?.map(asset => asset.DigitalAssetId);
    if (!assetIds || !token) { return; }

    GetAssetsFromListRequest(
      token,
      { 'DigitalAssetIds': assetIds },
      (response) => {
        const link = document.createElement('a');
        link.href = response.Result;
        const lastSegment = response.Result.split('/').pop() as string;
        link.download = lastSegment;
        link.click();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => { }
    );
  };

  const downloadButtons = () => {
    return (
      <>
        {selectedAssets && selectedAssets.length > 0
          ? (
            <>
              <ButtonTextSecondary
                data-testid="deselectallassets-button"
                onClick={deselectAllSelectedAssets}
                sx={{ minWidth: 118, margin: '0 0 0 8px', padding: '8px 11px !important' }}
              >
                Deselect All
              </ButtonTextSecondary>
              <ButtonContainedSecondary
                onClick={downloadFiles}
                sx={{ minWidth: 148, alignItems: 'center', justifyContent: 'flex-start', margin: '0 0 0 8px', padding: '8px 11px !important' }}
                startIcon={<Download />}
              >
                Download
                <div
                  style={{
                    margin: '0px 0px 0px 8px',
                    padding: '0 6.5px',
                    minWidth: '20px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    textAlign: 'center',
                    color: '#00B495'
                  }}
                >
                  <Typography
                    data-testid="selectedassetscounter"
                    sx={{
                      fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: 600,
                      fontSize: '0.75rem',
                      lineHeight: 1.25,
                      letterSpacing: '0.00875em'
                    }}
                  >
                    {selectedAssets?.length}
                  </Typography>
                </div>
              </ButtonContainedSecondary>
            </>
          ) : null
        }

        {canTouch
          ? (
            <Grid>
              <ButtonTextSecondary
                data-testid="selectassets-button"
                onClick={selectButtonOnClick}
                sx={{ margin: '0 0 0 8px', padding: '8px 11px !important' }}
              >
                {isSelectable ? 'Cancel' : 'Select'}
              </ButtonTextSecondary>
            </Grid>
          ) : null
        }
      </>
    );
  };

  return (
    <Grid container justifyContent="center" alignItems="center" sx={pageHeaderContainerStyle}>
      <Grid item flexGrow={1} sx={{ display: 'flex', alignItems: 'center', margin: noMargin ? '0' : '24px 0 0 0px', flexDirection: { xs: 'column', sm: 'row' } }}>
        {title || subtitle
          ? titleSectionRow
            ? (
              <Grid container direction='row' justifyContent={title ? 'flex-start' : (!title && subtitle) ? 'flex-start' : 'center'} alignItems="baseline" sx={{ paddingLeft: noPadding ? '0px' : '24px', }}>
                {smallerTitle ? <HeaderSix>{title}</HeaderSix> : <HeaderFive>{title}</HeaderFive>}
                <BodyTwoSecondary sx={{ marginLeft: !title ? '24px' : '12px' }}>{subtitle}</BodyTwoSecondary>
              </Grid>
            ) : (
              <Grid container direction='column' justifyContent={title ? 'flex-start' : (!title && subtitle) ? 'flex-start' : 'center'} alignItems="flex-start" sx={{ paddingLeft: noPadding ? '0px' : '24px', height: '56.5px' }}>
                {smallerTitle ? <HeaderSix>{title}</HeaderSix> : <HeaderFive>{title}</HeaderFive>}
                <BodyTwoSecondary sx={{ paddingTop: '4px' }}>{subtitle}</BodyTwoSecondary>
              </Grid>
            ) : null
        }
        <Grid item flexGrow={0} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }} >
          {activeButtons
            ? <Fragment>

              <IconButton aria-label="search" sx={{ margin: '0 8px 0 0', height: '48px', width: '48px', '&:hover': { backgroundColor: LIGHT_THEME.palette.action.selected } }} onClick={onClickSearch}>
                <SearchIcon color={(activeButtons) ? 'primary' : 'inherit'} />
              </IconButton>

              {!noSort
                && <SortButton
                  menuItems={
                    sortTypes.map((sortItem: { title: string; value: string; }) => ({
                      title: sortItem.title,
                      value: sortItem.value,
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      handleSelect: () => { if (handleSortSelect) handleSortSelect(sortItem.value); },
                    }))}
                  selectedSortType={selectedSortType}
                  activeButtons={activeButtons}
                />}

              {hasFilter && (
                hasDynamicFilter
                  ? <DynamicFilterMenu onUpdate={onFilterUpdate} setReset={setFilterReset} reset={filterReset} customFilterElement={customFilterElement} sizeKey="custom" activeButtons={activeButtons} filterOptions={filterOptions || {}} onSubmit={(selected, from, to) => onFilterSubmit && onFilterSubmit(selected, from, to)} isAdmin={isAdmin} defaultFilter={defaultFilter} />
                  : <FilterMenu setReset={setFilterReset} reset={filterReset} customFilterElement={customFilterElement} sizeKey="custom" activeButtons={activeButtons} filterOptions={filterOptions || {}} onSubmit={(selected, from, to) => onFilterSubmit && onFilterSubmit(selected, from, to)} isAdmin={isAdmin} defaultFilter={defaultFilter} />)}

            </Fragment>
            : null
          }

          {buttonTitle && modalCallback
            ? <ButtonContainedPrimary
              data-testid={buttonTitle}
              startIcon={buttonIconMail ? <MailIcon /> : <Add />}
              onClick={() => modalCallback()}
              sx={{ margin: '0 0 0 8px', width: 'max-content' }}
            >
              {buttonTitle}
            </ButtonContainedPrimary>
            : null
          }
        </Grid>
        <Grid
          display="flex"
          alignItems="center"
          alignSelf={isMdDown ? 'flex-end' : 'center'}
          justifyContent="flex-end">
          {downloadButtons()}
        </Grid>
        <Grid
          display="flex"
          alignItems="center"
          alignSelf={isMdDown ? 'flex-end' : 'center'}
          justifyContent="flex-end">
          {headerRight?.()}
        </Grid>
      </Grid>
    </Grid >
  );
}
