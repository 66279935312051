import { Box } from '@mui/material';
import { MiniDialog } from './MiniDialog';
import { Download } from '@mui/icons-material';
import {ButtonContainedSecondary} from '../../components-atoms/ButtonComponents';

interface IProps {
  open: boolean;
  onClose: () => void;
  url: string;
  errorUrl: string;
  downloadClicked: () => void;
}

export default function ImagePreviewDialog({ onClose, open, url, errorUrl, downloadClicked }: IProps): JSX.Element {

  const downloadButton = () => {
    return (
      <ButtonContainedSecondary
        onClick={()=> downloadClicked()}
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: '8px 11px !important',
          marginRight: '8px'
        }}
        startIcon={<Download />}
      >
        Download
      </ButtonContainedSecondary>
    );
  };

  return (
    <MiniDialog
      title="Image Preview"
      open={open}
      close={onClose}
      noMaxHeight
      confirm
      additionalButtons={downloadButton}
      customWidth={1360}
    >
      <Box sx={{ padding: '0 24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img
          data-testid="asset-preview"
          alt="preview"
          style={{ maxHeight: '70vh', maxWidth: '100%' }}
          src={url}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = errorUrl;
          }}
        />
      </Box>
    </MiniDialog>
  );
}
