import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

interface InfoComponentProps {
  title: string;
  description: string;
}

export function InfoComponent({ title, description }: InfoComponentProps): JSX.Element {
  return (
    <Alert severity="info" sx={{ borderRadius: '4px', width: '100%' }}>
      <AlertTitle>{title}</AlertTitle>
      {description}
    </Alert>
  );
}
