import { createStore, applyMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistReducer } from 'redux-persist';
import LocalStorage from 'redux-persist/lib/storage';

import { IS_DEV } from '../constants/isDev';

import * as Drawer from './reducers/drawer';
import * as User from './reducers/user';
import * as Merchant from './reducers/merchant';
import * as Store from './reducers/store';
import * as AccessRights from './reducers/accessRights';
import * as DigitalAsset from './reducers/digitalAssets';
import * as IndustryInsight from './reducers/industryInsights';
import * as IndustryEvent from './reducers/industryEvent';
import * as Search from './reducers/search';
import * as NPD from './reducers/npd';
import * as Loading from './reducers/loading';
import * as TerritoryManagers from './reducers/territoryManagers';
import * as MerchantRegistration from './reducers/merchantRegistration';
import * as Page from './reducers/page';
import * as DigitalToolkit from './reducers/digitaltoolkit';

const persistedReducer = persistReducer(
  { key: 'root', storage: LocalStorage, blacklist: ['storeReducer'] },
  combineReducers({
    ...Drawer,
    ...User,
    ...Merchant,
    ...Store,
    ...AccessRights,
    ...DigitalAsset,
    ...IndustryInsight,
    ...IndustryEvent,
    ...Search,
    ...NPD,
    ...Loading,
    ...TerritoryManagers,
    ...MerchantRegistration,
    ...Page,
    ...DigitalToolkit
  })
);

const appliedMiddleware = IS_DEV ? applyMiddleware(thunk, logger) : applyMiddleware(thunk);

export const STORE = createStore(persistedReducer, appliedMiddleware);
export const PERSISTING_STORE = persistStore(STORE);
