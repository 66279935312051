import { styled } from '@mui/system';
import List, { ListProps } from '@mui/material/List';
import ListSubheader, { ListSubheaderProps } from '@mui/material/ListSubheader';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';

import { LIGHT_THEME } from '../constants/theme';
import { OverlineSecondary } from './TypographyComponents';

export const DrawerListComponent = styled(List)<ListProps>(() => ({
  padding: '0'
}));

export const DrawerListSubheaderComponent = styled(ListSubheader)<ListSubheaderProps>(() => ({
  padding: '0 20px 0',
  marginTop: '8px'
}));

export const DrawerListOverlineSubheaderComponent = styled(OverlineSecondary)<ListSubheaderProps>(() => ({
  padding: '0 20px 0',
}));

export const DrawerListItemComponent = styled(ListItem)<ListItemProps>(() => ({
  borderRadius: 4,
  padding: 4
}));

export const DrawerListItemButtonComponent = styled(ListItemButton)<ListItemButtonProps>(({ theme }) => ({
  borderRadius: 8,
  fontSize: '14px',
  fontWeight: LIGHT_THEME.typography.fontWeightMedium,
  height: '40px',
  '&.Mui-selected': {
    borderRadius: 8,
    background: LIGHT_THEME.palette.action.selected,
    '& > .MuiListItemIcon-root > svg': {
      fill: theme.palette.primary.main,
    },
    '& > .MuiListItemText-root > .MuiTypography-root': {
      color: theme.palette.primary.main,
    }
  },
  ':hover': {
    borderRadius: 8
  }
}));
