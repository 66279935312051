import { FormHelperText, Grid } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FormControlInput, FormControlSelect } from '../../components-molecules/FormControl';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { EMAIL_VALIDATION, PHONE_VALIDATION_V2, REQUIRED_VALIDATION, validateWhiteSpace } from '../../forms/_predefinedValidations';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { CreateAdminUser, ListAdminRole, ListAllRoles } from '../../service/serviceService';

interface AddAdminUser {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  roleId: GUID;
}

export function AddAdminUser(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, formState: { isValid, errors, isSubmitted }, setError } = useForm<any>({ mode: 'all', reValidateMode: 'onChange' });
  const token = useTypedSelector(state => state.userReducer.token);
  const navigate = useNavigate();

  const submit: SubmitHandler<AddAdminUser> = (data): void => {
    if (token) {
      CreateAdminUser(
        token,
        {
          Email: data.email,
          FirstName: data.firstName,
          LastName: data.lastName,
          Phone: data.phone,
          RoleId: data.roleId as GUID
        },
        () => {
          setLoading(false);
          navigate('/admin/adminusers');
        },
        (error) => {
          setError('email', { message: error.response?.data as string || '', type: 'value' });
          setLoading(false);
        }
      );
    }
  };

  const [roleList, setRoleList] = useState<ListAdminRole[]>([]);

  useEffect(() => {
    if (token) {
      setLoading(true);
      ListAllRoles(
        token,
        (response) => {
          setRoleList(response.Result);
          setLoading(false);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  }, [token]);

  return (
    <FormPageComponent
      buttonLoading={loading}
      title="Add Admin Portal User"
      buttonTitle="Add User"
      hasError={isSubmitted && ('email' in errors)}
      buttonDisabled={loading || !isValid}
      close={() => navigate('/admin/adminusers')}
      submit={handleSubmit(submit)}
    >
      <Fragment>
        <ModalCardComponent>
          <SectionHeader title="Admin portal user details" />
          <Fragment>
            <Grid item container sx={{ flexGrow: 1 }}>
              <Grid item xs={12}>
                <FormControlInput
                  data-testid="add-admin-user-firstname"
                  control={control}
                  field={{
                    label: 'First name *',
                    name: 'firstName',
                    inputType: 'text',
                    validation: {
                      required: REQUIRED_VALIDATION,
                      validate: { validateWhiteSpace }
                    }
                  }} />
              </Grid>
              <Grid item xs={12}>
                <FormControlInput
                  data-testid="add-admin-user-lastname"
                  control={control}
                  field={{
                    label: 'Last Name *',
                    name: 'lastName',
                    inputType: 'text',
                    validation: {
                      required: REQUIRED_VALIDATION,
                      validate: { validateWhiteSpace }
                    }
                  }} />
              </Grid>
              <Grid item xs={12}>
                <FormControlInput
                  data-testid="add-admin-user-email"
                  control={control}
                  field={{
                    label: 'Email *',
                    name: 'email',
                    inputType: 'text',
                    validation: {
                      required: REQUIRED_VALIDATION,
                      pattern: EMAIL_VALIDATION,
                      validate: { validateWhiteSpace }
                    }
                  }} />
              </Grid>
              <Grid item xs={12}>
                <FormControlInput
                  data-testid="add-admin-user-phone"
                  control={control}
                  field={{
                    label: 'Phone number',
                    name: 'phone',
                    inputType: 'text',
                    validation: {
                      pattern: PHONE_VALIDATION_V2
                    }
                  }} />
                <FormHelperText sx={{ padding: '0 14px' }}>
                  Example: 07975777666
                </FormHelperText>
              </Grid>
            </Grid>
          </Fragment>
        </ModalCardComponent>
        <ModalCardComponent>
          <SectionHeader
            title="Admin portal user role"
            subtitle="Select a user role you want to assign to this admin portal user."
          />
          <Fragment>
            <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
              <Grid item xs={12}>
                <FormControlSelect
                  data-testid="add-admin-user-role"
                  control={control}
                  field={{
                    label: 'User role *',
                    name: 'roleId',
                    inputType: 'select',
                    options: roleList.map(m => ({ name: m.Name, value: m.Id })),
                    validation: {
                      required: REQUIRED_VALIDATION
                    }
                  }} />
              </Grid>
            </Grid>
          </Fragment>
        </ModalCardComponent>
      </Fragment>
    </FormPageComponent>
  );
}
