/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/naming-convention */
import { Box, CircularProgress, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BodyTwoSecondary, CaptionPrimary, CaptionSecondary, HeaderFourSecondary } from '../components-atoms/TypographyComponents';
import { Dashboard } from '../components-organisms/Dashboard';
import { Footer } from '../components-organisms/Footer';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { CreateRecentBenefitRequest, GetServiceRequest } from '../service/serviceService';
import { WebshopService } from '../components-organisms/WebshopService';
import { DigitalAssetService } from './DigitalAssetService';
import { KnowledgeBaseService } from '../components-organisms/KnowledgeBaseService';
import { IndustryInsightsService } from '../components-organisms/IndustryInsightsService';
import { NPDService } from '../components-organisms/NPDService';
import { DuluxAcademyService } from '../components-organisms/DuluxAcademyService';
import { RebateSchemeService } from '../components-organisms/RebateSchemeService';
import { VideoTrainingService } from '../components-organisms/VideoTrainingService';
import { SupportTooltip } from '../components-molecules/SupportTooltip';
import { FocusOfTheMonthService } from '../components-organisms/FocusOfTheMonthService';
import { NewsletterRepositoryService } from '../components-organisms/NewsletterRepositoryService';
import { DocumentRepository } from '../components-organisms/DocumentRepository';
import { HelpVideosService } from '../components-organisms/HelpVideosService';
import '../scss/components/_service-page.scss';
import { gaService } from '../service/gaService';
import { DigitalToolkitsService } from '../components-organisms/DigitalToolkitsService';
import { DigitalToolkitsSource } from '../utils/enums';
import { COMPACT_BAR_ENABLED, DISABLE_DEFAULT_HEADER } from '../constants/genaralValues';
import { ServicePageHeader } from '../components-organisms/appbars/ServicePageHeader';
import { IndustryEventsService } from '../components-organisms/IndustryEventsService';

const SERVICES: { [key: string]: (id?: string, service?: ServiceProperties) => JSX.Element; } = {
  'Webshop': () => <WebshopService />,
  'Brand Assets': (_, service) => <DigitalAssetService service={service} />,
  'Knowledge Base': (id, service) => < KnowledgeBaseService service={service} categoryId={service && service.CategoryId || ''} serviceId={id || ''} />,
  'Industry Insights': (id, service) => <IndustryInsightsService service={service} id={id || ''} />,
  'Industry Events': () => <IndustryEventsService />,
  'Product Updates': (id) => <NPDService id={id || ''} />,
  'Dulux Academy': () => <DuluxAcademyService />,
  'Monthly Rebate Update': () => <RebateSchemeService />,
  'Dulux Trade YouTube Channel': () => <VideoTrainingService />,
  'Focus of the Month': () => <FocusOfTheMonthService />,
  'Newsletter Library': (_, service) => <NewsletterRepositoryService service={service} />,
  'Document Library': () => <DocumentRepository />,
  'Help': () => <HelpVideosService />,
  'Digital Toolkits': (_, service) => <DigitalToolkitsService source={DigitalToolkitsSource.Client} service={service} />
};

export function ServicePage(): JSX.Element {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [token, title] = useTypedSelector((state) => [state.userReducer.token, state.pageReducer.title]);
  const [service, setService] = useState<ServiceProperties>();
  const [loading, setLoading] = useState(false);
  const showCompactBar = COMPACT_BAR_ENABLED.includes(title);

  useEffect(() => {
    if (service?.Name) {
      gaService.pageView(service.Name);
    }
  }, [service]);

  useEffect(() => {
    if (token) {
      setLoading(true);
      GetServiceRequest(
        token,
        id as GUID,
        (response) => {
          setService(response.Result.Service);
          dispatch({ type: 'SET_PAGE_TITLE', payload: { title: response.Result.Service.Name } });
          setLoading(false);
        },
        () => {
          navigate('/unauthorized');
        }
      );
      CreateRecentBenefitRequest(
        token,
        { ServiceId: id as GUID },
        () => { },
        () => { }
      );

      return () => {
        dispatch({ type: 'SET_PAGE_TITLE', payload: { title: '' } });
      };
    }
  }, [id, token, navigate, dispatch]);

  if (loading) {
    return (
      <div className="progress-spinner-wrapper">
        <CircularProgress color="primary" />
      </div>
    );
  }

  if (service && showCompactBar) {
    return (
      <Dashboard noDrawer noMargin noPadding fullWidth merchantPage justifyContent='flex-start'>
        {!DISABLE_DEFAULT_HEADER.includes(service.Name)
          ? <ServicePageHeader
            hideSearch
            service={service}
          />
          : <></>
        }
        <Box width='100%' minHeight='80vh'>
          {SERVICES[service.Name as string](id, service)}
        </Box>

        <Grid item sx={{ width: '100%' }}>
          <Footer />
        </Grid>
      </Dashboard>
    );
  }

  if (service) {
    return (
      <Dashboard noDrawer noMargin noPadding fullWidth merchantPage>
        <Grid sx={{ width: '100%' }}>
          <Grid container direction="row" justifyContent="center" sx={{ background: 'linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)' }}>
            <Grid direction="column" container item alignItems="flex-start" justifyContent="flex-start" sx={{ maxWidth: '1408px', width: '100%', padding: '0 24px' }}>
              <Grid sx={{ margin: '12px 0px 28px' }}>
                <CaptionPrimary style={{ color: 'rgba(1, 33, 105, 1)', cursor: 'pointer' }} onClick={() => navigate('/')}>Home</CaptionPrimary>
                <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
                <CaptionPrimary style={{ color: 'rgba(1, 33, 105, 1)', cursor: 'pointer' }} onClick={() => navigate(`/category/${service.CategoryId}`)}>{service.CategoryName}</CaptionPrimary>
                <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
                <CaptionPrimary>{service.Name}</CaptionPrimary>
              </Grid>
              <Grid container item alignItems="flex-end" justifyContent="space-between" sx={{ paddingTop: '8px', paddingBottom: '8px' }}>
                <Grid item md={2} className="banner-image-wrapper">
                  <img src={require(`../assets/img/category/${service.ImageUrl}@2x.png`)} style={{ width: '160px', height: '160px' }} />
                </Grid>
                <Grid item sx={{ padding: { xs: '0px 0px 12px 0px', sm: '0px 0px 12px 20px' }, width: '100%', flex: 1 }}>
                  <HeaderFourSecondary>{service.Name}</HeaderFourSecondary>
                  <Grid container justifyContent="space-between" alignItems="flex-end">
                    <BodyTwoSecondary style={{ maxWidth: 783, paddingTop: '4px' }}>
                      {service.Description}
                    </BodyTwoSecondary>
                  </Grid>
                </Grid>
                <Grid xs={12} sm={1} item display="flex" alignSelf="flex-end" justifyContent="flex-end">
                  <SupportTooltip />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item direction="row" justifyContent="center">
            {SERVICES[service.Name as string](id, service)}
          </Grid>
        </Grid>
        <Grid container sx={{ background: 'linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Grid item sx={{ maxWidth: '1408px', width: '100%' }}>
            <Footer />
          </Grid>
        </Grid>
      </Dashboard>
    );
  }

  return <></>;
}
