/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
import { Fragment, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NoItems } from '../components-molecules/NoItems';
import { ISelectedOptions } from '../components-molecules/FilterMenu';
import { BodyOnePrimary, CaptionSecondary } from '../components-atoms/TypographyComponents';
import { Container, Grid, CircularProgress, useMediaQuery, Stack, Typography } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { GetNewsletterRepository } from '../service/serviceService';
import { IStyles, LIGHT_THEME } from '../constants/theme';
import { ServicePageHeader } from './appbars/ServicePageHeader';
import NewsletterShortCard from './cards/listing/NewsletterServiceShortCard';
import { ListDivider } from '../components-molecules/Divider';

export type NewsletterGroup = {
  Group: string;
  Newsletters: Newsletters[];
}

export interface Newsletters {
  Id: string;
  Subject: string;
  Body: string
  SentDate: string;
  Attachments: NewsletterAttachments[];
}

export interface NewsletterAttachments {
  FileName: string;
  FileUrl: string;
}

export interface INewsletterItem {
  newsletter: Newsletters;
}

const filterOptions = {
  'Attachments': [
    { name: 'Yes', value: 'true' },
    { name: 'No', value: 'false' }
  ]
};

export function NewsletterRepositoryService({ service }: any): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [data, setData] = useState<NewsletterGroup[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filter, setFilter] = useState<ISelectedOptions>({ 'Date Range': [], 'Attachments': [] });
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  const mdDown = useMediaQuery(LIGHT_THEME.breakpoints.down('md'));

  useEffect(() => {
    getNewsletters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, filter, page]);

  const getNewsletters = () => {
    if (token) {
      GetNewsletterRepository(token, {
        Page: page,
        Query: query,
        StartDate: filter['Date Range']?.[0],
        EndDate: filter['Date Range']?.[1],
        HasAttachment: filter.Attachments?.length === 1 ? filter.Attachments[0] === 'true' ? true : false : undefined
      },
      (response) => {
        let temp = [...data];
        response.Result.Result.map(m => {
          const tempMatch = temp.find((f) => f.Group === m.Group);
          if (tempMatch) {
            temp[temp.indexOf(tempMatch)].Newsletters = [...temp[temp.indexOf(tempMatch)].Newsletters, ...m.Newsletters];
          } else {
            temp = [...temp, m];
          }
        });
        setTotalCount(response.Result.Count);
        setData(temp);
        setTimeout(() => {
          setLoading(false);
        }, 100);
      },
      () => setLoading(false)
      );
    }
  };

  const onSearch = (text: string) => {
    setPage(0);
    setLoading(true);
    setData([]);
    setQuery('');
    if (text.length > 0) {
      setTimeout(() => {
        setQuery(text);
      }, 100);
    }
  };

  const onFilterSubmit = (e: any) => {
    setPage(0);
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
    setLoading(true);
    setData([]);
  };

  const GetList = () => {
    if (data.length > 0) {
      return (
        <>
          {data.map((group: NewsletterGroup) => {
            return (
              <ListDivider
                key={group.Group}
                division={{ name: group.Group, length: group.Newsletters.length }}
                caption={{ singular: 'Newsletter', plural: 'Newsletters' }}
                halfMargin
                noBottomPadding
              >
                {
                  group.Newsletters.length > 0
                    ? group.Newsletters.map((newsletter: Newsletters) =>
                      <NewsletterShortCard key={newsletter.Id} newsletter={newsletter} />
                    ) : <></>
                }
              </ListDivider>
            );
          })}
        </>
      );
    }
    return <></>;
  };

  const getItemCount = () => {
    let totalItemCount = 0;
    data.forEach(group => {
      totalItemCount += group.Newsletters.length;
    });
    return totalItemCount;
  };

  function renderTitle() {
    return (
      <Stack
        direction='row'
        justifyContent="space-between"
        mt={2}
        ml={smallDown ? 0 : 2}
        mr={4}>
        <Stack
          gap={2}
          direction={mdDown ? 'column' : 'row'}
          alignItems={mdDown ? 'flex-start' : 'center'}>
          <Typography fontSize="20px" fontWeight="600">
            {service.Name}
          </Typography>
          <CaptionSecondary>
            Showing 1 - {getItemCount()} of {totalCount}
          </CaptionSecondary>
        </Stack>
      </Stack>
    );
  }

  return (
    <Fragment>
      <ServicePageHeader
        service={service}
        onSearch={onSearch}
        filterOptions={filterOptions}
        selectedFilters={filter}
        onChange={onFilterSubmit}
      />
      <Container style={{ maxWidth: '720px', width: '100vw' }}>
        {loading
          ? (
            <Grid container justifyContent="center">
              <CircularProgress
                color='primary'
                size={50}
                style={styles.circularProgress}
              />
            </Grid>
          ) : getItemCount() > 0
            ? (

              <InfiniteScroll
                style={{ overflowY: 'hidden' }}
                dataLength={getItemCount()} //This is important field to render the next data
                next={() => { setPage(prev => prev + 1); }}
                scrollThreshold={smallDown ? '550px' : '50px'}
                hasMore={getItemCount() < totalCount}
                loader={!loading && (
                  <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
                    <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
                  </Container>
                )}
              >
                {renderTitle()}
                <GetList />
              </InfiniteScroll>
            ) : query || !Object.values(filter).every(option => Array.isArray(option) && option.length === 0)
              ? <BodyOnePrimary sx={{ marginTop: '16px' }}>0 results found.</BodyOnePrimary>
              : <NoItems
                icon={<MailIcon style={styles.noItemsIcon} />}
                title="No newsletters yet"
                description="You haven’t received any email newsletters yet."
              />
        }
      </Container>
    </Fragment>
  );
}

const styles: IStyles = {
  divider: { width: '100%', marginTop: '32px' },
  noItemsIcon: { fontSize: '88px', fill: LIGHT_THEME.palette.primary.main },
  circularProgress: { margin: '48px 0' }
};
