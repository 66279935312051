const INITIAL: UserState = {
  loading: false,
  error: undefined,
  token: undefined,
  data: undefined,
  super: undefined,
  services: []
};

export const userReducer = (state: UserState = INITIAL, action: UserAction): UserState => {
  switch (action.type) {
    case 'USER_ANY_FAILURE':
      return { loading: false, error: action.payload.error, token: undefined, data: undefined, super: undefined, services:[] };

    case 'USER_ANY_FAILURE_NO_SIGNOUT':
      return { ...state, loading: false, error: action.payload.error, };

    case 'USER_LOADING':
      return { ...state, loading: true };

    case 'USER_SET_TOKEN':
      return { ...state, token: action.payload.token };
      
    case 'USER_SIGNIN_SUCCESS':
    {
      const data = {...state.data, ...action.payload.data};
      return { ...state, loading: false, error: undefined, token: action.payload.token, data: data, super: undefined };
    }

    case 'USER_SET_MARKETING':
    {
      const data = {...state.data, Marketing: action.payload.marketing} as UserProperties;
      return { ...state, data };
    }

    case 'USER_SIGNOUT':
    case 'USER_FORGOT_PASS':
    case 'USER_RESET_PASS':
    {
      if (state.super) {
        localStorage.removeItem('merchantToken');
      }
      return { loading: false, error: undefined, token: undefined, data: undefined, super: undefined, services: [] };
    }

    case 'USER_AUTH':
      return { ...state };

    case 'USER_ADD_SUPERUSE':
      return { ...state, super: { merchant: action.payload.merchant, token: action.payload.token } };

    case 'USER_CLEAR_SUPERUSE': {
      localStorage.removeItem('merchantToken');
      return { ...state, super: undefined };
    }
    case 'USER_SET_SERVICES':{
      return { ...state, services: action.payload.services };
    }
    case 'USER_NONE':
    default:
      return state;
  }
};
