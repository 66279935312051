import { Fragment, useEffect, useState } from 'react';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';

import { BodyOnePrimary, BodyTwoSecondary } from '../components-atoms/TypographyComponents';


function RemoveButton({ rowId, onDelete }: { rowId: GUID | string | number; onDelete: (id: GUID | string | number) => void; }): JSX.Element {
  return (
    <Grid item>
      <RemoveCircleIcon color="primary" fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => onDelete(rowId)} />
    </Grid>
  );
}

function ToggleSwitch({ rowId, checked, onToggle }: { rowId: GUID | string | number; checked: boolean; onToggle: (id: GUID | string | number, checked: boolean) => void; }): JSX.Element {
  return (
    <Grid item>
      <Switch color="primary" sx={{ cursor: 'pointer' }} checked={checked} onChange={(e, checked) => onToggle(rowId, checked)} />
    </Grid>
  );
}

type CombinationRowData = AccessRight | GlobalEmailNotificationConfig | { [key: string]: string; };

type CombinationRowProps = {
  columnTitles: { [key: string]: string; };
  dataset: CombinationRowData[];
} & (
  { onDelete: (id: GUID | string | number) => void; }
  | { onToggle: (id: GUID | string | number, checked: boolean) => void; }
);

export function CombinationRow(props: CombinationRowProps): JSX.Element {
  const { columnTitles, dataset } = props;
  return (
    <Fragment>
      {dataset.map((row, rowIndex) =>
        <Fragment key={rowIndex}>
          <Grid container alignItems="center" sx={{ margin: '16px 0' }}>

            {Object.values(row).map((column: string | boolean, columnIndex) => {
              if (columnIndex !== 0 && typeof column !== 'boolean') {
                return (
                  <Grid key={columnIndex} item sx={{ width: '180px', flexGrow: 1, flexShrink: 1 }}>
                    <BodyTwoSecondary style={{ marginBottom: '4px' }}>{columnTitles[Object.keys(row)[columnIndex]]}</BodyTwoSecondary>
                    <BodyOnePrimary>{column}</BodyOnePrimary>
                  </Grid>
                );
              }
              return null;
            })}

            {'onDelete' in props
              ? <RemoveButton rowId={row.Id} onDelete={props.onDelete} />
              : null}

            {'onToggle' in props && 'IsEnabled' in row && typeof row.IsEnabled === 'boolean'
              ? <ToggleSwitch rowId={row.Id} checked={row.IsEnabled} onToggle={props.onToggle} />
              : null}
          </Grid>

          {rowIndex !== dataset.length - 1 ? <Box><Divider /></Box> : null}
        </Fragment>
      )}
    </Fragment>
  );
}

interface EditorsPickRowProps {
  dataset: EditorsPick[];
  onDelete: (id: GUID) => void;
  onMove: (data: EditorsPick[]) => void;
}

export function EditorsPickRow({ dataset, onDelete, onMove }: EditorsPickRowProps): JSX.Element {
  const [renderData, setRenderData] = useState<EditorsPick[]>(dataset);
  useEffect(() => {
    setRenderData(dataset);
  }, [dataset]);
  const moveUp = (idx: number) => {
    const temp = [...renderData];
    const tempItem = temp[idx];
    temp[temp.indexOf(tempItem)] = temp[idx - 1];
    temp[idx - 1] = tempItem;
    setRenderData([...temp]);
    onMove(temp);
  };
  const moveDown = (idx: number) => {
    const temp = [...renderData];
    const tempItem = temp[idx];
    temp[temp.indexOf(tempItem)] = temp[idx + 1];
    temp[idx + 1] = tempItem;
    setRenderData([...temp]);
    onMove(temp);
  };
  return (
    <Fragment>
      {renderData.map((row, rowIndex) =>
        <Fragment key={rowIndex}>
          <Grid container alignItems="center" sx={{ margin: '16px 0' }}>

            <Grid xs={5} item sx={{ width: '180px', flexGrow: 1, flexShrink: 1 }}>
              <BodyTwoSecondary style={{ marginBottom: '4px' }}>{`Service ${rowIndex + 1}`}</BodyTwoSecondary>
              <BodyOnePrimary>{row.ServiceName}</BodyOnePrimary>
              <BodyTwoSecondary>{row.CategoryName}</BodyTwoSecondary>
            </Grid>
            <Grid xs={5} item sx={{ width: '180px', flexGrow: 1, flexShrink: 1 }}>
              <BodyTwoSecondary style={{ marginBottom: '4px' }}>Access Rights</BodyTwoSecondary>
              <BodyOnePrimary>{row.AccessRights?.map(m => `${m.AudienceName} - ${m.UserRoleName}`).join(',')}</BodyOnePrimary>
              <BodyTwoSecondary></BodyTwoSecondary>
            </Grid>
            <Grid xs={1} item container direction="column" >
              <IconButton
                data-testid="editors-pick-move-up-button"
                onClick={() => moveUp(rowIndex)}
                sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                disabled={rowIndex === 0}>
                <KeyboardArrowUpIcon color={
                  rowIndex === 0
                    ? 'disabled'
                    : 'primary'
                } />
              </IconButton>
              <IconButton
                data-testid="editors-pick-move-down-button"
                onClick={() => moveDown(rowIndex)}
                sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                disabled={rowIndex === dataset.length - 1}>
                <KeyboardArrowDownIcon color={
                  rowIndex === dataset.length - 1
                    ? 'disabled'
                    : 'primary'
                } />
              </IconButton>
            </Grid>

            <Grid container item xs={1} alignItems="center" justifyContent="center">
              <RemoveCircleIcon
                color="primary"
                data-testid="editors-pick-remove-button"
                fontSize="small"
                sx={{ cursor: 'pointer' }}
                onClick={() => onDelete(row.EditorsPickId)} />
            </Grid>
          </Grid>

          {rowIndex !== dataset.length - 1 ? <Box><Divider /></Box> : null}
        </Fragment>
      )}
    </Fragment>
  );
}
