/* eslint-disable operator-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import DigitalAssetCard from '../components-organisms/cards/DigitalAssetCard';
import { LIGHT_THEME } from '../constants/theme';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import {
  BodyOneSecondary,
  CaptionSecondary,
} from '../components-atoms/TypographyComponents';
import { MiniDialog } from '../components-molecules/dialogs/MiniDialog';

const MAX_ASSET_LIMIT = 25;

interface IProps {
  digitalAssets: FocusedContent[];
  onEdit?: (id: string) => void;
  onClick: (id: string) => void;
  onDelete?: (id: string) => void;
  isAdmin?: boolean;
  canTouch?: boolean;
}

export function DigitalAssetsFocusedContentsList({
  digitalAssets,
  onEdit,
  onClick,
  onDelete,
  isAdmin,
}: IProps): JSX.Element {
  const dispatch = useTypedDispatch();
  const [selectedAssets]: [DigitalAsset[]] = useTypedSelector((state) => [
    state.digitalAssetReducer.selectedAssets,
  ]);
  const [modalOpen, setModalOpen] = useState('');
  const mdDown = useMediaQuery(LIGHT_THEME.breakpoints.down('md'));
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  const user = useTypedSelector((state) => state.userReducer.data);
  const isTMorSM = user?.IsTerritoryManager || user?.IsSalesManager;

  const handleCheck = (digitalAsset: any) => {
    const isSelected =
      selectedAssets &&
      selectedAssets?.some(
        (asset: any) => JSON.stringify(digitalAsset) === JSON.stringify(asset)
      );

    if (selectedAssets && digitalAsset) {
      if (isSelected) {
        dispatch({
          type: 'SET_DIGITAL_ASSET_SELECT',
          payload: {
            selectedAssets: selectedAssets.filter(
              (asset: any) =>
                JSON.stringify(digitalAsset) !== JSON.stringify(asset)
            ),
          },
        });
        return;
      }

      if (selectedAssets.length === MAX_ASSET_LIMIT) {
        setModalOpen('error');
        return;
      }

      dispatch({
        type: 'SET_DIGITAL_ASSET_SELECT',
        payload: {
          selectedAssets: [...selectedAssets, digitalAsset],
        },
      });
    }
  };

  function renderErrorModal() {
    if (modalOpen === 'error') {
      return (
        <MiniDialog
          title='Multiple asset download'
          open={modalOpen === 'error'}
          close={() => setModalOpen('')}
          confirm
        >
          <Box sx={{ padding: '0 24px' }}>
            <BodyOneSecondary>
              <p>You can only download 25 assets at a time.</p>
              <p>
                To select and download other assets you will have to first
                deselect some of the assets in your current selection, or
                download current selection first and continue with other assets.
              </p>
            </BodyOneSecondary>
          </Box>
        </MiniDialog>
      );
    }
  }

  function renderTitle() {
    return (
      <Stack
        direction='row'
        justifyContent='space-between'
        mt={smallDown ? 2 : 4}
        mr={1}
      >
        <Stack
          gap={2}
          direction={mdDown ? 'column' : 'row'}
          alignItems={mdDown ? 'flex-start' : 'center'}
        >
          <Typography fontSize='20px' fontWeight='600'>
            Focused Content
          </Typography>
          <CaptionSecondary>{digitalAssets.length} Assets</CaptionSecondary>
        </Stack>
      </Stack>
    );
  }

  function renderList() {
    
    return (
      <Grid container item columnSpacing={'30px'} rowSpacing={3} sx={{ width: 'calc(100% + 14px)' }}>
        {digitalAssets.map((asset: any) => {
          return (
            <DigitalAssetCard
              isAdmin={isAdmin}
              isTMorSM={isTMorSM}
              onClick={() => onClick(asset.DigitalAssetId)}
              key={asset.DigitalAssetId}
              digitalAsset={asset}
              onEdit={() => {
                if (onEdit) onEdit(asset.DigitalAssetId);
              }}
              onDelete={() => {
                if (onDelete) onDelete(asset.DigitalAssetId);
              }}
              listPage
              isSelected={
                selectedAssets &&
                selectedAssets.filter(a => a.DigitalAssetId === asset.DigitalAssetId).length > 0
              }
              handleCheck={handleCheck}
            />
          );
        })}
      </Grid>
    );
  }

  return (
    <Stack
      data-test-id='digital-asset-focused-content-list'
      pl={{ xs: '20px', sm: 4, md: 4, lg: 4, xl: 4 }}
      style={{ paddingBottom: '32px' }}
      bgcolor='rgba(1, 33, 105, 0.04)'
    >
      {renderTitle()}
      {renderErrorModal()}
      <Stack mt='34px'>{renderList()}</Stack>
    </Stack>
  );
}
