import { styled } from '@mui/system';
import { BoxProps } from '@mui/material/Box';

export const ImageComponent = styled('img')<BoxProps>(() => ({}));

export const RoundedImageComponent = styled(ImageComponent)<BoxProps>(() => ({
  border: '1px solid rgba(0, 0, 0, 0.08)',
  borderRadius: '4px',
  objectFit: 'contain'
}));
