import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CircularProgress, Grid } from '@mui/material';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';

import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { GetSalesManagerRoles, GroupedSearchSalesManager, SearchSalesManagersRequest } from '../service/adminService';

import { SalesManagersList } from './SalesManagersList';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { IFilterOptions } from '../components-molecules/FilterMenu';
import { NoItems } from '../components-molecules/NoItems';
import { BodyOnePrimary } from '../components-atoms/TypographyComponents';

import { LIGHT_THEME } from '../constants/theme';
import { SearchbarWithDeboubce } from '../components-molecules/SearchBarWithDebounce';
import { AZ_SORT } from '../utils/sortTypes';

const PAGE_SIZE = 10;
export function SalesManagersTab(): JSX.Element {
  const [salesManagers, setSalesManagers] = useState<GroupedSearchSalesManager[]>([]);
  const token = useTypedSelector((state) => state.userReducer.token);
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState(0);
  const [sortType, setSortType] = useState<string>('asc');
  const [filter, setFilter] = useState<ISelectedOptions>({ Role: [] });
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>({ Role: [] });
  const [filterReset, setFilterReset] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      GetSalesManagerRoles(
        token,
        (response) => {
          setFilterOptions({ Role: response.Result.map(m => ({ title: m.Name, value: m.Id })) });
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  }, [token]);

  const getSalesManagers = useCallback((t: string, p = 0, prevData: GroupedSearchSalesManager[] = []): void => {
    SearchSalesManagersRequest(
      t,
      {
        Query: query,
        Size: PAGE_SIZE,
        Page: p,
        OrderBy: sortType,
        RoleIds: filter.Role?.length > 0 ? filter.Role : []
      },
      (response) => {
        if (p === 0) {
          setSalesManagers(response.Result.SalesManagers);
          setCount(response.Result.Count);
        } else { 
          setSalesManagers([...prevData, ...response.Result.SalesManagers]);
        }
        setLoading(false);
      },
      (error) => { if (error.response) console.error(error.response); }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, sortType, filter, refresh]);

  const loadMore = () => {
    if (token) {
      setPage((p: number) => p + 1);
      getSalesManagers(token, page + 1, salesManagers);
    }
  };

  useEffect(() => {
    if (token) getSalesManagers(token);
  }, [token, getSalesManagers]);



  const searchOnChange=(text:string) => {
    setPage(0);
    setLoading(true);
    setTimeout(() => {
      setQuery(text);
    }, 100);
  };

  return (
    <>
      {salesManagers
        && (
          <>
            <PageHeader
              title={''}
              subtitle={
                (count > 0)
                  ? `${count} ${(count > 1) ? 'Sales Managers' : 'Sales Manager'}`
                  : loading
                    ? ''
                    : 'No Sales Managers Yet'
              }
              buttonTitle="New Manager"
              activeButtons
              modalCallback={() => navigate('/admin/add-salesmanager')}
              onClickSearch={() => setSearchOpen(!searchOpen)}
              sortTypes={AZ_SORT}
              selectedSortType={sortType}
              handleSortSelect={val => { setPage(0); setSortType(val); }}
              hasFilter
              filterReset={filterReset}
              setFilterReset={setFilterReset}
              filterOptions={filterOptions}
              onFilterSubmit={(selected) => setFilter(selected)}
            />
            <SearchbarWithDeboubce
              isOpen={searchOpen}
              query={query}
              placeholder="Search Sales Managers"
              onCancel={() => { setSearchOpen(false); setQuery(''); setPage(0); }}
              onChange={searchOnChange}
            />
            {loading
              ? <CircularProgress
                color='primary'
                size={50}
                style={{ zIndex: 999, position: 'absolute', top: '50vh', right: '40vw' }}
              />
              : ((salesManagers.length > 0)
                ? <SalesManagersList
                  onDelete={() => setRefresh(!refresh)}
                  salesManagers={salesManagers}
                  loadMore={loadMore}
                  total={count}
                />
                : query || !Object.values(filter).every(option => Array.isArray(option) && option.length === 0)
                  ? <Grid sx={{ width: '100%', paddingLeft: '24px', marginTop: '16px' }}>
                    <BodyOnePrimary>0 results found.</BodyOnePrimary>
                  </Grid>
                  : <NoItems
                    icon={<NoAccountsIcon sx={{ fontSize: 88, fill: LIGHT_THEME.palette.primary.main }} />}
                    title="No senior sales managers yet"
                    description="You don’t have any senior sales managers yet. Start by adding a new one."
                  />)
            }
          </>
        )
      }
    </>
  );
}
