import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';

import { ListDivider } from '../components-molecules/Divider';
import { ArticleListCard } from './cards/ArticleListCard';

import { CircularProgress, Container, Grid, useMediaQuery } from '@mui/material';
import { LIGHT_THEME } from '../constants/theme';

interface IProps {
  articles: GroupedArticles[];
  onEdit: (id: string) => void;
  onPublishChange: (id: string) => void;
  onDelete: (id: string) => void;
  loadMore: () => void;
  total: number;
  onMove: (newOrders: Article[]) => void;
}

export function ArticleList({ articles, onEdit, onPublishChange, onDelete, loadMore, total, onMove }: IProps): JSX.Element {
  const timeoutDuration = 300;
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  const [renderData, setRenderData] = useState<GroupedArticles[]>(articles);

  useEffect(() => {
    setRenderData(articles);
  }, [articles]);

  const moveUp = (idx: number) => {
    const tempItems = [...renderData];
    const temp = [...renderData.filter(f => f.Group === 'Published')[0].Articles];
    const tempItem = temp[idx];
    temp[temp.indexOf(tempItem)] = temp[idx - 1];
    temp[idx - 1] = tempItem;
    tempItems[tempItems.indexOf(renderData.find(f => f.Group === 'Published') as GroupedArticles)].Articles = temp;
    setRenderData([...tempItems]);
    onMove(temp);
  };

  const moveDown = (idx: number) => {
    const tempItems = [...renderData];
    const temp = [...renderData.filter(f => f.Group === 'Published')[0].Articles];
    const tempItem = temp[idx];
    temp[temp.indexOf(tempItem)] = temp[idx + 1];
    temp[idx + 1] = tempItem;
    tempItems[tempItems.indexOf(renderData.find(f => f.Group === 'Published') as GroupedArticles)].Articles = temp;
    setRenderData([...tempItems]);
    onMove(temp);
  };
  
  return (
    <div style={{ paddingBottom: '48px' }}>
      <InfiniteScroll
        style={{ overflowY: 'hidden' }}
        dataLength={articles.map(m => m.Articles).flat().length} //This is important field to render the next data
        next={() => debounce(() => loadMore(), timeoutDuration)()}
        scrollThreshold={smallDown ? '550px' : '50px'}
        hasMore={articles.map(m => m.Articles).flat().length < total}
        loader={
          <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
            <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
          </Container>
        }
      >
        {renderData.map((article) => {
          return (
            <ListDivider
              key={article.Group}
              division={{ name: article.Group, length: article.Articles.length }}
              caption={{ singular: 'Topic', plural: 'Topics' }}
              halfMargin
              noBottomPadding
            >
              <Grid container item xs={12} columnSpacing={2} rowSpacing={3} sx={{ width: '100%', margin: 0 }}>

                {article.Articles.map((item, idx) =>
                  <ArticleListCard
                    count={article.Articles.length}
                    moveDown={moveDown}
                    moveUp={moveUp}
                    key={item.Id}
                    article={item}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onPublishChange={onPublishChange}
                    index={idx}
                  />
                )}
              </Grid>
            </ListDivider>
          );
        })}
      </InfiniteScroll>
    </div>
  );
}
