import { Section } from '../components-molecules/section/Section';
import { SectionHeader } from '../components-molecules/section/SectionHeader';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { Dashboard } from '../components-organisms/Dashboard';
import { Box, Divider, Tab } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import { SectionRow } from '../components-molecules/section/SectionRow';
import { TabContext, TabList } from '@mui/lab';
import { useState, useEffect, Fragment, useCallback } from 'react';
import { ImageComponent } from '../components-atoms/ImageComponents';
import { ButtonTextPrimary } from '../components-atoms/ButtonComponents';
import { GetMerchantRequest, GetMerchantUsersRequest } from '../service/merchantsService';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { MerchantEmployeeTabPanel } from '../components-organisms/MerchantEmployeeTabPanel';
import { MerchantStoreTabPanel } from '../components-organisms/MerchantStoreTabPanel';
import { useLocation, useNavigate } from 'react-router-dom';
import imageIcon from '../assets/img/merchant_placeholder.jpg';

export function Organisation(): JSX.Element {
  const { state } = useLocation();
  const [currentTab, setCurrentTab] = useState<'stores' | 'employees'>(state ? state.latestTab : 'stores');
  const [merchant, setMerchant] = useState<Merchant>();
  const [userProperties, token, refresh] = useTypedSelector(state => [state.userReducer.data, state.userReducer.token, state.merchantReducer.refresh]);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const getMerchant = useCallback((t: string, mId: GUID): void => {
    GetMerchantRequest(
      t,
      mId,
      (response) => {
        if (response) {
          setMerchant(response);
          dispatch({ type: 'MERCHANT_SET_CURRENT_DATA', payload: { merchant: response } });
          GetMerchantUsersRequest(
            t,
            response.Id,
            (resp) => {
              dispatch({ type: 'MERCHANT_SET_EMPLOYEES', payload: { employees: resp } });
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {}
          );
          dispatch({ type: 'MERCHANT_SET_STORES', payload: { stores: response.Stores } });
        }
      },
      (error) => {
        if (error.response) {
          dispatch({ type: 'USER_ANY_FAILURE_NO_SIGNOUT', payload: { error: error.response.data.Error } });
        }
      }
    );
  }, [dispatch]);

  useEffect(() => {
    if (userProperties && token) {
      getMerchant(token, userProperties.MerchantId);
    }
  }, [refresh, token, userProperties, dispatch, getMerchant]);

  return (
    (merchant)
      ? (
        <Dashboard noDrawer merchantPage>
          <PageHeader
            title={'Organisation Profile'}
          />
          <Section>
            <SectionHeader title="Basic information" button={
              userProperties && userProperties.Role !== 'Store Employee' && userProperties.Role !== 'Store Manager'
                ? <ButtonTextPrimary startIcon={<Edit />} onClick={() => navigate('/edit-organisation', { state: { navigateBackTo: window.location.pathname } })}>Edit</ButtonTextPrimary>
                : undefined
            } />

            <SectionRow title="" lines={[{ element: <ImageComponent key={merchant.Id} src={merchant.ThumbnailUrl || merchant.Logo || imageIcon} loading="lazy" height="96" width="96" /> }]} />
            <Box><Divider /></Box>
            <SectionRow title="Name" lines={[{ data: merchant.Name, type: 'body1' }]} />

            <Box><Divider /></Box>

            <SectionRow title="Payer/parent code" lines={[{ data: merchant.PayerCode, type: 'body1' }]} />

            <Box><Divider /></Box>

            <SectionRow
              title="HQ Address"
              lines={[
                { data: merchant.AddressLine1, type: 'body1' },
                { data: merchant.Phone, type: 'body2', color: 'text.secondary' }
              ]}
            />

          </Section>
          <TabContext value={currentTab}>
            <TabList onChange={(e: unknown, newValue: 'stores' | 'employees') => setCurrentTab(newValue)} sx={{ margin: '24px 0 0', width: '100%' }}>
              <Tab label="Stores" value="stores" sx={{ width: '50%', maxWidth: '50%' }} />
              <Tab label="Employees" value="employees" sx={{ width: '50%', maxWidth: '50%' }} />
            </TabList>

            <Box sx={{ minHeight: '80vh', width: '100%' }}>
              <MerchantStoreTabPanel merchant={merchant} setPageOpen={() => navigate('/add-store', { state: { navigateBackTo: window.location.pathname } } )} />
              <MerchantEmployeeTabPanel setPageOpen={() => navigate('/add-employee', { state: { navigateBackTo: window.location.pathname } } )} />
            </Box>
          </TabContext>
        </Dashboard>
      )
      : <Fragment />
  );
}
