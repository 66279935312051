import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { DigitalAssetsList } from '../components-organisms/DigitalAssetsList';
import { DeleteDigitalAssetRequest, GetDigitalAssetRequest } from '../service/serviceService';
import { BodyOneSecondary, HeaderFive } from '../components-atoms/TypographyComponents';
import { Alert, Box, CircularProgress, Grid, Snackbar } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CampaignPageRequest } from '../service/externalPageService';
import { DigitalAssetsFocusedContentsList } from '../components-organisms/DigitalAssetsFocusedContentsList';
interface IProps {
  noTab?: boolean;
  isAdmin?: boolean;
  noMargin?: boolean;
  loadMore: () => void;
  total: number;
  focusedContents: FocusedContent[];
  showFocusedContents?: boolean;
}

export function DigitalAssets({ total, noTab, isAdmin, loadMore, focusedContents, showFocusedContents }: IProps): JSX.Element {  
  const [digitalAssets, offset, sortType, loading]: any = useTypedSelector((state) => [state.digitalAssetReducer.assetListToShow, state.digitalAssetReducer.offset, state.digitalAssetReducer.sortType, state.digitalAssetReducer.loading]);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({});
  const [isNewAssetAdded, setIsNewAssetAdded] = useState<boolean>(state?.isNewAssetAdded ? state.isNewAssetAdded : false);
  const [isAssetUpdated, setIsAssetUpdated] = useState<boolean>(state?.isAssetUpdated ? state.isAssetUpdated : false);
  const [token] = useTypedSelector((state) => [state.userReducer.token]);
  const dispatch = useTypedDispatch();

  const getAsset = useCallback((id: string): void => {
    if (token) {
      GetDigitalAssetRequest(
        token,
        id,
        (response) => {
          dispatch({ type: 'SET_CURRENT_DIGITAL_ASSET', payload: { currentData: response.Result } });
          navigate('/admin/edit-asset', { state: { navigateBackTo: window.location.pathname } });
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatch]); 

  const onEdit = (id: string) => {
    getAsset(id);
  };

  const onDelete = (id: string) => {    
    if (token) {
      DeleteDigitalAssetRequest(
        token,
        id,
        () => dispatch({ type: 'DIGITAL_ASSET_REFRESH' }),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  const onClick = (id: string) => {
    dispatch({ type: 'SET_DIGITAL_ASSET_TOP', payload: { top: window.scrollY } });
    navigate(`/asset-detail/${id}`, { state: { previousUrl: window.location.pathname } });
  };

  useEffect(() => {
    if (searchParams.get('query')) {
      dispatch({ type: 'SET_DIGITAL_ASSET_SEARCH', payload: { search: searchParams.get('query') || '' } });
    }
    if (token && searchParams.get('query')) {
      CampaignPageRequest(token, { Url: window.location.href });
    }
  }, [token, searchParams, dispatch]);

  const hasAsset = useMemo(() => {
    if (sortType === 'BestMatch') {
      return digitalAssets[0]?.DigitalAssets?.length > 0;
    }

    return digitalAssets.length > 0;
  }, [digitalAssets, sortType]);

  function renderNoResult() {
    return (
      <Box width="320px" margin="0 auto" display="flex" flexDirection="column" alignItems="center" textAlign="center">
        <Box mt={noTab ? 0 : 6} mb={4}>
          <img src={require('../assets/img/brand-assets/no-result.png')} width="160px" height="160px" />
        </Box>
        <HeaderFive mb={1}>0 search results</HeaderFive>
        <BodyOneSecondary>We couldn’t find any matching brand assets for your search term. Please check your search term or update your filters to try again.</BodyOneSecondary>
      </Box>
    );
  }

  function renderSnackbars() {
    return (
      <>
        {!loading && (
          <Snackbar
            open={isNewAssetAdded}
            autoHideDuration={5000}
            onClose={() => setIsNewAssetAdded(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{
              '&.MuiSnackbar-root': { top: '120px', right: '16px' }
            }}
          >
            <Alert
              data-testid="brand-asset-added-confirmation"
              onClose={() => setIsNewAssetAdded(false)}
              variant="filled"
              severity="success"
              action=""
              sx={{
                width: '100%',
                boxShadow: '0 10px 16px 0px grey',
                borderRadius: '4px'
              }}
            >
              Brand asset was successfully added.
            </Alert>
          </Snackbar>
        )}
        {!loading && (
          <Snackbar
            open={isAssetUpdated}
            autoHideDuration={5000}
            onClose={() => setIsAssetUpdated(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{
              '&.MuiSnackbar-root': { top: '120px', right: '16px' }
            }}
          >
            <Alert
              data-testid="brand-asset-updated-confirmation"
              onClose={() => setIsAssetUpdated(false)}
              variant="filled"
              severity="success"
              sx={{
                width: '100%',
                boxShadow: '0 10px 16px 0px grey',
                borderRadius: '4px'
              }}
            >
              Brand asset has been successfully updated.
            </Alert>
          </Snackbar>
        )}
      </>
    );
  }

  if (loading && offset === 0) {
    return (
      <Grid container item justifyContent="center" alignItems="center">
        <CircularProgress
          color="primary"
          size={50}
          style={{ zIndex: 999, margin: '56px 0' }}
        />
      </Grid>
    );
  }

  if (hasAsset) {
    return (
      <>
        {showFocusedContents && (
          <DigitalAssetsFocusedContentsList
            isAdmin={isAdmin}
            digitalAssets={focusedContents}
            onEdit={onEdit}
            onClick={onClick}
          />
        )}
        <DigitalAssetsList
          loadMore={loadMore}
          isAdmin={isAdmin}
          digitalAssets={digitalAssets}
          onEdit={onEdit}
          onDelete={onDelete}
          onClick={onClick}
          total={total}
        />
        {renderSnackbars()}
      </>
    );
  }

  return (
    <Grid
      item
      container>
      {renderNoResult()}
    </Grid>
  );
}
