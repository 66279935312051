/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { Dashboard } from '../components-organisms/Dashboard';
import { TerritoryManagersTab } from '../components-organisms/TerritoryManagersTab';
import { SalesManagersTab } from '../components-organisms/SalesManagersTab';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { IStyles } from '../constants/theme';

export default function SalesManagers(): JSX.Element {
  const userData = useTypedSelector((state) => state.userReducer.data);
  const navigate = useNavigate();
  const location = useLocation();
  const isFromTm = location.state?.from === 'add-territorymanager' || location.state?.from === 'edit-territorymanager';
  const isFromSalesManager = location.state?.from === 'add-salesmanager' || location.state?.from === 'edit-salesmanager';
  const [currentTab, setCurrentTab] = useState<'sales' | 'territory'>(
    !isFromSalesManager && ((userData && userData.IsSalesManager) || isFromTm) ? 'territory' : 'sales');

  return (
    <Dashboard justifyContent="flex-start">
      <PageHeader
        title={userData && userData.IsSalesManager ? 'Territory Managers' : 'Sales Managers'}
        buttonTitle={''}
        modalCallback={() => navigate('/admin/add-salesmanager')}
      />
      <TabContext value={currentTab}>
        {userData && !userData.IsSalesManager && (
          <TabList onChange={(e: unknown, newValue: 'sales' | 'territory') => setCurrentTab(newValue)} style={styles.tabList}>
            <Tab data-testid={'sales-managers-tab'} label="Sales Managers" value="sales" style={styles.tabButton} />
            <Tab data-testid="territory-managers-tab" label="Territory Managers" value="territory" style={styles.tabButton} />
          </TabList>
        )}
        <Box sx={{ minHeight: '80vh', width: '100%' }}>
          <TabPanel sx={{ p: 0 }} value="sales">
            <SalesManagersTab />
          </TabPanel>
          <TabPanel sx={{ p: 0 }} value="territory">
            <TerritoryManagersTab />
          </TabPanel>
        </Box>
      </TabContext>

    </Dashboard>
  );
}

const styles: IStyles = {
  tabList: {
    margin: '24px 0 36px',
    width: '100%'
  },
  tabButton: {
    width: '50%',
    maxWidth: '50%'
  }
};
