import { Fragment, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import { Section } from '../../components-molecules/section/Section';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { SectionRow } from '../../components-molecules/section/SectionRow';
import { BodyOneLink, BodyOneSecondary } from '../../components-atoms/TypographyComponents';
import { DeleteAdminRequest } from '../../service/adminService';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { MiniDialog } from '../../components-molecules/dialogs/MiniDialog';

function NoMerchantStores(): JSX.Element {
  return (
    <SectionRow
      title="Merchant Stores"
      lines={[{
        data: 'Manager does not have any merchant stores assigned yet. You can assign Territory Managers to a Merchant Store on the Merchant page.',
        type: 'body1',
        color: 'text.secondary'
      }]}
    />
  );
}

interface IProps {
  onDelete: () => void;
  territorymanager: TerritoryManager;
}

export function TerritoryManagerListCard({ territorymanager, onDelete }: IProps): JSX.Element {
  const navigate = useNavigate();
  const merchantStoresList = territorymanager.Stores
    ? territorymanager.Stores.map((store, index) =>
      <Link key={`${store.MerchantId}${index}`} component={RouterLink} to={`/admin/merchants/${store.MerchantId}`} sx={{ display: 'inline-block', margin: '0 0 0 4px' }}>
        {territorymanager.Stores
          ? <BodyOneLink>{store.Name}{territorymanager.Stores.length - 1 > index ? ',' : null}</BodyOneLink>
          : null
        }
      </Link>
    )
    : null;

  const token = useTypedSelector(state => state.userReducer.token);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const deleteTerritoryManager = () => {
    if (token) {
      DeleteAdminRequest(
        token,
        territorymanager.AdminProperties.Id,
        () => onDelete(),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  return (
    <Section>
      <SectionHeader
        hasMenuIcon
        title={`${territorymanager.AdminProperties.FirstName} ${territorymanager.AdminProperties.LastName}`}
        label={territorymanager.AdminProperties.RegistrationPending ? 'Registration Pending' : ''}
        onDelete={() => setModalOpen(true)}
        onEdit={() => navigate('/admin/edit-territorymanager', { state: { territorymanager } })}
      />
      <SectionRow
        title="Contact"
        lines={[
          { data: territorymanager.AdminProperties.Email, type: 'body1' },
          { data: territorymanager.AdminProperties.Phone, type: 'body2', color: 'text.secondary' }
        ]}
      />
      <Box><Divider /></Box>
      {(territorymanager.Stores && territorymanager.Stores.length > 0)
        ? <SectionRow
          title="Merchant Stores"
          lines={[{
            element: <Fragment key={territorymanager.AdminProperties.Email}><div>{merchantStoresList}</div></Fragment>
          }]}
        />
        : <NoMerchantStores />
      }
      {modalOpen
        ? <MiniDialog
          title="Delete Territory Manager"
          open={modalOpen}
          close={() => setModalOpen(false)}
          remove={() => {
            deleteTerritoryManager();
            setModalOpen(false);
          }}>
          <Box sx={{ padding: '0 24px' }}>
            <BodyOneSecondary>
              Are you sure you want to delete this Territory Manager?
            </BodyOneSecondary>
          </Box>
        </MiniDialog>
        : null
      }
    </Section>
  );
}
