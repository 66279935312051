import { DigitalToolkitsList } from './lists/DigitalToolkitsList';
import { DigitalToolkitsSource } from '../utils/enums';

interface IProps {
  service?: any;
  source: DigitalToolkitsSource;
}

export function DigitalToolkitsService({ service, source }: IProps): JSX.Element {
  return (
    <>
      <DigitalToolkitsList
        service={service}
        source={source}
      />
    </>
  );
}
