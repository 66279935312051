/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo } from 'react';
import { ButtonBase, styled } from '@mui/material';
import { INewsletterItem } from '../../NewsletterRepositoryService';
import { BodyTwoSecondary, HeaderSix } from '../../../components-atoms/TypographyComponents';
import { FormatDate } from '../../../utils/dateUtils';
import { useNavigate } from 'react-router-dom';
import { LIGHT_THEME } from '../../../constants/theme';

const NewsletterContainer = styled(ButtonBase)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  alignItems: 'flex-start',
  textAlign: 'left',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderRadius: '20px',
  margin: '24px 0px',
  padding: '24px !important',
  cursor: 'pointer',
  transition: '0.4s all',
  ':hover': {
    background: LIGHT_THEME.palette.action.hover
  }
});

export const NewsletterShortCard = ({ newsletter }: INewsletterItem): JSX.Element => {
  const navigate = useNavigate();
  const sentDate = useMemo(() => FormatDate(new Date(newsletter.SentDate), { day: 'numeric', month: 'long', format: 'dd MM YYYY' }), [newsletter]);

  function onClick() {
    navigate('/detail/emailnewsletter/' + newsletter.Id);
  }

  return (
    <NewsletterContainer onClick={onClick}>
      <HeaderSix>{newsletter.Subject}</HeaderSix>
      <BodyTwoSecondary>
        {sentDate}
      </BodyTwoSecondary>
    </NewsletterContainer>
  );
};

export default NewsletterShortCard;
