import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { STORE } from '../redux/store';

type StoreRootState = ReturnType<typeof STORE.getState>;
type StoreDispatch = typeof STORE.dispatch;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const useTypedSelector: TypedUseSelectorHook<StoreRootState> = useSelector;
export const useTypedDispatch = (): Dispatch<CombinedStoreActions> => useDispatch<StoreDispatch>();
