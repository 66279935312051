import { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CircularProgress } from '@mui/material';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import { Add } from '@mui/icons-material';

import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { SearchTerritoryManagersRequest } from '../service/adminService';

import { TerritoryManagersList } from './TerritoryManagersList';
import { TerritoryManagerAssignModal } from './TerritoryManagerAssignModal';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { NoItems } from '../components-molecules/NoItems';
import { ButtonContainedPrimary } from '../components-atoms/ButtonComponents';

import { LIGHT_THEME } from '../constants/theme';
import { SearchbarWithDeboubce } from '../components-molecules/SearchBarWithDebounce';

export function TerritoryManagersTab(): JSX.Element {
  const [territoryManagers, setTerritoryManagers] = useState<TerritoryManager[]>([]);
  const [token, userData] = useTypedSelector((state) => [state.userReducer.token, state.userReducer.data]);
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState(0);
  const pageSize = 10;
  const [searchOpen, setSearchOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
 
  const getTerritoryManagers = useCallback((t: string, page = 0, size = pageSize, prevData: TerritoryManager[] = []): void => {
    SearchTerritoryManagersRequest(
      t,
      {
        Query: query,
        Size: size,
        Page: page
      },
      (response) => {
        if (response) {
          if(page === 0) {
            setCount(response.Result.Count);
          }
          setLoading(false);
          setTerritoryManagers([...prevData, ...response.Result.Result]);
          dispatch({ type: 'MERCHANT_SET_TERRITORY_MANAGERS', payload: { territoryManagers: response.Result.Result } });
        }
      },
      (error) => { if (error.response) console.error(error.response); }
    );
  }, [dispatch, query]);

  const loadMore = () => {
    if (token) {
      getTerritoryManagers(token, page + 1, pageSize, territoryManagers);
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (token) getTerritoryManagers(token);
  }, [token, getTerritoryManagers, query, refresh]);

  const onSubmitTMModal = () => {
    if (token) getTerritoryManagers(token);
  };

  const headerRight = () => {
    if (userData && userData.IsSalesManager) {
      return (
        <Fragment>
          <TerritoryManagerAssignModal onSubmit={onSubmitTMModal} />
          <ButtonContainedPrimary
            data-testid='new-manager-button'
            startIcon={<Add />}
            onClick={() => navigate('/admin/add-territorymanager')}
            sx={{ margin: '0 0 0 8px', padding: '8px 11px !important', width: 'max-content' }}
          >
            New Manager
          </ButtonContainedPrimary>
        </Fragment>
      );
    }
    return (
      <ButtonContainedPrimary
        data-testid='new-manager-button'
        startIcon={<Add />}
        onClick={() => navigate('/admin/add-territorymanager')}
        sx={{ margin: '0 0 0 8px', padding: '8px 11px !important', width: 'max-content' }}
      >
        New Manager
      </ButtonContainedPrimary>
    );
  };

  const searchOnChange=(text:string)=>{
    setQuery(text);
  };

  return (
    <>
      {territoryManagers
        && (
          <>
            <PageHeader
              title={''}
              subtitle={
                (count > 0)
                  ? `${count} ${(count > 1) ? 'Territory Managers' : 'Territory Manager'}`
                  : loading
                    ? ''
                    : 'No Territory Managers Yet'
              }
              activeButtons={count > 0}
              onClickSearch={() => setSearchOpen(!searchOpen)}
              noSort
              headerRight={headerRight}
            />
            <SearchbarWithDeboubce
              isOpen={searchOpen}
              query={query}
              placeholder="Search Territory Managers"
              onCancel={() => { setSearchOpen(false); setQuery(''); }}
              onChange={searchOnChange}
            />
            {loading
              ? <CircularProgress
                color='primary'
                size={50}
                style={{ zIndex: 999, position: 'absolute', top: '50vh', right: '40vw' }}
              />
              : ((territoryManagers.length > 0)
                ? <TerritoryManagersList
                  onDelete={() => setRefresh(!refresh)}
                  territoryManagers={territoryManagers}
                  loadMore={loadMore}
                  total={count}
                />
                : <NoItems
                  icon={<NoAccountsIcon sx={{ fontSize: 88, fill: LIGHT_THEME.palette.primary.main }} />}
                  title="No territory managers yet"
                  description="You don’t have any territory managers yet. Start by adding the first manager."
                />)
            }
          </>
        )
      }
    </>
  );
}
