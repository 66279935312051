import { CircularProgress, Container, Grid, Stack } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { GetIndustryEventDetails, JoinIndustryEventRequest } from '../service/serviceService';
import DetailPageHeader from './appbars/DetailPageHeader';
import { Footer } from './Footer';
import IndustryEventWatchEvent from './modals/IndustryEventWatchEvent';
import { IndustryEventListingCard } from './cards/listing/IndustryEventListingCard';

export function IndustryEventDetail(): JSX.Element {
  const { id } = useParams();
  const navigate = useNavigate();
  const [token, userServices] = useTypedSelector((state) => [state.userReducer.token, state.userReducer.services]);
  const [industryEvent, setIndustryEvent] = useState<IndustryEvent>();
  const [watchEventModal, setWatchEventModal] = useState<IndustryEvent>();

  const getIndustryEventDetails = useCallback((token: string) => {
    if (id) GetIndustryEventDetails(token, id, (response) => setIndustryEvent(response.Result), () => { /** */ });
  }, [id]);

  const onJoinEvent = useCallback(() => {
    if (token && industryEvent) {
      JoinIndustryEventRequest(token, industryEvent.Id, () => {/** */ }, () => {/** */ });
      window.open(industryEvent.JoinUrl, '_blank', 'rel=noopener noreferrer');
    }
  }, [industryEvent, token]);

  const onWatchEventModalOpen = useCallback(() => {
    if (industryEvent) setWatchEventModal(industryEvent);
  }, [industryEvent]);

  const onWatchEventModalClose = useCallback(() => {
    setWatchEventModal(undefined);
  }, []);

  useEffect(() => {
    if (token) getIndustryEventDetails(token);
  }, [token, getIndustryEventDetails]);

  const onBack = () => {
    const digitalAssetServiceId = userServices.flatMap(f => f.Services).find(m => m.ServiceName === 'Industry Events')?.ServiceId;
    const serviceUrl = '/service/';
    if (digitalAssetServiceId) {
      navigate(`${serviceUrl}${digitalAssetServiceId}`);
      return;
    }
    navigate(-1);
  };

  return (
    <Stack flex={1}>
      {industryEvent === undefined
        ? <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
          <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
        </Container>
        : <Fragment>
          <DetailPageHeader
            title={industryEvent ? industryEvent.Title : ''}
            onBack={onBack} />

          <Stack width="100%" px={{ xs: '20px', sm: 4 }}>
            <IndustryEventListingCard
              hideEllipsis
              key={industryEvent.Id}
              title={industryEvent.Title}
              description={industryEvent.Description}
              thumbnailUrl={industryEvent.ThumbnailUrl}
              thumbnailFit='cover'
              event={industryEvent}
              onJoinEvent={onJoinEvent}
              onWatchEventModalOpen={onWatchEventModalOpen}
            />

            <IndustryEventWatchEvent
              isOpen={watchEventModal !== undefined}
              id={watchEventModal ? watchEventModal.Id : ''}
              name={watchEventModal ? watchEventModal.Title : ''}
              password={watchEventModal ? watchEventModal.RecordingPassword : ''}
              eventUrl={watchEventModal ? watchEventModal.ShareUrl : ''}
              onClose={() => onWatchEventModalClose()} />
          </Stack>
        </Fragment>}

      <Grid item sx={{ width: '100%' }}>
        <Footer />
      </Grid>
    </Stack>
  );
}
