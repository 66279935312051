import ReactGA from 'react-ga4';
import { IS_DEV } from '../constants/isDev';
import { environment } from '../environment';
import { STORE } from '../redux/store';
import { isBrandAssetFilterApplied } from '../utils/getBrandAssetFilters';
import { DEFAULT_BRAND_ASSET_SORT } from '../constants/digitalAssets';

export enum SendTo {
  admin = 'admin',
  client = 'client',
}

interface IGaServiceOptions {
  sendTo?: SendTo
}

const BRAND_ASSET_PAGES = ['Brand Assets', 'Asset Detail'];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const gaService = {
  pageView:(title?: string, options?: IGaServiceOptions): void => {
    if (!title) { return; }

    const isAdmin = options?.sendTo === SendTo.admin || location.pathname.startsWith('/admin');
    if (IS_DEV) {
      console.log('Pageview:', { title, isAdmin });
    }

    // Remove digital asset list top value on different pages
    if (!BRAND_ASSET_PAGES.includes(title)) {
      STORE.dispatch({ type: 'SET_DIGITAL_ASSET_SEARCH_AND_SORT', payload: { search: '', sortType: DEFAULT_BRAND_ASSET_SORT } });
      const state = STORE.getState();
      if (state.digitalAssetReducer.search?.length > 0 || isBrandAssetFilterApplied(state.digitalAssetReducer.filter) || state.digitalAssetReducer.top > 0) {
        STORE.dispatch({ type: 'RESET_DIGITAL_ASSET_FILTER' });
        STORE.dispatch({ type: 'SET_DIGITAL_ASSET_TOP', payload: { top: 0 } });
      }
    }

    ReactGA.event('page_view', { page_location: location.href, page_title: title, send_to: isAdmin ? environment.gaAdminToken : environment.gaToken });
  }
};
