import { Box, BoxProps } from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { LIGHT_THEME } from '../constants/theme';

// Header 3

export function HeaderThreePrimary(props: TypographyProps<'h3'>): JSX.Element {
  return <Typography variant="h3" component="h3" color="text.primary" {...props} sx={{ ...props.sx }} />;
}

// Header 4

export function HeaderFourPrimary(props: TypographyProps<'h4'>): JSX.Element {
  return <Typography variant="h4" component="h4" color="text.primary" {...props} sx={{ ...props.sx }} />;
}
export function HeaderFourSecondary(props: TypographyProps<'h4'>): JSX.Element {
  return <Typography variant="h4" component="h4" color="#545850" {...props} sx={{ ...props.sx }} />;
}
// Header 5
export function HeaderFive(props: TypographyProps<'h5'>): JSX.Element {
  return <Typography {...props} variant="h5" component="h5" sx={{ ...props.sx }} />;
}

export function HeaderFivePrimary(props: TypographyProps<'h5'>): JSX.Element {
  return <Typography {...props} variant="h5" component="h5" color="text.primary" sx={{ ...props.sx }} />;
}

// Header 6
export function HeaderSix(props: TypographyProps<'h6'>): JSX.Element {
  return <Typography {...props} variant="h6" component="h6" color="text.primary" sx={{ margin: '0 0 12px', ...props.sx }} />;
}

// Body1
export function BodyOnePrimary(props: TypographyProps & { component?: React.ElementType; }): JSX.Element {
  return <Typography {...props} variant="body1" color="text.primary" sx={{ ...props.sx }} />;
}

export function BodyOneSecondary(props: TypographyProps & { component?: React.ElementType; }): JSX.Element {
  return <Typography {...props} variant="body1" color="text.secondary" sx={{ ...props.sx }} />;
}

export function BodyOneLink(props: TypographyProps & { component?: React.ElementType; }): JSX.Element {
  return <Typography {...props} variant="body1" color="primary" sx={{ ...props.sx, ':hover': { textDecoration: 'underline' } }} />;
}

// Body2
export function BodyTwoPrimary(props: TypographyProps & { component?: React.ElementType; }): JSX.Element {
  return <Typography {...props} variant="body2" color="text.primary" sx={{ ...props.sx }} />;
}

export function BodyTwoSecondary(props: TypographyProps & { component?: React.ElementType; }): JSX.Element {
  return <Typography {...props} variant="body2" color="text.secondary" sx={{ ...props.sx }} />;
}

// Subtitle 1

export function SubtitleOnePrimary(props: TypographyProps & { component?: React.ElementType; }): JSX.Element {
  return <Typography {...props} variant="subtitle1" color="text.primary" sx={{ ...props.sx }} />;
}
export function SubtitleOneSecondary(props: TypographyProps & { component?: React.ElementType; }): JSX.Element {
  return <Typography {...props} variant="subtitle1" color="text.secondary" sx={{ ...props.sx }} />;
}

// Subtitle 2
export function SubtitleTwoPrimary(props: TypographyProps & { component?: React.ElementType; }): JSX.Element {
  return <Typography variant="subtitle2" color="text.primary" {...props} sx={{ ...props.sx }} />;
}
export function SubtitleTwoSecondary(props: TypographyProps & { component?: React.ElementType; }): JSX.Element {
  return <Typography {...props} variant="subtitle2" color="text.secondary" sx={{ ...props.sx }} />;
}

// Captions
export function CaptionPrimary(props: TypographyProps & { component?: React.ElementType; }): JSX.Element {
  return <Typography {...props} variant="caption" color="text.primary" sx={{ ...props.sx }} />;
}

export function CaptionSecondary(props: TypographyProps & { component?: React.ElementType; }): JSX.Element {
  return <Typography {...props} variant="caption" color="text.secondary" sx={{ ...props.sx }} />;
}

// Overlines
export function OverlineSecondary(props: TypographyProps & { component?: React.ElementType; }): JSX.Element {
  return <Typography {...props} variant="overline" color="text.secondary" sx={{ ...props.sx }} />;
}

export function OverlineError(props: TypographyProps & { component?: React.ElementType; }): JSX.Element {
  return <Typography {...props} variant="overline" color="error.main" sx={{ ...props.sx }} />;
}

// Contained Typography
export function ContainedTypography(props: { text: string; typographyProps?: TypographyProps; boxProps?: BoxProps; } & { component?: React.ElementType; }): JSX.Element {
  return (
    <Box
      {...props.boxProps}
      sx={{
        display: 'block',
        height: '32px',
        width: 'max-content',
        padding: '0 12px',
        borderRadius: '16px',
        background: LIGHT_THEME.palette.secondary.main,
        ...props.boxProps?.sx
      }}
    >
      <Typography {...props.typographyProps} variant="overline" color="white" sx={{ ...props.typographyProps?.sx }}>{props.text}</Typography>
    </Box>
  );
}
