import Box from '@mui/material/Box';
import { useCallback, useEffect, useState } from 'react';
import { CombinationRow } from '../components-molecules/CombinationRow';
import { Section } from '../components-molecules/section/Section';
import { SectionHeader } from '../components-molecules/section/SectionHeader';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { Dashboard } from '../components-organisms/Dashboard';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { GetGlobalEmailNotificationServicesRequest, ToggleGlobalEmailNotificationServicesRequest } from '../service/emailNotificationService';

export function GlobalEmailNotificationSettings(): JSX.Element {
  const [token] = useTypedSelector((state) => [state.userReducer.token]);

  const [dataset, setDataset] = useState<GlobalEmailNotificationConfig[]>([]);

  const toggle = (id: string | number | GUID) => {
    const index = dataset.findIndex((item) => item.Id === id);
    if (index > -1) {
      const newDataset = [...dataset];
      newDataset[index].IsEnabled = !newDataset[index].IsEnabled;
      setDataset(JSON.parse(JSON.stringify(newDataset)));
    }
  };

  const getData = useCallback(() => {
    if (token) GetGlobalEmailNotificationServicesRequest(token, (response) => setDataset([...response]), (error) => console.log(error));
  }, [token]);

  const onToggle = useCallback((id: string | number | GUID) => {
    if (token) {
      toggle(id);

      ToggleGlobalEmailNotificationServicesRequest(
        token,
        id,
        () => getData(),
        () => toggle(id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData, token]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Dashboard justifyContent="flex-start">
      <PageHeader title={'Email Notification Settings'} />
      <Section>
        <SectionHeader
          title="Published content notifications"
          subtitle="Set up email notifications for each specific service. Activating email notifications will automatically send alerts or allow users of the admin panel to manually choose the option to notify territory & sales managers when creating new content."
          fullWidthHeader
        />

        <Box sx={{ margin: '24px 0 0' }}>
          <CombinationRow
            columnTitles={{ Id: 'Id', ServiceName: 'Service' }}
            dataset={dataset.sort((a, b) => a.ServiceName.localeCompare(b.ServiceName))}
            onToggle={onToggle}
          />
        </Box>
      </Section>
    </Dashboard>
  );
}
