import { useEffect, useState } from 'react';

import { UserDivider } from '../utils/arrayDividers';

import { ListDivider } from '../components-molecules/Divider';
import { EmployeeCard } from '../components-organisms/cards/EmployeeCard';

export function EmployeesList({ employees, navigateBackTo }: { employees: MerchantUser[]; navigateBackTo: string; }): JSX.Element {
  const [sectionedlist, setSectionedList] = useState<DividedList<MerchantUser>>({});

  useEffect(() => {
    if (employees.length > 0) {
      setSectionedList({ ...UserDivider<MerchantUser>(employees) });
    }
  }, [employees]);

  return (
    <div style={{ paddingBottom: 72 }}>
      {Object.keys(sectionedlist).sort().map((section, sectionIndex) => {
        return (
          <ListDivider
            key={sectionIndex}
            division={{ name: section, length: sectionedlist[section].length }}
            caption={{ singular: 'Store User', plural: 'Store Users' }}
          >
            {sectionedlist[section].map((item, itemIndex) => <EmployeeCard key={itemIndex} employee={item} navigateBackTo={navigateBackTo} />)}
          </ListDivider>
        );
      })}
    </div>
  );
}
