/* eslint-disable @typescript-eslint/naming-convention */
import { Container } from '@mui/material';
import { ListDivider } from '../components-molecules/Divider';
import { GroupedThread, } from '../service/serviceService';
import { ThreadListItem } from './cards/ThreadListItem';

interface IProps {
  data: GroupedThread[];
  currentTab: string;
  onArchive: () => void;
}

export function ThreadList({ data, currentTab, onArchive }: IProps): JSX.Element {
  return (
    <Container sx={{pb:9,'&.MuiContainer-root':{paddingLeft:'0px',paddingRight:'0px'}}}>
      {
        data.map(item =>
          <ListDivider
            key={item.Group}
            division={{ name: item.Group, length: item.Threads.length }}
            caption={{ singular: 'Thread', plural: 'Threads' }}
            halfMargin
            
          >
            {item.Threads.map(thread =>
              /* eslint-disable react/prop-types */
              <ThreadListItem key={thread.Id} thread={thread} currentTab={currentTab} onArchive={onArchive} />
            )}
          </ListDivider>
        )
      }
    </Container>
  );
}
