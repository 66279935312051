/* eslint-disable @typescript-eslint/naming-convention */
import { Grid } from '@mui/material';
import { BodyOneSecondary, HeaderSix } from '../components-atoms/TypographyComponents';
import { IStyles } from '../constants/theme';

export function RebateSchemeService(): JSX.Element {
  return (
    <>
      <Grid container item justifyContent="flex-start" sx={{ maxWidth: '1408px', width: '100%', padding: '0 24px', margin: '32px 0px' }}>
        <Grid item sm={3}>
          <Grid
            container
            justifyContent="center"
            item
            style={styles.imageContainer}>
            <img
              style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'cover', borderRadius: '4px' }}
              src={`${require('../assets/img/rebatescheme/chart-sample.png')}`}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={9} sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
          <Grid container sx={{ maxWidth: { sm: '672px' }, width: '100%' }}>
            <BodyOneSecondary style={{ marginBottom: '16px' }}>
              This is the section where we appraise you of your rebate status, recognize
              your annual performance and share information about rewards like hospitality
              or trips with you.
            </BodyOneSecondary>
            <HeaderSix sx={{ margin: '10px 0' }}>
              Annual Rebate Update
            </HeaderSix>
            <BodyOneSecondary style={{ marginTop: '16px', marginBottom: '32px' }}>
              Look forward to receiving monthly updates on your progress against annual
              rebate set in the joint business plan in your inbox like the format below.
            </BodyOneSecondary>
            <Grid sx={{ maxHeight: '388px', padding: { xs: '6px', sm: '24px' }, background: 'rgba(0, 0, 0, 0.05)', borderRadius: '17px', marginBottom: '32px' }}>
              <img
                style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'cover' }}
                src={`${require('../assets/img/rebatescheme/chart.png')}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const styles: IStyles = {
  imageContainer: {
    aspectRatio: '1/1',
    maxHeight: '320px',
    maxWidth: '320px',
    width: '100%',
    objectFit: 'contain',
    marginLeft: 'auto'
  }
};
