// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '../scss/components/_carousel.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
interface IProps {
  services: IServiceByCategory[];
}
export function Carousel({ services }: IProps): JSX.Element {
  const [digitalAsset, setDigitalAsset] = useState<{
    ServiceId: GUID;
    ServiceName: string;
    Description: string;
    ImageUrl: string;
    CategoryId: GUID;
  } | undefined>();
  const [knowledgebase, setKnowledgebase] = useState<{
    ServiceId: GUID;
    ServiceName: string;
    Description: string;
    ImageUrl: string;
    CategoryId: GUID;
  } | undefined>();
  const navigate = useNavigate();
  useEffect(() => {
    const serviceList = services.map(m => m.Services).flat();
    setDigitalAsset(serviceList.find(f => f.ServiceName === 'Brand Assets'));
    setKnowledgebase(serviceList.find(f => f.ServiceName === 'Knowledge Base'));
  }, [services]);
  return (
    <Swiper
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      }}
      pagination={{
        clickable: true
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      className="swiper"
    >
      {/* -- Slide 1 -- */}
      <SwiperSlide className="swiper-slide swiper-slide--1 swiper-slide--text-only">
        <div className="swiper-slide-content">
        </div>
      </SwiperSlide>

      {/* -- Slide 2 -- */}
      {digitalAsset && <SwiperSlide onClick={() => navigate(`/service/${digitalAsset.ServiceId}`)} className="swiper-slide swiper-slide--2 is-link">
        <div className="swiper-slide-content">
        </div>
      </SwiperSlide>}

      {/* -- Slide 3 -- */}
      {knowledgebase && <SwiperSlide onClick={() => navigate(`/service/${knowledgebase.ServiceId}`)} className="swiper-slide swiper-slide--3 is-link">
        <div className="swiper-slide-content">
        </div>
      </SwiperSlide>}

    </Swiper>
  );
}
