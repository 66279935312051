/* eslint-disable @typescript-eslint/naming-convention */
import { Divider, Grid, Stack } from '@mui/material';
import { BodyOnePrimary, HeaderSix } from '../components-atoms/TypographyComponents';
import { ExternalRedirectButton } from '../components-molecules/ExternalRedirectButton';
import { IStyles } from '../constants/theme';

export function WebshopService(): JSX.Element {
  return (
    <Grid container item justifyContent="flex-start" sx={{ maxWidth: '1408px', width: '100%', padding: '0 24px', margin: '32px 0px' }}>
      <Grid item sm={3}>
        <Grid
          container
          justifyContent="center"
          item
          style={styles.imageContainer}>
          <img
            style={{ maxHeight: '100%', maxWidth: '100%', objectFit:'cover' }}
            src={`${require('../assets/img/webshop/paint-portal-2.png')}`}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={9} sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
        <Grid container sx={{ maxWidth: { sm: '672px' }, width: '100%', height: '100%' }} justifyContent="flex-start" alignItems="center">
          <HeaderSix>My Paints Portal</HeaderSix>
          <BodyOnePrimary style={{ marginBottom: '16px' }}>Choose the new Decorative Paints UK portal for all your decorating needs big or small. As well as products from all the leading brands, you can access a wide range of online ordering in account management features.</BodyOnePrimary>
          <Stack mb="44px" direction="row" gap={2}>
            <ExternalRedirectButton variant="outlined" url={'https://www.mypaintsportal.com/gb/en/customer/account/create/'}>Create Account</ExternalRedirectButton>
            <ExternalRedirectButton url={'https://www.mypaintsportal.com/gb/en/'}>Visit Portal</ExternalRedirectButton>
          </Stack>
          <Divider style={{ width: '100%' }} />
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles: IStyles = {
  imageContainer: {
    aspectRatio: '1/1',
    maxHeight: '320px',
    maxWidth: '320px',
    width: '100%',
    objectFit: 'contain',
    marginLeft: 'auto'
  }
};
