import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import Grid from '@mui/material/Grid';

import { ForgotFormTypes, FORGOT_FORM } from '../forms/forgot';
import { useTypedDispatch } from '../hooks/TypedReduxHooks';
import { PasswordResetUserMailRequest } from '../service/userService';

import { FormControlInput } from '../components-molecules/FormControl';
import { FormCard } from '../components-molecules/cards/FormCard';
import { BodyTwoSecondary, HeaderSix } from '../components-atoms/TypographyComponents';
import { ButtonContainedPrimary, ButtonRouteContainedPrimary, ButtonRouteTextPrimary } from '../components-atoms/ButtonComponents';
import { gaService } from '../service/gaService';

function ForgotSuccess({ email }: { email: string; }): JSX.Element {
  return (
    <FormCard
      rightButton={<ButtonRouteContainedPrimary fullWidth sx={{ my: 1 }} to="/signin" >Back to Sign In</ButtonRouteContainedPrimary>}
    >
      <Grid item sx={{ flexGrow: 1 }}>
        <HeaderSix>Forgot password?</HeaderSix>
        <BodyTwoSecondary sx={{ margin: '0 0 8px' }}>
          We have sent and email with a link to a form where you will
          be able to reset your password to the following email address:
        </BodyTwoSecondary>
        <BodyTwoSecondary sx={{ fontWeight: 700, margin: '0 0 8px' }}>{email}</BodyTwoSecondary>
      </Grid>
    </FormCard>
  );
}

export function Forgot(): JSX.Element {
  const { control, formState: { isValid }, handleSubmit, getValues, setError } = useForm({ mode: 'all', reValidateMode: 'onBlur' });

  const dispatch = useTypedDispatch();
  const [requestSuccess, setRequestSuccess] = useState(false);

  useEffect(() => {
    gaService.pageView('Forgot password?');
  }, []);

  const forgot: SubmitHandler<ForgotFormTypes> = (data): void => {
    PasswordResetUserMailRequest(
      { Email: data.email },
      () => setRequestSuccess(true),
      (error) => {
        if (error.response) {
          dispatch({ type: 'USER_ANY_FAILURE', payload: { error: error.response.data.Error } });
          setError('email', { message: error.response.data.Error, type: 'value' });
        }
      }
    );
  };

  return (
    requestSuccess
      ? <ForgotSuccess email={getValues('email')} />
      : <FormCard
        leftButton={<ButtonRouteTextPrimary fullWidth sx={{ my: 1 }} to="/signin">Back to Sign In</ButtonRouteTextPrimary>}
        rightButton={<ButtonContainedPrimary type="submit" fullWidth sx={{ my: 1 }} disabled={!isValid}>Submit email</ButtonContainedPrimary>}
        submit={handleSubmit(forgot)}
      >
        <Grid item sx={{ flexGrow: 1 }}>
          <HeaderSix>Forgot password?</HeaderSix>
          <BodyTwoSecondary sx={{ margin: '0 0 8px' }}>
            Enter email address you’ve used to sign up with
            Dulux Connect for Merchants below and we will send
            you a link to a form where you will be able to
            reset your password.
          </BodyTwoSecondary>
        </Grid>

        <Grid item sx={{ flexGrow: 1 }}>
          <FormControlInput control={control} field={FORGOT_FORM[0]} />
        </Grid>
      </FormCard>
  );
}
