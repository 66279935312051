import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BodyOneSecondary, CaptionPrimary, CaptionSecondary, HeaderFourSecondary, HeaderSix } from '../components-atoms/TypographyComponents';
import { Dashboard } from '../components-organisms/Dashboard';
import { Footer } from '../components-organisms/Footer';
import '../scss/components/_policy-pages.scss';
import { gaService } from '../service/gaService';

export function TermsAndConditions(): JSX.Element {
  const navigate = useNavigate();
 
  useEffect(() => {
    gaService.pageView('General Terms and Conditions');
  }, []);

  return (
    <Dashboard noDrawer noMargin noPadding fullWidth merchantPage>
      <Grid container direction="column" sx={{ width: '100%' }} className="policy-page">
        <Grid container justifyContent="center">
          <Grid direction="column" container item alignItems="center">
            <Grid sx={{ margin: '12px 0px 28px', maxWidth: '1408px', width: '100%', padding: '0 24px' }}>
              <CaptionPrimary style={{ color: 'rgba(1, 33, 105, 1)', cursor: 'pointer' }} onClick={() => navigate('/')}>Home</CaptionPrimary>
              <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
              <CaptionPrimary style={{ marginBottom: '4px' }}>General Terms and Conditions</CaptionPrimary>
            </Grid>
            <Grid
              container
              justifyContent="center"
              sx={{
                margin: '90px 0 32px',
                background: 'linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)'
              }}>
              <Grid
                container
                sx={{
                  maxWidth: '1408px',
                  width: '100%',
                  padding: '0 24px'
                }}>
                <HeaderFourSecondary style={{ maxWidth: '783px', width: '100%', marginBottom: '4px' }}>
                  General Terms and Conditions
                </HeaderFourSecondary>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ color: 'rgba(0, 0, 0, 0.54)', maxWidth: { sm: '672px' }, width: '100%', padding: '0 24px'  }}>
            <HeaderFourSecondary>
              Colour Match Legal Guidance Notes for AkzoNobel Paint-Mixing Stockists
            </HeaderFourSecondary>
            <p>&nbsp;</p>
            <HeaderSix>
              AkzoNobel Colours and Recipes
            </HeaderSix>
            <BodyOneSecondary>
              <ul>
                <li>
                  AkzoNobel has an extensive range of colours available for colour mixing the Dulux Tinting System.
                </li>
                <li>
                  AkzoNobel does not hold recipes for competitor colours on its tinting software. All recipes available are from the AkzoNobel database of colour recipes.
                </li>
                <li>
                  AkzoNobel does not advertise, support or otherwise encourage the “holding of competitor colours” in any database.
                </li>
              </ul>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Scanning Colours
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                Dulux colour matching provides a colour that closely matches or represents this colour:
              </p>
              <ul>
                <li>
                  By calculating a bespoke colour recipe to give as accurate match as possible.
                </li>
                <li>
                  Or by returning a nearest colour from our AkzoNobel Colour Collection Database, if a bespoke colour cannot be calculated.
                </li>
              </ul>
              <p>
                These matches will be shown on screen as an ‘excellent, good or moderate match’.
              </p>
              <p>
                In all cases customer should be encouraged to purchase a colour sampler to ensure the colour match meets their requirements.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Saving Colours for Customers
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                Stockists can save a colour match (bespoke or Collection) against a customer name on Dulux Mix2Win Next software for future use.
              </p>
              <p>
                Should a Stockist wish to hold frequently used colours, they can do so, but must develop their own record system that does not include Competitor Brands or Names. Noting the following:
              </p>
              <ul>
                <li>
                  The Stockist shall not use any competitor trade marks (Brand, Colour Name, acronym or any obvious reference) on Dulux tinting machine software, display screens, labels or receipts for colour matched paint.
                </li>
                <li>
                  The Stockist shall not represent that colour matched paint is equivalent or the same as any competitor paint brand or colour name.
                </li>
                <li>
                  The Stockist must always make it clear that the scan is a nearest match, not identical and that the paint product is not equivalent to any Competitor Brand.
                </li>
              </ul>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderFourSecondary>
              Dulux Promise Terms and Conditions
            </HeaderFourSecondary>
            <BodyOneSecondary>
              <p>
                We promise our paint will give you the perfect colour with a uniform finish and the coverage stated, or we’ll replace it.
              </p>
              <ol>
                <li>
                  The Dulux Promise (the “Promise”) entitles a customer who has purchased Dulux; Matt, Silk or Soft Sheen, or EasyCare Matt, Kitchen or Bathroom, or Dulux Trade; Vinyl Matt, Silk or Soft Sheen, Flat Matt, Diamond Matt or Diamond Eggshell (500ml and larger packs only) or Dulux or Dulux Trade product with the Dulux Promise logo on-pack in the UK (“Selected Product”) who believes that their Selected Product hasn’t delivered the perfect colour, a uniform finish or the coverage stated, to a voucher to replace the Selected Product.
                </li>
                <li>
                  The Promise is available to private individuals and sole traders aged 16 and above that are resident in the United Kingdom (including Northern Ireland, the Channel Islands and the Isle of Man) and to all partnerships and companies with their registered office address in the United Kingdom (the “Participants”). The Promise is not available to employees of the AkzoNobel group of companies, their agents, wholesalers, resellers or retailers. Resellers and retailers may not submit claims on behalf of their customers.
                </li>
                <li>
                  The Promise covers the replacement of the Selected Product only and excludes any claim for labour or other costs in respect of the original or replacement product.
                </li>
                <li>
                  Claims can only be made by the person that purchased the Selected Product.
                </li>
                <li>
                  The Selected Product must have been applied on properly prepared surfaces and in accordance with the manufacturer’s tips and instructions (including those on-pack, datasheet and colour label instructions).
                </li>
                <li>
                  Claims must be submitted by contacting the AkzoNobel Customer Advice centre on either 0333 222 7070 (Dulux Trade) or 0333 222 7171 (Dulux Retail), via email to duluxpromise@akzonobel.com or post to AkzoNobel Customer Advice Centre, The AkzoNobel Building, Wexham Road, Slough, SL2 5DS, no later than 6 months after the purchase of the Selected Product. You will be required to:
                </li>
                <ul>
                  <li>
                    Provide a clear copy of your itemised receipt or invoice showing the details of the Selected Product that you have purchased, the date of the purchase and the price paid for the Selected Product;
                  </li>
                  <li>
                    Provide a description of the issue with the colour, the finish or the coverage stated of the Selected Product;
                  </li>
                  <li>
                    Provide the postcode of the address at which the Selected Product has been applied;
                  </li>
                  <li>
                    Provide a photograph of the wall(s) painted with the Selected Product.
                  </li>
                  <li>
                    Provide the batch number that is printed on the can
                  </li>
                </ul>
                <li>
                  Provided that the Selected Product has been applied on properly prepared surfaces and in accordance with the manufacturer’s tips and instructions (including those on-pack, datasheet and colour label instructions), the claim will be deemed valid if the description of the issue and application photo demonstrate that the Selected Product is not the perfect colour or is not a uniform finish or has not provided the coverage stated on the pack.
                </li>
                <li>
                  We may send a representative to assess the painted area in question to verify that the claim is based on a bona fide purchase of a Selected Product and to ensure that the Selected Product and its application meets the conditions required for the Promise to apply.
                </li>
                <li>
                  Upon validation of a claim, a voucher for the replacement product will be sent to you by post within 28 days. You can redeem your voucher for a replacement for the Selected Product at any participating store before the voucher expiration date. Replacement products are subject to availability. Full voucher terms and conditions apply, see voucher for details. Participating stores include: B&Q, Homebase, Dulux Decorator Centres, Brewers, TP, Jewson and most independent paint retailers (please note list is correct at time of publishing but remains subject to change).
                </li>
                <li>
                  The voucher is issued for the recommended product to replace the Selected Product that was deemed unsatisfactory.
                </li>
                <li>
                  For a claim related to colour not being the colour you like, the replacement product cannot be in the same colour as the Selected Product which is the subject of the claim, and the Promise will not apply to the replacement colour. Replacement product for claims relating to colour are limited to 10L per claim and per customer claiming.
                </li>
                <li>
                  We reserve the right to reject claim if we have reason to believe that they are fraudulent or that there has been a violation of any of these Terms and Conditions (“T&Cs”).
                </li>
                <li>
                  We accept no responsibility for claims that are incomplete, illegible, damaged or lost. Proof of submission of a claim is not proof of receipt.
                </li>
                <li>
                  It is not necessary for customers to return the unsatisfactory product (unless we specifically request it in which case we shall arrange and pay for carriage). Returns will not be accepted. Disposal of any product is the responsibility of the customer.
                </li>
                <li>
                  No third party or joint submissions will be accepted.
                </li>
                <li>
                  The Promise does not cover problems that were caused due to reasons outside of our control (for an example an issue with the substrate) or where the Selected Product was applied on surfaces which were not properly prepared or applied otherwise than in accordance with the manufacturer’s tips and instructions.
                </li>
                <li>
                  Claims not made in accordance with these T&Cs will be deemed invalid. If a claim is refused because the terms of the Promise have not been met, AkzoNobel’s decision is final.
                </li>
                <li>
                  If any provision in these Terms and Conditions (“T&Cs”) is found in any court or competent authority to be invalid, unlawful or unenforceable, that provision shall be deemed not to be part of the T&Cs and it shall not affect the enforceability of the rest of the provisions of the T&Cs.
                </li>
                <li>
                  We reserve the right to withdraw, amend or terminate the Promise without notice. All claims made in accordance with these T&Cs and made prior to the Promise being withdrawn, amended or terminated will still be honoured.
                </li>
                <li>
                  The voucher has no cash value, is not transferable or assignable and cannot be used in conjunction with any other offers or promotions. No cash or alternative to the voucher will be provided.
                </li>
                <li>
                  AkzoNobel shall not be liable for any:
                </li>
                <ul>
                  <li>
                    Conditions, warranties or other terms which are not included in the Promise or these T&Cs; or
                  </li>
                  <li>
                    Any indirect or consequential loss, damage or costs incurred by any customer in connection with the Selected Product or this Promise, or the cost of any labour for the application of the Selected Product.
                  </li>
                </ul>
                <li>
                  These exclusions do not exclude or restrict liability for death or personal injury resulting from the negligence of AkzoNobel, or its employees or agents or anything else that the law says we cannot exclude.
                </li>
                <li>
                  The Promise does not affect your statutory rights.
                </li>
                <li>Data will be held in accordance with our Privacy Policy.
                </li>
                <li>
                  Please retain these T&Cs for future reference.
                </li>
                <li>
                  This Promise is governed by the laws of England and Wales and is subject to the exclusive jurisdiction of the courts of England and Wales.
                </li>
                <li>
                  In these terms and conditions “We” and “AkzoNobel” mean Imperial Chemical Industries Limited trading as ICI Paints AkzoNobel.
                </li>
              </ol>
              <p>
                For all Promise related correspondence, please contacts us as per methods detailed in clause 6 above.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderFourSecondary>
              Dulux Weathershield Promise
            </HeaderFourSecondary>
            <BodyOneSecondary>
              <p>
                We promise our paint will give you the perfect colour with a uniform finish and the coverage stated, or we’ll replace it.
              </p>
              <ol>
                <li>
                  The Dulux Promise (the “Promise”) entitles a customer who has purchased Dulux; Matt, Silk or Soft Sheen, or EasyCare Matt, Kitchen or Bathroom, or Dulux Trade; Vinyl Matt, Silk or Soft Sheen, Flat Matt, Diamond Matt or Diamond Eggshell (500ml and larger packs only) or Dulux or Dulux Trade product with the Dulux Promise logo on-pack in the UK (“Selected Product”) who believes that their Selected Product hasn’t delivered the perfect colour, a uniform finish or the coverage stated, to a voucher to replace the Selected Product.
                </li>
                <li>
                  The Promise is available to private individuals and sole traders aged 16 and above that are resident in the United Kingdom (including Northern Ireland, the Channel Islands and the Isle of Man) and to all partnerships and companies with their registered office address in the United Kingdom (the “Participants”). The Promise is not available to employees of the AkzoNobel group of companies, their agents, wholesalers, resellers or retailers. Resellers and retailers may not submit claims on behalf of their customers.
                </li>
                <li>
                  The Promise covers the replacement of the Selected Product only and excludes any claim for labour or other costs in respect of the original or replacement product.
                </li>
                <li>
                  Claims can only be made by the person that purchased the Selected Product.
                </li>
                <li>
                  The Selected Product must have been applied on properly prepared surfaces and in accordance with the manufacturer’s tips and instructions (including those on-pack, datasheet and colour label instructions).
                </li>
                <li>
                  Claims must be submitted by contacting the AkzoNobel Customer Advice centre on either 0333 222 7070 (Dulux Trade) or 0333 222 7171 (Dulux Retail), via email to duluxpromise@akzonobel.com or post to AkzoNobel Customer Advice Centre, The AkzoNobel Building, Wexham Road, Slough, SL2 5DS, no later than 6 months after the purchase of the Selected Product. You will be required to:
                </li>
                <ul>
                  <li>
                    Provide a clear copy of your itemised receipt or invoice showing the details of the Selected Product that you have purchased, the date of the purchase and the price paid for the Selected Product;
                  </li>
                  <li>
                    Provide a description of the issue with the colour, the finish or the coverage stated of the Selected Product;
                  </li>
                  <li>
                    Provide the postcode of the address at which the Selected Product has been applied;
                  </li>
                  <li>
                    Provide a photograph of the wall(s) painted with the Selected Product.
                  </li>
                  <li>
                    Provide the batch number that is printed on the can
                  </li>
                </ul>
                <li>
                  Provided that the Selected Product has been applied on properly prepared surfaces and in accordance with the manufacturer’s tips and instructions (including those on-pack, datasheet and colour label instructions), the claim will be deemed valid if the description of the issue and application photo demonstrate that the Selected Product is not the perfect colour or is not a uniform finish or has not provided the coverage stated on the pack.
                </li>
                <li>
                  We may send a representative to assess the painted area in question to verify that the claim is based on a bona fide purchase of a Selected Product and to ensure that the Selected Product and its application meets the conditions required for the Promise to apply.
                </li>
                <li>
                  Upon validation of a claim, a voucher for the replacement product will be sent to you by post within 28 days. You can redeem your voucher for a replacement for the Selected Product at any participating store before the voucher expiration date. Replacement products are subject to availability. Full voucher terms and conditions apply, see voucher for details. Participating stores include: B&Q, Homebase, Dulux Decorator Centres, Brewers, TP, Jewson and most independent paint retailers (please note list is correct at time of publishing but remains subject to change).
                </li>
                <li>
                  The voucher is issued for the recommended product to replace the Selected Product that was deemed unsatisfactory.
                </li>
                <li>
                  For a claim related to colour not being the colour you like, the replacement product cannot be in the same colour as the Selected Product which is the subject of the claim, and the Promise will not apply to the replacement colour. Replacement product for claims relating to colour are limited to 10L per claim and per customer claiming.
                </li>
                <li>
                  We reserve the right to reject claim if we have reason to believe that they are fraudulent or that there has been a violation of any of these Terms and Conditions (“T&Cs”).
                </li>
                <li>
                  We accept no responsibility for claims that are incomplete, illegible, damaged or lost. Proof of submission of a claim is not proof of receipt.
                </li>
                <li>
                  It is not necessary for customers to return the unsatisfactory product (unless we specifically request it in which case we shall arrange and pay for carriage). Returns will not be accepted. Disposal of any product is the responsibility of the customer.
                </li>
                <li>
                  No third party or joint submissions will be accepted.
                </li>
                <li>
                  The Promise does not cover problems that were caused due to reasons outside of our control (for an example an issue with the substrate) or where the Selected Product was applied on surfaces which were not properly prepared or applied otherwise than in accordance with the manufacturer’s tips and instructions.
                </li>
                <li>
                  Claims not made in accordance with these T&Cs will be deemed invalid. If a claim is refused because the terms of the Promise have not been met, AkzoNobel’s decision is final.
                </li>
                <li>
                  If any provision in these Terms and Conditions (“T&Cs”) is found in any court or competent authority to be invalid, unlawful or unenforceable, that provision shall be deemed not to be part of the T&Cs and it shall not affect the enforceability of the rest of the provisions of the T&Cs.
                </li>
                <li>
                  We reserve the right to withdraw, amend or terminate the Promise without notice. All claims made in accordance with these T&Cs and made prior to the Promise being withdrawn, amended or terminated will still be honoured.
                </li>
                <li>
                  The voucher has no cash value, is not transferable or assignable and cannot be used in conjunction with any other offers or promotions. No cash or alternative to the voucher will be provided.
                </li>
                <li>
                  AkzoNobel shall not be liable for any:
                </li>
                <ul>
                  <li>
                    Conditions, warranties or other terms which are not included in the Promise or these T&Cs; or
                  </li>
                  <li>
                    Any indirect or consequential loss, damage or costs incurred by any customer in connection with the Selected Product or this Promise, or the cost of any labour for the application of the Selected Product.
                  </li>
                </ul>
                <li>
                  These exclusions do not exclude or restrict liability for death or personal injury resulting from the negligence of AkzoNobel, or its employees or agents or anything else that the law says we cannot exclude.
                </li>
                <li>
                  The Promise does not affect your statutory rights.
                </li>
                <li>
                  Data will be held in accordance with our Privacy Policy.
                </li>
                <li>
                  Please retain these T&Cs for future reference.
                </li>
                <li>
                  This Promise is governed by the laws of England and Wales and is subject to the exclusive jurisdiction of the courts of England and Wales.
                </li>
                <li>
                  In these terms and conditions “We” and “AkzoNobel” mean Imperial Chemical Industries Limited trading as ICI Paints AkzoNobel.
                </li>
              </ol>
              <p>
                For all Promise related correspondence, please contacts us as per methods detailed in clause 6 above.
              </p>
            </BodyOneSecondary>
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{
        background: 'linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Grid item sx={{ maxWidth: '1408px', width: '100%' }}>
          <Footer />
        </Grid>
      </Grid>
    </Dashboard>
  );
}
