import { Link as RouterLink } from 'react-router-dom';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { RoundCardComponent } from '../../components-atoms/CardComponents';
import { SxProps } from '@mui/material';

interface SectionComponentProps {
  children: null | JSX.Element | (JSX.Element | null)[];
  fullPadding?: boolean;
  to?: string;
  showOverflowingChild?: boolean;
  applyMarginBottom?: boolean;
  customSx?: SxProps<any>
}

export function Section({ children, fullPadding, to, showOverflowingChild, applyMarginBottom, customSx }: SectionComponentProps): JSX.Element {
  const sectionChildElement = (<RoundCardComponent
    variant='outlined'
    sx={{
      width: '100%',
      padding: fullPadding ? '24px' : '24px 24px 0 24px',
      background: 'transparent',
      marginBottom:applyMarginBottom ? '72px':undefined,
      ...customSx
    }}
  >
    {children}
  </RoundCardComponent>);

  const element = (to)
    ? <Button fullWidth variant="text" component={RouterLink} to={to} sx={{ margin: '24px 0 0', padding: '0', borderRadius: '20px', width:'100%' }}>
      {sectionChildElement}
    </Button>
    : <Box sx={{ margin: '24px 0 0', padding: '0', borderRadius: '20px', width:'100%', '.MuiPaper-root': { overflow: showOverflowingChild ? 'inherit' : 'hidden' } }}>
      {sectionChildElement}
    </Box>;

  return element;
}

