import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import { OverlineSecondary } from '../../components-atoms/TypographyComponents';
import { Variant } from '@mui/material/styles/createTypography';

interface SectionRowComponentProps {
  title?: string;
  lines: ({ data: string; type: Variant; color?: 'text.primary' | 'text.secondary' | 'error.main'; style?: React.CSSProperties } | { element: JSX.Element; })[];
  hasChevron?: boolean;
  onChevronClick?: () => void;
  endIcon?: JSX.Element;
}

export function SectionRow({ title, lines, hasChevron, onChevronClick, endIcon }: SectionRowComponentProps): JSX.Element {
  return (
    <Grid container flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" flexWrap={{ xs: 'nowrap', sm: 'unset' }} sx={{ flexShrink: 1, flexGrow: 1, minHeight: '80px' }}>
      {title
        ? <Grid item sx={{ width: { xs: '100%', sm: '164px' }, padding: { xs: '16px 0 0', sm: '0' }, flexShrink: 0 }}>
          <OverlineSecondary>{title}</OverlineSecondary>
        </Grid>
        : null
      }

      <Grid container item alignItems="center">
        <Grid item container rowGap="4px" justifyContent="space-between" sx={{ flexFlow: 'column', flexGrow: 1, padding: { xs: '0 0 16px', sm: '16px 0' }, width: '164px', minHeight: `${lines.length * 24}px`, wordBreak: 'break-word' }}>
          {lines.map((line, index) =>
            ('element' in line)
              ? line.element
              : <Grid item key={index}>
                <Typography variant={line.type} sx={{ color: line.color, ...line.style }}>{line.data}</Typography>
              </Grid>
          )}
        </Grid>
        {endIcon
          ? <Grid item sx={{ width: 'auto' }} >
            {endIcon}
          </Grid>
          : null
        }
        {hasChevron
          ? <Grid item sx={{ width: 'auto' }} >
            <IconButton onClick={onChevronClick}>
              <ChevronRightIcon />
            </IconButton>
          </Grid>
          : null
        }
      </Grid>
    </Grid>
  );
}
