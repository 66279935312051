/* eslint-disable @typescript-eslint/naming-convention */
import { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormHelperText, Grid, LinearProgress } from '@mui/material';
import { MerchantSetup, MerchantSetupDetail, MerchantSetupDetailResponseModel } from '../../service/serviceService';
import { BodyOneSecondary } from '../../components-atoms/TypographyComponents';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { FormControlImage, FormControlInput } from '../../components-molecules/FormControl';
import { ModalCardComponent, ModalComponent } from '../../components-molecules/Modal';
import { ADD_MERCHANT_FORM } from '../../forms/addMerchant';
import { environment } from '../../environment';
import { IStyles } from '../../constants/theme';
import { UploadToAzure } from '../../hooks/uploadToAzure';

interface AddMerchant {
  logo: File;
  name: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postCode: string;
  county: string;
  phone: string;
  email: string;
}

interface onProgressResponse {
  fileName: string;
  percentage: number;
}

interface IAddMerchantDetailsProps {
  merchantId: GUID;
  isOpen: boolean;
  modalCallback: () => void;
}

interface DefaultValue {
  [key: string]: string | undefined;
}

export function AddMerchantDetails({ merchantId, isOpen, modalCallback }: IAddMerchantDetailsProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [merchantDetails, setMerchantDetails] = useState<MerchantSetupDetailResponseModel>();
  const [loadingProgress, setLoadingProgress] = useState<onProgressResponse[]>([]);
  const [percentage, setPercentage] = useState(0);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<DefaultValue>();

  useEffect(() => {
    calculatePercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProgress]);

  useEffect(() => {
    if (merchantId) {
      MerchantSetupDetail(
        merchantId,
        (response) => setMerchantDetails(response.Result),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  }, [merchantId]);

  const { control, handleSubmit, setError, formState: { isValid, errors } } = useForm<any>({
    mode: 'all',
    reValidateMode: 'onBlur'
  });

  //TODO Find a better way
  useLayoutEffect(() => {
    if (merchantDetails) {
      setDefaultValues({
        logo: merchantDetails.Logo || '',
        payerCode: merchantDetails.PayerCode || '',
        name: merchantDetails.Name || '',
        addressLine1: merchantDetails.AddressLine1 || '',
        addressLine2: merchantDetails.AddressLine2,
        city: merchantDetails.City || '',
        postCode: merchantDetails.Postcode || '',
        county: merchantDetails.County || '',
        phone: merchantDetails.Phone || '',
        email: merchantDetails.Email || ''
      });
    }
  }, [merchantDetails]);

  const submit: SubmitHandler<AddMerchant> = async (data) => {
    setLoading(true);
    setScrollToTop(true);
    if (data.logo) {

      const merchantLogo = await UploadToAzure({
        data: [data.logo],
        onProgress: onProgress,
        folderName: environment.merchantFolder
      });

      const imageUrl = merchantLogo[0].Url;

      MerchantSetup(
        {
          Id: merchantId,
          Logo: imageUrl,
          Name: data.name,
          AddressLine1: data.addressLine1,
          AddressLine2: data.addressLine2,
          City: data.city,
          Postcode: data.postCode,
          County: data.county,
          Phone: data.phone,
          Email: data.email
        },
        () => {
          setLoading(false);
          setScrollToTop(false);
          modalCallback();
        },
        (error) => {
          setLoading(false);
          setScrollToTop(false);
          setError('name', { message: error.response?.data.Error });
        }
      );
    }
  };

  const onProgress = (data: onProgressResponse) => {
    const result = loadingProgress?.find(x => data.fileName === x.fileName);
    let tempLoadingProcesses = loadingProgress;

    if (result !== undefined) {
      tempLoadingProcesses= tempLoadingProcesses?.map(x => {
        if (x.fileName === data.fileName) {
          return {...x, percentage: data.percentage};
        } else {
          return x;
        }
      });
    } else {
      tempLoadingProcesses?.push(data);
    }
    setLoadingProgress(tempLoadingProcesses);
  };

  const calculatePercentage = () => {
    let total = 0;
    loadingProgress.forEach(x=>{
      total += x.percentage / loadingProgress.length;
    });
    setPercentage(Math.round(total));
  };

  return (
    <ModalComponent
      hasError={errors.name}
      title="Connect for Merchants"
      buttonTitle="Submit"
      buttonDisabled={!isValid || loading}
      isOpen={isOpen}
      submit={handleSubmit(submit)}
      buttonLoading={loading}
      scrollToTop={scrollToTop}
    >
      {loading ? (
        <LinearProgress variant="determinate" color="secondary" value={percentage}  sx={{ zIndex: 2 }} />
      ) : (
        <></>
      )}
      <Fragment>
        <BodyOneSecondary sx={{ maxWidth: '840px', width: '100%', margin: '0 auto', paddingTop: '24px' }}>
          Fill in organisation information below. You can edit this information later from the user dropdown at the top right corner of the page under Organisation Settings.
        </BodyOneSecondary>
        <BodyOneSecondary sx={{ maxWidth: '840px', width: '100%', margin: '0 auto', paddingTop: '24px' }}>
          Your existing stores have been imported and you can find or edit the details in the Organisation Settings section mentioned above.
        </BodyOneSecondary>
        {defaultValues ? ADD_MERCHANT_FORM.map(section => {
          return (
            <ModalCardComponent key={section.title}>
              <SectionHeader title={section.title} />
              <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
                {section.fields.map(field =>
                  <Grid key={field.name} item xs={field.name === 'city' || field.name === 'postCode' ? 6 : 12}>
                    {field.inputType === 'logo'
                      ? <FormControlImage initialValue={defaultValues.logo} accept='image/*' subtitle='Square image with maximum file size up to 2 MB works best.' control={control} field={field} removeHelperTextMarginLeft />
                      : <FormControlInput control={control} field={field} disabled={field.name === 'payerCode'} defaultValue={defaultValues[field.name]} />
                    }
                    {field.helperText 
                      && <FormHelperText sx={{ padding: '0 14px' }}>
                        {field.helperText}
                      </FormHelperText>
                    }
                  </Grid>
                )}
              </Grid>
            </ModalCardComponent>
          );
        }) : null}
      </Fragment>
      {loading ? (
        <div style={styles.overlay}>
        </div>
      ) : (
        <></>
      )}
    </ModalComponent>
  );
}

const styles: IStyles = {
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  }
};
