/* eslint-disable @typescript-eslint/naming-convention */
import { CSSProperties, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { BodyTwoSecondary, CaptionPrimary, HeaderSix } from '../../components-atoms/TypographyComponents';
import { ButtonTextPrimary } from '../../components-atoms/ButtonComponents';
import { IStyles } from '../../constants/theme';


export function SectionHeaderMenuButton({ handleClick, sx }: { handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void; sx?: CSSProperties | undefined; }): JSX.Element {
  return (
    <IconButton sx={{ margin: '0 -18px 0 0', ...sx }} onClick={handleClick}>
      <MoreVertIcon />
    </IconButton>
  );
}

interface SectionHeaderMenuComponentProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClose: () => void;
  onDelete?: () => void;
  onUnpublish?: () => void;
  onPublish?: () => void;
  onEdit?: () => void;
  noIcon?: boolean;
  minWidth?: string;
}

export function SectionHeaderMenu({ anchorEl, open, handleClose, onDelete, onEdit, onUnpublish, onPublish, noIcon, minWidth }: SectionHeaderMenuComponentProps): JSX.Element {
  const mw = minWidth ? minWidth : undefined;

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      {onEdit
        && <MenuItem onClick={() => { onEdit(); handleClose(); }} sx={{ minWidth: mw }}>
          {!noIcon && <ListItemIcon><Edit fontSize="small" /></ListItemIcon>}
          <ListItemText>Edit</ListItemText>
        </MenuItem>}

      {onUnpublish
        && <MenuItem onClick={() => { onUnpublish(); handleClose(); }} sx={{ minWidth: mw }}>
          {!noIcon && <ListItemIcon><Delete fontSize="small" /></ListItemIcon>}
          <ListItemText>Unpublish</ListItemText>
        </MenuItem>}

      {onPublish
        && <MenuItem onClick={() => { onPublish(); handleClose(); }} sx={{ minWidth: mw }}>
          {!noIcon && <ListItemIcon><Delete fontSize="small" /></ListItemIcon>}
          <ListItemText>Publish</ListItemText>
        </MenuItem>}

      {onDelete
        && <MenuItem onClick={() => { onDelete(); handleClose(); }} sx={{ minWidth: mw }}>
          {!noIcon && <ListItemIcon><Delete fontSize="small" /></ListItemIcon>}
          <ListItemText sx={{ color: 'error.main' }}>Delete</ListItemText>
        </MenuItem>
      }
    </Menu>
  );
}

interface SectionHeaderComponentProps {
  title?: string;
  subtitle?: string;
  label?: string;
  errorMessage?: string;
  hasMenuIcon?: boolean;
  editable?: boolean;
  button?: JSX.Element;
  onDelete?: () => void;
  onEdit?: () => void;
  fullWidthHeader?: boolean;
}

export function SectionHeader({ title, subtitle, label, hasMenuIcon, editable, button, onDelete, onEdit, errorMessage, fullWidthHeader }: SectionHeaderComponentProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <Grid container justifyContent="space-between" alignItems="center" sx={{ flexShrink: 1, flexGrow: 1, margin: '2px 0 10px 0' }}>
      <Grid item sx={{ maxWidth: fullWidthHeader ? 'unset' : '75%' }}>
        <HeaderSix sx={{ margin: 0, display: 'flex' }}>
          {title}
          {label && (
            <Grid style={styles.stampContainer}>
              <CaptionPrimary style={styles.stampText}>{label}</CaptionPrimary>
            </Grid>
          )}
        </HeaderSix>
        {subtitle ? <BodyTwoSecondary sx={{ margin: '12px 0 0' }}>{subtitle}</BodyTwoSecondary> : null}
        {errorMessage ? <BodyTwoSecondary sx={{ margin: '12px 0 0', color: 'red' }}>{errorMessage}</BodyTwoSecondary> : null}
      </Grid>

      {hasMenuIcon ? <Grid item><SectionHeaderMenuButton handleClick={handleClick} /></Grid> : null}

      {editable ? <ButtonTextPrimary startIcon={<Edit />}>Edit</ButtonTextPrimary> : null}

      {button ? button : null}

      <SectionHeaderMenu anchorEl={anchorEl} open={open} handleClose={handleClose} onEdit={onEdit} onDelete={onDelete} />
    </Grid>
  );
}

const styles: IStyles = {
  stampContainer: {
    borderRadius: '4px',
    backgroundColor: '#ED6C0214',
    padding: '0px 8px',
    display: 'flex',
    alignItems: 'center',
    maxHeight: '24px',
    marginLeft: '8px',
    marginTop: '4px',
    minHeight: '24px',
    width: 'fit-content'
  },
  stampText: {
    color: '#ED6C02'
  }
};
