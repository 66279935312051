import { Grid } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ButtonOutlinedPrimary } from '../../components-atoms/ButtonComponents';
import { BodyOnePrimary, BodyTwoSecondary } from '../../components-atoms/TypographyComponents';
import { FormControlImage, FormControlSelect } from '../../components-molecules/FormControl';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { FILE_VALIDATION_WITH_TYPE, REQUIRED_VALIDATION } from '../../forms/_predefinedValidations';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { AssetStatusButtonGroup } from '../AssetStatusButtonGroup';
import IndustryEventsRegisteredUsers from '../IndustryEventsRegisteredUsers';
import { EditIndustryEventRequest, IIIndustryEventTargetsResponse, IndustryEventTargets } from '../../service/serviceService';
import { UploadToAzure } from '../../hooks/uploadToAzure';
import { environment } from '../../environment';
import placeholderImage from '../../assets/img/industry-events/industry_event_placeholder.png';

interface IOption {
  title: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  value: boolean;
}

interface EditIndustryEvent {
  firstName: string;
  lastName: string;
  phone: string;
  roleId: GUID;
}

const STATUS_OPTIONS: IOption[] = [
  { title: 'Published', color: 'success', value: true },
  { title: 'Unpublished', color: 'error', value: false }
];

interface OnProgressResponse {
  fileName: string;
  percentage: number;
}

export function EditIndustryEvent(): JSX.Element {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [loadingProgress, setLoadingProgress] = useState<OnProgressResponse[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [token, event] = useTypedSelector(state => [state.userReducer.token, state.industryEventReducer.currentData]);
  const [targets, setTargets] = useState<IIIndustryEventTargetsResponse[]>([]);
  const { control, handleSubmit, formState: { errors, isValid, isSubmitted }, getValues, setValue } = useForm({ mode: 'all', reValidateMode: 'onChange' });

  useEffect(() => {
    setValue('Published', event.IsPublished, { shouldDirty: true, shouldValidate: true });
  }, [setValue, event]);

  const getTargets = () => {
    if (!token) return;
    IndustryEventTargets(
      token,
      (res) => {
        setTargets(res.Result);
        const selected = res.Result.find(t => t.Name === event.TargetUsers);
        setValue('targetUsers', selected?.Key, { shouldDirty: true, shouldValidate: true });
      },
      () => { /** */ }
    );
  };

  useEffect(() => {
    getTargets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditDetails = () => {
    if (token) window.open(event.EditUrl + '/edit', '_blank');
  };

  const onProgress = (data: OnProgressResponse) => {
    const result = loadingProgress?.find(x => data.fileName === x.fileName);
    let tempLoadingProcesses = loadingProgress;

    if (result !== undefined) {
      tempLoadingProcesses = tempLoadingProcesses?.map(x => {
        if (x.fileName === data.fileName) {
          return { ...x, percentage: data.percentage };
        } else {
          return x;
        }
      });
    } else {
      tempLoadingProcesses?.push(data);
    }
    setLoadingProgress(tempLoadingProcesses);
  };

  const submit: SubmitHandler<any> = async (data) => {
    if (token) {
      let fileUrl = '';
      setLoading(true);
      setScrollToTop(true);

      if (data.image.size > 0) {
        const fileName = await UploadToAzure({
          data: [data.image],
          onProgress: onProgress,
          folderName: environment.industryEventsFolder
        });

        fileUrl = fileName[0].Url;
      }

      EditIndustryEventRequest(
        token,
        {
          Id: event.Id,
          ImageUrl: fileUrl.length > 0 ? fileUrl : event.ThumbnailUrl?.split('/').pop(),
          IsPublished: getValues('Published'),
          Target: data.targetUsers
        },
        () => {
          setLoading(false);
          navigate(-1);
        },
        (error) => {
          setErrorMessage(error.response?.data.Error || '');
          setLoading(false);
          setScrollToTop(false);
        }
      );
    }
  };

  return (
    <FormPageComponent
      buttonLoading={loading}
      title="Edit Industry Event"
      buttonTitle="Submit"
      hasError={!!errorMessage && isSubmitted && ('email' in errors)}
      buttonDisabled={loading || !isValid}
      close={() => navigate(-1)}
      submit={handleSubmit(submit)}
      scrollToTop={scrollToTop}
    >
      <Fragment>
        <ModalCardComponent>
          <SectionHeader title="Event details" />
          <Fragment>
            <Grid item container sx={{ flexGrow: 1 }}>
              <Grid item xs={12} container columns={12} justifyContent="space-between">
                <Grid item xs={9}>
                  <BodyOnePrimary>Event Name, Date & Password</BodyOnePrimary>
                  <BodyTwoSecondary>Edit event name, scheduled date and password on external page.</BodyTwoSecondary>
                </Grid>
                <Grid item xs={2}>
                  <ButtonOutlinedPrimary onClick={onEditDetails} sx={{ margin: '0 0 0 8px' }}>Edit Details</ButtonOutlinedPrimary>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormControlImage
                  accept="image/*"
                  data-testid="edit-event-image"
                  control={control}
                  initialValue={event.ThumbnailUrl || placeholderImage}
                  initialFileType='image'
                  field={{
                    label: 'Event Image',
                    name: 'image',
                    inputType: 'logo',
                    validation: {
                      required: REQUIRED_VALIDATION,
                      validate: FILE_VALIDATION_WITH_TYPE(5, 'image/*')
                    }
                  }}
                  title='Event Image'
                  subtitle="Square image works best. Maximum file size 5 MB."
                />
              </Grid>
              <Grid item xs={12} sx={{ margin: '32px 0 0' }}>
                <AssetStatusButtonGroup
                  subtitle="Published events are visible to merchants, territory and sales managers. Unpublished events are hidden."
                  title="Event Status"
                  data-testid="edit-event-published"
                  value={getValues('Published') || event.IsPublished}
                  onChange={(value: boolean) => setValue('Published', value, { shouldDirty: true, shouldValidate: true })}
                  options={STATUS_OPTIONS}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <FormControlSelect
                    data-testid="edit-event-target-users"
                    control={control}
                    field={{
                      label: 'Target Users',
                      name: 'targetUsers',
                      inputType: 'select',
                      options: targets.map(t => ({ name: t.Name, value: t.Key })),
                      validation: {
                        required: REQUIRED_VALIDATION
                      }
                    }} />
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        </ModalCardComponent>
        <IndustryEventsRegisteredUsers id={event.Id} />
      </Fragment>
    </FormPageComponent>
  );
}
