/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useState } from 'react';
import {
  useMediaQuery,
  CircularProgress,
  Grid,
  Box,
  Typography,
  Stack,
} from '@mui/material';
import { LIGHT_THEME } from '../../constants/theme';
import { FocusedContentListCard } from '../cards/FocusedContentListCard';
import {
  useTypedDispatch,
  useTypedSelector,
} from '../../hooks/TypedReduxHooks';
import {
  DeleteRequest,
  ListRequest,
  ReOrderRequest,
} from '../../service/focusedContentService';
import { MiniDialog } from '../../components-molecules/dialogs/MiniDialog';
import {
  BodyOneSecondary,
  CaptionSecondary,
} from '../../components-atoms/TypographyComponents';
import { PageHeader } from '../../components-molecules/titlebars/PageHeader';
import { NoItems } from '../../components-molecules/NoItems';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { TabPanel } from '@mui/lab';

export function FocusedContentList(): JSX.Element {
  const token = useTypedSelector((state) => state.userReducer.token);
  const [focusedContents, setFocusedContents] = useState<FocusedContent[]>([]);

  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const mdDown = useMediaQuery(LIGHT_THEME.breakpoints.down('md'));
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));

  const getFocusedContents = useCallback(
    (token: string, prevData: any[] = []) => {
      if (token) {
        setLoading(true);
        ListRequest(
          token,
          (response) => {
            if (response) {
              setFocusedContents(prevData.concat(response.Result));
              setLoading(false);
            }
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => {}
        );
      }
    },
    []
  );

  const onMove = (newData: FocusedContent[]) => {
    if (token) {
      const idList = newData.map((m) => ({ Id: m.DigitalAssetId }));
      ReOrderRequest(
        token,
        idList,
        () => {
          return;
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  const movebyIdx = (idx: number, step: number) => {
    const temp = [...focusedContents];
    const tempItem = temp[idx];
    temp[temp.indexOf(tempItem)] = temp[step];
    temp[step] = tempItem;
    setFocusedContents([...temp]);
    onMove(temp);
  };

  const moveUp = (idx: number) => {
    movebyIdx(idx, idx - 1);
  };

  const moveDown = (idx: number) => {
    movebyIdx(idx, idx + 1);
  };
  const removeFocusedContent = (id: string) => {
    if (token) {
      DeleteRequest(
        token,
        id,
        () => {
          setRefresh(!refresh);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  useEffect(() => {
    if (token) getFocusedContents(token);
  }, [token, getFocusedContents, refresh]);

  function renderList() {    
    return focusedContents.map((item: FocusedContent, index) => (
      <FocusedContentListCard
        key={item.DigitalAssetId}
        index={index}
        count={focusedContents.length}
        product={item}
        showControls={true}
        onDelete={function (id: string): void {
          setSelectedId(id);
          setModalOpen(true);
        }}
        moveUp={function (index: number): void {
          moveUp(index);
        }}
        moveDown={function (index: number): void {
          moveDown(index);
        }}
      />
    ));
  }

  function renderTitle() {
    return (
      <Stack
        direction='row'
        justifyContent='space-between'
        mt={smallDown ? 2 : 4}
        mr={1}
      >
        <Stack
          gap={2}
          direction={mdDown ? 'column' : 'row'}
          alignItems={mdDown ? 'flex-start' : 'center'}
        >
          <Typography fontSize='20px' fontWeight='600'>
            Focused Content Assets
          </Typography>
          <CaptionSecondary>{focusedContents.length} Assets</CaptionSecondary>
        </Stack>
      </Stack>
    );
  }
  function renderContent() {
    return (
      <Grid width='100%' px={{ xs: '20px', sm: 4 }}>
        <Box>
          {renderTitle()}
          {modalOpen ? (
            <MiniDialog
              title='Remove Focus Content'
              open={modalOpen}
              disabled={!selectedId}
              close={() => {
                setModalOpen(false);
                setSelectedId(undefined);
              }}
              remove={() => {
                if (selectedId) {
                  removeFocusedContent(selectedId);
                }
                setSelectedId(undefined);
                setModalOpen(false);
              }}
            >
              <Box sx={{ padding: '0 24px' }}>
                <BodyOneSecondary>
                  {
                    'Are you sure that you want to remove this from focused contents?'
                  }
                </BodyOneSecondary>
              </Box>
            </MiniDialog>
          ) : null}
          {!loading && focusedContents.length <= 0 ? (
            <NoItems
              icon={
                <BusinessCenterIcon
                  sx={{
                    fontSize: 88,
                    fill: LIGHT_THEME.palette.primary.main,
                  }}
                />
              }
              title='No focused contents yet'
              description='You haven’t added any brand assets as focused content yet.'
            />
          ) : null}
        </Box>

        {loading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 320,
              zIndex: 999,
            }}
          >
            <CircularProgress color='primary' size={50} />
          </Box>
        ) : (
          <Grid
            container
            item
            xs={12}
            columnSpacing={2}
            rowSpacing={3}
            sx={{ width: '100%', margin: 0 }}
          >
            {renderList()}
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <TabPanel value={'content-second'} sx={{ padding: 0, width: '100%' }}>
      {renderContent()}
    </TabPanel>
  );
}
