import { Container, CircularProgress, useMediaQuery } from '@mui/material';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ListDivider } from '../components-molecules/Divider';
import { LIGHT_THEME } from '../constants/theme';
import { GetAdminUsersGrouped } from '../service/serviceService';
import { AdminUserCard } from './cards/AdminUserCard';

interface IProps {
  users: GetAdminUsersGrouped[];
  loadMore: () => void;
  total: number;
  onEdit: (id: GUID) => void;
  onDelete: (id: GUID) => void;
}

export function AdminUserList({ users, loadMore, total, onEdit, onDelete }: IProps): JSX.Element {
  const timeoutDuration = 300;
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  
  return (
    <InfiniteScroll
      style={{ overflowY: 'hidden' }}
      dataLength={users.map(m => m.Users).flat().length} //This is important field to render the next data
      next={() => debounce(() => loadMore(), timeoutDuration)()}
      scrollThreshold={smallDown ? '550px' : '50px'}
      hasMore={users.map(m => m.Users).flat().length < total}
      loader={
        <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
          <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
        </Container>
      }
    >
      {users.map(m =>
        <ListDivider
          key={m.Group}
          division={{ name: m.Group || 'No role assigned', length: m.Users.length }}
          caption={{ singular: 'User', plural: 'Users' }}
        >
          {m.Users.map((item, itemIndex) =>
            <AdminUserCard
              onEdit={onEdit}
              onDelete={onDelete}
              key={itemIndex}
              user={item} />)}
        </ListDivider>
      )}
    </InfiniteScroll>
  );
}
