
import { Section } from '../../components-molecules/section/Section';
import { BodyTwoSecondary, CaptionPrimary, CaptionSecondary, HeaderSix } from '../../components-atoms/TypographyComponents';
import { Grid, IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LIGHT_THEME } from '../../constants/theme';
import '../../scss/components/_industry-insight.scss';
import { useState } from 'react';
import { FormatDate } from '../../utils/dateUtils';
import imageIcon from '../../assets/icons/Imagefile.svg';
interface IProps {
  insight: IndustryInsight;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onPublishChange: (id: string) => void;
}
export function IndustryInsightListCard({ insight, onEdit, onDelete, onPublishChange }: IProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [error, setError] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <Section fullPadding>
      <Grid container item direction="row" columnSpacing={3} sx={{ width: '100%' }}>
        <Grid
          container
          justifyContent="center"
          item xs={2}
        >
          <Grid
            container
            justifyContent="center"
            item
            sx={{
              aspectRatio: '1/1',
              height: '96px',
              width: '96px',
              border: '1px solid rgba(0, 0, 0, 0.08)',
              borderRadius: '4px',
              objectFit: 'contain'
            }}>

            <img
              style={{ maxHeight: '100%', maxWidth: '100%', }}
              src={error ? imageIcon : insight.ThumbnailUrl || insight.ImageUrl}
              onError={() => setError(true)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={8} direction="column">
          <HeaderSix sx={{ marginBottom: '4px' }} >{insight.Title}</HeaderSix>
          <CaptionSecondary sx={{ marginBottom: '4px' }}>
            {FormatDate(new Date(insight.Created), { day: '2-digit', month: 'long', format: 'dd MM, YYYY' })}
          </CaptionSecondary>
          <BodyTwoSecondary className="insight-card__description">
            {insight.Description}
          </BodyTwoSecondary>
        </Grid>
        <Grid xs={1} container item justifyContent="center" alignItems="center">
          <CaptionPrimary fontWeight={700} sx={{ color: insight.Published ? LIGHT_THEME.palette.success.main : LIGHT_THEME.palette.error.main }}>
            {insight.Published ? 'Published' : 'Unpublished'}
          </CaptionPrimary>
        </Grid>
        <Grid xs={1} container item justifyContent="flex-end" alignItems="center">
          <IconButton sx={{ margin: '0 -18px 0 0', padding: 0, height: '48px', width: '48px' }} onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ '.MuiPaper-root': { width: '160px', marginTop: '8px' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => { onEdit(insight.Id); handleClose(); }}>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { onPublishChange(insight.Id); handleClose(); }}>
          <ListItemText >{!insight.Published ? 'Publish' : 'Unpublish'}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { onDelete(insight.Id); handleClose(); }}>
          <ListItemText sx={{ color: 'error.main' }}>Delete</ListItemText>
        </MenuItem>


      </Menu>
    </Section>
  );
}

