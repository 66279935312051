/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo } from 'react';
import { Container, Grid, styled } from '@mui/material';
import { BodyTwoSecondary, HeaderFourSecondary, OverlineSecondary } from '../../components-atoms/TypographyComponents';
import { FormatDate } from '../../utils/dateUtils';
import { correctUrls } from '../../hooks/correctUrls';
import '../../scss/components/_content.scss';

const Title = styled(HeaderFourSecondary)({
  fontFamily: 'Open Sans',
  fontSize: '34px',
  lineHeight: '42px',
  letterSpacing: '0.25px',
  color: 'rgba(0, 0, 0, 0.6)',
  fontWeight: 600,
});

interface IProps {
  attachments: any;
  newsletter: any;
  contentClass?: string;
}

export const NewsletterServiceCard = ({ newsletter, attachments, contentClass }: IProps): JSX.Element => {
  const sentDate = useMemo(() => FormatDate(new Date(newsletter.SentDate), { day: 'numeric', month: 'long', format: 'dd MM YYYY' }), [newsletter]);

  return (
    <Container className="newsletter-service-card">
      <Title>{newsletter.Subject}</Title>
      <BodyTwoSecondary sx={{ mt: 1.5, marginBottom: '32px' }}>
        {sentDate}
      </BodyTwoSecondary>
      <Grid sx={{ width: '100%', marginBottom: '44px' }}>
        <div className={`rte ${contentClass}`} style={{ wordBreak: 'break-word', color: 'rgb(0, 0, 0, 0.65)' }} dangerouslySetInnerHTML={{ __html: correctUrls(newsletter.Body) }}></div>
      </Grid>
      {attachments.length > 0 && (
        <Grid container direction='row' alignItems='center'>
          <Grid item sm={3}>
            <OverlineSecondary>FILE ATTACHMENTS</OverlineSecondary>
          </Grid>
          <Grid xs={12} sm={9} item>
            <Grid maxWidth='md' sx={{ display: 'flex', flexDirection: 'column' }}>
              {attachments.map((attachment: any, index: number) =>
                <BodyTwoSecondary
                  key={index}
                  style={{ color: '#012169', cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => window.open(attachment.FileUrl)}
                >
                  {attachment.FileName}
                </BodyTwoSecondary>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default NewsletterServiceCard;
