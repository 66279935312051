import { useCallback, useEffect, useState } from 'react';
import { NoItems } from '../components-molecules/NoItems';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { LIGHT_THEME } from '../constants/theme';
import { GetAdminUsersGrouped, GetAdminUsers, DeleteAdminUser, ListAvailableRoles } from '../service/serviceService';
import axios, { CancelToken } from 'axios';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { Box, Checkbox, CircularProgress, FormControlLabel, Grid } from '@mui/material';
import { AdminUserList } from './AdminUserList';
import { BodyOneSecondary, HeaderFive } from '../components-atoms/TypographyComponents';
import { MiniDialog } from '../components-molecules/dialogs/MiniDialog';
import { IFilterOption, IFilterOptions, ISelectedOptions } from '../components-molecules/FilterMenu';
import { useNavigate } from 'react-router-dom';
import { SearchbarWithDeboubce } from '../components-molecules/SearchBarWithDebounce';

const SORT_TYPES = [
  {
    title: 'A \u2014 Z',
    value: 'name'
  },
  {
    title: 'Admin Role',
    value: 'role'
  },
];

interface IAdminUserFilter extends IFilterOptions {
  Role: IFilterOption[];
}

export function AdminUsersSection(): JSX.Element {
  const [users, setUsers] = useState<GetAdminUsersGrouped[]>([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [filter, setFilter] = useState<ISelectedOptions>({ Role: [] });
  const [query, setQuery] = useState('');
  const token = useTypedSelector(state => state.userReducer.token);
  const cancelTokenSource = axios.CancelToken.source();
  const [page, setPage] = useState(0);
  const [sortType, setSortType] = useState<string>('name');
  const pageSize = 20;
  const [modal, setModal] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<GUID | undefined>();
  const [filterReset, setFilterReset] = useState(false);
  const [filterOptions, setFilterOptions] = useState<IAdminUserFilter>({
    Role: []
  });
  const [noRoleFilter, setNoRoleFilter] = useState(false);
  const [noRole, setNoRole] = useState(false);
  const navigate = useNavigate();
  
  const deleteAdminUser = () => {
    if (token && userToDelete) {
      DeleteAdminUser(
        token,
        userToDelete,
        () => getUsers(token, cancelTokenSource.token),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  const getUsers = useCallback((token: string, cancelToken?: CancelToken, page = 0, size = pageSize, prevData: GetAdminUsersGrouped[] = []) => {
    setLoading(true);
    GetAdminUsers(
      token,
      {
        Query: query,
        GroupBy: sortType,
        Page: page,
        RoleIds: filter.Role?.length > 0 ? filter.Role as GUID[] : [],
        Size: size,
        NoRole: noRoleFilter
      },
      (response) => {
        if(page === 0){
          setCount(response.Result.Count);
        }
        let temp = [...prevData];
        response.Result.Result.map(m => {
          const tempMatch = temp.find((f) => f.Group === m.Group);
          if (tempMatch) {
            temp[temp.indexOf(tempMatch)].Users = [...temp[temp.indexOf(tempMatch)].Users, ...m.Users];
          } else {
            temp = [...temp, m];
          }
        });
        setUsers(temp);
        setLoading(false);
      },
      () => setLoading(false),
      cancelToken
    );
  }, [query, sortType, noRoleFilter, filter]);

  useEffect(() => {
    if (token) getUsers(token, cancelTokenSource.token);
    return () => {
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, getUsers]);

  useEffect(() => {
    if (token) {
      ListAvailableRoles(
        token,
        (response) => {
          setFilterOptions({ Role: response.Result.map(m => ({ title: m.Name, value: m.Id })) });
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  }, [token]);

  const loadMore = () => {
    if (token) {
      getUsers(token, undefined, page + 1, pageSize, users);
      setPage(page + 1);
    }
  };
  
  return (
    <>
      <PageHeader
        buttonTitle="Admin Portal User"
        subtitle={
          (count > 0)
            ? `${count} ${(count > 1) ? 'Admin Portal Users' : 'Admin Portal User'}`
            : loading
              ? ''
              : 'No Admin Portal Users Yet'
        }
        activeButtons={true}
        modalCallback={() => navigate('/admin/add-admin-user')}
        onClickSearch={() => setSearchOpen(!searchOpen)}
        sortTypes={SORT_TYPES}
        selectedSortType={sortType}
        handleSortSelect={val => setSortType(val)}
        filterOptions={filterOptions}
        onFilterSubmit={(selected) => {
          setFilter(selected);
          setNoRoleFilter(noRole);
        }}
        hasFilter
        filterReset={filterReset}
        setFilterReset={setFilterReset}
        customFilterElement={
          <FormControlLabel
            sx={{ margin: 0, width: '100%', '&.MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled': { color: LIGHT_THEME.palette.text.primary } }}
            disabled={users.flatMap(m => m.Users)[0]?.NoRoleCount === 0}
            control={
              <Checkbox
                sx={{ padding: 0, margin: '0 23px 0 9px' }}
                checked={noRole}
                onChange={(e, val) => {
                  setFilterReset(true);
                  setNoRole(val);
                }}
                name="no-role" />
            }
            label="Only users without user role"
          />
        }
      />
      <SearchbarWithDeboubce
        isOpen={searchOpen}
        query={query}
        placeholder="Search User Roles"
        onCancel={() => { setSearchOpen(false); setQuery(''); }}
        onChange={(text:string) => setQuery(text)}
      />
      {loading && page === 0
        ? <Grid container item justifyContent="center" alignItems="center">
          <CircularProgress
            color="primary"
            size={50}
            style={{ zIndex: 999, margin: '56px 0' }}
          />
        </Grid>
        : (
          !(users.length > 0)
            ? (!query
              ? <NoItems
                icon={<ManageAccountsIcon sx={{ fontSize: 88, fill: LIGHT_THEME.palette.primary.main }} />}
                title="No admin portal users yet"
                description="You haven’t added any admin portal users yet. Start by adding the first user." />
              : <Grid item container direction="column" sx={{ padding: '16px 24px', marginTop: '24px' }} rowSpacing={1}>
                <HeaderFive>{users.length} search results found</HeaderFive>
                <BodyOneSecondary>Adjust your search term and try again</BodyOneSecondary>
              </Grid>)
            : <AdminUserList
              onEdit={id => {
                const selectedUser = users.flatMap(f => f.Users).find(f => f.Id === id);
                navigate('/admin/edit-admin-user', { state: { selectedUser } });
              }}
              onDelete={id => {
                setUserToDelete(id);
                setModal(true);
              }}
              users={users}
              loadMore={loadMore}
              total={count}
            />
        )
      }
      {modal
        ? <MiniDialog
          title="Delete Admin Portal User"
          open={modal}
          close={() => { setModal(false); setUserToDelete(undefined); }}
          remove={() => {
            deleteAdminUser();
            setModal(false);
            setUserToDelete(undefined);
          }}>
          <Box sx={{ padding: '0 24px' }}>
            <BodyOneSecondary>
              Are you sure you want to delete this Admin Portal User?
            </BodyOneSecondary>
          </Box>
        </MiniDialog>
        : null
      }
    </>
  );
}
