const INITIAL: AccessRightsState = {
  currentData: [],
};
  
export const accessRightsReducer = (state: AccessRightsState = INITIAL, action: AccessRightsAction): AccessRightsState => {
  switch (action.type) {
    case 'SET_CURRENT_ACCESS_RIGHTS':
      return { ...state, currentData: action.payload.currentData };
    default:
      return state;
  }
};
  
