import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { Divider, Grid } from '@mui/material';
import { BodyTwoSecondary, HeaderSix, SubtitleOnePrimary } from '../components-atoms/TypographyComponents';
import { GetRecentArticleRequest } from '../service/serviceService';
import placeholder from '../assets/img/article@2x.png';
import '../scss/components/_recent-items.scss';
import '../scss/components/_thumbnail.scss';
import { FormatDate } from '../utils/dateUtils';

interface IProps {
  categoryId: string;
  serviceId: string;
}

export function RecentArticles({categoryId, serviceId}: IProps): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const navigate = useNavigate();
  const [recentArticles, setRecentArticles] = useState<any>([]);

  useEffect(() => {
    if (token) {
      GetRecentArticleRequest(
        token,
        {
          Page: 0,
          Size: 6,
        },
        (response) => { if (response) setRecentArticles(response.Result); },
        (error: any) => { if (error.response) console.error(error.response.data.Error); }
      );
    }
  }, [token]);

  return (
    <>
      {recentArticles.length > 0 && (
        <>
          <Divider style={{ width: '100%'}} />
          <section className="recent-items">
            <div className="recent-items__title">
              <HeaderSix>Recent Articles</HeaderSix>
            </div>
            <Grid container item columnSpacing={20 / 8} alignItems="center">
              {recentArticles.map((recentArticle: any) => (
                <Grid key={recentArticle.RemoteId} className="recent-items__content" item lg={4} xl={4} xs={12} md={6} sm={6}>
                  <a
                    onClick={() => navigate(`/detail/article/${recentArticle.RemoteId}`, {
                      state: {
                        categoryId,
                        serviceId
                      }
                    })}
                    className="recent-item recent-item--link"
                  >
                    <img
                      src={placeholder}
                      className="
                      recent-item__media
                      thumb thumb--large thumb--picture-icon
                    "
                    />
                    <div className="recent-item__content">
                      <SubtitleOnePrimary className="u-text-truncate-multi">
                        {recentArticle.Title}
                      </SubtitleOnePrimary>
                      <BodyTwoSecondary>
                        {FormatDate(new Date(recentArticle.PublishedDate), { day: 'numeric', month: 'short', format: 'dd MM YYYY' })}
                      </BodyTwoSecondary>
                    </div>
                  </a>
                </Grid>
              ))}
            </Grid>
          </section>
        </>
      )}
    </>
  );
}
