const INITIAL: SearchState = {
  currentData: '',
};
  
export const searchReducer = (state: SearchState = INITIAL, action: SearchAction): SearchState => {
  switch (action.type) {
    case 'SET_SEARCH_QUERY':
      return { ...state, currentData: action.payload.currentData };
    default:
      return state;
  }
};
