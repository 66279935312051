export function ExtractEmployees(stores: Store[]): User[] {
  const users: User[] = [];
  for (const key in stores) {
    const emp = stores[key].StoreEmployees;
    if (emp) users.push(...emp);
  }
  return users;
}

export function ExtractTerritoryManagers(stores: Store[]): TerritoryManager[] {
  const territoryManagers: TerritoryManager[] = [];
  stores.map(store => {
    if (store.TerritoryManager && !territoryManagers.some(tm => tm.AdminProperties.Id === store.TerritoryManager?.AdminProperties.Id)) {
      territoryManagers.push(store.TerritoryManager);
    }
  });
  return territoryManagers;
}
