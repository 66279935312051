/* eslint-disable @typescript-eslint/naming-convention */
import { Fragment, useEffect, useState } from 'react';
import { MainBarCompact } from './MainBarCompact';
import { IconButton, Toolbar, styled } from '@mui/material';
import { HeaderSix } from '../../components-atoms/TypographyComponents';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface IDetailPageHeaderProps {
  title: string;
  onBack: () => void;
}

const DetailPageToolbar = styled(Toolbar)((props: { scrollTop: number }) => ({
  width: '100%',
  position: 'sticky',
  top: 0,
  zIndex: 99,
  height: '61px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  boxShadow: props.scrollTop <= 60 ? 'none' : '0 3px 3px 0 rgb(51 51 51 / 11%)',
}));

export default function DetailPageHeader(props: IDetailPageHeaderProps): JSX.Element {
  const { title, onBack } = props;
  const [scrollTop, setScrollTop] = useState<any>(0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function handleScroll() {
    setScrollTop(window.pageYOffset);
  }

  return (
    <Fragment>
      <MainBarCompact merchantPage={true} />
      <DetailPageToolbar variant='modal' scrollTop={scrollTop}>
        <IconButton onClick={onBack} sx={{ ml: { xs: '20px', sm: 4, md: 4 }, width: 48, height: 48 }}>
          <ArrowBackIcon color='primary' />
        </IconButton>
        <HeaderSix sx={{
          ml: 2,
          my: 0,
          color: 'rgba(0, 0, 0, 0.87)',
          whiteSpace: 'nowrap',
          fontSize: {
            xl: '20px',
            lg: '20px',
            md: '14px',
            sm: '14px',
            xs: '14px',
          }
        }}>
          {title}
        </HeaderSix>
      </DetailPageToolbar>
    </Fragment>
  );
}
