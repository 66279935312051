import { STARTS_WITH_NUMBER_PATTERN, STARTS_WITH_NUMBER_OR_LETTER_PATTERN } from '../constants/regexpPatterns';

export function UserDivider<T>(array: (T & { FirstName: string; })[]): DividedList<T> {
  const tempList: { [key: string]: T[]; } = {};

  array.forEach((element) => {
    if (element.FirstName.match(STARTS_WITH_NUMBER_PATTERN)) {
      if (!('0 ... 9' in tempList)) tempList['0 ... 9'] = [];
      tempList['0 ... 9'].push(element);
    }
    else if (!element.FirstName.match(STARTS_WITH_NUMBER_OR_LETTER_PATTERN)) {
      if (!('.' in tempList)) tempList['.'] = [];
      tempList['.'].push(element);
    }
    else {
      const letter = element.FirstName[0].toUpperCase();
      if (!(letter in tempList)) tempList[letter.toUpperCase()] = [];
      tempList[letter].push(element);
    }
  });

  return ({ ...tempList });
}

export function AdminDivider<T>(array: (T & { AdminProperties: { FirstName: string; }; })[]): DividedList<T> {
  const tempList: { [key: string]: T[]; } = {};

  array.forEach((element) => {
    if (element.AdminProperties.FirstName.match(STARTS_WITH_NUMBER_PATTERN)) {
      if (!('0 ... 9' in tempList)) tempList['0 ... 9'] = [];
      tempList['0 ... 9'].push(element);
    }
    else if (!element.AdminProperties.FirstName.match(STARTS_WITH_NUMBER_OR_LETTER_PATTERN)) {
      if (!('.' in tempList)) tempList['.'] = [];
      tempList['.'].push(element);
    }
    else {
      const letter = element.AdminProperties.FirstName[0].toUpperCase();
      if (!(letter in tempList)) tempList[letter.toUpperCase()] = [];
      tempList[letter].push(element);
    }
  });

  return ({ ...tempList });
}

export function DataDivider<T>(array: (T & { Name: string; })[]): DividedList<T> {
  const tempList: { [key: string]: T[]; } = {};

  array.forEach((element) => {
    if (element.Name.match(STARTS_WITH_NUMBER_PATTERN)) {
      if (!('0 ... 9' in tempList)) tempList['0 ... 9'] = [];
      tempList['0 ... 9'].push(element);
    }
    else if (!element.Name.match(STARTS_WITH_NUMBER_OR_LETTER_PATTERN)) {
      if (!('.' in tempList)) tempList['.'] = [];
      tempList['.'].push(element);
    }
    else {
      const letter = element.Name[0].toUpperCase();
      if (!(letter in tempList)) tempList[letter] = [];
      tempList[letter].push(element);
    }
  });

  return ({ ...tempList });
}
