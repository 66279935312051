import { AxiosError } from 'axios';
import { HttpRequestDelete, HttpRequestGet, HttpRequestPost, HttpRequestPut } from './genericService';

interface GetTerritoryManagersResponseModel {
  TerritoryManagers: {
    TerritoryManager: TerritoryManager;
    Merchants: Merchant[];
  }[];
}

interface GetTerritoryManagersRequestModel {
  Query: string;
}

export function GetTerritoryManagersRequest(token: string, body: GetTerritoryManagersRequestModel, success: (responseData: TerritoryManager[]) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<GetTerritoryManagersResponseModel, GetTerritoryManagersRequestModel>(
    '/admin/territory-managers',
    body,
    (response) => {
      const tmArray: TerritoryManager[] = response.data.Result.TerritoryManagers.map(x => {
        const temp: TerritoryManager = {
          ...x.TerritoryManager,
          Merchants: x.Merchants
        };
        return temp;
      });
      success(tmArray);
    },
    (error) => failure(error),
    token
  );
}

export function DeleteAdminRequest(token: string, guid: GUID, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestDelete<string>(
    `/admin/${guid}`,
    () => success(),
    (error) => failure(error),
    token
  );
}

interface InviteAdminRequest {
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  IsAdmin: boolean;
}

export function InviteAdminRequest(token: string, body: InviteAdminRequest, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<unknown, InviteAdminRequest>(
    '/admin',
    body,
    () => success(),
    (error) => failure(error),
    token
  );
}

type EditAdminRequestModel = {
  Id: GUID;
  FirstName: string;
  LastName: string;
  Phone: string;
};

export function EditAdminRequest(token: string, body: EditAdminRequestModel, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void, noSuperData?: boolean): void {
  HttpRequestPut<unknown, EditAdminRequestModel>(
    '/admin/edit',
    body,
    () => success(),
    (error: AxiosError) => failure(error),
    token,
    undefined,
    noSuperData
  );
}

export function GetMerchantTokenRequest(token: string, id: string, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<string>(
    `/merchant/${id}/token`,
    (response) => success(response.data),
    (error: AxiosError) => failure(error),
    token
  );
}

interface SearchTerritoryManagersResponseModel {
  Result: TerritoryManager[];
  Count: number;
}

interface SearchTerritoryManagersRequestModel {
  Query: string;
  Size: number;
  Page: number;
}

export function SearchTerritoryManagersRequest(token: string, body: SearchTerritoryManagersRequestModel, success: (response: ApiResponse<SearchTerritoryManagersResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<SearchTerritoryManagersResponseModel, SearchTerritoryManagersRequestModel>(
    '/admin/search',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export type SalesManager = {
  FirstName: string;
  LastName: string;
  Phone: string;
  Email: string;
  SalesManagerRoleId: string;
}

export function SubmitSalesManager(token: string, body: SalesManager, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, SalesManager>(
    '/salesmanager',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export type SalesManagerRoles = {
  Id: GUID;
  Name: string;
}[]

export function GetSalesManagerRoles(token: string, success: (response: ApiResponse<SalesManagerRoles>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<SalesManagerRoles>(
    '/salesmanager/roles',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface SalesManagerMerchant {
  Id: GUID;
  Name: string;
}

export interface SearchSalesManagerResult {
  Id: GUID;
  FirstName: string;
  LastName: string;
  Role: string;
  Email: string;
  Phone: string;
  Merchants: SalesManagerMerchant[];
  RegistrationPending: boolean;
}

export interface GroupedSearchSalesManager {
  Group: string;
  SalesManagers: SearchSalesManagerResult[];
}

interface SearchSalesManagersResponseModel {
  SalesManagers: GroupedSearchSalesManager[];
  Count: number;
}

interface SearchSalesManagersRequestModel {
  Query: string;
  Size: number;
  Page: number;
  OrderBy: string;
  RoleIds?: GUID[];
}

export function SearchSalesManagersRequest(token: string, body: SearchSalesManagersRequestModel, success: (response: ApiResponse<SearchSalesManagersResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<SearchSalesManagersResponseModel, SearchSalesManagersRequestModel>(
    '/salesmanager/search',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

type EditSalesManagerRequestModel = {
  Id: GUID;
  FirstName: string;
  LastName: string;
  Phone: string;
  SalesManagerRoleId: string;
};

export function EditSalesManagerRequest(token: string, body: EditSalesManagerRequestModel, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void, noSuperData?: boolean): void {
  HttpRequestPut<string, EditSalesManagerRequestModel>(
    '/salesmanager',
    body,
    () => success(),
    (error: AxiosError) => failure(error),
    token,
    undefined,
    noSuperData
  );
}

export function DeleteSalesManagerRequest(token: string, guid: GUID, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestDelete<string>(
    `/salesmanager/${guid}`,
    () => success(),
    (error) => failure(error),
    token
  );
}

interface GetAdminHelpfilesRequestModel {
  Size: number;
  Page: number;
}

interface GetAdminHelpfilesResponseModel {
  Result: AdminHelpfile[];
  Count: number;
}

export function GetAdminHelpfilesRequest(token: string, body: GetAdminHelpfilesRequestModel, success: (response: ApiResponse<GetAdminHelpfilesResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<GetAdminHelpfilesResponseModel, GetAdminHelpfilesRequestModel>(
    '/helpfile/list',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}
