/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Stack, debounce, useMediaQuery } from '@mui/material';
import { FormControlSelect } from '../FormControl';
import { useForm } from 'react-hook-form';
import { getSelectedValuesForInput } from '../../constants/digitalAssets';
import { LIGHT_THEME } from '../../constants/theme';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 480,
    },
  },
};

export function HeaderFilter({ filterOptions, sortOptions, selectedFilters, selectedSort, onChange, onClose}: any): JSX.Element {
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  const { control } = useForm<any>({ mode: 'all', reValidateMode: 'onChange' });
  const filterList: any = useRef();
  const [offset, setOffset] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);
  useEffect(() => {
    setIsScrollable(filterList?.current?.scrollWidth > filterList?.current?.clientWidth);
  }, [filterList]);

  const onScroll = (e: any) => {
    debounce(() => setOffset(e.target.scrollLeft), 150)();
  };

  const scroll = (to: string) => {
    const newLeft = to === 'left' ? 0 : filterList?.current?.scrollWidth;
    if(filterList.current) {
      filterList.current.scrollLeft = newLeft;
      setOffset(newLeft);
    }
  };

  function renderSort() {
    return (
      <Box
        sx={{ 
          flexShrink: 1,
          minWidth: '210px',
          scrollSnapAlign: smallDown ? 'center' : 'none'
        }}>
        <FormControlSelect
          noMargin
          suppressError
          data-testid={'digital-asset-header-sort'}
          control={control}
          selectProps={{
            name: 'OrderBy',
            sx:{
              height: '48px',
              borderTopLeftRadius: '8px',
              overflow: 'hidden',
            },
            value: selectedSort,
            onChange: onChange,
            onClose: onClose
          }}
          field={{
            label: 'Sort By',
            name: 'OrderBy',
            inputType: 'select',
            options: sortOptions,
            validation: {}
          }} 
        />
      </Box>       
    );
  }

  function renderDynamicFilters() {
    if(filterOptions && Object.keys(filterOptions)?.length > 0) {
      return(
        Object.keys(filterOptions).map(key =>
          filterOptions[key] && filterOptions[key].length > 0 && (
            <Box key={key}
              sx={{ 
                flexShrink: 1,
                minWidth: '210px',
                scrollSnapAlign: 'center'
              }}>
              <FormControlSelect
                showCheckbox
                noMargin
                suppressError
                data-testid={key}
                control={control}
                renderValue={key && filterOptions && selectedFilters ? getSelectedValuesForInput(key, filterOptions, selectedFilters[key]) : ''}
                selectProps={{
                  name: key,
                  multiple: true,
                  sx:{
                    height: '48px',
                    borderTopLeftRadius: '8px',
                    overflow: 'hidden',
                  },
                  value: selectedFilters ? selectedFilters[key] : [],
                  onChange: onChange,
                  onClose: onClose,
                  MenuProps: MenuProps
                }}
                field={{
                  label: key,
                  name: key,
                  inputType: 'select',
                  options: filterOptions[key].map((f: any)=>
                    ({name: f?.name || f?.title, 
                      value: f.value
                    })) as any,
                  validation: {
                  }
                }} />
            </Box>         
          )
        )
      );
    }

    // Skeleton
    return(
      [...Array(6)].map((e, i) =>
        <Box key={i} sx={{
          flexShrink: 1,
          minWidth: 210,
          height: 50,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          backgroundColor: '#f3f5f8'
        }} />
      )
    );
  }

  const showArrow = !smallDown && isScrollable && Object.keys(filterOptions || []).length > 2;
  return (
    <Stack 
      display="flex" 
      flexDirection="row" 
      alignItems="center"
      height="64px">
      {showArrow
        ? <IconButton
          sx={{
            position: 'absolute',
            left: '8px',
            opacity: offset === 0 ? 0 : 1,
            zIndex: 99,
            background: 'white',
            '&:hover': {
              background:'white'
            }
          }}
          onClick={()=>{ scroll('left'); }}>
          <ChevronLeftIcon/>
        </IconButton>
        : <></>}
      <Stack
        ref={filterList}
        onScroll={onScroll}
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap="8px"
        pl={{xs: '20px', sm: 4, md: 4, lg: 4, xl: 4}}
        pr={{xs: 1, sm: 1, md: '20px', lg: '20px', xl: '20px'}}
        sx={{
          overflowX: 'scroll',
          overflowY: 'hidden',
          scrollSnapType: smallDown ? 'x mandatory' : 'none',
          scrollBehavior: 'smooth',
          '&::-webkit-scrollbar' :{
            display: 'none',
          }
        }}
      >
        {sortOptions && renderSort()}
        {renderDynamicFilters()}
      </Stack>
      {showArrow
        ? <IconButton
          sx={{
            position: 'absolute',
            right: '8px',
            opacity: offset >= (filterList?.current?.clientWidth / Object.keys(filterOptions || []).length || 0 ) ? 0 : 1,
            zIndex: 99,
            background: 'white',
            '&:hover': {
              background:'white'
            }
          }}
          onClick={()=>{ scroll('right'); }}>
          <ChevronRightIcon />
        </IconButton> : <></>}
    </Stack>
  );
}
