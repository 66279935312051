/* eslint-disable @typescript-eslint/naming-convention */
import { Box } from '@mui/material';
import { Dashboard } from '../../../components-organisms/Dashboard';
import { PageHeader } from '../../../components-molecules/titlebars/PageHeader';
import { IndustryEvents } from '../../IndustryEvents';

export default function IndustryEventsServiceDetail(): JSX.Element {
  return (
    <Dashboard justifyContent='flex-start'>
      <PageHeader title="Industry Events" />
      <Box sx={{ minHeight: '80vh', width: '100%' }}>
        <IndustryEvents />
      </Box>
    </Dashboard>
  );
}
