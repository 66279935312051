import { SubmitHandler } from 'react-hook-form';
import { ShareDigitalAssetRequest } from '../../service/serviceService';
import { ShareContentPage } from './ShareContentPage';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export default function DigitalAssetShare(): JSX.Element {
  const navigate = useNavigate();
  const [token, selectedAssets]: any = useTypedSelector((state) => [state.userReducer.token, state.digitalAssetReducer.selectedAssets]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onSubmit: SubmitHandler<any> = async (data) => {
    if (token) {
      setLoading(true);
      const recipientIds = data?.selectedRecipients?.map((r: any) => r?.Recipients?.map((re: any) => re.Id)).flat();
      ShareDigitalAssetRequest(
        token,
        {
          Body: data.body,
          Subject: data.subject,
          RecipientIds: recipientIds,
          DigitalAssetIds: selectedAssets?.map((asset: any) => asset.DigitalAssetId) as string[]
        },
        () => {
          navigate(-1);
        },
        (error) => {
          setError(error.response?.data.Error || '');
          setLoading(false);
        }
      );
    }
  };

  return (
    <ShareContentPage
      title='Share Digital Asset'
      loading={loading}
      error={error}
      onSubmit={(data: ISharePageContent) => onSubmit(data)} />
  );
}
