import { Fragment, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormHelperText, Grid } from '@mui/material';
import { UserSetup } from '../../service/serviceService';
import { BodyOneSecondary } from '../../components-atoms/TypographyComponents';
import { ModalCardComponent, ModalComponent } from '../../components-molecules/Modal';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { FormControlInput } from '../../components-molecules/FormControl';
import { ADD_USER_FORM } from '../../forms/addUser';

interface AddUser {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
}

interface IAddUserDetailsProps {
  userEmail: string;
  isManager: boolean;
  isOpen: boolean;
  modalCallback: () => void;
}

export function AddUserDetails({ userEmail, isManager, isOpen, modalCallback }: IAddUserDetailsProps): JSX.Element {
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, formState: { isValid }, reset } = useForm<any>({ mode: 'all', reValidateMode: 'onBlur' });

  useEffect(() => {
    reset({ email: userEmail });
  }, [userEmail, reset]);

  const submit: SubmitHandler<AddUser> = (data): void => {
    setLoading(true);
    UserSetup(
      {
        FirstName: data.firstName,
        LastName: data.lastName,
        Email: data.email,
        Phone: data.phone,
      },
      () => {
        setLoading(false);
        modalCallback();
      },
      () => setLoading(false)
    );
  };

  return (
    <ModalComponent
      title="Connect for Merchants"
      buttonTitle={isManager ? 'Next' : 'Submit'}
      buttonDisabled={!isValid || loading}
      isOpen={isOpen}
      submit={handleSubmit(submit)}
      buttonLoading={loading}
    >
      <Fragment>
        <BodyOneSecondary sx={{ maxWidth: '840px', width: '100%', margin: '0 auto', paddingTop: '24px' }}>
          Fill in your personal information below. You can edit this information later from the user dropdown at the top right corner of the page under Account Settings.
        </BodyOneSecondary>
        {ADD_USER_FORM.map(section => {
          return (
            <ModalCardComponent key={section.title}>
              <SectionHeader title={section.title} />
              <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
                {section.fields.map((field, index) =>
                  <Grid key={index} item xs={12} sx={{ flexGrow: 1 }}>
                    <FormControlInput control={control} field={field} disabled={field.name === 'email'} />

                    {field.helperText 
                      && <FormHelperText sx={{ padding: '0 14px' }}>
                        {field.helperText}
                      </FormHelperText>
                    }
                  </Grid>
                )}
              </Grid>
            </ModalCardComponent>
          );
        })}
      </Fragment>
    </ModalComponent>
  );
}
