import { STORE } from '../redux/store';
export const ROLE_LIST: string[] = [
  'Merchant Manager',
  'Store Manager',
  'Store Employee'
];
interface Role{
  name: string
  value: string
}
export const INVITATION_ROLE_LIST: Role[] = [
  {name:'Merchant Manager / Owner / Head Office', value: 'Merchant Manager'},
  {name: 'Store Manager', value: 'Store Manager'},
  {name: 'Store Employee', value: 'Store Employee'}
];
export function GetRoleOptions(): SelectFieldOption[] {
  const userData = STORE.getState().userReducer.data;

  if (userData?.Role === 'Store Manager') {
    return ROLE_LIST.filter(f => f !== 'Merchant Manager').map(v => ({ name: v, value: v }));
  }
  return ROLE_LIST.map(v => ({ name: v, value: v }));
}
export function GetInvitationRoleOptions(): SelectFieldOption[] {
  const userData = STORE.getState().userReducer.data;

  if (userData?.Role === 'Store Manager') {
    return INVITATION_ROLE_LIST.filter(f => f.value !== 'Merchant Manager');
  }
  return INVITATION_ROLE_LIST;
}
