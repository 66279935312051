import { Box, Grid, Modal, Paper } from '@mui/material';
import { Download } from '@mui/icons-material';
import {ButtonContainedSecondary, ButtonTextPrimary} from '../../components-atoms/ButtonComponents';
import { VideoComponent } from '../VideoComponent';
import { isFirefox, isIOS, isMobileSafari, isSafari } from 'react-device-detect';
import { HeaderSix } from '../../components-atoms/TypographyComponents';
import videoIcon from '../../assets/icons/Videofile.svg';

interface IProps {
  open: boolean;
  onClose: () => void;
  thumbnail: string;
  url: string;
  downloadClicked?: () => void;
  noDownload?: boolean;
  noTitle?: boolean;
}

export default function VideoPreviewDialog({ open, onClose, thumbnail, url, downloadClicked, noDownload, noTitle }: IProps): JSX.Element {
  const controlsEnable= isIOS || isMobileSafari || isSafari || isFirefox ? false : true;

  return <Modal
    open={open}
    onClose={close}
    disableAutoFocus={true}
  >
    <Paper
      elevation={24}
      sx={{
        borderRadius: '12px',
        maxWidth: window.innerWidth > 1456 ? '1360px' : 'calc(100% - 48px)',
        minWidth: '320px',
        width:'100%',
        position: 'absolute', top: '50%', left: '50.5%', transform: 'translate(-50.5%, -50%)',
        background: 'background.paper',
        maxHeight: '85%'
      }}
    > 
      <Grid
        container
        direction='column'
        alignContent='center'
        justifyContent='space-between'
        sx={{ height: '100%', width: '100%', paddingLeft:'24px', paddingRight:'24px' }}
      >

        <Box sx={{ width: '100%', height: '64px', padding: '16px 0px', margin: '0' }}>
          {noTitle ? (
            <></>
          ) : (
            <HeaderSix>{'Video Preview'}</HeaderSix>
          )}
        </Box>
        
        {url !== ''
          ? <VideoComponent 
            controlsEnable={controlsEnable} 
            thumbnailUrl={thumbnail} 
            previewUrl={url}
            noDownload={noDownload}
          />
          : <div style={{height:'128px',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <img
              data-testid="digital-asset-thumbnail"
              style={{ objectFit: 'contain', maxWidth: '48px' , maxHeight: '48px' }}
              alt="preview"
              
              src={videoIcon}
            /></div>
        }
      </Grid>
      
      <Box sx={{ height: '32px', margin: '16px', alignSelf: 'end', display:'flex', justifyContent:'flex-end' }}>
        {downloadClicked ? (
          <>
            <ButtonContainedSecondary
              onClick={() => downloadClicked()}
              sx={{
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: '8px 11px !important',
                marginRight: '8px'
              }}
              startIcon={<Download />}
            >
            Download
            </ButtonContainedSecondary>
            <ButtonTextPrimary onClick={onClose} data-testid='confirmation-button'>OK</ButtonTextPrimary>
          </>
        ) : (
          <ButtonTextPrimary onClick={onClose} data-testid='confirmation-button'>Close</ButtonTextPrimary>
        )}
      </Box>
    </Paper>
  </Modal>;
}
