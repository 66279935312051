import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { Dashboard } from '../components-organisms/Dashboard';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import { useState } from 'react';
import { Box } from '@mui/material';
import { AdminRolesSection } from '../components-organisms/AdminRolesSection';
import { TabPanel } from '@mui/lab';
import { AdminUsersSection } from '../components-organisms/AdminUsersSection';
import { useLocation } from 'react-router-dom';

export function AdminUsers(): JSX.Element {
  const { state } = useLocation();
  const [currentTab, setCurrentTab] = useState<'users' | 'roles'>(state ? state.latestTab : 'users');

  return (
    <Dashboard justifyContent='flex-start'>
      <PageHeader noMargin title="Admin Portal Users" />
      <TabContext value={currentTab}>
        <TabList onChange={(e: unknown, newValue: 'users' | 'roles') => setCurrentTab(newValue)} sx={{ margin: '24px 0 0', width: '100%' }}>
          <Tab data-testid={'admin-users-content-tab'} label="Users" value="users" sx={{ width: '50%', maxWidth: '50%' }} />
          <Tab data-testid="admin-roles-tab" label="User Roles" value="roles" sx={{ width: '50%', maxWidth: '50%' }} />
        </TabList>
        <Box sx={{ minHeight: '80vh', width: '100%' }}>
          <TabPanel sx={{ padding: 0 }} value="users"><AdminUsersSection /></TabPanel>
          <TabPanel sx={{ padding: 0 }} value="roles"><AdminRolesSection /></TabPanel>
        </Box>
      </TabContext>
    </Dashboard>
  );
}
