import { Grid, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CaptionPrimary, HeaderSix, SubtitleTwoPrimary } from '../components-atoms/TypographyComponents';
import '../scss/components/_editors-pick.scss';
import '../scss/components/_media-objects.scss';
import '../scss/components/_thumbnail.scss';

interface IProps {
  data: EditorsPickByUser[];
}
export function EditorsPick({ data }: IProps): JSX.Element {
  const navigate = useNavigate();
  return (
    <>
      <section className="editors-picks">
        <HeaderSix>{'Editor\'s Pick'}</HeaderSix>
        <Grid container item columnSpacing={20 / 8} alignItems="center" className="editors-pick__container">
          {data.map((obj) => {
            return (
              <Grid key={obj.ServiceName} item sm={6} md={4} lg={3} xs={12} className="editors-pick__item">
                <Tooltip disableFocusListener disableTouchListener arrow title={obj.Description}>
                  <a
                    data-testid="editors-pick-tile-link"
                    className="
                      media-object
                      media-object--compact
                      media-object--border
                      media-object--link
                    "
                    onClick={() => navigate(`/service/${obj.ServiceId}`)}
                  >
                    <div className="media-object__media">
                      <img
                        srcSet={`
                        ${require(`../assets/img/category/${obj.ImageUrl}.png`)} 1x, 
                        ${require(`../assets/img/category/${obj.ImageUrl}@2x.png`)} 2x,
                        ${require(`../assets/img/category/${obj.ImageUrl}@3x.png`)} 3x
                      `}
                        src={require(`../assets/img/category/${obj.ImageUrl}@3x.png`)}
                        alt={`${obj.ServiceName} Logo`}
                        className="thumb thumb--xsmall"
                      />
                    </div>
                    <div className="media-object__content">
                      <CaptionPrimary>{obj.CategoryName}</CaptionPrimary>
                      <SubtitleTwoPrimary>{obj.ServiceName}</SubtitleTwoPrimary>
                    </div>
                  </a>
                </Tooltip>
              </Grid>
            );
          })}
        </Grid>
      </section>
    </>
  );
}
