/* eslint-disable @typescript-eslint/naming-convention */
import '../scss/components/_rte.scss';
import { Grid } from '@mui/material';
import { useState, useEffect, Fragment } from 'react';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { ListArticlesResponseModel, ListArticlesRequest } from '../service/serviceService';
import { CircularProgress } from '@mui/material';
import { NoItems } from '../components-molecules/NoItems';
import { IStyles, LIGHT_THEME } from '../constants/theme';
import { ListingCard } from './cards/listing/ListingCard';
import ArticleIcon from '@mui/icons-material/Article';

export function FocusOfTheMonthService(): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const [articles, setArticles] = useState<ListArticlesResponseModel[]>([]);
  const [loading, setLoading] = useState(true);

  const getArticles = (token: string, prevData: ListArticlesResponseModel[] = []) => {
    if (token) {
      ListArticlesRequest(
        token,
        (response) => {
          if (response) {
            setArticles(prevData.concat(response.Result));
            setLoading(false);
          }
        },
        () => setLoading(false)
      );
    }
  };

  useEffect(() => {
    if (token) getArticles(token);
  }, [token]);

  return (
    <Fragment>
      {loading
        ? <CircularProgress
          color='primary'
          size={50}
          style={styles.circularProgress}
        />
        : ((articles.length > 0)
          ? <Grid width='100%' px={{ xs: '20px', sm: 4 }}>
            {articles.map((article, index) =>
              <ListingCard
                key={article.Id}
                title={article.Title}
                description={article.Description}
                thumbnailUrl={article.ThumbnailUrl}
                createDate={article.Published}
                thumbnailFit='cover'
                url={'/detail/focusofthemonth/' + article.Id}
                isFirst={index === 0}
                isLast={index === articles.length - 1} />
            )}
          </Grid>
          : <NoItems
            icon={<ArticleIcon style={styles.noItemsIcon} />}
            title="No topics yet"
            description="There are no topics published yet."
          />)
      }
    </Fragment>
  );
}

const styles: IStyles = {
  p0: { padding: 0 },
  ptb24: { padding: '0 24px' },
  noItemsIcon: { fontSize: '88px', fill: LIGHT_THEME.palette.primary.main },
  circularProgress: { zIndex: 999, position: 'absolute', top: '50vh', right: '40vw' }
};
