import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BodyOneSecondary, CaptionPrimary, CaptionSecondary, HeaderFourSecondary, HeaderSix } from '../components-atoms/TypographyComponents';
import { Dashboard } from '../components-organisms/Dashboard';
import { Footer } from '../components-organisms/Footer';
import '../scss/components/_policy-pages.scss';
import { gaService } from '../service/gaService';

export function Disclaimer(): JSX.Element {
  const navigate = useNavigate();
  
  useEffect(() => {
    gaService.pageView('Disclaimer');
  }, []);

  return (
    <Dashboard noDrawer noMargin noPadding fullWidth merchantPage>
      <Grid container direction="column" sx={{ width: '100%' }} className="policy-page">
        <Grid container justifyContent="center">
          <Grid direction="column" container item alignItems="center">
            <Grid sx={{ margin: '12px 0px 28px', maxWidth: '1408px', width: '100%', padding: '0 24px' }}>
              <CaptionPrimary style={{ color: 'rgba(1, 33, 105, 1)', cursor: 'pointer' }} onClick={() => navigate('/')}>Home</CaptionPrimary>
              <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
              <CaptionPrimary style={{ marginBottom: '4px' }}>Disclaimer</CaptionPrimary>
            </Grid>
            <Grid
              container
              justifyContent="center"
              sx={{
                margin: '90px 0 32px',
                background: 'linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)'
              }}>
              <Grid
                container
                sx={{
                  maxWidth: '1408px',
                  width: '100%',
                  padding: '0 24px'
                }}>
                <HeaderFourSecondary style={{ maxWidth: '783px', width: '100%', marginBottom: '4px' }}>
                  Disclaimer
                </HeaderFourSecondary>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ color: 'rgba(0, 0, 0, 0.54)', maxWidth: { sm: '672px' }, width: '100%', padding: '0 24px'  }}>
            <HeaderSix>
              Your privacy on this website
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                This site is operated and maintained by the AkzoNobel entity referred to in the “Website Owner” section of this website (hereafter “AkzoNobel” or “we”). AkzoNobel is the controller of the processing of all personal data collected through this website.
              </p>
              <p>
                The primary purpose of this website is to be a dynamic resource and business tool so that we can offer our services to you in the best possible way. We want you to feel secure when visiting our site and are committed to maintaining your privacy when doing so. The following provides an overview of how we protect your privacy during your visit, based on and in accordance with applicable privacy legislation.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              What information do we collect?
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                Information on this website is collected in two ways: directly (e.g. when you provide information on various pages of this website) and indirectly (e.g. through our site’s technology). This is further explained below.
              </p>
              <p>
                We collect information that you voluntarily submit it to us, e.g. by completing forms on the website, by registering for a conference or by participating in an online survey. When we collect this type of information, we will notify you as to why we are asking for information and how this information will be used. It is completely up to you whether or not you want to provide it. The following categories of personal data may be collected: name, contact details and other information that you enter on forms on the website.
              </p>
              <p>
                One example of information we collect indirectly is through our Internet access logs. When you access this website, your Internet address is automatically collected and is placed in our Internet access logs.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Cookies
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                We also collect information through the use of cookies. Cookies are small files of information which save and retrieve information about your visit to this website - for example, how you entered our site, how you navigated through the site, and what information was of interest to you. The cookies we use identify you merely as a number. If you are uncomfortable regarding cookies use, please keep in mind you can disable cookies on your computer by changing the settings in preferences or options menu in your browser. However, if your browser does not accept cookies from this website, you may not be able to access or use all features of this website.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              How do we use this information?
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                We use your personal data to maintain this website such as answering your questions, processing your enrolment for a conference and sending information to you regarding any current or future activities of AkzoNobel. If legally required, we will obtain your permission, before sending you such information. We analyze the data which is collected indirectly to determine what is most effective about our site, to help us identify ways to improve it, and eventually, to determine how we can tailor this website to make it more effective. We may also use data for other purposes, which we would describe to you at the point we collect the information.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Will we share this with outside parties?
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                As a global organization, data we collect may be transferred internationally throughout AkzoNobel’s worldwide organization. We will not sell individual information and will share it only with our advisors. There will be other times when we need to share information, for example, in the case of an event where we need to provide our caterer with meal preference information. But again, before you submit any information, we will notify you as to why we are asking for specific information and it is completely up to you whether or not you want to provide it.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              What about sensitive personal data?
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                We do not generally seek to collect sensitive personal data through this site. If we do seek to collect such data, we will ask you to consent to our proposed uses of the data. We may also collect some sensitive personal data incidentally. By providing us with unsolicited sensitive personal data, you consent to our using the data subject to applicable law as described in this Privacy and Cookie Statement. The references to “sensitive personal data” are to the various categories of personal data identified by European and other data privacy laws as requiring special treatment, including in some circumstances the need to obtain explicit consent. These categories comprise personal identity numbers, personal data about personality and private life, racial or ethnic origin, nationality, political opinions, membership of political parties or movements, religious, philosophical or other similar beliefs, membership of a trade union or profession or trade association, physical or mental health, genetic code, addictions, sexual life, property matters or criminal record (including information about suspected criminal activities).
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Security and retention period
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                The transfer, storage and processing of personal data collected through this website is secured by means of current, usual technical measures. In general, AkzoNobel will not retain the personal data that is obtained through this website for a period longer than is necessary for achieving the purposes for which they were collected, or as long as the law prescribes.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Do you have access to your information?
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                Based on this Privacy and Cookie Statement, you are entitled to know whether we hold information about you and, if we do, to have access to that information and require it to be corrected if it is inaccurate. You also have the right to have your personal data erased or blocked if any of the processing of it is in violation with the applicable privacy legislation.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Google Analytics terms of service
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                This website uses Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics uses “cookies”, which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States . Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.
              </p>
            </BodyOneSecondary>
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{
        background: 'linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Grid item sx={{ maxWidth: '1408px', width: '100%' }}>
          <Footer />
        </Grid>
      </Grid>
    </Dashboard>
  );
}
