/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Container, Grid, useMediaQuery } from '@mui/material';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { IStyles, LIGHT_THEME } from '../constants/theme';
import { NoItems } from '../components-molecules/NoItems';
import FileIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { TabPanel } from '@mui/lab';
import { ThreadList } from './ThreadList';
import { GetAvailableTms, GetMerchantThreads, GetTerritoryManagerThreads, GetThreadMerchants, GroupedThread } from '../service/serviceService';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SearchbarWithDeboubce } from '../components-molecules/SearchBarWithDebounce';
import axios from 'axios';

interface IProps {
  tabValue?: string;
  currentTab?: string;
}

type RecipientGroup = 'merchant' | 'territoryManager';

export type SortType = 'asc' | 'desc';
const SORT_TYPES = [
  {
    title: 'A \u2014 Z',
    value: 'asc'
  },
  {
    title: 'Z \u2014 A',
    value: 'desc'
  }
];
export function DocumentRepository({ tabValue, currentTab }: IProps): JSX.Element {
  const [searchOpen, setSearchOpen] = useState(false);
  const userData = useTypedSelector((state) => state.userReducer.data);
  const superData = useTypedSelector((state) => state.userReducer.super);
  const token = useTypedSelector(state => state.userReducer.token);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [threadListData, setThreadListData] = useState<GroupedThread[]>([]);
  const [query, setQuery] = useState('');
  const [sortType, setSortType] = useState<SortType>('desc');
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  const [page, setPage] = useState(0);
  const size = 10;
  const navigate = useNavigate();
  const getRecipientType = (tab: any) => {
    return tab === 'accessRights' ? 'territoryManager' : 'merchant';
  };
  const recipientType: RecipientGroup = getRecipientType(currentTab);
  const [filterOptions, setFilterOptions] = useState({ 'Date Range': [], 'Attachments': [{ title: 'Yes', value: 'true' }, { title: 'No', value: 'false' }], 'Archived': [{ title: 'Yes', value: 'true' }, { title: 'No', value: 'false' }] });
  const [filter, setFilter] = useState<ISelectedOptions>({ 'Date Range': [], 'Attachments': [] });
  const emptyFilter = { 'Date Range': [], Attachments: [], Archived: [] };
  const [refresh, setRefresh] = useState(false);
  const [merchantNameList, setMerchantNameList] = useState<any>([]);
  const [territoryNameList, setTerritoryNameList] = useState<any>([]);
  const [firstRender, setFirstRender] = useState(false);
  const [filterReset, setFilterReset] = useState(false);
  const cancelTokenSource = axios.CancelToken.source();
  useEffect(() => {
    setPage(0);
    if (token && userData && userData.IsAdmin) {
      getMerchantNameList();
      getTerritoryNameList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const getMerchantNameList = () => {
    if (!token || recipientType !== 'merchant') { return; }

    if (merchantNameList?.length) {
      setFilterOptions((prev: any) => ({
        ...prev,
        'Managers': null,
        'Merchants': merchantNameList,
      }));

      return;
    }

    GetThreadMerchants(
      token,
      {
        OrderBy: 'asc',
      },
      response => {
        const merchants: any[] = [];
        response.Result.map(m => {
          m.Merchants.forEach(d => merchants.push(({ title: d.Name, value: d.Id })));
        });
        setMerchantNameList(merchants);
        setFilterOptions((prev: any) => ({
          ...prev,
          'Managers': null,
          'Merchants': merchants
        }));
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {});
  };
  const getTerritoryNameList = () => {
    if (!token || recipientType !== 'territoryManager') { return; }

    if (territoryNameList?.length) {
      setFilterOptions((prev: any) => ({
        ...prev,
        'Merchants': null,
        'Managers': territoryNameList
      }));

      return;
    }

    GetAvailableTms(
      token,
      (response) => {
        const tms = [...response.Result.map((tm: { FirstName: string, LastName: string, Id: string; }) => ({ title: `${tm.FirstName} ${tm.LastName}`, value: tm.Id }))];
        setTerritoryNameList(tms);
        setFilterOptions((prev: any) => ({
          ...prev,
          'Merchants': null,
          'Managers': tms
        }));
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  };
  const getMerchantThreads = useCallback((canceltoken = undefined, prev: GroupedThread[] = [], page = 0, thisFilter = filter) => {
    setLoading(true);
    if (token) {
      const paginatedBody = {
        Query: query,
        Page: page,
        Size: size,
        OrderBy: sortType,
        DateFrom: thisFilter['Date Range']?.[0],
        DateTo: thisFilter['Date Range']?.[1],
        HasAttachment: thisFilter.Attachments?.length === 1 ? thisFilter.Attachments[0] === 'true' ? true : false : undefined,
        IsArchived: thisFilter.Archived?.length === 1 ? thisFilter.Archived[0] === 'true' ? true : false : undefined,
        ManagerIds: thisFilter['Managers'],
      };
      GetMerchantThreads(token, paginatedBody, (response => {
        if (page === 0) {
          setThreadListData(response.Result.Result);
        } else {
          let temp = [...prev];
          response.Result.Result.map(m => {
            const tempMatch = temp.find((f) => f.Group === m.Group);
            if (tempMatch) {
              temp[temp.indexOf(tempMatch)].Threads = [...temp[temp.indexOf(tempMatch)].Threads, ...m.Threads];
            } else {
              temp = [...temp, m];
            }
          });
          setThreadListData(temp);
        }
        setTotalCount(response.Result.Count);
        setLoading(false);
      }), () => {
        setLoading(false);
      }, canceltoken);
    }
  }, [sortType, token, query, filter]);

  const getTerritoryManagerThreads = useCallback((cancelToken = undefined, prev: GroupedThread[] = [], page = 0, thisFilter = filter) => {
    setLoading(true);
    if (token) {
      const paginatedBody = {
        Query: query,
        Page: page,
        Size: size,
        OrderBy: sortType,
        DateFrom: thisFilter['Date Range']?.[0],
        DateTo: thisFilter['Date Range']?.[1],
        HasAttachment: thisFilter.Attachments?.length === 1 ? thisFilter.Attachments[0] === 'true' ? true : false : undefined,
        IsArchived: thisFilter.Archived?.length === 1 ? thisFilter.Archived[0] === 'true' ? true : false : undefined,
        ManagerIds: thisFilter['Managers'],
      };
      GetTerritoryManagerThreads(token, paginatedBody, (response => {
        if (page === 0) {
          setThreadListData(response.Result.Result);
        } else {
          let temp = [...prev];
          response.Result.Result.map(m => {
            const tempMatch = temp.find((f) => f.Group === m.Group);
            if (tempMatch) {
              temp[temp.indexOf(tempMatch)].Threads = [...temp[temp.indexOf(tempMatch)].Threads, ...m.Threads];
            } else {
              temp = [...temp, m];
            }
          });
          setThreadListData(temp);
        }
        setTotalCount(response.Result.Count);
        setLoading(false);
      }), () => {
        setLoading(false);
      }, cancelToken);
    }
  }, [sortType, token, query, filter]);


  const searchTermOnChanged=(text:string) => {
    setPage(0);
    setLoading(true);
    setQuery('');
    setTimeout(() => {
      setQuery(text);
    }, 100);
  };

  useEffect(() => {
    if (recipientType === 'territoryManager') {
      getTerritoryManagerThreads(cancelTokenSource.token);
      return () => {
        cancelTokenSource.cancel();
      };
    } else {
      getMerchantThreads(cancelTokenSource.token);
      return () => {
        cancelTokenSource.cancel();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, getTerritoryManagerThreads, getMerchantThreads]);
  useEffect(() => {
    if (firstRender) {

      if (!Object.values(filter).some(s => s.length > 0)) {
        if (recipientType === 'territoryManager') {
          getTerritoryManagerThreads(undefined, [], 0, emptyFilter);
        } else {
          getMerchantThreads(undefined, [], 0, emptyFilter);
        }
      } else {
        setFilterReset(true);
      }
    }
    return () => setFirstRender(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientType, firstRender]);
  const loadMore = () => {
    setPage(prev => prev + 1);
    if (recipientType === 'territoryManager') {
      getTerritoryManagerThreads(undefined, threadListData, page + 1);
    } else {
      getMerchantThreads(undefined, threadListData, page + 1);
    }
  };

  const getItemCount = () => {
    if (threadListData.length > 0) return threadListData.reduce((count, current) => count + current.Threads.length, 0);
    return 0;
  };
  return (
    <>
      {userData && userData.IsAdmin && !superData && tabValue
        ? <TabPanel value={tabValue} style={styles.p0}>
          <InfiniteScroll
            style={{ overflow: 'hidden' }}
            dataLength={getItemCount()} //This is important field to render the next data
            next={() => loadMore()}
            scrollThreshold={smallDown ? '550px' : '50px'}
            hasMore={getItemCount() < totalCount}
            loader={
              <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
                <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
              </Container>
            }
          >
            <PageHeader
              titleSectionRow
              subtitle={loading ? '' : `Showing 1 - ${getItemCount()} of ${totalCount}`}
              activeButtons={true}
              buttonTitle={userData && !superData && ((userData.IsTerritoryManager) || (userData.IsSalesManager && recipientType !== 'merchant')) ? 'New Thread' : ''}
              modalCallback={() => navigate('/admin/new-thread', { state: { navigateBackTo: window.location.pathname, currentTab } })}
              onClickSearch={() => setSearchOpen(!searchOpen)}
              hasFilter
              noSort={userData && !userData.IsAdmin || !!superData}
              sortTypes={SORT_TYPES}
              selectedSortType={sortType}
              filterReset={filterReset}
              setFilterReset={setFilterReset}
              handleSortSelect={(val) => {
                setPage(0);
                setSortType(val as SortType);
              }}
              filterOptions={filterOptions}
              onFilterSubmit={(selected) => {
                setPage(0);
                setFilter(selected);
                setLoading(true);
              }}
              noPadding
            />
            <SearchbarWithDeboubce
              isOpen={searchOpen}
              query={query}
              placeholder="Search Document Library"
              onCancel={() => {
                setSearchOpen(false);
                if (query !== '') {
                  setPage(0);
                  setQuery('');
                  setLoading(true);
                  setThreadListData([]);
                }
              }}
              onChange={searchTermOnChanged}
            />
            {loading && page === 0
              ? <Grid container item justifyContent="center" alignItems="center">
                <CircularProgress
                  color="primary"
                  size={50}
                  style={{ zIndex: 999, margin: '56px 0' }}
                />
              </Grid>
              : threadListData.length > 0
                ? <ThreadList data={threadListData} currentTab={currentTab || 'content'} onArchive={() => setRefresh(prev => !prev)} />
                : <NoItems
                  icon={<FileIcon style={styles.noItemsIcon} />}
                  title="No threads yet"
                  description="You haven’t created or received any threads yet. Start by creating a new thread."
                />
            }
          </InfiniteScroll>
        </TabPanel>
        : <Container style={styles.container}>
          <InfiniteScroll
            style={{ overflow: 'hidden' }}
            dataLength={getItemCount()} //This is important field to render the next data
            next={() => loadMore()}
            scrollThreshold={smallDown ? '550px' : '50px'}
            hasMore={getItemCount() < totalCount}
            loader={
              <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
                <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
              </Container>
            }
          >
            <PageHeader
              titleSectionRow
              noMargin
              title={'Threads'}
              subtitle={loading ? '' : `Showing 1 - ${getItemCount()} of ${totalCount}`}
              activeButtons={true}
              buttonTitle={userData && !superData && ((userData.IsTerritoryManager) || (userData.IsSalesManager && recipientType !== 'merchant')) ? 'New Thread' : ''}
              modalCallback={() => navigate('/admin/new-thread', { state: { navigateBackTo: window.location.pathname, currentTab } })}
              onClickSearch={() => setSearchOpen(!searchOpen)}
              hasFilter
              noSort={userData && !userData.IsAdmin || !!superData}
              sortTypes={SORT_TYPES}
              selectedSortType={sortType}
              handleSortSelect={(val) => {
                setPage(0);
                setSortType(val as SortType);
              }
              }
              filterOptions={filterOptions}
              onFilterSubmit={(selected) => {
                setPage(0);
                setFilter(selected);
                setLoading(true);
                setThreadListData([]);
              }}
              noPadding
            />
            <SearchbarWithDeboubce
              isOpen={searchOpen}
              query={query}
              placeholder="Search Document Library"
              onCancel={() => {
                setSearchOpen(false);
                if (query !== '') {
                  setPage(0);
                  setQuery('');
                  setLoading(true);
                  setThreadListData([]);
                }
              }}
              onChange={searchTermOnChanged}
            />
            {loading && page === 0
              ? <Grid container item justifyContent="center" alignItems="center">
                <CircularProgress
                  color="primary"
                  size={50}
                  style={{ zIndex: 999, margin: '56px 0' }}
                />
              </Grid>
              : threadListData.length > 0
                ? <ThreadList data={threadListData} currentTab={currentTab || 'content'} onArchive={() => setRefresh(prev => !prev)} />
                : <NoItems
                  icon={<FileIcon style={styles.noItemsIcon} />}
                  title="No threads yet"
                  description="You haven’t created or received any threads yet. Start by creating a new thread."
                />
            }
          </InfiniteScroll>
        </Container>}</>
  );
}

const styles: IStyles = {
  p0: { padding: 0 },
  container: { maxWidth: '720px', width: '100vw' },
  divider: { width: '100%', marginTop: '32px' },
  noItemsIcon: { fontSize: '88px', fill: LIGHT_THEME.palette.primary.main },
  circularProgress: { margin: '48px 0' }
};
