import { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Section } from './section/Section';
import { SubPageHeader } from './titlebars/SubPageHeader';
import { ButtonContainedPrimary } from '../components-atoms/ButtonComponents';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from '@mui/material';

interface ModalComponentProps {
  children: null | JSX.Element | (JSX.Element | null)[];
  title: string;
  buttonTitle?: string;
  buttonDisabled?: boolean;
  isOpen: boolean;
  close?: (refresh?: boolean) => void;
  submit?: (e: any) => void;
  hasError?: boolean;
  buttonLoading?: boolean;
  buttons?: JSX.Element[];
  submitButton?: boolean;
  fullWidth?: boolean;
  assetDetailPage?: boolean;
  scrollToTop?: boolean;
}

export function ModalComponent({ children, title, buttonTitle, buttonDisabled, isOpen, close, submit, hasError = false, buttonLoading, buttons, submitButton = true, fullWidth, scrollToTop }: ModalComponentProps): JSX.Element {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const mobileView = useMediaQuery('(max-width: 480px)');

  useEffect(() => {
    setIsSubmitted(false); if (hasError || scrollToTop) {
      document.getElementsByClassName('modalComponent')?.[0]?.scroll(0, 0);
    }
  }, [isOpen, hasError, scrollToTop]);

  return (
    <Modal className="modalComponent" hideBackdrop open={isOpen} sx={{ position: 'absolute', overflow: 'hidden auto', margin: 0, padding: 0, background: '#fff' }}>
      <Box component="form" noValidate onSubmit={(e: any) => { setIsSubmitted(true); if (submit) submit(e); }} sx={{ width: '100vw', minHeight: '100vh', borderRadius: 0, margin: 0, padding: '0 0 36px' }}>
        <Box sx={{ width: '100vw', boxShadow: '0 3px 3px 0 rgb(51 51 51 / 11%)', background: '#fff', position: 'sticky', top: 0, zIndex: 99 }}>
          <Container sx={{
            maxWidth: fullWidth ? 'auto !important' : '840px !important',
            padding: fullWidth ? 'auto !important' : '0 !important'
          }}>
            <Toolbar variant="modal" sx={{ height: mobileView ? '106px' : '96px' } }>
              <SubPageHeader
                title={title}
                backCallback={close}
                buttons={[submitButton
                  ? <ButtonContainedPrimary data-testid={`modal-button-${buttonTitle}`} key={0} type="submit" disabled={isSubmitted || buttonDisabled}>
                    {buttonLoading ? (
                      <CircularProgress
                        color='inherit'
                        size={18}
                        sx={{ marginRight: 2 }}
                      />
                    ) : (
                      <></>
                    )}
                    {buttonTitle}</ButtonContainedPrimary> : <></>,
                ].concat(buttons ? buttons : [])}
                assetDetailPage
              />
            </Toolbar>
          </Container>
        </Box>
        {children}
      </Box>
    </Modal>
  );
}

interface ModalCardComponentProps {
  children: null | JSX.Element | (JSX.Element | null)[];
  showOverflowingChild?: boolean;
}

export function ModalCardComponent({ children, showOverflowingChild }: ModalCardComponentProps): JSX.Element {
  return (
    <Container maxWidth={false} sx={{ maxWidth: '840px', paddingLeft: { xs: '16px', md: 0 }, paddingRight: { xs: '16px', md: 0 } }}>
      <Section fullPadding showOverflowingChild={showOverflowingChild}>
        <Grid container sx={{ flexFlow: 'column' }}>
          {children}
        </Grid>
      </Section>
    </Container>
  );
}
