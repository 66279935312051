import { Grid, IconButton, InputAdornment, useMediaQuery } from '@mui/material';
import { LIGHT_THEME } from '../../constants/theme';
import { HeaderSix } from '../../components-atoms/TypographyComponents';
import { ServicePageHeaderInfoModal } from '../../components-organisms/modals/ServicePageHeaderInfoModal';
import DebouncedInput from '../DebouncedInput';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { KnowledgeBaseSearch } from '../../components-organisms/KnowledgeBaseSearch';

interface IServicePageHeaderFirstRowProps {
  inputRef: any;
  hideSearch?: boolean;
  service: any;
  noModal?: boolean;
  infoModal?: any;
  filterMenuVisible?: boolean;
  searchPlaceholder?: string;
  isAdmin?: boolean;
  onToggleFilterMenu?: () => void;
  customSearchbar?: any;
  onSearch: (query: string) => void;
}

export default function ServicePageHeaderFirstRow(props: IServicePageHeaderFirstRowProps): JSX.Element {
  const [savedQuery] = useTypedSelector((state) => [state.digitalAssetReducer.search]);
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));

  const {
    inputRef,
    hideSearch,
    service, noModal,
    infoModal,
    filterMenuVisible,
    searchPlaceholder,
    isAdmin,
    onToggleFilterMenu,
    customSearchbar,
    onSearch
  } = props;

  function renderTitle() {
    return (
      <Grid
        item
        display='flex'
        flexDirection='row'
        flex={smallDown ? 1 : 0}
        alignItems='center'>
        <img
          src={require(`../../assets/img/category/${service.ImageUrl}.png`)}
          style={{ height: '48px', width: '48px' }} />
        <HeaderSix sx={{
          margin: '0px 0px 0px 16px',
          whiteSpace: 'nowrap',
          fontSize: {
            xl: '20px',
            lg: '20px',
            md: '14px',
            sm: '14px',
            xs: '14px',
          }
        }}>
          {service.Name}
        </HeaderSix>
      </Grid>
    );
  }

  function renderSearchbar() {
    // TODO
    if (customSearchbar && customSearchbar === 'knowledgeBase') {
      return (
        <KnowledgeBaseSearch
          categoryId={service.CategoryId}
          serviceId={service.Id}
        />
      );
    }

    return (
      <DebouncedInput
        ref={inputRef}
        fullWidth
        defaultValue={savedQuery}
        name='Search'
        placeholder={searchPlaceholder || 'Search'}
        size='small'
        onChange={onSearch}
        InputProps={{
          endAdornment:
            savedQuery?.length > 2
            && <InputAdornment position='end'>
              <IconButton edge='end' onClick={() => onSearch?.('')}>
                <CloseIcon sx={{ color: '#384f9a' }} />
              </IconButton>
            </InputAdornment>
        }}
        sx={{
          pr: smallDown ? '12px' : 0,
          mt: -1,
          input: {
            pb: 1.5,
            pt: 2,
          },
        }}
      />
    );
  }

  function renderModal() {
    if (noModal || isAdmin) { return; }

    if (infoModal) {
      return infoModal();
    }

    return (
      <ServicePageHeaderInfoModal
        title={service.Name}
        description={service.Description}
      />
    );
  }

  function renderInfoAndArrow() {
    return (
      <Grid
        item
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: '8px'
        }}>
        {renderModal()}
        {onToggleFilterMenu
          && <IconButton sx={{ width: 48, height: 48 }} onClick={onToggleFilterMenu}>
            <KeyboardArrowUpIcon sx={{
              transition: 'all 0.3s',
              transform: `rotate(${filterMenuVisible ? '0deg' : '180deg'})`
            }} />
          </IconButton>
        }
      </Grid>
    );
  }

  if (hideSearch) {
    return (
      <Grid
        data-test-id='title-and-arrows'
        container
        pl={{ xs: '20px', sm: 4, md: 4, lg: 4, xl: 4 }}
        pr={{ xs: 1, sm: 1, md: '20px', lg: '20px', xl: '20px' }}
        height='64px'
        justifyContent='space-between'>
        <Grid display='flex' flexDirection='row' alignItems='center'>
          {renderTitle()}
        </Grid>
        {renderInfoAndArrow()}
      </Grid>
    );
  }

  return (
    <Grid
      data-test-id='title-and-arrows'
      container
      display='flex'
      alignItems='center'
      justifyContent={{ xs: 'space-between', sm: 'initial' }}
      pr={{ xs: 1, sm: 1, md: '20px', lg: '20px', xl: '20px' }}
      pl={{ xs: '20px', sm: 4, md: 4, lg: 4, xl: 4 }}
      px={isAdmin ? 0 : undefined}
      py={1}
      height={smallDown ? 'auto' : '64px'}>
      {!isAdmin
        && <Grid display='flex' flexDirection='row' alignItems='center'>
          {renderTitle()}
        </Grid>
      }

      <Grid
        item
        sm
        xs={12}
        pl={smallDown ? 0 : isAdmin ? 0 : 3}
        pr={smallDown ? 0 : 3}
        pt={smallDown ? 2 : 1}
        order={smallDown ? 3 : 'inherit'}>
        {renderSearchbar()}
      </Grid>
      {renderInfoAndArrow()}
    </Grid>
  );
}
