import TabPanel from '@mui/lab/TabPanel';
import { StoresList } from './StoresList';
import { NoItems } from '../components-molecules/NoItems';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import StoreDisabled from '../assets/icons/StoreDisabled.svg';
import { Icon } from '@mui/material';
import { LIGHT_THEME } from '../constants/theme';

interface MerchantStoreTabPanelProps {
  merchant: Merchant;
  setPageOpen: () => void;
}

export function MerchantStoreTabPanel({ merchant, setPageOpen }: MerchantStoreTabPanelProps): JSX.Element {
  const hasStores = merchant.Stores.length > 0 && merchant.Stores[0].Id !== '00000000-0000-0000-0000-000000000000';
  const userData = useTypedSelector(state => state.userReducer.data);
  return (
    <TabPanel value="stores" sx={{ padding: 0 }}>
      <PageHeader
        title=""
        subtitle={
          (hasStores)
            ? `${merchant.Stores.length} ${(merchant.Stores.length > 1) ? 'Stores' : 'Store'}`
            : 'No Stores Yet'
        }
        buttonTitle="Add Store"
        activeButtons={false}
        modalCallback={(userData && userData.Role !== 'Store Employee' && userData.Role !== 'Store Manager') ? setPageOpen : undefined}
        pageHeaderContainerStyle={{
          marginTop: '32px'
        }}
      />

      {(hasStores) // TODO: oof
        ? <StoresList stores={merchant.Stores} navigateBackTo={window.location.pathname} />
        : <NoItems
          icon={
            <Icon sx={{ fontSize: 88, fill: LIGHT_THEME.palette.primary.main }}>
              <img src={StoreDisabled}/>
            </Icon>
          }
          title="No stores yet"
          description="You haven’t added any stores to this merchant yet. Start by adding the first store."
        />
      }
    </TabPanel>
  );
}
