import Avatar from '@mui/material/Avatar';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import { LIGHT_THEME } from '../constants/theme';
import { CenteredDialogComponent } from './dialogs/CenteredDialog';
import { BodyOneSecondary, HeaderFive } from '../components-atoms/TypographyComponents';

interface NoItemsProps {
  title: string;
  description: string;
  icon?: JSX.Element;
}

export function NoItems({ title, description, icon = <DomainDisabledIcon sx={{ fontSize: 88, fill: LIGHT_THEME.palette.primary.main }} /> }: NoItemsProps): JSX.Element {
  return (
    <CenteredDialogComponent sx={{ height: '602px', maxHeight: '65vh', maxWidth: '320px', width: '100%' }}>
      <Avatar sx={{ height: '160px', width: '160px', background: LIGHT_THEME.palette.action.selected }}>
        {icon}
      </Avatar >
      <HeaderFive sx={{ textAlign: 'center', marginTop: '32px' }}>{title}</HeaderFive>
      <BodyOneSecondary sx={{ textAlign: 'center' }}>{description}</BodyOneSecondary>
    </CenteredDialogComponent >
  );
}
