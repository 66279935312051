import { Grid } from '@mui/material';
import { BodyTwoSecondary, HeaderSix, OverlineSecondary, SubtitleOnePrimary } from '../components-atoms/TypographyComponents';
import placeholder from '../assets/img/article@2x.png';
import '../scss/components/_recent-items.scss';
import '../scss/components/_thumbnail.scss';
import { FormatDate } from '../utils/dateUtils';

interface IProps {
  articles: Array<{
    date: string;
    link: string;
    title: string;
  }>;
}

export function DidYouKnow({ articles }: IProps): JSX.Element {
  return (
    <>
      <section className="recent-items">
        <div className="recent-items__title">
          <HeaderSix>Did You Know?</HeaderSix>
        </div>
        <Grid container item columnSpacing={20 / 8} alignItems="center">
          {articles.map(article => (
            <Grid key={article.title} className="recent-items__content" item lg={4} xl={4} xs={12} md={6} sm={6} >
              <a
                onClick={() => window.open(`/detail/article/${article.link}`, '_self')}
                className="recent-item recent-item--link"
              >
                <img
                  src={placeholder}
                  className="
                    recent-item__media
                    thumb thumb--large thumb--picture-icon
                  "
                />
                <div className="recent-item__content">
                  <OverlineSecondary>
                    Knowledge Base
                  </OverlineSecondary>
                  <SubtitleOnePrimary className="u-text-truncate-multi">
                    {article.title}
                  </SubtitleOnePrimary>
                  <BodyTwoSecondary>
                    {FormatDate(new Date(article.date), { day: 'numeric', month: 'short', format: 'dd MM YYYY' })}
                  </BodyTwoSecondary>
                </div>
              </a>
            </Grid>
          ))}
        </Grid>
      </section>
    </>
  );
}
