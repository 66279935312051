import { useState } from 'react';
import { Box, IconButton, Link } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { MiniDialog } from '../../components-molecules/dialogs/MiniDialog';
import { BodyOneSecondary } from '../../components-atoms/TypographyComponents';

export function DigitalAssetInfoModal(): JSX.Element {
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  
  function infoDialog() {
    return(
      <MiniDialog
        noCancel
        title="Instant access to logos, documents & templates on a click of a button"
        open={showInfoDialog}
        close={() => {
          setShowInfoDialog(false);
        }}
        titleSx={{
          mt: 5,
          py: 3
        }}
        bodySx={{
          height: 'unset'
        }}
        childrenSx={{
          py: 5
        }}
      >
        <Box px={3}>
          <BodyOneSecondary>
            Filter results by Brand, Product name and type of asset or just type what you want to search and get access to your desired asset as and when required.
          </BodyOneSecondary>
          <br/>
          <BodyOneSecondary>
            For any further information about Brand Assets please <Link href="#">contact us.</Link>
          </BodyOneSecondary>
        </Box>
      </MiniDialog>
    );
  }

  return(
    <>
      <IconButton sx={{ width: 48, height: 48 }} onClick={()=> setShowInfoDialog(true)}>
        <InfoIcon />
      </IconButton>

      {infoDialog()}
    </>
  );
}
