import { EMAIL_VALIDATION, PHONE_VALIDATION_V2, REQUIRED_VALIDATION, validateWhiteSpace } from './_predefinedValidations';

export type SalesManagerFormTypes = {
  firstName: string,
  lastName: string,
  role: string,
  email: string,
  phone: string
};

type SalesManagerForm = [
  { title: string, fields: [FormInputField, FormInputField, FormSelectField, FormInputField, FormInputField]; }
];

export const SALES_MANAGER_FORM: SalesManagerForm = [
  {
    title: 'Manager details',
    fields: [
      {
        label: 'First name',
        name: 'firstName',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          validate: { validateWhiteSpace }

        }
      },
      {
        label: 'Last name',
        name: 'lastName',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          validate: { validateWhiteSpace }

        }
      },
      {
        label: 'Sales Manager Role',
        name: 'role',
        inputType: 'select',
        validation: {
          required: REQUIRED_VALIDATION
        }
      },
      {
        label: 'Email',
        name: 'email',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          pattern: EMAIL_VALIDATION
        }
      },
      {
        label: 'Phone number',
        name: 'phone',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          pattern: PHONE_VALIDATION_V2,
          validate: { validateWhiteSpace }

        }
      }
    ]
  }
];
