/* eslint-disable @typescript-eslint/naming-convention */

const imageFileTypes = '.jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .gif, .webp, .tiff, .tif, .bmp, .dib, .svg, .svgz, .apng, .avif, .pjpeg, .pjp, .heif, .heic, .eps, .psd, .ai';
const videoFileTypes = '.webm, .mpg, .mp2, .mpeg, .mpe, .mpv, .ogg, .mp4, .m4p, .m4v, .avi, .wmv, .mov, .qt, .flv, .swf, .avchd, .gxf, .ts, .ps, .3gp, .3gpp, .asf, .isma, .ismv, .dvr-ms, .mkv, .wav, .mxf';
const plainTextFileTypes = '.doc, .docx, .odt, .rtf';
const presentationFileTypes = '.odp, .pot, .potm, .potx, .pps, .ppsx, .ppsxm, .ppt, .pptm, .pptx, .pdf';
const spreadsheetFileTypes = '.csv, .ods, .xls, .xlsx';
const soundFileTypes = '.mp3, .m4a, .aac, .oga, .flac, .pcm, .aiff';
const compressedFileTypes = '.7z, .zip, .rar';

export default imageFileTypes + ', ' + videoFileTypes + ', ' + plainTextFileTypes + ', ' + presentationFileTypes + ', ' + spreadsheetFileTypes + ', ' + soundFileTypes + ', ' + compressedFileTypes;
