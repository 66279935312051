import { Grid, useMediaQuery } from '@mui/material';
import { MouseEventHandler } from 'react';
import { ButtonOutlinedPrimary } from '../components-atoms/ButtonComponents';
import { LIGHT_THEME } from '../constants/theme';
import DebouncedInput from './DebouncedInput';

interface IProps {
  isOpen: boolean;
  placeholder?: string;
  onChange: (text:string)=>void;
  onCancel: MouseEventHandler<HTMLButtonElement> | undefined;
  query: string;
}
export function SearchbarWithDeboubce({ isOpen, placeholder = 'Search', onChange, onCancel }: IProps): JSX.Element {
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));

  return (
    isOpen
      ? (
        <Grid container sx={{ marginTop: '10px' }} justifyContent="space-between">
          <Grid item sx={{ flex: 1 }}>
            
            <DebouncedInput 
              autoFocus
              fullWidth
              onChange={onChange}
              sx={{ padding: smallDown ? '0 0 8px' : '0 16px 0 0' }}
              variant="filled"
              name="Search"
              placeholder={placeholder}
            />
          </Grid>
          <Grid container item alignItems='center' justifyContent='flex-end' sx={{ width: smallDown ? '100%' : '96px' }}>
            <ButtonOutlinedPrimary
              data-testid='cancel-search-button'
              size='large'
              onClick={(e: any) => {
                if (onCancel) {
                  onCancel(e);
                }
                
              }}
            >
                Cancel
            </ButtonOutlinedPrimary>
          </Grid>
        </Grid >
      ) : <></>
  );
}
