/* eslint-disable @typescript-eslint/naming-convention */
import { Container, useMediaQuery } from '@mui/material';
import DuluxMerchantConnectLogo from '../assets/img/DuluxMerchantConnect-logo.png';
import { BodyTwoPrimary, BodyTwoSecondary, CaptionSecondary, SubtitleTwoPrimary } from '../components-atoms/TypographyComponents';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import parse from 'html-react-parser';
import ThreadAttachmentPreview from './ThreadAttachmentPreview';
import { IStyles, LIGHT_THEME } from '../constants/theme';
import { CommentDetail } from '../service/serviceService';

interface IProps {
  attachmentClicked:(type:string,preview:string,file:string,thumbnail:string,fileName:string)=>void,
  data :CommentDetail
  subject: string
}

const DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  day: '2-digit',
  month: 'long',
};

export default function ThreadItem({attachmentClicked, data, subject}: IProps): JSX.Element {
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));

  const getRecipients = () => {
    let recipients ='';
    data.Recipients.forEach(item => {
      if (recipients === '' ) {
        if (item.Merchant !== null) {
          recipients = item.FirstName + ' ' + item.LastName + ', ' + item.Merchant ;
        } else {
          recipients = item.FirstName + ' ' + item.LastName ;
        }
      } else {
        if (item.Merchant !== null) {
          recipients = recipients + ', ' + item.FirstName + ' ' + item.LastName + ', ' + item.Merchant;
        } else{
          recipients = recipients + ', ' + item.FirstName + ' ' + item.LastName ;
        }
      }
    });
    return recipients;
  };

  const getSender = () => {
    let text = '';
    if (data.CreatedBy.Merchant !== null && data.CreatedBy.Merchant !== '') {
      text = data.CreatedBy.FirstName + ' ' + data.CreatedBy.LastName + ', ' + data.CreatedBy.Merchant  ;
    } else {
      text = data.CreatedBy.FirstName + ' ' + data.CreatedBy.LastName  ;
    }
    return text;
  };

  const getLogo = () => {
    if (data.CreatedBy.Logo === null) {
      return DuluxMerchantConnectLogo;
    } else {
      return data.CreatedBy.Logo; 
    }
  };

  const getPreviewType = (type: string) => {
    if (type.includes('image')) {
      return 'image';
    } else if (type.includes('video')) {
      return 'video';
    } else {
      return 'pdf';
    }
  };

  const getAttachments = () => {
    return data.Attachments.map(attachment => {
      const previewUrl = getPreviewType(attachment.FileType) === 'image' ? attachment.FileUrl : attachment.PreviewUrl;
      return <ThreadAttachmentPreview key={attachment.PreviewUrl} 
        text={attachment.FileName} 
        url={attachment.ThumbnailUrl} 
        type={attachment.FileType}
        onClick={() => attachmentClicked(getPreviewType(attachment.FileType),previewUrl,attachment.FileUrl,attachment.ThumbnailUrl,attachment.FileName)}
        downloadClicked = {() => {downloadClicked(attachment.FileUrl,attachment.FileName);}}
      />;
    });
  };

  const downloadClicked= (fileUrl:string, fileName:string) => {
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = fileName;
    document.body.appendChild(a); 
    a.click();    
    a.remove();     
  };

  return (
    <Container style={styles.cardContainer} >
      <div style={{...styles.headerContainer,flexDirection: smallDown ? 'column' : 'row'}}>
        <div style={styles.titleContainer}>
          <div>
            <div style={styles.image}>
              <img src={getLogo()}style={styles.image}/>
            </div>
          </div>
          <div style={styles.infoContainer}>
            <SubtitleTwoPrimary component="span" sx={{  }}>{getSender()}</SubtitleTwoPrimary>
            <BodyTwoPrimary>{ subject }</BodyTwoPrimary>
            <div style={{display:'flex',flexDirection: 'row'}}>
              <BodyTwoPrimary>To:</BodyTwoPrimary>
              <BodyTwoSecondary style={{marginLeft: '4px'}}>{getRecipients()}</BodyTwoSecondary>
            </div>
          </div>
        </div>
        <div style={{
          ...styles.dateContainer, 
          alignItems: smallDown ? 'flex-start' : 'flex-end',
          marginTop: smallDown ? '24px' : undefined,  
          marginLeft: smallDown ? '46px' : '16px'
        }}>
          <CaptionSecondary style={{...styles.captionText, marginLeft: smallDown ? '4px' : undefined }}>
            {new Date(data.Created || '').toLocaleDateString(['en-gb'], DATE_OPTIONS)}
          </CaptionSecondary>
          {data.Attachments.length > 0 && <div style={styles.headerAttachmentContainer}>
            <AttachFileIcon style={styles.attachMiniIcon} />
            <CaptionSecondary style={styles.captionText}>{ data.Attachments.length } Attachments</CaptionSecondary>
          </div>}
        </div>
      </div>
      <div style={styles.body}>
        <BodyTwoSecondary
          sx={{
            '& > h2, h3, p': { marginTop: 0, marginBottom: '12px' },
            '& > *': { fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif' },
            '& > h2': {
              fontWeight: 600,
              fontSize: '20px;',
              lineHeight: '160%',
              letterSpacing: '0.15px'
            },
            '& > h3': {
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '22px'
            }
          }}
        >
          {parse(data.Body)}
        </BodyTwoSecondary>
      </div>
      {data.Attachments.length > 0 && <div style={styles.attachmentsContainer}>
        <div style={styles.attachmentTitleContainer}>
          <SubtitleTwoPrimary component="span">File Attachments</SubtitleTwoPrimary>
          <CaptionSecondary style={styles.captionFileNumberText}>{ data.Attachments.length } Files</CaptionSecondary>
        </div>
        <div style={styles.fileAttachmentContainer}>
          {getAttachments()}
        </div>
      </div>}
    </Container>
  );
}
const styles: IStyles = {
  cardContainer: { 
    maxWidth: '840px',
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.12)', 
    borderRadius: '20px',
    margin: '24px 0px',
    padding: '24px 24px'
  },
  headerContainer: {
    width:'100%',
    display:'flex',
    flexDirection:'row'
  },
  infoContainer: {
    marginLeft:'16px',
    display: 'flex',
    flexDirection: 'column',
    width:'100%'
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  captionText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  headerAttachmentContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '4px'
  },
  attachMiniIcon: {
    width: '16px',
    height: '16px',
    color: '#00000099',
    marginRight: '8px'
  },
  body: {
    paddingTop: '24px',
    paddingBottom: '24px'
  },
  attachmentsContainer: {

  },
  attachmentTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginBottom: '24px'
  },
  captionFileNumberText: {
    marginLeft: '8px'
  },
  image: {
    width: '32px',
    height: '32px'
  },
  titleContainer: {
    width: '100%',
    display: 'flex'
  },
  fileAttachmentContainer: {
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'repeat(auto-fit, minmax(164px, 212px))'
  }
};
