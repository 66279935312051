/* eslint-disable @typescript-eslint/naming-convention */
import { Box } from '@mui/material';
import { TabContext } from '@mui/lab';
import { Dashboard } from '../../../components-organisms/Dashboard';
import { PageHeader } from '../../../components-molecules/titlebars/PageHeader';
import { DigitalAssetService } from '../../DigitalAssetService';

const currentTab = 'content';
export function BrandAssetServiceDetail(): JSX.Element {
  return (
    <Dashboard justifyContent='flex-start'>
      <PageHeader title="Brand Assets" />
      <TabContext value={currentTab}>
        <Box sx={{ minHeight: '80vh', width: '100%' }}>
          <DigitalAssetService
            isAdmin
            service={{
              Name: 'Digital Assets',
              ImageUrl: 'branded-asset'
            }}
          />
        </Box>
      </TabContext>
    </Dashboard>
  );
}

export default BrandAssetServiceDetail;
