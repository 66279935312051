import { useCallback, useEffect, useState } from 'react';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { NoItems } from '../components-molecules/NoItems';
import { TabPanel } from '@mui/lab';
import { NPDList } from '../components-organisms/NPDList';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { ChangeProductPublishStateRequest, DeleteProductRequest, GetProductRequest, GetProductsRequest, ReOrderProductsRequest } from '../service/serviceService';
import { MiniDialog } from '../components-molecules/dialogs/MiniDialog';
import { BodyOneSecondary } from '../components-atoms/TypographyComponents';
import { Box, CircularProgress } from '@mui/material';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import { LIGHT_THEME } from '../constants/theme';
import { useNavigate } from 'react-router-dom';
import { ExternalPageRequest } from '../service/externalPageService';
import { SearchbarWithDeboubce } from '../components-molecules/SearchBarWithDebounce';

export function NewProductDevelopment(): JSX.Element {
  const pageSize = 10;
  const token = useTypedSelector(state => state.userReducer.token);
  const [page, setPage] = useState(0);
  const [products, setProducts] = useState<NPDItem[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>();
  const [total, setTotal] = useState<number>(0);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if(token){
      ExternalPageRequest(
        token,
        {Url: window.location.href}
      );
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  const loadMore = () => {
    if (token) {
      getProducts(token, page + 1, pageSize, products);
      setPage(page + 1);
    }
  };

  const getProducts = useCallback((t: string, page = 0, size = pageSize, prevData: NPDItem[] = []): void => {
    GetProductsRequest(
      t,
      { Page: page, Size: size, Query: query },
      (response) => {
        if (response) {
          setTotal(response.Result.Count);
          let temp = [...prevData];
          response.Result.Result.map(m => {
            const tempMatch = temp.find((f) => f.GroupName === m.GroupName);
            if (tempMatch) {
              temp[temp.indexOf(tempMatch)].Products = [...temp[temp.indexOf(tempMatch)].Products, ...m.Products];
            } else {
              temp = [...temp, m];
            }
          });
          setProducts(temp);
          setLoading(false);
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  }, [query]);

  const changePublishState = (id: string) => {
    if (token) {
      setProducts([]);
      setLoading(true);
      ChangeProductPublishStateRequest(
        token,
        {
          Id: id
        },
        () => { setRefresh(!refresh); setPage(0); },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  const onMove = (newData: Product[]) => {
    if (token) {
      const idList = newData.map(m => ({ Id: m.Id }));
      ReOrderProductsRequest(
        token,
        idList,
        () => { return; },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  const getProduct = (id: string) => {
    if (token) {
      GetProductRequest(
        token,
        id,
        (response) => {
          if (response) {
            dispatch({ type: 'SET_CURRENT_PRODUCT', payload: { currentData: response.Result } });
            navigate('/admin/edit-product');
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  const deleteProduct = (id: string) => {
    if (token) {
      DeleteProductRequest(
        token,
        id,
        () => { setRefresh(!refresh); setPage(0); },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  useEffect(() => {
    if (token) getProducts(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, query, refresh]);

  const searchOnChange = (text:string) =>{
    setQuery(text);
  };

  return (
    <TabPanel value="content" sx={{ padding: 0 }}>
      <PageHeader
        buttonTitle={'Add Product'}
        activeButtons={true}
        modalCallback={() => navigate('/admin/add-product')}
        onClickSearch={() => setSearchOpen(!searchOpen)}
        noSort
      />
      <SearchbarWithDeboubce
        isOpen={searchOpen}
        query={query}
        placeholder="Search Products"
        onCancel={() => { setSearchOpen(false); setQuery(''); }}
        onChange={searchOnChange}
      />
      {loading
        ? <CircularProgress
          color='primary'
          size={50}
          style={{ zIndex: 999, position: 'absolute', top: '50vh', right: '40vw' }}
        />
        : ((products.length > 0)
          ? <NPDList
            onMove={onMove}
            total={total}
            products={products}
            loadMore={loadMore}
            onEdit={(id) => {
              getProduct(id);
            }}
            onDelete={(id) => {
              setSelectedId(id);
              setModalOpen(true);
            }}
            onPublishChange={(id) => changePublishState(id)}
          />
          : <NoItems
            icon={<FormatPaintIcon sx={{ fontSize: 88, fill: LIGHT_THEME.palette.primary.main }} />}
            title="No products yet"
            description="You haven’t added any products yet. Start by adding the first product."
          />)
      }
      {modalOpen
        ? <MiniDialog
          title="Delete Product"
          open={modalOpen}
          disabled={!selectedId}
          close={() => {
            setModalOpen(false);
            setSelectedId(undefined);
          }}
          remove={() => {
            if (selectedId) {
              deleteProduct(selectedId);
            }
            setSelectedId(undefined);
            setModalOpen(false);
          }}>
          <Box sx={{ padding: '0 24px' }}>
            <BodyOneSecondary>
              {'Are you sure that you want to remove this Product?'}
            </BodyOneSecondary>
          </Box>
        </MiniDialog>
        : null
      }
    </TabPanel>
  );
}
