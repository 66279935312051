import { Fragment, useState, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import Grid from '@mui/material/Grid';

import { ModalCardComponent } from '../../components-molecules/Modal';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { FormControlInput, FormControlSelect } from '../../components-molecules/FormControl';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { UserFormTypes } from '../../forms/addUser';
import AddIcon from '@mui/icons-material/Add';
import { UserEditRequest } from '../../service/userService';
import { useTypedDispatch, useTypedSelector } from '../../hooks/TypedReduxHooks';
import { EMPLOYEE_FORM } from '../../forms/employee';
import { Box, Divider, FormHelperText } from '@mui/material';
import { ButtonContainedPrimary } from '../../components-atoms/ButtonComponents';
import { InfoComponent } from '../../components-atoms/InfoComponent';
import { ListDialog } from '../../components-molecules/dialogs/MiniDialog';
import { SectionRow } from '../../components-molecules/section/SectionRow';
import { AssignToStoreRequest, UnassignStoreRequest } from '../../service/storeService';
import { useLocation, useNavigate } from 'react-router-dom';

type GeneralType = {
  [key: string]: string;
};

export function EditUser(): JSX.Element {
  const [token, storesList, storesOfEmployee] = useTypedSelector((state) => [state.userReducer.token, state.merchantReducer.stores, state.merchantReducer.storesOfEmployee]);
  const [dialog, setDialog] = useState(false);
  const [storeList, setStoreList] = useState<Store[]>(storesOfEmployee);
  const [storesToUnassign, setStoresToUnassign] = useState<string[]>([]);
  const [storesToAssign, setStoresToAssign] = useState<string[]>([]);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const userData = state.employee;

  const defaultValues: GeneralType = {
    firstName: userData.FirstName,
    lastName: userData.LastName,
    email: userData.Email,
    phone: userData.Phone,
    role: userData.Role,
  };
  const [loading, setLoading] = useState(false);
  const { control, formState: { isValid }, handleSubmit,trigger } = useForm({ mode: 'all', reValidateMode: 'onBlur', defaultValues });

  useEffect(() => {
    trigger();
  }, [trigger]);
  

  const addStore = (list: Set<string>): void => {
    const storeIds: string[] = storesOfEmployee ? storesOfEmployee.map(store => store.Id) : [];
    const tmpUnassign: string[] = [];
    const tmpAssign: string[] = [];
    storeIds.map(id => {
      if (!list.has(id)) {
        tmpUnassign.push(id);
      }
    });
    setStoresToUnassign(tmpUnassign);
    list.forEach(val => {
      if (!storeIds.includes(val)) {
        tmpAssign.push(val);
      }
    });
    setStoresToAssign(tmpAssign);
    setStoreList(storesList.filter(store => tmpAssign.includes(store.Id) || (storeIds.includes(store.Id) && !tmpUnassign.includes(store.Id))));
    setDialog(false);
  };

  useEffect(() => {
    setStoreList(storesOfEmployee);
  }, [storesOfEmployee]);

  const editUser: SubmitHandler<UserFormTypes> = (data): void => {
    if (token) {
      setLoading(true);
      UserEditRequest(
        token,
        {
          Id: userData.Id,
          FirstName: data.firstName,
          LastName: data.lastName,
          Phone: data.phone,
          Role: data.role,
        },
        () => {
          storesToAssign.map(id => {
            AssignToStoreRequest(
              token,
              {
                UserId: userData.Id,
                StoreId: id as GUID,
              },
              () => setLoading(false),
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              () => {}
            );
          });
          storesToUnassign.map(id => {
            UnassignStoreRequest(
              token,
              id,
              userData.Id,
              () => setLoading(false),
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              () => {}
            );
          });
          if (storesToAssign.length === 0 && storesToUnassign.length === 0) {
            setLoading(false);
          }
          dispatch({ type: 'MERCHANT_REFRESH' });
          navigate(state.navigateBackTo, { state: { latestTab: 'employees' } });
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };
  
  return (
    <FormPageComponent title="Edit Employee" buttonTitle="Edit Employee" buttonDisabled={!isValid || loading} buttonLoading={loading} close={() => navigate(state.navigateBackTo, { state: { latestTab: 'employees' } })} submit={handleSubmit(editUser)}>
      <Fragment>
        {EMPLOYEE_FORM.map((section, index) => {
          return (
            <ModalCardComponent key={index}>
              <SectionHeader title={section.title} />
              <Fragment>
                {section.fields.map((field, index) =>
                  <Grid key={index} item sx={{ flexGrow: 1 }}>
                    {field.inputType === 'select'
                      ? <FormControlSelect control={control} field={field} />
                      : <FormControlInput disabled={field.name === 'email'} control={control} field={field} />
                    }
                    {field.helperText 
                      && <FormHelperText sx={{ padding: '0 14px' }}>
                        {field.helperText}
                      </FormHelperText>
                    }
                  </Grid>
                )}
              </Fragment>
            </ModalCardComponent>
          );
        })}
      </Fragment>

      <ModalCardComponent>
        <SectionHeader
          title="Stores"
          button={
            <ButtonContainedPrimary disabled={storesList.length === 0} startIcon={<AddIcon />} onClick={() => setDialog(true)}>Link Stores</ButtonContainedPrimary>
          }
        />

        {storeList === undefined || storeList.length === 0
          ? <Grid item sx={{ flexGrow: 1, margin: '16px 0' }}>
            <InfoComponent
              title="Adding Store"
              description={storesList.length > 0 ? 'Add the employee to stores they work at or manage. You can also add employees to the stores at a later point.' : 'You haven\'t added any stores to this merchant yet.'}
            />
          </Grid>
          : <Fragment>
            {storeList.map((store, index) =>
              <Fragment key={index}>
                <SectionRow
                  title="Store"
                  lines={[
                    { data: store.Name, type: 'body1' },
                    { data: `${store.CustomerCode}`, type: 'body2', color: 'text.secondary' }
                  ]}
                />
                {storeList.length - 1 > index ? <Box><Divider /></Box> : null}
              </Fragment>
            )}
          </Fragment>
        }
      </ModalCardComponent>

      <ListDialog
        items={storesList}
        title="Select Stores"
        caption={{ singular: 'Store', plural: 'Stores' }}
        open={dialog}
        multiple
        close={() => setDialog(false)}
        submit={addStore}
        edit
      />
    </FormPageComponent>
  );
}
