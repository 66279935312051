import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { Divider, Grid } from '@mui/material';
import { HeaderSix, SubtitleOnePrimary } from '../components-atoms/TypographyComponents';
import { GetRecentColourRequest } from '../service/serviceService';
import '../scss/components/_recent-items.scss';
import '../scss/components/_thumbnail.scss';

interface IProps {
  categoryId: string;
  serviceId: string;
}

export function RecentColours({categoryId, serviceId}: IProps): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const navigate = useNavigate();
  const [recentColours, setRecentColours] = useState<any>([]);

  useEffect(() => {
    if (token) {
      GetRecentColourRequest(
        token,
        {
          Page: 0,
          Size: 6,
        },
        (response) => { if (response) setRecentColours(response.Result); },
        (error: any) => { if (error.response) console.error(error.response.data.Error); }
      );
    }
  }, [token]);

  return (
    <>
      {recentColours.length > 0 && (
        <>
          <Divider style={{ width: '100%'}} />
          <section className="recent-items">
            <div className="recent-items__title">
              <HeaderSix>Recent Colours</HeaderSix>
            </div>
            <Grid container item columnSpacing={20 / 8} alignItems="center">
              {recentColours.map((recentColour: any) => (
                <Grid key={recentColour.RemoteId} className="recent-items__content" item lg={4} xl={4} xs={12} md={6} sm={6}>
                  <a
                    onClick={() => navigate(`/detail/colour/${recentColour.RemoteId}`, {
                      state: {
                        categoryId,
                        serviceId
                      }
                    })}
                    className="recent-item recent-item--link"
                  >
                    <span
                      className="
                        recent-item__media
                        thumb thumb--large
                      "
                      style={{
                        backgroundColor: recentColour.Hex ? '#' + recentColour.Hex : '',
                        borderRadius: '0px 17px',
                        boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
                        aspectRatio: '1/1'
                      }}
                    ></span>
                    <div className="recent-item__content">
                      <SubtitleOnePrimary className="u-text-truncate-multi">
                        {recentColour.Name}
                      </SubtitleOnePrimary>
                    </div>
                  </a>
                </Grid>
              ))}
            </Grid>
          </section>
        </>
      )}
    </>
  );
}
