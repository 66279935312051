import { Fragment } from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import { Section } from '../../components-molecules/section/Section';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { SectionRow } from '../../components-molecules/section/SectionRow';
import { GetAdminRolesResponseModel } from '../../service/serviceService';

interface IProps {
  role: GetAdminRolesResponseModel;
  onDelete: (role: string) => void;
  onEdit: () => void;
  hasMenuIcon?: boolean;
}
export function UserRoleCard({ role, onDelete, onEdit, hasMenuIcon }: IProps): JSX.Element {
  const getAccessText = (content: boolean, accessRight: boolean) =>
    `${content ? 'Content management' : ''}${content && accessRight ? ', ' : ''}${accessRight ? 'Access rights management' : ''}`;
  return (
    <Section>
      <SectionHeader
        hasMenuIcon={hasMenuIcon}
        title={role.Role}
        onDelete={() => onDelete(role.Role)}
        onEdit={() => onEdit()} />

      {role.AdminSection
        ? <SectionRow
          title="Admin Sections"
          lines={[
            { data: role.AdminSection.flatMap(m => m.AdminService).join(', '), type: 'body1' }
          ]}
        />
        : null
      }
      {role.ServiceSection
        ? <>
          {role.ServiceSection.map((m, idx) => (
            <Fragment key={m.Service}>
              {idx === 0 && !role.AdminSection
                ? null
                : <Box><Divider /></Box>}

              <SectionRow
                title={m.Category}
                lines={[
                  { data: m.Service, type: 'body1' },
                  {
                    data: getAccessText(m.HasContentAccess, m.HasAccessManagementAccess),
                    type: 'body2',
                    color: 'text.secondary'
                  }
                ]}
              />
            </Fragment>
          ))}
        </>
        : null}

    </Section>
  );
}
