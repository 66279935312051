/* eslint-disable react/no-unescaped-entities */
import { MiniDialog } from '../../components-molecules/dialogs/MiniDialog';
import { Box, Stack } from '@mui/material';
import { BodyOneSecondary } from '../../components-atoms/TypographyComponents';
import { ButtonContainedPrimary, ButtonTextPrimary } from '../../components-atoms/ButtonComponents';

interface IIndustryEventPublishEventProps {
  isOpen: boolean;
  onClose: () => void;
  onProceed: () => void;
}

export default function IndustryEventPublishEvent({ isOpen, onClose, onProceed }: IIndustryEventPublishEventProps): JSX.Element {
  return (
    <MiniDialog
      title='Publishing Event'
      open={isOpen}
      close={() => onClose()}
      submit={() => onProceed()}
      customBottomButtons={() =>
        <Box sx={{ margin: '16px', alignSelf: 'end' }}>
          <ButtonTextPrimary sx={{ mr: '8px' }} onClick={onClose}>Cancel</ButtonTextPrimary>
          <ButtonContainedPrimary onClick={onProceed}>Edit Event</ButtonContainedPrimary>
        </Box>
      }>
      <Box mx='24px'>
        <Stack gap='16px' mb='16px'>
          <BodyOneSecondary>
            To publish a recently created event, access the "Edit Event" section first. Upload an event image and configure the target users.
          </BodyOneSecondary>
          <BodyOneSecondary>
            Subsequently, you can update the event status to "Published," making it visible to users.
          </BodyOneSecondary>
        </Stack>
      </Box>
    </MiniDialog>
  );
}
