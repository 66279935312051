import axios, { AxiosResponse, AxiosError, CancelToken } from 'axios';
import { STORE } from '../redux/store';

function GetHeaders(token?: string): { 'Content-Type': 'application/json'; 'token': undefined | any;  'LogToken': undefined | any; } {
  if (token) {
    return { 
      'Content-Type': 'application/json', 
      'token': token ? token : STORE.getState().userReducer.token,
      'LogToken': STORE.getState().userReducer.token ? STORE.getState().userReducer.token: token
    };
  }
  else return { 'Content-Type': 'application/json', 'token': undefined, 'LogToken': undefined };
}
function GetMultiPartHeaders(token?: string): { 'Content-Type': 'multipart/form-data'; 'token': undefined | any; 'LogToken': undefined | any; } {
  if (token) { 
    return { 'Content-Type': 'multipart/form-data', 
      'token': token ? token : STORE.getState().userReducer.token,
      'LogToken': STORE.getState().userReducer.token ? STORE.getState().userReducer.token: token,
    };
  }
  else return { 'Content-Type': 'multipart/form-data', 'token': undefined, 'LogToken': undefined };
}
function GetSuperData() {
  const superData = STORE.getState().userReducer.super;
  return superData;
}
const navigateToSignIn = () => {
  STORE.dispatch({ type: 'USER_SIGNOUT' });
  window.location.href = '/signin';
};
export function HttpRequestGet<T>(
  url: string,
  success: (responseData: AxiosResponse<ApiResponse<T>>) => void,
  failure: (error: AxiosError) => void,
  token?: string,
  cancelToken?: CancelToken,
  noSuperData?: boolean
): void {
  const superData = GetSuperData();
  let reqToken = token;
  if (superData && !noSuperData) {
    reqToken = superData.token;
  }
  const headers = GetHeaders(reqToken);
  axios.get(url, { headers, cancelToken })
    .then((response: AxiosResponse) => {
      success(response);
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 401) {
        navigateToSignIn();
      } else {
        failure(error);
      }
    });
}

export function HttpRequestDelete<T>(
  url: string,
  success: (responseData: AxiosResponse<ApiResponse<T>>) => void,
  failure: (error: AxiosError) => void,
  token?: string,
  cancelToken?: CancelToken,
  noSuperData?: boolean
): void {
  const superData = GetSuperData();
  let reqToken = token;
  if (superData && !noSuperData) {
    reqToken = superData.token;
  }
  const headers = GetHeaders(reqToken);
  axios.delete(url, { headers, cancelToken })
    .then((response: AxiosResponse) => {
      success(response);
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 401) {
        navigateToSignIn();
      } else {
        failure(error);
      }
    });
}

export function HttpRequestPost<T, P>(
  url: string,
  body: P,
  success: (response: AxiosResponse<ApiResponse<T>>) => void,
  failure: (error: AxiosError) => void,
  token?: string,
  cancelToken?: CancelToken,
  noSuperData?: boolean
): void {
  const superData = GetSuperData();
  let reqToken = token;
  if (superData && !noSuperData) {
    reqToken = superData.token;
  }
  const headers = GetHeaders(reqToken);
  axios.post(url, body, { headers, cancelToken })
    .then((response: AxiosResponse) => {
      success(response);
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 401) {
        navigateToSignIn();
      } else {
        failure(error);
      }
    });
}

export function HttpRequestPut<T, P>(
  url: string,
  body: P,
  success: (responseData: AxiosResponse<ApiResponse<T>>) => void,
  failure: (error: AxiosError) => void,
  token?: string,
  cancelToken?: CancelToken,
  noSuperData?: boolean
): void {
  const superData = GetSuperData();
  let reqToken = token;
  if (superData && !noSuperData) {
    reqToken = superData.token;
  }
  const headers = GetHeaders(reqToken);
  axios.put(url, body, { headers, cancelToken })
    .then((response: AxiosResponse) => {
      success(response);
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 401) {
        navigateToSignIn();
      } else {
        failure(error);
      }
    });
}

export function HttpRequestPatch<T, P>(
  url: string,
  body: P,
  success: (responseData: AxiosResponse<ApiResponse<T>>) => void,
  failure: (error: AxiosError) => void,
  token?: string,
  cancelToken?: CancelToken,
  noSuperData?: boolean
): void {
  const superData = GetSuperData();
  let reqToken = token;
  if (superData && !noSuperData) {
    reqToken = superData.token;
  }
  const headers = GetHeaders(reqToken);
  axios.patch(url, body, { headers, cancelToken })
    .then((response: AxiosResponse) => {
      success(response);
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 401) {
        navigateToSignIn();
      } else {
        failure(error);
      }
    });
}

export function MultiPartRequest<T, P>(
  url: string,
  formData: P,
  success: (response: AxiosResponse<T>) => void,
  failure: (error: AxiosError) => void,
  token?: string,
  cancelToken?: CancelToken,
  noSuperData?: boolean
): void {
  const superData = GetSuperData();
  let reqToken = token;
  if (superData && !noSuperData) {
    reqToken = superData.token;
  }
  const headers = GetMultiPartHeaders(reqToken);
  axios.post(url, formData, { headers, cancelToken })
    .then((response: AxiosResponse) => {
      success(response);
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 401) {
        navigateToSignIn();
      } else {
        failure(error);
      }
    });
}
