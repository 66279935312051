import { useEffect, useState } from 'react';

import { DataDivider } from '../utils/arrayDividers';
import { ListDivider } from '../components-molecules/Divider';
import { MerchantListCard } from '../components-organisms/cards/MerchantListCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';
import { CircularProgress, Container, useMediaQuery } from '@mui/material';
import { LIGHT_THEME } from '../constants/theme';
interface IProps {
  merchants: Merchant[];
  loadMore: () => void;
  total: number;
}
export function MerchantsList({ merchants, loadMore, total }: IProps): JSX.Element {
  const [sectionedlist, setSectionedList] = useState<DividedList<Merchant>>({});

  useEffect(() => {
    if (merchants.length > 0) {
      setSectionedList({ ...DataDivider(merchants) });
    }
  }, [merchants]);
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));

  return (
    <div style={{ paddingBottom: '48px', width: '100%' }}>
      <InfiniteScroll
        style={{ overflowY: 'hidden' }}
        dataLength={merchants.length} //This is important field to render the next data
        next={() => debounce(() => loadMore(), 300)()}
        scrollThreshold={smallDown ? '550px' : '50px'}
        hasMore={merchants.length < total}
        loader={
          <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
            <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
          </Container>
        }
      >
        {Object.keys(sectionedlist).sort().map(section => {
          return (
            <ListDivider
              key={section}
              division={{ name: section, length: sectionedlist[section].length }}
              caption={{ singular: 'Merchant', plural: 'Merchants' }}
            >
              {sectionedlist[section].map(item => <MerchantListCard key={item.Id} merchant={item} />)}
            </ListDivider>
          );
        })}
      </InfiniteScroll>

    </div>
  );
}

