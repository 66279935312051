import { Grid, Stack, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { FullScreenAssetDialog, FullScreenDialogServiceType } from '../components-molecules/dialogs/FullScreenAssetDialog';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import AdminHelpCard from '../components-organisms/cards/AdminHelpCard';
import { Dashboard } from '../components-organisms/Dashboard';
import { LIGHT_THEME } from '../constants/theme';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { GetAdminHelpfilesRequest } from '../service/adminService';

export default function AdminHelp(): JSX.Element {
  const isMdDown = useMediaQuery(LIGHT_THEME.breakpoints.down('md'));
  const [token] = useTypedSelector((state) => [state.userReducer.token]);
  const [count, setCount] = useState(0);
  const [data, setData] = useState<AdminHelpfile[]>([]);
  const [fullScreenModalOpen, setFullScreenModalOpen] = useState<number>(-1);

  const page = 1;
  const pageSize = 50;

  useEffect(() => {
    if (token) {
      GetAdminHelpfilesRequest(
        token,
        { Page: page, Size: pageSize },
        (response) => {
          setCount(response.Result.Count);
          setData(response.Result.Result);
        },
        () => { /* */ }
      );
    }
  }, [page, token]);

  const getOnClick = (asset: AdminHelpfile) => {
    const idx = data.findIndex(e => e.Id === asset.Id);
    setFullScreenModalOpen(idx);
  };

  return (
    <Dashboard justifyContent="flex-start">
      <PageHeader
        title={'Connect for Merchants Help'}
      />
      <PageHeader
        title={'Help files'}
        subtitle={`Showing 1 - ${data.length} of ${count}`}
        titleSectionRow
        smallerTitle
      />
      <Stack flex={1}>
        <Stack
          pt={isMdDown ? 0 : 4}
          pb={4}
          px={4}
          display="flex"
          flex={1}
          flexDirection={isMdDown ? 'column' : 'row'}
          justifyContent="space-between">
          <Stack
            flex={1}
            sx={{ order: isMdDown ? 1 : 'initial' }}>
            <Grid
              container
              item
              columnSpacing={'30px'}
              rowSpacing={2}
            >
              {data.map((file, index) =>
                <AdminHelpCard
                  helpfile={file}
                  key={index}
                  source="detail"
                  onClick={() => getOnClick(file)}
                  oneCard={data.length === 1}
                />
              )}
            </Grid>
          </Stack>

          <FullScreenAssetDialog
            open={fullScreenModalOpen > -1}
            index={fullScreenModalOpen}
            assets={data}
            onClose={() => setFullScreenModalOpen(-1)}
            noDownloadButton
            serviceType={FullScreenDialogServiceType.AdminHelp}
          />
        </Stack>
      </Stack>
    </Dashboard>
  );
}
