import { EMAIL_VALIDATION, REQUIRED_VALIDATION } from './_predefinedValidations';

export type ForgotFormTypes = {
  email: string,
};

export const FORGOT_FORM: [FormInputField] = [
  {
    label: 'Email',
    name: 'email',
    inputType: 'text',
    validation: {
      required: REQUIRED_VALIDATION,
      pattern: EMAIL_VALIDATION
    }
  }
];
