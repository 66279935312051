/* eslint-disable @typescript-eslint/naming-convention */
import { Box } from '@mui/material';
import { TabContext } from '@mui/lab';
import { Dashboard } from '../../../components-organisms/Dashboard';
import { DigitalToolkitsService } from '../../../components-organisms/DigitalToolkitsService';
import { DigitalToolkitsSource } from '../../../utils/enums';

const currentTab = 'content';
export function DigitalToolkitServiceDetail(): JSX.Element {
  return (
    <Dashboard justifyContent='flex-start'>
      <TabContext value={currentTab}>
        <Box sx={{ minHeight: '80vh', width: '100%' }}>
          <DigitalToolkitsService source={DigitalToolkitsSource.Admin} />
        </Box>
      </TabContext>
    </Dashboard>
  );
}

export default DigitalToolkitServiceDetail;
