/* eslint-disable @typescript-eslint/naming-convention */
export enum DigitalToolkitsSource {
  Client = 'Client',
  Admin = 'Admin'
}

export enum DigitalToolkitCardSource {
  Listing = 'Listing',
  Detail = 'Detail'
}


export enum DownloadButtonSource {
  AssetListing = 'AssetListing',
  AssetDetail = 'AssetDetail',
  Admin = 'Admin'
}
