import { PASSWORD_VALIDATION, REQUIRED_VALIDATION } from './_predefinedValidations';

export interface ResetFormInputTypes {
  password: string,
  passwordRepeat: string,
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const RESET_FORM = (password?: string): any => [
  {
    label: 'Password',
    name: 'password',
    inputType: 'password',
    validation: {
      required: REQUIRED_VALIDATION,
      pattern: PASSWORD_VALIDATION,
    }
  },
  {
    label: 'Password Repeat',
    name: 'passwordRepeat',
    inputType: 'password',
    validation: {
      required: REQUIRED_VALIDATION,
      validate: (value: any) => value === password || 'Passwords do not match'
    }
  }
];
