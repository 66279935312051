/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { LIGHT_THEME } from '../../constants/theme';
import { CardContainer } from '../../constants/digitalAssets';

interface IProps {
  helpfile: AdminHelpfile;
  onClick: () => void;
  isAdmin?: boolean;
  oneCard?: boolean;
  listPage?: boolean;
  canTouch?: boolean;
  source?: string;
}

export default function AdminHelpCard({ helpfile, onClick, isAdmin, oneCard, source }: IProps): JSX.Element {
  const mdDown = useMediaQuery(LIGHT_THEME.breakpoints.down('md'));
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  const oneCardSize = smallDown ? 6 : 4;
  const isDetail = source === 'detail';

  const AssetPreview = useCallback(() => {
    return (
      <>
        <img
          data-testid="digital-asset-thumbnail"
          style={{
            objectFit: 'contain',
            alignSelf: 'center',
            maxWidth: oneCard ? '128px' : '100%',
            maxHeight: oneCard ? '128px' : '100%'
          }}
          alt="preview"
          src={helpfile.ThumbnailUrl}
        />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderImage() {
    return (
      <Grid
        item
        container
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        data-testid="digital-asset-item"
        style={{
          aspectRatio: '1/1',
          cursor: 'pointer',
          position: 'relative',
          borderRadius: '4px',
          overflow: 'hidden',
          backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }}>
        <AssetPreview />
      </Grid>
    );
  }

  function renderInfo() {
    return (
      <Stack mt={1} gap={0.5}>
        <Typography
          style={{
            fontSize: '14px',
            color: 'black',
            overflow: 'hidden',
            maxWidth: oneCard ? '220px' : isDetail ? '256px' : mdDown ? '74%' : '256px',
          }}>
          {helpfile.Title}
        </Typography>
      </Stack>
    );
  }

  return (
    <Grid
      item
      container
      sm={oneCard ? oneCardSize : 6}
      md={oneCard ? oneCardSize : isDetail ? 4 : isAdmin ? 6 : 4}
      style={{ position: 'relative', maxHeight: '80vh' }}>
      <CardContainer
        container
        item
        isSelected={false}
        onClick={onClick}
      >
        {renderImage()}
        {renderInfo()}
      </CardContainer>
    </Grid >
  );
}
