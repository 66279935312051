import { styled } from '@mui/system';
import Grid, { GridProps } from '@mui/material/Grid';

export const CenteredDialogComponent = styled(Grid)<GridProps>(() => ({
  display: 'flex',
  margin: 'auto',
  padding: 3,
  flexDirection: 'column',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center'
}));
