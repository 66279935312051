import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ButtonTextPrimary } from '../../components-atoms/ButtonComponents';
import { ListDivider } from '../../components-molecules/Divider';
import { FormControlInput } from '../../components-molecules/FormControl';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { LIGHT_THEME } from '../../constants/theme';
import { REQUIRED_VALIDATION, validateWhiteSpace } from '../../forms/_predefinedValidations';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { CreateAdminRole, ListAdminServices, ListServicesByCategory } from '../../service/serviceService';

export function AddUserRole(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, formState: { isValid, errors, isSubmitted }, setError, } = useForm({ mode: 'all', reValidateMode: 'onChange' });
  const token = useTypedSelector(state => state.userReducer.token);
  const [services, setServices] = useState<ListService[]>([]);
  const [adminServices, setAdminServices] = useState<ListAdminService[]>([]);
  const [selectedAdminServices, setSelectedAdminServices] = useState<GUID[]>([]);
  const [selectedServices, setSelectedServices] = useState<{ id: GUID; content: boolean; accessRights: boolean; }[]>([]);
  const navigate = useNavigate();

  const submit: SubmitHandler<any> = (data): void => {
    if (token) {
      setLoading(true);
      CreateAdminRole(
        token,
        {
          Name: data.roleName,
          Services: selectedServices.map(m => ({ Id: m.id, Content: m.content, AccessRights: m.accessRights })),
          AdminServices: selectedAdminServices
        },
        () => {
          setLoading(false);
          navigate('/admin/adminusers', { state: { latestTab: 'roles' } });
        },
        (error) => {
          setError('roleName', { message: error.response?.data as string, type: 'value' });
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    if (token) {
      ListServicesByCategory(
        token,
        (response) => setServices(response.Result),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
      ListAdminServices(
        token,
        (response) => setAdminServices(response.Result),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  }, [token]);

  const handleAdminServiceChange = (m: ListAdminService) => {
    if (!selectedAdminServices.find(f => f === m.Id)) {
      setSelectedAdminServices(prev => {
        const merchantsOption = adminServices.find(s=> s.Name === 'Merchants') as ListAdminService;
        // Select 'Merchant Registrations'
        // should also select 'Merchants'
        if(m.Name === 'Merchant Registrations'){
          return [...prev, m.Id,  merchantsOption?.Id];
        }

        // Default flow
        return [...prev, m.Id];
      });
    }
    else {
      setSelectedAdminServices(prev => {
        const merchantRegistrationsOption = adminServices.find(s=> s.Name === 'Merchant Registrations') as ListAdminService;
        // Deselect 'Merchant'
        // should also deselect 'Merchant Registrations'
        if(m.Name === 'Merchants'){
          return  prev.filter(f => f !== m.Id && f !== merchantRegistrationsOption.Id);
        }
        
        // Default flow
        return prev.filter(f => f !== m.Id);
      });
    }
  };

  const handleServiceChange = (id: GUID, content?: boolean, accessRights?: boolean) => {
    const hasContent = services.flatMap(f => f.Services).find(f => f.Id === id)?.HasContent;
    const temp = selectedServices.find(f => f.id === id);
    if (!temp) {
      //add
      const newObj = {
        id,
        content: !!hasContent && !!content,
        accessRights: !!accessRights
      };
      setSelectedServices(prev => [...prev, newObj]);
    } else {
      //remove
      if(content && accessRights && !hasContent && temp.accessRights){
        setSelectedServices(prev => prev.filter(f => f.id !== id));
      }
      else if (content && accessRights && (temp.content || temp.accessRights) && !(temp.content && temp.accessRights)) {
        setSelectedServices(prev => [...prev.filter(f => f.id !== id), {
          id, content: true, accessRights: true
        }]);
      }
      else if (accessRights === undefined && !content && temp.content && temp.accessRights) {
        setSelectedServices(prev => [...prev.filter(f => f.id !== id), { id, content: !!content, accessRights: temp.accessRights }]);
      } else if (content === undefined && !accessRights && temp.content && temp.accessRights) {
        setSelectedServices(prev => [...prev.filter(f => f.id !== id), { id, content: temp.content, accessRights: !!accessRights }]);
      }
      else if (content && accessRights || (accessRights === undefined && !content) || (content === undefined && !accessRights)) {
        setSelectedServices(prev => prev.filter(f => f.id !== id));
      }
      else if (accessRights === undefined) {
        setSelectedServices(prev => [...prev.filter(f => f.id !== id), {
          id, content: !!hasContent && !!content, accessRights: temp.accessRights
        }]);
      }
      else if (content === undefined) {
        setSelectedServices(prev => [...prev.filter(f => f.id !== id), {
          id, content: temp.content, accessRights: accessRights
        }]);
      }
    }
  };

  const handleAllAdminServices = (select: boolean) => {
    if (select) {
      const temp = adminServices.map(f => f.Id);
      setSelectedAdminServices(temp);
    } else {
      setSelectedAdminServices([]);
    }
  };

  const handleAllServices = (select: boolean) => {
    if (select) {
      const temp = services.flatMap(f => f.Services).map(f => ({
        id: f.Id,
        content: true,
        accessRights: true
      }));
      setSelectedServices(temp);
    } else {
      setSelectedServices([]);
    }
  };

  return (
    <FormPageComponent
      buttonLoading={loading}
      title="Add User Role"
      buttonTitle="Add Role"
      hasError={isSubmitted && ('roleName' in errors)}
      buttonDisabled={loading || !isValid || (selectedServices.length === 0 && selectedAdminServices.length === 0)}
      close={() => navigate('/admin/adminusers', { state: { latestTab: 'roles' } })}
      submit={handleSubmit(submit)}
    >
      <Fragment>
        <ModalCardComponent>
          <SectionHeader title="User role name" />
          <Fragment>
            <Grid item container sx={{ flexGrow: 1 }}>
              <Grid item xs={12}>
                <FormControlInput
                  data-testid="add-user-role-name"
                  control={control}
                  field={{
                    label: 'Role name *',
                    name: 'roleName',
                    inputType: 'text',
                    validation: {
                      required: REQUIRED_VALIDATION,
                      validate: { validateWhiteSpace }
                    }
                  }} />
              </Grid>
            </Grid>
          </Fragment>
        </ModalCardComponent>
        <ModalCardComponent>
          <SectionHeader
            title="Admin portal settings"
            subtitle="User with this user role assigned will be able to view and manage selected sections of the admin portal."
            button={
              <>
                <ButtonTextPrimary onClick={() => handleAllAdminServices(true)}>Select all</ButtonTextPrimary>
                <ButtonTextPrimary disabled={selectedAdminServices.length === 0} onClick={() => handleAllAdminServices(false)}>Deselect all</ButtonTextPrimary>
              </>
            }
          />
          <Fragment>
            <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
              {adminServices.map(m =>
                <Grid
                  xs={12}
                  container
                  key={m.Id}
                  sx={{
                    margin: '4px -16px 4px 16px',
                    padding: '8px 16px',
                    borderRadius: '8px',
                    width: 'unset',
                    background: selectedAdminServices.includes(m.Id) ? LIGHT_THEME.palette.action.selected : 'none',
                    ':hover': { background: LIGHT_THEME.palette.action.hover }
                  }}
                >
                  <FormControlLabel
                    componentsProps={{
                      typography: {
                        ml: 1
                      }
                    }}
                    control={
                      <Checkbox
                        checked={selectedAdminServices.includes(m.Id)}
                        onChange={() => handleAdminServiceChange(m)}
                        name={m.Name} />
                    }
                    label={m.Name}
                  />
                </Grid>
              )}
            </Grid>
          </Fragment>
        </ModalCardComponent>
        <ModalCardComponent>
          <SectionHeader
            title="Services"
            subtitle="User with this user role assigned will be able to content management or/and manage access rights of selected services of Contect for Merchants portal."
            button={
              <>
                <ButtonTextPrimary onClick={() => handleAllServices(true)}>Select all</ButtonTextPrimary>
                <ButtonTextPrimary disabled={selectedServices.length === 0} onClick={() => handleAllServices(false)}>Deselect all</ButtonTextPrimary>
              </>
            }
          />
          <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
            {services.map(m =>
              <ListDivider
                key={m.Category}
                division={{ name: m.Category, length: m.Services.length }}
                caption={{ singular: 'Service', plural: 'Services' }}
              >{
                  m.Services.map(s =>
                    <Grid
                      xs={12}
                      container
                      key={s.Id}
                      sx={{
                        margin: '4px -16px 4px 16px',
                        padding: '8px 16px',
                        borderRadius: '8px',
                        width: 'unset',
                        background: selectedServices.find(f => f.id === s.Id && (f.content || f.accessRights)) ? LIGHT_THEME.palette.action.selected : 'none',
                        ':hover': { background: LIGHT_THEME.palette.action.hover }
                      }}
                    >
                      <Grid item container>
                        <FormControlLabel
                          componentsProps={{
                            typography: {
                              ml: 1
                            }
                          }}
                          control={
                            <Checkbox
                              checked={!!selectedServices.find(f => f.id === s.Id && (!s.HasContent || f.content) && f.accessRights)}
                              onChange={() => handleServiceChange(s.Id, true, true)}
                              name={s.Name}
                              indeterminate={s.HasContent && !!selectedServices.find(f => f.id === s.Id && (f.content || f.accessRights) && !(f.content && f.accessRights))}
                            />
                          }
                          label={s.Name}
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        ml={7}>
                        <Grid xs>
                          <FormControlLabel
                            sx={{ mt: 1 }}
                            componentsProps={{
                              typography: {
                                ml: 1
                              }
                            }}
                            control={
                              <Checkbox
                                disabled={!s.HasContent}
                                checked={s.HasContent && !!selectedServices.find(f => f.id === s.Id && f.content)}
                                onChange={(e, checked) => handleServiceChange(s.Id, checked, undefined)}
                                name={s.Name} />
                            }
                            label="Content management"
                          />
                        </Grid>
                        <Grid xs>
                          <FormControlLabel
                            sx={{ mt: 1 }}
                            componentsProps={{
                              typography: {
                                ml: 1
                              }
                            }}
                            control = {
                              <Checkbox
                                checked={!!selectedServices.find(f => f.id === s.Id && f.accessRights)}
                                onChange={(e, checked) => handleServiceChange(s.Id, undefined, checked)}
                                name={s.Name} 
                              />
                            }
                            label="Access rights management"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                }
              </ListDivider>
            )}
          </Grid>
        </ModalCardComponent>
      </Fragment>
    </FormPageComponent>
  );
}
