import UploadIcon from '@mui/icons-material/Upload';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import { LIGHT_THEME } from '../constants/theme';

import { ImageComponent } from '../components-atoms/ImageComponents';
import imageIcon from '../assets/icons/Imagefile.svg';

interface ImageUploadButtonProps {
  defaultImage?: string;
  multiple?: boolean;
  disabled?: boolean;
  onChange: (files: FileList | File) => void;
  accept?: string;
  name: string;
}

export function ImageUploadButton({ defaultImage, multiple, disabled, onChange, accept = 'image/*', name }: ImageUploadButtonProps): JSX.Element {
  return (
    <label htmlFor={`image-upload-${name}`}>
      <input
        id={`image-upload-${name}`}
        accept={accept}
        type="file"
        multiple={multiple}
        style={{ display: 'none' }}
        onBlur={undefined}
        onChange={(e) => {
          const files = e.target.files;
          if (files !== null) onChange(multiple ? files : files[0]);
        }}
      />

      <Button variant="text" component="span" disabled={disabled}>
        <Box sx={{ height: '96px', width: '96px', background: LIGHT_THEME.palette.action.hover, border: '1px solid rgba(0, 0, 0, 0.08)', borderRadius: '4px' }}>
          {!!defaultImage
            && <ImageComponent
              src={defaultImage}
              onError={({ currentTarget }) => {
                (currentTarget as (EventTarget & HTMLImageElement)).src = imageIcon;
              }}
              loading="lazy"
              height="94"
              width="94"
              sx={{ borderRadius: '4px', opacity: 0.8, objectFit: 'contain' }} />
          }
          <Avatar sx={{ height: '48px', width: '48px', background: 'rgba(255, 255, 255, 0.54)', margin: '24px', position: 'absolute', top: 8 }}>
            <UploadIcon sx={{ fontSize: 15, fill: LIGHT_THEME.palette.primary.main }} />
          </Avatar>

        </Box>
      </Button>
    </label>
  );
}
