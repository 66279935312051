import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { Grid, Toolbar } from '@mui/material';
import { ImageComponent } from '../components-atoms/ImageComponents';
import { BodyOneSecondary } from '../components-atoms/TypographyComponents';
import AkzoNobelLogo from '../assets/img/AkzoNobel-logo.svg';
import '../scss/components/_footer.scss';

declare global {
  interface Window {
    OneTrust: {
      ToggleInfoDisplay: () => void;
    };
  }
}

interface IProps {
  hideLinks?: boolean;
}

const YEAR = new Date().getFullYear();

export function Footer({ hideLinks = false }: IProps): JSX.Element {
  const services = useTypedSelector((state) => state.userReducer.services);

  const servicePageLink = (serviceName: string) => {
    const service = services.map(obj => obj.Services).flat().find(service => service.ServiceName === serviceName);
    if (service) {
      return `/service/${service.ServiceId}`;
    }
    return '';
  };

  return (
    <>
      <Toolbar className="footer">
        <Grid className="footer__row-1 container">
          <a
            href="https://www.duluxtradepaintexpert.co.uk/en/brands/dulux-trade"
            target="_blank"
            rel="noreferrer"
            className="footer__brand"
          >
            <img
              srcSet={`
                ${require('../assets/img/footer/dulux-trade.png')} 1x, 
                ${require('../assets/img/footer/dulux-trade@2x.png')} 2x,
                ${require('../assets/img/footer/dulux-trade@3x.png')} 3x
              `}
              src={require('../assets/img/footer/dulux-trade@2x.png')}
              alt="Dulux Trade logo"
            />
          </a>
          <a
            href="https://www.duluxtradepaintexpert.co.uk/en/brands/polycell-trade"
            target="_blank"
            rel="noreferrer"
            className="footer__brand"
          >
            <img
              srcSet={`
                ${require('../assets/img/footer/polycell.png')} 1x, 
                ${require('../assets/img/footer/polycell@2x.png')} 2x,
                ${require('../assets/img/footer/polycell@3x.png')} 3x
              `}
              src={require('../assets/img/footer/polycell@2x.png')}
              alt="Polycell logo"
            />
          </a>
          <a
            href="https://www.duluxtradepaintexpert.co.uk/en/brands/hammerite"
            target="_blank"
            rel="noreferrer"
            className="footer__brand"
          >
            <img
              srcSet={`
                ${require('../assets/img/footer/hammerite.png')} 1x, 
                ${require('../assets/img/footer/hammerite@2x.png')} 2x,
                ${require('../assets/img/footer/hammerite@3x.png')} 3x
              `}
              src={require('../assets/img/footer/hammerite@2x.png')}
              alt="Hammerite logo"
            />
          </a>
          <a
            href="https://www.duluxtradepaintexpert.co.uk/en/brands/cuprinol"
            target="_blank"
            rel="noreferrer"
            className="footer__brand"
          >
            <img
              srcSet={`
                ${require('../assets/img/footer/cuprinol.png')} 1x, 
                ${require('../assets/img/footer/cuprinol@2x.png')} 2x,
                ${require('../assets/img/footer/cuprinol@3x.png')} 3x
              `}
              src={require('../assets/img/footer/cuprinol@2x.png')}
              alt="Cuprinol logo"
            />
          </a>
          <a
            href="https://www.duluxtradepaintexpert.co.uk/en/brands/sikkens"
            target="_blank"
            rel="noreferrer"
            className="footer__brand"
          >
            <img
              srcSet={`
                ${require('../assets/img/footer/sikkens.png')} 1x, 
                ${require('../assets/img/footer/sikkens@2x.png')} 2x,
                ${require('../assets/img/footer/sikkens@3x.png')} 3x
              `}
              src={require('../assets/img/footer/sikkens@2x.png')}
              alt="Sikkens logo"
            />
          </a>
          <a
            href="https://www.duluxtradepaintexpert.co.uk/en/brands/armstead-trade"
            target="_blank"
            rel="noreferrer"
            className="footer__brand"
          >
            <img
              srcSet={`
                ${require('../assets/img/footer/armstead.png')} 1x, 
                ${require('../assets/img/footer/armstead@2x.png')} 2x,
                ${require('../assets/img/footer/armstead@3x.png')} 3x
              `}
              src={require('../assets/img/footer/armstead@2x.png')}
              alt="Armstead logo"
            />
          </a>
        </Grid>

        <Grid className="footer__row-2 container">
          <nav>
            <ul className="footer__legal u-mt-0 u-mb-0">
              {!hideLinks
                ? <>
                  <li>
                    <a href="http://content.duluxtradepaintexpert.co.uk/products/sustainability" target="_blank" rel="noreferrer" data-testid="sustainability-button">
                      <BodyOneSecondary
                        sx={{
                          fontSize: '13.8px',
                          fontWeight: 400,
                          lineHeight: 1.45,
                          letterSpacing: '.25px'
                        }}
                      >
                        Sustainability
                      </BodyOneSecondary>
                    </a>
                  </li>
                  <li>
                    <a href="/term-use" data-testid="termsofuse-button">
                      <BodyOneSecondary
                        sx={{
                          fontSize: '13.8px',
                          fontWeight: 400,
                          lineHeight: 1.45,
                          letterSpacing: '.25px'
                        }}
                      >
                        Terms of Use
                      </BodyOneSecondary>
                    </a>
                  </li>
                </>
                : <></>
              }
              <li>
                <a href="/cookie-policy" data-testid="cookiepolicy-button">
                  <BodyOneSecondary
                    sx={{
                      fontSize: '13.8px',
                      fontWeight: 400,
                      lineHeight: 1.45,
                      letterSpacing: '.25px'
                    }}
                  >
                    Cookie Policy
                  </BodyOneSecondary>
                </a>
              </li>
              <li>
                <button
                  id="ot-sdk-btn"
                  className="ot-sdk-show-settings"
                  onClick={() => window?.OneTrust?.ToggleInfoDisplay()}
                  style={{
                    color: 'unset',
                    backgroundColor: 'transparent',
                    fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
                    fontSize: 'unset',
                    lineHeight: 'unset',
                    padding: 0,
                    border: 'none'
                  }}>
                  Cookies Settings
                </button>
              </li>
              <li>
                <a href="/privacy-policy" data-testid="privacypolicy-button">
                  <BodyOneSecondary
                    sx={{
                      fontSize: '13.8px',
                      fontWeight: 400,
                      lineHeight: 1.45,
                      letterSpacing: '.25px'
                    }}
                  >
                    Privacy Policy
                  </BodyOneSecondary>
                </a>
              </li>
              {!hideLinks
                ? <>
                  <li>
                    <a href="/disclaimer" data-testid="disclaimer-button">
                      <BodyOneSecondary
                        sx={{
                          fontSize: '13.8px',
                          fontWeight: 400,
                          lineHeight: 1.45,
                          letterSpacing: '.25px'
                        }}
                      >
                        Disclaimer
                      </BodyOneSecondary>
                    </a>
                  </li>
                  <li>
                    <a href={servicePageLink('Help')} data-testid="help-button">
                      <BodyOneSecondary
                        sx={{
                          fontSize: '13.8px',
                          fontWeight: 400,
                          lineHeight: 1.45,
                          letterSpacing: '.25px'
                        }}
                      >
                        Help
                      </BodyOneSecondary>
                    </a>
                  </li>
                </>
                : <></>
              }
              <li>
                <a href="https://www.duluxtradepaintexpert.co.uk/en/advice/contact-us" target="_blank" rel="noreferrer" data-testid="contactus-button">
                  <BodyOneSecondary
                    sx={{
                      fontSize: '13.8px',
                      fontWeight: 400,
                      lineHeight: 1.45,
                      letterSpacing: '.25px'
                    }}
                  >
                    Contact Us
                  </BodyOneSecondary>
                </a>
              </li>
            </ul>
          </nav>
        </Grid>

        <Grid className="footer__row-3 container">
          <span className="footer__copyright">
            <BodyOneSecondary
              sx={{
                fontSize: '13.8px',
                fontWeight: 400,
                lineHeight: 1.45,
                letterSpacing: '.25px'
              }}
            >
              Copyright © {YEAR} Akzo Nobel N.V.
            </BodyOneSecondary>
          </span>
          <a href="https://www.akzonobel.com/" target="_blank" rel="noreferrer" className="footer__logo">
            <ImageComponent
              src={AkzoNobelLogo}
              alt="AkzoNobel"
              loading="lazy"
            />
          </a>
        </Grid>
      </Toolbar>
    </>
  );
}
