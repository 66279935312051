/* eslint-disable @typescript-eslint/naming-convention */
import '../../../scss/components/_rte.scss';
import { useMemo, useState } from 'react';
import moment from 'moment';
import { Box, Divider, Grid, Stack, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CaptionSecondary, HeaderFivePrimary } from '../../../components-atoms/TypographyComponents';
import { correctUrls } from '../../../hooks/correctUrls';
import { LIGHT_THEME } from '../../../constants/theme';
import { ButtonContainedPrimary } from '../../../components-atoms/ButtonComponents';
import { removeTags } from '../../../utils/getShortDescription';
import imageIcon from '../../../assets/icons/Imagefile.svg';


interface ListingCardImageProps {
  hasShadow?: boolean;
  thumbnailFit?: string;
  error: boolean;
  setError: (error: boolean) => void;
  thumbnailUrl: string;
}

function ListingCardImage({ hasShadow, thumbnailFit, error, setError, thumbnailUrl }: ListingCardImageProps): JSX.Element {
  const smDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));

  return (
    <Grid
      item
      xs={12}
      sm={3}
      display='flex'
      justifyContent='flex-start'
      alignItems='flex-start'>
      <Grid
        container
        justifyContent="center"
        item
        sx={{
          aspectRatio: '1/1',
          maxHeight: { xs: '176px', sm: '320px' },
          maxWidth: { xs: '100%', sm: '320px' },
          width: '100%',
          borderRadius: '4px',
          boxShadow: hasShadow ? LIGHT_THEME.shadows[6] : undefined,
          outline: '1px solid rgba(0, 0, 0, 0.12)'
        }}>
        <img
          style={{
            width: '100%',
            borderRadius: '4px',
            maxHeight: thumbnailFit === 'contain' ? '320px' : '100%',
            height: smDown ? '176px' : '100%',
            objectFit: thumbnailFit as any,
            background: thumbnailFit === 'contain' ? 'rgba(0, 0, 0, 0.04)' : 'white',
          }}
          src={error ? imageIcon : thumbnailUrl}
          onError={() => setError(true)}
        />
      </Grid>
    </Grid>
  );
}

interface IListingCardProps {
  content?: any;
  thumbnailUrl: string;
  title: string;
  description: string;
  url?: string;
  isFirst?: boolean;
  isLast?: boolean;
  hideEllipsis?: boolean;
  createDate?: Date | string;
  thumbnailFit?: string;
  onClick?: () => void;
  customButtons?: any;
  fromDetail?: boolean;
  hasShadow?: boolean;
}

const ellipsisStyle = {
  wordBreak: 'break-word',
  color: 'rgb(0, 0, 0, 0.65)',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export function ListingCard(props: IListingCardProps): JSX.Element {
  const {
    content,
    isFirst,
    isLast,
    title,
    url,
    customButtons,
    createDate,
    description,
    thumbnailUrl,
    onClick,
    fromDetail = false,
    hideEllipsis = false,
    thumbnailFit = 'contain',
    hasShadow
  } = props;

  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const formattedDescription = useMemo(() => fromDetail ? correctUrls(description) : removeTags(description), [fromDetail, description]);
  const formattedDate = useMemo(() => createDate ? moment(new Date(createDate)).format('MMM DD, yyyy') : null, [createDate]);

  function renderContent() {
    return (
      <Grid
        item
        xs={12}
        sm={9}
        mt={{ xs: 4, sm: 0 }}>
        <Grid container height='100%' mb={{ xs: 0, sm: '12px' }}>
          <Box maxWidth='672px'>
            <HeaderFivePrimary
              sx={{
                margin: '10px 0',
                color: 'rgba(0, 0, 0, 0.87)',
                fontFamily: 'Open Sans',
                fontWeight: '700',
                fontSize: '24px'
              }}>
              {title}
            </HeaderFivePrimary>

            {createDate ? <CaptionSecondary sx={{ pb: 1 }}>{formattedDate}</CaptionSecondary> : <></>}

            <p
              className={`rte ${fromDetail ? '' : 'listing-card-rte'}`}
              style={!hideEllipsis
                ? {
                  wordBreak: 'break-word', margin: 0, fontSize: 14, color: 'rgba(0, 0, 0, 0.6)', ...ellipsisStyle as any
                } : { wordBreak: 'break-word', margin: 0, fontSize: 14, color: 'rgba(0, 0, 0, 0.6)' }
              }
              dangerouslySetInnerHTML={{ __html: formattedDescription }}>
            </p>

            {content?.()}

            <Stack mt='20px' mb='32px'>
              {customButtons ? customButtons()
                : fromDetail ? <></> : <ButtonContainedPrimary
                  onClick={() => onClick ? onClick() : url ? navigate(url) : undefined}
                  sx={{ height: '36px', padding: '6px 20px', alignSelf: 'flex-start' }}>
                  Learn More
                </ButtonContainedPrimary>
              }
            </Stack>
          </Box>
        </Grid>
        {!isLast
          && <Divider sx={{ width: '100%' }} />
        }
      </Grid>
    );
  }

  return (
    <Grid
      container
      mt={isFirst ? 0 : 4}
      mb={8}
      maxWidth='1408px'
      columnSpacing={{ xs: 0, sm: 4, lg: 8, xl: 8 }}
      sx={{ border: '1px solid transparent' }}
    >
      <ListingCardImage
        hasShadow={hasShadow}
        thumbnailFit={thumbnailFit}
        error={error}
        setError={setError}
        thumbnailUrl={thumbnailUrl}
      />
      {renderContent()}
    </Grid>
  );
}
