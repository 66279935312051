import { useMediaQuery, Container, CircularProgress, Stack } from '@mui/material';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LIGHT_THEME } from '../constants/theme';
import { IndustryInsightListModel } from '../service/serviceService';
import { ListingCard } from './cards/listing/ListingCard';
import { useNavigate } from 'react-router-dom';

interface IProps {
  insights: IndustryInsightListModel[];
  loadMore: () => void;
  total: number;
}

export function IndustryInsightServiceList({ insights, loadMore, total }: IProps): JSX.Element {
  const timeoutDuration = 300;
  const navigate = useNavigate();
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));

  function onClick(item: IndustryInsightListModel) {
    window.open(item.PdfUrl, '_blank');
    navigate('/detail/industryinsight/' + item.Id);
  }

  return (
    <Stack width="100%" px={{ xs: '20px', sm: 4 }}>
      <InfiniteScroll
        style={{ overflow: 'inherit' }}
        dataLength={insights.length} //This is important field to render the next data
        next={() => debounce(() => loadMore(), timeoutDuration)()}
        scrollThreshold={smallDown ? '550px' : '50px'}
        hasMore={insights.length !== total}
        loader={
          <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
            <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
          </Container>
        }>
        {insights.map((item, idx) => (
          <ListingCard
            hideEllipsis
            key={item.Id}
            title={item.Title}
            description={item.Description}
            thumbnailUrl={item.ImageUrl || item.ThumbnailUrl}
            createDate={item.Created}
            onClick={() => onClick(item)}
            isLast={idx === insights.length - 1}
            thumbnailFit='cover'
          />
        ))}
      </InfiniteScroll>
    </Stack>
  );
}
