/* eslint-disable @typescript-eslint/naming-convention */
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { Container, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import LockIcon from '@mui/icons-material/Lock';
import ReplyIcon from '@mui/icons-material/Reply';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SubtitleTwoPrimary, HeaderSix, BodyTwoPrimary, BodyTwoSecondary, CaptionSecondary } from '../../components-atoms/TypographyComponents';
import { IStyles } from '../../constants/theme';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { Thread, ThreadRecipient, ArchiveThread } from '../../service/serviceService';

interface ListItemProps {
  thread: Thread;
  currentTab: string;
  onArchive: () => void;
}

const DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  day: '2-digit',
  month: 'long',
};

export function ThreadListItem({ thread, currentTab, onArchive }: ListItemProps): JSX.Element {
  const navigate = useNavigate();
  const [token,userData, superData] = useTypedSelector(state => [state.userReducer.token, state.userReducer.data, state.userReducer.super]);
  const showThreeDotIcon = userData && userData.IsAdmin && (userData.IsTerritoryManager || userData.IsSalesManager) && !superData && !(currentTab === 'content' && userData.IsSalesManager);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const container = useRef<HTMLDivElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event: any) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const getRecipients = (data: ThreadRecipient[]) => {
    let result = '';

    data.forEach(recepient => {
      if (result === '') {
        result = recepient.FirstName + ' ' + recepient.LastName;
      } else {
        result = result + ', ' + recepient.FirstName + ' ' + recepient.LastName;
      }
    });
    return result;
  };

  const archiveThread = (threadId: string) => {
    if (token) {
      ArchiveThread(
        token,
        threadId,
        () => {
          onArchive();
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  return (
    <Container
      ref={container}
      onClick={() => navigate(userData && userData.IsAdmin && !superData ? `/admin/thread-detail/${thread.Id}` : `/thread-detail/${thread.Id}`, { state: { navigateBackTo: window.location.pathname, currentTab } })}
      style={styles.container}
      sx={{ backgroundColor: !thread.IsViewed ? 'rgba(1, 33, 105, 0.08)' : 'initial' }}
    >
      <Grid container justifyContent="flex-end">
        <Grid container flexDirection="column" alignItems="center" style={styles.iconGrid}>
          {!thread.IsViewed && <CircleIcon color="primary" style={styles.circleIcon} />}
          {thread.Archived && <LockIcon fontSize="small" style={styles.icons} />}
          {thread.HasReplies && <ReplyIcon fontSize="small" style={styles.icons} />}
        </Grid>
        <Grid style={styles.bodyGrid} sx={{ maxWidth: showThreeDotIcon ? 'calc(100% - 190px)' : 'calc(100% - 146px)' }}>
          <SubtitleTwoPrimary style={styles.mb4}>{thread.FirstName + ' ' + thread.LastName}</SubtitleTwoPrimary>
          <HeaderSix style={styles.mb4}>{thread.Subject}</HeaderSix>
          <Grid container flexWrap="nowrap" style={styles.mb4}>
            <BodyTwoPrimary style={styles.mr4}>
            To:
            </BodyTwoPrimary>
            <BodyTwoSecondary>
              {getRecipients(thread.Recipients)}
            </BodyTwoSecondary>
          </Grid>
          <Grid style={styles.innerBodyGrid}>
            <BodyTwoSecondary
              sx={{
                '& > h2, h3, p': { margin: 0 },
                '& > :not(:first-child)': { display: 'none' },
                '& > :first-child': {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: 400,
                  fontSize: '0.875rem',
                  lineHeight: 1.43,
                  letterSpacing: '0.01071em'
                }
              }}
            >
              {parse(thread.Body || '')}
            </BodyTwoSecondary>
          </Grid>
        </Grid>
        <Grid container flexDirection="column" alignItems="flex-end" style={styles.dateGrid}>
          <CaptionSecondary style={styles.mb4}>{new Date(thread.Created || '').toLocaleDateString(['en-gb'], DATE_OPTIONS)}</CaptionSecondary>
          {thread.AttachmentCount > 0
          && (
            <Grid container justifyContent="flex-end" alignItems="center" style={styles.mt4}>
              <AttachFileIcon style={styles.attachFileIcon} />
              <CaptionSecondary>{thread.AttachmentCount} Attachments</CaptionSecondary>
            </Grid>
          )}
        </Grid>
        {showThreeDotIcon
        && (
          <Grid item justifyContent="flex-end" alignItems="center" sx={{ '&.MuiGrid-root': { maxWidth: '36px', maxHeight: '36px', marginLeft: '8px' } }}>
            <IconButton data-testid="thread-three-dot" onClick={handleClick} disabled={thread.Archived}>
              <MoreVertIcon sx={{ height: '20px', width: '20px' }} />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem data-testid="thread-three-dot-archive" onClick={(e) => { handleClose(e); archiveThread(thread.Id); }}>
          <ListItemIcon>
            <LockIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText sx={{ color: 'error.main' }}>Archive</ListItemText>
        </MenuItem>
      </Menu>
    </Container>
  );
}

const styles: IStyles = {
  container: {
    maxWidth: '840px',
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '20px',
    margin: '16px 0px 0px 0px',
    paddingTop: '24px',
    paddingBottom: '24px',
    cursor: 'pointer'
  },
  circleIcon: { height: '10px', width: '10px', marginTop: '4px', marginBottom: '8px' },
  icons: { color: 'rgba(0, 0, 0, 0.38)', marginBottom: '8px' },
  attachFileIcon: { height: '16px', width: '16px', color: '#00000099', marginRight: '8px' },
  iconGrid: { width: '20px' },
  bodyGrid: { width: '100%', marginLeft: '8px' },
  innerBodyGrid: { width: '100%', marginBottom: '8px' },
  dateGrid: { width: '118px' },
  mt4: { marginTop: '4px' },
  mr4: { marginRight: '4px' },
  mb4: { marginBottom: '4px' }
};
