import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { CircularProgress, Container, Grid, useMediaQuery } from '@mui/material';
import { LIGHT_THEME } from '../constants/theme';
import { IndustryEventCard } from './cards/IndustryEventCard';
import IndustryEventWatchEvent from './modals/IndustryEventWatchEvent';
import IndustryEventPublishEvent from './modals/IndustryEventPublishEvent';

interface IProps {
  industryEvents: IndustryEvent[];
  total: number;
  isTMorSM?: boolean;
  loadMore: () => void;
  onEdit: (id: string) => void;
  onUnpublish: (id: string) => void;
  onJoinEvent: (eventId: GUID | string) => void;
  onWatchEventModalOpen: (eventId: GUID | string) => void;
  onWatchEventModalClose: () => void;
  watchEventModal?: IndustryEvent;
}

export function IndustryEventsList({ industryEvents, total, isTMorSM, loadMore, onEdit, onUnpublish, onJoinEvent, onWatchEventModalOpen, onWatchEventModalClose, watchEventModal }: IProps): JSX.Element {
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  const [renderData, setRenderData] = useState<IndustryEvent[]>(industryEvents);
  const [selectedEvent, setSelectedEvent] = useState('');

  useEffect(() => {
    setRenderData(industryEvents);
  }, [industryEvents]);

  const onPublish = (id: string) => {
    setSelectedEvent(id);
  };

  return (
    <div style={{ paddingBottom: '48px' }}>
      <InfiniteScroll
        style={{ overflowY: 'hidden' }}
        dataLength={total} //This is important field to render the next data
        next={() => loadMore()}
        scrollThreshold={smallDown ? '550px' : '50px'}
        hasMore={industryEvents.length < total}
        loader={
          <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
            <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
          </Container>
        }
      >
        <Grid container item xs={12} columnSpacing={2} rowSpacing={3} sx={{ width: '100%', margin: 0 }}>
          {renderData.map((industryEvent: IndustryEvent) =>
            <IndustryEventCard
              key={industryEvent.Id}
              event={industryEvent}
              isTMorSM={isTMorSM}
              onEdit={() => onEdit(industryEvent.Id)}
              onUnpublish={() => onUnpublish(industryEvent.Id)}
              onPublish={() => onPublish(industryEvent.Id)}
              onJoinEvent={onJoinEvent}
              onWatchEventModalOpen={onWatchEventModalOpen}
            />
          )}
        </Grid>
      </InfiniteScroll>

      <IndustryEventWatchEvent
        isOpen={watchEventModal !== undefined}
        id={watchEventModal ? watchEventModal.Id : ''}
        name={watchEventModal ? watchEventModal.Title : ''}
        password={watchEventModal?.RecordingPassword ? watchEventModal.RecordingPassword : ''}
        eventUrl={watchEventModal?.ShareUrl ? watchEventModal.ShareUrl : ''}
        onClose={() => onWatchEventModalClose()} />

      <IndustryEventPublishEvent
        isOpen={selectedEvent.length > 0}
        onClose={() => {
          setSelectedEvent('');
        }}
        onProceed={function (): void {
          onEdit(selectedEvent);
          setSelectedEvent('');
        }}
      />
    </div>
  );
}
