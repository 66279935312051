import { REQUIRED_VALIDATION, EMAIL_VALIDATION } from './_predefinedValidations';

export type SignInFormTypes = {
  email: string,
  password: string,
};

export const SIGNIN_FORM: [FormInputField, FormInputField] = [
  {
    label: 'Email Address',
    name: 'email',
    inputType: 'text',
    validation: {
      required:  REQUIRED_VALIDATION,
      pattern: EMAIL_VALIDATION
    }
  },
  {
    label: 'Password',
    name: 'password',
    inputType: 'password',
    validation: {
      required: REQUIRED_VALIDATION
    }
  }
];
