import { Fragment, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

import Delete from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';

import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { DeleteMerchantRequest, GetMerchantRequest, GetMerchantUsersRequest } from '../service/merchantsService';

import { Section } from '../components-molecules/section/Section';
import { SectionHeader } from '../components-molecules/section/SectionHeader';
import { SectionRow } from '../components-molecules/section/SectionRow';
import { InfoComponent } from '../components-atoms/InfoComponent';
import { SubPageHeader } from '../components-molecules/titlebars/SubPageHeader';
import { MerchantEmployeeTabPanel } from '../components-organisms/MerchantEmployeeTabPanel';
import { MerchantStoreTabPanel } from '../components-organisms/MerchantStoreTabPanel';
import { Dashboard } from '../components-organisms/Dashboard';
import { ButtonTextError, ButtonTextPrimary } from '../components-atoms/ButtonComponents';
import Edit from '@mui/icons-material/Edit';
import { MiniDialog } from '../components-molecules/dialogs/MiniDialog';
import { BodyOneSecondary } from '../components-atoms/TypographyComponents';
import imageIcon from '../assets/img/merchant_placeholder.jpg';

export function Merchant(): JSX.Element {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useTypedDispatch();
  const token = useTypedSelector((state) => state.userReducer.token);
  const refresh: boolean = useTypedSelector(state => state.merchantReducer.refresh);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<'stores' | 'employees'>(state ? state.latestTab : 'stores');
  const [merchant, setMerchant] = useState<Merchant>();
  const { merchantId } = useParams();
  const user = useTypedSelector((state) => state.userReducer.data);
  const getMerchant = useCallback((t: string, mId: GUID): void => {
    GetMerchantRequest(
      t,
      mId,
      (response) => {
        if (response) {
          setMerchant(response);
          dispatch({ type: 'MERCHANT_SET_CURRENT_DATA', payload: { merchant: response } });
          GetMerchantUsersRequest(
            t,
            response.Id,
            (resp) => {
              dispatch({ type: 'MERCHANT_SET_EMPLOYEES', payload: { employees: resp } });
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {}
          );

          dispatch({ type: 'MERCHANT_SET_STORES', payload: { stores: response.Stores } });
        }
      },
      (error) => {
        if (error.response) console.error(error.response.data.Error);
      }
    );
  }, [dispatch]);

  const deleteMerchant = (): void => {
    if (token) {
      DeleteMerchantRequest(
        token,
        merchantId as GUID,
        () => navigate('/admin/merchants'),
        (error) => {
          if (error.response)
            if (error.response) dispatch({ type: 'USER_ANY_FAILURE_NO_SIGNOUT', payload: { error: error.response.data.Error } });
        }
      );
    }
  };

  useEffect(() => {
    if (token && merchantId) {
      getMerchant(token, merchantId as GUID);
    }
  }, [getMerchant, merchantId, token, refresh]);
  
  return (
    <Dashboard>
      {merchant
        ? <Fragment>
          <SubPageHeader
            title={(merchant) ? merchant.Name : ''}
            subtitle="Merchant"
            backRoute="/admin/merchants"
            logoSrc={merchant.ThumbnailUrl || merchant.Logo || imageIcon}
            buttons={[
              user && user.IsAdmin && user.IsTerritoryManager
                ? <></>
                : <ButtonTextError key={0} startIcon={<Delete />} onClick={() => setModalOpen(true)}>Delete</ButtonTextError>
            ]}
          />

          <Section>
            <SectionHeader title="Basic information" button={
              <ButtonTextPrimary startIcon={<Edit />} onClick={() => navigate('/admin/edit-merchant', { state: { navigateBackTo: window.location.pathname } }) }>Edit</ButtonTextPrimary>
            } />

            <SectionRow title="Payer/parent code" lines={[{ data: merchant.PayerCode, type: 'body1' }]} />

            <Box><Divider /></Box>

            <SectionRow title="Audience" lines={[{ data: merchant.AudienceName, type: 'body1' }]} />

            <Box><Divider /></Box>

            <SectionRow
              title="HQ Address"
              lines={[
                { data: merchant.AddressLine1, type: 'body1' },
                { data: merchant.Phone, type: 'body2', color: 'text.secondary' }
              ]}
            />

            <Box><Divider /></Box>

            {merchant.MerchantManagers.length > 0 && merchant.MerchantManagers[0].UserProperties.Id !== '00000000-0000-0000-0000-000000000000' // TODO: oof
              ? <SectionRow
                title="Manager"
                lines={[
                  // TODO: Fix this later
                  { data: `${merchant.MerchantManagers[0].UserProperties.FirstName} ${merchant.MerchantManagers[0].UserProperties.LastName}`, type: 'body1' },
                  { data: merchant.MerchantManagers[0].UserProperties.Email, type: 'body2', color: 'text.secondary' },
                  { data: merchant.MerchantManagers[0].UserProperties.Phone, type: 'body2', color: 'text.secondary' }
                ]}
              />
              : <SectionRow
                lines={[{
                  element: <InfoComponent
                    key={0}
                    title="Merchant Manager"
                    description="You haven't added any Merchant Managers to this merchant. Once you will add an employee with Merchant Manager user role assigned to them they will appear here."
                  />
                }]}
              />
            }
          </Section>

          <TabContext value={currentTab}>
            <TabList onChange={(e: unknown, newValue: 'stores' | 'employees') => setCurrentTab(newValue)} sx={{ margin: '24px 0 0', width: '100%' }}>
              <Tab label="Merchant Stores" value="stores" sx={{ width: '50%', maxWidth: '50%' }} />
              <Tab label="Merchant Employees" value="employees" sx={{ width: '50%', maxWidth: '50%' }} />
            </TabList>

            <Box sx={{ minHeight: '80vh', width: '100%' }}>
              <MerchantStoreTabPanel merchant={merchant} setPageOpen={() => navigate('/admin/add-store', { state: { navigateBackTo: window.location.pathname } } )} />
              <MerchantEmployeeTabPanel setPageOpen={() => navigate('/admin/add-employee', { state: { navigateBackTo: window.location.pathname } } )} />
            </Box>
          </TabContext>
        </Fragment>
        : null
      }
      {modalOpen
        ? <MiniDialog
          title="Delete Merchant"
          open={modalOpen}
          close={() => setModalOpen(false)}
          remove={() => {
            deleteMerchant();
            setModalOpen(false);
          }}>
          <Box sx={{ padding: '0 24px' }}>
            <BodyOneSecondary>
              Are you sure you want to delete this Merchant?
            </BodyOneSecondary>
          </Box>
        </MiniDialog>
        : null
      }
    </Dashboard>
  );
}
