import { FormEvent } from 'react';

import { styled } from '@mui/system';
import Card, { CardProps } from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { ImageComponent } from '../../components-atoms/ImageComponents';
import { CenteredDialogComponent } from '../dialogs/CenteredDialog';

import DuluxMerchantConnectLogo from '../../assets/img/DuluxMerchantConnect-logo-96x96.png';
import { useLocation } from 'react-router-dom';

const SigninCardComponent = styled(Card)<CardProps>(() => {
  const location = useLocation();

  return ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: '550px',
    height: '100%',
    borderRadius: '20px',
    padding: location.pathname === '/welcome' ? '48px 0px 24px' : '48px 40px 24px',
  });
});

interface FormCardProps {
  children: null | JSX.Element | (JSX.Element | null)[];
  leftButton?: JSX.Element;
  rightButton?: JSX.Element;
  submit?: (e: FormEvent<HTMLFormElement>) => void;
  formId?: string;
  formName?: string;
}

export function FormCard({ children, leftButton, rightButton, submit, formId, formName }: FormCardProps): JSX.Element {
  return (
    <CenteredDialogComponent sx={{ width: '100%' }}>
      <ImageComponent
        src={DuluxMerchantConnectLogo}
        alt="AkzoNobel"
        loading="lazy"
        height="96"
        sx={{ maxWidth: '100%', marginBottom: '48px' }}
      />
      <Box name={formName} id={formId} component="form" noValidate onSubmit={submit} sx={{ width: '450px', maxWidth: '100%', padding: { xs: '0 16px', sm: '0' } }}>
        <SigninCardComponent variant="outlined">
          <Grid container sx={{ width: '450px', maxWidth: '100%' }}>
            {children}
          </Grid>

          <Grid container sx={{ width: '450px', maxWidth: '100%', justifyContent: 'space-between' }}>
            <Grid item>
              {leftButton}
            </Grid>

            <Grid item>
              {rightButton}
            </Grid>
          </Grid>
        </SigninCardComponent>
      </Box>
    </CenteredDialogComponent>
  );
}
