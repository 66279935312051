import { Fragment, useCallback, useState } from 'react';

import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import { Section } from '../../components-molecules/section/Section';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { SectionRow } from '../../components-molecules/section/SectionRow';
import { InfoComponent } from '../../components-atoms/InfoComponent';
import { DeleteStoreRequest } from '../../service/storeService';
import { useTypedSelector, useTypedDispatch } from '../../hooks/TypedReduxHooks';
import { MiniDialog } from '../../components-molecules/dialogs/MiniDialog';
import { BodyOneSecondary } from '../../components-atoms/TypographyComponents';
import { useNavigate } from 'react-router-dom';

export function StoreCard({ store, navigateBackTo }: { store: Store; navigateBackTo: string; }): JSX.Element {
  const [token, userData] = useTypedSelector(state => [state.userReducer.token, state.userReducer.data]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const deleteStore = useCallback((): void => {
    if (token) {
      DeleteStoreRequest(
        token,
        store.Id,
        () => { dispatch({ type: 'MERCHANT_REFRESH' }); },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  }, [dispatch, store, token]);

  const onEdit = () => {
    dispatch({
      type: 'SET_CURRENT_STORE', payload: {
        store: {
          id: store.Id,
          name: store.Name,
          customerCode: store.CustomerCode,
          addressLine1: store.AddressLine1,
          addressLine2: store.AddressLine2,
          city: store.City,
          postCode: store.PostCode,
          county: store.County,
          email: store.Email,
          phone: store.Phone,
        },
        employeeList: store.StoreEmployees,
        managerList: store.StoreManagers,
        territoryManager: store.TerritoryManager,
        merchantManagerList: store.MerchantManagers
      }
    });
    navigate(userData && userData.IsAdmin ? '/admin/edit-store' : '/edit-store', { state: { navigateBackTo } });
  };

  return (
    <Section>
      <SectionHeader hasMenuIcon={userData && userData.Role !== 'Store Employee'} title={store.Name} onDelete={() => setModalOpen(true)} onEdit={() => onEdit()} />

      <SectionRow
        title="Address"
        lines={[
          //TODO: add address concenator
          { data: store.AddressLine1, type: 'body1' },
          { data: store.Phone, type: 'body2', color: 'text.secondary' }
        ]}
      />

      <Box><Divider /></Box>

      <SectionRow
        title="Customer Code"
        lines={[
          { data: store.CustomerCode, type: 'body1' }
        ]}
      />



      {!(userData?.Role === 'Store Manager' || userData?.Role === 'Store Employee' || userData?.Role === 'Merchant Manager')
        ? <>
          <Box><Divider /></Box>
          {(store.TerritoryManager
            ? <SectionRow
              title="Territory Manager"
              lines={[
                { data: `${store.TerritoryManager.AdminProperties.FirstName} ${store.TerritoryManager.AdminProperties.LastName}`, type: 'body1' },
                { data: store.TerritoryManager.AdminProperties.Email, type: 'body2', color: 'text.secondary' },
                { data: store.TerritoryManager.AdminProperties.Phone, type: 'body2', color: 'text.secondary' }
              ]}
            />
            : <SectionRow
              title="Territory Manager"
              lines={[{
                element: <InfoComponent
                  key={store.Id}
                  title=""
                  description="You haven't added a Territory Manager to this store."
                />
              }]}
            />)}
        </> : null
      }

      <Fragment>
        {store.MerchantManagers.map((merchantManager, index) =>
          <Fragment key={index}>
            <Box><Divider /></Box>
            <SectionRow
              title="Merchant Manager"
              lines={[
                // TODO: Fix later
                { data: `${merchantManager.UserProperties.FirstName} ${merchantManager.UserProperties.LastName}`, type: 'body1' },
                { data: merchantManager.UserProperties.Email, type: 'body2', color: 'text.secondary' },
                { data: merchantManager.UserProperties.Phone, type: 'body2', color: 'text.secondary' }
              ]}
            />
          </Fragment>
        )}
      </Fragment>

      <Fragment>
        {store.StoreManagers.map((manager, index) =>
          <Fragment key={index}>
            <Box><Divider /></Box>
            <SectionRow
              title="Store Manager"
              lines={[
                // TODO: Fix later
                { data: `${manager.UserProperties.FirstName} ${manager.UserProperties.LastName}`, type: 'body1' },
                { data: manager.UserProperties.Email, type: 'body2', color: 'text.secondary' },
                { data: manager.UserProperties.Phone, type: 'body2', color: 'text.secondary' }
              ]}
            />
          </Fragment>
        )}
      </Fragment>

      <Fragment>
        {store.StoreEmployees.map((employee, index) =>
          <Fragment key={index}>
            <Box><Divider /></Box>
            <SectionRow
              title="Store Employee"
              lines={[
                { data: `${employee.UserProperties.FirstName} ${employee.UserProperties.LastName}`, type: 'body1' },
                { data: employee.UserProperties.Email, type: 'body2', color: 'text.secondary' }
              ]}
            />
          </Fragment>
        )}
      </Fragment>

      {
        modalOpen
          ? <MiniDialog
            title="Delete Store"
            open={modalOpen}
            close={() => setModalOpen(false)}
            remove={() => {
              deleteStore();
              setModalOpen(false);
            }}>
            <Box sx={{ padding: '0 24px' }}>
              <BodyOneSecondary>
                {'Are you sure you want to delete this Store?'}
              </BodyOneSecondary>
            </Box>
          </MiniDialog>
          : null
      }
    </Section>
  );
}
