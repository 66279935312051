import { useCallback, useEffect, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import {
  IndustryInsightListModel,
  ListIndustryInsightsRequest,
} from '../service/serviceService';
import { IndustryInsightServiceList } from './IndustryInsightServiceList';
import { ServicePageHeader } from './appbars/ServicePageHeader';
import {
  BodyOneSecondary,
  HeaderFive,
} from '../components-atoms/TypographyComponents';
import { IStyles, LIGHT_THEME } from '../constants/theme';
import { Box, CircularProgress, Grid } from '@mui/material';

interface IProps {
  id: string;
  service: any;
}

export function IndustryInsightsService({ id, service }: IProps): JSX.Element {
  const dispatch = useTypedDispatch();
  const token = useTypedSelector((state) => state.userReducer.token);
  const [insights, setInsights] = useState<IndustryInsightListModel[]>([]);
  const pageSize = 10;
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState(0);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(true);

  const loadMore = () => {
    if (token) {
      getInsights(token, page + 1, pageSize, insights);
      setPage(page + 1);
    }
  };

  const styles: IStyles = {
    divider: { width: '100%', marginTop: '32px' },
    noItemsIcon: { fontSize: '88px', fill: LIGHT_THEME.palette.primary.main },
    circularProgress: { margin: '48px 0' },
  };

  const getInsights = useCallback(
    (
      token: string,
      page = 0,
      pageSize = 10,
      prevData: IndustryInsightListModel[] = []
    ) => {
      if (token) {
        ListIndustryInsightsRequest(
          token,
          {
            Size: pageSize,
            Page: page,
            Query: query,
            ServiceId: id,
          },
          (response) => {
            if (response) {
              setTotal(response.Result.Count);
              setInsights(prevData.concat(response.Result.Result));
              setTimeout(() => {
                setLoading(false);
              }, 100);
            }
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => setLoading(false)
        );
      }
    },
    [id, query]
  );

  const onSearch = (text: string) => {
    setPage(0);
    setLoading(true);
    setInsights([]);
    setQuery('');
    if (text.length > 0) {
      setTimeout(() => {
        setQuery(text);
        dispatch({ type: 'SET_DIGITAL_ASSET_SEARCH', payload: { search: text } });
      }, 100);
    }
  };

  useEffect(() => {
    if (token) getInsights(token);
  }, [token, getInsights]);

  return (
    <>
      <ServicePageHeader service={service} onSearch={onSearch} />      
      {loading ? (
        <Grid container justifyContent='center'>
          <CircularProgress
            color='primary'
            size={50}
            style={styles.circularProgress}
          />
        </Grid>
      ) : insights.length > 0 ? (
        <IndustryInsightServiceList
          total={total}
          insights={insights}
          loadMore={loadMore}
        />
      ) : (
        <Box
          width='320px'
          margin='0 auto'
          display='flex'
          flexDirection='column'
          alignItems='center'
          textAlign='center'
        >
          <Box mt={6} mb={4}>
            <img
              src={require('../assets/img/brand-assets/no-result.png')}
              width='160px'
              height='160px'
            />
          </Box>
          <HeaderFive mb={1}>No industry insights</HeaderFive>
          <BodyOneSecondary>
            We couldn’t find any matching insights for your search term. Please
            check your search term to try again.
          </BodyOneSecondary>
        </Box>
      )}
    </>
  );
}
