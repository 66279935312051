import { Divider } from '@mui/material';
import { Section } from '../../components-molecules/section/Section';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { SectionRow } from '../../components-molecules/section/SectionRow';
import { GetAdminUsersResponseModel } from '../../service/serviceService';

interface IProps {
  user: GetAdminUsersResponseModel;
  onDelete: (id: GUID) => void;
  onEdit: (id: GUID) => void;
}
export function AdminUserCard({ user, onDelete, onEdit }: IProps): JSX.Element {
  return (
    <Section>
      <SectionHeader
        hasMenuIcon
        label={user.RegistrationPending ? 'Registration Pending' : ''}
        title={`${user.FirstName} ${user.LastName}`}
        onDelete={() => onDelete(user.Id)}
        onEdit={() => onEdit(user.Id)}
      />

      <SectionRow
        title="Contact"
        lines={[
          { data: user.Email, type: 'body1' },
          {
            data: user.Phone,
            type: 'body2',
            color: 'text.secondary'
          }
        ]}
      />

      <Divider />
      
      <SectionRow
        title="User Role"
        lines={[
          { data: user.Role || 'User has no user role assigned', type: 'body1', color: user.Role ? 'text.primary' : 'error.main' }
        ]}
      />
    </Section>
  );
}
