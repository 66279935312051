import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { FieldValues, Control, useController } from 'react-hook-form';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { Checkbox, ListItemText, TextField } from '@mui/material';
import { ImageUploadButton } from './ImageUploadButton';
import { BodyOnePrimary, BodyTwoSecondary } from '../components-atoms/TypographyComponents';
import DatePicker from '@mui/lab/DatePicker';
import presentationIcon from '../assets/icons/Presentationfile.svg';
import otherIcon from '../assets/icons/Otherfile.svg';
import videoIcon from '../assets/icons/Videofile.svg';

interface FormControlInputComponentProps {
  control: Control<FieldValues, any>;
  field: FormInputField;
  disabled?: boolean;
  multiline?: boolean;
  halfWidth?: boolean;
  suppressError?: boolean;
  showPassword?: boolean;
  setShowPassword?: React.Dispatch<React.SetStateAction<boolean>>;
  defaultValue?: string;
  dontShowOptionalText?: boolean;
}

export function FormControlInput({ control, field, disabled, multiline, suppressError, showPassword, setShowPassword, defaultValue, dontShowOptionalText }: FormControlInputComponentProps): JSX.Element {
  const controller = useController({ name: field.name, control, rules: field.validation, defaultValue: defaultValue || '' });
  return (
    <FormControl
      variant="filled"
      fullWidth
      error={(suppressError || !controller.fieldState.invalid || !controller.fieldState.error) ? false : (controller.fieldState.invalid || controller.fieldState.error)}
      sx={{ margin: '16px 0 0' }}
    >
      <InputLabel htmlFor={field.name} required={false}>{field.label}{field.validation.required ? '' : !dontShowOptionalText ? ' (optional)' : ''}</InputLabel>

      <FilledInput
        id={field.name}
        disabled={disabled}
        multiline={multiline}
        type={(field.inputType === 'password' && showPassword) ? 'text' : field.inputType}
        autoComplete={field.name}
        {...controller.field}
        endAdornment={(field.inputType === 'password' && (showPassword || setShowPassword))
          ? <InputAdornment position="end">
            <IconButton
              edge="end"
              aria-label="toggle password visibility"
              onClick={() => { if (setShowPassword) setShowPassword(v => !v); }}
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
          : <Fragment />
        }
      />

      {suppressError
        ? null
        : <FormHelperText>
          {(controller.fieldState.invalid && controller.fieldState.error) ? controller.fieldState.error.message : ''}
        </FormHelperText>
      }
    </FormControl>
  );
}

interface FormControlSelectComponentProps {
  value?: boolean;
  control: Control<FieldValues, any>;
  field: FormSelectField;
  //TODO: initial value
  halfWidth?: boolean;
  suppressError?: boolean;
  selectProps?: any;
  noMargin?: boolean;
  showCheckbox?: boolean;
  renderValue?: string;
}

export function FormControlSelect({ renderValue, control, field, halfWidth, suppressError, selectProps, noMargin, showCheckbox }: FormControlSelectComponentProps): JSX.Element {
  const controller = useController({ name: field.name, control, rules: field.validation, defaultValue: '' });

  return (
    <FormControl
      fullWidth={!halfWidth} variant="filled"
      error={(suppressError || !controller.fieldState.invalid) ? false : controller.fieldState.invalid}
      sx={{ margin: noMargin ? 0 : '16px 0 0' }}
    >
      <InputLabel htmlFor={field.name} required={false}>{field.label}{field.validation.required ? '' : ''}</InputLabel>

      <Select
        id={field.name}
        label={field.name}
        renderValue={(s: any) => renderValue ? renderValue : Array.isArray(s) ? s.join(', ') : field.options?.find(o => o.value === s)?.name || ''}
        {...controller.field}
        {...selectProps}
      >
        {field.options?.filter(v => v.value !== '').map((option) =>
          <MenuItem
            key={option.value}
            value={option.value}>
            {showCheckbox && <Checkbox checked={selectProps?.value?.indexOf(option?.value) > -1} />}
            <ListItemText primary={option.name} />
          </MenuItem>)}
      </Select>

      {suppressError
        ? null
        : <FormHelperText>
          {(controller.fieldState.invalid && controller.fieldState.error) ? controller.fieldState.error.message : ''}
        </FormHelperText>
      }
    </FormControl>
  );
}

interface FormControlImageComponentProps {
  control: Control<FieldValues, any>;
  field: FormImageField;
  initialValue?: string;
  initialFileType?: string;
  suppressError?: boolean;
  title?: string;
  multiple?: boolean;
  accept?: string;
  subtitle?: string;
  removeHelperTextMarginLeft?: boolean;
}

export function FormControlImage({
  control,
  field,
  initialValue,
  initialFileType,
  suppressError,
  title = 'Logotype',
  multiple,
  accept,
  subtitle = 'You can upload images, videos, PDFs and PowerPoint presentations. You can select and upload multiple sizes (files) at once but you can only upload a single file type per brand asset.',
  removeHelperTextMarginLeft
}: FormControlImageComponentProps): JSX.Element {
  const [file, setFile] = useState<File>();
  const controller = useController({
    name: field.name,
    control,
    rules: initialValue ? undefined : field.validation,
    defaultValue: []
  });
  const [fileType, setFileType] = useState<string>(initialFileType || '');
  const [uri, setUri] = useState<string>(initialValue || '');
  useEffect(() => {
    if (file) {
      setUri(URL.createObjectURL(file));
      setFileType(file.type);
    }
  }, [file]);

  console.debug({ fileType, uri });

  return (
    <FormControl
      fullWidth
      error={(suppressError || !controller.fieldState.invalid) ? false : controller.fieldState.invalid}
      sx={{ margin: '16px 0 0', '.MuiFormHelperText-root': { marginLeft: removeHelperTextMarginLeft ? 0 : 'initial' } }}
    >
      <Grid item container sx={{ flexGrow: 1, justifyContent: 'space-between', alignItems: 'center', flexWrap: 'nowrap' }}>
        <Grid item>
          <BodyOnePrimary sx={{ marginBottom: '4px' }}>{title}</BodyOnePrimary>
          <BodyTwoSecondary variant="body2">{subtitle}</BodyTwoSecondary>
          {suppressError
            ? null
            : <FormHelperText>
              {(controller.fieldState.invalid && controller.fieldState.error) ? controller.fieldState.error.message : ''}
            </FormHelperText>
          }
        </Grid>
        <Grid item>
          <ImageUploadButton
            name={field.name}
            accept={accept}
            defaultImage={
              fileType.includes('image')
                ? uri
                : uri
                  ? fileType.includes('video')
                    ? videoIcon
                    : fileType.includes('presentation')
                      ? presentationIcon
                      : otherIcon
                  : ''}
            onChange={(e) => { if (multiple) { const fileList = e as FileList; setFile(fileList[0]); } else { setFile(e as File); } controller.field.onChange(e); }}
            multiple={multiple}
          />
        </Grid>
      </Grid>
    </FormControl>
  );
}

interface FormControlDateComponentProps {
  control: Control<FieldValues, any>;
  field: FormDateField;
  halfWidth?: boolean;
  suppressError?: boolean;
  setIsDateValid: Dispatch<SetStateAction<boolean>>;
  testId?: string;
}

export function FormControlDate({ control, field, suppressError, setIsDateValid, testId }: FormControlDateComponentProps): JSX.Element {
  const controller = useController({ name: field.name, control, rules: field.validation, defaultValue: null });
  return (
    <FormControl
      variant="filled"
      fullWidth
      error={(suppressError || !controller.fieldState.invalid) ? false : controller.fieldState.invalid}
      sx={{ margin: '16px 0 0' }}
    >
      {/*<InputLabel htmlFor={field.name} required={false}>{field.label}{field.validation.required ? '' : ' (optional)'}</InputLabel>*/}

      <DatePicker
        label={field.label}
        mask="__-__-____"
        inputFormat="dd-MM-yyyy"
        onError={(error) => error ? setIsDateValid(false) : setIsDateValid(true)}
        onAccept={() => setIsDateValid(true)}
        renderInput={(params) => <TextField variant="filled" data-testid={testId} {...params} autoComplete='off' />}
        {...controller.field}
      />

      {suppressError
        ? null
        : <FormHelperText>
          {(controller.fieldState.invalid && controller.fieldState.error) ? controller.fieldState.error.message : ''}
        </FormHelperText>
      }
    </FormControl>
  );
}
