import { Fragment, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { StoreFormTypes, STORE_FORM } from '../../forms/store';
import { useTypedDispatch, useTypedSelector } from '../../hooks/TypedReduxHooks';
import { AddStoreRequest, AssignToStoreRequest } from '../../service/storeService';

import { ModalCardComponent } from '../../components-molecules/Modal';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { FormControlInput } from '../../components-molecules/FormControl';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { SectionRow } from '../../components-molecules/section/SectionRow';
import { InfoComponent } from '../../components-atoms/InfoComponent';
import { ListDialog } from '../../components-molecules/dialogs/MiniDialog';
import { ButtonContainedPrimary } from '../../components-atoms/ButtonComponents';

import { Alert, FormHelperText } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetTerritoryManagersRequest } from '../../service/adminService';

export function AddStore(): JSX.Element {
  const dispatch = useTypedDispatch();
  const form = useForm({ mode: 'all', reValidateMode: 'onBlur' });
  const [dialog, setDialog] = useState<false | 'territorymanager' | 'storeemployee'>(false);
  const [token, userData] = useTypedSelector((state) => [state.userReducer.token, state.userReducer.data]);
  const [territoryManager, setTerritoryManager] = useState<TerritoryManager>();
  const [storeEmployees, setStoreEmployees] = useState<MerchantUser[]>([]);
  const [tmId, setTmId] = useState<string>();
  const [employeesToAssign, setEmployeesToAssign] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const merchant: Merchant = useTypedSelector(state => state.merchantReducer.currentData);
  const employeesList: MerchantUser[] = useTypedSelector(state => state.merchantReducer.employees);
  const territoryManagers: TerritoryManager[] = useTypedSelector(state => state.territoryManagersReducer.data);
  const territoryManagersLoading: boolean = useTypedSelector(state => state.territoryManagersReducer.loading);
  const [query, setQuery] = useState('');

  const navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    if (userData && userData.IsTerritoryManager) {
      setTerritoryManager(territoryManagers.find(f => f.AdminProperties.Id === userData.Id));
      setTmId(userData.Id);
    }
  }, [userData, territoryManagers]);
  const getTerritoryManagers = useCallback(() => {
    if (token) {
      dispatch({ type: 'SET_MANAGERS_LOADING', payload: { currentData: true } });
      GetTerritoryManagersRequest(
        token,
        { Query: query },
        (response) => {
          dispatch({ type: 'SET_MANAGERS', payload: { currentData: response } });
          dispatch({ type: 'SET_MANAGERS_LOADING', payload: { currentData: false } });
        },
        (error) => { console.error(error); }
      );
    }
  }, [query, token, dispatch]);

  const submit: SubmitHandler<StoreFormTypes> = (data): void => {
    if (token) {
      setLoading(true);
      setErrorMessage('');
      AddStoreRequest(
        token,
        {
          StoreName: data.name,
          MerchantId: merchant.Id,
          CustomerCode: data.customerCode,
          AddressLine1: data.addressLine1,
          AddressLine2: data.addressLine2,
          City: data.city,
          PostCode: data.postCode,
          County: data.county,
          Phone: data.phone,
          Email: data.email,
          TerritoryManagerId: tmId as GUID
        },
        (response) => {
          if (employeesToAssign.length > 0) {
            employeesToAssign.map(id => {
              AssignToStoreRequest(
                token,
                {
                  StoreId: response.Result as GUID,
                  UserId: id as GUID,
                },
                () => setLoading(false),
                () => setLoading(false)
              );
            });
          } else {
            setLoading(false);
          }
          form.reset();
          setTerritoryManager(undefined);
          setStoreEmployees([]);
          navigate(state.navigateBackTo);
        },
        (error) => {
          setLoading(false);
          setErrorMessage(error.response?.data.Error || '');
        }
      );
    }
  };

  const addTerritoryManager = (list: Set<string>): void => {
    setTmId(list.values().next().value);
    setTerritoryManager(territoryManagers.find(f => list.has(f.AdminProperties.Id)));
    setDialog(false);
  };

  const addStoreEmployees = (list: Set<string>): void => {
    const tmpAssign: string[] = [];
    list.forEach(val => {
      tmpAssign.push(val);
    });
    setEmployeesToAssign(tmpAssign);
    setStoreEmployees(employeesList.filter(user => tmpAssign.includes(user.Id)));
    setDialog(false);
  };

  const searchTermChanged = (text: string) => {
    setQuery(text);
  };

  useEffect(() => {
    getTerritoryManagers();
  }, [getTerritoryManagers]);

  return (
    <FormPageComponent
      title="Add New Store"
      buttonTitle="Add Store"
      buttonDisabled={!form.formState.isValid || loading}
      close={() => {
        navigate(state.navigateBackTo);
        form.reset();
        setTerritoryManager(undefined);
        setStoreEmployees([]);
      }}
      submit={form.handleSubmit(submit)}
      buttonLoading={loading}
      hasError={!!errorMessage}
    >
      <Fragment>
        <Grid item sx={{ flexGrow: 1 }}>
          {!!errorMessage
            && <Alert severity="error">{errorMessage}</Alert>
          }
        </Grid>
        {STORE_FORM.map(section => {
          return (
            <ModalCardComponent key={section.title}>
              <SectionHeader title={section.title} />
              <Fragment>
                <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
                  {section.fields.map(field =>
                    <Grid key={field.name} item xs={field.name === 'city' || field.name === 'postCode' ? 6 : 12}>
                      <FormControlInput control={form.control} field={field} />
                      {field.helperText 
                       && <FormHelperText sx={{ padding: '0 14px' }}>
                         {field.helperText}
                       </FormHelperText>
                      }
                    </Grid>
                  )}
                </Grid>
              </Fragment>
            </ModalCardComponent>
          );
        })}
      </Fragment>

      <ModalCardComponent>
        <SectionHeader
          title="Territory manager"
          button={
            userData && (userData.Role !== 'Store Manager' && userData.Role !== 'Store Employee')
              ? <ButtonContainedPrimary disabled={territoryManagers.length === 0 || userData.IsTerritoryManager} startIcon={<AddIcon />} onClick={() => setDialog('territorymanager')}>Link Manager</ButtonContainedPrimary>
              : undefined
          }
        />

        {territoryManager === undefined
          ? <Grid item sx={{ flexGrow: 1, margin: '16px 0' }}>
            <InfoComponent
              title="Add Territory Manager"
              description={territoryManagers.length > 0 ? 'Add Territory Manager to the store.' : 'You haven\'t added any territory managers to this merchant yet.'}
            />
          </Grid>
          : <SectionRow
            title="Territory Manager"
            lines={[
              { data: `${territoryManager.AdminProperties.FirstName} ${territoryManager.AdminProperties.LastName}`, type: 'body1' },
              { data: territoryManager.AdminProperties.Email, type: 'body2', color: 'text.secondary' },
              { data: territoryManager.AdminProperties.Phone, type: 'body2', color: 'text.secondary' }
            ]}
          />
        }
      </ModalCardComponent>

      <ModalCardComponent>
        <SectionHeader
          title="Store users"
          button={
            <ButtonContainedPrimary disabled={employeesList.length === 0} startIcon={<AddIcon />} onClick={() => setDialog('storeemployee')}>Link Employees</ButtonContainedPrimary>
          }
        />

        {storeEmployees.length === 0
          ? <Grid item sx={{ flexGrow: 1, margin: '16px 0' }}>
            <InfoComponent
              title="Adding Store Users"
              description={employeesList.length > 0 ? 'Add Store Manager and Store Users to the store.' : 'You haven\'t added any employees to this merchant yet.'}
            />
          </Grid>
          : <Fragment>
            {storeEmployees.map((employee, index) =>
              <Fragment key={index}>
                <SectionRow
                  title={employee.Role}
                  lines={[
                    { data: `${employee.FirstName} ${employee.LastName}`, type: 'body1' },
                    { data: employee.Email, type: 'body2', color: 'text.secondary' }
                  ]}
                />
                {storeEmployees.length - 1 > index ? <Box><Divider /></Box> : null}
              </Fragment>
            )}
          </Fragment>
        }
      </ModalCardComponent>

      <ListDialog
        items={territoryManagers}
        title="Add Territory Manager"
        caption={{ singular: 'Territory Manager', plural: 'Territory Managers' }}
        open={dialog === 'territorymanager'}
        close={() => setDialog(false)}
        submit={addTerritoryManager}
        hasSearch
        searchTextOnChange={searchTermChanged}
        searchPlaceHolder="Search Territory Manager"
        loading={territoryManagersLoading}
      />

      <ListDialog
        items={employeesList}
        title="Add Store Users"
        caption={{ singular: 'User', plural: 'Users' }}
        open={dialog === 'storeemployee'}
        close={() => setDialog(false)}
        submit={addStoreEmployees}
        multiple
      />
    </FormPageComponent >
  );
}
