/* eslint-disable @typescript-eslint/naming-convention */
import { Grid } from '@mui/material';
import React, { Fragment } from 'react';
import { ButtonRouteTextPrimary } from '../components-atoms/ButtonComponents';
import { BodyTwoSecondary, HeaderSix } from '../components-atoms/TypographyComponents';
import { FormCard } from '../components-molecules/cards/FormCard';
import { IStyles } from '../constants/theme';
import CheckIcon from '@mui/icons-material/Check';

export default function GetInvitedSuccess():JSX.Element {
  return (
    <FormCard
      leftButton={<ButtonRouteTextPrimary  fullWidth sx={{ my: 1 }} to="/signin">Back to Sign In</ButtonRouteTextPrimary>}
    >
      <Grid item sx={{ flexGrow: 1 }}>
        <HeaderSix data-testid="sign-in-page">Thank you for registering your interest with Dulux Connect for Merchants</HeaderSix>
        
        <Grid style={styles.iconContainer}>
          <Grid style={styles.iconCircle}>
            <CheckIcon style={styles.icon} />
          </Grid>
        </Grid>
        <BodyTwoSecondary style={styles.pb16}>We have received your application.</BodyTwoSecondary>
        <BodyTwoSecondary style={styles.pb16}>We will review your application and send you a link to the Sign In page to set your password after your organisation has been approved.</BodyTwoSecondary>
        <BodyTwoSecondary style={styles.pb16}>After signing in you will be able to create more stores and invite further users to join your organisation.</BodyTwoSecondary>
      </Grid>
      <Fragment>
      
      </Fragment>
    </FormCard>
  );
}
const styles: IStyles = {
  iconContainer:{
    width:'100%',
    padding:'16px 0px',
    display: 'flex',
    justifyContent: 'center',
  },
  iconCircle:{
    width:'160px',
    height:'160px',
    borderRadius:'80px',
    backgroundColor:'#4CAF5014',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon:{
    width:'96px',
    height:'96px',
    color:'#4CAF50',
  },
  pb16:{
    paddingBottom:'16px'
  }

};
  
