/* eslint-disable react-hooks/exhaustive-deps */
import { Chip, Stack, SxProps } from '@mui/material';
import { ButtonTextPrimary } from '../../components-atoms/ButtonComponents';

// TODO: any
interface IProps {
  chips: any,
  onClear: () => void,
  onDelete: (filter: any) => void
  sx: SxProps<any>
}

export function HeaderFilterChips(props: IProps): JSX.Element {
  const {chips, onClear, onDelete, sx} = props;

  if(!chips || chips?.length === 0){
    return <></>;
  }

  return (
    <Stack flex={1} display="flex" flexDirection="row" alignItems="center" sx={sx}>
      {chips?.length > 0
      && <ButtonTextPrimary
        size="small"
        onClick={onClear}
        sx={{ minWidth: 80, padding: '4px 8px'  
        }}>
      Clear All
      </ButtonTextPrimary> 
      }
      <Stack
        display="flex"
        flexDirection="row"
        alignItems="center"
        ml={1} 
        gap={1}
        width="100%"
        overflow="auto"
        sx={{
          scrollSnapType: 'x mandatory',
          '&::-webkit-scrollbar' : {
            display: 'none',
          }
        }}>
        {chips.map((o: any) =>
          <Chip
            key={o.value} 
            label={o.name}
            onDelete={()=> onDelete(o)}
            sx={{
              fontSize: '13px',
              borderRadius: '8px',
              background: '#E0E0E0',
              scrollSnapAlign: 'center',
            }}   
          />    
        )}
      </Stack>
    </Stack>
  );
}
