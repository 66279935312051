import { useEffect, useState } from 'react';
import { useScrollPosition } from '../hooks/useScrollPosition';
import { useTypedSelector } from '../hooks/TypedReduxHooks';

import { styled } from '@mui/system';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';

import { LIGHT_THEME } from '../constants/theme';

import { DrawerList } from '../components-molecules/DrawerList';
import { ButtonContainedPrimary } from '../components-atoms/ButtonComponents';
import { GetAvailableServices } from '../service/serviceService';

export const CategoryPageDrawerComponent = styled(Drawer)<DrawerProps>(() => ({
  borderRadius: 8
}));

const BG_COLORS: { [key: string]: string; } = {
  'Training': 'training',
  'Think Forward': 'think_forward',
  'Work Smarter': 'work_smarter',
  'Rewards': 'rewards'
};

export function CategoryPageDrawer(): JSX.Element {
  const { scrollPosition } = useScrollPosition();
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const token = useTypedSelector(state => state.userReducer.token);
  const [drawerItems, setDrawerItems] = useState<DrawerItem[]>([]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (token) {
      GetAvailableServices(
        token,
        (response) => {
          const services = response.Result;
          setDrawerItems(services.map(category => (
            {
              type: 'item',
              route: `/category/${category.Services[0].CategoryId}`,
              primaryText: category.CategoryName,
              customBackgroundColor: `url(/backgrounds/${BG_COLORS[category.CategoryName]}.png)`
            }
          )));
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  }, [token]);

  const items: DrawerItem[] = [
    { type: 'head', primaryText: 'All Categories', header: true },
    ...drawerItems,
  ];

  return (
    <>
      <Drawer
        anchor="left"
        open={mobileOpen}
        variant="temporary"
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', lg: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 'inherit', border: 'none' },
        }}
      >
        <Toolbar sx={{
          height:
            (scrollPosition > (3 + LIGHT_THEME.misc.toolbar.akzonobelHeightNumber))
              ? LIGHT_THEME.misc.toolbar.mainHeight
              : `${3 + LIGHT_THEME.misc.toolbar.akzonobelHeightNumber + LIGHT_THEME.misc.toolbar.mainHeightNumber - scrollPosition}px`
        }} />
        <DrawerList items={items} />
      </Drawer>
      <Drawer
        anchor="left"
        open
        variant="permanent"
        sx={{
          display: { xs: 'none', lg: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 'inherit', border: 'none' },
        }}
      >
        <Toolbar sx={{
          height:
            (scrollPosition > (3 + LIGHT_THEME.misc.toolbar.akzonobelHeightNumber))
              ? LIGHT_THEME.misc.toolbar.mainHeight
              : `${3 + LIGHT_THEME.misc.toolbar.akzonobelHeightNumber + LIGHT_THEME.misc.toolbar.mainHeightNumber - scrollPosition}px`
        }} />
        <DrawerList items={items} />
      </Drawer>
      {!mobileOpen && (
        <ButtonContainedPrimary
          sx={{
            display: { xs: 'flex', lg: 'none' },
            position: 'fixed',
            bottom: '48px',
            right: '40px',
            width: '156px',
            borderRadius: '24px',
            padding: '14px 16px 14px 14px',
            zIndex: 1201
          }}
          onClick={handleDrawerToggle}
        >
          <MenuIcon sx={{ height: '20px', width: '20px', marginRight: '4px' }} />
          All Categories
        </ButtonContainedPrimary>
      )}
    </>
  );
}
