const INITIAL: PageState = {
  title: 'Home'
};

export const pageReducer = (state: PageState = INITIAL, action: PageAction): PageState => {
  switch (action.type) {
    case 'SET_PAGE_TITLE':
      return { ...state, title: action.payload.title };
    default:
      return state;
  }
};
