import { Link as RouterLink } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';

import { BodyTwoSecondary, HeaderFive, HeaderSix } from '../../components-atoms/TypographyComponents';
import imageIcon from '../../assets/icons/Imagefile.svg';

interface SubPageHeaderProps {
  title: string;
  subtitle?: string;
  backRoute?: string;
  backCallback?: () => void;
  logoSrc?: string;
  buttons?: JSX.Element[];
  assetDetailPage?: boolean;
  subPageHeaderStyle?: React.CSSProperties;
  invitationDetailPage?: boolean;
  buttonsNoPaddingRight?: boolean;
  extraMargin?: boolean;
}

export function SubPageHeader({ title, subtitle, logoSrc, backRoute, backCallback, buttons, assetDetailPage, subPageHeaderStyle, invitationDetailPage, buttonsNoPaddingRight, extraMargin }: SubPageHeaderProps): JSX.Element {
  const mobileView = useMediaQuery('(max-width: 480px)');
  
  return (
    <Grid container direction="row" justifyContent="center" alignItems={mobileView ? 'flex-end' : 'center'} sx={{ height: mobileView ? '80px' : '88px', marginTop: mobileView ? 0 : '32px', padding: { xs: '0 16px', md: 0 }, margin: '0 0', ...subPageHeaderStyle}}>
      <Grid item container flexGrow={1} columnGap="16px" alignItems="center" flexWrap="nowrap" sx={{ width: mobileView ? '100%' : 'max-content', marginRight: '12px', whiteSpace: mobileView ? 'unset' : 'nowrap', overflow: 'hidden' }}>
        {backRoute || backCallback ? (
          <Box sx={{ width: '48px', height: '48px', padding: '4px' }}>
            {backRoute
              ? <IconButton component={RouterLink} to={backRoute}>
                <ArrowBackIcon color="primary" />
              </IconButton>
              : null
            }

            {backCallback
              ? <IconButton onClick={backCallback} aria-label="back">
                <ArrowBackIcon color="primary" />
              </IconButton>
              : null
            }
          </Box>
        ) : <></>}

        {logoSrc
          ? <img
            src={logoSrc}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = imageIcon;
            }}
            style={{
              height: '48px',
              width: '48px',
              border: '1px solid rgba(0, 0, 0, 0.08)',
              borderRadius: '4px',
              objectFit: 'contain'
            }}
          />
          : null}

        <Grid container flexWrap="wrap" alignItems="center" style={{ paddingRight: buttonsNoPaddingRight ? '12px' : undefined }}>
          <Grid item flexGrow={1} sx={{ overflow: 'hidden', width: mobileView ? '10ch' : undefined, maxWidth: invitationDetailPage ? '509px' : undefined }}>
            {subtitle ? <BodyTwoSecondary>{subtitle}</BodyTwoSecondary> : null}
            {assetDetailPage ? (
              <HeaderSix style={{ overflow: 'hidden', textOverflow: 'ellipsis', margin: 0, whiteSpace: 'nowrap' }}>{title}</HeaderSix>
            ) : (
              <HeaderFive style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: invitationDetailPage && title.length > 25 ? 'break-spaces' : undefined, display: invitationDetailPage ? title.length > 25 ? 'block' : 'flex' : undefined, marginTop: extraMargin ? '4px' : 0 }}>{title}</HeaderFive>
            )}
            
          </Grid>
          
          {buttons?.map((button, index) =>
            <Grid key={index} item flexGrow={0} sx={{ paddingRight: assetDetailPage ? '8px' : buttonsNoPaddingRight?'0px':'24px' }}>
              {button}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
