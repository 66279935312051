/* eslint-disable @typescript-eslint/naming-convention */
import { Grid, Alert, Box, FormControl, FormControlLabel, Radio, RadioGroup, IconButton, CircularProgress, LinearProgress } from '@mui/material';
import { useCallback, useEffect, useState, Fragment } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormControlInput, FormControlSelect } from '../../components-molecules/FormControl';
import { ModalCardComponent } from '../../components-molecules/Modal';
import RichTextEditor, { EditorValue, ToolbarConfig } from 'react-rte';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { REQUIRED_VALIDATION } from '../../forms/_predefinedValidations';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { ButtonContainedPrimary } from '../../components-atoms/ButtonComponents';
import Add from '@mui/icons-material/Add';
import { InfoComponent } from '../../components-atoms/InfoComponent';
import { MiniCard, MiniDialog } from '../../components-molecules/dialogs/MiniDialog';
import { BodyOnePrimary, BodyOneSecondary, BodyTwoPrimary, BodyTwoSecondary, CaptionSecondary } from '../../components-atoms/TypographyComponents';
import { CombinationRow } from '../../components-molecules/CombinationRow';
import { SectionRow } from '../../components-molecules/section/SectionRow';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { CreateNewsletterRequest, GetAudiencesRequest, GetRolesRequest, NewsletterAttachment, GetMerchantManagers } from '../../service/serviceService';
import { ListMerchantsWithManagersRequest } from '../../service/merchantsService';
import { DataDivider } from '../../utils/arrayDividers';
import { ListDivider } from '../../components-molecules/Divider';
import { IStyles, LIGHT_THEME } from '../../constants/theme';
import SearchIcon from '@mui/icons-material/Search';
import { SortButton } from '../../components-molecules/SortButton';
import CheckFileExtensions from '../../hooks/checkFileExtensions';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { useNavigate } from 'react-router-dom';
import { UploadToAzure } from '../../hooks/uploadToAzure';
import { environment } from '../../environment';
import { SearchbarWithDeboubce } from '../../components-molecules/SearchBarWithDebounce';

const TOOLBAR_CONFIG: ToolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['BLOCK_TYPE_DROPDOWN', 'INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS',],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Paragraph', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'Bullet List', style: 'unordered-list-item' },
    { label: 'Numbered List', style: 'ordered-list-item' }
  ]
};

interface IRecipient {
  audience: string;
  role: string;
}

interface INewsletter {
  body: string;
  subject: string;
  attachments: File[];
}

interface IOption {
  name: string;
  value: string;
}

interface RecipientData extends AccessRight {
  AudienceId: string;
  RoleId: string;
}

interface MerchantManagersGroup{
  Group: string;
  Managers: Manager[]
}

interface Manager {
  Id: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Merchant: string;
}

interface onProgressResponse {
  fileName: string;
  percentage: number;
}

type RecipientGroup = 'group' | 'merchant'| 'merchantManagers';
const EMPTY_HTML = '<p><br></p>';

const SORT_TYPES = [
  {
    title: 'A \u2014 Z',
    value: 'asc'
  },
  {
    title: 'Z \u2014 A',
    value: 'desc'
  }
];

export function SendEmail(): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const [html, setHtml] = useState<EditorValue>(RichTextEditor.createEmptyValue());
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [dialog, setDialog] = useState('');
  const [recipients, setRecipients] = useState<RecipientData[]>([]);
  const [merchants, setMerchants] = useState<MerchantWithManagers[]>([]);
  const [selectedMerchant, setSelectedMerchant] = useState<MerchantWithManagers>();
  const [tempMerchant, setTempMerchant] = useState<MerchantWithManagers>();
  const [attachments, setAttachments] = useState<File[]>([]);
  const [audienceOptions, setAudienceOptions] = useState<IOption[]>([]);
  const [roleOptions, setRoleOptions] = useState<IOption[]>([]);
  const [recipientType, setRecipientType] = useState<RecipientGroup>('group');
  const { control, handleSubmit, formState: { isValid }, setValue } = useForm({ mode: 'all', reValidateMode: 'onChange' });
  const { control: recipientControl, handleSubmit: recipientHandleSubmit, formState: { isValid: recipientIsValid }, reset: resetRecipients } = useForm({ mode: 'all', reValidateMode: 'onChange' });
  const [loading, setLoading] = useState(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [loadingProgress, setLoadingProgress] = useState<onProgressResponse[]>([]);
  const [percentage, setPercentage] = useState(0);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [merchantManagers, setMerchantManagers] = useState<MerchantManagersGroup[]>([]);
  const [selectedMerchantManagers, setSelectedMerchantManagers] = useState<string[]>([]);
  const [merchantManagerSearchQuery, setMerchantManagerSearchQuery] = useState('');
  const [merchantManagerSort, setMerchantManagerSort] = useState('asc');
  const [merchantManagersLoading, setMerchantManagersLoading] = useState(false);
  const [tempMerchantManager, setTempMerchantManager] = useState<string[]>([]);
  const [fileAttachmenErrorMessage, setFileAttachmenErrorMessage] = useState<string|undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    calculatePercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProgress]);
  
  const submit: SubmitHandler<INewsletter> = async (data) => {
    if (token) {
      setLoading(true);
      setScrollToTop(true);
      if (attachments.length > 0) {
        const attachmentFileNames = await UploadToAzure({
          data: attachments,
          onProgress: onProgress,
          folderName: environment.newsletterFolder
        });

        const requestAttachments: NewsletterAttachment[] = [];
        attachmentFileNames.forEach(async(x) => {
          requestAttachments.push({
            FileName: x.Name,
            FileUrl: x.Url,
            Size: x.Size,
            ContentType: x.FileType
          });
        });
    
        CreateNewsletterRequest(
          token,
          {
            Attachments: requestAttachments,
            Body: data.body,
            Subject: data.subject,
            Recipients: recipients.length > 0 ? recipients.map(m => ({ AudienceId: m.AudienceId, UserRoleId: m.RoleId })) : undefined,
            MerchantId: selectedMerchant ? selectedMerchant.Id : undefined,
            ManagerIds: selectedMerchantManagers.length > 0 ? selectedMerchantManagers : undefined
          },
          () => {
            setLoading(false);
            setScrollToTop(false);
            navigate('/admin/newsletters');
          },
          (error) => {
            setErrorMessage(error.response?.data.Error || '');
            setLoading(false);
            setScrollToTop(false);
          }
        );
      } else {
        CreateNewsletterRequest(
          token,
          {
            Body: data.body,
            Subject: data.subject,
            Recipients: recipients.length > 0  ? recipients.map(m => ({ AudienceId: m.AudienceId, UserRoleId: m.RoleId })) : undefined,
            MerchantId: selectedMerchant ? selectedMerchant.Id : undefined,
            ManagerIds: selectedMerchantManagers.length > 0 ? selectedMerchantManagers : undefined
          },
          () => {
            setLoading(false);
            setScrollToTop(false);
            navigate('/admin/newsletters');
          },
          (error) => {
            setErrorMessage(error.response?.data.Error || '');
            setLoading(false);
            setScrollToTop(false);
          }
        );
      }
    }
  };

  const onProgress = (data: onProgressResponse) => {
    const result = loadingProgress?.find(x => data.fileName === x.fileName);
    let tempLoadingProcesses = loadingProgress;

    if (result !== undefined) {
      tempLoadingProcesses= tempLoadingProcesses?.map(x => {
        if (x.fileName === data.fileName) {
          return {...x, percentage: data.percentage};
        } else {
          return x;
        }
      });
    } else {
      tempLoadingProcesses?.push(data);
    }
    setLoadingProgress(tempLoadingProcesses);
  };

  const calculatePercentage = () => {
    let total = 0;
    loadingProgress.forEach(x => {
      total += x.percentage / loadingProgress.length;
    });
    setPercentage(Math.round(total));
  };

  const submitRecipients: SubmitHandler<IRecipient> = (data): void => {
    if (recipients.filter(f => f.AudienceId === data.audience && f.RoleId === data.role).length > 0) {
      return;
    }
    setRecipients([
      ...recipients,
      {
        Id: (recipients.length + 1).toString(),
        AudienceName: audienceOptions.find(f => f.value === data.audience)?.name as AudienceName,
        UserRoleName: roleOptions.find(f => f.value === data.role)?.name as UserRole,
        AudienceId: data.audience,
        RoleId: data.role
      }
    ]);
    setDialog('');
    resetRecipients();
  };

  useEffect(() => {
    setTempMerchant(undefined);
    setSelectedMerchant(undefined);
    setSelectedMerchantManagers([]);
    setRecipients([]);
  }, [recipientType]);
  
  useEffect(() => {
    if (token) {
      getMerchantManagersList();
      GetRolesRequest(
        token,
        (response) => setRoleOptions(response.Result.map(m => ({ name: m.Name, value: m.Id }))),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
      GetAudiencesRequest(
        token,
        (response) => setAudienceOptions(response.Result.map(m => ({ name: m.Name, value: m.Id }))),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
      ListMerchantsWithManagersRequest(
        token,
        (response) => setMerchants(response.Result),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    getMerchantManagersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [merchantManagerSearchQuery, merchantManagerSort]);
  
  const getMerchantManagersList = () => {
    if(token){
      setMerchantManagersLoading(true);
      GetMerchantManagers(
        token,
        {
          OrderBy:merchantManagerSort,
          Query:merchantManagerSearchQuery
        },
        (response) => {
          setMerchantManagers(response.Result);
          setMerchantManagersLoading(false);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  const addButtonText = () => {
    switch (recipientType) {
      case 'group':
        return'Add Recipients';
      case 'merchant':
        return 'Select Merchant';
      case 'merchantManagers':
        return 'Add Managers';
      default:
        break;
    }
  };

  const addRecipientButtonPressed= () => {
    switch (recipientType) {
      case 'group' :
        setDialog('recipient');
        break;
      case 'merchant' :
        setDialog('merchant');
        break;
      case 'merchantManagers' :
        setDialog('merchantManagers');
        setTempMerchantManager(selectedMerchantManagers);
        break;
      default:
        break;
    }
  };

  const getManagerSelected = (id:string) => {
    let isSelected = false;
    tempMerchantManager?.forEach(managerId => {
      if (id === managerId) {
        isSelected = true;
      }
    });
    return isSelected;
  };
  const addToList = useCallback((id:string): void => {
    let temp = tempMerchantManager;
    const result = temp.filter(x => x === id);
    if (result.length ===0 ) {
      temp = [...temp,id];
    } else {
      temp = temp.filter(x => x !== id);
    }
    setTempMerchantManager(temp);
  }, [tempMerchantManager]);

  /* const debouncedSearchTerm = useRef(
    debounce(async (text) => {
      setMerchantManagerSearchQuery(text);
    }, 500)
  ).current; */
  const searchOnChange=(text:string) => {
    setMerchantManagerSearchQuery(text);
  };
  
  const NoRecipientSelected = () => {
    return (
      <InfoComponent
        title="Newsletter Recipients"
        description="You haven’t set any recipients for this email newsletter yet. Start adding recipient groups by defining the first combination of merchant audience and user role. You can also send newsletters to specific merchants or specific merchant managers."
      />
    );
  };

  const getSelectedManagers = () => {
    const managers: Manager[] = []; 
    selectedMerchantManagers.forEach(selected => {
      merchantManagers?.forEach(merchantGroup => {
        merchantGroup.Managers.forEach(manager => {
          if (manager.Id === selected) {
            managers.push(manager);
          }
        });
      });
    });
    return managers;
  };

  const GetRecipientList = () => {
    switch (recipientType) {
      case 'group' :
        if (recipients.length > 0 ) {
          return <GetGroupList />;
        } else {
          return <NoRecipientSelected />; 
        }
      case 'merchant' :
        if (selectedMerchant) {
          return <GetSelectedMerchantList />;
        } else {
          return <NoRecipientSelected />; 
        }
      case 'merchantManagers' :
        if (selectedMerchantManagers.length > 0) {          
          return <GetManagerList />;
        } else {
          return <NoRecipientSelected />; 
        }
      default:
        return <></>;
    }
  };

  const GetGroupList = () => {
    if(recipients.length === 0 ){
      return<></>;
    }
    return <CombinationRow
      columnTitles={{
        AudienceName: 'Audience',
        UserRoleName: 'User Role',
      }}
      dataset={recipients.map(({ Id, AudienceName, UserRoleName }) => ({ Id, AudienceName, UserRoleName }))}
      onDelete={(id: string | number | GUID) => {
        const temp = [...recipients].filter(f => f.Id !== id);
        setRecipients(temp);
      }}
    />;
  };

  const GetSelectedMerchantList= () => {
    if(!selectedMerchant){
      return<></>;
    }
    return (
      <Grid container alignItems="center" justifyContent="space-between" sx={{ width: '100%', marginTop: '12px' }}>
        <Grid item sx={{ width: '28px' }}>
          <img
            style={{ width: '28px', height: '28px' }}
            src={selectedMerchant.Logo}
          />
        </Grid>
        <Grid xs={10} item justifyContent="flex-start" sx={{ paddingLeft: '12px', width: '100%' }}>
          <BodyOnePrimary>{selectedMerchant.Name}</BodyOnePrimary>
          <BodyOneSecondary style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          }}>
            {selectedMerchant.Emails.join(', ')}
          </BodyOneSecondary>
        </Grid>
        <Grid item>
          <RemoveCircleIcon
            color="primary"
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              setSelectedMerchant(undefined);
              setTempMerchant(undefined);
            }} />
        </Grid>
      </Grid>
    );
  };

  const GetManagerList= () => {
    const managers:Manager[] = getSelectedManagers(); 
    return (
      <Fragment>
        {managers.map((manager, rowIndex) =>
          <Fragment key={rowIndex}>
            <Grid container alignItems="center" sx={{ margin: '16px 0' }}>
              <Grid item sx={{ width: '180px', flexGrow: 1, flexShrink: 1 }}>
                <BodyTwoSecondary style={{ marginBottom: '4px' }}>{'Merchant Manager'}</BodyTwoSecondary>
                <BodyOnePrimary>{manager.FirstName + ' ' + manager.LastName}</BodyOnePrimary>
                <CaptionSecondary>{manager.Email}</CaptionSecondary>
              </Grid>
              <Grid item sx={{ width: '180px', flexGrow: 1, flexShrink: 1 }}>
                <BodyTwoSecondary style={{ marginBottom: '4px' }}>{'Merchant'}</BodyTwoSecondary>
                <BodyOnePrimary>{manager.Merchant}</BodyOnePrimary>
              </Grid>
              <Grid item>
                <RemoveCircleIcon color="primary" fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => removeMerchantManagerFromList(manager.Id)} />
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Fragment>
    );
  };

  const removeMerchantManagerFromList=(id:string)=>{
    const list = selectedMerchantManagers.filter(x => x!==id);
    setSelectedMerchantManagers(list);
  };

  return (
    <>
      <FormPageComponent
        hasError={!!errorMessage}
        title="New Email Newsletter"
        buttonTitle="Send Email"
        buttonDisabled={!isValid || !(recipients.length > 0 || !!selectedMerchant || selectedMerchantManagers.length > 0) || loading }
        buttonLoading={loading}
        close={() => navigate('/admin/newsletters')}
        submit={handleSubmit(submit)}
        scrollToTop={scrollToTop}
      >
        {loading
          ? <LinearProgress variant="determinate" color="secondary" value={percentage}  sx={{ zIndex: 2 }} />
          : <></>
        }
        <Grid item sx={{ flexGrow: 1 }}>
          {!!errorMessage
            && <Alert severity="error">{errorMessage}</Alert>
          }
        </Grid>
        <ModalCardComponent>
          <SectionHeader title="Newsletter details" />
          <Grid item container rowSpacing={2} sx={{ flexGrow: 1 }}>
            <Grid item xs={12}>
              <FormControlInput
                data-testid="send-email-subject"
                control={control}
                field={{
                  label: 'Subject',
                  name: 'subject',
                  inputType: 'text',
                  validation: {
                    maxLength: {
                      value: 50,
                      message: 'Subject should be up to 50 characters.',
                    },
                    required: REQUIRED_VALIDATION,
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <RichTextEditor
                {...control.register('body', { required: REQUIRED_VALIDATION, validate: val => val !== EMPTY_HTML })}
                value={html}
                toolbarConfig={TOOLBAR_CONFIG}
                placeholder="Body"
                onChange={(val: EditorValue) => {
                  setHtml(val);
                  setValue('body', val.toString('html'), { shouldDirty: true, shouldValidate: true });
                }}
              />
            </Grid>
          </Grid>
        </ModalCardComponent>
        <ModalCardComponent>
          <SectionHeader
            title="File attachments"
            subtitle="Select files you would like to attach to this newsletter. Maximum file size 5MB."
            errorMessage={fileAttachmenErrorMessage}
            button={
              <label htmlFor="inputid">
                <input
                  data-testid="attachments-upload"
                  id="inputid"
                  accept=".pdf, .pptx, .ppt"
                  type="file"
                  multiple
                  onClick={event => {
                    event.currentTarget.files = null;
                    event.currentTarget.value = '';
                  }}
                  style={{ display: 'none' }}
                  {...control.register('attachments', { required: { value: false, message: '' } })}
                  onChange={(e) => {
                    setFileAttachmenErrorMessage(undefined);
                    let fileArray: File[] = Array.prototype.slice.call(e.target.files);
                    const response = CheckFileExtensions(fileArray,'.pdf, .pptx, .ppt');
                    if(response.errorMessage!==null){
                      setFileAttachmenErrorMessage(response.errorMessage);
                    }
                    fileArray = response.allowedFiles.filter(newFile=>{
                      let fileNotExist = true;
                      attachments.forEach(file=>{
                        if(newFile.size === file.size && file.name === newFile.name){
                          fileNotExist = false;
                        }
                      });
                      return fileNotExist;
                    });
                    setAttachments([...attachments, ...fileArray]);
                    setValue('attachments', [...attachments, ...fileArray], { shouldDirty: true, shouldValidate: true });
                  }}
                />
                <ButtonContainedPrimary
                  data-testid="add-attachment"
                  startIcon={<Add />}
                  sx={{ margin: '0 0 0 8px', width: 'max-content', pointerEvents: 'none' }}
                >
                  Add Attachment
                </ButtonContainedPrimary>
              </label>
            } />
          <Grid xs={12} item container sx={{ flexGrow: 1, width: '100%' }}>
            {attachments.length > 0
              ? <>
                {attachments.map((file: File, idx) => (
                  <>
                    <SectionRow
                      title={`File ${idx + 1}`}
                      lines={[
                        { data: file.name, type: 'body1' }
                      ]}
                      endIcon={<RemoveCircleIcon color="primary" fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => {
                        const temp = [...attachments].filter(f => attachments.indexOf(f) !== idx);
                        setAttachments(temp);
                        setValue('attachments', temp, { shouldDirty: true, shouldValidate: true });
                      }} />}
                    />
                  </>
                ))}
              </>
              : <InfoComponent
                title="File attachments"
                description="You haven’t set any file attachments yet. Start by selecting files in a dialog. You can add multiple files at once"
              />
            }
          </Grid>
        </ModalCardComponent>
        <ModalCardComponent>
          <SectionHeader
            title="Recipient groups"
          />
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={recipientType}
              row
              onChange={e => setRecipientType(e.target.value as RecipientGroup)}
            >
              <FormControlLabel value="group" control={<Radio />} label="Recipient Group" />
              <FormControlLabel value="merchant" control={<Radio />} label="Selected Merchant" />
              <FormControlLabel value="merchantManagers" control={<Radio />} label="Selected Merchant Managers" />
            </RadioGroup>
          </FormControl>
          {recipientType === 'merchant' && selectedMerchant
            ? null
            : <Grid
              sx={{ padding: '24px 0' }}
              item
              container
              direction="row-reverse"
            >
              <ButtonContainedPrimary 
                onClick={addRecipientButtonPressed} 
                startIcon={recipientType === 'group'|| 'merchantManagers' && <Add />}> 
                {addButtonText()}
              </ButtonContainedPrimary>
            </Grid>
          }
          <Grid item container sx={{ flexGrow: 1 }}>
            <GetRecipientList />
          </Grid>
        </ModalCardComponent>
        {loading ? (
          <div style={styles.overlay}>
          </div>
        ) : (
          <></>
        )}
      </FormPageComponent>
      <MiniDialog
        title="Add Recipients"
        open={dialog === 'recipient'}
        disabled={!recipientIsValid}
        close={() => {
          setDialog('');
        }}
        submit={recipientHandleSubmit(submitRecipients)}
      >
        <Box sx={{ padding: '0 24px' }}>
          <BodyOneSecondary>
            Set a combination of Merchant Audience and User role to define recipient group.
          </BodyOneSecondary>
          <FormControlSelect
            control={recipientControl}
            field={{
              label: 'Select merchant audience',
              name: 'audience',
              inputType: 'select',
              options: audienceOptions,
              validation: {
                required: REQUIRED_VALIDATION
              }
            }} />
          <FormControlSelect
            control={recipientControl}
            field={{
              label: 'Select user role',
              name: 'role',
              inputType: 'select',
              options: roleOptions,
              validation: {
                required: REQUIRED_VALIDATION
              }
            }} />
        </Box>
      </MiniDialog>
      <MiniDialog
        title="Select Merchant"
        disabled={!tempMerchant}
        open={dialog === 'merchant'}
        close={() => {
          setDialog('');
        }}
        submit={() => {
          setSelectedMerchant(tempMerchant);
          setDialog('');
        }}
      >
        {Object.keys({ ...DataDivider(merchants) }).sort().map((section, sectionIndex) => {
          return (
            <ListDivider
              key={sectionIndex}
              division={{ name: section, length: { ...DataDivider(merchants) }[section].length }}
              caption={{ singular: 'Merchant', plural: 'Merchants' }}
              halfMargin
            >
              {merchants.length > 0
                ? { ...DataDivider(merchants) }[section].map((item, itemIndex) => (
                  <MiniCard
                    key={itemIndex}
                    onClick={() => setTempMerchant(item)}
                    title={item.Name}
                    subtitle={item.Emails.join(', ')}
                    subtitleOverflow
                    selected={tempMerchant?.Id === item.Id}
                    multiple={false}
                  />
                ))
                : null
              }
            </ListDivider>
          );
        })}
      </MiniDialog>
      <MiniDialog
        title="Select Merchant Managers"
        disabled={tempMerchantManager.length === 0}
        open={dialog === 'merchantManagers'}
        close={() => setDialog('')}
        submit={() => {
          setSelectedMerchantManagers(tempMerchantManager);
          setTempMerchantManager([]);
          setDialog('');
        }}
      >
        <>
          <div style={{
            flexDirection:'column',
          }}>
            <div style={{
              display: 'flex',
              justifyContent:'flex-end',
              padding:' 8px 24px',
            }}>
              <IconButton aria-label="search" sx={styles.sortFilterButton} onClick={()=>setShowSearchInput(!showSearchInput)}>
                <SearchIcon color={ 'primary' } />
              </IconButton>
              <SortButton
                menuItems={
                  SORT_TYPES.map((sortItem: { title: string; value: string; }) => ({
                    title: sortItem.title,
                    value: sortItem.value,
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    handleSelect: () => { setMerchantManagerSort(sortItem.value); },
                  }))}
                selectedSortType={merchantManagerSort}
              />
            </div>
            {showSearchInput && (
              <div style={{ padding:' 0px 24px' }}>
                <SearchbarWithDeboubce
                  isOpen={true}
                  query={merchantManagerSearchQuery}
                  placeholder="Search Merchant Managers"
                  onCancel={() => { setShowSearchInput(false); setMerchantManagerSearchQuery(''); }}
                  onChange={searchOnChange}
                />
              </div> 
            )}
          </div>
          {merchantManagersLoading ? (
            <Grid container item justifyContent="center" alignItems="center">
              <CircularProgress
                color="primary"
                size={50}
                style={{ zIndex: 999, margin: '56px 0' }}
              />
            </Grid>
          ) : merchantManagers.length > 0
            ? merchantManagers.map((managerGroup,index) => {
              return (
                <>
                  <ListDivider
                    key={index}
                    division={{ name: managerGroup.Group, length: managerGroup.Managers.length }}
                    caption={{ singular: 'Manager', plural: 'Managers' }}
                    halfMargin
                  >
                    {managerGroup.Managers.map((manager,managerInx) =>
                      <MiniCard
                        key={managerInx}
                        onClick={() =>{addToList(manager.Id);}}
                        title={`${manager.FirstName} ${manager.LastName}`}
                        subtitle={manager.Merchant}
                        secondSubtitle={manager.Email}
                        selected={getManagerSelected(manager.Id)}
                        multiple={true}/>
                    )}
                  </ListDivider>
                </>
              );
            }) : <BodyTwoPrimary sx={{ padding: '16px 24px 0' }}>0 results found.</BodyTwoPrimary>
          }
        </>
      </MiniDialog>
    </>
  );
}

const styles : IStyles = {
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  },
  sortFilterButton: {
    margin: '0 8px 0 0', 
    height: '48px',
    width: '48px', 
    '&:hover': { 
      backgroundColor: LIGHT_THEME.palette.action.selected 
    }
  }
};

