/* eslint-disable @typescript-eslint/naming-convention */

import { Section } from '../../components-molecules/section/Section';
import {
  BodyTwoSecondary,
  CaptionPrimary,
  HeaderSix,
} from '../../components-atoms/TypographyComponents';
import { Grid, IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LIGHT_THEME } from '../../constants/theme';
import '../../scss/components/_industry-insight.scss';
import { useState } from 'react';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import imageIcon from '../../assets/icons/Imagefile.svg';

interface IProps {
  product: FocusedContent;
  onDelete: (id: string) => void;
  moveUp: (ids: number) => void;
  moveDown: (idx: number) => void;
  index: number;
  count: number;
  showControls?: boolean;
}
export function FocusedContentListCard({
  product,
  index,
  onDelete,
  moveUp,
  moveDown,
  count,
  showControls,
}: IProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const [imgError, setImgError] = useState(false);

  function renderActionButton() {
    if (showControls) {
      return (
        <Grid item container xs={4} justifyContent='flex-end'>
          <IconButton
            sx={{
              margin: '0 -18px 0 0',
              padding: 0,
              height: '48px',
              width: '48px',
            }}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </Grid>
      );
    }
  }

  function sortButtons() {
    if (!showControls) {
      return (
        <Grid
          xs={8}
          container
          item
          direction='column'
          justifyContent='flex-end'
          alignItems='flex-end'
          sx={{ width: '100%' }}
        ></Grid>
      );
    }

    return (
      <Grid
        xs={8}
        container
        item
        direction='column'
        justifyContent='flex-end'
        alignItems='flex-end'
        sx={{ width: '100%' }}
      >
        <IconButton
          data-testid='focused-content-move-up-button'
          onClick={() => moveUp(index)}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
          disabled={index === 0}
        >
          <KeyboardArrowUpIcon color={index === 0 ? 'disabled' : 'primary'} />
        </IconButton>
        <IconButton
          data-testid='focused-content-move-down-button'
          onClick={() => moveDown(index)}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
          disabled={index === count - 1}
        >
          <KeyboardArrowDownIcon
            color={index === count - 1 ? 'disabled' : 'primary'}
          />
        </IconButton>
      </Grid>
    );
  }

  return (
    <Section fullPadding>
      <Grid
        container
        item
        direction='row'
        columnSpacing={3}
        sx={{ width: '100%' }}
      >
        <Grid container justifyContent='center' item xs={2}>
          <Grid
            container
            justifyContent='center'
            item
            sx={{
              aspectRatio: '1/1',
              height: '96px',
              width: '96px',
              border: '1px solid rgba(0, 0, 0, 0.08)',
              borderRadius: '4px',
              objectFit: 'contain',
            }}
          >
            <img
              style={{ maxHeight: '100%', maxWidth: '100%' }}
              src={
                imgError ? imageIcon : product.ThumbnailUrl || product.FileUrl
              }
              onError={() => setImgError(true)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={7} direction='column'>
          <BodyTwoSecondary>
            {' '}
            {product.Published ? `Content ${index + 1}` : 'Unlisted'}
          </BodyTwoSecondary>
          <HeaderSix sx={{ marginBottom: 0 }}>
            {product.DigitalAssetName}
          </HeaderSix>
        </Grid>
        <Grid xs={1} container item justifyContent='center' alignItems='center'>
          <CaptionPrimary
            fontWeight={700}
            sx={{
              color: product.Published
                ? LIGHT_THEME.palette.success.main
                : LIGHT_THEME.palette.error.main,
            }}
          >
            {product.Published ? 'Published' : 'Unpublished'}
          </CaptionPrimary>
        </Grid>
        <Grid
          xs={2}
          container
          item
          justifyContent='center'
          alignItems='center'
          direction='row'
        >
          {sortButtons()}
          {renderActionButton()}
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ '.MuiPaper-root': { width: '160px', marginTop: '8px' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            onDelete(product.DigitalAssetId);
            handleClose();
          }}
        >
          <ListItemText sx={{ color: 'error.main' }}>Remove</ListItemText>
        </MenuItem>
      </Menu>
    </Section>
  );
}
