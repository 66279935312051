/* eslint-disable @typescript-eslint/naming-convention */
import { Divider, Grid } from '@mui/material';
import { BodyOnePrimary, HeaderSix } from '../components-atoms/TypographyComponents';
import { ExternalRedirectButton } from '../components-molecules/ExternalRedirectButton';
import { IStyles } from '../constants/theme';

export function VideoTrainingService(): JSX.Element {
  return (
    <Grid container item justifyContent="flex-start" sx={{ maxWidth: '1360px', width: '100%', padding: '0 24px', margin: '32px 0px' }}>
      <Grid item sm={3}>
        <Grid
          container
          justifyContent="center"
          item
          style={styles.imagecontainer}>
          <img
            style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'cover', borderRadius: '4px' }}
            src={`${require('../assets/img/video-training/video.png')}`}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={9} sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
        <Grid container sx={{ maxWidth: { sm: '672px' }, width: '100%', height: '100%' }} justifyContent="flex-start" alignItems="center">
          <HeaderSix>Dulux Trade UK YouTube Channel</HeaderSix>
          <BodyOnePrimary style={{ marginBottom: '16px' }}>Access our YouTube channel for information about our range of products, colors, interior design and how to guides.</BodyOnePrimary>
          <ExternalRedirectButton style={{ marginBottom: '44px' }} url={'https://www.youtube.com/user/duluxtradeuk'}>Visit Channel</ExternalRedirectButton>
          <Divider style={{ width: '100%' }} />
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles: IStyles = {
  imageContainer: {
    aspectRatio: '1/1',
    maxHeight: '320px',
    maxWidth: '320px',
    width: '100%',
    objectFit: 'contain',
    marginLeft: 'auto',
    borderRadius: '4px'
  }
};
