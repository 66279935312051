import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate as ReduxPersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-data-grid-pro';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LIGHT_THEME } from './constants/theme';

import { STORE, PERSISTING_STORE } from './redux/store';
import reportWebVitals from './reportWebVitals';

import './index.css';

import './api';

import { App } from './App';

// TODO: Move this into env variables
LicenseInfo.setLicenseKey('0c842060571c886bef9948109c76e61cT1JERVI6MzIxMjEsRVhQSVJZPTE2Njc3NTAyMjIwMDAsS0VZVkVSU0lPTj0x');

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={LIGHT_THEME}>
      <CssBaseline />
      <ReduxProvider store={STORE}>
        <ReduxPersistGate loading={null} persistor={PERSISTING_STORE}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ReduxPersistGate>
      </ReduxProvider>
    </ThemeProvider >
  </StrictMode >,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
