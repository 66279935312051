/* eslint-disable @typescript-eslint/naming-convention */
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SubPageHeader } from '../components-molecules/titlebars/SubPageHeader';
import { Dashboard } from './Dashboard';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonContainedPrimary, ButtonTextError, ButtonTextPrimary } from '../components-atoms/ButtonComponents';
import { GetInvitationDetail, GetPendingInvitationCount, InvitationDetailResponse, InvitationMarkAsComplete, InvitationSubmitReject } from '../service/serviceService';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { Box, CircularProgress, Divider, Grid } from '@mui/material';
import { BodyOneSecondary } from '../components-atoms/TypographyComponents';
import { IStyles } from '../constants/theme';
import { Section } from '../components-molecules/section/Section';
import { SectionHeader } from '../components-molecules/section/SectionHeader';
import { SectionRow } from '../components-molecules/section/SectionRow';
import { MiniDialog } from '../components-molecules/dialogs/MiniDialog';
import { FormControlInput } from '../components-molecules/FormControl';
import { useForm } from 'react-hook-form';
import { REQUIRED_VALIDATION, validateWhiteSpace } from '../forms/_predefinedValidations';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import moment from 'moment';

export default function InvitationDetail():JSX.Element {
  const { id } = useParams();
  const dispatch = useTypedDispatch();
  const token = useTypedSelector(state => state.userReducer.token);
  const [data, setData] = useState<InvitationDetailResponse|null>(null);
  const [loading, setLoading] = useState(true);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const { control ,getValues, formState: { isValid }, reset} = useForm({ mode: 'all', reValidateMode: 'onChange' });
  const [rejectSendedModalOpen, setRejectSendedModalOpen] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [rejectedDateTime, setRejectedDateTime] = useState<string|null>(null);

  useEffect(() => {
    getInvitationDetail();
    
    return ()=>{
      dispatch({ type: 'SET_TAB', payload: { tab: null } });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  const getInvitationDetail=()=>{
    if(token && id){
      setLoading(true);
      GetInvitationDetail(token,id,(response)=>{
        setData(response.Result);
        setLoading(false);
        
      },
      () => {
        setLoading(false);
      });
    }
    
  };

  const rejectOnClick = () => {
    setRejectModalOpen(true);
  };

  const completeOnClick = () => {
    if(token && id){
      InvitationMarkAsComplete(
        token,
        id,
        () => {
          getInvitationDetail();
          getPendingCount();
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  const rejectSubmitClicked = () => {
    setRejectLoading(true);
    const internalNotes = getValues('internalNotes');
    const rejectionReason = getValues('rejectionReason');
    if (token && id) {
      InvitationSubmitReject(
        token,
        {
          Id:id,
          InternalNotes:internalNotes,
          Reason:rejectionReason
        },
        (res) => {
          setRejectedDateTime(res.Result.Rejected);
          getInvitationDetail();
          setRejectModalOpen(false);
          setRejectLoading(false);
          setRejectSendedModalOpen(true);
          getPendingCount();
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {setRejectLoading(false);}
      );
    }
    
  };

  const getInvitationPending = () => {
    if (data !== null) {
      return data.Completed === null && data.Rejected === null ? true :false; 
    }
    return false;
  };

  const rightButtons = [
    <ButtonTextError key={0} startIcon={<CloseIcon />} onClick={rejectOnClick}>Reject</ButtonTextError>,
    <ButtonContainedPrimary
      key={1}
      onClick={completeOnClick}
      sx={{ margin: '0 0 0 8px', padding: '8px 11px !important', width: 'max-content', height:'36px' }}
    >
      Mark as Complete
    </ButtonContainedPrimary>
  ];

  const closeClicked = () => {
    if(rejectLoading) return;

    setRejectModalOpen(false);
    reset();
  };

  const rejectSendCloseClicked=()=>{
    setRejectSendedModalOpen(false);
    setRejectedDateTime(null);
  };

  const getPendingCount=()=>{
    if(token){
      GetPendingInvitationCount(
        token,
        (res) => {
          dispatch({ type: 'DRAWER_SET_PENDING_INVITATION_COUNT', payload: { invitationCount: res.Result.Count } });
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  const openMerchants=()=>{
    window.open(window.location.origin+'/admin/merchants', '_blank', 'noopener,noreferrer');
  };

  const getStoreCity=()=>{
    if(data?.StoreCity !== null && data?.StoreCity !== '' && data?.StoreCounty !== null && data?.StoreCounty !== ''){
      return data?.StoreCity + ', ' + data?.StoreCounty;
    }
    return '/';
  };

  const getFormatRejectedDateTime=()=>{
    return rejectedDateTime? moment(new Date(rejectedDateTime)).format('dddd, MMM DD, yyyy') :'';
  };

  return (
    <Dashboard>
      {
        loading
          ? <Grid container item width={'100%'} justifyContent="center" alignItems="center">
            <CircularProgress
              color="primary"
              size={50}
              style={{ zIndex: 999, margin: '56px 0' }}
            />
          </Grid>
          :<Fragment>
            <SubPageHeader
              title={data ? data?.MerchantName : ''}
              subtitle="Merchant Registrations"
              backRoute="/admin/merchantregistrations"
              buttons={getInvitationPending() ? rightButtons: []}
              invitationDetailPage
              buttonsNoPaddingRight
              extraMargin
            />

            {getInvitationPending()
            && <div style={{marginTop:'24px'}}>
              <BodyOneSecondary style={{marginBottom:'24px'}}>
                Review the submitted data to decide whether an organisation already exists or if it can be added to the platform.
              </BodyOneSecondary>
              <BodyOneSecondary style={{marginBottom:'24px'}}>
                If the reviewed registration is valid add a new merchant organisation to the platform or add a new user to an existing organisation. After the organisation has been created/user added return to this screen and mark the application as complete.
              </BodyOneSecondary>
              <BodyOneSecondary>
                In case the registration doesn’t meet the standards you can reject the organisation and state the rejection reason which will be shared with the customer.   
              </BodyOneSecondary>
              <div style={styles.openMerchantButtonContainer}>
                <ButtonTextPrimary endIcon={<ArrowForwardIcon/>} onClick={openMerchants}>Open Merchants</ButtonTextPrimary>
              </div>

            </div>}
            
            {
              data?.Completed &&<Section>
                <SectionHeader title="Invitation request complete"  />
                <SectionRow title="Date" lines={[{ data: data ? moment(new Date(data.Completed)).format('dddd, MMM DD, yyyy')  : '', type: 'body1' }]} />
                <Box><Divider /></Box>
                <SectionRow title="Completed By" lines={[{ data: data.ProcessedByName ? data.ProcessedByName : '', type: 'body1' }]} />
              </Section>
            }
            {
              data?.Rejected 
                && <Section>
                  <SectionHeader title="Rejection reason"  />

                  <SectionRow title="Sent to" lines={[
                    { data: data ? data?.FirstName + ' ' + data.LastName  : '', type: 'body1' },
                    { data: data ? data?.Email : '', type: 'body2', color:'text.secondary'}
                  ]} />

                  <Box><Divider /></Box>
                  <SectionRow title="Date" lines={[{ data: data ? data?.Rejected !== '' ? moment(new Date(data.Rejected)).format('dddd, MMM DD, yyyy'): '/' : '', type: 'body1' }]} />
                  <Box><Divider /></Box>
                
                  <SectionRow title="Rejected By" lines={[{ data: data.ProcessedByName ? data.ProcessedByName : '', type: 'body1' }]} />
                  <Box><Divider /></Box>

                  <SectionRow title="Reason" lines={[{ data: data.RejectionReason ? data.RejectionReason : '', type: 'body1' }]} />
                  <Box><Divider /></Box>

                  <SectionRow title="Internal notes" lines={[{ data: data && data.RejectionInternalNotes ? data?.RejectionInternalNotes !== '' ? data.RejectionInternalNotes : '/': '', type: 'body1' }]} />

                </Section>
            }
            
            <Section>
              <SectionHeader title="Merchant information"  />

              <SectionRow title="Name" lines={[{ data: data ? data?.MerchantName  : '', type: 'body1' }]} />

              <Box><Divider /></Box>
              <SectionRow title="Parent / Payer Code" lines={[{ data: data ? data?.MerchantSAPCode !== '' ? data?.MerchantSAPCode : '/' : '', type: 'body1' }]} />
            </Section>
            <Section>
              <SectionHeader title="Registration submitted by"  />

              <SectionRow title="Name" lines={[{ data: data ? data?.FirstName + ' ' + data.LastName  : '', type: 'body1' }]} />

              <Box><Divider /></Box>

              <SectionRow title="Contact" lines={[
                { data: data ? data?.Email : '', type: 'body1' },
                { data: data ? data?.Phone : '', type: 'body2', color:'text.secondary' }
              ]} />

              <Box><Divider /></Box>

              <SectionRow title="Role" lines={[
                { data: data ? data?.RoleName : '', type: 'body1' },
              ]} />
            </Section>
            <Section applyMarginBottom>
              <SectionHeader title="Store information"  />

              <SectionRow title="Name" lines={[{ data: data ? data?.StoreName !== '' ? data?.StoreName : '/': '', type: 'body1' }]} />

              <Box><Divider /></Box>

              <SectionRow title="Post Code" lines={[
                { data: data ? data?.StorePostCode : '', type: 'body1' },
                { data: data ? getStoreCity() : '', type: 'body2', color:'text.secondary' }
              ]} />

              <Box><Divider /></Box>

              <SectionRow title="Territory Manager" lines={[
                { data: data ? data?.StoreTerritoryManager !== '' ? data?.StoreTerritoryManager : '/' : '', type: 'body1' },
              ]} />
            </Section>
          </Fragment>
      }
      <MiniDialog
        open={rejectModalOpen}
        close={closeClicked}
        title='Reject organisation application'
        
        submit={rejectSubmitClicked}
        disabled={!isValid || rejectLoading}
      >
        <Box sx={{ padding: '0 24px' }}>
          <BodyOneSecondary sx={{ marginBottom: '16px' }}>
            Merchant organisation <b>{data?.MerchantName}</b> cannot be added to the platform. Rejection email will be sent to <b>{data?.Email}</b>.
          </BodyOneSecondary>
          <BodyOneSecondary sx={{ marginBottom: '16px' }}>
            Write a rejection reason which will be shared with the customer. You can also add internal notes.
          </BodyOneSecondary>
          <FormControlInput
            data-testid="rejection-reason"
            control={control}
            disabled={rejectLoading}
            multiline
            field={{
              label: 'Rejection reason*',
              name: 'rejectionReason',
              inputType: 'text',
              validation: {
                required: REQUIRED_VALIDATION,
                validate: { validateWhiteSpace }
              }
            }} />
          <FormControlInput
            data-testid="internal-notes"
            control={control}
            disabled={rejectLoading}
            dontShowOptionalText
            field={{
              label: 'Internal notes',
              name: 'internalNotes',
              inputType: 'text',
              validation: {
              }
            }} />
        </Box>
      </MiniDialog>
      <MiniDialog
        open={rejectSendedModalOpen}
        close={rejectSendCloseClicked}
        title='Rejection sent'
        noCancel
      >
        <Box sx={{ padding: '0 24px',height: '196px' }}>
          <BodyOneSecondary sx={{ marginBottom: '16px' }}>
            Rejection email for the merchant organisation <b>{data?.MerchantName}</b> has been sent to <b>{data?.Email}</b> on {getFormatRejectedDateTime()}.
          </BodyOneSecondary>
        </Box>
      </MiniDialog>
    </Dashboard>
  );
}
const styles: IStyles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '840px',
    width: '100%',
    margin: '0 auto',
    padding: '40px 16px 0'
  },
  openMerchantButtonContainer: {
    marginTop: '32px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
};
