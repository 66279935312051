export type SortType = {
  title: string;
  value: string;
}

export const AZ_SORT = [
  {
    title: 'A \u2014 Z',
    value: 'asc'
  },
  {
    title: 'Z \u2014 A',
    value: 'desc'
  }
];
