import { useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, SubmitHandler, useWatch } from 'react-hook-form';

import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { ResetFormInputTypes, RESET_FORM } from '../forms/reset';
import { useTypedDispatch } from '../hooks/TypedReduxHooks';
import { PasswordResetResponseModel, PasswordResetUserTokenRequest, ValidateTokenRequest } from '../service/userService';

import { FormControlInput } from '../components-molecules/FormControl';
import { FormCard } from '../components-molecules/cards/FormCard';
import { TextListComponent } from '../components-atoms/ListComponents';
import { BodyOnePrimary, BodyTwoSecondary, CaptionSecondary, HeaderSix } from '../components-atoms/TypographyComponents';
import { ButtonContainedPrimary, ButtonRouteContainedPrimary, ButtonRouteTextPrimary } from '../components-atoms/ButtonComponents';
import { ProfileSetup } from './ProfileSetup';
import { CircularProgress } from '@mui/material';
import { CenteredDialogComponent } from '../components-molecules/dialogs/CenteredDialog';
import { Box, Checkbox } from '@mui/material';
import { LIGHT_THEME } from '../constants/theme';
import { GetMarketingOption } from '../service/serviceService';
import { NotFound } from './NotFound';

export function ResetSuccess(): JSX.Element {
  return (
    <FormCard
      rightButton={<ButtonRouteContainedPrimary fullWidth sx={{ my: 1 }} to="/signin" >Back to Sign In</ButtonRouteContainedPrimary>}
    >
      <Grid item sx={{ flexGrow: 1 }}>
        <HeaderSix>Set your password</HeaderSix>
        <BodyTwoSecondary sx={{ margin: '0 0 12px' }}>Your password has been successfully set.</BodyTwoSecondary>
        <BodyTwoSecondary sx={{ margin: '0 0 8px' }}>You can sign in now with your new password.</BodyTwoSecondary>
      </Grid>
    </FormCard>
  );
}

export function Reset(): JSX.Element {
  const { clearErrors, control, setError, formState: { isValid }, handleSubmit } = useForm({ mode: 'all', reValidateMode: 'onBlur' });
  const dispatch = useTypedDispatch();
  const { guid } = useParams();
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const [passwordResetResponse, setPasswordResetResponse] = useState<PasswordResetResponseModel>({ Id: '' as GUID, IsSetUp: false });
  const [optInMarketing, setOptInMarketing] = useState(false);
  const [showMarketing, setShowMarketing] = useState<boolean>();
  const [loading, setLoading] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);

  const password = useWatch({
    control,
    name: 'password', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: '' // default value before the render
  });


  const passwordRepeat = useWatch({
    control,
    name: 'passwordRepeat', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: '' // default value before the render
  });


  const reset: SubmitHandler<ResetFormInputTypes> = (data): void => {
    if (guid) {
      setLoadingButton(true);
      PasswordResetUserTokenRequest(
        { Token: guid, Password: data.password, Marketing: showMarketing ? optInMarketing : undefined },
        (response) => {
          if (response.Result) {
            setPasswordResetResponse(response.Result);
          }
          setRequestSuccess(true);
          setLoadingButton(false);
          dispatch({ type: 'USER_SIGNOUT' });
        },
        (error) => {
          if (error.response) {
            setErrorMessage(error.response.data.Error);
            setLoadingButton(false);
            dispatch({ type: 'USER_ANY_FAILURE', payload: { error: error.response.data.Error } });
          }
        }
      );
    }
  };

  useLayoutEffect(() => {
    if (guid) {
      ValidateTokenRequest(
        guid,
        (response) => {
          setIsTokenValid(response.Result);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
      GetMarketingOption(
        guid,
        (response) => {
          setShowMarketing(response.Result);
          setLoading(false);
        },
        () => setLoading(false)
      );
    }
  }, [guid]);

  useEffect(() => {
    if (password !== passwordRepeat) {
      setError('passwordRepeat', { message: 'Password do not match.', type: 'manual' });
    } else {
      clearErrors('passwordRepeat');
    }
  }, [password, clearErrors, passwordRepeat, setError]);

  return (
    loading
      ? <Grid container item justifyContent="center" alignItems="center" sx={{ width: '100vw', height: '100vh' }}>
        <CircularProgress
          color="primary"
          size={50}
          style={{ zIndex: 999 }}
        />
      </Grid>
      : !isTokenValid
        ? <NotFound noAppBar noDrawer expiredLink/>
        : requestSuccess
          ? !passwordResetResponse.IsSetUp
            ? <ProfileSetup userId={passwordResetResponse.Id} isSetUp={passwordResetResponse.IsSetUp} />
            : <ResetSuccess />
          : showMarketing !== undefined
            ? <CenteredDialogComponent>
              <FormCard
                leftButton={<ButtonRouteTextPrimary fullWidth sx={{ my: 1 }} to="/signin">Back to Sign In</ButtonRouteTextPrimary>}
                rightButton={
                  <ButtonContainedPrimary type="submit" fullWidth sx={{ my: 1 }} disabled={!isValid}>
                    {loadingButton ? (
                      <CircularProgress
                        color='inherit'
                        size={18}
                        sx={{ marginRight: 2 }}
                      />
                    ) : (
                      <></>
                    )}
                  Set Password
                  </ButtonContainedPrimary>
                }
                submit={handleSubmit(reset)}
              >
                {errorMessage ? <Grid item sx={{ flexGrow: 1, paddingBottom: '24px' }}> <Alert color='error' icon={<ErrorOutlineIcon />}>{errorMessage}</Alert></Grid> : null}
                <Grid item sx={{ flexGrow: 1 }}>
                  <HeaderSix>Set your password</HeaderSix>
                  <BodyTwoSecondary sx={{ margin: '0 0 8px' }}>Choose a secure password:</BodyTwoSecondary>
                  <TextListComponent
                    lines={[
                      'With min. 8 to max. 15 characters',
                      'Containing at least 1 uppercase letter and 1 lowercase letter',
                      'Containing at least 1 number'
                    ]}
                  />
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>
                  <FormControlInput control={control} field={RESET_FORM()[0]} showPassword={showPassword} setShowPassword={setShowPassword} />
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>
                  <FormControlInput control={control} field={RESET_FORM(password)[1]} showPassword={showRepeatPassword} setShowPassword={setShowRepeatPassword} />
                </Grid>
                {showMarketing
                  ? <Box sx={{ width: '450px', maxWidth: '100%', padding: '24px 0px' }}>
                    <Grid
                      container
                      flexWrap="nowrap"
                      alignItems="center"
                      onClick={() => setOptInMarketing(prev => !prev)}
                      sx={{
                        cursor: 'pointer',
                        margin: '0 0 8px',
                        padding: '8px 0px',
                        borderRadius: '8px',
                        width: 'unset',
                        background: optInMarketing ? LIGHT_THEME.palette.action.selected : 'none',
                        ':hover': { background: LIGHT_THEME.palette.action.hover }
                      }}
                    >
                      <Grid item sx={{ height: '42px', width: { xs: '32px', sm:'42px' } }}>
                        <Checkbox checked={optInMarketing} sx={{ padding: '0', margin: '9px 0' }} />
                      </Grid>
                      <Grid item>
                        <BodyOnePrimary>
                          I would like to receive marketing emails.
                        </BodyOnePrimary>
                      </Grid>
                    </Grid>
                    <CaptionSecondary style={{ display: 'block' }}>
                      By checking the checkbox you confirm you want to receive news, special offers and promotions from Connect for Merchants relating to its services to your registered email. You can change this preference in the Account Settings.
                    </CaptionSecondary>
                  </Box>
                  : <></>}
              </FormCard>
            </CenteredDialogComponent>
            : <></>
  );
}
