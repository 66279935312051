import { useState } from 'react';
import { MiniDialog } from '../../components-molecules/dialogs/MiniDialog';
import { Box, IconButton, InputAdornment, Snackbar, Stack, TextField } from '@mui/material';
import { BodyOnePrimary, BodyOneSecondary } from '../../components-atoms/TypographyComponents';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ButtonContainedPrimary, ButtonTextPrimary } from '../../components-atoms/ButtonComponents';
import { WatchIndustryEventRequest } from '../../service/serviceService';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';

interface IIndustryEventWatchEventProps {
  isOpen: boolean;
  id: string;
  name: string;
  password: string;
  eventUrl: string;
  onClose: () => void;
}

export default function IndustryEventWatchEvent({ isOpen, id, name, password, eventUrl, onClose }: IIndustryEventWatchEventProps): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const [showSnackbar, setShowSnackbar] = useState(false);

  function onProceed() {
    if (token) {
      WatchIndustryEventRequest(token, id, () => {/** */ }, () => {/** */ });
      window.open(eventUrl, '_blank');
    }
  }

  function onCopy() {
    setShowSnackbar(true);
    navigator.clipboard.writeText(password);
  }

  return (
    <>
      <MiniDialog
        title='Watch Event'
        open={isOpen}
        close={() => onClose()}
        submit={() => onProceed()}
        customBottomButtons={() =>
          <Box sx={{ margin: '16px', alignSelf: 'end' }}>
            <ButtonTextPrimary sx={{ mr: '8px' }} onClick={() => onClose()}>Cancel</ButtonTextPrimary>
            <ButtonContainedPrimary onClick={() => onProceed()}>Proceed</ButtonContainedPrimary>
          </Box>
        }>
        <Box mx='24px'>
          <Stack gap='16px' mb='16px'>
            <BodyOneSecondary>
              The following link will open an external site outside of Dulux Connect for Merchants platform. The content on the site is password protected.
            </BodyOneSecondary>
            <BodyOneSecondary>
              Copy the password before proceeding to the external site.
            </BodyOneSecondary>
            <BodyOnePrimary>
              {name}
            </BodyOnePrimary>
          </Stack>
          <TextField
            fullWidth
            variant='filled'
            id='event-password'
            placeholder='Password'
            aria-label='Password'
            label='Password'
            value={password}
            InputProps={{
              endAdornment:
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                    onClick={onCopy}>
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>
            }}
          />
        </Box>
      </MiniDialog>
      <Snackbar
        message="Event password copied to clipboard."
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
      />
    </>
  );
}
