/* eslint-disable @typescript-eslint/naming-convention */
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import CompleteInvitations from '../components-organisms/CompleteInvitations';
import { Dashboard } from '../components-organisms/Dashboard';
import PendingInvitations from '../components-organisms/PendingInvitations';
import RejectedInvitations from '../components-organisms/RejectedInvitations';
import { IStyles } from '../constants/theme';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { GetPendingInvitationCount } from '../service/serviceService';

export default function MerchantRegistrations():JSX.Element {
  const dispatch = useTypedDispatch();
  const savedTab = useTypedSelector(state => state.merchantRegistrationReducer.tab);
  const [currentTab, setCurrentTab] = useState<'pending' | 'completed' | 'rejected'>(savedTab?savedTab:'pending');
  const token = useTypedSelector(state => state.userReducer.token);

  useEffect(() => {
    getPendingCount();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPendingCount=()=>{
    if(token){
      GetPendingInvitationCount(
        token,
        (res) => {
          dispatch({ type: 'DRAWER_SET_PENDING_INVITATION_COUNT', payload: { invitationCount: res.Result.Count } });
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };
  return (
    <Dashboard justifyContent="flex-start">
      <PageHeader
        title={'Merchant Registrations'}
        noSort
      />
      <TabContext value={currentTab}>
        <TabList onChange={(e: unknown, newValue: 'pending' | 'completed' | 'rejected') => setCurrentTab(newValue)} style={styles.tabList}>
          <Tab data-testid={'admin-users-content-tab'} label="Pending Invitation"  value="pending" style={styles.tabButton} />
          <Tab data-testid="admin-roles-tab" label="Completed Invitation Requests" value="completed" style={styles.tabButton} />
          <Tab data-testid="admin-roles-tab" label="Rejected Invitation Requests" value="rejected" style={styles.tabButton} />
        </TabList>
        <Box sx={{ minHeight: '80vh', width: '100%' }}>
          <TabPanel value="pending" style={styles.p0}>
            <PendingInvitations/>
          </TabPanel>
          <TabPanel value="completed" style={styles.p0}>
            <CompleteInvitations/>
          </TabPanel>
          <TabPanel value="rejected" style={styles.p0}>
            <RejectedInvitations/>
          </TabPanel>
        </Box>
      </TabContext>

    </Dashboard>
  );
}
const styles: IStyles = {
  tabList: {
    margin: '24px 0 36px',
    width: '100%'
  },
  tabButton: {
    width: 'calc(100%/3)', 
    maxWidth: 'calc(100%/3)'
  },
  p0: {
    padding: 0
  }
};
