import Grid from '@mui/material/Grid';
import imageIcon from '../../assets/img/industry-events/industry_event_placeholder.png';
import { Section } from '../../components-molecules/section/Section';
import { HeaderSix, BodyTwoPrimary, ContainedTypography, CaptionPrimary, CaptionSecondary, OverlineSecondary } from '../../components-atoms/TypographyComponents';
import { LIGHT_THEME } from '../../constants/theme';
import { Fragment, useCallback, useState } from 'react';
import { ButtonOutlinedPrimary } from '../../components-atoms/ButtonComponents';
import { SectionHeaderMenu, SectionHeaderMenuButton } from '../../components-molecules/section/SectionHeader';
import Box from '@mui/system/Box';
import { BlinkBox } from '../../components-molecules/BlinkBox';
import moment from 'moment';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { RegisterIndustryEvent } from '../../service/serviceService';

interface IndustryEventCardComponentProps {
  event: IndustryEvent;
  isTMorSM?: boolean;
  onEdit: () => void;
  onUnpublish: () => void;
  onPublish: () => void;
  onJoinEvent: (eventId: GUID | string) => void;
  onWatchEventModalOpen: (eventId: GUID | string) => void;
}

export function IndustryEventCard({ event, isTMorSM, onEdit, onUnpublish, onPublish, onJoinEvent, onWatchEventModalOpen }: IndustryEventCardComponentProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const token = useTypedSelector(state => state.userReducer.token);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const [registered, setRegistered] = useState<number>();

  const onRegisterNow = useCallback((eventId: GUID | string) => {
    if (token) RegisterIndustryEvent(token, eventId, () => setRegistered(Date.now()), () => { /** */ });
  }, [token]);

  const registrationDate = event.RegistrationDate || registered;

  return (
    <Section fullPadding customSx={{ background: event.State === 'Live' ? LIGHT_THEME.palette.action.focus : event.State === 'Upcoming' ? LIGHT_THEME.palette.action.hover : undefined }}>
      <Grid container columns={2} flexWrap="nowrap">
        <Grid item sx={{ height: '96px', width: '96px', marginRight: '24px' }}>
          <img
            src={event.ThumbnailUrl || imageIcon}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = imageIcon;
            }}
            style={{
              height: 'inherit',
              width: 'inherit',
              border: '1px solid rgba(0, 0, 0, 0.08)',
              borderRadius: '4px',
              objectFit: 'cover',
              background: 'white'
            }}
          />
        </Grid>

        <SectionHeaderMenu
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          onEdit={onEdit}
          onUnpublish={event.IsPublished ? onUnpublish : undefined}
          onPublish={!event.IsPublished ? onPublish : undefined}
          noIcon
          minWidth='160px'
        />

        <Grid item width="100%">
          <Grid container columns={3} alignItems="center" justifyContent="space-between" columnGap="16px" sx={{ margin: '0 0 4px' }}>
            {event.State === 'Live'
              ? <Fragment>
                <Box
                  width="fit-content"
                  height="32px"
                  px="12px"
                  borderRadius="16px"
                  bgcolor={LIGHT_THEME.palette.error.main}>
                  <BlinkBox
                    width="8px"
                    height="8px"
                    borderRadius="4px"
                    marginRight="8px"
                    display="inline-block"
                    bgcolor="white"
                  />
                  <OverlineSecondary sx={{ color: 'white', textTransform: 'uppercase' }}>
                    Live
                  </OverlineSecondary>
                </Box>
                <Box>
                  {isTMorSM && <ButtonOutlinedPrimary variant="outlined" color="primary" onClick={() => onJoinEvent(event.Id)}>Join Event</ButtonOutlinedPrimary>}
                  {!isTMorSM && <SectionHeaderMenuButton handleClick={handleClick} sx={{ margin: '0 0 0 12px' }} />}
                </Box>
              </Fragment>
              : null}

            {event.State === 'Upcoming'
              ? <Fragment>
                <ContainedTypography text="Upcoming" typographyProps={{ sx: { textTransform: 'uppercase' } }} />
                {isTMorSM
                  && <Fragment>
                    {registrationDate
                      && <Grid item sx={{ textAlign: 'right' }} flexGrow={1}>
                        <BodyTwoPrimary sx={{ maxWidth: '245px', float: 'right' }}>
                          You have registered to this event on {moment(new Date(registrationDate)).format('MMM DD, yyyy – h:mm a')}
                        </BodyTwoPrimary>
                      </Grid>}

                    <Grid item>
                      <ButtonOutlinedPrimary
                        variant="outlined"
                        color="primary"
                        onClick={() => onRegisterNow(event.Id)}
                        disabled={registered !== undefined || event.HasRegistered}
                      >
                        Register Now
                      </ButtonOutlinedPrimary>
                    </Grid>
                  </Fragment>}
                {!isTMorSM && <SectionHeaderMenuButton handleClick={handleClick} sx={{ margin: '0 0 0 12px' }} />}
              </Fragment>
              : null}
          </Grid>

          <Grid container columns={2} alignItems="center" justifyContent="space-between" sx={{ margin: '0 0 4px' }}>
            <HeaderSix sx={{ margin: '0' }}>{event.Title}</HeaderSix>

            {event.State === 'Past'
              ? <Box>
                {isTMorSM && <ButtonOutlinedPrimary variant="outlined" color="primary" onClick={() => onWatchEventModalOpen(event.Id)}>Watch Event</ButtonOutlinedPrimary>}
                {!isTMorSM && <SectionHeaderMenuButton handleClick={handleClick} sx={{ margin: '0 0 0 12px' }} />}
              </Box>
              : null}
          </Grid>

          <Grid container columns={2} rowGap="16px">
            <Grid item sm={1}>
              <CaptionSecondary component="p">Date & Time</CaptionSecondary>
              <CaptionPrimary>{event.StartDate ? moment(new Date(event.StartDate)).format('MMM DD, yyyy – h:mm a') : '-'}</CaptionPrimary>
            </Grid>

            <Grid item sm={1}>
              <CaptionSecondary component="p">Password</CaptionSecondary>
              <CaptionPrimary>{event.RecordingPassword || '-'}</CaptionPrimary>
            </Grid>

            {!isTMorSM
              && <Grid item sm={1}>
                <CaptionSecondary component="p">Target Users</CaptionSecondary>
                <CaptionPrimary>{event.TargetUsers || '-'}</CaptionPrimary>
              </Grid>}

            {!isTMorSM && event.State !== 'Past'
              && <Grid item sm={1}>
                <CaptionSecondary component="p">Registered Users</CaptionSecondary>
                <CaptionPrimary>{event.NumberOfRegistrations}</CaptionPrimary>
              </Grid>}

            {event.State === 'Past'
              && <Grid item sm={1}>
                <CaptionSecondary component="p">Number of Views</CaptionSecondary>
                <CaptionPrimary>{event.NumberOfViews}</CaptionPrimary>
              </Grid>}

            {!isTMorSM
              && <Grid item sm={1}>
                <CaptionSecondary component="p">Event Status</CaptionSecondary>
                {event.IsPublished
                  ? <CaptionPrimary fontWeight={600} sx={{ color: LIGHT_THEME.palette.success.main }}>Published</CaptionPrimary>
                  : <CaptionPrimary fontWeight={600} sx={{ color: LIGHT_THEME.palette.error.main }}>Unpublished</CaptionPrimary>}
              </Grid>}

            <Grid item sm={2}>
              <BodyTwoPrimary>{event.Description}</BodyTwoPrimary>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Section >
  );
}
