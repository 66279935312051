/* eslint-disable @typescript-eslint/naming-convention */
import { CircularProgress, Grid } from '@mui/material';
import { useState } from 'react';
import { IStyles } from '../constants/theme';
import { isSafari } from 'react-device-detect';

interface IProps {
  url: string;
  darkTheme?: boolean;
}

export const PdfViewer = ({ url }: IProps): JSX.Element => {
  const [loading, setLoading] = useState(true);

  const stopLoading = () => {
    setLoading(false);
  };

  if(isSafari) {
    return (
      <embed 
        src={url}
        onLoad={() => stopLoading()}
        width="100%" 
        height="100%" />
    );
  }

  return <div style={styles.container}>
    {loading
      ? <Grid width="100%" height="100%" container item justifyContent="center" alignItems="center">
        <CircularProgress
          color="primary"
          size={50}
          style={{ zIndex: 999, margin: '56px 0' }}
        />
      </Grid>
      : <></>
    }
    <iframe src={url} onLoad={() => stopLoading()} width="100%" height="100%" style={{ display: loading ? 'none' : 'initial' }} />
  </div>; 
};

const styles: IStyles = {
  container: { width:'100%', height: '100%' }
};
