/* eslint-disable @typescript-eslint/naming-convention */
import '../../../scss/components/_rte.scss';
import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { Box, Divider, Grid, Stack, useMediaQuery } from '@mui/material';
import { BodyOnePrimary, BodyTwoPrimary, ContainedTypography, HeaderFivePrimary, OverlineSecondary } from '../../../components-atoms/TypographyComponents';
import { LIGHT_THEME } from '../../../constants/theme';
import { ButtonContainedPrimary } from '../../../components-atoms/ButtonComponents';
import { removeTags } from '../../../utils/getShortDescription';
import eventPlaceholder from '../../../assets/img/industry-events/industry_event_placeholder.png';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { BlinkBox } from '../../../components-molecules/BlinkBox';
import { useTypedSelector } from '../../../hooks/TypedReduxHooks';
import { RegisterIndustryEvent } from '../../../service/serviceService';


interface IndustryEventListingCardImageProps {
  hasShadow?: boolean;
  thumbnailFit?: string;
  error: boolean;
  setError: (error: boolean) => void;
  useEventPlaceholder?: boolean;
  thumbnailUrl?: string;
}

function IndustryEventListingCardImage({ hasShadow, thumbnailFit, error, setError, thumbnailUrl }: IndustryEventListingCardImageProps): JSX.Element {
  const smDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));

  return (
    <Grid
      item
      xs={12}
      sm={3}
      display='flex'
      justifyContent='flex-start'
      alignItems='flex-start'>
      <Grid
        container
        justifyContent="center"
        item
        sx={{
          aspectRatio: '1/1',
          maxHeight: { xs: '176px', sm: '320px' },
          maxWidth: { xs: '100%', sm: '320px' },
          width: '100%',
          borderRadius: '4px',
          boxShadow: hasShadow ? LIGHT_THEME.shadows[6] : undefined,
          outline: '1px solid rgba(0, 0, 0, 0.12)'
        }}>
        <img
          style={{
            width: '100%',
            borderRadius: '4px',
            maxHeight: thumbnailFit === 'contain' ? '320px' : '100%',
            height: smDown ? '176px' : '100%',
            objectFit: thumbnailFit as any,
            background: thumbnailFit === 'contain' ? 'rgba(0, 0, 0, 0.04)' : 'white',
          }}
          src={thumbnailUrl === null || error ? eventPlaceholder : thumbnailUrl}
          onError={() => setError(true)}
        />
      </Grid>
    </Grid>
  );
}

interface IndustryEventListingCardProps {
  thumbnailUrl?: string;
  title: string;
  description: string;
  isFirst?: boolean;
  isLast?: boolean;
  hideEllipsis?: boolean;
  thumbnailFit?: string;
  event: IndustryEvent;
  hasShadow?: boolean;
  onJoinEvent: (eventId: GUID | string) => void;
  onWatchEventModalOpen: (eventId: GUID | string) => void;
}

const ellipsisStyle = {
  wordBreak: 'break-word',
  color: 'rgb(0, 0, 0, 0.65)',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export function IndustryEventListingCard(props: IndustryEventListingCardProps): JSX.Element {
  const {
    thumbnailUrl,
    title,
    description,
    isFirst,
    isLast,
    hideEllipsis = false,
    thumbnailFit = 'contain',
    event,
    hasShadow,
    onJoinEvent,
    onWatchEventModalOpen
  } = props;
  const token = useTypedSelector(state => state.userReducer.token);

  const [error, setError] = useState(false);
  const formattedDescription = useMemo(() => removeTags(description), [description]);
  const [seperatedDate, seperatedTime] = useMemo(
    () => {
      if (event.StartDate) {
        return [
          moment(new Date(event.StartDate)).format('MMM DD, yyyy'),
          moment(new Date(event.StartDate)).format('h:mm a'),
        ];
      }
      return [null, null];
    }, [event.StartDate]);

  const [registered, setRegistered] = useState<number>();

  const onRegisterNow = useCallback((eventId: GUID | string) => {
    if (token) RegisterIndustryEvent(token, eventId, () => setRegistered(Date.now()), () => { /** */ });
  }, [token]);

  const registrationDate = event.RegistrationDate || registered;

  function renderContent() {
    return (
      <Grid
        item
        xs={12}
        sm={9}
        mt={{ xs: 4, sm: 0 }}>
        <Grid container height='100%' mb={{ xs: 0, sm: '12px' }}>
          <Box maxWidth='672px'>
            {event.State === 'Live'
              ? <Box
                width="fit-content"
                height="32px"
                px="12px"
                borderRadius="16px"
                bgcolor={LIGHT_THEME.palette.error.main}>
                <BlinkBox
                  width="8px"
                  height="8px"
                  borderRadius="4px"
                  marginRight="8px"
                  display="inline-block"
                  bgcolor="white"
                />
                <OverlineSecondary sx={{ color: 'white', textTransform: 'uppercase' }}>
                  Live
                </OverlineSecondary>
              </Box>
              : null}

            {event.State === 'Upcoming' ? <ContainedTypography text="Upcoming" typographyProps={{ sx: { textTransform: 'uppercase' } }} /> : null}

            <HeaderFivePrimary sx={{ margin: '10px 0', color: 'rgba(0, 0, 0, 0.87)', fontFamily: 'Open Sans', fontWeight: '700', fontSize: '24px' }}>
              {title}
            </HeaderFivePrimary>

            {seperatedDate || seperatedTime || event.State === 'Past'
              ? <Grid container alignItems="center" columnGap="32px" sx={{ mb: '8px' }}>
                {seperatedDate
                  ? <Grid item>
                    <EventIcon color='primary' sx={{ height: '16.67px', width: '16.67px', m: '0 6px -3px 0' }} />
                    <BodyOnePrimary sx={{ display: 'inline-block' }}>{seperatedDate}</BodyOnePrimary>
                  </Grid>
                  : null}
                {seperatedTime
                  ? <Grid item>
                    <AccessTimeIcon color='primary' sx={{ height: '16.67px', width: '16.67px', m: '0 6px -3px 0' }} />
                    <BodyOnePrimary sx={{ display: 'inline-block' }}>{seperatedTime}</BodyOnePrimary>
                  </Grid>
                  : null}
                {event.State === 'Past'
                  ? <Grid item>
                    <VisibilityIcon color='primary' sx={{ height: '16.67px', width: '16.67px', m: '0 6px -3px 0' }} />
                    <BodyOnePrimary sx={{ display: 'inline-block' }}>{event.NumberOfViews}</BodyOnePrimary>
                  </Grid>
                  : null}
              </Grid>
              : null}

            <p
              className={'rte listing-card-rte'}
              style={!hideEllipsis
                ? {
                  wordBreak: 'break-word', margin: 0, fontSize: 14, color: 'rgba(0, 0, 0, 0.6)', ...ellipsisStyle as any
                } : { wordBreak: 'break-word', margin: 0, fontSize: 14, color: 'rgba(0, 0, 0, 0.6)' }
              }
              dangerouslySetInnerHTML={{ __html: formattedDescription }}>
            </p>

            <Stack mt='20px' mb='32px'>
              {event.State === 'Live'
                && <ButtonContainedPrimary onClick={() => onJoinEvent(event.Id)} sx={{ height: '36px', padding: '6px 20px', alignSelf: 'flex-start' }}>
                  Join Event
                </ButtonContainedPrimary>}

              {event.State === 'Upcoming'
                && <ButtonContainedPrimary
                  onClick={() => onRegisterNow(event.Id)}
                  disabled={event.HasRegistered || registered !== undefined}
                  sx={{ height: '36px', padding: '6px 20px', alignSelf: 'flex-start' }}
                >
                  Register Now
                </ButtonContainedPrimary>}

              {event.State === 'Past'
                && <ButtonContainedPrimary
                  onClick={() => onWatchEventModalOpen(event.Id)}
                  sx={{ height: '36px', padding: '6px 20px', alignSelf: 'flex-start' }}
                >
                  Watch Event
                </ButtonContainedPrimary>}

              {registrationDate
                ? <BodyTwoPrimary sx={{ margin: '16px 0 0' }}>
                  You have registered to this event on {moment(new Date(registrationDate)).format('MMM DD, yyyy – h:mm a')}
                </BodyTwoPrimary>
                : null}
            </Stack>
          </Box>
        </Grid>

        {!isLast && <Divider sx={{ width: '100%' }} />}
      </Grid>
    );
  }

  return (
    <Grid
      container
      mt={isFirst ? 0 : 4}
      mb={8}
      maxWidth='1408px'
      columnSpacing={{ xs: 0, sm: 4, lg: 8, xl: 8 }}
    >
      <IndustryEventListingCardImage
        hasShadow={hasShadow}
        thumbnailFit={thumbnailFit}
        error={error}
        setError={setError}
        thumbnailUrl={thumbnailUrl}
      />
      {renderContent()}
    </Grid>
  );
}
