/* eslint-disable @typescript-eslint/naming-convention */
import { Divider, Grid, List, ListItem } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { BodyOneSecondary, BodyTwoSecondary, HeaderSix, SubtitleOnePrimary } from '../components-atoms/TypographyComponents';
import { IStyles, LIGHT_THEME } from '../constants/theme';
import '../scss/components/_list.scss';
import '../scss/components/_masked-image.scss';
import { ExternalRedirectButton } from '../components-molecules/ExternalRedirectButton';

export function DuluxAcademyService(): JSX.Element {
  return (
    <>
      <Grid
        container
        item
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          maxWidth: '1408px',
          width: '100%',
          padding: '0 24px',
          marginTop: '32px'
        }}
      >
        <Grid item sm={3}>
          <Grid
            container
            justifyContent="center"
            item
            style={styles.imageContainer}
          >
            <img
              style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'cover' }}
              src={`${require('../assets/img/academy/dulux-academy-logo@2x.png')}`}
            />
          </Grid>
        </Grid>
        <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
          <Grid sx={{ maxWidth: { sm: '672px' }, width: '100%' }}>
            <HeaderSix sx={{ margin: '28px 0 10px' }}>
              About Dulux Academy
            </HeaderSix>
            <BodyOneSecondary style={{ marginBottom: '8px' }}>
              Our team of industry leading experts have done it all before and will
              share the possibilities offered with new technologies and developments
              in products and the required application skills. Training with Dulux
              Academy is fun and engaging and you’ll take part in learning experiences
              that cover knowledge and understanding and where possible we’ll aim to
              give you practical hands on experience.
            </BodyOneSecondary>
            <BodyOneSecondary style={{ marginBottom: '20px' }}>
              Dulux Academy has a strong relationship with supplier partners including
              Mirka, Graco, Tesa, Graham & Brown and Axus ensuring those who train with
              us have access to and information about the latest tools and equipment on
              the market.
            </BodyOneSecondary>
            <ExternalRedirectButton
              style={{ marginBottom: '62px' }}
              url={'https://academy.duluxtradepaintexpert.co.uk/'}>
              Proceed to Dulux Academy
            </ExternalRedirectButton>
            <Divider style={{ width: '100%' }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        sx={{
          marginTop: '32px',
          background: 'linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)'
        }}
      >
        <Grid container sx={{ maxWidth: '1408px', width: '100%', padding: '0 24px', margin: '0 auto' }}>
          <Grid item xs={12} sm={3} sx={{ width: '100%' }}>
          </Grid>
          <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
            <Grid sx={{ maxWidth: { sm: '672px' }, width: '100%' }}>
              <SubtitleOnePrimary>Investing in your team’s product knowledge will:</SubtitleOnePrimary>
              <List sx={{ '&.MuiList-root': { paddingTop: '24px', paddingBottom: '29px' } }} className="benefits-list">
                <ListItem
                  alignItems="flex-start"
                  disablePadding
                  className="list-item"
                >
                  <CheckIcon sx={{ fill: LIGHT_THEME.palette.primary.main }}/>
                  <BodyTwoSecondary>
                    Increase confidence
                  </BodyTwoSecondary>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  disablePadding
                  className="list-item"
                >
                  <CheckIcon sx={{ fill: LIGHT_THEME.palette.primary.main }}/>
                  <BodyTwoSecondary>
                    Build enthusiasm & engagement within teams
                  </BodyTwoSecondary>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  disablePadding
                  className="list-item"
                >
                  <CheckIcon sx={{ fill: LIGHT_THEME.palette.primary.main }}/>
                  <BodyTwoSecondary>
                    Provide customers with reassurance
                  </BodyTwoSecondary>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  disablePadding
                  className="list-item"
                >
                  <CheckIcon sx={{ fill: LIGHT_THEME.palette.primary.main }}/>
                  <BodyTwoSecondary>
                    Demonstrate your value as a provider of advice
                  </BodyTwoSecondary>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  disablePadding
                  className="list-item"
                >
                  <CheckIcon sx={{ fill: LIGHT_THEME.palette.primary.main }}/>
                  <BodyTwoSecondary sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.08) !important', width: '100%' }}>
                    Develop inhouse expertise
                  </BodyTwoSecondary>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  disablePadding
                  className="list-item"
                >
                  <CheckIcon sx={{ fill: LIGHT_THEME.palette.primary.main }}/>
                  <BodyTwoSecondary sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.08) !important', width: '100%' }}>
                    Offer customers options and solutions
                  </BodyTwoSecondary>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  disablePadding
                  className="list-item"
                >
                  <CheckIcon sx={{ fill: LIGHT_THEME.palette.primary.main }}/>
                  <BodyTwoSecondary sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.08) !important', width: '100%' }}>
                    Assist understanding competitors
                  </BodyTwoSecondary>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  disablePadding
                  className="list-item"
                >
                  <CheckIcon sx={{ fill: LIGHT_THEME.palette.primary.main }}/>
                  <BodyTwoSecondary sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.08) !important', width: '100%' }}>
                    Encourage continuous learning within teams
                  </BodyTwoSecondary>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        alignItems="flex-start"
        sx={{
          maxWidth: '1408px',
          width: '100%',
          padding: '0 24px',
          marginTop: '32px'
        }}
      >
      </Grid>
    </>
  );
}

const styles: IStyles = {
  imageContainer: {
    aspectRatio: '1/1',
    maxHeight: '320px',
    maxWidth: '320px',
    width: '100%',
    objectFit: 'contain',
    marginLeft: 'auto'
  }
};
