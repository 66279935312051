/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';
import axios from 'axios';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import { Avatar, CircularProgress, Container, FilledInput, IconButton, LinearProgress } from '@mui/material';

import { LIGHT_THEME } from '../../constants/theme';
import { useTypedDispatch, useTypedSelector } from '../../hooks/TypedReduxHooks';
import { GroupedMerchant, ListMerchantsRequest } from '../../service/merchantsService';
import { GetMerchantTokenRequest } from '../../service/adminService';

import { ImageComponent, RoundedImageComponent } from '../../components-atoms/ImageComponents';
import { BodyTwoPrimary, BodyTwoSecondary, CaptionSecondary, HeaderFivePrimary, HeaderSix } from '../../components-atoms/TypographyComponents';
import { ButtonOutlinedPrimary, ButtonTextPrimary } from '../../components-atoms/ButtonComponents';
import { ListDivider } from '../../components-molecules/Divider';

import DuluxMerchantConnectLogo from '../../assets/img/DuluxMerchantConnect-logo.png';
import imageIcon from '../../assets/img/merchant_placeholder.jpg';
import { GetAvailableServices } from '../../service/serviceService';
import PreviewIcon from '@mui/icons-material/Preview';

export function MainBar({ merchantPage }: { merchantPage?: boolean; }): JSX.Element {
  const [token, userData, superData] = useTypedSelector((state) => [state.userReducer.token, state.userReducer.data, state.userReducer.super]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const [groupedMerchants, setGroupedMerchants] = useState<GroupedMerchant[]>([]);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState(0);
  const [query, setQuery] = useState<string>('');
  const pageSize = 20;
  const merchantRefresh = useTypedSelector(state => state.merchantReducer.refresh);
  const loadingState = useTypedSelector((state) => state.loadingReducer);
  const timeoutDuration = 500;
  const cancelTokenSource = axios.CancelToken.source();

  // const user = useTypedSelector((state) => state.userReducer.data);
  // const isTMorSM = user?.IsTerritoryManager || user?.IsSalesManager;
  const isTMorSM = false;
  const isAdmin = useMemo(() => window.location.href.includes('/admin'), [window.location]);
  const isProfile = useMemo(() => window.location.pathname === '/profile', [window.location]);

  const getTerritoryManagerMerchants = useCallback((t: string, cancelToken = undefined, page = 0, size = pageSize, prevData: GroupedMerchant[] = []): void => {
    ListMerchantsRequest(
      t,
      {
        Query: query,
        Size: size,
        Page: page
      },
      (response) => {
        if (response) {
          let temp = [...prevData];
          response.Result.Result.map(m => {
            const tempMatch = temp.find((f) => f.GroupName === m.GroupName);
            if (tempMatch) {
              temp[temp.indexOf(tempMatch)].Result = [...temp[temp.indexOf(tempMatch)].Result, ...m.Result];
            } else {
              temp = [...temp, m];
            }
          });
          setGroupedMerchants(temp);
          setTotal(response.Result.Count);
        }
      },
      (error) => { if (error.response) console.error(error.response.data.Error); },
      cancelToken
    );
  }, [query]);

  const loadMore = () => {
    if (token) {
      getTerritoryManagerMerchants(token, undefined, page + 1, pageSize, groupedMerchants);
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (token && (userData?.IsAdmin)) getTerritoryManagerMerchants(token, cancelTokenSource.token);
    return () => {
      cancelTokenSource.cancel();
    };
  }, [getTerritoryManagerMerchants, token, userData, merchantRefresh]);

  const logout = () => {
    dispatch({ type: 'USER_SIGNOUT' });
    navigate('/signin');
  };

  interface IPathOptions {
    [key: string]: ((merchant?: MerchantListModel) => void);
  }

  const pathOptions: IPathOptions = {
    merchant: (merchant?: MerchantListModel): void => {
      if (merchant && token) {
        GetMerchantTokenRequest(
          token,
          merchant.Id,
          (response) => {
            if (response.Result) {
              dispatch({ type: 'USER_ADD_SUPERUSE', payload: { merchant, token: response.Result } });
              localStorage.setItem('merchantToken', response.Result);
              GetAvailableServices(
                response.Result,
                (res) => {
                  dispatch({ type: 'USER_SET_SERVICES', payload: { services: res.Result } });
                  if (location.pathname === '/') {
                    navigate(0);
                  } else {
                    navigate('/');
                  }
                },
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                () => { }
              );

            }
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => { }
        );
      }
    },
    admin: (): void => {
      dispatch({ type: 'USER_CLEAR_SUPERUSE' });
      navigate('/admin');
    },
    profile: (): void => navigate('/profile'),
    organisation: (): void => navigate('/organisation')
  };

  const handlePath = (path: string, merchant?: MerchantListModel) => {
    pathOptions[path](merchant);
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const onSearch = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setQuery(event.target.value);
    debounce((e) => dispatch({ type: 'SET_SEARCH_QUERY', payload: { currentData: e.target.value } }), timeoutDuration)(event);
  };

  const onClear = () => {
    setQuery('');
    dispatch({ type: 'SET_SEARCH_QUERY', payload: { currentData: '' } });
  };

  const getInitials = (fullName: string) => {
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if (index === 0 || index === allNames.length - 1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        transition: '0.07s all',
        paddingLeft: '8px',
        boxShadow: '0 3px 3px 0 rgb(51 51 51 / 11%)',
        backgroundColor: 'unset',
      }}>
      <Toolbar variant="main" sx={{ justifyContent: 'space-between' }} >
        <Box sx={{ position: 'relative', }}>
          <ImageComponent
            onClick={() => navigate('/')}
            src={DuluxMerchantConnectLogo}
            alt="Dulux Connect for Merchants"
            sx={{
              height: { xs: '48px' },
              maxHeight: '70px',
              maxWidth: '100%',
              cursor: 'pointer',
            }}
          />
        </Box>

        <HeaderFivePrimary sx={{ display: { xs: 'none', sm: 'block' }, ml: 2, flexGrow: 1 }}>{(userData?.IsAdmin) && (merchantPage ? 'Connect for Merchants' : 'Admin Portal')}</HeaderFivePrimary>

        {(userData)
          ? merchantPage ? (
            <IconButton data-testid="side-menu-button" onClick={openMenu}>
              <Avatar sx={{ bgcolor: '#00B495' }}>{getInitials(`${userData.FirstName} ${userData.LastName}`)}</Avatar>
            </IconButton>
          ) : (
            <ButtonTextPrimary data-testid="side-menu-button" endIcon={<ArrowDropDownIcon />} onClick={openMenu} sx={{ margin: '0 -8px 0 0' }}>
              {`${userData.FirstName} ${userData.LastName}`}
            </ButtonTextPrimary>
          )
          : null
        }

        {(userData)
          ? <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            onClose={closeMenu}
            MenuListProps={{ sx: { width: '100%', padding: '0', maxWidth: '350px' } }}
          >
            <Box sx={{ margin: '20px 0', height: '92px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
              <HeaderSix sx={{ m: 0, p: 0 }}>&#8288;{`${userData.FirstName} ${userData.LastName}`}</HeaderSix>
              <BodyTwoSecondary style={{ fontSize: '14px', wordBreak: 'break-all', padding: '0 32px' }}>{userData.Email}</BodyTwoSecondary>
            </Box>

            <Divider />

            <Box id="scrollableDiv" sx={{ maxHeight: '450px', overflow: 'auto', scrollbarWidth: 'thin' }}>
              {(userData.IsAdmin)
                ? <ButtonTextPrimary
                  sx={{
                    height: '60px',
                    width: '100%',
                    borderRadius: 0,
                    padding: '0 !important',
                    background: isAdmin && !isProfile ? 'rgba(1, 33, 105, 0.08)' : 'transparent'
                  }}
                  onClick={() => handlePath('admin')}
                >
                  <Grid
                    container
                    sx={{
                      height: '40px',
                      margin: '10px 32px',
                      borderRadius: '8px'
                    }}
                  >
                    <Grid item sx={{ height: '40px', width: '40px', padding: '8px 6px', marginLeft: '-8px' }}>
                      <SettingsIcon sx={{ fill: '#415AA9' }} />
                    </Grid>
                    <Grid item sx={{ height: '40px', textAlign: 'left', margin: '0 0 0 6px', padding: '10px 0' }}>
                      <BodyTwoPrimary>Admin Panel</BodyTwoPrimary>
                    </Grid>
                  </Grid>
                </ButtonTextPrimary>
                : null
              }

              {isTMorSM
                ? <ButtonTextPrimary
                  sx={{
                    height: '60px',
                    width: '100%',
                    borderRadius: 0,
                    padding: '0 !important',
                    background: !superData?.merchant.Id && !isAdmin && !isProfile ? 'rgba(1, 33, 105, 0.08)' : 'transparent'
                  }}
                  onClick={() => {
                    dispatch({ type: 'USER_CLEAR_SUPERUSE' });
                    navigate('/');
                  }}>
                  <Grid
                    container
                    sx={{
                      height: '40px',
                      margin: '10px 32px',
                      borderRadius: '8px'
                    }}>
                    <Grid item sx={{ height: '40px', width: '40px', padding: '8px 6px', marginLeft: '-8px' }}>
                      <PreviewIcon sx={{ fill: '#415AA9' }} />
                    </Grid>
                    <Grid item sx={{ height: '40px', textAlign: 'left', margin: '0 0 0 6px', padding: '10px 0' }}>
                      <BodyTwoPrimary>Customer Portal</BodyTwoPrimary>
                    </Grid>
                  </Grid>
                </ButtonTextPrimary>
                : null}

              <ButtonTextPrimary
                sx={{
                  height: '60px',
                  width: '100%',
                  borderRadius: 0,
                  padding: '0 !important',
                  background: isProfile ? 'rgba(1, 33, 105, 0.08)' : 'transparent'
                }}
                onClick={() => handlePath('profile')}
              >
                <Grid
                  container
                  sx={{
                    height: '40px',
                    margin: '10px 32px',
                    borderRadius: '8px',
                  }}
                >
                  <Grid item sx={{ height: '40px', width: '40px', padding: '8px 6px', marginLeft: '-8px' }}>
                    <AccountCircleIcon sx={{ fill: '#415AA9' }} />
                  </Grid>
                  <Grid item sx={{ height: '40px', textAlign: 'left', margin: '0 0 0 6px', padding: '10px 0' }}>
                    <BodyTwoPrimary data-testid="account-settings-button" >Account Settings</BodyTwoPrimary>
                  </Grid>
                </Grid>
              </ButtonTextPrimary>

              {(userData.IsAdmin)
                ? <Grid
                  container
                  alignItems="center"
                  sx={{
                    height: '47px',
                    width: 'calc(100% - 48px)',
                    margin: '8px 24px 9px'
                  }}
                >
                  <FilledInput
                    value={query}
                    size="medium"
                    fullWidth
                    data-testid="search-input"
                    placeholder="Search"
                    onChange={e => onSearch(e)}
                    endAdornment={query && (
                      <IconButton data-testid="search-clear-button" onClick={onClear}>
                        <ClearIcon fontSize='small' />
                      </IconButton>
                    )}
                    sx={{
                      height: '100%',
                      '&.MuiFilledInput-root': {
                        borderTopLeftRadius: '4px',
                        borderTopRightRadius: '4px'
                      },
                      '.MuiFilledInput-input': {
                        paddingTop: '12px',
                        paddingBottom: '12px'
                      }
                    }}
                  />
                </Grid>
                : null
              }

              {(userData.IsAdmin)
                ? <div style={{ width: '100%' }}>
                  <InfiniteScroll
                    style={{ overflowY: 'hidden' }}
                    dataLength={groupedMerchants.map(m => m.Result).flat().length} //This is important field to render the next data
                    next={() => debounce(() => loadMore(), 300)()}
                    scrollableTarget="scrollableDiv"
                    hasMore={groupedMerchants.map(m => m.Result).flat().length < total}
                    loader={
                      <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '30px', paddingBottom: '80px' }}>
                        <CircularProgress sx={{ position: 'absolute', top: '0', left: '50%' }} size={18} />
                      </Container>
                    }
                  >
                    {groupedMerchants.map((merchants) => {
                      return (
                        <ListDivider
                          key={merchants.GroupName}
                          division={{ name: merchants.GroupName, length: merchants.Result.length }}
                          caption={{ singular: 'Merchant', plural: 'Merchants' }}
                          halfMargin
                          leftMargin
                        >
                          {merchants.Result.map((merchant, index) => {
                            return (
                              <ButtonTextPrimary
                                disabled={merchant.ManagerStatus !== 'Active'}
                                key={index}
                                sx={{
                                  height: '62px',
                                  width: '100%',
                                  borderRadius: 0,
                                  padding: '0 !important',
                                  marginBottom: merchant.ManagerStatus === 'Active' ? 'auto' : '20px',
                                  background: merchant.Id === superData?.merchant.Id ? LIGHT_THEME.palette.action.selected : 'none',
                                  '&:hover': {
                                    backgroundColor: merchant.Id === superData?.merchant.Id ? 'rgba(1, 33, 105, 0.24)' : LIGHT_THEME.palette.action.hover
                                  }
                                }}
                                onClick={() => handlePath('merchant', merchant)}
                              >
                                <Grid
                                  container
                                  alignItems="center"
                                  sx={{
                                    minHeight: '40px',
                                    height: '100%',
                                    margin: '11px 32px',
                                    borderRadius: '8px',
                                    flexWrap: 'nowrap'
                                  }}
                                >
                                  <Grid item sx={{ height: '40px', width: '40px', padding: '6px', marginLeft: '-6px' }}>
                                    <RoundedImageComponent src={merchant.ThumbnailUrl || merchant.Logo || imageIcon} sx={{
                                      height: '28px', width: '28px',
                                      opacity: merchant.ManagerStatus !== 'Active' ? '0.4' : '1.0',
                                      filter: merchant.ManagerStatus !== 'Active' ? 'alpha(opacity = 40)' : 'auto'
                                    }} />
                                  </Grid>
                                  <Grid item sx={{ height: '40px', textAlign: 'left', margin: '0 0 0 6px', display: 'flex', flexDirection: 'column' }}>
                                    <BodyTwoPrimary sx={{ color: merchant.ManagerStatus === 'Active' ? LIGHT_THEME.palette.text.primary : LIGHT_THEME.palette.text.disabled }}>{merchant.Name}</BodyTwoPrimary>
                                    <CaptionSecondary sx={{ color: merchant.ManagerStatus === 'Active' ? LIGHT_THEME.palette.text.primary : LIGHT_THEME.palette.text.disabled }}>{merchant.PayerCode}</CaptionSecondary>
                                    {
                                      merchant.ManagerStatus === ''
                                        ? <CaptionSecondary sx={{ color: LIGHT_THEME.palette.warning.main }}>No manager added yet</CaptionSecondary>
                                        : merchant.ManagerStatus === 'Pending'
                                          ? <CaptionSecondary sx={{ color: LIGHT_THEME.palette.warning.main }}>Manager registration pending</CaptionSecondary>
                                          : <></>
                                    }
                                  </Grid>
                                </Grid>
                              </ButtonTextPrimary>
                            );
                          })}
                        </ListDivider>
                      );
                    })}
                  </InfiniteScroll>
                </div>
                : null
              }

              {
                (!(userData.IsAdmin))
                  ? <ButtonTextPrimary
                    sx={{ height: '60px', width: '100%', borderRadius: 0, padding: '0 !important' }}
                    onClick={() => handlePath('organisation')}
                  >
                    <Grid
                      container
                      sx={{
                        height: '40px',
                        margin: '10px 24px',
                        borderRadius: '8px',
                      }}
                    >
                      <Grid item sx={{ height: '40px', width: '40px', padding: '8px 6px' }}>
                        <BusinessIcon sx={{ fill: '#415AA9' }} />
                      </Grid>

                      <Grid item sx={{ height: '40px', textAlign: 'left', margin: '0 0 0 6px', padding: '10px 0' }}>
                        <BodyTwoPrimary>Organisation Profile</BodyTwoPrimary>
                      </Grid>
                    </Grid>
                  </ButtonTextPrimary>
                  : null
              }
            </Box>

            <Box sx={{ height: '60px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <ButtonOutlinedPrimary
                data-testid="logout-button"
                startIcon={<LogoutIcon />}
                onClick={() => logout()}
                sx={{
                  height: 30,
                  p: '4px 16px',
                  color: '#415AA9',
                  border: '1px solid #8090B4'
                }}>
                Sign Out
              </ButtonOutlinedPrimary>
            </Box>
          </Menu>
          : null
        }
      </Toolbar>

      {loadingState?.loading
        && <LinearProgress
          variant="determinate"
          color="secondary"
          value={loadingState?.percentage || 0}
        />
      }
    </AppBar >
  );
}
