/* eslint-disable @typescript-eslint/naming-convention */
import { IconButton, Tooltip } from '@mui/material';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { SUPPORT } from '../constants/customerSupport';
import { IStyles } from '../constants/theme';

const DEFAULT_TEXT = 'Got a question? Connect with us';
interface SupportTooltipProps {
  title?: string;
  arrow?: boolean;
  absolute?: boolean;
  white?: boolean;
}

export function SupportTooltip({ arrow = true, title = DEFAULT_TEXT, absolute, white }: SupportTooltipProps): JSX.Element {
  return (
    <Tooltip arrow={arrow} title={title}>
      <IconButton
        sx={[
          styles.iconButton,
          absolute
            ? styles.absoluteIconButton : {}
        ]}
        href={`mailto:${SUPPORT.mail}?subject=${SUPPORT.subject}`}
      >
        <ContactSupportIcon
          color='action'
          sx={[
            styles.icon,
            white
              ? styles.whiteIcon : {}
          ]}
        />
      </IconButton>
    </Tooltip>
  );
}

const styles: IStyles = {
  iconButton: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px'
  },
  absoluteIconButton: {
    position: 'absolute',
    bottom: '47px',
    right: '20px'
  },
  icon: {
    width: '24px',
    height: '24px'
  },
  whiteIcon: {
    color: '#fff'
  }
};
