/* eslint-disable @typescript-eslint/naming-convention */
import { Grid, Alert, LinearProgress } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormControlImage, FormControlInput } from '../../components-molecules/FormControl';
import { ModalCardComponent } from '../../components-molecules/Modal';
import RichTextEditor, { EditorValue, ToolbarConfig } from 'react-rte';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { REQUIRED_VALIDATION, LOGO_VALIDATION_WITH_TYPE } from '../../forms/_predefinedValidations';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { AddNewArticle, } from '../../service/serviceService';
import { AssetStatusButtonGroup } from '../AssetStatusButtonGroup';
import { IStyles } from '../../constants/theme';
import Add from '@mui/icons-material/Add';
import { SectionRow } from '../../components-molecules/section/SectionRow';
import { InfoComponent } from '../../components-atoms/InfoComponent';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { UploadToAzure } from '../../hooks/uploadToAzure';
import { environment } from '../../environment';
import CheckFileExtensions from '../../hooks/checkFileExtensions';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { useNavigate } from 'react-router-dom';
import { ButtonContainedPrimary } from '../../components-atoms/ButtonComponents';

const TOOLBAR_CONFIG: ToolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['BLOCK_TYPE_DROPDOWN', 'INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS',],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Paragraph', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-five' },
    { label: 'Heading Small', style: 'header-six' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'Bullet List', style: 'unordered-list-item' },
    { label: 'Numbered List', style: 'ordered-list-item' }
  ]
};

interface AddArticleModel{
  attachments: File[];
  body: string;
  image: File | null;
  title: string;
  published: any;
}

interface IOption {
  title: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  value: boolean;
}

interface Attachments {
  Name: string;
  Url: string;
  Size: number;
}

interface onProgressResponse {
  fileName: string;
  percentage: number;
}

export function  AddArticle(): JSX.Element {
  const buttonGroupOptions: IOption[] = [
    { title: 'Published', color: 'success', value: true },
    { title: 'Unpublished', color: 'error', value: false }
  ];
  const [errorMessage, ] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [html, setHtml] = useState<EditorValue>(RichTextEditor.createEmptyValue());
  const [attachments, setAttachments] = useState<File[]>([]);
  const [loadingProgress, setLoadingProgress] = useState<onProgressResponse[]>([]);
  const [percentage, setPercentage] = useState(0);
  const [token] = useTypedSelector((state) => [state.userReducer.token]);
  const { control, handleSubmit, formState: { isValid }, setValue } = useForm({ mode: 'all', reValidateMode: 'onChange' });
  const [fileAttachmenErrorMessage, setFileAttachmenErrorMessage] = useState<string|undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    setValue('published', true, { shouldDirty: true, shouldValidate: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    calculatePercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProgress]);
  

  const submit: SubmitHandler<AddArticleModel> = async (data:AddArticleModel) => {
    setLoading(true);
    setScrollToTop(true);
    
    const attachmentFileNames = await UploadToAzure({
      data:attachments,
      onProgress:onProgress,
      folderName: environment.articleFolder
      
    });
    
    const articleImageFileName = await UploadToAzure({
      data:[data.image],
      onProgress:onProgress,
      folderName: environment.articleFolder
      
    });

    const requestAttachments:Attachments[]= [];
    attachmentFileNames.forEach(async(x)=>{
      requestAttachments.push({
        Name:x.Name,
        Size:x.Size,
        Url:x.Url
      });
    });
    
    const imageUrl = articleImageFileName[0].Url;
    if (token) {
      AddNewArticle(token,{
        Title:data.title,
        Description:data.body,
        ImageUrl:imageUrl,
        Published:data.published,
        Attachments: requestAttachments
      },
      () => {
        setLoading(false);
        navigate(-1);
      },
      () => {
        setScrollToTop(false);
        setLoading(false);
      });
      
    }
  };

  const onProgress = (data:onProgressResponse) => {
    const result = loadingProgress?.find(x => data.fileName === x.fileName);
    let tempLoadingProcesses = loadingProgress;

    if (result !== undefined){
      tempLoadingProcesses= tempLoadingProcesses?.map(x => {
        if (x.fileName === data.fileName) {
          return {...x, percentage: data.percentage};
        }else{
          return x;
        }
      });
    } else {
      tempLoadingProcesses?.push(data);
    }
    
    setLoadingProgress(tempLoadingProcesses);
  };

  const calculatePercentage = () => {
    let total = 0;
    loadingProgress.forEach(x=>{
      total += x.percentage / loadingProgress.length;
    });
    
    setPercentage(Math.round(total));
  };

  return (
    <FormPageComponent
      hasError={!!errorMessage}
      title="Add Topic"
      buttonTitle="Submit"
      buttonDisabled={!isValid || loading}
      buttonLoading={loading}
      close={() => navigate(-1)}
      submit={handleSubmit(submit)}
      scrollToTop={scrollToTop}
    >
      <Fragment>
        <Grid item sx={{ flexGrow: 1 }}>
          {!!errorMessage
            && <Alert severity="error">{errorMessage}</Alert>
          }
        </Grid>
        
        {loading && <LinearProgress variant="determinate" color="secondary" value={percentage}  sx={{ zIndex: 2 }} />}
        <ModalCardComponent>
          <SectionHeader title="Topic Image" />
          <Fragment>
            <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
              <Grid item xs={12}>
                <FormControlImage
                  accept="image/*"
                  data-testid="add-article-image"
                  control={control}
                  field={{
                    label: 'Image',
                    name: 'image',
                    inputType: 'logo',
                    validation: {
                      required: REQUIRED_VALIDATION,
                      validate: LOGO_VALIDATION_WITH_TYPE('image/*')
                    }
                  }}
                  title="Image"
                  subtitle="Square image with 640px x 640px resolution works best."
                />
              </Grid>
            </Grid>
          </Fragment>
        </ModalCardComponent>
        
        <ModalCardComponent>
          <SectionHeader title="Topic Content" />
          <Grid item container columnSpacing={4} sx={styles.fg1}>
            <Grid item xs={12} sx={styles.mb16}>
              <FormControlInput
                data-testid="add-article-title"
                control={control}
                field={{
                  label: 'Title',
                  name: 'title',
                  inputType: 'text',
                  validation: {
                    required: REQUIRED_VALIDATION,
                  }
                }}
              /> 
            </Grid>
            <Grid item xs={12}>
              <RichTextEditor
                {...control.register('body', { required: REQUIRED_VALIDATION })}
                value={html}
                toolbarConfig={TOOLBAR_CONFIG}
                placeholder="Body"
                onChange={(val: EditorValue) => {
                  setHtml(val);
                  setValue('body', val.toString('html'), { shouldDirty: true, shouldValidate: true });
                }}
              />
            </Grid>
          </Grid>
        </ModalCardComponent>
        <ModalCardComponent>
          <SectionHeader
            title="File attachments"
            subtitle="Select files you would like to attach to this newsletter. Maximum file size 5MB."
            errorMessage={fileAttachmenErrorMessage}
            button={
              <label htmlFor="inputid">
                <input
                  data-testid="attachments-upload"
                  id="inputid"
                  accept=".pdf, .pptx, .ppt"
                  type="file"
                  multiple
                  onClick={event => {
                    event.currentTarget.files = null;
                    event.currentTarget.value = '';
                  }}
                  style={{ display: 'none' }}
                  {...control.register('attachments', { required: { value: false, message: '' } })}
                  onChange={(e) => {
                    setFileAttachmenErrorMessage(undefined);
                    let fileArray: File[] = Array.prototype.slice.call(e.target.files);
                    const response = CheckFileExtensions(fileArray,'.pdf, .pptx, .ppt');
                    if(response.errorMessage!==null){
                      setFileAttachmenErrorMessage(response.errorMessage);
                    }
                    fileArray = response.allowedFiles.filter(newFile=>{
                      let fileNotExist = true;
                      attachments.forEach(file=>{
                        if(newFile.size === file.size && file.name === newFile.name){
                          fileNotExist = false;
                        }
                      });
                      return fileNotExist;
                    });
                    setAttachments([...attachments, ...fileArray]);
                    setValue('attachments', [...attachments, ...fileArray], { shouldDirty: true, shouldValidate: true });
                  }}
                />
                <ButtonContainedPrimary
                  data-testid="add-attachment"
                  startIcon={<Add />}
                  sx={{ margin: '0 0 0 8px', width: 'max-content', pointerEvents: 'none' }}
                >
                  Add Attachment
                </ButtonContainedPrimary>
              </label>
            } />
          <Grid xs={12} item container sx={{ flexGrow: 1, width: '100%' }}>
            {attachments.length > 0
              ? <>
                {attachments.map((file: File, idx) => (
                  <SectionRow
                    key={idx}
                    title={`File ${idx + 1}`}
                    lines={[
                      { data: file.name, type: 'body1' }
                    ]}
                    endIcon={
                      <RemoveCircleIcon
                        color="primary"
                        fontSize="small"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          const temp = [...attachments].filter(f => attachments.indexOf(f) !== idx);
                          setAttachments(temp);
                          setValue('attachments', temp, { shouldDirty: true, shouldValidate: true });
                        }}
                      />
                    }
                  />
                ))}
              </>
              : <InfoComponent
                title="File attachments"
                description="You haven’t set any file attachments yet. Start by selecting files in a dialog. You can add multiple files at once"
              />
            }
          </Grid>
        </ModalCardComponent>
        <ModalCardComponent>
          <SectionHeader title="Topic Status" />
          <Grid item container columnSpacing={4} sx={styles.fg1}>
            <Grid item xs={12} sx={styles.mb16}>
              <AssetStatusButtonGroup
                subtitle="Published topics are visible to customers and unpublished topics are hidden."
                
                data-testid="add-article-published"
                onChange={(value: boolean) => setValue('published', value, { shouldDirty: true, shouldValidate: true })}
                options={buttonGroupOptions}
              />
            </Grid>
          </Grid>
        </ModalCardComponent>
        {loading ? (
          <div style={styles.overlay}>
          </div>
        ) : (
          <></>
        )}
      </Fragment>
    </FormPageComponent>
  );
}

const styles: IStyles = {
  fg1: { flexGrow: 1 },
  mb16: { marginBottom: '16px' },
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  }
};
