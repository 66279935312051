import Grid from '@mui/material/Grid';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { OverlineSecondary,BodyTwoSecondary } from '../components-atoms/TypographyComponents';

interface EditAttachmentFileComponentProps {
  title?: string;
  fileName: string;
  removeClicked: any;
  url:string
}

export function EditAttachmentFile({ title, fileName, removeClicked, url }: EditAttachmentFileComponentProps): JSX.Element {
  return (
    <Grid container justifyContent="space-between" alignItems="center" sx={{ flexShrink: 1, flexGrow: 1, minHeight: '80px' }}>
      {title
        ? <Grid item sx={{ width: '164px' }}>
          <OverlineSecondary>{title}</OverlineSecondary>
        </Grid>
        : null
      }
      <Grid item container rowGap="4px" justifyContent="space-between" sx={{ flexFlow: 'column', flexGrow: 1, padding: '16px 0', width: '164px', minHeight: '24px' }}>
        <BodyTwoSecondary
          style={{ color: '#012169', cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => window.open(url)}
        >
          {fileName}
        </BodyTwoSecondary>
      </Grid>
      <Grid item sx={{ width: 'auto' }} >
        <RemoveCircleIcon color="primary" fontSize="small" sx={{ cursor: 'pointer' }} onClick={removeClicked} />
      </Grid>
    </Grid>
  );
}
