import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {  CaptionPrimary, CaptionSecondary, HeaderFourSecondary } from '../components-atoms/TypographyComponents';
import { Dashboard } from '../components-organisms/Dashboard';
import { Footer } from '../components-organisms/Footer';
import '../scss/components/_policy-pages.scss';
import { gaService } from '../service/gaService';

export function PrivacyPolicy(): JSX.Element {
  const navigate = useNavigate();
  
  useEffect(() => {
    initOneTrust();
    gaService.pageView('Privacy Policy');
  }, []);


  const initOneTrust = () => {
    const oneTrust: any = window.OneTrust;
    oneTrust.NoticeApi.Initialized.then(async function () {
      oneTrust.NoticeApi.LoadNotices(['https://privacyportalde-cdn.onetrust.com/24527c35-1de1-4aaa-833d-de50b42446bd/privacy-notices/a58effe7-5173-4f06-a9a9-ec6180250fc1.json'], true, 'en-us');
    
    });
  };

  return (
    <Dashboard noDrawer noMargin noPadding fullWidth merchantPage>
      <Grid container direction="column" sx={{ width: '100%' }} className="policy-page">
        <Grid container justifyContent="center">
          <Grid direction="column" container item alignItems="center">
            <Grid sx={{ margin: '12px 0px 28px', maxWidth: '1408px', width: '100%', padding: '0 24px' }}>
              <CaptionPrimary style={{ color: 'rgba(1, 33, 105, 1)', cursor: 'pointer' }} onClick={() => navigate('/')}>Home</CaptionPrimary>
              <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
              <CaptionPrimary style={{ marginBottom: '4px' }}>Privacy Policy</CaptionPrimary>
            </Grid>
            <Grid
              container
              justifyContent="center"
              sx={{
                margin: '90px 0 32px',
                background: 'linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)'
              }}>
              <Grid
                container
                sx={{
                  maxWidth: '1408px',
                  width: '100%',
                  padding: '0 24px'
                }}>
                <HeaderFourSecondary style={{ maxWidth: '783px', width: '100%', marginBottom: '4px' }}>
                  Privacy Policy
                </HeaderFourSecondary>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ color: 'rgba(0, 0, 0, 0.54)', maxWidth: { sm: '672px' }, width: '100%', padding: '0 24px' }}>
            <div className='otnotice-language-dropdown-container'>
              <select id='otnotice-language-dropdown' aria-label='language selector'></select>
            </div>
            <div id='otnotice-a58effe7-5173-4f06-a9a9-ec6180250fc1' className='otnotice'></div>

            <div className='ot-form-wrapper' style={{ maxWidth:'750px', height:'1050px', margin: 'auto', border: '1px solid #c0c2c7'}}>
              <iframe style={{width: '100%', height: '100%', border: 'none'}} src='https://privacyportal-de.onetrust.com/webform/24527c35-1de1-4aaa-833d-de50b42446bd/8c650df7-a9c9-4780-a173-e91886e285fa'>
              </iframe>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{
        background: 'linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Grid item sx={{ maxWidth: '1408px', width: '100%' }}>
          <Footer />
        </Grid>
      </Grid>
    </Dashboard>
  );
}

