import InfiniteScroll from 'react-infinite-scroll-component';

import { CircularProgress, Container, debounce, useMediaQuery } from '@mui/material';

import { SalesManagerListCard } from './cards/SalesManagerListCard';
import { ListDivider } from '../components-molecules/Divider';
import { GroupedSearchSalesManager } from '../service/adminService';
import { LIGHT_THEME } from '../constants/theme';

interface IProps {
  salesManagers: GroupedSearchSalesManager[];
  loadMore: () => void;
  total: number;
  onDelete: () => void;
}

export function SalesManagersList({ salesManagers, loadMore, total, onDelete }: IProps): JSX.Element {
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));

  const getDataLength = () => {
    let total = 0;
    salesManagers.forEach(x => {
      total += x.SalesManagers.length;
    });
    return total;
  };
  
  return (
    <div style={{ paddingBottom: '48px', width: '100%' }}>
      <InfiniteScroll
        style={{ overflowY: 'hidden' }}
        dataLength={getDataLength()} //This is important field to render the next data
        next={() => debounce(() => loadMore(), 300)()}
        scrollThreshold={smallDown ? '550px' : '50px'}
        hasMore={getDataLength() < total}
        loader={
          <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
            <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
          </Container>
        }
      >
        {salesManagers.map((manager, index) => {
          return (
            <ListDivider
              key={index}
              division={{ name: manager.Group, length: manager.SalesManagers.length }}
              caption={{ singular: 'Sales Manager', plural: 'Sales Managers' }}
            >
              {manager.SalesManagers.map(item =>
                <SalesManagerListCard
                  onDelete={onDelete}
                  key={`${item.Id}${new Date()}`}
                  salesmanager={item}
                />
              )}
            </ListDivider>
          );
        })}
      </InfiniteScroll>
    </div>
  );
}
