import { Fragment, useCallback } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';
import HelpIcon from '@mui/icons-material/Help';
import LabelIcon from '@mui/icons-material/Label';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { styled } from '@mui/system';

import { LIGHT_THEME } from '../constants/theme';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { useScrollPosition } from '../hooks/useScrollPosition';

import { Toolbar } from '@mui/material';
import { DrawerList } from '../components-molecules/DrawerList';
import { environment } from '../environment';

export const DrawerComponent = styled(Drawer)<DrawerProps>(() => ({
  borderRadius: 8
}));

interface IAdminServiceIcon {
  [key: string]: JSX.Element;
}

const ADMIN_SERVICE_ICON: IAdminServiceIcon = {
  merchants: <BusinessIcon />,
  newsletters: <EmailIcon />,
  salesmanagers: <AccountCircleIcon />,
  adminusers: <SupervisedUserCircleIcon />,
  editorspick: <LabelIcon />,
  globalemailnotifications: <SettingsApplicationsIcon />,
  merchantregistrations: <NewReleasesIcon/>
};

export function MainDrawer(): JSX.Element {
  const [didInfer, drawerOpen, inferredDevice, services, adminServices, user ] = useTypedSelector((state) =>
    [
      state.drawerReducer.didInfer,
      state.drawerReducer.drawerOpen,
      state.drawerReducer.inferredDevice,
      state.drawerReducer.services,
      state.drawerReducer.adminServices,
      state.userReducer.data,
    ]);
  const { scrollPosition } = useScrollPosition();
  const serviceItems: DrawerItem[]
    = (services.length > 0 ? [{ type: 'head', primaryText: 'Services' }] : []).concat(services.map((category: IServiceByCategory) => {
      return [
        { type: 'subhead', primaryText: category.CategoryName }].concat(
        category.Services.map(service => ({
          type: 'item',
          route: `/admin/servicedetail/${service.ServiceId}`,
          primaryText: service.ServiceName,
          icon: <LabelIcon />
        }))
      );
    }).flat()) as DrawerItem[];

  const adminServiceItems = useCallback(() => adminServices.map(adminService => ({
    type: 'item',
    route: `/admin/${adminService.Route}`,
    primaryText: adminService.AdminServiceName,
    icon: ADMIN_SERVICE_ICON[adminService.Route]
  })) as DrawerItem[],[adminServices]);

  const helpDrawerItem: DrawerItem = {
    type: 'item',
    route: '/admin/help',
    primaryText: 'Help',
    icon: <HelpIcon />
  };

  const tmServices = [{
    type: 'item',
    route: '/admin/merchants',
    primaryText: 'Merchants',
    icon: <BusinessIcon />
  }, {
    type: 'item',
    route: '/admin/newsletters',
    primaryText: 'Email Newsletters',
    icon: <EmailIcon />
  }, 
  helpDrawerItem,
  {
    type: 'head',
    primaryText: 'Services'
  }, {
    type: 'subhead',
    primaryText: 'Work Smarter'
  }, {
    type: 'item',
    route: '/admin/document-repository',
    primaryText: 'Document Library',
    icon: <LabelIcon />
  }, {
    type: 'item',
    route: '/admin/brand-assets',
    primaryText: 'Brand Assets',
    icon: <LabelIcon />
  }, {
    type: 'item',
    route: '/admin/digital-toolkits',
    primaryText: 'Digital Toolkits',
    icon: <LabelIcon />
  },
  {
    type: 'subhead',
    primaryText: 'Think Forward'
  },
  {
    type: 'item',
    route: '/admin/industry-events',
    primaryText: 'Industry Events',
    icon: <LabelIcon />
  }] as DrawerItem[];

  const icDrawerItem: DrawerItem = {
    type: 'item',
    route: environment.industryCloudUrl,
    external: true,
    primaryText: 'Analytics - Industry Cloud',
    icon: <AnalyticsIcon />
  };
  const getDrawerItems = useCallback(() => {
    // Push `Sales Manager`into TM drawer
    if(user?.IsSalesManager) {
      const items = [...tmServices];
      items.splice(1, 0, {
        type: 'item',
        route: '/admin/salesmanagers',
        primaryText: 'Sales Managers',
        icon: <AccountCircleIcon />
      });
      return items;
    }

    switch (user?.Role) {
      case 'Territory Manager':
        return tmServices;
      case 'Super Admin':
        return [
          ...adminServiceItems(),
          icDrawerItem,
          helpDrawerItem,
          ...serviceItems,
        ];
      default:
        return [
          ...adminServiceItems(),
          helpDrawerItem,
          ...serviceItems,
        ];
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user,adminServiceItems]);

  const items = getDrawerItems() as DrawerItem[];

  return (
    (!didInfer)
      ? <Fragment />
      : <Drawer
        anchor="left"
        open={drawerOpen}
        variant={inferredDevice === 'mobile' ? 'temporary' : 'persistent'}
        sx={{ '& .MuiDrawer-paper': { border: 'none', width: 'inherit', overflow: 'hidden', } }}
      >
        <Toolbar sx={{
          height:
            (scrollPosition > (3 + LIGHT_THEME.misc.toolbar.akzonobelHeightNumber))
              ? LIGHT_THEME.misc.toolbar.mainHeight
              : `${3 + LIGHT_THEME.misc.toolbar.akzonobelHeightNumber + LIGHT_THEME.misc.toolbar.mainHeightNumber - scrollPosition}px`
        }} />
        <DrawerList
          sx={{
            overflowY: 'scroll',
            marginTop: (scrollPosition > (3 + LIGHT_THEME.misc.toolbar.akzonobelHeightNumber))
              ? '-3px'
              : '30px'
          }}
          items={items}
        />
      </Drawer >
  );
}
