const INITIAL: LoadingState = {
  loading: false
};

export const loadingReducer = (state: LoadingState = INITIAL, action: LoadingAction | LoadingEndAction): LoadingState => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        ...action.payload
      };
    case 'SET_LOADING_END':
      return INITIAL;
    default:
      return state;
  }
};

