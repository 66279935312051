
import { BlobHTTPHeaders, BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../environment';

type UploadAzureProps = {
  data: any;
  onProgress: any;
  folderName: string;
  isDownloadable?: boolean;
  useDocRepoContainer?: boolean;
};

interface Attachments {
  Name: string;
  Url: string;
  Size: number;
  FileType: string;
}
// If isDownloadable is false file will be opened in a new tab
export function UploadToAzure({ data, onProgress, folderName, isDownloadable = false, useDocRepoContainer = false }: UploadAzureProps): Promise<Attachments[]> {
  const blobServiceClient = BlobServiceClient.fromConnectionString(environment.azureConnectionString);

  const uploadToAzure = (containerClient: ContainerClient, file: File) => {
    const uuid = uuidv4();
    const fileName = `${uuid}_${file.name}`.replaceAll(' ', '_');
    const blobName = `${folderName}/${fileName}`;
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const blobHTTPHeaders: BlobHTTPHeaders | undefined = !isDownloadable ? {
      blobContentType: file.type,
    } : {
      blobContentDisposition: `attachment; filename="${file.name}"`
    };
    return blockBlobClient.upload(file, file.size, {
      onProgress: (ev) => {
        calculatePercentage(fileName, ev.loadedBytes, file.size);
      },
      blobHTTPHeaders
    }).then(() => {
      const uploaded: Attachments = {
        Name: file.name,
        Url: fileName,
        Size: file.size,
        FileType: file.type
      };
      return uploaded;
    });
  };

  const calculatePercentage = (fileName: string, loadedBytes: number, fileSize: number) => {
    onProgress({
      fileName: fileName,
      percentage: Math.round((loadedBytes * 100) / fileSize),
      fileSize,
      loadedBytes,
    });
  };

  let containerClient: ContainerClient;

  if (useDocRepoContainer) {
    containerClient = blobServiceClient.getContainerClient(environment.docRepoContainerName);
  } else {
    containerClient = blobServiceClient.getContainerClient(environment.containerName);
  }

  return new Promise((resolveFunc) => {
    Promise.all(data.map((file: File) => uploadToAzure(containerClient, file))).then(urls => {
      resolveFunc(urls);
    });
  });
}
