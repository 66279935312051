/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { GetAvailableServices, GetEditorsPickByUserRequest, GetHomepageRequest, GetRecentBenefitsRequest, HomepageIndustryInsights, HomepageIndustryInsightsRequest } from '../service/serviceService';
import { Divider, Grid, Box } from '@mui/material';
import { MainBar } from '../components-organisms/appbars/MainBar';
import { SearchWithSuggestion } from '../components-organisms/SearchWithSuggestion';
import { Carousel } from '../components-organisms/Carousel';
import { Categories } from '../components-organisms/Categories';
import { EditorsPick } from '../components-organisms/EditorsPick';
import { LatestInsights } from '../components-organisms/LatestInsights';
import { DidYouKnow } from '../components-organisms/DidYouKnow';
import { LearnMore } from '../components-organisms/LearnMore';
import { RecentBenefits } from '../components-organisms/RecentBenefits';
import { RecentSearches } from '../components-organisms/RecentSearches';
import { Footer } from '../components-organisms/Footer';
import { TutorialDialog } from '../components-organisms/modals/TutorialDialog';

import '../scss/layout/_grid.scss';
import { gaService } from '../service/gaService';

export function Frontpage(): JSX.Element {
  const location = useLocation();
  const showTutorial = location.state?.showTutorial ? location.state.showTutorial : false;
  window.history.replaceState({}, document.title);

  const [userData, token, superUser] = useTypedSelector((state) => [state.userReducer.data, state.userReducer.token, state.userReducer.super]);
  const [services, setServices] = useState<IServiceByCategory[]>([]);
  const [editorsPick, setEditorsPick] = useState<EditorsPickByUser[]>([]);
  const [recentBenefits, setRecentBenefits] = useState<RecentBenefit[]>([]);
  const [insights, setInsights] = useState<HomepageIndustryInsights[]>([]);
  const [knowledgeBaseService, setKnowledgeBaseService] = useState<any>({});
  const [homepageResult, setHomepageResult] = useState<any>({
    articles: [],
    blogPosts: [],
    industryInsights: [],
    products: [],
    webinars: []
  });
  const navigate = useNavigate();
  const canSeeFrontPage = useMemo(() => userData?.IsTerritoryManager || userData?.IsSalesManager || superUser, [userData]);

  useEffect(() => {
    gaService.pageView('Home');
  }, []);

  useEffect(() => {
    if(userData && userData.IsAdmin && !canSeeFrontPage) {
      navigate('/admin');
    }
  }, [navigate, superUser, userData, location]);

  useEffect(() => {
    if (token && (userData && (!userData.IsAdmin || canSeeFrontPage))) {
      GetAvailableServices(
        token,
        (response) => {
          setServices(response.Result);
          const industryInsightService = response.Result.map(m => m.Services).flat().find(f => f.ServiceName === 'Industry Insights');
          setKnowledgeBaseService(response.Result.map(m => m.Services).flat().find(f => f.ServiceName === 'Knowledge Base'));
          if (industryInsightService) {
            HomepageIndustryInsightsRequest(
              token,
              3,
              industryInsightService.ServiceId,
              (response) => setInsights(response.Result),
              
              () => {}
            );
          }
        },
        () => {}
      );
      GetEditorsPickByUserRequest(
        token,
        (response) => setEditorsPick(response.Result),
        () => {}
      );
      GetRecentBenefitsRequest(
        token,
        (response) => setRecentBenefits(response.Result),
        () => {}
      );
      GetHomepageRequest(
        token,
        (response) => {
          const result = JSON.parse(response.Result);
          setHomepageResult({ ...result });
        },
        () => {}
      );
    }
  }, [token, superUser,userData]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ position: 'sticky', top: '-36px', zIndex: (theme: any) => theme.zIndex.drawer + 1 }}>
        <MainBar merchantPage />
      </Box>
      <Grid sx={{ margin: '60px auto 0px', padding: '0 24px', maxWidth: '1408px' }}>
        <Grid item container xs={12} sx={{ width: '100%' }}>
          <Grid item sx={{ width: '100%', marginTop: '32px' }}>
            <SearchWithSuggestion categoryId={knowledgeBaseService?.CategoryId} serviceId={knowledgeBaseService?.ServiceId} />
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <Carousel services={services} />
          </Grid>
          <Divider sx={{ width: '100%' }} />
          {services.length > 0 && (
            <>
              <Grid item sx={{ width: '100%' }}>
                <Categories categories={services.map(m => ({ Name: m.CategoryName, Id: m.Services[0].CategoryId }))} />
              </Grid>
              <Divider sx={{ width: '100%' }} />
            </>
          )}
          {editorsPick.length > 0 && (
            <>
              <Grid item sx={{ width: '100%' }}>
                <EditorsPick data={editorsPick} />
              </Grid>
              <Divider sx={{ width: '100%' }} />
            </>
          )}
          {insights.length > 0 && (
            <>
              <Grid item sx={{ width: '100%' }}>
                <LatestInsights insights={insights} serviceId={services.map(m => m.Services).flat().find(f => f.ServiceName === 'Industry Insights')?.ServiceId || ''} />
              </Grid>
              <Divider sx={{ width: '100%' }} />
            </>
          )}
          {homepageResult.articles.length > 0 && (
            <>
              <Grid item sx={{ width: '100%' }}>
                <DidYouKnow articles={homepageResult.articles} />
              </Grid>
              <Divider sx={{ width: '100%' }} />
            </>
          )}
          {homepageResult.products.length > 0 && (
            <>
              <Grid item sx={{ width: '100%' }}>
                <LearnMore products={homepageResult.products} />
              </Grid>
              <Divider sx={{ width: '100%' }} />
            </>
          )}
          {recentBenefits.length > 0 && (
            <>
              <Grid item sx={{ width: '100%' }}>
                <RecentBenefits data={recentBenefits} />
              </Grid>
              <Divider sx={{ width: '100%' }} />
            </>
          )}
          <Grid item sx={{ width: '100%' }}>
            <RecentSearches />
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{
        background: 'linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 auto'
      }}>
        <Grid item sx={{ maxWidth: '1408px', width: '100%' }}>
          <Footer />
        </Grid>
      </Grid>
      <TutorialDialog showDialog={showTutorial} videoSrc="https://hncdev.azureedge.net/merchantconnect-dev/brand_assets_images/p_8445ef08-15ed-49f2-977e-53cf3bf3fb7a_Connect_for_Merchant_Overview.mp4" videoPoster="https://hncdev.azureedge.net/merchantconnect-prod/help_videos/connect_for_merchants_overview.jpg" />
    </Box>
  );
}
