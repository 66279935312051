import { CircularProgress, Container, Grid, useMediaQuery } from '@mui/material';
import { ListDivider } from '../components-molecules/Divider';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';
import { LIGHT_THEME } from '../constants/theme';
import { IndustryInsightListCard } from './cards/IndustryInsightListCard';

interface IProps {
  insights: IndustryInsightItem[];
  onEdit: (id: string) => void;
  onPublishChange: (id: string) => void;
  onDelete: (id: string) => void;
  loadMore: () => void;
  total: number;
}

export function IndustryInsightList({ insights, onEdit, onPublishChange, onDelete, loadMore, total }: IProps): JSX.Element {
  const timeoutDuration = 300;
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  
  return (
    <InfiniteScroll
      style={{ overflowY: 'hidden' }}
      dataLength={insights.map(m => m.IndustryInsight).flat().length} //This is important field to render the next data
      next={() => debounce(() => loadMore(), timeoutDuration)()}
      scrollThreshold={smallDown ? '550px' : '50px'}
      hasMore={insights.map(m => m.IndustryInsight).flat().length < total}
      loader={
        <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
          <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
        </Container>
      }
    >
      {insights.map((insight) => {
        return (
          <ListDivider
            key={insight.Date}
            division={{ name: insight.Date, length: insight.IndustryInsight.length }}
            caption={{ singular: 'Insight', plural: 'Insights' }}
            halfMargin
            noBottomPadding
          >
            <Grid container item xs={12} columnSpacing={2} rowSpacing={3} sx={{ width: '100%', margin: 0 }}>
              {insight.IndustryInsight.map(item =>
                <IndustryInsightListCard
                  key={item.Id}
                  insight={item}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onPublishChange={onPublishChange}
                />
              )}
            </Grid>
          </ListDivider>
        );
      })}
    </InfiniteScroll>
  );
}
