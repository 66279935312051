/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const isBrandAssetFilterApplied = (savedFilter: any): boolean => {
  const hasAssetType = savedFilter['Asset Type']?.length > 0;
  const hasBrand = savedFilter.Brand?.length > 0;
  const hasColour = savedFilter.Colour?.length > 0;
  const hasFileType = savedFilter['File Type']?.length > 0;
  const hasOrientation = savedFilter.Orientation?.length > 0;
  const hasProduct = savedFilter.Product?.length > 0;
  const hasSize = savedFilter.Size?.length > 0;

  return hasAssetType || hasBrand || hasColour || hasFileType || hasOrientation || hasProduct || hasSize;
};
