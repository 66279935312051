import { Grid } from '@mui/material';
import { useNavigate } from 'react-router';
import { BodyTwoSecondary, HeaderSix, OverlineSecondary, SubtitleOnePrimary } from '../components-atoms/TypographyComponents';
import '../scss/components/_categories.scss';
import '../scss/components/_thumbnail.scss';
interface IProps {
  data: RecentBenefit[];
}

export function RecentBenefits({ data }: IProps): JSX.Element {
  const navigate = useNavigate();
  return (
    <>
      <section className="recent-items">
        <div className="recent-items__title" style={{ marginBottom: '8px' }}>
          <HeaderSix >Recent Services</HeaderSix>
        </div>
        <Grid container item columnSpacing={20 / 8} alignItems="center">
          {data.map(benefit => (
            <Grid key={benefit.ServiceId} className="recent-items__content" item lg={4} xl={4} xs={12} md={6} sm={6} >
              <a
                onClick={() => navigate(`/service/${benefit.ServiceId}`)}
                className="recent-item recent-item--link"
              >
                <img
                  srcSet={`
                    ${require(`../assets/img/category/${benefit.ImageUrl}.png`)} 1x, 
                    ${require(`../assets/img/category/${benefit.ImageUrl}@2x.png`)} 2x,
                    ${require(`../assets/img/category/${benefit.ImageUrl}@3x.png`)} 3x
                  `}
                  src={require(`../assets/img/category/${benefit.ImageUrl}@3x.png`)}
                  alt={`${benefit.CategoryName} logo`}
                  className="recent-item__media thumb thumb--large"
                />
                <div className="recent-item__content benefit__content">
                  <OverlineSecondary>
                    {benefit.CategoryName}
                  </OverlineSecondary>
                  <SubtitleOnePrimary>
                    {benefit.ServiceName}
                  </SubtitleOnePrimary>
                  <BodyTwoSecondary className="u-text-truncate-multi">
                    {benefit.Description}
                  </BodyTwoSecondary>
                </div>
              </a>
            </Grid>
          ))}
        </Grid>
      </section>
    </>
  );
}
