/* eslint-disable @typescript-eslint/naming-convention */
import { Grid, Alert, LinearProgress } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FormControlImage, FormControlInput } from '../../components-molecules/FormControl';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { IStyles } from '../../constants/theme';
import { environment } from '../../environment';
import { REQUIRED_VALIDATION, FILE_VALIDATION_WITH_TYPE } from '../../forms/_predefinedValidations';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { UploadToAzure } from '../../hooks/uploadToAzure';
import { CreateIndustryInsightRequest } from '../../service/serviceService';
import { AssetStatusButtonGroup } from '../AssetStatusButtonGroup';

interface AddInsightModel {
  pdf: File;
  image: File;
  title: string;
  description?: string;
  published: boolean;
}

interface IOption {
  title: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  value: boolean;
}

interface onProgressResponse {
  fileName: string;
  percentage: number;
}

export function AddInsight(): JSX.Element {
  const buttonGroupOptions: IOption[] = [
    { title: 'Published', color: 'success', value: true },
    { title: 'Unpublished', color: 'error', value: false }
  ];
  const token = useTypedSelector(state => state.userReducer.token);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { control, handleSubmit, formState: { isValid }, setValue } = useForm({ mode: 'all', reValidateMode: 'onChange' });
  const [loading, setLoading] = useState(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [loadingProgress, setLoadingProgress] = useState<onProgressResponse[]>([]);
  const [percentage, setPercentage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setValue('published', true, { shouldDirty: true, shouldValidate: true });
  }, [setValue]);

  useEffect(() => {
    calculatePercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProgress]);

  const submit: SubmitHandler<AddInsightModel> = async (data) => {
    if (token) {
      setLoading(true);
      setScrollToTop(true);

      const insightImageFileName = await UploadToAzure({
        data: [data.image],
        onProgress: onProgress,
        folderName: environment.insightFolder
      });

      const imageUrl = insightImageFileName[0].Url;

      const attachmentFileName = await UploadToAzure({
        data: [data.pdf],
        onProgress: onProgress,
        folderName: environment.insightFolder
      });

      const pdfUrl = attachmentFileName[0].Url;

      CreateIndustryInsightRequest(
        token,
        {
          ImageUrl: imageUrl,
          PdfUrl: pdfUrl,
          Title: data.title,
          Description: data.description,
          Published: data.published
        },
        () => {
          setLoading(false);
          navigate(-1);
        },
        (error) => {
          setErrorMessage(error.response?.data.Error || '');
          setLoading(false);
          setScrollToTop(false);
        }
      );
    }
  };

  const onProgress = (data: onProgressResponse) => {
    const result = loadingProgress?.find(x => data.fileName === x.fileName);
    let tempLoadingProcesses = loadingProgress;

    if (result !== undefined) {
      tempLoadingProcesses= tempLoadingProcesses?.map(x => {
        if (x.fileName === data.fileName) {
          return {...x, percentage: data.percentage};
        } else {
          return x;
        }
      });
    } else {
      tempLoadingProcesses?.push(data);
    }
    setLoadingProgress(tempLoadingProcesses);
  };

  const calculatePercentage = () => {
    let total = 0;
    loadingProgress.forEach(x=>{
      total += x.percentage / loadingProgress.length;
    });
    setPercentage(Math.round(total));
  };

  return (
    <FormPageComponent
      hasError={!!errorMessage}
      title="Post Insight"
      buttonTitle="Post"
      buttonDisabled={!isValid || loading}
      buttonLoading={loading}
      close={() => navigate(-1)}
      submit={handleSubmit(submit)}
      scrollToTop={scrollToTop}
    >
      {loading
        ? <LinearProgress variant="determinate" color="secondary" value={percentage}  sx={{ zIndex: 2 }} />
        : <></>
      }
      <Fragment>
        <Grid item sx={{ flexGrow: 1 }}>
          {!!errorMessage
            && <Alert severity="error">{errorMessage}</Alert>
          }
        </Grid>
        <ModalCardComponent>
          <SectionHeader title="Insight details" />
          <Fragment>
            <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
              <Grid item xs={12}>
                <FormControlImage
                  key="pdf"
                  accept=".pdf"
                  data-testid="add-insight-pdf"
                  control={control}
                  field={{
                    label: 'PDF File',
                    name: 'pdf',
                    inputType: 'logo',
                    validation: {
                      required: REQUIRED_VALIDATION,
                      validate: FILE_VALIDATION_WITH_TYPE(50,'.pdf')
                    }
                  }}
                  title="PDF File"
                  subtitle="Maximum file size 50 MB."
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlImage
                  key="image"
                  accept="image/*"
                  data-testid="add-insight-image"
                  control={control}
                  field={{
                    label: 'Preview image',
                    name: 'image',
                    inputType: 'logo',
                    validation: {
                      required: REQUIRED_VALIDATION,
                      validate: FILE_VALIDATION_WITH_TYPE(5,'image/*')
                    }
                  }}
                  title="Preview Image"
                  subtitle="JPG file exported from the original PDF works best. Maximum file size 5 MB."
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlInput
                  data-testid="add-insight-title"
                  control={control}
                  field={{
                    label: 'Title',
                    name: 'title',
                    inputType: 'text',
                    validation: {
                      required: REQUIRED_VALIDATION,
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlInput
                  data-testid="add-insight-description"
                  control={control}
                  multiline
                  field={{
                    label: 'Description',
                    name: 'description',
                    inputType: 'text',
                    validation: {
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ margin: '32px 0 0' }}>
                <AssetStatusButtonGroup
                  subtitle="Published insights are visible to customers and unpublished insights are hidden."
                  title="Insight Status"
                  data-testid="add-insight-published"
                  onChange={(value: boolean) => setValue('published', value, { shouldDirty: true, shouldValidate: true })}
                  options={buttonGroupOptions}
                />
              </Grid>
            </Grid>
          </Fragment>
        </ModalCardComponent>
        {loading ? (
          <div style={styles.overlay}>
          </div>
        ) : (
          <></>
        )}
      </Fragment>
    </FormPageComponent>
  );
}

const styles: IStyles={
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  }
};
