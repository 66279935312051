import { Box } from '@mui/material';
import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ButtonContainedPrimary } from '../components-atoms/ButtonComponents';
import { InfoComponent } from '../components-atoms/InfoComponent';
import { BodyOneSecondary } from '../components-atoms/TypographyComponents';
import { CombinationRow } from '../components-molecules/CombinationRow';
import { MiniDialog } from '../components-molecules/dialogs/MiniDialog';
import { FormControlSelect } from '../components-molecules/FormControl';
import { Section } from '../components-molecules/section/Section';
import { SectionHeader } from '../components-molecules/section/SectionHeader';
import { AccessFormInputTypes, ACCESS_FORM } from '../forms/access';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import AddIcon from '@mui/icons-material/Add';
import TabPanel from '@mui/lab/TabPanel';
import {
  AddServiceAccessRightRequest,
  DeleteServiceAccessRightRequest,
} from '../service/serviceService';

interface IProps {
  serviceId: GUID;
  getService: () => void;
  noTab?: boolean;
}

export function AccessRightsManagement({ serviceId, getService, noTab }: IProps): JSX.Element {
  const {
    control,
    reset,
    formState: { isValid },
    handleSubmit,
  } = useForm({ mode: 'all', reValidateMode: 'onBlur' });
  const [dialog, setDialog] = useState(false);
  const token = useTypedSelector((state) => state.userReducer.token);
  const accessRights = useTypedSelector((state) => state.accessRightsReducer.currentData);
  const addAccessRight: SubmitHandler<AccessFormInputTypes> = (data): void => {
    if (token) {
      AddServiceAccessRightRequest(
        token,
        serviceId,
        {
          Audience: data.audienceName,
          Role: data.role,
        },
        () => getService(),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
    reset();
    setDialog(false);
  };
  const deleteAccessRight = (id: GUID) => {
    if (token) {
      DeleteServiceAccessRightRequest(
        token,
        id,
        () => getService(),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  return (
    <>{
      noTab
        ? (<div style={{ paddingBottom: '48px' }}>
          <Section>
            <SectionHeader
              title="Service Access Rights"
              subtitle="Set Merchant Audience and User Role combinations to enable users access to the content of this service."
              button={
                <ButtonContainedPrimary
                  startIcon={<AddIcon />}
                  sx={{ maxWidth: '20%' }}
                  onClick={() => setDialog(true)}
                >
                  Add Access
                </ButtonContainedPrimary>
              }
            />
            <>
              {accessRights.length > 0 ? (
                <Box sx={{ margin: '24px 0 0' }}>
                  <CombinationRow
                    columnTitles={{
                      AudienceName: 'Audience',
                      UserRoleName: 'User Role',
                    }}
                    dataset={accessRights}
                    onDelete={(id) => deleteAccessRight(id as GUID)}
                  />
                </Box>
              ) : (
                <Box sx={{ margin: '24px 0 16px' }}>
                  <InfoComponent
                    title="Access Rights"
                    description="You haven’t set any access rights for this service yet. Start adding access by defining the first combination of merchant audience and user role."
                  />
                </Box>
              )}
            </>
          </Section>

          <MiniDialog
            title="Add Access Rights"
            open={dialog}
            disabled={!isValid}
            close={() => {
              setDialog(false);
              reset();
            }}
            submit={handleSubmit(addAccessRight)}
          >
            <Box sx={{ padding: '0 24px' }}>
              <BodyOneSecondary>
                Set a combination of Merchant Audience and User role to grant users
                access to this service.
              </BodyOneSecondary>

              {ACCESS_FORM.map((field, index) => (
                <FormControlSelect key={index} control={control} field={field} />
              ))}
            </Box>
          </MiniDialog>
        </div>)
        : (<TabPanel value="accessRights" sx={{ padding: 0 }}>
          <Section>
            <SectionHeader
              title="Service Access Rights"
              subtitle="Set Merchant Audience and User Role combinations to enable users access to the content of this service."
              button={
                <ButtonContainedPrimary
                  startIcon={<AddIcon />}
                  sx={{ maxWidth: '20%' }}
                  onClick={() => setDialog(true)}
                >
                  Add Access
                </ButtonContainedPrimary>
              }
            />
            <>
              {accessRights.length > 0 ? (
                <Box sx={{ margin: '24px 0 0' }}>
                  <CombinationRow
                    columnTitles={{
                      AudienceName: 'Audience',
                      UserRoleName: 'User Role',
                    }}
                    dataset={accessRights}
                    onDelete={(id) => deleteAccessRight(id as GUID)}
                  />
                </Box>
              ) : (
                <Box sx={{ margin: '24px 0 16px' }}>
                  <InfoComponent
                    title="Access Rights"
                    description="You haven’t set any access rights for this service yet. Start adding access by defining the first combination of merchant audience and user role."
                  />
                </Box>
              )}
            </>
          </Section>

          <MiniDialog
            title="Add Access Rights"
            open={dialog}
            disabled={!isValid}
            close={() => {
              setDialog(false);
              reset();
            }}
            submit={handleSubmit(addAccessRight)}
          >
            <Box sx={{ padding: '0 24px' }}>
              <BodyOneSecondary>
                Set a combination of Merchant Audience and User role to grant users
                access to this service.
              </BodyOneSecondary>

              {ACCESS_FORM.map((field, index) => (
                <FormControlSelect key={index} control={control} field={field} />
              ))}
            </Box>
          </MiniDialog>
        </TabPanel>)
    }
    </>

  );
}
