import { styled } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import List, { ListProps } from '@mui/material/List';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemIcon, { ListItemIconProps } from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { LIGHT_THEME } from '../constants/theme';

const ListComponent = styled(List)<ListProps>(() => ({
  margin: '12px 0 8px',
  padding: '0'
}));

const ListItemComponent = styled(ListItem)<ListItemProps>(() => ({
  marginTop: '0',
  padding: '0'
}));

const ListItemIconComponent = styled(ListItemIcon)<ListItemIconProps>(() => ({
  height: '20px',
  width: '20px',
  minWidth: '0',
  margin: '0 16px 0 0'
}));

interface TextListComponentProps {
  lines: string[];
}

export function TextListComponent({ lines }: TextListComponentProps): JSX.Element {
  return (
    <ListComponent>
      {lines.map((item, index) => {
        return (
          <ListItemComponent key={index}>
            <ListItemIconComponent>
              <CheckIcon sx={{ height: 'inherit', width: 'inherit', fill: LIGHT_THEME.palette.primary.main }} />
            </ListItemIconComponent>
            <ListItemText primary={item} primaryTypographyProps={{ variant: 'body2' }} />
          </ListItemComponent>
        );
      })}
    </ListComponent>
  );
}
