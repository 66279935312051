/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Grid, styled } from '@mui/material';
import { GetDigitalAssetsRequest } from '../service/serviceService';
import presentationIcon from '../assets/icons/Presentationfile.svg';
import otherIcon from '../assets/icons/Otherfile.svg';
import videoIcon from '../assets/icons/Videofile.svg';
import imageIcon from '../assets/icons/Imagefile.svg';
import { LIGHT_THEME } from './theme';

export const DEFAULT_BRAND_ASSET_SORT = 'Created';
export const SORT_TYPES = [
  // {
  //   name: 'Most Popular Assets',
  //   value: 'MostPopular'
  // },
  {
    name: 'Best Match',
    value: 'BestMatch'
  },
  {
    name: 'Brand',
    value: 'Brand'
  },
  {
    name: 'Date Added',
    value: 'Created'
  },
  {
    name: 'Asset Type',
    value: 'Type'
  },
  {
    name: 'Orientation',
    value: 'Orientation'
  },
  {
    name: 'Publish State',
    value: 'Published'
  },
];

export const DIGITAL_ASSETS_INITIAL_FILTERS = {
  Brands: [],
  Products: [],
  Colours: [],
  AssetTypes: [],
  FileTypes: [],
  Orientations: [],
  Sizes: []
};

export const getDigitalAssets = (t: string, s: any, callback: (res: any)=> void) => GetDigitalAssetsRequest(
  t,
  {
    Offset: s.Offset || 0,
    PageSize: 24,
    Size: s.Size ? s.Size[0] : undefined,
    // SizeFrom: sizeFrom ? sizeFrom : undefined,
    // SizeTo: sizeTo ? sizeTo : undefined,
    Query: s.Query,
    OrderBy: s.OrderBy,
    ProductIds: s.Product,
    ColourIds: s.Colour,
    BrandIds: s.Brand,
    OrientationIds: s.Orientation,
    ShareToken: s.ShareToken,
    FileTypeIds: s['File Type'],
    DigitalAssetTypeIds: s['Asset Type'],
  },
  (response) => {
    callback(response.Result);
  },
  () => {}
);

export const mapFilterOption = (filters: DigitalAssetFilter | any, key: keyof DigitalAssetFilter) =>
  filters[key].map((m: any) => ({ name: m.Title, value: m.Value }));

// DigitalAssetFilter
export const mapFilterOptions = (prev: any, filters: any) => 
  ({
    ...prev,
    Brand: mapFilterOption(filters, 'Brands'),
    Product: mapFilterOption(filters, 'Products'),
    Colour: mapFilterOption(filters, 'Colours'),
    ['Asset Type']: mapFilterOption(filters, 'AssetTypes'),
    ['File Type']: mapFilterOption(filters, 'FileTypes'),
    Orientation: mapFilterOption(filters, 'Orientations'),
  });

// TODO: any
export function getTargetSrc(currentDigitalAsset: any, digitalAsset: any) {
  if (currentDigitalAsset !== undefined) {
    switch (currentDigitalAsset.FileType) {
      case 'Video':
        return videoIcon;
      case 'Presentation':
        return presentationIcon;
      case 'Image':
        return imageIcon;
      default:
        return otherIcon;
    }
  } else if (digitalAsset !== undefined) {
    switch (digitalAsset?.DigitalAssetFileType) {
      case 'Video':
        return videoIcon;
      case 'Presentation':
        return presentationIcon;
      case 'Image':
        return imageIcon;
      default:
        return otherIcon;
    }
  } else {
    return otherIcon;
  }
}

export const HeaderContainer = styled(Grid)(({ scrollTop }:{ scrollTop: number}) => ({
  width: '100%',
  zIndex: 99,
  background: 'white',
  boxShadow: scrollTop <= 60 ? 'none' : '0 3px 3px 0 rgb(51 51 51 / 11%)',
}));

export const CardContainer = styled(Grid)(() => ({
  isSelected
} : {
  isSelected: boolean
}) => ({
  transition: 'all 0.3s',
  overflow: 'hidden',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  cursor: 'pointer',
  borderRadius: 8,
  padding:  '8px 8px 12px 8px',
  backgroundColor: isSelected ? '#e8ebf1' : 'white',
  '&:hover': {
    backgroundColor: isSelected ? '#e8ebf1' : '#f0f2f5'
  }
}));

export function clearSelectedAssets(dispatch: any) {
  dispatch({
    type: 'SET_DIGITAL_ASSET_SELECT', 
    payload: { 
      selectedAssets: []
    }
  });
}

export function getSelectedValuesForInput(key: string, filterOptions: any, saved: any): string {
  if(!filterOptions || !key || !saved) { return ''; }
  return filterOptions[key]
    .filter((o: any) => saved?.includes(o.value))
    .map((f: any) => f.name)
    .join(', ');
}

export const SORT_LABELS: any = {
  // MostPopular: 'Most Popular Assets',
  Brand: 'Assets by Brand',
  Created: 'Assets by Create Date',
  Type: 'Assets by Type',
  Orientation: 'Assets by Orientation',
  Published: 'Assets by Publish Date',
  Query: 'Search Results',
  Share: 'Shared Brand Assets',
  BestMatch: 'Search Results'
};

export function getSortTitle(key: string) {
  return SORT_LABELS[key];
}

export const DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  day: '2-digit',
  month: 'long',
};

export const AssetDialogImage = styled('img')({
  maxWidth: '100vw',
  maxHeight: '100vh',
  objectFit: 'contain',
  transition: 'all 0.4s',
  [LIGHT_THEME.breakpoints.down('sm')]: {
    maxHeight: 'calc(100vh - 64px)'
  },
});

export const getFileNameFromUrl = (url: string) => url.split('/').pop();

// TODO: any
export const mergeListHeaders = (res: any, prevData: any) => {
  let temp = [...prevData];
  res.DigitalAssets.map((m: any) => {
    const tempMatch = temp.find((f) => f.GroupName === m.GroupName);
    if (tempMatch) {
      temp[temp.indexOf(tempMatch)].DigitalAssets = [...temp[temp.indexOf(tempMatch)].DigitalAssets, ...m.DigitalAssets];
    } else {
      temp = [...temp, m];
    }
  });

  return temp;
};
