import { useCallback, useEffect, useState } from 'react';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { NoItems } from '../components-molecules/NoItems';
import { TabPanel } from '@mui/lab';
import { ChangeInsightPublishStateRequest, DeleteInsightRequest, GetIndustryInsightRequest, GetIndustryInsightsRequest } from '../service/serviceService';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { IndustryInsightList } from '../components-organisms/IndustryInsightList';
import { MiniDialog } from '../components-molecules/dialogs/MiniDialog';
import { Box, CircularProgress } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { BodyOneSecondary } from '../components-atoms/TypographyComponents';
import { ISelectedOptions } from '../components-molecules/FilterMenu';
import { LIGHT_THEME } from '../constants/theme';
import { useNavigate } from 'react-router-dom';
import { SearchbarWithDeboubce } from '../components-molecules/SearchBarWithDebounce';

export function IndustryInsights(): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const pageSize = 24;
  const [insights, setInsights] = useState<IndustryInsightItem[]>([]);
  const [page, setPage] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>();
  const [total, setTotal] = useState(0);
  const filterOptions = { 'Publish State': [{ title: 'Published', value: 'published' }, { title: 'Unpublished', value: 'unpublished' }] };
  const [filter, setFilter] = useState<ISelectedOptions>({ 'Publish State': [] });
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const loadMore = () => {
    if (token) {
      getInsights(token, page + 1, pageSize, insights);
      setPage(page + 1);
    }
  };

  const getInsights = useCallback((t: string, page = 0, size = pageSize, prevData: IndustryInsightItem[] = []): void => {
    setLoading(true);
    GetIndustryInsightsRequest(
      t,
      {
        Page: page,
        Size: size,
        Query: query,
        Published: filter['Publish State'].includes('published'),
        Unpublished: filter['Publish State'].includes('unpublished')
      },
      (response) => {
        if (response) {
          setTotal(response.Result.Count);
          let temp = [...prevData];
          response.Result.Result.map(m => {
            const tempMatch = temp.find((f) => f.Date === m.Date);
            if (tempMatch) {
              temp[temp.indexOf(tempMatch)].IndustryInsight = [...temp[temp.indexOf(tempMatch)].IndustryInsight, ...m.IndustryInsight];
            } else {
              temp = [...temp, m];
            }
          });
          setInsights(temp);
          setLoading(false);
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  }, [query, filter]);

  const deleteInsight = (id: string) => {
    if (token) {
      DeleteInsightRequest(
        token,
        id,
        () => setRefresh(!refresh),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  const changePublishState = (id: string) => {
    if (token) {
      ChangeInsightPublishStateRequest(
        token,
        {
          Id: id
        },
        () => setRefresh(!refresh),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  const setCurrentInsight = (id: string) => {
    if (token) {
      GetIndustryInsightRequest(
        token,
        id,
        (response) => {
          dispatch({ type: 'SET_CURRENT_INDUSTRY_INSIGHT', payload: { currentData: response.Result } });
          navigate('/admin/edit-insight');
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  useEffect(() => {
    if (token) getInsights(token);
  }, [token, query, refresh, getInsights]);

  const searchOnChange =(text:string)=>{
    setQuery(text);
  };

  return (
    <TabPanel value="content" sx={{ padding: 0 }}>
      <PageHeader
        buttonTitle={'Post Insight'}
        activeButtons={true}
        modalCallback={() => navigate('/admin/add-insight')}
        onClickSearch={() => setSearchOpen(!searchOpen)}
        hasFilter
        noSort
        filterOptions={filterOptions}
        onFilterSubmit={(selected) => setFilter(selected)}
        isAdmin={true}
      />
      <SearchbarWithDeboubce
        isOpen={searchOpen}
        query={query}
        placeholder="Search Insights"
        onCancel={() => { setSearchOpen(false); setQuery(''); }}
        onChange={searchOnChange}
      />
      {loading && page === 0
        ? <CircularProgress
          color='primary'
          size={50}
          style={{ zIndex: 999, position: 'absolute', top: '50vh', right: '40vw' }}
        />
        : insights.length > 0
          ? <IndustryInsightList
            total={total}
            insights={insights}
            loadMore={loadMore}
            onEdit={(id) => {
              setCurrentInsight(id);

            }}
            onDelete={(id) => {
              setSelectedId(id);
              setModalOpen(true);
            }}
            onPublishChange={(id) => changePublishState(id)}
          />
          : <NoItems
            icon={<ListAltIcon sx={{ fontSize: 88, fill: LIGHT_THEME.palette.primary.main }} />}
            title="No insights posted yet"
            description="You haven’t posted any insights yet. Start by posting the first insight."
          />
      }
      {modalOpen
        ? <MiniDialog
          title="Delete Insight"
          open={modalOpen}
          disabled={!selectedId}
          close={() => {
            setModalOpen(false);
            setSelectedId(undefined);
          }}
          remove={() => {
            if (selectedId) {
              deleteInsight(selectedId);
            }
            setSelectedId(undefined);
            setModalOpen(false);
          }}>
          <Box sx={{ padding: '0 24px' }}>
            <BodyOneSecondary>
              Are you sure that you want to remove this Insight?
            </BodyOneSecondary>
          </Box>
        </MiniDialog>
        : null
      }
    </TabPanel>
  );
}
