/* eslint-disable @typescript-eslint/naming-convention */
import { Alert, Grid, LinearProgress } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate,  } from 'react-router-dom';
import RichTextEditor, { EditorValue, ToolbarConfig } from 'react-rte';
import { FormControlInput } from '../components-molecules/FormControl';
import { FormPageComponent } from '../components-molecules/FormPageComponent';
import { ModalCardComponent } from '../components-molecules/Modal';
import { SectionHeader } from '../components-molecules/section/SectionHeader';
import { SectionRow } from '../components-molecules/section/SectionRow';
import { IStyles } from '../constants/theme';
import { REQUIRED_VALIDATION } from '../forms/_predefinedValidations';
import CheckFileExtensions from '../hooks/checkFileExtensions';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { InfoComponent } from '../components-atoms/InfoComponent';
import Add from '@mui/icons-material/Add';
import { AddThreadComment, CreateThreadAttachment } from '../service/serviceService';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { UploadToAzure } from '../hooks/uploadToAzure';
import BrandAssetFileTypes from '../utils/BrandAssetsSupportedFileTypes';
import { ButtonContainedPrimary } from '../components-atoms/ButtonComponents';

interface onProgressResponse {
  fileName: string;
  percentage: number;
}
interface Attachments {
  Name: string;
  Url: string;
  Size: number;
  FileType:string
}
export default function NewThreadComment():JSX.Element {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [errorMessage, ] = useState<string>('');
  const [loading, setLoading] = useState(false); 
  const [percentage, setPercentage] = useState(0);
  const [html, setHtml] = useState<EditorValue>(RichTextEditor.createEmptyValue());
  const [fileAttachmenErrorMessage, setFileAttachmenErrorMessage] = useState<string|undefined>();
  const [attachments, setAttachments] = useState<File[]>([]);
  const [loadingProgress, setLoadingProgress] = useState<onProgressResponse[]>([]);
  const token = useTypedSelector(state => state.userReducer.token);
  const { control , handleSubmit, formState: { isValid }, setValue } = useForm({ mode: 'all', reValidateMode: 'onChange' });

  useEffect(() => {
    setValue('subject', state.thread.Subject, { shouldDirty: true, shouldValidate: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    calculatePercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProgress]);
  const calculatePercentage = () => {
    let total = 0;
    loadingProgress.forEach(x=>{
      total += x.percentage / loadingProgress.length;
    });
    if(percentage<total){
      setPercentage(Math.round(total));
    }
    
  };

  const submit: SubmitHandler<any> = async (data:any) => {
    if(token){
      setScrollToTop(true);
      setLoading(true);
      const requestAttachments:CreateThreadAttachment[]= [];
      let attachmentFileNames:Attachments[];
      if(attachments.length>0){
        attachmentFileNames = await UploadToAzure({
          data:attachments,
          onProgress:onProgress,
          folderName: state.thread.Id,
          useDocRepoContainer:true,
          isDownloadable:true
        });
        attachmentFileNames.forEach(async(x)=>{
          requestAttachments.push({
            FileName:x.Name,
            FileUrl: state.thread.Id+ '/'+ x.Url,
            FileType: x.FileType
          });
        });
      }
      setPercentage(100);
      
      AddThreadComment(token,{
        ThreadId: state.thread.Id,
        Body: data.body,
        Attachments: attachments.length > 0 ? requestAttachments : null
      },
      ()=>{
        navigate(state.navigateBackTo, {state: {...state, navigateBackTo: state.prevNavigateBackTo}});
        setLoading(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
      );
    }
  };

  const onProgress = (data:onProgressResponse) => {
    const result = loadingProgress?.find(x => data.fileName === x.fileName);
    let tempLoadingProcesses = loadingProgress;

    if (result !== undefined){
      tempLoadingProcesses= tempLoadingProcesses?.map(x => {
        if (x.fileName === data.fileName) {
          return {...x, percentage: data.percentage};
        }else{
          return x;
        }
      });
    } else {
      tempLoadingProcesses?.push(data);
    }
    
    setLoadingProgress(tempLoadingProcesses);
  };

  return (
    <FormPageComponent
      hasError={!!errorMessage}
      title={'New Comment'}
      buttonTitle="Submit"
      buttonDisabled={!isValid || loading}
      buttonLoading={loading}
      close={() => navigate(state.navigateBackTo, {state: {...state, navigateBackTo: state.prevNavigateBackTo}})}
      submit={handleSubmit(submit)}
      scrollToTop={scrollToTop}
      submitButton
    >
      <Fragment>
        <Grid item sx={{ flexGrow: 1 }}>
          {!!errorMessage
            && <Alert severity="error">{errorMessage}</Alert>
          }
        </Grid>

        {loading && <LinearProgress variant="determinate" color="secondary" value={percentage}  sx={{ zIndex: 2 }} />}
        <ModalCardComponent showOverflowingChild>
          <SectionHeader title="Thread details" />
          <Grid item container rowSpacing={2} sx={{ flexGrow: 1 }}>
            <Grid item xs={12}>
              <FormControlInput
                data-testid="send-email-subject"
                control={control}
                disabled
                field={{
                  label: 'Subject',
                  name: 'subject',
                  inputType: 'text',
                  validation: {
                    maxLength: {
                      value: 50,
                      message: 'Subject should be up to 50 characters.',
                    },
                    required: REQUIRED_VALIDATION,
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <RichTextEditor
                {...control.register('body', { required: REQUIRED_VALIDATION, validate: val => val !== EMPTY_HTML })}
                value={html}
                toolbarConfig={TOOLBAR_CONFIG}
                placeholder="Body"
                onChange={(val: EditorValue) => {
                  setHtml(val);
                  setValue('body', val.toString('html'), { shouldDirty: true, shouldValidate: true });
                }}
              />
            </Grid>
          </Grid>
        </ModalCardComponent>
        <ModalCardComponent>
          <SectionHeader
            title="File attachments"
            subtitle="Select files you would like to attach to this thread."
            errorMessage={fileAttachmenErrorMessage}
            button={
              <label htmlFor="inputid">
                <input
                  data-testid="attachments-upload"
                  id="inputid"
                  accept={BrandAssetFileTypes}
                  type="file"
                  multiple
                  onClick={event => {
                    event.currentTarget.files = null;
                    event.currentTarget.value = '';
                  }}
                  style={{ display: 'none' }}
                  {...control.register('attachments', { required: { value: false, message: '' } })}
                  onChange={(e) => {
                    setFileAttachmenErrorMessage(undefined);
                    let fileArray: File[] = Array.prototype.slice.call(e.target.files);
                    const response = CheckFileExtensions(fileArray,BrandAssetFileTypes);
                    if(response.errorMessage!==null){
                      setFileAttachmenErrorMessage(response.errorMessage);
                    }
                    fileArray = response.allowedFiles.filter(newFile=>{
                      let fileNotExist = true;
                      attachments.forEach(file=>{
                        if(newFile.size === file.size && file.name === newFile.name){
                          fileNotExist = false;
                        }
                      });
                      return fileNotExist;
                    });
                    setAttachments([...attachments, ...fileArray]);
                    setValue('attachments', [...attachments, ...fileArray], { shouldDirty: true, shouldValidate: true });
                  }}
                />
                <ButtonContainedPrimary
                  data-testid="add-attachment"
                  startIcon={<Add />}
                  sx={{ margin: '0 0 0 8px', width: 'max-content', pointerEvents: 'none' }}
                >
                  Add Attachment
                </ButtonContainedPrimary>
              </label>
            } />
          <Grid xs={12} item container sx={{ flexGrow: 1, width: '100%' }}>
            {attachments.length > 0
              ? <>
                {attachments.map((file: File, idx) => (
                  <>
                    <SectionRow
                      title={`File ${idx + 1}`}
                      lines={[
                        { data: file.name, type: 'body1' }
                      ]}
                      endIcon={<RemoveCircleIcon color="primary" fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => {
                        const temp = [...attachments].filter(f => attachments.indexOf(f) !== idx);
                        setAttachments(temp);
                        setValue('attachments', temp, { shouldDirty: true, shouldValidate: true });
                      }} />}
                    />
                  </>
                ))}
              </>
              : <InfoComponent
                title="File attachments"
                description="You haven’t set any file attachments yet. Start by selecting files in a dialog. You can add multiple files at once"
              />
            }
          </Grid>
        </ModalCardComponent>
      </Fragment>
      {loading ? (
        <div style={styles.overlay}>
        </div>
      ) : (
        <></>
      )}
    </FormPageComponent>
  );
}

const styles: IStyles={
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  }
};


const TOOLBAR_CONFIG: ToolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['BLOCK_TYPE_DROPDOWN', 'INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS',],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Paragraph', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'Bullet List', style: 'unordered-list-item' },
    { label: 'Numbered List', style: 'ordered-list-item' }
  ]
};
const EMPTY_HTML = '<p><br></p>';
