import '../scss/components/_dashboard.scss';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { LIGHT_THEME } from '../constants/theme';
import { MainDrawer } from './MainDrawer';
import { MainBar } from './appbars/MainBar';
import { MainBarCompact } from './appbars/MainBarCompact';
import { COMPACT_BAR_ENABLED } from '../constants/genaralValues';

interface DashboardProps {
  children: null | string | JSX.Element | (JSX.Element | string | null)[];
  merchantPage?: boolean;
  noDrawer?: boolean;
  noAppBar?: boolean;
  noMargin?: boolean;
  noPadding?: boolean;
  fullWidth?: boolean;
  justifyContent?: string;
}

export function Dashboard({ children, merchantPage, noDrawer, noAppBar, noMargin, noPadding, fullWidth, justifyContent = 'space-between' }: DashboardProps): JSX.Element {
  const [title, drawerOpen, inferredDevice] = useTypedSelector((state) => [state.pageReducer.title, state.drawerReducer.drawerOpen, state.drawerReducer.inferredDevice]);
  const showCompactBar = COMPACT_BAR_ENABLED.includes(title);

  function renderAppBar() {
    if(noAppBar) { return <></>; }

    if(showCompactBar) {
      return (
        <Box sx={{ position: 'inherit', zIndex: (theme: any) => theme.zIndex.drawer + 1 }}>
          <MainBarCompact merchantPage={merchantPage} />
        </Box>
      );
    }
    return (
      <Box sx={{ position: 'sticky', top: '-36px', zIndex: (theme: any) => theme.zIndex.drawer + 1 }}>
        <MainBar merchantPage={merchantPage} />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', height: 'auto' }}>
      {showCompactBar && <Box height="3px" bgcolor="#012169" />}
      {renderAppBar()}

      {!noDrawer ? <MainDrawer /> : null}

      <Box
        sx={{
          px: 3,
          padding: noPadding
            ? showCompactBar ? 0 : '60px 0 0'
            : ((inferredDevice === 'desktop' && !noDrawer) || (inferredDevice === 'tablet' && drawerOpen))
              ? `60px 0 36px ${LIGHT_THEME.misc.drawer.width}`
              : '60px 0 36px 0'
        }}
      >
        <Container
          maxWidth={false}
          className="content-container"
          sx={{
            maxWidth: fullWidth ? '100%' : '888px',
            width: '100%',
            height: 'auto',
            margin: noMargin ? '0' : 'auto',
            padding: noPadding ? '0px !important' : { xs: '0 16px', sm: '0 24px' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: justifyContent,
            alignItems: 'center',
            flex: 1,
          }}>
          {children}
        </Container>
      </Box>
    </Box>
  );
}
