import { Grid, IconButton } from '@mui/material';
import { useState } from 'react';
import { CaptionSecondary } from '../components-atoms/TypographyComponents';
import presentationIcon from '../assets/icons/Presentationfile.svg';
import otherIcon from '../assets/icons/Otherfile.svg';
import videoIcon from '../assets/icons/Videofile.svg';
import imageIcon from '../assets/icons/Imagefile.svg';
import DownloadIcon from '@mui/icons-material/Download';

interface IProps {
  url: string;
  text: string;
  onClick?: () => void;
  type: string;
  downloadClicked: ()=>void
}

export default function ThreadAttachmentPreview({ text, url, onClick, type, downloadClicked }: IProps): JSX.Element {
  const [imageError, setImageError] = useState<boolean>( url !== null ? false : true);

  const getPreviewType = () => {
    if (type.includes('image')) {
      return 'Image';
    } else if (type.includes('video')) {
      return 'Video';
    } else if (type.includes('application')) {
      return 'Presentation';
    } else {
      return 'other';
    }
  };

  const getTargetSrc = () => {
    switch (getPreviewType()) {
      case 'Video':
        return videoIcon;
      case 'Presentation':
        return presentationIcon;
      case 'Image':
        return imageIcon;
      default:
        return otherIcon;
    }
  };

  const imgUrl = imageError ? getTargetSrc() : url !== null ? url : getTargetSrc();
  
  return (
    <Grid item container onClick={onClick}>
      <Grid
        container
        item
        direction="column"
        sx={{
          border: '1px solid rgba(0,0,0,0.12)',
          borderRadius: '4px',
          overflow: 'hidden',
          flex: 1,
          aspectRatio: '1/1',
          position: 'relative',
          cursor: 'pointer',
          '&:before': {
            content: '""',
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
          },
          '&:hover': {
            '&:before': {
              opacity: .54,
            },
          }
        }}>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            downloadClicked();
          }}
          sx={{
            position: 'absolute',
            right: '8px',
            top:'8px',
            zIndex: 9,
            color: '#fff',
            backgroundColor:'#00B495',
            width:'32px',
            height:'32px'
          }}
        >
          <DownloadIcon/>
        </IconButton>
        <Grid
          container
          item
          sx={{
            flex: 1,
            cursor: 'pointer',
            objectFit: 'contain',
            margin: 0,
            maxHeight: 'calc(100% - 48px)'
          }}
        >
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            style={{
              objectFit: 'contain',
              maxHeight: '100%',
              backgroundColor:  '#fff'
            }}
          >
            <img
              data-testid="digital-asset-thumbnail"
              style={{ objectFit: 'contain', maxWidth: imageError? '48px' : '100%' , maxHeight: imageError? '48px' : '100%'}}
              alt="preview"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = getTargetSrc();
                setImageError(true);
              }}
              src={imgUrl}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: '0px 8px', height: '48px' }}>
          <Grid item xs={12}/*  onClick={()=>{}} */ sx={{ '&.MuiGrid-root': { maxWidth:'fit-content' } }}>
            <CaptionSecondary
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                cursor: 'pointer'
              }}>
              {text}
            </CaptionSecondary>
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  );
}
