import { useCallback, useLayoutEffect, useState } from 'react';

interface IProps {
  children: null | JSX.Element;
  currentVersion: string;
  isEnabled: boolean;
}
export function CacheBuster({
  children = null,
  currentVersion,
  isEnabled = false,
}: IProps): JSX.Element | null {
  const [cacheStatus, setCacheStatus] = useState({
    loading: true,
    isLatestVersion: false
  });
  const checkCacheStatus = useCallback(async () => {
    try {
      const res = await fetch('/meta.json');
      const { version: metaVersion } = await res.json();
      const shouldForceRefresh = isThereNewVersion(metaVersion, currentVersion);
      if (shouldForceRefresh) {
        setCacheStatus({
          loading: false,
          isLatestVersion: false
        });
      } else {
        setCacheStatus({
          loading: false,
          isLatestVersion: true
        });
      }
    } catch (error) {
      setCacheStatus({
        loading: false,
        isLatestVersion: true
      });
    }
  }, [currentVersion]);
  useLayoutEffect(() => {
    isEnabled && checkCacheStatus();
  }, [isEnabled, checkCacheStatus]);

  const isThereNewVersion = (metaVersion: string, currentVersion: string) => {
    return metaVersion !== currentVersion;
  };

  const refreshCacheAndReload = async () => {
    try {
      if (window?.caches) {
        const { caches } = window;
        const cacheNames = await caches.keys();
        for (const cacheName of cacheNames) {
          caches.delete(cacheName);
        }
        window.location.reload();
      }
    } catch (error) {}
  };

  if (!isEnabled) {
    return children;
  } else {
    if (!cacheStatus.loading && !cacheStatus.isLatestVersion) {
      refreshCacheAndReload();
      return null;
    }
    return children;
  }
}

