import { FieldValues, SubmitHandler, useForm, useWatch } from 'react-hook-form';

import Grid from '@mui/material/Grid';

import { ResetFormInputTypes, RESET_FORM } from '../../forms/reset';

import { ModalCardComponent } from '../../components-molecules/Modal';
import { FormControlInput } from '../../components-molecules/FormControl';
import { BodyTwoSecondary } from '../../components-atoms/TypographyComponents';
import { TextListComponent } from '../../components-atoms/ListComponents';
import { Fragment, useEffect, useState } from 'react';
import { GetUserInfoRequest, PasswordChangeRequest } from '../../service/userService';
import { useTypedDispatch, useTypedSelector } from '../../hooks/TypedReduxHooks';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { useNavigate } from 'react-router-dom';

export function ChangePassword(): JSX.Element {
  const { clearErrors, reset, control, setError, formState: { isValid }, handleSubmit } = useForm<FieldValues>({ mode: 'all' });
  const [user, token] = useTypedSelector(state => [state.userReducer.data, state.userReducer.token]);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);
  const navigate = useNavigate();

  const password = useWatch({
    control,
    name: 'password', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: '' // default value before the render
  });

  const passwordRepeat = useWatch({
    control,
    name: 'passwordRepeat', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: '' // default value before the render
  });

  const dispatch = useTypedDispatch();

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (password !== passwordRepeat) {
      setError('passwordRepeat', { message: 'Passwords do not match', type: 'manual' });
    } else {
      clearErrors('passwordRepeat');
    }
  }, [password, clearErrors, passwordRepeat, setError]);

  const resetPassword: SubmitHandler<ResetFormInputTypes> = () => {
    if (token && user) {
      PasswordChangeRequest(
        token,
        {
          Password: password
        },
        user.Id,
        () => {
          GetUserInfoRequest(
            token,
            (response) => {
              dispatch({ type: 'USER_SIGNIN_SUCCESS', payload: { token: token, data: response.Result } });
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {}
          );
          navigate('/profile');
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  return (
    <FormPageComponent title="Reset password" buttonTitle="Reset Password" buttonDisabled={!isValid} close={() => navigate('/profile')} submit={handleSubmit(resetPassword)}>
      <ModalCardComponent>
        <Grid item sx={{ flexGrow: 1 }}>
          <BodyTwoSecondary sx={{ margin: '0 0 8px' }}>Choose a secure password:</BodyTwoSecondary>
          <TextListComponent
            lines={[
              'With min. 8 to max. 15 characters',
              'Containing at least 1 uppercase letter and 1 lowercase letter',
              'Containing at least 1 number'
            ]}
          />
        </Grid>
        <Fragment>
          <Grid item sx={{ flexGrow: 1 }}>
            <FormControlInput control={control} field={RESET_FORM()[0]} showPassword={showPassword} setShowPassword={setShowPassword} />
          </Grid>
          <Grid item sx={{ flexGrow: 1 }}>
            <FormControlInput control={control} field={RESET_FORM(password)[1]} showPassword={showRepeatPassword} setShowPassword={setShowRepeatPassword} />
          </Grid>
        </Fragment>
      </ModalCardComponent>
    </FormPageComponent>
  );
}
