import { useCallback, useEffect, useState } from 'react';
import { NoItems } from '../components-molecules/NoItems';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { LIGHT_THEME } from '../constants/theme';
import { DeleteAdminRole, GetAdminRoles, GetAdminRolesResponse, GetAdminRolesResponseModel } from '../service/serviceService';
import axios, { CancelToken } from 'axios';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { Box, CircularProgress, Grid } from '@mui/material';
import { ListDivider } from '../components-molecules/Divider';
import { UserRoleCard } from './cards/UserRoleCard';
import { BodyOneSecondary } from '../components-atoms/TypographyComponents';
import { MiniDialog } from '../components-molecules/dialogs/MiniDialog';
import { useNavigate } from 'react-router-dom';
import { SearchbarWithDeboubce } from '../components-molecules/SearchBarWithDebounce';

export function AdminRolesSection(): JSX.Element {
  const [roles, setRoles] = useState<GetAdminRolesResponse[]>([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [query, setQuery] = useState('');
  const token = useTypedSelector(state => state.userReducer.token);
  const cancelTokenSource = axios.CancelToken.source();
  const [modal, setModal] = useState<boolean>(false);
  const [roleToDelete, setRoleToDelete] = useState('');
  const isEditable = (role: GetAdminRolesResponseModel) =>
    role.ServiceSection?.[0].Editable || role.AdminSection?.[0].Editable || false;
  const navigate = useNavigate();

  const getRoles = useCallback((token: string, cancelToken: CancelToken) => {
    setLoading(true);
    GetAdminRoles(
      token,
      {
        Query: query
      },
      (response) => {
        setCount(response.Result.flatMap(f => f.Roles).length);
        setRoles(response.Result);
        setLoading(false);
      },
      () => setLoading(false),
      cancelToken
    );
  }, [query]);
  const deleteAdminRole = () => {
    if (token && roleToDelete) {
      DeleteAdminRole(
        token,
        roleToDelete,
        () => getRoles(token, cancelTokenSource.token),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };
  useEffect(() => {
    if (token) getRoles(token, cancelTokenSource.token);
    return () => {
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, getRoles]);
  return (
    <>
      <PageHeader
        buttonTitle="User Role"
        subtitle={
          (count > 0)
            ? `${count} ${(count > 1) ? 'User Roles' : 'User Role'}`
            : loading
              ? ''
              : 'No User Roles Yet'
        }
        activeButtons={true}
        modalCallback={() => navigate('/admin/add-user-role')}
        onClickSearch={() => setSearchOpen(!searchOpen)}
        noSort
      />
      <SearchbarWithDeboubce
        isOpen={searchOpen}
        query={query}
        placeholder="Search User Roles"
        onCancel={() => { setSearchOpen(false); setQuery(''); }}
        onChange={(text:string) => setQuery(text)}
      />
      {!loading
        ? !(roles.length > 0)
          ? <NoItems
            icon={<ManageAccountsIcon sx={{ fontSize: 88, fill: LIGHT_THEME.palette.primary.main }} />}
            title="No user roles yet"
            description="You haven’t added any user roles yet. Start by adding the first user role." />
          : <>
            {roles.map(m =>
              <ListDivider
                key={m.GroupName}
                division={{ name: m.GroupName, length: m.Roles.length }}
                caption={{ singular: 'User Role', plural: 'User Roles' }}
              >
                {m.Roles.map((item, itemIndex) =>
                  <UserRoleCard
                    hasMenuIcon={isEditable(item)}
                    onDelete={(role) => {
                      setRoleToDelete(role);
                      setModal(true);
                    }}
                    onEdit={() => {
                      navigate('/admin/edit-user-role', { state: { selectedRole: item } });
                    }}
                    key={itemIndex}
                    role={item} />)}
              </ListDivider>
            )}
          </>
        : <Grid container item justifyContent="center" alignItems="center">
          <CircularProgress
            color="primary"
            size={50}
            style={{ zIndex: 999, margin: '56px 0' }}
          />
        </Grid>}
      {modal
        ? <MiniDialog
          title="Delete User Role"
          open={modal}
          close={() => { setModal(false); setRoleToDelete(''); }}
          remove={() => {
            deleteAdminRole();
            setModal(false);
            setRoleToDelete('');
          }}>
          <Box sx={{ padding: '0 24px' }}>
            <BodyOneSecondary>
                Are you sure you want to delete this User Role?
            </BodyOneSecondary>
          </Box>
        </MiniDialog>
        : null
      }
    </>
  );
}
