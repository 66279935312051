import { useNavigate } from 'react-router-dom';
import { HomepageIndustryInsights } from '../service/serviceService';
import { ButtonTextPrimary } from '../components-atoms/ButtonComponents';
import { BodyOnePrimary, BodyTwoSecondary, HeaderSix } from '../components-atoms/TypographyComponents';
import { Grid } from '@mui/material';
import '../scss/components/_latest-insights.scss';
import '../scss/components/_image-card.scss';
import '../scss/mixins/_link.scss';
import { FormatDate } from '../utils/dateUtils';
import { useTypedSelector } from '../hooks/TypedReduxHooks';

interface IProps {
  serviceId: string;
  insights: HomepageIndustryInsights[];
}

export function LatestInsights({ serviceId, insights }: IProps): JSX.Element {
  const navigate = useNavigate();
  const token = useTypedSelector(state => state.userReducer.token);

  function onClick(insight:HomepageIndustryInsights){    
    if (token) {
      navigate(`/detail/industryinsight/${insight?.Id}`);      
    }
  }
  return (
    <>
      <section className="latest-insights">
        <div className='latest-insights__title'>
          <HeaderSix className="latest-insights__main-title" >Read Latest Insights from the Industry</HeaderSix>
          <ButtonTextPrimary onClick={() => navigate(`/service/${serviceId}`)}>See All</ButtonTextPrimary>
        </div>
        <Grid container item className="latest-insights__container">
          {insights.map((insight) => {
            return (
              <a
                key={insight.Title}
                className="image-card image-card--link image-card--masked"
                onClick={()=>onClick(insight)}
                target="_blank"
                rel="noreferrer"
              >
                <div
                  className="image-card__thumb"
                  role="img"
                  aria-label={insight.Title}
                  style={{ backgroundImage: `url('${insight.ImageUrl}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
                ></div>
                <div className="image-card__footer">
                  <BodyOnePrimary className="subtitle-1 subtitle-1--dark u-text-capitalize">
                    {insight.Title}
                  </BodyOnePrimary>
                  <BodyTwoSecondary>
                    {FormatDate(new Date(insight.Published), { day: 'numeric', month: 'short', format: 'MM dd, YYYY' })}
                  </BodyTwoSecondary>
                </div>
              </a>
            );
          })}
        </Grid>
      </section>
    </>
  );
}
