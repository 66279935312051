/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { ExtractTerritoryManagers } from '../../utils/arrayExtractor';
import { Section } from '../../components-molecules/section/Section';
import { BodyOnePrimary, BodyTwoSecondary, CaptionPrimary, HeaderSix } from '../../components-atoms/TypographyComponents';
import { IStyles } from '../../constants/theme';
import imageIcon from '../../assets/img/merchant_placeholder.jpg';

const SHOW_TM_SIZE = 2;
export function MerchantListCard({ merchant }: { merchant: Merchant; }): JSX.Element {
  const [territoryManagers] = useState(ExtractTerritoryManagers(merchant.Stores));
  const slicedTMList = territoryManagers.slice(SHOW_TM_SIZE);

  const renderTerritoryManagerList = useCallback(() => {
    if(territoryManagers.length > 0) {
      return (
        <>
          {territoryManagers.slice(0, SHOW_TM_SIZE).map((tm, index) =>
            <BodyOnePrimary key={index}>{`${tm.AdminProperties.FirstName} ${tm.AdminProperties.LastName}`}</BodyOnePrimary>
          )}
  
          {slicedTMList?.length > 0
            && <Typography variant="caption" color="text.secondary">
              +{slicedTMList.length} more
            </Typography>
          }
        </>
      );
    }
    return <BodyOnePrimary>None</BodyOnePrimary>;
  },
  [territoryManagers, slicedTMList.length]
  );

  return (
    <Section fullPadding to={`/admin/merchants/${merchant.Id}`}>
      <Grid container columnGap="24px" sx={{ flexFlow: 'row wrap' }}>
        <Grid item sx={{ flexShrink: 1, flexGrow: 0, height: '96px', width: '96px' }}>
          <img
            src={merchant.ThumbnailUrl || merchant.Logo || imageIcon}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = imageIcon;
            }}
            style={{
              height: 'inherit',
              width: 'inherit',
              border: '1px solid rgba(0, 0, 0, 0.08)',
              borderRadius: '4px',
              objectFit: 'contain'
            }}
          />
        </Grid>

        <Grid item sx={{ flexGrow: 1 }}>
          <HeaderSix sx={{ margin: !merchant.IsSetUp ? '0px' : '0 0 12px' }}>{merchant.Name}</HeaderSix>
          {merchant.ManagerStatus === ''
            ? <Grid style={styles.stampContainer}>
              <CaptionPrimary style={styles.stampText}>No Manager Added</CaptionPrimary>
            </Grid>
            : merchant.ManagerStatus === 'Pending' || !merchant.IsSetUp
              ? <Grid style={styles.stampContainer}>
                <CaptionPrimary style={styles.stampText}>Registration Pending</CaptionPrimary>
              </Grid>
              : <></>
          }
          <Grid container sx={{ flexFlow: 'row wrap' }}>
            <Grid item sx={{ flexGrow: 1, flexShrink: 0, width: '120px' }}>
              <BodyTwoSecondary>Audience</BodyTwoSecondary>
              <BodyOnePrimary>{merchant.AudienceName}</BodyOnePrimary>
            </Grid>
            <Grid item sx={{ flexGrow: 1, flexShrink: 0, width: '120px' }}>
              <BodyTwoSecondary>Payer/Parent Code</BodyTwoSecondary>
              <BodyOnePrimary>{merchant.PayerCode}</BodyOnePrimary>
            </Grid>
            <Grid item sx={{ flexGrow: 1, flexShrink: 0, width: '120px' }}>
              <BodyTwoSecondary>Territory Manager</BodyTwoSecondary>
              {renderTerritoryManagerList()}
            </Grid>
            <Grid item sx={{ flexGrow: 1, flexShrink: 0, width: '120px' }}>
              <BodyTwoSecondary>Stores</BodyTwoSecondary>
              <BodyOnePrimary>{merchant.Stores.length}</BodyOnePrimary>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
}

const styles: IStyles = {
  stampContainer: {
    borderRadius: '4px',
    backgroundColor: '#ED6C0214',
    padding: '0px 8px',
    display: 'flex',
    alignItems: 'center',
    maxHeight: '24px',
    marginTop: '4px',
    minHeight: '24px',
    width: 'fit-content'
  },
  stampText: {
    color: '#ED6C02'
  }
};
