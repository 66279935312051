/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { ChangeArticlePublishStateRequest, DeleteArticleRequest, GetArticlesRequest, ReOrderArticlesRequest } from '../service/serviceService';

import { TabPanel } from '@mui/lab';
import { Box, CircularProgress } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';

import { ArticleList } from '../components-organisms/ArticleList';
import { MiniDialog } from '../components-molecules/dialogs/MiniDialog';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { ISelectedOptions } from '../components-molecules/FilterMenu';
import { NoItems } from '../components-molecules/NoItems';
import { BodyOnePrimary, BodyOneSecondary } from '../components-atoms/TypographyComponents';

import { IStyles, LIGHT_THEME } from '../constants/theme';
import { useNavigate } from 'react-router-dom';
import { SearchbarWithDeboubce } from '../components-molecules/SearchBarWithDebounce';

const SORT_TYPES = [
  {
    title: 'Published',
    value: 'desc'
  },
  {
    title: 'Unpublished',
    value: 'asc'
  }
];

export function FocusOfTheMonth(): JSX.Element {
  const pageSize = 10;
  const token = useTypedSelector(state => state.userReducer.token);
  const [page, setPage] = useState(0);
  const [articles, setArticles] = useState<GroupedArticles[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>();
  const [total, setTotal] = useState<number>(0);
  const [sortType, setSortType] = useState<string>('desc');
  const filterOptions = { 'Date Range': [], 'Publish State': [{ title: 'Published', value: 'true' }, { title: 'Unpublished', value: 'false' }], Attachments: [{ title: 'Yes', value: 'true' }, { title: 'No', value: 'false' }] };
  const [filter, setFilter] = useState<ISelectedOptions>({ 'Date Range': [], 'Publish State': [], Attachments: [] });
  const navigate = useNavigate();

  const loadMore = () => {
    if (token) {
      getArticles(token, page + 1, pageSize, articles);
      setPage(page + 1);
    }
  };

  const getArticles = (t: string, page = 0, size = pageSize, prevData: GroupedArticles[] = []): void => {
    setLoading(true);
    GetArticlesRequest(
      t,
      {
        Page: page,
        Size: size,
        Query: query,
        OrderBy: sortType,
        StartDate: filter['Date Range']?.[0],
        EndDate: filter['Date Range']?.[1],
        IsPublished: filter['Publish State']?.length === 1 ? filter['Publish State'][0] === 'true' : undefined,
        HasAttachment: filter.Attachments?.length === 1 ? filter.Attachments[0] === 'true' : undefined,
      },
      (response) => {
        if (response) {
          setTotal(response.Result.Count);
          let temp = [...prevData];
          response.Result.Result.map(m => {
            const tempMatch = temp.find((f) => f.Group === m.Group);
            if (tempMatch) {
              temp[temp.indexOf(tempMatch)].Articles = [...temp[temp.indexOf(tempMatch)].Articles, ...m.Articles];
            } else {
              temp = [...temp, m];
            }
          });
          setArticles(temp);
          setLoading(false);
        }
      },
      () => setLoading(false)
    );
  };

  const changePublishState = (id: string) => {
    if (token) {
      setArticles([]);
      setLoading(true);
      ChangeArticlePublishStateRequest(
        token,
        {
          Id: id
        },
        () => { setRefresh(!refresh); setPage(0); },
        () => setLoading(false)
      );
    }
  };

  const onMove = (newData: Article[]) => {
    if (token) {
      const idList = newData.map(m => ({ Id: m.Id }));
      ReOrderArticlesRequest(
        token,
        idList,
        () => { return; },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  const deleteArticle = (id: string) => {
    if (token) {
      DeleteArticleRequest(
        token,
        id,
        () => { setRefresh(!refresh); setPage(0); },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  useEffect(() => {
    if (token) getArticles(token);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, query, sortType, filter, refresh]);
  
  const findArticleForEdit = (id: string) => {
    let foundArticle;
    articles.forEach(group => {
      group.Articles.forEach(article => {
        if (article.Id === id) {
          foundArticle = article;
        }
      });
    });
    navigate('/admin/edit-topic', { state: { article: foundArticle } });
  };

  const searchOnChange = (text:string) => {
    setQuery(text);
  };
  
  return (
    <TabPanel value="content" style={styles.p0}>
      <PageHeader
        buttonTitle={'Add Topic'}
        activeButtons={true}
        modalCallback={() => navigate('/admin/add-topic')}
        sortTypes={SORT_TYPES}
        selectedSortType={sortType}
        handleSortSelect={(val) => setSortType(val)}
        onClickSearch={() => setSearchOpen(!searchOpen)}
        hasFilter
        filterOptions={filterOptions}
        onFilterSubmit={(selected) => setFilter(selected)}
        isAdmin={true}
      />
      <SearchbarWithDeboubce
        isOpen={searchOpen}
        query={query}
        placeholder="Search Topics"
        onCancel={() => { setSearchOpen(false); setQuery(''); }}
        onChange={searchOnChange}
      />
      {loading && page === 0
        ? <CircularProgress
          color='primary'
          size={50}
          style={styles.circularProgress}
        />
        : (articles.length > 0
          ? <ArticleList
            onMove={onMove}
            total={total}
            articles={articles}
            loadMore={loadMore}
            onEdit={(id) => {
              //getArticle(id);
              findArticleForEdit(id);
            }}
            onDelete={(id) => {
              setSelectedId(id);
              setModalOpen(true);
            }}
            onPublishChange={(id) => changePublishState(id)}
          />
          : query || !Object.values(filter).every(option => Array.isArray(option) && option.length === 0)
            ? <BodyOnePrimary sx={{ marginTop: '16px' }}>0 topics found.</BodyOnePrimary>
            : <NoItems
              icon={<ArticleIcon style={styles.noItemsIcon} />}
              title="No topics yet"
              description="You haven’t added any topics yet. Start by adding the first topic."
            />)
      }

      {modalOpen
        ? <MiniDialog
          title="Delete Topic"
          open={modalOpen}
          disabled={!selectedId}
          close={() => {
            setModalOpen(false);
            setSelectedId(undefined);
          }}
          remove={() => {
            if (selectedId) {
              deleteArticle(selectedId);
            }
            setSelectedId(undefined);
            setModalOpen(false);
          }}>
          <Box style={styles.ptb24}>
            <BodyOneSecondary>
              {'Are you sure that you want to remove this Topic?'}
            </BodyOneSecondary>
          </Box>
        </MiniDialog>
        : null
      }
    </TabPanel>
  );
}

const styles: IStyles = {
  p0: { padding: 0 },
  ptb24: { padding: '0 24px' },
  noItemsIcon: { fontSize: '88px', fill: LIGHT_THEME.palette.primary.main },
  circularProgress: { zIndex: 999, position: 'absolute', top: '50vh', right: '40vw' }
};
