/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { MiniDialog } from '../../components-molecules/dialogs/MiniDialog';
import { BodyOneSecondary } from '../../components-atoms/TypographyComponents';

interface IProps {
  title: string;
  description: string;
}

export function ServicePageHeaderInfoModal({title, description}: IProps): JSX.Element {
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  
  function infoDialog() {
    return(
      <MiniDialog
        noCancel
        title={title}
        open={showInfoDialog}
        close={() => {
          setShowInfoDialog(false);
        }}
        titleSx={{
          mt: 5,
          py: 3
        }}
        bodySx={{
          height: 'unset'
        }}
        childrenSx={{
          py: 5
        }}
      >
        <Box px={3}>
          <BodyOneSecondary>
            {description}
          </BodyOneSecondary>
        </Box>
      </MiniDialog>
    );
  }

  return(
    <>
      <IconButton sx={{ width: 48, height: 48 }} onClick={()=> setShowInfoDialog(true)}>
        <InfoIcon />
      </IconButton>

      {infoDialog()}
    </>
  );
}
