import { MCTheme, Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { Palette } from '@mui/material/styles/createPalette';
import { Typography } from '@mui/material/styles/createTypography';
import CSS from 'csstype';

// Required for the ability to manage these with the Theme
import type { } from '@mui/x-data-grid-pro/themeAugmentation';
import type { } from '@mui/lab/themeAugmentation';

declare module '@mui/material/styles' {
  interface MCTheme extends Theme {
    misc: {
      drawer: {
        width: string;
      };
      toolbar: {
        mainHeightNumber: number;
        mainHeight: string;
        akzonobelHeightNumber: number;
        akzonobelHeight: string;
        modalHeightNumber: number;
        modalHeight: string;
      };
    };
  }
}

declare module '@mui/material/Toolbar' {
  interface ToolbarPropsVariantOverrides {
    main: true;
    akzonobel: true;
    modal: true;
  }
}

declare module '@mui/material/Icon' {
  interface IconPropsVariantOverrides {
    main: true;
    akzonobel: true;
  }
}

const DEFAULT = createTheme();

const LIGHT_THEME_DEFAULT_PALETTE: Palette = {
  ...DEFAULT.palette,
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    default: 'rgba(255, 255, 255, 1)',
    paper: 'rgba(255, 255, 255, 1)',
  },
  primary: {
    main: 'rgba(1, 33, 105, 1)',
    light: 'rgba(51, 77, 135, 1)',
    dark: 'rgba(0, 23, 73, 1)',
    contrastText: 'rgba(255, 255, 255, 1)',
  },
  secondary: {
    main: 'rgba(0, 180, 149, 1)',
    light: 'rgba(89, 231, 198, 1)',
    dark: 'rgba(0, 131, 103, 1)',
    contrastText: 'rgba(255, 255, 255, 1)',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.6)',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(1, 33, 105, 0.04)',
    selected: 'rgba(1, 33, 105, 0.08)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    focus: 'rgba(1, 33, 105, 0.12)',
    hoverOpacity: 0.08,
    selectedOpacity: 0.16,
    disabledOpacity: 0.38,
    focusOpacity: 0.12,
    activatedOpacity: 0.24
  },
  error: {
    main: 'rgba(244, 67, 54, 1)',
    dark: 'rgba(227, 27, 12, 1)',
    light: 'rgba(248, 128, 120, 1)',
    contrastText: 'rgba(255, 255, 255, 1)',
  },
  info: {
    main: 'rgba(33, 150, 243, 1)',
    dark: 'rgba(11, 121, 208, 1)',
    light: 'rgba(100, 182, 247, 1)',
    contrastText: 'rgba(255, 255, 255, 1)',
  },
  warning: {
    main: 'rgba(237, 108, 2, 1)',
    dark: 'rgba(199, 119, 0, 1)',
    light: 'rgba(255, 181, 71, 1)',
    contrastText: 'rgba(255, 255, 255, 1)',
  },
  success: {
    main: 'rgba(76, 175, 80, 1)',
    dark: 'rgba(59, 135, 62, 1)',
    light: 'rgba(123, 198, 126, 1)',
    contrastText: 'rgba(255, 255, 255, 1)',
  }
};

const LIGHT_THEME_DEFAULT_TYPOGRAPHY: Typography = {
  ...DEFAULT.typography,
  htmlFontSize: 16,
  fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontFamily: '"Colour Sans Light", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 300,
    fontSize: '6rem',
    lineHeight: 1.167,
    letterSpacing: '-0.01562em'
  },
  h2: {
    fontFamily: '"Colour Sans Bold", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 300,
    fontSize: '3.75rem',
    lineHeight: 1.2,
    letterSpacing: '-0.00833em'
  },
  h3: {
    fontFamily: '"Colour Sans Bold", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '3rem',
    lineHeight: 1.167,
    letterSpacing: '0em'
  },
  h4: {
    fontFamily: '"Colour Sans Bold", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '2.125rem',
    lineHeight: 1.235,
    letterSpacing: '0.00735em'
  },
  h5: {
    fontFamily: '"Colour Sans Bold", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
  h6: {
    fontFamily: '"Open Sans", "Colour Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 600,
    fontSize: '1.25rem',
    lineHeight: 1.6,
    letterSpacing: '0.0075em'
  },
  subtitle1: {
    fontFamily: '"Open Sans", "Colour Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.75,
    letterSpacing: '0.00938em'
  },
  subtitle2: {
    fontFamily: '"Open Sans", "Colour Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: 1.57,
    letterSpacing: '0.00714em',
  },
  body1: {
    fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
    letterSpacing: '0.00938em'
  },
  body2: {
    fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
  },
  button: {
    fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
  },
  caption: {
    fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.33,
    letterSpacing: '0.03333em'
  },
  overline: {
    fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 2.66,
    letterSpacing: '0.08333em',
    textTransform: 'uppercase'
  },
};

const LIGHT_THEME_DEFAULT_MISC_VARIABLES = {
  shape: {
    borderRadius: 8
  },
  misc: {
    drawer: {
      width: '330px'
    },
    toolbar: {
      mainHeightNumber: 60,
      mainHeight: '60px',
      akzonobelHeightNumber: 33,
      akzonobelHeight: '33px',
      modalHeightNumber: 96,
      modalHeight: '96px'
    }
  }
};

export const LIGHT_THEME = createTheme({
  ...LIGHT_THEME_DEFAULT_MISC_VARIABLES,
  palette: LIGHT_THEME_DEFAULT_PALETTE,
  typography: LIGHT_THEME_DEFAULT_TYPOGRAPHY,
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1440,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            width: '11px',
          },
          '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
            background: 'white',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            background: 'rgba(0, 0, 0, 0.28)',
            padding: '0 2px',
            borderRight: '2px solid transparent',
            borderLeft: '2px solid transparent',
            borderRadius: '5.5px',
            backgroundClip: 'padding-box',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          background: 'rgba(1, 33, 105, 0.04)',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: '330px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiModal:{
      defaultProps: {
        BackdropProps: {
          style: {
            backdropFilter: 'blur(5px)',
            WebkitBackdropFilter: 'blur(5px)',
            backgroundColor: 'rgba(255, 255, 255, .7)'
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiToolbar: {
      variants: [
        {
          props: { variant: 'main' },
          style: {
            background: LIGHT_THEME_DEFAULT_PALETTE.background.paper,
            color: LIGHT_THEME_DEFAULT_PALETTE.text.primary,
            height: LIGHT_THEME_DEFAULT_MISC_VARIABLES.misc.toolbar.mainHeight
          },
        },
        {
          props: { variant: 'akzonobel' },
          style: {
            height: LIGHT_THEME_DEFAULT_MISC_VARIABLES.misc.toolbar.akzonobelHeight,
            justifyContent: 'flex-end',
            background: 'linear-gradient(to right, #ffffff, #e9e9e9)',
            color: LIGHT_THEME_DEFAULT_PALETTE.text.primary
          },
        },
        {
          props: { variant: 'modal' },
          style: {
            height: LIGHT_THEME_DEFAULT_MISC_VARIABLES.misc.toolbar.modalHeight,
            justifyContent: 'space-between',
            padding: '0 !important',
            background: LIGHT_THEME_DEFAULT_PALETTE.background.paper,
            color: LIGHT_THEME_DEFAULT_PALETTE.text.primary
          },
        }
      ]
    }
  }
}) as MCTheme;

export interface IStyles{
  [key: string]: CSS.Properties | CustomCssTags ;
}
interface CustomCssTags {
  [key: string]: CSS.Properties;
}
