const INITIAL: NPDState = {
  currentData: {
    Id: '' as GUID,
    Title: '',
    Description: '',
    ImageUrl: '',
    ThumbnailUrl: '',
    Published: true,
  },
};

export const npdReducer = (state: NPDState = INITIAL, action: NPDAction): NPDState => {
  switch (action.type) {
    case 'SET_CURRENT_PRODUCT':
      return { ...state, currentData: action.payload.currentData };
    default:
      return state;
  }
};
