export const DEBOUNCE_TIMEOUT = 1000;
export const COMPACT_BAR_ENABLED = [
  'Brand Assets',
  'Digital Toolkits',
  'Dulux Academy',
  'Dulux Trade YouTube Channel',
  'Document Library',
  'Focus of the Month',
  'Help',
  'Knowledge Base',
  'Product Updates',
  'Industry Insights',
  'Industry Events',
  'Monthly Rebate Update',
  'Newsletter Library',
  'Webshop'
];

export const DISABLE_DEFAULT_HEADER = [
  'Brand Assets',
  'Newsletter Library',
  'Knowledge Base',
  'Industry Insights'
];
