import { Fragment, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';

import packageJson from '../package.json';
import NewThreadComment from './components-organisms/NewThreadComment';
import PdfPreviewPage from './components-organisms/PdfPreviewPage';
import ThreadDetails from './components-organisms/ThreadDetails';
import { AddAdminUser } from './components-organisms/modals/AddAdminUser';
import { AddArticle } from './components-organisms/modals/AddArticle';
import { AddAsset } from './components-organisms/modals/AddAsset';
import { AddEmployee } from './components-organisms/modals/AddEmployee';
import { AddInsight } from './components-organisms/modals/AddInsight';
import { AddMerchant } from './components-organisms/modals/AddMerchant';
import { AddProduct } from './components-organisms/modals/AddProduct';
import { AddStore } from './components-organisms/modals/AddStore';
import { AddTerritoryManager } from './components-organisms/modals/AddTerritoryManager';
import { AddUserRole } from './components-organisms/modals/AddUserRole';
import { AssetDetail } from './components-organisms/modals/AssetDetail';
import { ChangePassword } from './components-organisms/modals/ChangePassword';
import { EditAdminUser } from './components-organisms/modals/EditAdminUser';
import { EditArticle } from './components-organisms/modals/EditArticle';
import { EditAsset } from './components-organisms/modals/EditAsset';
import { EditInsight } from './components-organisms/modals/EditInsight';
import { EditIndustryEvent } from './components-organisms/modals/EditIndustryEvent';
import { EditOrganisation } from './components-organisms/modals/EditOrganisation';
import { EditProduct } from './components-organisms/modals/EditProduct';
import { EditStore } from './components-organisms/modals/EditStore';
import { EditTerritoryManager } from './components-organisms/modals/EditTerritoryManager';
import { EditUser } from './components-organisms/modals/EditUser';
import { EditUserRole } from './components-organisms/modals/EditUserRole';
import { NewThread } from './components-organisms/modals/NewThread';
import { SendEmail } from './components-organisms/modals/SendEmail';
import { AdminUsers } from './components-pages/AdminUsers';
import { ArticleDetailsPage } from './components-pages/ArticleDetailsPage';
import { CategoryPage } from './components-pages/CategoryPage';
import { ColourDetailsPage } from './components-pages/ColourDetailsPage';
import { CookiePolicy } from './components-pages/CookiePolicy';
import { DigitalAssetService } from './components-pages/DigitalAssetService';
import { Disclaimer } from './components-pages/Disclaimer';
import { DocumentRepositoryTM } from './components-pages/DocumentRepositoryTM';
import { EditorsPickAdmin } from './components-pages/EditorsPickAdmin';
import { Forgot } from './components-pages/Forgot';
import { Frontpage } from './components-pages/Frontpage';
import { Merchant } from './components-pages/Merchant';
import { Merchants } from './components-pages/Merchants';
import { Newsletters } from './components-pages/Newsletters';
import { NotFound } from './components-pages/NotFound';
import { Organisation } from './components-pages/Organisation';
import { PrivacyPolicy } from './components-pages/PrivacyPolicy';
import { ProductDetailsPage } from './components-pages/ProductDetailsPage';
import { Profile } from './components-pages/Profile';
import { Reset } from './components-pages/Reset';
import { SearchResults } from './components-pages/SearchResults';
import { ServiceDetail } from './components-pages/ServiceDetail';
import { ServicePage } from './components-pages/ServicePage';
import { SignIn } from './components-pages/SignIn';
import { TermsAndConditions } from './components-pages/TermsAndConditions';
import { TermsOfUse } from './components-pages/TermsOfUse';
import { Welcome } from './components-pages/Welcome';
import { environment } from './environment';
import { useTypedDispatch, useTypedSelector } from './hooks/TypedReduxHooks';
import { GetAllAdminServicesRequest } from './service/serviceService';
import { CacheBuster } from './utils/CacheBuster';

import ReactGA from 'react-ga4';
import InvitationDetail from './components-organisms/InvitationDetail';
import { AddDigitalToolkit } from './components-organisms/modals/AddDigitalToolkit';
import { AddSalesManager } from './components-organisms/modals/AddSalesManager';
import DigitalAssetShare from './components-organisms/modals/DigitalAssetShare';
import { DigitalToolkitDetail } from './components-organisms/modals/DigitalToolkitDetail';
import DigitalToolkitShare from './components-organisms/modals/DigitalToolkitShare';
import { EditDigitalToolkit } from './components-organisms/modals/EditDigitalToolkit';
import { EditSalesManager } from './components-organisms/modals/EditSalesManager';
import AdminHelp from './components-pages/AdminHelp';
import GetInvited from './components-pages/GetInvited';
import GetInvitedSuccess from './components-pages/GetInvitedSuccess';
import MerchantRegistrations from './components-pages/MerchantRegistrations';
import SalesManagers from './components-pages/SalesManagers';
import { Unauthorized } from './components-pages/Unauthorized';
import BrandAssetServiceDetail from './components-pages/admin/detail/BrandAssetServiceDetail';
import DigitalToolkitServiceDetail from './components-pages/admin/detail/DigitalToolkitServiceDetail';
import { EmailNewsletterDetail } from './components-pages/detail/EmailNewsletterDetail';
import { FocusOfTheMonthDetail } from './components-pages/detail/FocusOfTheMonthDetail';
import { IndustryInsightDetail } from './components-pages/detail/IndustryInsightDetail';
import { LatestProductDetail } from './components-pages/detail/LatestProductDetail';
import { GlobalEmailNotificationSettings } from './components-pages/GlobalEmailNotificationSettings';
import { IndustryEventDetail } from './components-organisms/IndustryEventDetail';
import IndustryEventsServiceDetail from './components-pages/admin/detail/IndustryEventsServiceDetail';

ReactGA.initialize([
  {
    trackingId: environment.gaToken,
    gaOptions: {
      name: 'client',
    }
  },
  {
    trackingId: environment.gaAdminToken,
    gaOptions: {
      name: 'admin',
    }
  },
]);

function MerchantRoutes() {
  const userData = useTypedSelector((state) => state.userReducer.data);
  const location = useLocation();
  const to = (location.pathname === '/forgot' || location.pathname === 'reset') ? location.pathname : '/signin';

  return userData
    ? <Outlet />
    : <Navigate to={to} state={{ from: location }} />;
}

function AdminRoutes() {
  const userData = useTypedSelector((state) => state.userReducer.data);
  const location = useLocation();
  // find a better way to filter roles
  return (userData && userData.IsAdmin && !userData.IsTerritoryManager && !userData.IsSalesManager)
    ? <Outlet />
    : <Navigate to="/signin" state={{ from: location }} />;
}

function TerritoryManagerRoutes() {
  const userData = useTypedSelector((state) => state.userReducer.data);
  const location = useLocation();
  // find a better way to filter roles
  return (userData && (userData.IsTerritoryManager || userData.IsSalesManager))
    ? <Outlet />
    : <Navigate to="/signin" state={{ from: location }} />;
}

const ADMIN_SERVICE_ELEMENTS: { [key: string]: JSX.Element; } = {
  merchants: (
    <Fragment key="merchant">
      <Route path='/admin/merchants' element={<Merchants />} />
      <Route path='/admin/merchants/:merchantId' element={<Merchant />} />
      <Route path='/admin/add-merchant' element={<AddMerchant />} />
      <Route path="/admin/edit-merchant" element={<EditOrganisation />} />
      <Route path="/admin/add-store" element={<AddStore />} />
      <Route path="/admin/edit-store" element={<EditStore />} />
      <Route path="/admin/add-employee" element={<AddEmployee />} />
      <Route path="/admin/edit-employee" element={<EditUser />} />
    </Fragment>
  ),
  newsletters: (
    <Fragment key="newsletters">
      <Route path='/admin/newsletters' element={<Newsletters />} />
      <Route path='/admin/new-email' element={<SendEmail />} />
    </Fragment>
  ),
  salesmanagers: (
    <Fragment key="salesmanagers">
      <Route path='/admin/salesmanagers' element={<SalesManagers />} />
      <Route path='/admin/add-salesmanager' element={<AddSalesManager />} />
      <Route path='/admin/edit-salesmanager' element={<EditSalesManager />} />
      <Route path='/admin/add-territorymanager' element={<AddTerritoryManager />} />
      <Route path='/admin/edit-territorymanager' element={<EditTerritoryManager />} />
    </Fragment>
  ),
  adminusers: (
    <Fragment key="adminusers">
      <Route path='/admin/adminusers' element={<AdminUsers />} />
      <Route path='/admin/add-admin-user' element={<AddAdminUser />} />
      <Route path='/admin/edit-admin-user' element={<EditAdminUser />} />
      <Route path='/admin/add-user-role' element={<AddUserRole />} />
      <Route path='/admin/edit-user-role' element={<EditUserRole />} />
    </Fragment>
  ),
  editorspick: <Route key="editorspick" path='/admin/editorspick' element={<EditorsPickAdmin />} />
};

export function App(): JSX.Element {
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();
  //const isProd = process.env.NODE_ENV === 'production';
  const [adminRoutes, setAdminRoutes] = useState<JSX.Element[]>([]);
  const [services, adminServices, token, user, superUser] = useTypedSelector((state) => [state.drawerReducer.services, state.drawerReducer.adminServices, state.userReducer.token, state.userReducer.data, state.userReducer.super]);

  useEffect(() => {
    dispatch({ type: 'SET_PAGE_TITLE', payload: { title: '' } });

    document.documentElement.scrollTo({
      top: 0,
      left: 0
    });
    if (pathname.includes('admin') && superUser) {
      dispatch({ type: 'USER_CLEAR_SUPERUSE' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, dispatch]);

  const getAdminRoutesByRole = (services: IServiceByCategory[], adminServices: IAdminService[]) => {
    const routes = [];
    if (adminServices?.length > 0) {
      adminServices.forEach(service => {
        routes.push(
          ADMIN_SERVICE_ELEMENTS[service.Route]
        );
      });
    }
    if (services?.length > 0) {
      routes.push(<Route key="servicedetail" path="/admin/servicedetail/:id" element={<ServiceDetail />} />);
    }
    return routes;
  };

  const getSalesManagersRoutes = () => {
    if (user && user.IsSalesManager) {
      return (
        <Fragment>
          <Route path='/admin/salesmanagers' element={<SalesManagers />} />
          <Route path='/admin/add-salesmanager' element={<AddSalesManager />} />
          <Route path='/admin/edit-salesmanager' element={<EditSalesManager />} />
          <Route path='/admin/add-territorymanager' element={<AddTerritoryManager />} />
          <Route path='/admin/edit-territorymanager' element={<EditTerritoryManager />} />
        </Fragment>
      );
    }
    return <></>;
  };

  useLayoutEffect(() => {
    if (!adminServices) {
      if (token) {
        GetAllAdminServicesRequest(
          token,
          (resp) => dispatch({ type: 'DRAWER_SET_ADMIN_SERVICE_LIST', payload: { adminServices: resp.Result } }),
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => { }
        );
      } else {
        dispatch({ type: 'DRAWER_SET_ADMIN_SERVICE_LIST', payload: { adminServices: [] } });
      }
    }
  }, [dispatch, adminServices, token]);

  useLayoutEffect(() => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    dispatch({ type: 'DRAWER_INFER', payload: { viewportWidth: vw } });
    setAdminRoutes(getAdminRoutesByRole(services, adminServices));
    // eslint-disable-next-\line react-hooks/exhaustive-deps
  }, [dispatch, adminServices, services]);

  const navigateToDefault = useCallback(() => {
    if (adminServices && adminServices.length > 0) {
      return `/admin/${adminServices[0].Route}`;
    } else {
      return `/admin/servicedetail/${services[0]?.Services[0].ServiceId}`;
    }
  }, [adminServices, services]);

  return (
    <CacheBuster isEnabled={false} currentVersion={packageJson.version}>
      <Box sx={{ display: 'flex', minHeight: '100vh', background: 'white' }}>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/get-invited" element={<GetInvited />} />
          <Route path="/get-invited-success" element={<GetInvitedSuccess />} />
          <Route path="/reset/:guid" element={<Reset />} />
          <Route path="/admin/help" element={<AdminHelp />} />

          <Route path="/" element={<MerchantRoutes />}>
            <Route path="/" element={<Frontpage />} />
            {/* Detail Pages */}
            <Route path="/detail/emailnewsletter/:id" element={<EmailNewsletterDetail />} />
            <Route path="/detail/focusofthemonth/:id" element={<FocusOfTheMonthDetail />} />
            <Route path="/detail/npd/:id" element={<LatestProductDetail />} />
            <Route path="/detail/industryinsight/:id" element={<IndustryInsightDetail />} />
            <Route path="/detail/toolkit/:toolkitId" element={<DigitalToolkitDetail />} />
            {/* Detail Pages */}
            <Route path="/asset-detail/:assetId" element={<AssetDetail />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/reset-password" element={<ChangePassword />} />
            <Route path="/organisation" element={<Organisation />} />
            <Route path="/edit-organisation" element={<EditOrganisation />} />
            <Route path="/add-store" element={<AddStore />} />
            <Route path="/edit-store" element={<EditStore />} />
            <Route path="/add-employee" element={<AddEmployee />} />
            <Route path="/edit-employee" element={<EditUser />} />
            <Route path="/thread-detail/:id" element={<ThreadDetails />} />
            <Route path="/thread-new-comment" element={<NewThreadComment />} />
            <Route path="/document-preview" element={<PdfPreviewPage />} />
            <Route path="/service/digitalassets" element={<DigitalAssetService />} />
            <Route path="/service/:id" element={<ServicePage />} />
            <Route path="/category/:id" element={<CategoryPage />} />
            <Route path="/detail/article/:id" element={<ArticleDetailsPage />} />
            <Route path="/detail/colour/:id" element={<ColourDetailsPage />} />
            <Route path="/detail/product/:id" element={<ProductDetailsPage />} />
            <Route path="/detail/toolkit/:id" element={<></>} />
            <Route path="/detail/industry-event/:id" element={<IndustryEventDetail />} />
            <Route path="/searchresults" element={<SearchResults />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/term-use" element={<TermsOfUse />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
          </Route>
          {user?.IsTerritoryManager || user?.IsSalesManager
            ? <Route path="/" element={<TerritoryManagerRoutes />}>
              <Route path="/" element={<Frontpage />} />
              <Route path="/welcome" element={<Welcome />} />
              <Route path="/admin" element={<Navigate to={'/admin/merchants'} />} />
              <Route path='/admin/merchants' element={<Merchants />} />
              <Route path='/admin/merchants/:merchantId' element={<Merchant />} />
              <Route path='/admin/add-merchant' element={<AddMerchant />} />
              <Route path="/admin/edit-merchant" element={<EditOrganisation />} />
              {getSalesManagersRoutes()}
              <Route path="/admin/add-store" element={<AddStore />} />
              <Route path="/admin/edit-store" element={<EditStore />} />
              <Route path="/admin/add-employee" element={<AddEmployee />} />
              <Route path="/admin/edit-employee" element={<EditUser />} />
              <Route path='/admin/newsletters' element={<Newsletters />} />
              <Route path='/admin/new-email' element={<SendEmail />} />
              <Route path='/admin/document-repository' element={<DocumentRepositoryTM />} />
              <Route path='/admin/brand-assets' element={<BrandAssetServiceDetail />} />
              <Route path='/admin/industry-events' element={<IndustryEventsServiceDetail />} />
              <Route path='/admin/digital-toolkits' element={<DigitalToolkitServiceDetail />} />
              <Route path='/admin/new-thread' element={<NewThread />} />
              <Route path="/admin/thread-detail/:id" element={<ThreadDetails />} />
              <Route path="/admin/edit-event" element={<EditIndustryEvent />} />
              <Route path="/admin/thread-new-comment" element={<NewThreadComment />} />
              <Route path='/admin/digital-asset-share' element={<DigitalAssetShare />} />
              <Route path='/admin/digital-toolkit-share/:id' element={<DigitalToolkitShare />} />
              <Route path='/admin/globalemailnotifications' element={<GlobalEmailNotificationSettings />} />
              <Route path="/admin/unauthorized" element={<Unauthorized noDrawer />} />
              <Route path="/admin/*" element={<NotFound noDrawer />} />
            </Route>
            : <Route path="/" element={<AdminRoutes />}>
              <Route path="/welcome" element={<Welcome />} />
              <Route path="/admin" element={<Navigate to={navigateToDefault()} />} />
              {adminRoutes}
              <Route path="/admin/add-topic" element={<AddArticle />} />
              <Route path="/admin/edit-topic" element={<EditArticle />} />
              <Route path="/admin/add-asset" element={<AddAsset />} />
              <Route path="/admin/edit-asset" element={<EditAsset />} />
              <Route path="/admin/add-product" element={<AddProduct />} />
              <Route path="/admin/edit-product" element={<EditProduct />} />
              <Route path="/admin/add-digitaltoolkit" element={<AddDigitalToolkit />} />
              <Route path="/admin/edit-digitaltoolkit" element={<EditDigitalToolkit />} />
              <Route path="/admin/add-insight" element={<AddInsight />} />
              <Route path="/admin/edit-insight" element={<EditInsight />} />
              <Route path="/admin/edit-event" element={<EditIndustryEvent />} />
              <Route path='/admin/new-thread' element={<NewThread />} />
              <Route path='/admin/merchantregistrations' element={<MerchantRegistrations />} />
              <Route path="/admin/thread-detail/:id" element={<ThreadDetails />} />
              <Route path="/admin/merchantregistrations/:id" element={<InvitationDetail />} />
              {/* TODO: Check duplicate definition */}
              <Route path='/admin/digital-asset-share' element={<DigitalAssetShare />} />
              <Route path='/admin/digital-toolkit-share/:id' element={<DigitalToolkitShare />} />
              <Route path='/admin/globalemailnotifications' element={<GlobalEmailNotificationSettings />} />
              <Route path="/admin/unauthorized" element={<Unauthorized noDrawer />} />
              <Route path="/admin/*" element={<NotFound noDrawer />} />
            </Route>}
          <Route path='/heritage-true-white-digital-toolkit' element={<DigitalToolkitDetail id={environment.heritageToolkitId} />} />
          <Route path='/dulux-trade-stain-block-matt-digital-toolkit' element={<DigitalToolkitDetail id={'cde43a1f-029d-4caa-b56c-bf5913dd0cb2'} />} />
          <Route path="/unauthorized" element={<Unauthorized noDrawer noAppBar />} />
          <Route path="*" element={<NotFound noDrawer noAppBar />} />
        </Routes>
      </Box>
    </CacheBuster>
  );
}
