/* eslint-disable @typescript-eslint/naming-convention */
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import Grid from '@mui/material/Grid';
import { CircularProgress, FormHelperText } from '@mui/material';

import { SalesManagerFormTypes, SALES_MANAGER_FORM } from '../../forms/salesManager';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { GetSalesManagerRoles, SubmitSalesManager } from '../../service/adminService';

import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { FormControlInput, FormControlSelect } from '../../components-molecules/FormControl';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { IStyles } from '../../constants/theme';

export function AddSalesManager(): JSX.Element {
  const token = useTypedSelector((state) => state.userReducer.token);
  const [loading, setLoading] = useState(false);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([]);
  const { control, handleSubmit, formState: { isValid }, setValue } = useForm({ mode: 'all', reValidateMode: 'onChange' });
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      setOptionsLoading(true);
      GetSalesManagerRoles(
        token,
        (response) => {
          setOptionsLoading(false);
          response.Result.map(res => {
            setOptions(((prev: any) => [...prev, { value: res.Id, name: res.Name }]));
          });
        },
        () => {
          setOptionsLoading(false);
        }
      );
    }
  }, [token]);

  useEffect(() => {
    setValue('published', true, { shouldDirty: true, shouldValidate: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit: SubmitHandler<SalesManagerFormTypes> = (data): void => {
    if (token) {
      setLoading(true);
      setScrollToTop(true);
      SubmitSalesManager(
        token,
        {
          FirstName: data.firstName,
          LastName: data.lastName,
          Phone: data.phone,
          Email: data.email,
          SalesManagerRoleId: data.role
        },
        () => {
          navigate('/admin/salesmanagers', { state: { from: 'add-salesmanager' } });
        },
        () => {
          setLoading(false);
          setScrollToTop(false);
        }
      );
    }
  };

  return (
    <Fragment>
      {optionsLoading ? (
        <Grid container item justifyContent="center" alignItems="center">
          <CircularProgress
            color="primary"
            size={50}
            style={{ zIndex: 999, margin: '56px 0' }}
          />
        </Grid>
      ) : (
        <FormPageComponent
          title="Add Sales Manager"
          buttonTitle="Add Manager"
          buttonDisabled={!isValid || loading}
          buttonLoading={loading}
          close={() => navigate('/admin/salesmanagers', { state: { from: 'add-salesmanager' } })}
          submit={handleSubmit(submit)}
          scrollToTop={scrollToTop}
        >
          <Fragment>
            {SALES_MANAGER_FORM.map((section, index) => {
              return (
                <ModalCardComponent key={index}>
                  <SectionHeader title={section.title} />
                  <Fragment>
                    {section.fields.map((field, idx) =>
                      <Grid key={idx}>
                        {field.inputType === 'select'
                          ? <FormControlSelect control={control} field={{ ...field, options }} />
                          : <FormControlInput control={control} field={field} />
                        }
                      </Grid>
                    )}
                    <div style={styles.helperTextContainer}>
                      <FormHelperText>
                        Example: 07975777666
                      </FormHelperText>
                    </div>
                  </Fragment>
                </ModalCardComponent>
              );
            })}
          </Fragment>
          {loading ? (
            <div style={styles.overlay}>
            </div>
          ) : (
            <></>
          )}
        </FormPageComponent>
      )}
    </Fragment>
  );
}

const styles: IStyles = {
  helperTextContainer: {
    padding: '0 14px'
  },
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  }
};
