/* eslint-disable @typescript-eslint/naming-convention */
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import Grid from '@mui/material/Grid';
import { CircularProgress, FormHelperText } from '@mui/material';

import { SalesManagerFormTypes, SALES_MANAGER_FORM } from '../../forms/salesManager';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { EditSalesManagerRequest, GetSalesManagerRoles, SearchSalesManagerResult } from '../../service/adminService';

import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { FormControlInput, FormControlSelect } from '../../components-molecules/FormControl';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { IStyles } from '../../constants/theme';

type GeneralType = {
  [key: string]: string;
};

export function EditSalesManager(): JSX.Element {
  const token = useTypedSelector((state) => state.userReducer.token);
  const [loading, setLoading] = useState(false);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const salesManager: SearchSalesManagerResult = state.salesmanager;

  const defaultValues: GeneralType = {
    firstName: salesManager.FirstName,
    lastName: salesManager.LastName,
    phone: salesManager.Phone,
    email: salesManager.Email,
    role: ''
  };

  const { control, formState: { isValid }, handleSubmit, reset } = useForm({ mode: 'all', reValidateMode: 'onBlur', defaultValues });

  useEffect(() => {
    if (token) {
      setOptionsLoading(true);
      GetSalesManagerRoles(
        token,
        (response) => {
          setOptionsLoading(false);
          response.Result.map(res => {
            setOptions(((prev: any) => [...prev, { value: res.Id, name: res.Name }]));
            if (res.Name === salesManager.Role) {
              reset({ ...defaultValues, role: res.Id });
            }
          });
        },
        () => {
          setOptionsLoading(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const submit: SubmitHandler<SalesManagerFormTypes> = (data): void => {
    if (token) {
      setLoading(true);
      setScrollToTop(true);
      EditSalesManagerRequest(
        token,
        {
          Id: salesManager.Id,
          FirstName: data.firstName,
          LastName: data.lastName,
          Phone: data.phone,
          SalesManagerRoleId: data.role
        },
        () => {
          navigate('/admin/salesmanagers', { state: { from: 'edit-salesmanager' } });
        },
        () => {
          setLoading(false);
          setScrollToTop(false);
        }
      );
    }
  };

  return (
    <Fragment>
      {optionsLoading ? (
        <Grid container item justifyContent="center" alignItems="center">
          <CircularProgress
            color="primary"
            size={50}
            style={{ zIndex: 999, margin: '56px 0' }}
          />
        </Grid>
      ) : (
        <FormPageComponent
          title="Edit Sales Manager"
          buttonTitle="Submit"
          buttonDisabled={!isValid || loading}
          close={() => navigate('/admin/salesmanagers', { state: { from: 'edit-salesmanager' } })}
          submit={handleSubmit(submit)}
          scrollToTop={scrollToTop}
        >
          <Fragment>
            {SALES_MANAGER_FORM.map((section, index) => {
              return (
                <ModalCardComponent key={index}>
                  <SectionHeader title={section.title} />
                  <Fragment>
                    {section.fields.map((field, idx) =>
                      <Grid key={idx}>
                        {field.inputType === 'select'
                          ? <FormControlSelect control={control} field={{ ...field, options }} />
                          : <FormControlInput disabled={field.name === 'email'} control={control} field={field} />
                        }
                      </Grid>
                    )}
                    <div style={styles.helperTextContainer}>
                      <FormHelperText>
                        Example: 07975777666
                      </FormHelperText>
                    </div>
                  </Fragment>
                </ModalCardComponent>
              );
            })}
          </Fragment>
          {loading ? (
            <div style={styles.overlay}>
            </div>
          ) : (
            <></>
          )}
        </FormPageComponent>
      )}
    </Fragment>
  );
}

const styles: IStyles = {
  helperTextContainer: {
    padding: '0 14px'
  },
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  }
};
