import { useMediaQuery, Container, CircularProgress, Stack } from '@mui/material';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LIGHT_THEME } from '../constants/theme';
import IndustryEventWatchEvent from './modals/IndustryEventWatchEvent';
import { IndustryEventListingCard } from './cards/listing/IndustryEventListingCard';

interface IProps {
  total: number;
  events: IndustryEvent[];
  watchEventModal: IndustryEvent | undefined;
  loadMore: () => void;
  onJoinEvent: (eventId: GUID | string) => void;
  onWatchEventModalOpen: (eventId: GUID | string) => void;
  onWatchEventModalClose: () => void;
}

export function IndustryEventsServiceList({ total, events, watchEventModal, loadMore, onJoinEvent, onWatchEventModalOpen, onWatchEventModalClose }: IProps): JSX.Element {
  const timeoutDuration = 300;
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));

  return (
    <Stack width="100%" px={{ xs: '20px', sm: 4 }}>
      <InfiniteScroll
        style={{ overflow: 'inherit' }}
        dataLength={total} //This is important field to render the next data
        next={() => debounce(() => loadMore(), timeoutDuration)()}
        scrollThreshold={smallDown ? '550px' : '50px'}
        hasMore={events.length < total}
        loader={
          <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
            <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
          </Container>
        }
      >
        {events.map((item, idx) => (
          <IndustryEventListingCard
            hideEllipsis
            event={item}
            key={item.Id}
            title={item.Title}
            description={item.Description}
            thumbnailUrl={item.ThumbnailUrl}
            isFirst={idx === 0}
            isLast={idx === events.length - 1}
            thumbnailFit='cover'
            hasShadow={item.State === 'Live'}
            onJoinEvent={onJoinEvent}
            onWatchEventModalOpen={onWatchEventModalOpen}
          />
        ))}
      </InfiniteScroll>

      <IndustryEventWatchEvent
        isOpen={watchEventModal !== undefined}
        id={watchEventModal ? watchEventModal.Id : ''}
        name={watchEventModal ? watchEventModal.Title : ''}
        password={watchEventModal ? watchEventModal.RecordingPassword : ''}
        eventUrl={watchEventModal ? watchEventModal.ShareUrl : ''}
        onClose={() => onWatchEventModalClose()} />
    </Stack>
  );
}
