import { Grid, Tooltip } from '@mui/material';
import { HeaderFive, HeaderSix } from '../components-atoms/TypographyComponents';
import '../scss/components/_categories.scss';

interface IProps {
  categories: {
    Name: string;
    Id: GUID;
  }[];
}

const TOOLTIP_TITLE: { [key: string]: string; } = {
  'Training': 'Training yourself, your team and your staff. Access all of the services you will need including the Dulux Academy to improve capability, capacity, productivity and performance.',
  'Think Forward': 'Discover what\'s new on offer from Dulux and get industry leading insights to shape your business today and future-proof it for tomorrow. Think forward helps you get tomorrow\'s answers today.',
  'Work Smarter': 'Tools you need to do your business smartly. From access to Webshop for product ordering and accounts, to brand assets for your use on websites and the shop floor.',
  'Rewards': 'Rewards is our section where you would find acknowledgement for your relationship with us. We will appraise you of your rebate status, recognize your annual performance and share information about rewards like hospitality or trips with you.'
};

export function Categories({ categories }: IProps): JSX.Element {
  return (
    <>
      <section className="categories">
        <HeaderSix align="left">Browse All Categories</HeaderSix>
        <Grid container item columnSpacing={20 / 8} alignItems="center">
          {categories.map(category => (
            <Tooltip key={category.Id} disableFocusListener disableTouchListener arrow title={TOOLTIP_TITLE[category.Name]}>
              <Grid className="category__container" item sm={3} md={3} xs={6}>
                <a
                  data-testid="category-tile-link"
                  className={`category category--link category--${category.Name.toLowerCase().replaceAll(/[^A-Z0-9]/ig, '')}`}
                  role="link"
                  href={`/category/${category.Id}`}
                >
                  <div className="category__inner">
                    <div className="category__inner-centering">
                      <div className="category__inner-sizing">
                        <HeaderFive color="#fff" className="category__inner-header">{category.Name}</HeaderFive>
                      </div>
                    </div>
                  </div>
                </a>
              </Grid>
            </Tooltip>
          ))}
        </Grid>
      </section>
    </>
  );
}
