import { CircularProgress, Container, Grid, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

import { ListDivider } from '../components-molecules/Divider';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';
import { LIGHT_THEME } from '../constants/theme';
import { NPDListCard } from './cards/NPDListCard';

interface IProps {
  products: NPDItem[];
  onEdit: (id: string) => void;
  onPublishChange: (id: string) => void;
  onDelete: (id: string) => void;
  loadMore: () => void;
  total: number;
  onMove: (newOrders: Product[]) => void;
}

export function NPDList({ products, onEdit, onPublishChange, onDelete, loadMore, total, onMove }: IProps): JSX.Element {
  const timeoutDuration = 300;
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  const [renderData, setRenderData] = useState<NPDItem[]>(products);
  useEffect(() => {
    setRenderData(products);
  }, [products]);
  const moveUp = (idx: number) => {
    const tempItems = [...renderData];
    const temp = [...renderData.filter(f => f.GroupName === 'Published')[0].Products];
    const tempItem = temp[idx];
    temp[temp.indexOf(tempItem)] = temp[idx - 1];
    temp[idx - 1] = tempItem;
    tempItems[tempItems.indexOf(renderData.find(f => f.GroupName === 'Published') as NPDItem)].Products = temp;
    setRenderData([...tempItems]);
    onMove(temp);
  };
  const moveDown = (idx: number) => {
    const tempItems = [...renderData];
    const temp = [...renderData.filter(f => f.GroupName === 'Published')[0].Products];
    const tempItem = temp[idx];
    temp[temp.indexOf(tempItem)] = temp[idx + 1];
    temp[idx + 1] = tempItem;
    tempItems[tempItems.indexOf(renderData.find(f => f.GroupName === 'Published') as NPDItem)].Products = temp;
    setRenderData([...tempItems]);
    onMove(temp);
  };
  
  return (
    <div style={{ paddingBottom: '48px' }}>
      <InfiniteScroll
        style={{ overflowY: 'hidden' }}
        dataLength={products.map(m => m.Products).flat().length} //This is important field to render the next data
        next={() => debounce(() => loadMore(), timeoutDuration)()}
        scrollThreshold={smallDown ? '550px' : '50px'}
        hasMore={products.map(m => m.Products).flat().length !== total}
        loader={
          <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
            <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
          </Container>
        }
      >
        {renderData.map((product) => {
          return (
            <ListDivider
              key={product.GroupName}
              division={{ name: product.GroupName, length: product.Products.length }}
              caption={{ singular: 'Product', plural: 'Products' }}
              halfMargin
              noBottomPadding
            >
              <Grid container item xs={12} columnSpacing={2} rowSpacing={3} sx={{ width: '100%', margin: 0 }}>
                {product.Products.map((item, idx) =>
                  <NPDListCard
                    count={product.Products.length}
                    moveDown={moveDown}
                    moveUp={moveUp}
                    key={item.Id}
                    product={item}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onPublishChange={onPublishChange}
                    index={idx}
                  />
                )}
              </Grid>
            </ListDivider>
          );
        })}
      </InfiniteScroll>
    </div >
  );
}
