/* eslint-disable @typescript-eslint/naming-convention */
import { Fragment, useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { AssetFormTypes } from '../../forms/digitalAssets';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';

import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { FormControlDate, FormControlImage, FormControlInput, FormControlSelect } from '../../components-molecules/FormControl';
import { EditCreateDigitalAssetFile, EditDigitalAssetRequest, GetBrands, GetProducts, GetColours, GetOrientations, GetTags, GetTypes, GetColourIds } from '../../service/serviceService';
import { REQUIRED_VALIDATION, validateWhiteSpace, FILE_TYPE_VALIDATION_MULTIPLE } from '../../forms/_predefinedValidations';
import { Autocomplete, Checkbox, Chip, createFilterOptions, FormControlLabel, LinearProgress, TextField } from '@mui/material';
import { AssetStatusButtonGroup } from '../AssetStatusButtonGroup';
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { environment } from '../../environment';
import { v4 as uuidv4 } from 'uuid';
import { isNonRenderable } from '../../utils/isNonRenderable';
import { IStyles, LIGHT_THEME } from '../../constants/theme';
import BrandAssetFileTypes from '../../utils/BrandAssetsSupportedFileTypes';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { GetGlobalEmailNotificationServicesRequest } from '../../service/emailNotificationService';
import { BodyOneSecondary } from '../../components-atoms/TypographyComponents';

interface IOption {
  title: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  value: boolean;
}

interface FileSize {
  [key: string]: {
    loaded: number;
  };
}

const filter = createFilterOptions<any>();

export function EditAsset(): JSX.Element {
  const navigate = useNavigate();
  const { state } = useLocation();
  const digitalAsset: ICurrentDigitalAsset = useTypedSelector(state => state.digitalAssetReducer.currentData);
  const [defaultAsset] = useState<AssetFormTypes>({
    logo: null,
    name: digitalAsset.DigitalAsset.DigitalAssetName,
    tags: digitalAsset.DigitalAssetTag.map(m => m.TagName),
    published: digitalAsset.DigitalAsset.Published,
    brand: digitalAsset.DigitalAsset.Brand,
    product: digitalAsset.DigitalAsset.Product,
    colour: digitalAsset.DigitalAsset.Colour,
    barcode: digitalAsset.DigitalAsset.Barcode,
    fini: digitalAsset.DigitalAsset.Fini,
    akzoColourId: digitalAsset.DigitalAsset.AkzoColourId,
    digitalAssetTypeId: digitalAsset.DigitalAsset.DigitalAssetTypeId,
    digitalAssetOrientationId: digitalAsset.DigitalAsset.DigitalAssetOrientationId,
    publicationDate: digitalAsset.DigitalAsset.PublicationDate as Date,
    sendEmailNotification: digitalAsset.DigitalAsset.ShouldSendEmailNotification,
  });
  const assetStatusOptions: IOption[] = [
    { title: 'Published', color: 'success', value: true },
    { title: 'Unpublished', color: 'error', value: false }
  ];
  const { control, handleSubmit, formState: { isValid }, getValues, setValue } = useForm<any>({ mode: 'all', reValidateMode: 'onChange', defaultValues: defaultAsset });
  const token = useTypedSelector((state) => state.userReducer.token);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [tagOptions, setTagOptions] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>(digitalAsset.DigitalAssetTag.map(m => m.TagName));
  const [brandOptions, setBrandOptions] = useState<any[]>([]);
  const [productOptions, setProductOptions] = useState<any[]>([]);
  const [colourOptions, setColourOptions] = useState<any[]>([]);
  const [akzoColourIdOptions, setAkzoColourIdOptions] = useState<any[]>([]);
  const [orientationOptions, setOrientationOptions] = useState<any[]>([]);
  const [typeOptions, setTypeOptions] = useState<any[]>([]);
  const [isDateValid, setIsDateValid] = useState<boolean>(true);
  const [fileSizes, setFileSizes] = useState<FileSize>({});
  const [totalSize, setTotalSize] = useState(0);
  const blobServiceClient = BlobServiceClient.fromConnectionString(environment.azureConnectionString);
  const [emailNotificationSettings, setEmailNotificationSettings] = useState<boolean>();  

  const uploadToAzure = (containerClient: ContainerClient, file: File) => {
    const uuid = uuidv4();
    const fileName = `${uuid}_${file.name}`.replaceAll(' ', '_');
    const blobName = `${environment.assetFolder}/${fileName}`;
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    return blockBlobClient.upload(file, file.size, {
      onProgress: (ev) => {
        setFileSizes(prev => ({ ...prev, [file.name]: { loaded: ev.loadedBytes } }));
      }
    }).then(() => {
      return fileName;
    });
  };

  useEffect(() => {
    if (token) {
      setValue('published', defaultAsset.published, { shouldDirty: true, shouldValidate: true });
      setValue('sendEmailNotification', defaultAsset.sendEmailNotification, { shouldDirty: true, shouldValidate: true });

      GetTypes(token, (response) => {
        setTypeOptions(response.Result.map(m => ({ value: m.Id, name: m.Name })));
      }, // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {});
      GetOrientations(token, (response) => {
        setOrientationOptions(response.Result.map(m => ({ value: m.Id, name: m.Name })));
      }, // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {});
      GetBrands(token, (response) => {
        setBrandOptions(response.Result.map(m => ({ value: m.Name, name: m.Name })));
      }, // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {});
      GetProducts(token, response => {
        setProductOptions(response.Result.map(m => ({ value: m.Name, name: m.Name })));
      }, // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {});
      GetColours(token, response => {
        setColourOptions(response.Result.map(m => ({ value: m.Name, name: m.Name })));
      }, // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {});
      GetColourIds(token, response => {
        setAkzoColourIdOptions(response.Result.map(m => ({ value: m.Name, name: m.Name })));
      },  // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {});
      GetTags(token, (response) => {
        setTagOptions(response.Result.map(m => ({ value: m.Name, name: m.Name })));
      }, // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {});
    }
  }, [token, setValue, defaultAsset]);

  const getPercentage = (obj: FileSize, totalSize: number) => {
    return Object.values(obj).length > 0 ? Object.values(obj).map(m => m.loaded).reduce((a, b) => a + b) / totalSize * 100 : 0;
  };

  const getGlobalEmailNotification = useCallback(() => {
    if (token) GetGlobalEmailNotificationServicesRequest(token, (response) => {
      const result = [...response];
      const isEnabledForTMandSM = result.find(r => r.ServiceName === 'Brand Assets — Territory & Sales Managers')?.IsEnabled;
      const isEnabledForMerchant = result.find(r => r.ServiceName === 'Brand Assets — Merchants')?.IsEnabled;
      setEmailNotificationSettings(isEnabledForTMandSM || isEnabledForMerchant);

    }, (error) => console.log(error));
  }, [token]);
  
  useEffect(() => {
    getGlobalEmailNotification();
  }, [getGlobalEmailNotification]);

  const readAsDataURL = (files: FileList, blobClient: BlobServiceClient): Promise<Promise<EditCreateDigitalAssetFile>[]> => {
    const containerClient = blobClient.getContainerClient(environment.containerName);
    const filesArray = Array.prototype.slice.call(files);
    let tempSizes = {};
    filesArray.map((m: File) => { tempSizes = { ...tempSizes, [m.name]: { loaded: 0 } }; });
    setFileSizes(tempSizes);
    setTotalSize(filesArray.map(m => m.size).reduce((a, b) => a + b));
    return new Promise(r => {
      Promise.all(filesArray.map((file: File) => uploadToAzure(containerClient, file))).then(urls => {
        r(filesArray.map((file: File, idx) => {
          return new Promise(resolve => {
            if (file.type.includes('image') && !isNonRenderable(file.type)) {
              const reader = new FileReader();
              reader.onload = e => {
                const img = new Image();
                img.src = e.target?.result as string;
                img.onload = () => {
                  resolve({
                    DigitalAssetFileTypeId: file.type,
                    Width: img.width,
                    Height: img.height,
                    FileUrl: urls[idx],
                    FileName: file.name,
                    SizeUnit: 'px',
                    Size: file.size,
                    Extension: file.name.split('.').pop() || '',
                  });
                };
              };
              reader.readAsDataURL(file);
            } else {
              resolve({
                DigitalAssetFileTypeId: file.type,
                Width: 0,
                Height: 0,
                FileUrl: urls[idx],
                FileName: file.name,
                SizeUnit: 'px',
                Size: file.size,
                Extension: file.name.split('.').pop() || '',
              });
            }
          });
        }));
      },
      (error) => {
        setErrorMessage(error.response?.data.Error || '');
        setLoading(false);
        setScrollToTop(false);
      });
    });
  };

  const submit: SubmitHandler<AssetFormTypes> = (data): void => {
    setLoading(true);
    setScrollToTop(true);
    if (token) {
      if (data.logo) {
        const filesArray: File[] = Array.prototype.slice.call(data.logo);
        if (!filesArray.every(f => f.type.split('/')[0] === filesArray[0].type.split('/')[0])) {
          setErrorMessage('All files need to be the same type.');
          setLoading(false);
          setScrollToTop(false);
          return;
        } else {
          setErrorMessage('');
        }
        readAsDataURL(data.logo, blobServiceClient).then((promises: Promise<EditCreateDigitalAssetFile>[]) =>
          Promise.all(promises).then((values: EditCreateDigitalAssetFile[]) => {
            EditDigitalAssetRequest(token,
              {
                DigitalAssetId: digitalAsset.DigitalAsset.DigitalAssetId,
                Name: data.name,
                PublicationDate: new Date(data.publicationDate),
                Published: data.published,
                Brand: data.brand,
                Product: data.product,
                Colour: data.colour || '',
                Barcode: data.barcode || '',
                Fini: data.fini || '',
                AkzoColourId: data.akzoColourId || undefined,
                Tags: data.tags || [],
                DigitalAssetOrientationId: data.digitalAssetOrientationId,
                DigitalAssetTypeId: data.digitalAssetTypeId,
                DigitalAssetFiles: values,
                SendEmailNotification: data.sendEmailNotification || false,
              },
              () => {
                setLoading(false);
                navigate(state.navigateBackTo, { state: { isAssetUpdated: true }});
              },
              (e) => {
                setErrorMessage(e.response?.data.Error || '');
                setLoading(false);
                setScrollToTop(false);
              });
          })
        );
      } else {
        EditDigitalAssetRequest(token,
          {
            DigitalAssetId: digitalAsset.DigitalAsset.DigitalAssetId,
            Name: data.name,
            PublicationDate: new Date(data.publicationDate),
            Published: data.published,
            Brand: data.brand,
            Product: data.product,
            Colour: data.colour || '',
            Barcode: data.barcode || '',
            Fini: data.fini || '',
            AkzoColourId: data.akzoColourId || undefined,
            Tags: data.tags || [],
            DigitalAssetOrientationId: data.digitalAssetOrientationId,
            DigitalAssetTypeId: data.digitalAssetTypeId,
            DigitalAssetFiles: [],
            SendEmailNotification: data.sendEmailNotification || false
          },
          () => {
            setLoading(false);
            navigate(state.navigateBackTo, { state: { isAssetUpdated: true }});
          },
          (e) => {
            setErrorMessage(e.response?.data.Error || '');
            setLoading(false);
            setScrollToTop(false);
          });
      }
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormPageComponent
        buttonLoading={loading}
        hasError={!!errorMessage}
        title="Edit Brand Asset"
        buttonTitle="Save Changes"
        buttonDisabled={!isValid || !isDateValid || loading}
        close={() => navigate(state.navigateBackTo, { state: { isAssetUpdated: false }})}
        submit={handleSubmit(submit)}
        scrollToTop={scrollToTop}
      >
        {loading ? (
          <LinearProgress variant="determinate" color="secondary" value={getPercentage(fileSizes, totalSize)} sx={{ zIndex: 2 }} />
        ) : (
          <></>
        )}
        <Fragment>
          <Grid item sx={{ flexGrow: 1 }}>
            {!!errorMessage
              && <Alert severity="error">{errorMessage}</Alert>
            }
          </Grid>
          <ModalCardComponent>
            <SectionHeader title="Basic information" />
            <Fragment>
              <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
                <Grid item xs={12}>
                  <FormControlImage
                    accept={BrandAssetFileTypes}
                    data-testid="edit-asset-image"
                    initialValue={digitalAsset.DigitalAssetFile[0].FileUrl}
                    initialFileType={digitalAsset.DigitalAssetFile[0].FileType.toLowerCase()}
                    control={control}
                    field={{
                      label: 'Brand Asset',
                      name: 'logo',
                      inputType: 'logo',
                      validation: {
                        required: REQUIRED_VALIDATION,
                        validate: FILE_TYPE_VALIDATION_MULTIPLE(BrandAssetFileTypes)

                      }
                    }}
                    title='Brand Asset'
                    multiple
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlInput
                    data-testid="edit-asset-name"
                    control={control}
                    field={{
                      label: 'Brand asset name',
                      name: 'name',
                      inputType: 'text',
                      validation: {
                        required: REQUIRED_VALIDATION,
                        validate: { validateWhiteSpace }
                      }
                    }} />
                </Grid>
                <Grid item xs={12} sx={{ margin: '16px 0 0' }}>
                  <Autocomplete
                    sx={{ '.MuiInputBase-input': { height: '31px', paddingTop: '3px', paddingBottom: '8px' } }}
                    data-testid="edit-asset-tags"
                    multiple
                    autoSelect
                    value={selectedTags}
                    onChange={(e, newValue) => {
                      setValue('tags', newValue, { shouldDirty: true, shouldValidate: true });
                      setSelectedTags(newValue);
                    }
                    }
                    id="tags-filled"
                    options={tagOptions.map((option) => option.name)}
                    freeSolo
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="tags"
                        value={getValues('tags') ? getValues('tags')[0] : ''}
                        variant="filled"
                        label="Tags"
                        placeholder="Tags"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sx={{ margin: '16px 0 0' }}>
                  <AssetStatusButtonGroup
                    data-testid="edit-asset-published"
                    subtitle="Published assets are visible to customers and unpublished assets are hidden."
                    title="Asset Status"
                    onChange={(value: boolean) => setValue('published', value, { shouldDirty: true, shouldValidate: true })}
                    value={getValues('published')}
                    options={assetStatusOptions}
                  />
                </Grid>
              </Grid>
            </Fragment>
          </ModalCardComponent>
          <ModalCardComponent key="Detailed information">
            <SectionHeader title="Detailed information" />
            <Fragment>
              <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
                <Grid item xs={12}>
                  <Autocomplete
                    data-testid="edit-asset-brand"
                    {...control.register('brand', { required: true })}
                    value={getValues('brand')}
                    onChange={(e, newValue) => {
                      if (typeof newValue === 'string') {
                        setValue('brand', newValue, { shouldDirty: true, shouldValidate: true });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue('brand', newValue.inputValue, { shouldDirty: true, shouldValidate: true });
                      } else {
                        setValue('brand', newValue, { shouldDirty: true, shouldValidate: true });
                      }
                    }}
                    id="brands-filled"
                    options={brandOptions.map((option) => option.name)}
                    freeSolo
                    handleHomeEndKeys
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option.title);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.title;
                    }}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="brand"
                        variant="filled"
                        label="Brand"
                        placeholder="Brand"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sx={{ margin: '16px 0 0' }}>
                  <Autocomplete
                    data-testid="edit-asset-product"
                    {...control.register('product', { required: true })}
                    value={getValues('product')}
                    onChange={(e, newValue) => {
                      if (typeof newValue === 'string') {
                        setValue('product', newValue, { shouldDirty: true, shouldValidate: true });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue('product', newValue.inputValue, { shouldDirty: true, shouldValidate: true });
                      } else {
                        setValue('product', newValue, { shouldDirty: true, shouldValidate: true });
                      }
                    }}
                    id="products-filled"
                    options={productOptions.map((option) => option.name)}
                    freeSolo
                    handleHomeEndKeys
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option.title);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.title;
                    }}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="product"
                        variant="filled"
                        label="Product"
                        placeholder="Product"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sx={{ margin: '16px 0 0' }}>
                  <Autocomplete
                    data-testid="edit-asset-colour"
                    {...control.register('colour')}
                    value={getValues('colour')}
                    onChange={(e, newValue) => {
                      if (typeof newValue === 'string') {
                        setValue('colour', newValue, { shouldDirty: true, shouldValidate: true });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue('colour', newValue.inputValue, { shouldDirty: true, shouldValidate: true });
                      } else {
                        setValue('colour', newValue, { shouldDirty: true, shouldValidate: true });
                      }
                    }}
                    id="colours-filled"
                    options={colourOptions.map((option) => option.name)}
                    freeSolo
                    handleHomeEndKeys
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option.title);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.title;
                    }}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="colour"
                        variant="filled"
                        label="Colour"
                        placeholder="Colour"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sx={{ margin: '16px 0 0' }}>
                  <Autocomplete
                    data-testid="edit-asset-colour-id"
                    {...control.register('akzoColourId')}
                    value={getValues('akzoColourId')}
                    onChange={(e, newValue) => {
                      if (typeof newValue === 'string') {
                        setValue('akzoColourId', newValue, { shouldDirty: true, shouldValidate: true });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue('akzoColourId', newValue.inputValue, { shouldDirty: true, shouldValidate: true });
                      } else {
                        setValue('akzoColourId', newValue, { shouldDirty: true, shouldValidate: true });
                      }
                    }}
                    id="colour-idss-filled"
                    options={akzoColourIdOptions.map((option) => option.name)}
                    freeSolo
                    handleHomeEndKeys
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option.title);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.title;
                    }}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="akzoColourId"
                        variant="filled"
                        label="Colour Id"
                        placeholder="Colour Id"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlSelect
                    data-testid="edit-asset-asset-type"
                    control={control}
                    field={{
                      label: 'Type of product',
                      name: 'digitalAssetTypeId',
                      inputType: 'select',
                      options: typeOptions,
                      validation: {
                        required: REQUIRED_VALIDATION
                      }
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <FormControlInput
                    data-testid="edit-asset-barcode"
                    control={control}
                    field={{
                      label: 'Barcode',
                      name: 'barcode',
                      inputType: 'text',
                      validation: {}
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <FormControlInput
                    data-testid="edit-asset-fini"
                    control={control}
                    field={{
                      label: 'FINI',
                      name: 'fini',
                      inputType: 'text',
                      validation: {}
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <FormControlSelect
                    data-testid="edit-asset-orientation"
                    control={control}
                    field={{
                      label: 'Orientation',
                      name: 'digitalAssetOrientationId',
                      inputType: 'select',
                      options: orientationOptions,
                      validation: {
                        required: REQUIRED_VALIDATION
                      }
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <FormControlDate
                    testId="publicationDate"
                    control={control}
                    field={{
                      label: 'Date of Creation',
                      name: 'publicationDate',
                      inputType: 'date',
                      validation: {
                        required: REQUIRED_VALIDATION
                      }
                    }}
                    setIsDateValid={setIsDateValid} />
                </Grid>
              </Grid>
            </Fragment>
          </ModalCardComponent>
          <ModalCardComponent key="Email Notifications">
            <SectionHeader title="Email Notifications" />
            <BodyOneSecondary>
              Check the box below to inform users about the newly created asset.
            </BodyOneSecondary>
            <Fragment>
              <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
                <Grid                   
                  xs={12}
                  container
                  flexWrap="nowrap"
                  alignItems="center"
                  sx={{
                    cursor: 'pointer',
                    margin: '24px -16px 24px 16px', 
                    padding: '8px 16px',
                    borderRadius: '8px',
                    width: 'unset',
                    background: digitalAsset.DigitalAsset.ShouldSendEmailNotification ? LIGHT_THEME.palette.action.selected : 'none',
                    ':hover': {
                      background: LIGHT_THEME.palette.action.hover
                    }
                  }}>
                  <Controller
                    control={control}
                    name={'sendEmailNotification'}
                    defaultValue={defaultAsset.sendEmailNotification}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        sx={{ mt: 1, margin: '0px 0px' }}
                        componentsProps={{
                          typography: {
                            ml: 1
                          }
                        }}
                        disabled={!emailNotificationSettings}
                        control={
                          <Checkbox
                            checked={value}
                            onChange={onChange} />}

                        label={'I would like to send out email notifications.'}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Fragment>
          </ModalCardComponent>
          {loading ? (
            <div style={styles.overlay}>
            </div>
          ) : (
            <></>
          )}
        </Fragment>
      </FormPageComponent>
    </LocalizationProvider>
  );
}

const styles: IStyles = {
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  }
};
