import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { Divider, Grid } from '@mui/material';
import { BodyTwoSecondary, HeaderSix, SubtitleOnePrimary } from '../components-atoms/TypographyComponents';
import { GetRecentProductRequest } from '../service/serviceService';
import '../scss/components/_recent-items.scss';
import '../scss/components/_thumbnail.scss';

interface IProps {
  categoryId: string;
  serviceId: string;
}

export function RecentProducts({categoryId, serviceId}: IProps): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const navigate = useNavigate();
  const [recentProducts, setRecentProducts] = useState<any>([]);

  useEffect(() => {
    if (token) {
      GetRecentProductRequest(
        token,
        {
          Page: 0,
          Size: 6,
        },
        (response) => { if (response) setRecentProducts(response.Result); },
        (error: any) => { if (error.response) console.error(error.response.data.Error); }
      );
    }
  }, [token]);

  return (
    <>
      {recentProducts.length > 0 && (
        <>
          <Divider style={{ width: '100%'}} />
          <section className="recent-items">
            <div className="recent-items__title">
              <HeaderSix>Recent Products</HeaderSix>
            </div>
            <Grid container item columnSpacing={20 / 8} alignItems="center">
              {recentProducts.map((recentProduct: any) => (
                <Grid key={recentProduct.RemoteId} className="recent-items__content" item lg={4} xl={4} xs={12} md={6} sm={6}>
                  <a
                    onClick={() => navigate(`/detail/product/${recentProduct.RemoteId}`, {
                      state: {
                        categoryId,
                        serviceId
                      }
                    })}
                    className="recent-item recent-item--link"
                  >
                    <img
                      src={recentProduct.Packshot}
                      className="
                        recent-item__media
                        thumb
                        thumb--large
                        thumb--picture-icon
                      "
                      style={{ padding: 8 }}
                    />
                    <div className="recent-item__content">
                      <SubtitleOnePrimary className="u-text-truncate-multi">
                        {recentProduct.Name}
                      </SubtitleOnePrimary>
                      <BodyTwoSecondary>
                        {recentProduct.Brand}
                      </BodyTwoSecondary>
                    </div>
                  </a>
                </Grid>
              ))}
            </Grid>
          </section>
        </>
      )}
    </>
  );
}
