interface DateOptions {
  month?: 'long' | 'short' | '2-digit' | 'numeric';
  year?: '2-digit' | 'numeric';
  day?: '2-digit' | 'numeric' | 'short' | 'long';
  format?: string;
}
interface MonthType {
  long: string;
  short: string;
  '2-digit': string;
  numeric: string;
}
interface DayType {
  long: string;
  short: string;
  '2-digit': string;
  numeric: string;
}
const MONTHS: MonthType[] = [
  { long: 'January', short: 'Jan', '2-digit': '01', numeric: '1' },
  { long: 'February', short: 'Feb', '2-digit': '02', numeric: '2' },
  { long: 'March', short: 'Mar', '2-digit': '03', numeric: '3' },
  { long: 'April', short: 'Apr', '2-digit': '04', numeric: '4' },
  { long: 'May', short: 'May', '2-digit': '05', numeric: '5' },
  { long: 'June', short: 'Jun', '2-digit': '06', numeric: '6' },
  { long: 'July', short: 'Jul', '2-digit': '07', numeric: '7' },
  { long: 'August', short: 'Aug', '2-digit': '08', numeric: '8' },
  { long: 'September', short: 'Sep', '2-digit': '09', numeric: '9' },
  { long: 'October', short: 'Oct', '2-digit': '10', numeric: '10' },
  { long: 'November', short: 'Nov', '2-digit': '11', numeric: '11' },
  { long: 'December', short: 'Dec', '2-digit': '12', numeric: '12' }
];
// eslint-disable-next-line @typescript-eslint/naming-convention
const DAYS: (dayOfMonth: number) => DayType[] = (dayOfMonth: number) => {
  const dayString = `${dayOfMonth <10 ? '0' :''}${dayOfMonth}`;
  return [
    { long: 'Monday', short: 'Mon', '2-digit': dayString, numeric: `${dayOfMonth}`},
    { long: 'Tuesday', short: 'Tue', '2-digit': dayString, numeric: `${dayOfMonth}`},
    { long: 'Wednesday', short: 'Wed', '2-digit': dayString, numeric: `${dayOfMonth}`},
    { long: 'Thursday', short: 'Thu', '2-digit': dayString, numeric: `${dayOfMonth}`},
    { long: 'Friday', short: 'Fri', '2-digit': dayString, numeric: `${dayOfMonth}`},
    { long: 'Saturday', short: 'Sat', '2-digit': dayString, numeric: `${dayOfMonth}`},
    { long: 'Sunday', short: 'Sun', '2-digit': dayString, numeric: `${dayOfMonth}`}
  ];
};
const DEFAULT_OPTIONS: DateOptions = {
  month: '2-digit',
  year: 'numeric',
  day: '2-digit',
  format: 'dd/MM/YYYY'
};

function GetYearString(y: number, t: '2-digit' | 'numeric'): string {
  return t === 'numeric' ? y.toString() : String(y).slice(-2);
}
export function FormatDate(d: Date, options: DateOptions = DEFAULT_OPTIONS): string {
  const { year = 'numeric', month = '2-digit', day = '2-digit', format = 'dd/MM/YYYY' } = options;
  const fullYear = d.getFullYear(); // YYYY
  const dayIdx = d.getDay(); // dd
  const dayOfMonth = d.getDate(); // dd
  const monthIdx = d.getMonth(); // 0 indexed
  const dateString = format
    .replace('dd', DAYS(dayOfMonth)[dayIdx][day])
    .replace('MM', MONTHS[monthIdx][month])
    .replace('YYYY', GetYearString(fullYear, year));
  return dateString;
}
