/* eslint-disable @typescript-eslint/naming-convention */
import { Grid, Alert, LinearProgress } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormControlImage, FormControlInput } from '../../components-molecules/FormControl';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { FILE_VALIDATION_WITH_TYPE, REQUIRED_VALIDATION } from '../../forms/_predefinedValidations';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { AssetStatusButtonGroup } from '../AssetStatusButtonGroup';
import { useNavigate } from 'react-router-dom';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { UploadToAzure } from '../../hooks/uploadToAzure';
import { environment } from '../../environment';
import { IStyles } from '../../constants/theme';
import { UpdateRequest } from '../../service/digitalToolkitService';

interface EditDigitalToolkitModel {
  title: string;
  description: string;
  packshot: File | null;
  published: boolean;
}

interface IOption {
  title: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  value: boolean;
}

interface onProgressResponse {
  fileName: string;
  percentage: number;
}

export function EditDigitalToolkit(): JSX.Element {
  const navigate = useNavigate();
  const buttonGroupOptions: IOption[] = [
    { title: 'Published', color: 'success', value: true },
    { title: 'Unpublished', color: 'error', value: false }
  ];
  const [token, digitalToolkit] = useTypedSelector(state => [state.userReducer.token, state.digitalToolkitReducer.currentData]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [loadingProgress, setLoadingProgress] = useState<onProgressResponse[]>([]);
  const [percentage, setPercentage] = useState(0);
  const [defaultProduct] = useState<any>({
    title: digitalToolkit.Title,
    description: digitalToolkit.Description,
    packshot: digitalToolkit.FileUrl,
    published: digitalToolkit.Published
  });

  const { control, handleSubmit, formState: { isValid }, setValue, getValues } = useForm<any>({ mode: 'all', reValidateMode: 'onChange', defaultValues: defaultProduct });

  useEffect(() => {
    setValue('published', defaultProduct.published, { shouldDirty: true, shouldValidate: true });
  }, [setValue, defaultProduct]);

  useEffect(() => {
    calculatePercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProgress]);

  const submit: SubmitHandler<EditDigitalToolkitModel> = async (data) => {
    if (token) {
      setLoading(true);
      setScrollToTop(true);

      let fileName;
      let fileUrl; 

      if (typeof data.packshot === 'object') {
        fileName = await UploadToAzure({
          data: [data.packshot],
          onProgress: onProgress,
          folderName: environment.digitalToolkitFolder
        });
        fileUrl = fileName[0].Url; 
      } else {
        const url: string = data?.packshot;
        const urlArray = url.split('/');
        fileUrl = urlArray[urlArray.length - 1];
      }

      UpdateRequest(
        token,
        {
          Id: digitalToolkit.Id,
          Title: data.title,
          Description: data.description,
          FileUrl: fileUrl,
          Published: data.published
        },
        () => {
          setLoading(false);
          navigate(-1);
        },
        (error) => {
          setErrorMessage(error.response?.data.Error || '');
          setLoading(false);
          setScrollToTop(false);
        }
      );
    }
  };

  const onProgress = (data: onProgressResponse) => {
    const result = loadingProgress?.find(x => data.fileName === x.fileName);
    let tempLoadingProcesses = loadingProgress;

    if (result !== undefined) {
      tempLoadingProcesses = tempLoadingProcesses?.map(x => {
        if (x.fileName === data.fileName) {
          return {...x, percentage: data.percentage};
        } else {
          return x;
        }
      });
    } else {
      tempLoadingProcesses?.push(data);
    }
    setLoadingProgress(tempLoadingProcesses);
  };

  const calculatePercentage = () => {
    let total = 0;
    
    loadingProgress.forEach(x => {
      total += x.percentage / loadingProgress.length;
    });
    setPercentage(Math.round(total));
  };

  return (
    <FormPageComponent
      hasError={!!errorMessage}
      title="Edit Toolkit"
      buttonTitle="Submit"
      buttonDisabled={!isValid}
      close={() => navigate(-1)}
      submit={handleSubmit(submit)}
      scrollToTop={scrollToTop}
    >
      {loading
        ? <LinearProgress variant="determinate" color="secondary" value={percentage}  sx={{ zIndex: 2 }} />
        : <></>
      }
      <Fragment>
        <Grid item sx={{ flexGrow: 1 }}>
          {!!errorMessage
            && <Alert severity="error">{errorMessage}</Alert>
          }
        </Grid>

        <ModalCardComponent>
          <SectionHeader title="Toolkit details" />
          <Fragment>
            <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
              <Grid item xs={12}>
                <FormControlImage
                  accept=".pdf"
                  data-testid="add-toolkit-packshot"
                  control={control}
                  initialValue={digitalToolkit.ThumbnailUrl || digitalToolkit.FileUrl}
                  initialFileType='image'
                  field={{
                    label: 'Toolkit PDF',
                    name: 'packshot',
                    inputType: 'logo',
                    validation: {
                      required: REQUIRED_VALIDATION,
                      validate : FILE_VALIDATION_WITH_TYPE(50, '.pdf')
                    }
                  }}
                  title="Toolkit PDF"
                  subtitle="Upload the Toolkit PDF."
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <FormControlInput
                  data-testid="add-toolkit-title"
                  control={control}
                  field={{
                    label: 'Title',
                    name: 'title',
                    inputType: 'text',
                    validation: {
                      required: REQUIRED_VALIDATION,
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <FormControlInput
                  data-testid="add-toolkit-description"
                  control={control}
                  multiline
                  field={{
                    label: 'Description',
                    name: 'description',
                    inputType: 'text',
                    validation: {
                      required: REQUIRED_VALIDATION,
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ margin: '16px 0 0' }}>
                <AssetStatusButtonGroup
                  subtitle="Published toolkits are visible to customers and unpublished toolkits are hidden."
                  title="Toolkit Status"
                  value={getValues('published')}
                  data-testid="add-toolkit-published"
                  onChange={(value: boolean) => setValue('published', value, { shouldDirty: true, shouldValidate: true })}
                  options={buttonGroupOptions}
                />
              </Grid>
            </Grid>
          </Fragment>
        </ModalCardComponent>
        {loading ? (
          <div style={styles.overlay}>
          </div>
        ) : (
          <></>
        )}
      </Fragment>
    </FormPageComponent>
  );
}

const styles: IStyles = {
  fg1: { flexGrow: 1 },
  mb16: { marginBottom: '16px' },
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  }
};
