import { Alert, Box, Grid } from '@mui/material';
import { ButtonContainedPrimary } from '../components-atoms/ButtonComponents';
import { InfoComponent } from '../components-atoms/InfoComponent';
import { BodyOneSecondary } from '../components-atoms/TypographyComponents';
import { EditorsPickRow } from '../components-molecules/CombinationRow';
import { MiniDialog } from '../components-molecules/dialogs/MiniDialog';
import { Section } from '../components-molecules/section/Section';
import { SectionHeader } from '../components-molecules/section/SectionHeader';
import AddIcon from '@mui/icons-material/Add';
import { useState, useCallback, useEffect } from 'react';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { Dashboard } from '../components-organisms/Dashboard';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormControlSelect } from '../components-molecules/FormControl';
import { CreateEditorsPickRequest, DeleteEditorsPickRequest, GetAllServicesRequest, GetEditorsPickRequest, OrderEditorsPickRequest } from '../service/serviceService';

export function EditorsPickAdmin(): JSX.Element {
  const [dialog, setDialog] = useState(false);
  const [services, setServices] = useState<IServiceByCategory[]>([]);
  const [serviceOptions, setServiceOptions] = useState<{ name: string; value: GUID; }[]>([]);
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [idToDelete, setIdToDelete] = useState<GUID>();
  const token = useTypedSelector((state) => state.userReducer.token);
  const {
    control,
    reset,
    setValue,
    formState: { isValid },
    handleSubmit,
  } = useForm({ mode: 'all', reValidateMode: 'onChange' });
  const [editorsPick, setEditorsPick] = useState<EditorsPick[]>([]);
  const getEditorsPickList = useCallback(() => {
    if (token) {
      GetEditorsPickRequest(
        token,
        (response) => setEditorsPick(response.Result),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  }, [token]);
  const addEditorsPick: SubmitHandler<{ ServiceId: string; }> = useCallback((data): void => {
    if (token) {
      setError('');
      CreateEditorsPickRequest(
        token,
        {
          ServiceId: data.ServiceId,
          Order: editorsPick.length + 1
        },
        () => {
          getEditorsPickList();
          setDialog(false);
          setServiceOptions([]);
          reset();
        },
        (error) => {
          setError(error.response?.data.Error || '');
        }
      );
    }
  }, [token, editorsPick, getEditorsPickList, reset]);
  const getCategories = useCallback(() => {
    if (token) {
      GetAllServicesRequest(
        token,
        (response) => setServices(response.Result),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  }, [token]);
  const onDelete = (id: GUID) => {
    if (token) {
      DeleteEditorsPickRequest(
        token,
        id,
        () => getEditorsPickList(),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };
  useEffect(() => {
    getEditorsPickList();
    getCategories();
  }, [getCategories, getEditorsPickList]);
  const onCategoryChange = (value: string) => {
    const servicesOfCategory = services.find(f => f.CategoryName === value);
    setServiceOptions(servicesOfCategory ? servicesOfCategory.Services.map(m => ({ name: m.ServiceName, value: m.ServiceId })) : []);
  };
  const onMove = (newData: EditorsPick[]) => {
    if (token) {
      const idList = newData.map(m => ({ ServiceId: m.ServiceId }));
      OrderEditorsPickRequest(
        token,
        idList,
        () => getEditorsPickList(),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };
  return (
    <Dashboard justifyContent="flex-start">
      <PageHeader title="Editor's Pick Section" />
      <Section>
        <SectionHeader
          title="Selection and order of displayed services"
          subtitle="You can add up to 8 services to be displayed in the Editor’s Pick section. You can remove already added services or change their order. Services are displayed on the homepage in the set order."
          button={
            <ButtonContainedPrimary
              data-testid="editors-pick-add-button"
              startIcon={<AddIcon />}
              sx={{ maxWidth: '20%' }}
              onClick={() => setDialog(true)}
              disabled={editorsPick.length >= 8}
            >
              Add Service
            </ButtonContainedPrimary>
          }
        />
        <>
          {editorsPick.length > 0 ? (
            <Box sx={{ margin: '24px 0 0' }}>
              <EditorsPickRow
                onMove={onMove}
                dataset={editorsPick}
                onDelete={(id: GUID) => { setDeleteDialog(true); setIdToDelete(id); }}
              />
            </Box>
          ) : (
            <Box sx={{ margin: '24px 0 16px' }}>
              <InfoComponent
                title="Editor's Pick Services"
                description="You haven’t set any services as editor's pick yet."
              />
            </Box>
          )}
        </>
      </Section>
      <MiniDialog
        title="Add Editor's Pick"
        open={dialog}
        disabled={!isValid || !!error}
        close={() => {
          setDialog(false);
          setServiceOptions([]);
          reset();
          setError('');
        }}
        submit={handleSubmit(addEditorsPick)}
      >
        <Grid item sx={{ flexGrow: 1 }}>
          {!!error
            && <Alert severity="error">{error}</Alert>
          }
        </Grid>
        <Box sx={{ padding: '0 24px' }}>
          <BodyOneSecondary>
            Select a category first and pick one of the available services you would like to add to Editor’s Pick section on the homepage.
          </BodyOneSecondary>

          <FormControlSelect
            selectProps={{
              onChange: (e: any) => {
                setValue('categoryId', e.target.value, { shouldDirty: true, shouldValidate: true });
                onCategoryChange(e.target.value);
                setError('');
              }
            }}
            data-testid="editors-pick-category-id-dropdown"
            control={control}
            field={{
              label: 'Category',
              name: 'categoryId',
              inputType: 'select',
              options: services.map(m => ({ name: m.CategoryName, value: m.CategoryName })),
              validation: {
                required: true
              }
            }} />
          <FormControlSelect
            control={control}
            data-testid="editors-pick-service-id-dropdown"
            field={{
              label: 'Service',
              name: 'ServiceId',
              inputType: 'select',
              options: serviceOptions,
              validation: {
                required: true
              }
            }} />
        </Box>
      </MiniDialog>
      <MiniDialog
        title="Delete Editor's Pick"
        open={deleteDialog}
        disabled={!idToDelete}
        close={() => {
          setDeleteDialog(false);
          setIdToDelete(undefined);
        }}
        submit={() => {
          if (idToDelete) {
            onDelete(idToDelete);
          }
          setDeleteDialog(false);
        }}>
        <Box sx={{ padding: '0 24px' }}>
          <BodyOneSecondary>
            {'Are you sure that you want to remove this service from Editor\'s pick?'}
          </BodyOneSecondary>
        </Box>
      </MiniDialog>
    </Dashboard >
  );
}
