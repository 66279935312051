/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Container, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import DigitalAssetCard from '../components-organisms/cards/DigitalAssetCard';
import { ListDivider } from '../components-molecules/Divider';
import { LIGHT_THEME } from '../constants/theme';
import { SORT_LABELS, getSortTitle } from '../constants/digitalAssets';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { BodyOneSecondary, CaptionSecondary } from '../components-atoms/TypographyComponents';
import { ButtonContainedPrimary } from '../components-atoms/ButtonComponents';
import AddIcon from '@mui/icons-material/Add';
import { MiniDialog } from '../components-molecules/dialogs/MiniDialog';

const MAX_ASSET_LIMIT = 25;

interface IProps {
  digitalAssets: DigitalAssetItem[];
  onEdit?: (id: string) => void;
  onClick: (id: string) => void;
  onDelete?: (id: string) => void;
  isAdmin?: boolean;
  loadMore: () => void;
  total: number;
  canTouch?: boolean;
  selectedAssets?: DigitalAsset[];
  setSelectedAssets?: React.Dispatch<React.SetStateAction<DigitalAsset[]>>;
}

export function DigitalAssetsList({ digitalAssets, onEdit, onClick, onDelete, isAdmin, loadMore, total }: IProps): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const [selectedAssets]: [DigitalAsset[]] = useTypedSelector((state) => [state.digitalAssetReducer.selectedAssets]);
  const [savedQuery, sortType] = useTypedSelector((state) => [state.digitalAssetReducer.search, state.digitalAssetReducer.sortType]);
  const [modalOpen, setModalOpen] = useState('');
  const mdDown = useMediaQuery(LIGHT_THEME.breakpoints.down('md'));
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  const fromShare = useMemo(() => window.location.search.includes('shareToken'), [window.location.href]);
  const user = useTypedSelector((state) => state.userReducer.data);
  const isTMorSM = user?.IsTerritoryManager || user?.IsSalesManager;

  const getDataLength =()=>{
    let total = 0;
    digitalAssets.forEach(x=>{
      total += x.DigitalAssets.length;
    });
    return total;
  };

  const addAsset = () => {
    navigate('/admin/add-asset', { state: { navigateBackTo: window.location.pathname } });
  };

  const handleCheck = (digitalAsset: any) => {
    const isSelected = selectedAssets && selectedAssets?.some((asset: any) => JSON.stringify(digitalAsset) === JSON.stringify(asset));

    if (selectedAssets && digitalAsset) {
      if (isSelected) {
        dispatch({
          type: 'SET_DIGITAL_ASSET_SELECT', 
          payload: { 
            selectedAssets: selectedAssets.filter((asset: any) => JSON.stringify(digitalAsset) !== JSON.stringify(asset))
          }
        });
        return;
      }

      if (selectedAssets.length === MAX_ASSET_LIMIT) {
        setModalOpen('error');
        return;
      }

      dispatch({
        type: 'SET_DIGITAL_ASSET_SELECT', 
        payload: { 
          selectedAssets: [...selectedAssets, digitalAsset] 
        }
      });
    }
  };

  function renderErrorModal() {
    if (modalOpen === 'error') {
      return (
        <MiniDialog
          title="Multiple asset download"
          open={modalOpen === 'error'}
          close={() => setModalOpen('')}
          confirm
        >
          <Box sx={{ padding: '0 24px' }}>
            <BodyOneSecondary>
              <p>
                  You can only download 25 assets at a time.
              </p>
              <p>
                  To select and download other assets you will have to first deselect some of the assets in your current selection, or download current selection first and continue with other assets.
              </p>
            </BodyOneSecondary>
          </Box>
        </MiniDialog>
      );
    }
  }

  function renderTitle() {
    return(
      <Stack
        direction='row'
        justifyContent="space-between"
        mt={smallDown ? 2 : 4} 
        mr={1}>
        <Stack
          gap={2} 
          direction={mdDown ? 'column' : 'row'} 
          alignItems={mdDown ? 'flex-start' : 'center'}>
          <Typography fontSize="20px" fontWeight="600">
            {fromShare ? SORT_LABELS.Share : savedQuery?.length > 0 ? SORT_LABELS.Query : getSortTitle(sortType)}
          </Typography>
          <CaptionSecondary>
            Showing 1 - {digitalAssets.map(m => m.DigitalAssets).flat().length} of {total}
          </CaptionSecondary>
        </Stack>
        {isAdmin && !isTMorSM
          ? <ButtonContainedPrimary
            onClick={addAsset}
            startIcon={<AddIcon />}
            sx={{ height: '42px' }}>
            Add Asset
          </ButtonContainedPrimary>
          : null}
      </Stack>
    );
  }

  function renderList(digitalAsset: any) {
    return (
      <Grid container item columnSpacing={'30px'} rowSpacing={3} sx={{ width: 'calc(100% + 14px)' }}>
        {digitalAsset.DigitalAssets.map((asset: any) =>
          <DigitalAssetCard
            isAdmin={isAdmin}
            isTMorSM={isTMorSM}
            onClick={() => onClick(asset.DigitalAssetId)}
            key={asset.DigitalAssetId}
            digitalAsset={asset}
            onEdit={() => { if (onEdit) onEdit(asset.DigitalAssetId); }}
            onDelete={() => { if (onDelete) onDelete(asset.DigitalAssetId); }}
            listPage
            isSelected={selectedAssets.filter(a => a.DigitalAssetId === asset.DigitalAssetId).length > 0}
            handleCheck={handleCheck}
          />)}
      </Grid>
    );
  }

  return (
    <Box
      data-test-id="digital-asset-list"
      pl={{ xs: '20px', sm: 4, md: 4, lg: 4, xl: 4 }}>
      {renderTitle()}
      {renderErrorModal()}
      <InfiniteScroll
        style={{ overflowY: 'hidden', marginBottom: '16px' }}
        dataLength={getDataLength()} //This is important field to render the next data
        next={loadMore}
        scrollThreshold={smallDown ? '550px' : '50px'}
        hasMore={getDataLength() < total}
        loader={
          <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
            <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
          </Container>
        }
      >
        {digitalAssets.map((digitalAsset) => {
          if (sortType === 'BestMatch') {
            return (
              <Stack mt='34px'>
                {renderList(digitalAsset)}
              </Stack>
            );
          }
          return (
            <ListDivider
              key={digitalAsset.GroupName}
              division={{ name: digitalAsset.GroupName, length: digitalAsset.DigitalAssets.length }}
              caption={{ singular: 'Asset', plural: 'Assets' }}
              halfMargin
              verticalPadding
            >
              {renderList(digitalAsset)}
            </ListDivider>
          );
        })}
      </InfiniteScroll>
    </Box >
  );
}
