/* eslint-disable @typescript-eslint/naming-convention */
const testEnv = {
  apiUrl: 'https://mc-api.hncdev.co.uk',
  azureConnectionString: 'DefaultEndpointsProtocol=https;AccountName=hncdev;AccountKey=zs4iDk8egJHbAkLdQtlcTCfc2uWquyKfhvWlkzTZSeJLSoQg6Ulkv4TioHfxRVorypQ8/LMFEqzQJPuBWVfbfA==',
  containerName: 'merchantconnect-dev',
  docRepoContainerName: 'merchantconnect-doc-repo-dev',
  assetFolder: 'brand_assets_images',
  merchantFolder: 'logos',
  articleFolder: 'articles',
  productFolder: 'npd_images',
  digitalToolkitFolder: 'digitaltoolkit',
  industryEventsFolder: 'industryevents',
  insightFolder: 'industry_insight',
  newsletterFolder: 'newsletter',
  industryCloudUrl: 'https://dev-ic.hnccloud.com/',
  gaToken: 'G-K5FYQ2TC50',
  gaAdminToken: 'G-79SN2BTMHP',
  heritageToolkitId: '193e890b-0755-44cb-987d-b1cf0cd6d879'
};

type EnvironmentVariables = typeof testEnv;

const liveEnv = (window as any).__env__ as EnvironmentVariables;

export const environment = liveEnv || testEnv;
