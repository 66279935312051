
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import { useState, MouseEvent } from 'react';
import { Menu, Grid, Box } from '@mui/material';
import { DropdownMenuButton } from './DropdownMenuButton';
import { ButtonTextPrimary } from '../components-atoms/ButtonComponents';
import { SortType } from '../utils/sortTypes';

interface IProps {
  selectedSortType: string | SortType;
  menuItems: Array<{
    title: string;
    value: string;
    handleSelect: () => void;
  }>;
  activeButtons?: boolean;
}
// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
export function SortButton(props: IProps): JSX.Element {
  const { selectedSortType, menuItems, activeButtons } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <ButtonTextPrimary
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{ margin: '0 8px 0 0', padding: '8px 11px !important' }}
          startIcon={<SortByAlphaIcon sx={{ width: '24px', height: '24px' }} color={(activeButtons) ? 'primary' : 'inherit'} />}
        >
          Sort
        </ButtonTextPrimary>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          marginTop: '10px',
          borderRadius: '12px',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          role: 'listbox',
        }}
      >
        <Grid
          sx={{
            width: '256px',
            padding: '8px 4px',
            borderRadius: '8px',
          }}>
          {menuItems
            && menuItems.map((i: any) => (
              <DropdownMenuButton
                key={i.title}
                title={i.title}
                value={i.value}
                handleClick={() => { i.handleSelect(); handleClose(); }}
                selected={selectedSortType === i.value ? true : false}
              />
            ))}
        </Grid>
      </Menu>

    </>
  );
}
