import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { ButtonTextPrimary } from './ButtonComponents';

interface InfoComponentProps {
  title: string;
  description: string;
  buttonText: string;
  onClick: () => void;
}

export function InfoComponentWithButton({ title, description, onClick, buttonText }: InfoComponentProps): JSX.Element {
  return (
    <Alert severity="info" sx={{ borderRadius: '4px', width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
        <div style={{ flex: 1 }}>
          <AlertTitle>{title}</AlertTitle>
          {description}
        </div>
        <ButtonTextPrimary onClick={onClick} sx={{
          width: 114,
          padding: '6px 10px',
        }}>
          {buttonText}
        </ButtonTextPrimary>
      </div>
    </Alert>
  );
}
