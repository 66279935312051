import { useState } from 'react';
import { TabContext, TabList } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { Dashboard } from '../components-organisms/Dashboard';
import { DocumentRepository } from '../components-organisms/DocumentRepository';
import { useLocation } from 'react-router-dom';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';

export function DocumentRepositoryTM(): JSX.Element {
  const { state } = useLocation();
  const [currentTab, setCurrentTab] = useState<'content' | 'accessRights'>(state && state.currentTab ? state.currentTab : 'content');
  return (
    <Dashboard justifyContent='flex-start'>
      <PageHeader title="Document Library" />
      <TabContext value={currentTab}>
        <TabList onChange={(e: unknown, newValue: 'content' | 'accessRights') => setCurrentTab(newValue)} sx={{ margin: '24px 0 0', width: '100%' }}>
          <Tab data-testid={'Document Library-merchants-tab'} label="Merchants" value="content" sx={{ width: '50%', maxWidth: '50%' }} />
          <Tab data-testid={'Document Library-territory-managers-tab'} label="Sales & Territory Managers" value="accessRights" sx={{ width: '50%', maxWidth: '50%' }} />
        </TabList>
        <Box sx={{ minHeight: '80vh', width: '100%' }}>
          <DocumentRepository tabValue={currentTab} currentTab={currentTab} />
        </Box>
      </TabContext>
    </Dashboard>
  );
}
