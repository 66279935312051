import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BodyOneSecondary, CaptionPrimary, CaptionSecondary, HeaderFourSecondary, HeaderSix } from '../components-atoms/TypographyComponents';
import { Dashboard } from '../components-organisms/Dashboard';
import { Footer } from '../components-organisms/Footer';
import '../scss/components/_policy-pages.scss';
import { gaService } from '../service/gaService';

export function TermsOfUse(): JSX.Element {
  const navigate = useNavigate();
  
  useEffect(() => {
    gaService.pageView('Terms of use');
  }, []);

  return (
    <Dashboard noDrawer noMargin noPadding fullWidth merchantPage>
      <Grid container direction="column" sx={{ width: '100%' }} className="policy-page">
        <Grid container justifyContent="center">
          <Grid direction="column" container item alignItems="center">
            <Grid sx={{ margin: '12px 0px 28px', maxWidth: '1408px', width: '100%', padding: '0 24px' }}>
              <CaptionPrimary style={{ color: 'rgba(1, 33, 105, 1)', cursor: 'pointer' }} onClick={() => navigate('/')}>Home</CaptionPrimary>
              <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
              <CaptionPrimary style={{ marginBottom: '4px' }}>Legal Notices</CaptionPrimary>
            </Grid>
            <Grid
              container
              justifyContent="center"
              sx={{
                margin: '90px 0 32px',
                background: 'linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)'
              }}>
              <Grid
                container
                sx={{
                  maxWidth: '1408px',
                  width: '100%',
                  padding: '0 24px'
                }}>
                <HeaderFourSecondary style={{ maxWidth: '783px', width: '100%', marginBottom: '4px' }}>Legal Notices</HeaderFourSecondary>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ color: 'rgba(0, 0, 0, 0.54)', maxWidth: { sm: '672px' }, width: '100%', padding: '0 24px'  }}>
            <HeaderFourSecondary>
              Terms of use
            </HeaderFourSecondary>
            <BodyOneSecondary>
              <p>
                Your access to and use of this website (this/our Site) is subject to the following Terms of Use. By accessing and using our Site you confirm that you accept these Terms of Use, without limitation or qualification and that you agree to comply with them. If you do not agree to these Terms of Use, you must not use our site.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Other applicable terms
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                These Terms of Use refer to and incorporate the following additional terms, which also apply to your use of our Site:
              </p>
            </BodyOneSecondary>
            <ul>
              <li>
                Our&nbsp;
                <a href="https://www.duluxtradepaintexpert.co.uk/en/privacy-policy" target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
                , which sets out the terms on which we process any personal data we collect from you, or that you provide to us. By using our Site, you consent to such processing and you warrant that all data provided by you is accurate.</li>
              <li>
                Our&nbsp;
                <a href="https://www.duluxtradepaintexpert.co.uk/en/cookies" target="_blank" rel="noreferrer">
                  Cookie Policy
                </a>
                , which sets out information about the cookies on our Site.
              </li>
              <li>
                Our&nbsp;
                <a href="https://www.duluxtradepaintexpert.co.uk/en/term-use" target="_blank" rel="noreferrer">
                  Acceptable Use Policy
                </a>
                &nbsp;below, which sets out the permitted uses and prohibited uses of our Site. When using our Site, you must comply with this Acceptable Use Policy.
              </li>
            </ul>
            <p>&nbsp;</p>
            <HeaderSix>
              About us
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                This Site is owned and operated by AkzoNobel Decorative Coatings B.V. a company registered in the Netherlands under number 28080245 and whose registered office is at Sassenheim, Rijksstraatweg 31, 2171AJ, Netherlands (we, our or us).
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Accessing our site
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                Access to our Site is permitted on a temporary basis, and we reserve the right to withdraw or amend the service we provide on our Site without notice. We do not guarantee that our Site, or any content on it, will always be available or be uninterrupted. We may suspend, withdraw, discontinue or change all or any part of our Site without notice. We will not be liable if for any reason our Site is unavailable at any time or for any period. From time to time, we may restrict access to some parts of our Site, or our entire Site.
              </p>
              <p>
                You are responsible for making all arrangements necessary for you to have access to our site.
              </p>
              <p>
                You are also responsible for ensuring that all persons who access our Site through your internet connection are aware of these Terms of Use and other applicable terms, and that they comply with them.
              </p>
              <p>
                If you choose, or are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any third party. We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time at our sole discretion.
              </p>
              <p>
                You are not permitted to use, or cause others to use, any automated system or software to extract content or data from our Site for commercial purposes except in cases where you or any applicable third party has entered into a written agreement with us that expressly permits such activity.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Changes to these Terms Of Use
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                We may from time to time vary these Terms of Use. Please check these Terms of Use regularly to ensure you are aware of any variations made by us. If you continue to use our Site, you are deemed to have accepted such variations. If you do not agree to such variations, you should not use our Site.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Changes to our site
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                We may update our Site from time to time, and may change the Content available on our Site at any time. However, please note that any of the content on our Site may be out of date at any given time, and we are under no obligation to update it. We do not guarantee that our Site, or the Content, will be free from errors or omissions.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Intellectual property rights
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                Other than where expressly stated we or our group companies own all intellectual property rights in the Site and in the material published on it (including but not limited to text, images, audio, video, html-code and buttons) (the “Content”). These rights include but are not limited to all copyrights, database rights, trade names and trademarks.
              </p>
              <p>
                You may only view, print out and use the Site and the Content for your own personal, non-commercial use. We and our group companies expressly reserve all intellectual property rights in and to the Site and the Content and your use of the Site and the Content is subject to the following restrictions. You must not:
              </p>
              <ul>
                <li>
                  remove any copyright or other proprietary notices contained in the Content;
                </li>
                <li>
                  use any Content from the Site in any manner that may infringe any copyright, intellectual property right or proprietary right of us or any third parties; or
                </li>
                <li>
                  reproduce, modify, display, perform, publish, distribute, disseminate, broadcast, communicate to the public or circulate to any third party or exploit this Site and/or the Content in any way including for any commercial purpose, without our prior written consent.
                </li>
              </ul>
              <p>
                We expressly reserve all rights in and to the duluxtradepaintexpert.co.uk and duluxtrade.co.uk domain names and all related domains and sub-domains, the name “AkzoNobel”, our logo and our service marks, trading names and/or trade marks. Other trade marks, products and company names mentioned on our Site may be trademarks of their respective owners or licensors and the rights in such marks are reserved to their respective owners or licensors.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Colour accuracy
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                We have made every effort to make the colours on screen as accurate as possible. Unfortunately, we cannot guarantee an exact colour match of the on screen colour to the colours of the actual products and the colour appearing on screen should not be relied on as being such. Colours on screen may vary depending on your screen settings and resolution.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Accuracy of information and content
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                The Content and information provided on our Site is free of charge and for informational purposes only and does not create a business or professional services relationship between you and us. We have made reasonable efforts to ensure that all information and Content provided on this Site is accurate at the time of inclusion, however there may be inadvertent and occasional errors.
              </p>
              <p>
                Views and opinions expressed in Content submitted by other users of our Site are those of the individual submitting the material, not those of AkzoNobel and we accept no responsibility for the content of such posts.
              </p>
              <p>
                We do not control the information provided by other users of our Site which is made available through the Site and Content may not be pre-moderated.
              </p>
              <p>
                The Site and the information and the Content on the Site are provided “as is”. We do not guarantee, and make no representation or warranties of any kind whether express or implied about the information, and Content provided through our Site, including any items used either directly or indirectly from our Site, and we reserve the right to make changes and corrections at any time, without notice. Commentary and information posted on the Site is not intended to amount to advice on which reliance should be placed. We accept no liability for any inaccuracies or omissions in the Site and any decisions based on information and opinions contained in the Site are the sole responsibility of the user.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Viruses
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                We have made reasonable efforts to ensure the Site is free from viruses. However, we do not guarantee and make no representation or warranty that our Site is free from bugs and/or viruses and you are responsible for ensuring you have installed adequate virus checking software.
              </p>
              <p>
                You must not misuse our Site by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to our Site, the server on which our Site is stored or any server, computer or database connected to our Site. You must not attack our Site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you may be committing a criminal offence. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our Site will cease immediately.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Linking to our site
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                You may link to the home page of our Site, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists. You must not establish a link to our Site in any website that is not owned by you.
              </p>
              <p>
                Our Site must not be framed on any other site, nor may you create a link to any part of our Site other than the home page.
              </p>
              <p>
                We reserve the right to withdraw linking permission without notice.
              </p>
              <p>
                The website in which you are linking must comply in all respects with the content standards set out in our Acceptable Use Policy. If you wish to make any use of Content on our Site other than that set out above, please&nbsp;
                <a href="https://www.duluxtradepaintexpert.co.uk/en/advice/contact-us" target="_blank" rel="noreferrer">
                  contact us
                </a>
                .
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Links to other websites
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                Our Site and/or the Content may contain links to third party websites. If you decide to visit any third party site, you do so at your own risk. We are not responsible for the content, accuracy or opinions expressed in such websites. Links do not imply that we are, or our Site is, affiliated to or associated with such sites.
              </p>
              <p>
                Your browsing and interaction on any other website, including websites which have a link to our Site, is subject to that website’s own rules and policies. Please read those rules and policies before proceeding.
              </p>
              <p>
                Our communications with you may contain information sourced from third party websites. Material from a third party site will be clearly marked and a link to the source website will be provided. We accept no responsibility or liability for any material supplied by or contained on any third party website which is linked from our communications with you, or any use of personal data by such a third party.
              </p>
              <p>
                The inclusion of any link in our communications with you does not imply endorsement by us of the linked site. If you decide to access linked third party websites, you do so at your own risk.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Advertising
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                The Site may contain advertising of third party products and services. We shall not be responsible for any inaccuracy in the advertising materials and give no guarantee, representation or warranty, whether express or implied, about the quality or performance of such goods or supply.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Offers and promotions
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                We may display offers, promotions or competitions on the Site from time to time and these will be subject to specific terms and conditions of which we will make you aware.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Uploading or posting material to our site
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                Whenever you make use of a feature that allows you to upload content to our Site or to make contact with other users of our Site, you must comply with the content standards set out in our Acceptable Use Policy. You warrant that any such contribution does comply with those standards, and you will be liable to us and indemnify us for any breach of that warranty. This means that you will be responsible for any loss or damage we suffer as a result of your breach of warranty.
              </p>
              <p>
                You grant to us a non-exclusive, perpetual, irrevocable, transferable, royalty-free license (including full rights to sub-license) to use any content that you upload to or post to our Site (“Your Content”) (including, without limit, the right to adapt, alter, amend or change Your Content) in any media throughout the world without restriction, and you warrant that any material you submit is your own work or that you have obtained all necessary rights and permissions of the relevant owner and that you have all relevant rights in Your Content to enable you to grant the rights in this clause.
              </p>
              <p>
                You hereby unconditionally and irrevocably waive and agree not to assert any and all moral rights and any other similar rights and all right of publicity and privacy in any country in the world in connection with our exploitation of the rights in Your Content granted hereunder, to the maximum extent permissible by law.
              </p>
              <p>
                Publication of Your Content will be at our sole discretion and we are entitled to make additions or deletions to any such material prior to publication, after publication or to refuse publication.
              </p>
              <p>
                If Your Content contains any material that is not owned by or licensed to you and/or which is subject to third party rights, you are responsible for obtaining, prior to submission of Your Content, all releases, consents and/or licenses necessary to permit use and exploitation of Your Content by us in accordance with these Terms of Use without additional payment.
              </p>
              <p>
                Although we have no obligation to moderate content on our Site, we reserve the right to monitor any information transmitted or received through our Site. We reserve the right to reject, block, suspend or remove Your Content at any time and in our sole discretion. We in no way guarantee that Your Content or any part of it will be displayed on our Site.
              </p>
              <p>
                You represent that you have the lawful right to submit such information and agree that you will not submit any information unless you are legally entitled to do so. We have the right to use, copy, distribute and disclose to third parties any such content for any purpose. We also have the right to disclose your identity to any third party who is claiming that any material posted or uploaded by you to our Site constitutes a violation of their intellectual property rights, or of their right to privacy. We will not be responsible, or liable to any third party, for the content or accuracy of any materials posted by you or any other user of our Site. We have the right to remove any content or posting you make on our Site at our sole discretion.
              </p>
              <p>
                We do not accept unauthorised idea submissions outside of established business relationships. Please do not communicate unauthorised idea submissions to us through this Site. Any ideas disclosed to us outside a pre-existing and documented confidential business relationship are not confidential and we shall therefore be entitled to develop, use, copy and/or commercially exploit these or similar ideas to the fullest extent and without compensating you or accounting to you. By submitting an idea or other detailed submission to us through this Site, you agree to be bound by the terms of this stated policy.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Liability
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                The Content displayed on our Site is provided without any guarantees, conditions, or warranties as to its accuracy.
              </p>
              <p>
                To the full extent allowed by applicable law, we hereby expressly exclude:
              </p>
              <ul>
                <li>
                  all conditions, warranties, representations and other terms which may apply to our Site or the Content, which might otherwise be implied by statute, common law or the law of equity;
                </li>
                <li>
                  any liability for any direct, special, indirect or consequential loss or damage incurred by the user in connection with our Site or in connection with the use, inability to use, or results of the use of our Site, any websites linked to it and any materials posted on it, including:
                </li>
              </ul>
              <ul>
                <li>
                  loss of income or revenue;
                </li>
                <li>
                  loss of profits, sales, business or contracts;
                </li>
                <li>
                  business interruption;
                </li>
                <li>
                  loss of anticipated savings;
                </li>
                <li>
                  additional or wasted expenditure;
                </li>
                <li>
                  loss of privacy or loss of data;
                </li>
                <li>
                  loss of opportunity, goodwill or reputation; or
                </li>
                <li>
                  any other damages of whatsoever kind resulting from whatever cause through the use of the Site and any information and opinions obtained either directly or indirectly from the Site whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable.
                </li>
              </ul>
              <p>
                This exclusion shall include all liability for loss or damage caused by any viruses or any other computer code, files or programs designed to interrupt, restrict, destroy, limit the functionality of or compromise the integrity of any computer software or hardware or telecommunications equipment or material transmitted with or as part of the pages of the Site or any material downloaded from it.
              </p>
              <p>
                We assume no responsibility for the content of websites linked on our Site and . will not be liable for any loss or damage that may arise from your use of them.
              </p>
              <p>
                Please note that some jurisdictions do not allow the exclusion of implied warranties, so some or all of the above exclusions may not apply to you.
              </p>
              <p>
                In addition, nothing shall exclude our liability for injury or death caused by our negligence, nor our liability for fraudulent misrepresentation, nor any other liability which cannot be excluded or limited under applicable law.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Indemnity
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                You agree to compensate us in full from and against any and all claims, liabilities, losses and expenses (including any legal fees and any third party claims) arising out of, or related to, any breach by you of the Terms of Use.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Severability
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                If these Terms of Use are or become partially void, the parties will continue to be bound by the remainder of the same. The parties shall replace the void part with provisions that are valid and have legal effect that corresponds with those of the void part as much as possible, taking into account the content and the purpose of these Terms of Use.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              International use
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                The Site is not directed at any person in any jurisdiction where for any reason the publication or availability of the Site is prohibited. Those in respect of whom such prohibitions apply must not access the Site.
              </p>
              <p>
                We do not represent that anything on the Site is appropriate for use or permitted by local laws in any jurisdictions. Those who access the Site do so on their own initiative and are responsible for compliance with applicable local laws or regulations; legal advice should be sought in cases of doubt.
              </p>
              <p>
                In the event that a translation of these Terms of Use differs from the original English version, the English version will prevail.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Applicable law
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                The Terms of Use and any dispute or claim arising out of or in connection with them or their subject matter or formation, or your use of the Site, (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of the Netherlands.
              </p>
              <p>
                The courts of the Netherlands will have exclusive jurisdiction over any claim arising from, or related to, use of our Site although we retain the right to bring proceedings against you for breach of these Terms of Use in your country of residence or any other relevant country.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Acceptable use policy
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                This Acceptable Use Policy sets out the terms between you and us under which you may access this website (this/our Site). This Acceptable Use Policy applies to all users of, and visitors to, our Site.
              </p>
              <p>
                Your use of our Site means that you accept, and agree to abide by, all the policies in this Acceptable Use Policy, which supplement our Terms of Use.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Prohibited uses
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                You may use our Site only for lawful purposes. You may not use our Site:
              </p>
              <ul>
                <li>
                  In any way that breaches any applicable local, national or international law or regulation.
                </li>
                <li>
                  In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.
                </li>
                <li>
                  For the purpose of harming or attempting to harm minors in any way.
                </li>
                <li>
                  To send, knowingly receive, upload, download, use or re-use any material which does not comply with our CONTENT STANDARDS below.
                </li>
                <li>
                  To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).
                </li>
                <li>
                  To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.
                </li>
              </ul>
              <p>
                You also agree:
              </p>
              <ul>
                <li>
                  Not to reproduce, duplicate, copy or re-sell any part of our Site in contravention of the provisions of our Terms of Use
                </li>
                <li>
                  Not to access without authority, interfere with, damage or disrupt:
                </li>
                <li>
                  any part of our Site;
                </li>
                <li>
                  any equipment or network on which our Site is stored;
                </li>
                <li>
                  any software used in the provision of our Site; or
                </li>
                <li>
                  any equipment or network or software owned or used by any third party.
                </li>
              </ul>
              <p>
                We may revise this Acceptable Use Policy at any time by amending this page. You are expected to check this page from time to time to take notice of any changes we make, as they are legally binding on you. Some of the provisions contained in this Acceptable Use Policy may also be superseded by provisions or notices published elsewhere on our Site.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Interactive services
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                We may from time to time provide interactive services on our Site, including, without limitation:
              </p>
              <ul>
                <li>
                  Chat rooms; and
                </li>
                <li>
                  Bulletin boards
                </li>
                <li>
                  (Interactive services)
                </li>
              </ul>
              <p>
                Where we do provide any Interactive Service, we will provide clear information to you about the kind of service offered, if it is moderated and what form of moderation is used (including whether it is human or technical).
              </p>
              <p>
                We will do our best to assess any possible risks for users (and in particular, for children) from third parties when they use any Interactive Service provided on our Site, and we will decide in each case whether it is appropriate to use moderation of the relevant service (including what kind of moderation to use) in the light of those risks. However, we are under no obligation to oversee, monitor or moderate any Interactive Service we provide on our Site, and to the fullest extent permitted by law, we expressly exclude our liability for any loss or damage arising from the use of any Interactive Service by a user in contravention of our content standards, whether the service is moderated or not.
              </p>
              <p>
                The use of any of our Interactive Services by a minor is subject to the consent of their parent or guardian. We advise parents who permit their children to use an Interactive Service that it is important that they communicate with their children about their safety online, as moderation is not foolproof. Minors who are using any Interactive Service should be made aware of the potential risks to them.
              </p>
              <p>
                Where we do moderate an Interactive Service, we will normally provide you with a means of contacting the moderator, should a concern or difficulty arise.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Content standards
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                These content standards apply to any and all material which you contribute to our Site (Contributions), and to any Interactive Services associated with it.
              </p>
              <p>
                You must comply with the spirit of the following standards as well as the letter. The standards apply to each part of any Contribution as well as to its whole.
              </p>
              <p>
                Contributions must:
              </p>
              <ul>
                <li>
                  Be accurate (where they state facts);
                </li>
                <li>
                  Be genuinely held (where they state opinions); and
                </li>
                <li>
                  Comply with the applicable law of the Netherlands and in any country from which they are posted.
                </li>
              </ul>
              <p>
                Contributions must not:
              </p>
              <ul>
                <li>
                  Contain any material which is defamatory of any person;
                </li>
                <li>
                  Contain any material which is obscene, offensive, hateful or inflammatory;
                </li>
                <li>
                  Promote sexually explicit material;
                </li>
                <li>
                  Promote violence;
                </li>
                <li>
                  Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;
                </li>
                <li>
                  Infringe any copyright, database right or trade mark of any other person;
                </li>
                <li>
                  Be likely to deceive any person;
                </li>
                <li>
                  Be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence;
                </li>
                <li>
                  Promote any illegal activity;
                </li>
                <li>
                  Be threatening, abuse or invade another’s privacy, or cause annoyance, inconvenience or needless anxiety;
                </li>
                <li>
                  Be likely to harass, upset, embarrass, alarm or annoy any other person;
                </li>
                <li>
                  Be used to impersonate any person, or to misrepresent your identity or affiliation with any person.
                </li>
                <li>
                  Give the impression that they emanate from us, if this is not the case; or
                </li>
                <li>
                  Advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer misuse.
                </li>
              </ul>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Suspension and Termination
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                We will determine, in our discretion, whether there has been a breach of this Acceptable Use Policy through your use of our Site. When a breach of this policy has occurred, we may take such action as we deem appropriate.
              </p>
              <p>
                Failure to comply with this Acceptable Use Policy constitutes a material breach of the Terms of Use upon which you are permitted to use our Site, and may result in our taking all or any of the following actions:
              </p>
              <ul>
                <li>
                  Immediate, temporary or permanent withdrawal of your right to use our Site.
                </li>
                <li>
                  Immediate, temporary or permanent removal of any posting or material uploaded by you to our Site.
                </li>
              </ul>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Issue of a warning to you.
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Further legal action against you.
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                Disclosure of such information to law enforcement authorities as we reasonably feel is necessary.
              </p>
              <p>
                We exclude liability for actions taken in response to breaches of this Acceptable Use Policy. The responses described in this policy are not limited, and we may take any other action we reasonably deem appropriate.
              </p>
              <p>&nbsp;</p>
            </BodyOneSecondary>
            <HeaderSix>
              Contact us
            </HeaderSix>
            <BodyOneSecondary>
              <p>
                To contact us, please&nbsp;
                <a href="https://www.duluxtradepaintexpert.co.uk/en/advice/contact-us" target="_blank" rel="noreferrer">
                  click here
                </a>
                .
              </p>
              <p>
                Last Updated 19/3/2019
              </p>
            </BodyOneSecondary>
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{
        background: 'linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Grid item sx={{ maxWidth: '1408px', width: '100%' }}>
          <Footer />
        </Grid>
      </Grid>
    </Dashboard>
  );
}
