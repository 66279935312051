const INITIAL: TerritoryManagerState = {
  data:[],
  loading:false,
  searchQuery:'',
};
  
export const territoryManagersReducer = (state: TerritoryManagerState = INITIAL, action:TerritoryManagerAction ): TerritoryManagerState => {
  switch (action.type) {
    case 'SET_MANAGERS':
      return { ...state, data: action.payload.currentData };
    case 'CLEAR_MANAGERS':
      return { ...state, data: [] };
    case 'SET_MANAGERS_LOADING':
      return {...state, loading:action.payload.currentData};
    case 'SET_MANAGERS_SEARCH_QUERY':
      return {...state, searchQuery: action.payload.currentData};
    default:
      return state;
  }
};
