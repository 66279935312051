import { useState, useCallback, useEffect } from 'react';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { ListProductsRequest, ProductsListModel } from '../service/serviceService';
import { NPDServiceList } from './NPDServiceList';

interface IProps {
  id: string;
}

export function NPDService({ id }: IProps): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const [products, setProducts] = useState<ProductsListModel[]>([]);
  const pageSize = 10;
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState(0);

  const loadMore = () => {
    if (token) {
      getProducts(token, page + 1, pageSize, products);
      setPage(page + 1);
    }
  };

  const getProducts = useCallback((token: string, page = 0, pageSize = 10, prevData: ProductsListModel[] = []) => {
    if (token) {
      ListProductsRequest(
        token,
        {
          Size: pageSize,
          Page: page,
          ServiceId: id,
        },
        (response) => {
          if (response) {
            setTotal(response.Result.Count);
            setProducts(prevData.concat(response.Result.Result));
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    }
  }, [id]);

  useEffect(() => {
    if (token) getProducts(token);
  }, [token, getProducts]);

  return (
    <NPDServiceList
      total={total}
      products={products}
      loadMore={loadMore}
    />
  );
}
