/* eslint-disable @typescript-eslint/naming-convention */
import { useRef, useState } from 'react';
import { ButtonContainedPrimary } from '../components-atoms/ButtonComponents';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { IStyles } from '../constants/theme';

interface IProps {
  thumbnailUrl: string | undefined;
  previewUrl: string | undefined;
  controlsEnable: boolean;
  noDownload?: boolean;
  style?: React.CSSProperties;
  autoplay?: boolean;
}

export const  VideoComponent=({ thumbnailUrl, previewUrl, controlsEnable, noDownload, style, autoplay = false }: IProps): JSX.Element => {
  const vidRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayVideo = () => {
    if (!isPlaying) {
      vidRef.current && vidRef.current.play();
      setTimeout(() => {
        setIsPlaying(true);
      }, 100);
    } else {
      vidRef.current && vidRef.current.pause();
      setTimeout(() => {
        setIsPlaying(false);
      }, 100);
    }
  };

  const PlayButton = () => {
    if (!controlsEnable) {
      return (
        <div style={styles.buttonContainer}>
          <ButtonContainedPrimary 
            onMouseDown={e => e.stopPropagation()} 
            onClick={handlePlayVideo} 
            sx={styles.button}
            style={{
              opacity: isPlaying? 0.6 : 1,
              backgroundColor : isPlaying? '#808B96': undefined
            }}
          >
            {isPlaying ? <PauseIcon/> : <PlayArrowIcon />}
          </ButtonContainedPrimary>
        </div>
      );
    }
    return<></>;
  };

  return (
    <>
      <video ref={vidRef} preload='auto' autoPlay={autoplay} controls={controlsEnable} controlsList={noDownload ? 'nodownload' : ''} poster={thumbnailUrl} style={{ width: '100%', maxHeight: '70vh', cursor: 'pointer', ...style }}>
        <source src={previewUrl} type="video/mp4" />
      </video>
      <PlayButton/>
    </>
  );
};
const styles: IStyles = {
  buttonContainer: {
    zIndex: 10,
    position: 'absolute',
    top: 'calc(50% - 21px)',
    left: 'calc(50% - 34px)'
  },
  button: {
    height: '42px'
  }
};
