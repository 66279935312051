import { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { SubPageHeader } from './titlebars/SubPageHeader';
import { ButtonContainedPrimary } from '../components-atoms/ButtonComponents';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from '@mui/material';
import { gaService } from '../service/gaService';

interface ModalComponentProps {
  children: null | JSX.Element | (JSX.Element | null)[];
  title: string;
  buttonTitle?: string;
  buttonDisabled?: boolean;
  close?: (refresh?: boolean) => void;
  submit?: (e: any) => void;
  hasError?: boolean;
  buttonLoading?: boolean;
  buttons?: JSX.Element[];
  submitButton?: boolean;
  fullWidth?: boolean;
  assetDetailPage?: boolean;
  scrollToTop?: boolean;
  overFlowHidden?: boolean;
  subPageHeaderStyle?: React.CSSProperties;
}

export function FormPageComponent({ children, title, buttonTitle, buttonDisabled, close, submit, hasError = false, buttonLoading, buttons, submitButton = true, fullWidth, scrollToTop, overFlowHidden, subPageHeaderStyle}: ModalComponentProps): JSX.Element {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const mobileView = useMediaQuery('(max-width: 480px)');

  useEffect(() => {
    gaService.pageView(title);
  }, [title]);
  
  useEffect(() => {
    setIsSubmitted(false);
    if (hasError || scrollToTop) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [hasError, scrollToTop]);

  return (
    <Box component="form" noValidate onSubmit={(e: any) => { setIsSubmitted(true); if (submit) submit(e); }} sx={{ width: '100vw', minHeight: '100vh', borderRadius: 0, margin: 0, padding: '0 0 36px', overflow: overFlowHidden ? 'hidden': undefined }}>
      <Box sx={{ width: '100vw', boxShadow: '0 3px 3px 0 rgb(51 51 51 / 11%)', background: '#fff', position: 'sticky', top: 0, zIndex: 99 }}>
        <Container sx={{
          maxWidth: fullWidth ? 'auto !important' : '840px !important',
          padding: fullWidth ? 'auto !important' : '0 !important'
        }}>
          <Toolbar variant="regular" sx={{ height: mobileView ? '106px' : '96px' } }>
            <SubPageHeader
              title={title}
              backCallback={close}
              subPageHeaderStyle={subPageHeaderStyle}
              buttons={[submitButton
                ? <ButtonContainedPrimary data-testid={`modal-button-${buttonTitle}`} key={0} type="submit" disabled={isSubmitted || buttonDisabled}>
                  {buttonLoading ? (
                    <CircularProgress
                      color='inherit'
                      size={18}
                      sx={{ marginRight: 2 }}
                    />
                  ) : (
                    <></>
                  )}
                  {buttonTitle}</ButtonContainedPrimary> : <></>,
              ].concat(buttons ? buttons : [])}
              assetDetailPage
            />
          </Toolbar>
        </Container>
      </Box>
      {children}
    </Box>
  );
}
