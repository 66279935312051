/* eslint-disable @typescript-eslint/naming-convention */
import { CircularProgress, Container, Grid, useMediaQuery } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { SearchbarWithDeboubce } from '../components-molecules/SearchBarWithDebounce';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { LIGHT_THEME } from '../constants/theme';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { GetInvitationsPending, InvitationGroup } from '../service/serviceService';
import { InvitationsList } from './InvitationsList';

const SORT_TYPES = [
  {
    title: 'A \u2014 Z',
    value: 'asc'
  },
  {
    title: 'Z \u2014 A',
    value: 'desc'
  }
];

export default function PendingInvitations():JSX.Element {
  const navigate = useNavigate();
  const token = useTypedSelector(state => state.userReducer.token);
  const size = 10;
  const [sortType, setSortType] = useState<string>('desc');
  const [query, setQuery] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [data, setData] = useState<InvitationGroup[]>([]);
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));

  const getInvitation = useCallback(
    (): void => {
      if(token){
        setLoading(true);
        GetInvitationsPending(token,{
          OrderBy:sortType,
          Page:page,
          Query:query,
          Size:size,
        },
        (response) => {
          if (page === 0) {
            setData(response.Result.Result);
          }else{
            let temp = [...data];
            response.Result.Result.map(m => {
              const tempMatch = temp.find((f) => f.Group === m.Group);
              if (tempMatch) {
                temp[temp.indexOf(tempMatch)].MerchantRegistrations = [...temp[temp.indexOf(tempMatch)].MerchantRegistrations, ...m.MerchantRegistrations];
              } else {
                temp = [...temp, m];
              }
            });
            setData(temp);
          }
          setTotalCount(response.Result.Count);
  
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, query, sortType, page],
  );
  
  useEffect(() => {
    getInvitation();
  }, [query,sortType,getInvitation,page]);

  const searchOnChange=(text:string) => {
    setPage(0);
    setLoading(true);
    setTimeout(() => {
      setQuery(text);
    }, 100);
  };


  const loadMore = () => {
    setPage(prev => prev + 1);
  };
  
  const getItemCount= () => {
    let totalItemCount = 0;
    data.forEach(group => {
      totalItemCount += group.MerchantRegistrations.length;
    });
    return totalItemCount;
  };

  return (
    <InfiniteScroll
      style={{ overflow: 'hidden' }}
      dataLength={getItemCount()} //This is important field to render the next data
      next={() => loadMore()}
      scrollThreshold={smallDown ? '550px' : '50px'}
      hasMore={totalCount ? getItemCount() < totalCount:false}
      loader={
        <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
          <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
        </Container>
      }
    >
      <PageHeader
        buttonTitle=""
        subtitle={
          (totalCount && totalCount > 0)
            ? `${totalCount} ${(totalCount > 1) ? 'Pending Invitations' : 'Pending Invitation'}`
            : loading
              ? ''
              : 'No Pending Invitation Yet'
        }
        activeButtons={true}
        modalCallback={() => navigate('/admin/add-admin-user')}
        onClickSearch={() => setSearchOpen(!searchOpen)}
        sortTypes={SORT_TYPES}
        selectedSortType={sortType}
        handleSortSelect={val =>{
          setPage(0);
          setSortType(val);
        }}
      />
      <SearchbarWithDeboubce
        isOpen={searchOpen}
        query={query}
        placeholder="Search Invitations"
        onCancel={() => { setSearchOpen(false); setQuery(''); }}
        onChange={searchOnChange}
      />
      <Container sx={{pb:9,'&.MuiContainer-root':{paddingLeft:'0px',paddingRight:'0px'}}}>
        {
          loading && page === 0
            ? <Grid container item justifyContent="center" alignItems="center">
              <CircularProgress
                color="primary"
                size={50}
                style={{ zIndex: 999, margin: '56px 0' }}
              />
            </Grid>
            :<InvitationsList data={data} tab='pending' />
        }
        
      </Container>
    </InfiniteScroll>
  );
}

