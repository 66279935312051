import { REQUIRED_VALIDATION, validateWhiteSpace } from './_predefinedValidations';

export type UserNameFormTypes = {
  firstName: string,
  lastName: string,
};

export const USER_NAME_FORM: [FormInputField, FormInputField] = [
  {
    label: 'First name',
    name: 'firstName',
    inputType: 'text',
    validation: {
      required: REQUIRED_VALIDATION,
      validate: { validateWhiteSpace }

    }
  },
  {
    label: 'Last name',
    name: 'lastName',
    inputType: 'text',
    validation: {
      required: REQUIRED_VALIDATION,
      validate: { validateWhiteSpace }

    }
  }
];
