import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTypedSelector } from '../hooks/TypedReduxHooks';

import { CircularProgress, Grid, useMediaQuery } from '@mui/material';
import { BodyTwoPrimary, BodyTwoSecondary, CaptionPrimary, CaptionSecondary, HeaderFourPrimary, SubtitleOnePrimary } from '../components-atoms/TypographyComponents';

import { Dashboard } from '../components-organisms/Dashboard';
import { CategoryPageDrawer } from '../components-organisms/CategoryPageDrawer';
import { Footer } from '../components-organisms/Footer';
import { GetServicesOfCategoryRequest } from '../service/serviceService';

import '../scss/components/_category-page.scss';
import '../scss/components/_thumbnail.scss';
import '../scss/components/_banner.scss';
import { LIGHT_THEME } from '../constants/theme';
import { SupportTooltip } from '../components-molecules/SupportTooltip';
import { gaService } from '../service/gaService';

const BANNER_NUMBER: { [key: string]: number; } = {
  'Training': 0,
  'Think Forward': 1,
  'Work Smarter': 2,
  'Rewards': 3
};

export function CategoryPage(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = useTypedSelector(state => state.userReducer.token);
  const [services, setServices] = useState<ServiceOfCategory[]>([]);
  const [loading, setLoading] = useState(false);
  const smallUp = useMediaQuery(LIGHT_THEME.breakpoints.up('sm'));
   
  useEffect(() => {
    gaService.pageView(services[0]?.CategoryName);
  }, [services]);

  useEffect(() => {
    if (token) {
      setLoading(true);
      GetServicesOfCategoryRequest(
        token,
        id as GUID,
        (response) => {
          setServices(response.Result);
          setLoading(false);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  }, [token, id]);

  return (
    <Dashboard noDrawer noMargin noPadding fullWidth merchantPage>
      {loading
        ? (
          <div className="progress-spinner-wrapper">
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Grid className="category-page" minHeight='95vh'>
            <CategoryPageDrawer />
            <>
              <Grid container sx={{ maxWidth: '1408px', width: '100%', margin: '0 auto', padding: '0 24px' }}>
                <CaptionPrimary className="home-link" onClick={() => navigate('/')}>Home</CaptionPrimary>
                <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
                <CaptionPrimary>{services[0]?.CategoryName}</CaptionPrimary>
                <Grid
                  container
                  item
                  direction='column'
                  alignItems='flex-start'
                  justifyContent='flex-end'
                  className={`category-page__banner banner banner--${BANNER_NUMBER[services[0]?.CategoryName]}`}
                >
                  <HeaderFourPrimary>{services[0]?.CategoryName}</HeaderFourPrimary>
                  <Grid container alignItems="flex-end" justifyContent="space-between" flexWrap="nowrap">
                    <BodyTwoPrimary style={{ maxWidth: smallUp ? '672px' : '100%' }}>{services[0]?.CategoryDescription}</BodyTwoPrimary>
                    <div style={{ marginLeft: 'auto', alignSelf: 'flex-end', }}>
                      <SupportTooltip white />
                    </div>
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={20 / 8} alignItems="center" className="category-page__container">
                  {services[0]?.Services.map((obj) => {
                    return (
                      <Grid key={obj.ServiceName} item xs={12} sm={6} md={4} className="category-page__item">
                        <a
                          className="category-page-item category-page-item--link"
                          onClick={() => navigate(`/service/${obj.ServiceId}`)}
                        >
                          <div className="category-page-item__media">
                            <img
                              src={require(`../assets/img/category/${obj.ImageUrl}@3x.png`)}
                              alt={`${obj.ServiceName} Logo`}
                              className="thumb thumb--medium"
                            />
                          </div>
                          <div className="category-page-item--text">
                            <SubtitleOnePrimary>{obj.ServiceName}</SubtitleOnePrimary>
                            <BodyTwoSecondary className="category-page-item--detail">{obj.Description}</BodyTwoSecondary>
                          </div>
                        </a>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </>
            <Grid container sx={{
              background: 'linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 'auto'
            }}>
              <Grid item sx={{ maxWidth: '1408px', width: '100%' }}>
                <Footer />
              </Grid>
            </Grid>
          </Grid>
        )}
    </Dashboard>
  );
}
